import styles from "./Priceplancomp.module.css";

const Priceplancomp = ({payg,monthly}) => {
  return (
    <div className={styles.priceplancomp}>
      <div className={styles.priceplancompInner}>
        <div className={styles.chooseThePerfectPlanForYoParent}>
          <div className={styles.chooseThePerfect}>
            Choose the perfect plan for you.
          </div>
          <div className={styles.loggworksConnectHomeowners}>
            Loggworks connect homeowners to genuine and reliable service
            providers who provide easy-to-book and hassle-free services.
          </div>
        </div>
      </div>
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div className={styles.frameWrapper}>
            <div className={styles.paygParent}>
              <div className={styles.payg}>PAYG</div>
              <div className={styles.parent}>
                <b className={styles.b}>{payg}%</b>
                <div className={styles.commissionjob}>Commission/Job</div>
              </div>
            </div>
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.getAllThePerksInOurMonthWrapper}>
              <div className={styles.getAllThe}>
                Get all the perks in our monthly subscription plan
              </div>
            </div>
            <div className={styles.sendOffersToServiceConsumeWrapper}>
              <div className={styles.getAllThe}>
                Send offers to service consumers directly to improve chances of
                getting jobs.
              </div>
            </div>
            <div className={styles.getAllThePerksInOurMonthWrapper}>
              <div className={styles.getAllThe}>
                Get jobs directly on your dashboard at no extra cost.
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.frameWrapper}>
            <div className={styles.paygParent}>
              <div className={styles.payg}>Monthly</div>
              <div className={styles.parent}>
                <div className={styles.b}>
                  <b>£{monthly}</b>
                </div>
                <div className={styles.commissionjob}>Per Month</div>
              </div>
            </div>
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.getAllThePerksInOurMonthWrapper}>
              <div className={styles.getAllThe}>
                Automatically manage your jobs with our job management feature.
              </div>
            </div>
            <div className={styles.sendOffersToServiceConsumeWrapper}>
              <div className={styles.getAllThe}>
                Send a quote to receive payment swiftly.
              </div>
            </div>
            <div className={styles.getAllThePerksInOurMonthWrapper}>
              <div className={styles.getAllThe}>
                Share your business on any platform and get booked.
              </div>
            </div>
            <div className={styles.sendOffersToServiceConsumeWrapper}>
              <div className={styles.getAllThe}>
                Get vetted for free to improve your business reputation and
                increase visibility.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Priceplancomp;
