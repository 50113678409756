import { useState, useCallback } from "react";
import MobileMenuProfProfessional1 from "../components/MobileMenuProfProfessional1";
import PortalPopup from "../components/PortalPopup";
import Dropdown from "../components/Dropdown";
import { useNavigate } from "react-router-dom";
import styles from "./BecomeAprofessionalArticle.module.css";
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import MobileFooter from "../components/MobileFooter";
const BecomeAprofessionalArticle = () => {
  const [
    isMobileMenuProfProfessionalPopupOpen,
    setMobileMenuProfProfessionalPopupOpen,
  ] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(null);
  const open = Boolean(isDropdownOpen);
  const [cat_tab,setCat_Tab]=useState(false)
  const [ch_tab,setCh_Tab]=useState(false)
  const [cle_tab,setCle_Tab]=useState(false)
  const [ent_tab,setEnt_Tab]=useState(false)
  const [app_tab,setApp_Tab]=useState(false)
  const [mob_tab,setMob_Tab]=useState(false)
  const [pers_tab,setPers_Tab]=useState(false)
  const [pet_tab,setPet_Tab]=useState(false)
  const [rec_tab,setRec_Tab]=useState(false)
  const [rem_tab,setRem_Tab]=useState(false)
  const [trad_tab,setTrad_Tab]=useState(false)
  const [trans_tab,setTrans_Tab]=useState(false)
  const [comp_tab,setComp_Tab]=useState(false)
  const [dry_tab,setDry_Tab]=useState(false)
  const [hea_tab,setHea_Tab]=useState(false)
  const [tui_tab,setTui_Tab]=useState(false)
  const openDropdown = (event) => {
    setDropdownOpen(event.currentTarget);
  };
  const closeDropdown = () => {
    setDropdownOpen(null);
  };
  const navigate = useNavigate();

  const openMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(true);
  }, []);

  const closeMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(false);
  }, []);

  const onProfessionalCategoriesContainer1Click = useCallback(() => {
    // Please sync "Home Mobile" to the project
  }, []);

  const onPageSelectorContainer1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/professional");
  }, [navigate]);

  const onServicesContainerClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onPricingTextClick = useCallback(() => {
    navigate("/priceplan");
  }, [navigate]);

  const onFrameContainer54Click = useCallback(() => {
    navigate("/professional-faq");
  }, [navigate]);

  const onPostAJob3Click = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const onHowItWorks4Click = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onContactUsText3Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQs3Click = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementText3Click = useCallback(() => {
    navigate("/agreement");
  }, [navigate]);

  const onHowItWorks5Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onContactUsText4Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQs4Click = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementText4Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/becometeam");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/partners");
  }, [navigate]);

  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/affiliate-program");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onLargeButtons1Click = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  return (
    <>
      <div className={styles.becomeaprofessionalarticle}>
        <div className={styles.homeMobile}>
          <div className={styles.navbarMobileclosed}>
            <img className={styles.layer2Icon} alt="" src="/layer-21.svg" 
            style={{cursor:'pointer'}} onClick={()=>navigate('/')} />
            <div className={styles.iconsaccountParent}>
              <img
                className={styles.iconsaccount}
                alt=""
                src="/iconsaccount21.svg"
                onClick={()=>window.open('https://www.professional.loggworks.co.uk/', '_blank')}
              />
              <img
                className={styles.hamburgerIcon}
                alt=""
                src="/hamburger.svg"
                onClick={openMobileMenuProfProfessionalPopup}
              />
            </div>
          </div>
          <div className={styles.homeMobileInner}>
            <div className={styles.frameParent}>
              <div className={styles.becomeATrustedHomeServiceParent}>
                <b className={styles.becomeATrusted}>
                  Become a trusted home service professional.
                </b>
                <div className={styles.loggworksConnectHomeownersContainer}>
                <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    Only pay commission on completed jobs
                    </div>
                    </div>
                  <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    100% free Quote
                    </div>
                  </div>
                   <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    100% Free business listing page
                    </div>
                  </div>
                  <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    100% free Automated Job Management 
                    </div>
                  </div>
                  <p style={{textAlign:'left'}}>feature</p>
                </div>
              </div>
              <div className={styles.largeButtons} style={{cursor: 'pointer'}} 
              onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                <img className={styles.iconsadd} alt="" src="/iconsadd3.svg" />
                <b className={styles.label}>Become a Professional</b>
                <img className={styles.iconsadd} alt="" src="/iconsadd4.svg" />
              </div>
            </div>
          </div>
          <div className={styles.ourServiceCatalogueParent}>
            <div className={styles.ourServiceCatalogue}>
              Our service catalogue
            </div>
            <div className={styles.professionalCategoriesParent}>
             {/*<div className={styles.professionalCategories} onClick={()=>{setCat_Tab(!cat_tab)}}>
                <img
                  className={styles.professionalCategoriesChild}
                  alt=""
                  src="/rectangle-1230716@2x.png"
                />
                <div className={styles.categoriesDesc}>
                  <div className={styles.categoryTitle}>
                    <div className={styles.titleDescWrapper}>
                      <div className={styles.titleDesc}>
                        <div className={styles.titleIcon}>
                          <div className={styles.catering}>Catering</div>
                          <img
                            className={styles.iconsminus}
                            alt=""
                            src={ cat_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                          />
                        </div>
                        <div className={styles.briefDescriptionOfContainer}>
                          <p className={styles.briefDescriptionOf}>
                            Brief description of what this entails.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.subCategories} style={cat_tab==false?{display:'none'}:{display:'block'}}>
                    <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>Kitchen Porter</div>
                      <div className={styles.kitchenPorter}>
                        Short-order cook
                      </div>
                      <div className={styles.kitchenPorter}>Caterer</div>
                      <div className={styles.kitchenPorter}>Others</div>
                     
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.professionalCategories1} onClick={()=>{setCh_Tab(!ch_tab)}}>
                <img
                  className={styles.professionalCategoriesChild}
                  alt=""
                  src="/rectangle-1230717@2x.png"
                />
                <div className={styles.categoriesDesc1}>
                  <div className={styles.categoryTitle}>
                    <div className={styles.titleDescContainer}>
                      <div className={styles.titleDesc}>
                        <div className={styles.titleIcon}>
                          <div className={styles.catering}>Childcare</div>
                          <img
                            className={styles.iconsminus}
                            alt=""
                            src={ ch_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                          />
                        </div>
                        <div className={styles.briefDescriptionOfContainer}>
                          <p
                            className={styles.briefDescriptionOf}
                          >{`Provides care and supervision for children. `}</p>
                          <p className={styles.briefDescriptionOf}>&nbsp;</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.subCategories1} style={ch_tab==false?{display:'none'}:{display:'block'}}>
                    <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>Babysitters</div>
                      <div className={styles.kitchenPorter}>Childminders</div>
                      <div className={styles.kitchenPorter}>Nannies</div>
                      <div className={styles.kitchenPorter}>
                        Virtual Nannies
                      </div>
                      <div className={styles.others32}>Nursery</div>
                      <div className={styles.others32}>Others</div>
                    </div>
                  </div>
                </div>
              </div>*/}
              <div className={styles.professionalCategories2}  onClick={()=>{setCle_Tab(!cle_tab)}}>
                <img
                  className={styles.professionalCategoriesChild}
                  alt=""
                  src="/rectangle-1230718@2x.png"
                />
                <div className={styles.categoriesDesc}>
                  <div className={styles.categoryTitle}>
                    <div className={styles.titleDescContainer}>
                      <div className={styles.titleDesc}>
                        <div className={styles.titleIcon}>
                          <div className={styles.catering}>Cleaning</div>
                          <img
                            className={styles.iconsminus}
                            alt=""
                            src={ cle_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                          />
                        </div>
                        <div className={styles.briefDescriptionOfContainer}>
                          <p className={styles.briefDescriptionOf}>
                            Provides cleaning services for homes or businesses.
                          </p>
                          <p className={styles.briefDescriptionOf}>.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.subCategories1} style={cle_tab==false?{display:'none'}:{display:'block'}}>
                    <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>
                        Domestic cleaners
                      </div>
                      <div className={styles.kitchenPorter}>
                        Window cleaners
                      </div>
                      <div className={styles.kitchenPorter}>
                        Property maintenance
                      </div>
                      <div className={styles.kitchenPorter}>
                        Drain and pipe cleaning
                      </div>
                      <div className={styles.others32}>
                        Domestic and commercial cleaners
                      </div>
                      <div className={styles.others32}>Others</div>
                    </div>
                  </div>
                </div>
              </div>
            { /*<div className={styles.professionalCategories2} onClick={()=>{setEnt_Tab(!ent_tab)}}>
                <img
                  className={styles.professionalCategoriesChild}
                  alt=""
                  src="/rectangle-1230719@2x.png"
                />
                <div className={styles.categoriesDesc}>
                  <div className={styles.categoryTitle}>
                    <div className={styles.titleDescContainer}>
                      <div className={styles.titleDesc}>
                        <div className={styles.titleIcon}>
                          <div className={styles.catering}>Entertainment</div>
                          <img
                            className={styles.iconsminus}
                            alt=""
                            src={ ent_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                          />
                        </div>
                        <div className={styles.briefDescriptionOfContainer}>
                          <p className={styles.briefDescriptionOf}>
                            Provides entertainment, such as music or party
                            games, for events or parties.
                          </p>
                          <p className={styles.briefDescriptionOf}>&nbsp;</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.subCategories1} style={ent_tab==false?{display:'none'}:{display:'block'}}>
                  <div className={styles.titleDesc}>
                            <div className={styles.kitchenPorter}>
                              Event planner
                            </div>
                            <div className={styles.kitchenPorter}>
                              Party planner
                            </div>
                            <div className={styles.kitchenPorter}>
                              Event coverage
                            </div>
                            <div className={styles.kitchenPorter}>
                              Event and party planner
                            </div>
                            <div className={styles.others32}>
                              Event planner and coverage services
                            </div>
                            <div className={styles.others32}>
                              DJ and disco hire
                            </div>
                            <div className={styles.others32}>
                              Function rooms and banqueting facilities
                            </div>
                            <div className={styles.others32}>
                              Organisers and planners
                            </div>
                            <div className={styles.others32}>
                              Wedding and reception venues
                            </div>
                            <div className={styles.others32}>
                              Photography and film
                            </div>
                            <div className={styles.others32}>Florist</div>
                           
                          </div>
                  </div>
                </div>
              </div>
              <div className={styles.professionalCategories2} onClick={()=>{setApp_Tab(!app_tab)}}>
                <img
                  className={styles.professionalCategoriesChild}
                  alt=""
                  src="/rectangle-1230720@2x.png"
                />
                <div className={styles.categoriesDesc}>
                  <div className={styles.categoryTitle}>
                    <div className={styles.titleDescContainer}>
                      <div className={styles.titleDesc}>
                        <div className={styles.titleIcon}>
                          <div className={styles.catering}>
                            Appliance Repair
                          </div>
                          <img
                            className={styles.iconsminus}
                            alt=""
                            src={ app_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                          />
                        </div>
                        <div className={styles.briefDescriptionOfContainer}>
                          <p className={styles.briefDescriptionOf}>
                            Fixes or maintains household appliances.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.subCategories1} style={app_tab==false?{display:'none'}:{display:'block'}}>
                  <div className={styles.titleDesc}>
                            <div className={styles.kitchenPorter}>
                              Domestic Appliance Repairs
                            </div>
                            <div className={styles.kitchenPorter}>
                              Appliance Repairs
                            </div>
                            <div className={styles.others186}>caterer</div>
                            <div className={styles.others186}>others</div>
                           
                          </div>
                  </div>
                </div>
              </div>
              <div className={styles.professionalCategories2} onClick={()=>{setMob_Tab(!mob_tab)}}>
                <img
                  className={styles.professionalCategoriesChild}
                  alt=""
                  src="/rectangle-1230721@2x.png"
                />
                <div className={styles.categoriesDesc}>
                  <div className={styles.categoryTitle}>
                    <div className={styles.titleDescContainer}>
                      <div className={styles.titleDesc}>
                        <div className={styles.titleIcon}>
                          <div className={styles.catering}>Mobile Mechanic</div>
                          <img
                            className={styles.iconsminus}
                            alt=""
                            src={ mob_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                          />
                        </div>
                        <div className={styles.briefDescriptionOfContainer}>
                          <p className={styles.briefDescriptionOf}>
                            Provides mechanic services for vehicles at a
                            location of the customer's choice.
                          </p>
                          <p className={styles.briefDescriptionOf}>&nbsp;</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.subCategories1} style={mob_tab==false?{display:'none'}:{display:'block'}}>
                    <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>
                      Mobile mechanic
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.professionalCategories6} onClick={()=>{setPers_Tab(!pers_tab)}}>
                <img
                  className={styles.professionalCategoriesChild}
                  alt=""
                  src="/rectangle-1230722@2x.png"
                />
                <div className={styles.categoriesDesc}>
                  <div className={styles.categoryTitle}>
                    <div className={styles.titleDescContainer}>
                      <div className={styles.titleDesc}>
                        <div className={styles.titleIcon}>
                          <div className={styles.catering}>
                            Personal Assistance
                          </div>
                          <img
                            className={styles.iconsminus}
                            alt=""
                            src={ pers_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                          />
                        </div>
                        <div className={styles.briefDescriptionOfContainer}>
                          Provides personal assistance with tasks such as
                          shopping or errands.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.subCategories1} style={pers_tab==false?{display:'none'}:{display:'block'}}>
                    <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>
                        Remote personal assistance
                      </div>
                      <div className={styles.kitchenPorter}>
                        Household personal assistance
                      </div>
                      <div className={styles.kitchenPorter}>Others</div>
                      <div className={styles.others186}>others</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.professionalCategories7} onClick={()=>{setPet_Tab(!pet_tab)}}>
                <img
                  className={styles.professionalCategoriesChild}
                  alt=""
                  src="/rectangle-1230723@2x.png"
                />
                <div className={styles.categoriesDesc}>
                  <div className={styles.categoryTitle}>
                    <div className={styles.titleDescContainer}>
                      <div className={styles.titleDesc}>
                        <div className={styles.titleIcon}>
                          <div className={styles.catering}>Pet Groomers</div>
                          <img
                            className={styles.iconsminus}
                            alt=""
                            src={ pet_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                          />
                        </div>
                        <div className={styles.briefDescriptionOfContainer}>
                          <p className={styles.briefDescriptionOf}>
                            Provides grooming services for pets.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.subCategories1} style={pet_tab==false?{display:'none'}:{display:'block'}}>
                    <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>Pet Grooming</div>
                      <div className={styles.kitchenPorter}>
                        Pet Training
                      </div>
                      <div className={styles.kitchenPorter}>Pet Grooming and training</div>
                      <div className={styles.kitchenPorter}>Dog Walker</div>
                      <div className={styles.kitchenPorter}>others</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.professionalCategories7} onClick={()=>{setRec_Tab(!rec_tab)}}>
                <img
                  className={styles.professionalCategoriesChild}
                  alt=""
                  src="/rectangle-1230724@2x.png"
                />
                <div className={styles.categoriesDesc}>
                  <div className={styles.categoryTitle8}>
                    <div className={styles.titleDescContainer}>
                      <div className={styles.titleDesc}>
                        <div className={styles.titleIcon}>
                          <div className={styles.catering}>
                            Recovery Services
                          </div>
                          <img
                            className={styles.iconsadd9}
                            alt=""
                            src={ rec_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                          />
                        </div>
                        <div className={styles.briefDescriptionOfContainer}>
                          <p className={styles.briefDescriptionOf}>
                            Brief description of what this entails.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.subCategories1} style={rec_tab==false?{display:'none'}:{display:'block'}}>
                    <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>Recovery</div>
                     
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.professionalCategories9} onClick={()=>{setRem_Tab(!rem_tab)}}>
                <img
                  className={styles.professionalCategoriesChild}
                  alt=""
                  src="/rectangle-1230725@2x.png"
                />
                <div className={styles.categoriesDesc}>
                  <div className={styles.categoryTitle8}>
                    <div className={styles.titleDescContainer}>
                      <div className={styles.titleDesc}>
                        <div className={styles.titleIcon}>
                          <div className={styles.catering}>Removal Service</div>
                          <img
                            className={styles.iconsadd9}
                            alt=""
                            src={ rem_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                          />
                        </div>
                        <div className={styles.briefDescriptionOfContainer}>
                          <p className={styles.briefDescriptionOf}>
                            Provides services to transport and remove items from
                            a location.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.subCategories1} style={rem_tab==false?{display:'none'}:{display:'block'}}>
                    <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>Removal</div>
                      <div className={styles.kitchenPorter}>
                        Domestic Removals
                      </div>
                      <div className={styles.kitchenPorter}>Commercial Removals</div>
                      <div className={styles.kitchenPorter}>Local Removals</div>
                    </div>
                  </div>
                </div>
              </div>*/}
              <div className={styles.professionalCategories10} onClick={()=>{setTrad_Tab(!trad_tab)}}>
                <img
                  className={styles.professionalCategoriesChild}
                  alt=""
                  src="/rectangle-1230726@2x.png"
                />
                <div className={styles.categoriesDesc}>
                  <div className={styles.categoryTitle8}>
                    <div className={styles.titleDescContainer}>
                      <div className={styles.titleDesc}>
                        <div className={styles.titleIcon}>
                          <div className={styles.catering}>Tradesmen</div>
                          <img
                            className={styles.iconsadd9}
                            alt=""
                            src={ trad_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                          />
                        </div>
                        <div className={styles.briefDescriptionOfContainer}>
                          <p className={styles.briefDescriptionOf}>
                            Provides skilled trade services, such as plumbing or
                            carpentry.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.subCategories1} style={trad_tab==false?{display:'none'}:{display:'block'}}>
                    <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>Tradesmen & Construction</div>
                      <div className={styles.kitchenPorter}>
                      Construction
                      </div>
                      <div className={styles.kitchenPorter}>Gardening & Landscaping</div>
                      <div className={styles.kitchenPorter}>Gardener</div>
                      <div className={styles.kitchenPorter}>Painter</div>
                      <div className={styles.kitchenPorter}>Decorating</div>
                      <div className={styles.kitchenPorter}>Plumber</div>
                      <div className={styles.kitchenPorter}>Handyman</div>
                      <div className={styles.kitchenPorter}>Electrician</div>
                      <div className={styles.kitchenPorter}>Builder</div>
                      <div className={styles.kitchenPorter}>Plasterers</div>
                      <div className={styles.kitchenPorter}>Architect</div>
                      <div className={styles.kitchenPorter}>Carpentry And Joiners</div>
                      <div className={styles.kitchenPorter}>Groundworkers</div>
                      <div className={styles.kitchenPorter}>Roofer</div>
                      <div className={styles.kitchenPorter}>Tiler</div>
                      <div className={styles.kitchenPorter}>Paving And Driveway</div>
                      <div className={styles.kitchenPorter}>Flooring</div>
                      <div className={styles.kitchenPorter}>Locksmiths</div>
                      <div className={styles.kitchenPorter}>Interior Designers</div>
                      <div className={styles.kitchenPorter}>Landscaper</div>
                      <div className={styles.kitchenPorter}>Carpenter</div>
                      <div className={styles.kitchenPorter}>Plasterer</div>
                      <div className={styles.kitchenPorter}>Driveways/Patio</div>
                      <div className={styles.kitchenPorter}>Fencing</div>
                      <div className={styles.kitchenPorter}>Boiler Repair</div>
                      <div className={styles.kitchenPorter}>Tree Surgeons</div>
                      <div className={styles.kitchenPorter}>Carpet Repairs</div>
                      <div className={styles.kitchenPorter}>Others</div>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className={styles.professionalCategories11} onClick={()=>{setTrans_Tab(!trans_tab)}}>
                <img
                  className={styles.professionalCategoriesChild}
                  alt=""
                  src="/rectangle-1230727@2x.png"
                />
                <div className={styles.categoriesDesc}>
                  <div className={styles.categoryTitle11}>
                    <div className={styles.titleDescContainer}>
                      <div className={styles.titleDesc}>
                        <div className={styles.titleIcon}>
                          <div className={styles.catering}>
                            Transport Services
                          </div>
                          <img
                            className={styles.iconsadd9}
                            alt=""
                            src={ trans_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                          />
                        </div>
                        <div className={styles.briefDescriptionOfContainer}>
                          <p className={styles.briefDescriptionOf}>
                            Provides transportation services for people or
                            goods.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.subCategories1} style={trans_tab==false?{display:'none'}:{display:'block'}}>
                    <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>Bus And Coach Hire</div>
                      <div className={styles.kitchenPorter}>
                      Van And Truck Hire
                      </div>
                      <div className={styles.kitchenPorter}>Chauffeur And Limousine Hire</div>
                      <div className={styles.kitchenPorter}>Car Hire</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.professionalCategories12} onClick={()=>{setComp_Tab(!comp_tab)}}>
                <img
                  className={styles.professionalCategoriesChild}
                  alt=""
                  src="/rectangle-1230728@2x.png"
                />
                <div className={styles.categoriesDesc}>
                  <div className={styles.categoryTitle}>
                    <div className={styles.titleDescContainer}>
                      <div className={styles.titleDesc}>
                        <div className={styles.titleIcon}>
                          <div
                            className={styles.catering}
                          >{`Computer & Phone Repair`}</div>
                          <img
                            className={styles.iconsadd9}
                            alt=""
                            src={ comp_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                          />
                        </div>
                        <div className={styles.briefDescriptionOfContainer}>
                          {" "}
                          Fixes or maintains computers and phones.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.subCategories1} style={comp_tab==false?{display:'none'}:{display:'block'}}>
                    <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>Phone</div>
                      <div className={styles.kitchenPorter}>
                      Computer Repair
                      </div>
                      <div className={styles.kitchenPorter}>Computer And Phone Repair</div>
                      <div className={styles.kitchenPorter}>others</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.professionalCategories6} onClick={()=>{setDry_Tab(!dry_tab)}}>
                <img
                  className={styles.professionalCategoriesChild}
                  alt=""
                  src="/rectangle-1230729@2x.png"
                />
                <div className={styles.categoriesDesc1}>
                  <div className={styles.categoryTitle8}>
                    <div className={styles.titleDescWrapper10}>
                      <div className={styles.titleDesc}>
                        <div className={styles.titleIcon}>
                          <div className={styles.catering}>
                            Dry Cleaners and laundrymen
                          </div>
                          <img
                            className={styles.iconsadd9}
                            alt=""
                            src={ dry_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                          />
                        </div>
                        <div className={styles.briefDescriptionOfContainer}>
                          <p className={styles.briefDescriptionOf}>
                            Provides dry cleaning and laundry services .
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.subCategories1} style={dry_tab==false?{display:'none'}:{display:'block'}}>
                    <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>Dry Cleaner</div>
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.professionalCategories2} onClick={()=>{setHea_Tab(!hea_tab)}}>
                <img
                  className={styles.professionalCategoriesChild}
                  alt=""
                  src="/rectangle-1230730@2x.png"
                />
                <div className={styles.categoriesDesc1}>
                  <div className={styles.categoryTitle8}>
                    <div className={styles.titleDescContainer}>
                      <div className={styles.titleDesc}>
                        <div className={styles.titleIcon}>
                          <div
                            className={styles.catering}
                          >{`Hiring Health, Wellness & Beauty coaches`}</div>
                          <img
                            className={styles.iconsadd9}
                            alt=""
                            src={ hea_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                          />
                        </div>
                        <div className={styles.briefDescriptionOfContainer}>
                          <p className={styles.briefDescriptionOf}>
                            Provides services related to health, wellness, and
                            beauty, such as massage or hair styling.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.subCategories1} style={hea_tab==false?{display:'none'}:{display:'block'}}>
                    <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>Counselling</div>
                      <div className={styles.kitchenPorter}>
                      Mentoring
                      </div>
                      <div className={styles.kitchenPorter}>Life Coaching</div>
                      <div className={styles.kitchenPorter}>Nutritionist And Dieticians</div>
                      <div className={styles.kitchenPorter}>Personal Trainers</div>
                      <div className={styles.kitchenPorter}>Relationship And Marriage Counselling</div>
                      <div className={styles.kitchenPorter}>Life Coaching And Mentoring</div>
                      <div className={styles.kitchenPorter}>Therapist</div>
                      <div className={styles.kitchenPorter}>others</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.professionalCategories15} onClick={()=>{setTui_Tab(!tui_tab)}}>
                <img
                  className={styles.professionalCategoriesChild}
                  alt=""
                  src="/rectangle-1230731@2x.png"
                />
                <div className={styles.categoriesDesc}>
                  <div className={styles.categoryTitle}>
                    <div className={styles.titleDescContainer}>
                      <div className={styles.titleDesc}>
                        <div className={styles.titleIcon}>
                          <div
                            className={styles.catering}
                          >{`Tuition & Classes`}</div>
                          <img
                            className={styles.iconsadd9}
                            alt=""
                            src={tui_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                          />
                        </div>
                        <div className={styles.briefDescriptionOfContainer}>
                          Provides education or lessons in a particular subject
                          or skill.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.subCategories1} style={tui_tab==false?{display:'none'}:{display:'block'}}>
                    <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>Academic Tutor</div>
                      <div className={styles.kitchenPorter}>
                      Language
                      </div>
                      <div className={styles.kitchenPorter}>Driving Lessons And Instructors</div>
                      <div className={styles.kitchenPorter}>Music</div>
                      <div className={styles.kitchenPorter}>Guitar</div>
                      <div className={styles.kitchenPorter}>Piano</div>
                      <div className={styles.kitchenPorter}>Drum</div>
                      <div className={styles.kitchenPorter}>Singing</div>
                      <div className={styles.kitchenPorter}>Clarinet</div>
                      <div className={styles.kitchenPorter}>Saxophone</div>
                      <div className={styles.kitchenPorter}>Violin</div>
                      <div className={styles.kitchenPorter}>others</div>
                    </div>
                  </div>
                </div>
              </div>*/}
            </div>
          </div>
          <MobileFooter/>
        </div>
        <div className={styles.professionalhelpcontact}>
          <div className={styles.professionalNavigation}>
            <div className={styles.navigation}>
              <img
                className={styles.navigationChild}
                onClick={()=>navigate('/')}
                style={{cursor:'pointer'}}
                alt=""
                src="/frame-66522.svg"
              />
              <div className={styles.navigationItem} />
              <div className={styles.tabSwitch}>
                <div className={styles.pageSelector}>
                  <div className={styles.descriptionParent}>
                    <b className={styles.facebook}>Professional</b>
                    <div className={styles.selector} />
                  </div>
                </div>
                <div
                  className={styles.pageSelector1}
                  onClick={onPageSelectorContainer1Click}
                >
                  <div className={styles.descriptionParent}>
                    <div className={styles.copyright2022Loggwork}>Consumer</div>
                    <div className={styles.selector1} />
                  </div>
                </div>
              </div>
              <div className={styles.searchBar}>
                <div className={styles.iconssearchWrapper}>
                  <img
                    className={styles.iconsminus}
                    alt=""
                    src="/iconssearch.svg"
                  />
                </div>
                <div className={styles.electrician}>Electrician</div>
                <div className={styles.dividerParent}>
                  <div className={styles.divider} />
                  <div className={styles.location}>
                    <img
                      className={styles.iconslocation}
                      alt=""
                      src="/iconslocation.svg"
                    />
                    <div className={styles.locationOrPostcode}>
                      Location or postcode
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.navigation1}>
              <div className={styles.homeParent}>
                <div className={styles.home} onClick={onHomeTextClick}>
                  Home
                </div>
                <div className={styles.howItWorks3}>How it works</div>
                <div
                  className={styles.services}
                  onClick={onServicesContainerClick}
                >
                  <div className={styles.services1}>Categories</div>
                  <img
                    className={styles.iconsadd9}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                <div className={styles.resources} 
                id="fade-button"
  aria-controls={open ? 'fade-menu' : undefined}
  aria-haspopup="true"
  aria-expanded={open ? 'true' : undefined}
                onClick={openDropdown}>
                  <div className={styles.services1}>Resources</div>
                  <img
                    className={styles.iconsadd9}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={isDropdownOpen}
        open={open}
        onClose={closeDropdown}
        TransitionComponent={Fade}
      >
      <Dropdown/>
      </Menu>
                <div className={styles.home} onClick={onPricingTextClick}>
                  Pricing
                </div>
              </div>
              <div className={styles.frameParent6}>
                <div className={styles.frameParent7}>
                  <div
                    className={styles.iconshelpParent}
                    onClick={onFrameContainer54Click}
                  >
                    <img
                      className={styles.iconsminus}
                      alt=""
                      src="/iconshelp.svg"
                    />
                    <div className={styles.help}>Help</div>
                  </div>
                  <div className={styles.iconsaccountGroup} style={{cursor:'pointer'}}
                   onClick={()=>window.open('https://www.professional.loggworks.co.uk/', '_blank')}>
                    <img
                      className={styles.iconsminus}
                      alt=""
                      src="/iconsaccount11.svg"
                    />
                    <div className={styles.help}>Account</div>
                  </div>
                </div>
                <div className={styles.smallButtons} style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                  <div className={styles.buttonWrapper}>
                    <div className={styles.button}>Become a professional</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameParent8}>
            <div className={styles.frameWrapper}>
              <div className={styles.becomeATrustedHomeServiceGroup}>
                <b className={styles.becomeATrusted1}>
                  Become a trusted home service professional.
                </b>
                <div className={styles.loggworksConnectHomeowners}>
                <div style={{display:'flex', gap:'1rem'}}>
                   <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    Only pay commission on completed jobs
                    </div>
                  </div>
                  <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    100% free Quote
                    </div>
                  </div>
                </div>
                <div style={{display:'flex',gap:'1rem'}}>
                   <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    100% Free business listing page
                    </div>
                  </div>
                  <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    100% free Automated Job Management feature 
                    </div>
                  </div>
                </div>
                </div>
                <div className={styles.largeButtons3} 
                 style={{cursor:'pointer'}}
                 onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder10.svg"
                  />
                  <b className={styles.label}>Become a professional</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder10.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.frameWrapper1}>
              <div className={styles.frameGroup}>
                <div className={styles.serviceCategoryParent}>
                  <div className={styles.serviceCategory}>Service category</div>
                  <div className={styles.loggworksConnectHomeowners1}>
                    Loggworks connect homeowners to genuine and reliable service
                    providers who provide easy-to-book and hassle-free services.
                  </div>
                </div>
                <div className={styles.frameParent10}>
                  <div className={styles.professionalCategoriesGroup}>
                    {/*<div className={styles.professionalCategories16}
                    style={{cursor:'pointer'}} onClick={()=>{setCat_Tab(!cat_tab)}}>
                      <img
                        className={styles.professionalCategoriesChild}
                        alt=""
                        src="/rectangle-12307@2x.png"
                      />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div className={styles.catering16}>
                                  Catering
                                </div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                  src={ cat_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                Provides food and drinks for events or parties.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1} 
                        style={cat_tab==false?{display:'none'}:{display:'block'}}>
                          <div className={styles.titleDesc}>
                            <div className={styles.kitchenPorter}>
                              Kitchen Porter
                            </div>
                            <div className={styles.kitchenPorter}>
                              Short-order cook
                            </div>
                            <div className={styles.kitchenPorter}>Caterer</div>
                            <div className={styles.kitchenPorter}>Others</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.professionalCategories16}
                    style={{cursor:'pointer'}} onClick={()=>{setCh_Tab(!ch_tab)}}>
                      <img
                        className={styles.professionalCategoriesChild}
                        alt=""
                        src="/rectangle-123071@2x.png"
                      />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div className={styles.catering16}>
                                  Childcare
                                </div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                  src={ ch_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                <p
                                  className={styles.briefDescriptionOf}
                                >{`Provides care and supervision for children. `}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1} style={ch_tab==false?{display:'none'}:{display:'block'}}>
                          <div className={styles.titleDesc}>
                            <div className={styles.kitchenPorter}>
                              Babysitters
                            </div>
                            <div className={styles.kitchenPorter}>
                              Childminders
                            </div>
                            <div className={styles.kitchenPorter}>Nannies</div>
                            <div className={styles.kitchenPorter}>
                              Virtual Nannies
                            </div>
                            <div className={styles.others32}>Nursery</div>
                            <div className={styles.others32}>Others</div>
                           
                          </div>
                        </div>
                      </div>
                    </div>*/}
                    <div className={styles.professionalCategories16}
                    style={{cursor:'pointer'}} onClick={()=>{setCle_Tab(!cle_tab)}}>
                      <img
                        className={styles.professionalCategoriesChild}
                        alt=""
                        src="/rectangle-123072@2x.png"
                      />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div className={styles.catering16}>
                                  Cleaning
                                </div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                 src={ cle_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                <p className={styles.briefDescriptionOf}>
                                  Provides cleaning services for homes or
                                  businesses.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1} style={cle_tab==false?{display:'none'}:{display:'block'}}>
                          <div className={styles.titleDesc}>
                            <div className={styles.kitchenPorter}>
                              Domestic cleaners
                            </div>
                            <div className={styles.kitchenPorter}>
                              Window cleaners
                            </div>
                            <div className={styles.kitchenPorter}>
                              Property maintenance
                            </div>
                            <div className={styles.kitchenPorter}>
                              Drain and pipe cleaning
                            </div>
                            <div className={styles.others32}>
                              Domestic and commercial cleaners
                            </div>
                            <div className={styles.others32}>Others</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.professionalCategories16}
                    style={{cursor:'pointer'}} onClick={()=>{setTrad_Tab(!trad_tab)}}>
                      <img
                        className={styles.professionalCategoriesChild}
                        alt=""
                        src="/rectangle-1230710@2x.png"
                      />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div className={styles.catering16}>
                                  Tradesmen
                                </div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                  src={ trad_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                <p className={styles.briefDescriptionOf}>
                                  Provides skilled trade services, such as
                                  plumbing or carpentry.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1} style={trad_tab==false?{display:'none'}:{display:'block'}}>
                        <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>Tradesmen & Construction</div>
                      <div className={styles.kitchenPorter}>
                      Construction
                      </div>
                      <div className={styles.kitchenPorter}>Gardening & Landscaping</div>
                      <div className={styles.kitchenPorter}>Gardener</div>
                      <div className={styles.kitchenPorter}>Painter</div>
                      <div className={styles.kitchenPorter}>Decorating</div>
                      <div className={styles.kitchenPorter}>Plumber</div>
                      <div className={styles.kitchenPorter}>Handyman</div>
                      <div className={styles.kitchenPorter}>Electrician</div>
                      <div className={styles.kitchenPorter}>Builder</div>
                      <div className={styles.kitchenPorter}>Plasterers</div>
                      <div className={styles.kitchenPorter}>Architect</div>
                      <div className={styles.kitchenPorter}>Carpentry And Joiners</div>
                      <div className={styles.kitchenPorter}>Groundworkers</div>
                      <div className={styles.kitchenPorter}>Roofer</div>
                      <div className={styles.kitchenPorter}>Tiler</div>
                      <div className={styles.kitchenPorter}>Paving And Driveway</div>
                      <div className={styles.kitchenPorter}>Flooring</div>
                      <div className={styles.kitchenPorter}>Locksmiths</div>
                      <div className={styles.kitchenPorter}>Interior Designers</div>
                      <div className={styles.kitchenPorter}>Landscaper</div>
                      <div className={styles.kitchenPorter}>Carpenter</div>
                      <div className={styles.kitchenPorter}>Plasterer</div>
                      <div className={styles.kitchenPorter}>Driveways/Patio</div>
                      <div className={styles.kitchenPorter}>Fencing</div>
                      <div className={styles.kitchenPorter}>Boiler Repair</div>
                      <div className={styles.kitchenPorter}>Tree Surgeons</div>
                      <div className={styles.kitchenPorter}>Carpet Repairs</div>
                      <div className={styles.kitchenPorter}>Others</div>
                    </div>
                        </div>
                      </div>
                    </div>
                  </div>
                {  /*<div className={styles.professionalCategoriesGroup}>
                    <div className={styles.professionalCategories16}
                    style={{cursor:'pointer'}} onClick={()=>{setEnt_Tab(!ent_tab)}}>
                      <img
                        className={styles.professionalCategoriesChild}
                        alt=""
                        src="/rectangle-123073@2x.png"
                      />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div className={styles.catering16}>
                                  Entertainment
                                </div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                  src={ ent_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                <p className={styles.briefDescriptionOf}>
                                  Provides entertainment, such as music or party
                                  games, for events or parties.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1} style={ent_tab==false?{display:'none'}:{display:'block'}}>
                          <div className={styles.titleDesc}>
                            <div className={styles.kitchenPorter}>
                              Event planner
                            </div>
                            <div className={styles.kitchenPorter}>
                              Party planner
                            </div>
                            <div className={styles.kitchenPorter}>
                              Event coverage
                            </div>
                            <div className={styles.kitchenPorter}>
                              Event and party planner
                            </div>
                            <div className={styles.others32}>
                              Event planner and coverage services
                            </div>
                            <div className={styles.others32}>
                              DJ and disco hire
                            </div>
                            <div className={styles.others32}>
                              Function rooms and banqueting facilities
                            </div>
                            <div className={styles.others32}>
                              Organisers and planners
                            </div>
                            <div className={styles.others32}>
                              Wedding and reception venues
                            </div>
                            <div className={styles.others32}>
                              Photography and film
                            </div>
                            <div className={styles.others32}>Florist</div>
                           
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.professionalCategories16}
                    style={{cursor:'pointer'}} onClick={()=>{setApp_Tab(!app_tab)}}>
                      <img
                        className={styles.professionalCategoriesChild}
                        alt=""
                        src="/rectangle-123074@2x.png"
                      />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div className={styles.catering16}>
                                  Appliance Repair
                                </div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                  src={ app_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                <p className={styles.briefDescriptionOf}>
                                  Fixes or maintains household appliances.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1} style={app_tab==false?{display:'none'}:{display:'block'}}>
                          <div className={styles.titleDesc}>
                            <div className={styles.kitchenPorter}>
                              Domestic Appliance Repairs
                            </div>
                            <div className={styles.kitchenPorter}>
                              Appliance Repairs
                            </div>
                            <div className={styles.others186}>caterer</div>
                            <div className={styles.others186}>others</div>
                           
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.professionalCategories16}
                    style={{cursor:'pointer'}} onClick={()=>{setMob_Tab(!mob_tab)}}>
                      <img
                        className={styles.professionalCategoriesChild}
                        alt=""
                        src="/rectangle-123075@2x.png"
                      />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div className={styles.catering16}>
                                  Mobile Mechanic
                                </div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                  src={ mob_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                <p className={styles.briefDescriptionOf}>
                                  Provides mechanic services for vehicles at a
                                  location of the customer's choice.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1} style={mob_tab==false?{display:'none'}:{display:'block'}}>
                          <div className={styles.titleDesc}>
                            <div className={styles.kitchenPorter}>
                              Mobile mechanic
                            </div>
                            <div className={styles.others186}>
                              short-order cook
                            </div>
                            <div className={styles.others186}>caterer</div>
                            <div className={styles.others186}>others</div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>*/}
                 {/* <div className={styles.professionalCategoriesGroup}>
                    <div className={styles.professionalCategories16}
                    style={{cursor:'pointer'}} onClick={()=>{setPers_Tab(!pers_tab)}}>
                      <img
                        className={styles.professionalCategoriesChild}
                        alt=""
                        src="/rectangle-123076@2x.png"
                      />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div className={styles.catering16}>
                                  Personal Assistance
                                </div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                  src={ pers_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                Provides personal assistance with tasks such as
                                shopping or errands.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1} style={pers_tab==false?{display:'none'}:{display:'block'}}>
                          <div className={styles.titleDesc}>
                            <div className={styles.kitchenPorter}>
                              Remote personal assistance
                            </div>
                            <div className={styles.kitchenPorter}>
                              Household personal assistance
                            </div>
                            <div className={styles.kitchenPorter}>Others</div>
                            <div className={styles.others186}>others</div>
                           
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.professionalCategories16}
                    style={{cursor:'pointer'}} onClick={()=>{setPet_Tab(!pet_tab)}}>
                      <img
                        className={styles.professionalCategoriesChild}
                        alt=""
                        src="/rectangle-123077@2x.png"
                      />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div className={styles.catering16}>
                                  Pet Groomers
                                </div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                  src={ pet_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                <p className={styles.briefDescriptionOf}>
                                  Provides grooming services for pets.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1} style={pet_tab==false?{display:'none'}:{display:'block'}}>
                          <div className={styles.titleDesc}>
                          <div className={styles.kitchenPorter}>Pet Grooming</div>
                      <div className={styles.kitchenPorter}>
                        Pet Training
                      </div>
                      <div className={styles.kitchenPorter}>Pet Grooming and training</div>
                      <div className={styles.kitchenPorter}>Dog Walker</div>
                      <div className={styles.kitchenPorter}>others</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.professionalCategories16}
                    style={{cursor:'pointer'}} onClick={()=>{setRec_Tab(!rec_tab)}}>
                      <img
                        className={styles.professionalCategoriesChild}
                        alt=""
                        src="/rectangle-123078@2x.png"
                      />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div className={styles.catering16}>
                                  Recovery Services
                                </div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                  src={ rec_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                <p className={styles.briefDescriptionOf}>
                                  Brief description of what this entails.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1} style={rec_tab==false?{display:'none'}:{display:'block'}}>
                          <div className={styles.titleDesc}>
                            <div className={styles.kitchenPorter}>
                             Recovery
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>*/}
                  {/*<div className={styles.professionalCategoriesGroup}>
                    <div className={styles.professionalCategories16}
                    style={{cursor:'pointer'}} onClick={()=>{setRem_Tab(!rem_tab)}}>
                      <img
                        className={styles.professionalCategoriesChild}
                        alt=""
                        src="/rectangle-123079@2x.png"
                      />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div className={styles.catering16}>
                                  Removal Service
                                </div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                  src={ rem_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                <p className={styles.briefDescriptionOf}>
                                  Provides services to transport and remove
                                  items from a location.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1} style={rem_tab==false?{display:'none'}:{display:'block'}}>
                        <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>Removal</div>
                      <div className={styles.kitchenPorter}>
                        Domestic Removals
                      </div>
                      <div className={styles.kitchenPorter}>Commercial Removals</div>
                      <div className={styles.kitchenPorter}>Local Removals</div>
                    </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.professionalCategories16}
                    style={{cursor:'pointer'}} onClick={()=>{setTrad_Tab(!trad_tab)}}>
                      <img
                        className={styles.professionalCategoriesChild}
                        alt=""
                        src="/rectangle-1230710@2x.png"
                      />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div className={styles.catering16}>
                                  Tradesmen
                                </div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                  src={ trad_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                <p className={styles.briefDescriptionOf}>
                                  Provides skilled trade services, such as
                                  plumbing or carpentry.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1} style={trad_tab==false?{display:'none'}:{display:'block'}}>
                        <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>Tradesmen & Construction</div>
                      <div className={styles.kitchenPorter}>
                      Construction
                      </div>
                      <div className={styles.kitchenPorter}>Gardening & Landscaping</div>
                      <div className={styles.kitchenPorter}>Gardener</div>
                      <div className={styles.kitchenPorter}>Painter</div>
                      <div className={styles.kitchenPorter}>Decorating</div>
                      <div className={styles.kitchenPorter}>Plumber</div>
                      <div className={styles.kitchenPorter}>Handyman</div>
                      <div className={styles.kitchenPorter}>Electrician</div>
                      <div className={styles.kitchenPorter}>Builder</div>
                      <div className={styles.kitchenPorter}>Plasterers</div>
                      <div className={styles.kitchenPorter}>Architect</div>
                      <div className={styles.kitchenPorter}>Carpentry And Joiners</div>
                      <div className={styles.kitchenPorter}>Groundworkers</div>
                      <div className={styles.kitchenPorter}>Roofer</div>
                      <div className={styles.kitchenPorter}>Tiler</div>
                      <div className={styles.kitchenPorter}>Paving And Driveway</div>
                      <div className={styles.kitchenPorter}>Flooring</div>
                      <div className={styles.kitchenPorter}>Locksmiths</div>
                      <div className={styles.kitchenPorter}>Interior Designers</div>
                      <div className={styles.kitchenPorter}>Landscaper</div>
                      <div className={styles.kitchenPorter}>Carpenter</div>
                      <div className={styles.kitchenPorter}>Plasterer</div>
                      <div className={styles.kitchenPorter}>Driveways/Patio</div>
                      <div className={styles.kitchenPorter}>Fencing</div>
                      <div className={styles.kitchenPorter}>Boiler Repair</div>
                      <div className={styles.kitchenPorter}>Tree Surgeons</div>
                      <div className={styles.kitchenPorter}>Carpet Repairs</div>
                      <div className={styles.kitchenPorter}>Others</div>
                    </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.professionalCategories16}
                    style={{cursor:'pointer'}} onClick={()=>{setTrans_Tab(!trans_tab)}}>
                      <img
                        className={styles.professionalCategoriesChild}
                        alt=""
                        src="/rectangle-1230711@2x.png"
                      />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div className={styles.catering16}>
                                  Transport Services
                                </div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                  src={ trans_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                <p className={styles.briefDescriptionOf}>
                                  Provides transportation services for people or
                                  goods.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1} style={trans_tab==false?{display:'none'}:{display:'block'}}>
                        <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>Bus And Coach Hire</div>
                      <div className={styles.kitchenPorter}>
                      Van And Truck Hire
                      </div>
                      <div className={styles.kitchenPorter}>Chauffeur And Limousine Hire</div>
                      <div className={styles.kitchenPorter}>Car Hire</div>
                    </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.professionalCategoriesGroup}>
                    <div className={styles.professionalCategories16} 
                    style={{cursor:'pointer'}} onClick={()=>{setComp_Tab(!comp_tab)}}>
                      <img
                        className={styles.professionalCategoriesChild}
                        alt=""
                        src="/rectangle-1230712@2x.png"
                      />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div
                                  className={styles.catering16}
                                >{`Computer & Phone Repair`}</div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                  src={ comp_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                {" "}
                                Fixes or maintains computers and phones.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1} style={comp_tab==false?{display:'none'}:{display:'block'}}>
                        <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>Phone</div>
                      <div className={styles.kitchenPorter}>
                      Computer Repair
                      </div>
                      <div className={styles.kitchenPorter}>Computer And Phone Repair</div>
                      <div className={styles.kitchenPorter}>others</div>
                    </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.professionalCategories16}
                    style={{cursor:'pointer'}} onClick={()=>{setDry_Tab(!dry_tab)}}>
                      <img
                        className={styles.professionalCategoriesChild}
                        alt=""
                        src="/rectangle-1230713@2x.png"
                      />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div
                                  className={styles.catering16}
                                >{`Dry Cleaning & Laundry`}</div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                  src={ dry_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                <p className={styles.briefDescriptionOf}>
                                  Provides dry cleaning and laundry services .
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1} style={dry_tab==false?{display:'none'}:{display:'block'}}>
                          <div className={styles.titleDesc}>
                            <div className={styles.kitchenPorter}>
                             Dry Cleaner
                            </div>
                          
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.professionalCategories16}
                    style={{cursor:'pointer'}} onClick={()=>{setHea_Tab(!hea_tab)}}>
                      <img
                        className={styles.professionalCategoriesChild}
                        alt=""
                        src="/rectangle-1230714@2x.png"
                      />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div
                                  className={styles.catering16}
                                >{`Health, Wellness & Beauty`}</div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                  src={ hea_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                <p className={styles.briefDescriptionOf}>
                                  Provides services related to health, wellness,
                                  and beauty, such as massage or hair styling.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1} style={hea_tab==false?{display:'none'}:{display:'block'}}>
                        <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>Counselling</div>
                      <div className={styles.kitchenPorter}>
                      Mentoring
                      </div>
                      <div className={styles.kitchenPorter}>Life Coaching</div>
                      <div className={styles.kitchenPorter}>Nutritionist And Dieticians</div>
                      <div className={styles.kitchenPorter}>Personal Trainers</div>
                      <div className={styles.kitchenPorter}>Relationship And Marriage Counselling</div>
                      <div className={styles.kitchenPorter}>Life Coaching And Mentoring</div>
                      <div className={styles.kitchenPorter}>Therapist</div>
                      <div className={styles.kitchenPorter}>others</div>
                    </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.professionalCategoriesGroup}>
                    <div className={styles.professionalCategories16} 
                    style={{cursor:'pointer'}} onClick={()=>{setTui_Tab(!tui_tab)}}>
                      <img
                        className={styles.professionalCategoriesChild}
                        alt=""
                        src="/rectangle-1230715@2x.png"
                      />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div
                                  className={styles.catering16}
                                >{`Tuition & Classes`}</div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                  src={ tui_tab==false?"/iconsadd21.svg":'/iconsminus.svg'}
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                Provides education or lessons in a particular
                                subject or skill.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1} style={tui_tab==false?{display:'none'}:{display:'block'}}>
                        <div className={styles.titleDesc}>
                      <div className={styles.kitchenPorter}>Academic Tutor</div>
                      <div className={styles.kitchenPorter}>
                      Language
                      </div>
                      <div className={styles.kitchenPorter}>Driving Lessons And Instructors</div>
                      <div className={styles.kitchenPorter}>Music</div>
                      <div className={styles.kitchenPorter}>Guitar</div>
                      <div className={styles.kitchenPorter}>Piano</div>
                      <div className={styles.kitchenPorter}>Drum</div>
                      <div className={styles.kitchenPorter}>Singing</div>
                      <div className={styles.kitchenPorter}>Clarinet</div>
                      <div className={styles.kitchenPorter}>Saxophone</div>
                      <div className={styles.kitchenPorter}>Violin</div>
                      <div className={styles.kitchenPorter}>others</div>
                    </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.professionalCategories32}>
                      <div className={styles.rectangleDiv} />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div className={styles.catering16}>
                                  Catering
                                </div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                  src="/iconsadd21.svg"
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                <p className={styles.briefDescriptionOf}>
                                  Brief description of what this entails.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1}>
                          <div className={styles.titleDesc}>
                            <div className={styles.kitchenPorter}>
                              Kitchen Porter
                            </div>
                            <div className={styles.kitchenPorter}>
                              short-order cook
                            </div>
                            <div className={styles.kitchenPorter}>caterer</div>
                            <div className={styles.kitchenPorter}>others</div>
                           
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.professionalCategories32}>
                      <div className={styles.rectangleDiv} />
                      <div className={styles.categoriesDesc16}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.titleDescContainer}>
                            <div className={styles.titleDesc}>
                              <div className={styles.titleIcon}>
                                <div className={styles.catering16}>
                                  Catering
                                </div>
                                <img
                                  className={styles.iconsminus}
                                  alt=""
                                  src="/iconsadd21.svg"
                                />
                              </div>
                              <div
                                className={styles.briefDescriptionOfContainer}
                              >
                                <p className={styles.briefDescriptionOf}>
                                  Brief description of what this entails.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.subCategories1}>
                          <div className={styles.titleDesc}>
                            <div className={styles.kitchenPorter}>
                              Kitchen Porter
                            </div>
                            <div className={styles.kitchenPorter}>
                              short-order cook
                            </div>
                            <div className={styles.kitchenPorter}>caterer</div>
                            <div className={styles.kitchenPorter}>others</div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>*/}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.prototypeFooter}>
            <div className={styles.footer}>
              <div className={styles.download}>
                <div className={styles.downloadOurApps}>Download our apps</div>
                <img
                  className={styles.playstoreButtonIcon}
                  alt=""
                  src="/playstore-button4.svg"
                />
                <img
                  className={styles.appstoreButtonIcon}
                  alt=""
                  src="/appstore-button.svg"
                />
              </div>
              <div className={styles.footerChild} />
              <img className={styles.layer2Icon1} 
              style={{cursor:'pointer'}}
              onClick={()=>{navigate('/')}}
              alt="" src="/layer-24.svg" />
              <div className={styles.frameParent11}>
                <div className={styles.homeownersParent}>
                  <b className={styles.professionals}>Homeowners</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.aboutUs} onClick={onPostAJob3Click}>
                      Post a Job
                    </div>
                    <div
                      className={styles.aboutUs}
                      onClick={onHowItWorks4Click}
                    >
                      How it works
                    </div>
                    <div
                      className={styles.aboutUs}
                      onClick={onContactUsText3Click}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.aboutUs}
                      onClick={onHelpFAQs3Click}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.aboutUs}
                      onClick={onUserAgreementText3Click}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.professionalsParent}>
                  <b className={styles.professionals}>Professionals</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.postAJob}
                    style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                    <div
                      className={styles.aboutUs}
                      onClick={onHowItWorks5Click}
                    >
                      Blog
                    </div>
                    <div
                      className={styles.aboutUs}
                      onClick={onContactUsText4Click}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.aboutUs}
                      onClick={onHelpFAQs4Click}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.aboutUs}
                      onClick={onUserAgreementText4Click}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.loggworksGroup}>
                  <b className={styles.professionals}>Loggworks</b>
                  <div className={styles.postAJobParent}>
                    <div
                      className={styles.aboutUs}
                      onClick={onAboutUsTextClick}
                    >
                      About us
                    </div>
                    <div
                      className={styles.aboutUs}
                      onClick={onCareersTextClick}
                    >
                      Careers
                    </div>
                    <div
                      className={styles.aboutUs}
                      onClick={onPartnersTextClick}
                    >
                      Partners
                    </div>
                    <div
                      className={styles.aboutUs}
                      onClick={onInfluencerProgramTextClick}
                    >
                      Affiliate program
                    </div>
                    <div className={styles.blog}>Blog</div>
                  </div>
                </div>
                <div className={styles.loggworksContainer}>
                  <div className={styles.loggworks2}>
                    <b className={styles.followUs1}>Follow us</b>
                  </div>
                  <div className={styles.groupParent}>
                    <div className={styles.groupDiv}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://web.facebook.com/Loggworks?_rdc=1&_rdr', '_blank')}>
                      <img
                        className={styles.frameIcon4}
                        alt=""
                        src="/frame20.svg"
                      />
                      <div className={styles.facebook1}>Facebook</div>
                    </div>
                    <div className={styles.frameParent12}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://twitter.com/loggworks', '_blank')}>
                      <img
                        className={styles.frameIcon5}
                        alt=""
                        src="/frame211.svg"
                      />
                      <div className={styles.twitter1}>Twitter</div>
                    </div>
                    <div className={styles.frameParent13}  
                    style={{cursor:'pointer'}}
                    onClick={()=>window.open('https://www.instagram.com/loggworks/?utm_medium=copy_link', '_blank')}>
                      <img
                        className={styles.frameIcon5}
                        alt=""
                        src="/frame24.svg"
                      />
                      <div className={styles.twitter1}>Instagram</div>
                    </div>
                    <div className={styles.frameParent14}>
                      <img
                        className={styles.frameIcon5}
                        alt=""
                        src="/frame25.svg"
                      />
                      <div className={styles.twitter1}>YouTube</div>
                    </div>
                    <div className={styles.mdilinkedinGroup}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.linkedin.com/company/loggworks', '_blank')}>
                      <img
                        className={styles.mdilinkedinIcon}
                        alt=""
                        src="/mdilinkedin1.svg"
                      />
                      <div className={styles.linkedin1}>LinkedIn</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.copyright2022LoggworkLtdAParent}>
                <div className={styles.copyright2022Loggwork}>
                  ©Copyright 2024 loggworks Ltd. All Rights Reserved
                </div>
                <div className={styles.frameParent15}>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/terms')}}>
                      Terms of Use
                    </div>
                    <div className={styles.frameItem} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div
                      className={styles.copyright2022Loggwork}
                      style={{cursor:'pointer'}}
                      onClick={()=>{navigate('/privacy')}}
                    >{`Privacy Policy `}</div>
                    <div className={styles.frameItem} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/cookie')}}>Cookies</div>
                    <div className={styles.frameItem} />
                  </div>
                  <div className={styles.contactUsParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Contact Us
                    </div>
                    <div className={styles.frameItem} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork} style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href='https://www.loggworks.co.uk/sitemap.xml'
                    }}>Sitemap</div>
                    <div className={styles.frameChild2} />
                  </div>
                </div>
              </div>
              <div className={styles.largeButtonsGroup}>
                <button
                  className={styles.largeButtons4}
                  style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder29.svg"
                  />
                  <b className={styles.label4}>Become a Professional</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder29.svg"
                  />
                </button>
                <button
                  className={styles.largeButtons4}
                  onClick={onLargeButtons1Click}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder30.svg"
                  />
                  <b className={styles.label4}>Post a Job</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder30.svg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMobileMenuProfProfessionalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuProfProfessionalPopup}
        >
          <MobileMenuProfProfessional1
            onClose={closeMobileMenuProfProfessionalPopup}
          />
        </PortalPopup>
      )}
 
    </>
  );
};

export default BecomeAprofessionalArticle;
