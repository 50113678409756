import { useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./ForProfessional.module.css";
import ToolBar from "./components/ToolBar";
import PrototypeFooter from "../../components/PrototypeFooter";
import { Autocomplete, TextField } from "@mui/material";
import mobnav from "../ConsumerHome.module.css";
import { useState } from "react";
import MobileFooter from "../../components/MobileFooter";
import PortalPopup from "../../components/PortalPopup";
import MenuMobile from "./components/MenuMobile";
import axios from "axios";
import ForProfessionalTab from "./components/ForProfessionalTab";
import ProfBanner from "./components/ProfBanner";
import SubsBanner from "./components/SubsBanner";
import Highlighter from "react-highlight-words";

const ForProfessional = () => {
  const [
    isMobileMenuProfProfessionalPopupOpen,
    setMobileMenuProfProfessionalPopupOpen,
  ] = useState(false);
  const openMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(true);
  }, []);

  const closeMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(false);
  }, []);
  const navigate = useNavigate();

  const onBLOGTextClick = useCallback(() => {
    navigate("/blog");
  }, [navigate]);
  const [business, setBusiness]=useState([])
  const [manage, setManage]=useState([])
  const [finance, setFinance]=useState([])
  const [spot, setSpot]=useState([])
  const [tips, setTips]=useState([])
  const [combined, setCombined]=useState([])
  const [how_to, setHowTo]=useState([])
  const [popular, setPopular]=useState([])
  const [feature_cons, setFeatureCons]=useState([])
  const [searchWord, setSearchWord] = useState("");

  const formatDateToWords = (dateString) => {
    const date = new Date(dateString);
    
    // Array of month names
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    // Get the components of the date
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    
    // Construct the formatted date string
    const formattedDate = `${month} ${day}, ${year}`;
    
    return formattedDate;
  };

  useEffect(() => {
    axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/for-professionals?populate=*')
    .then(response => {
      const blogsData = response.data.data;
      // Limiting to the first 3 blogs
      const featuredBlogs = blogsData.filter(blog => blog.attributes.category === 'featured');
      const popularBlogs = blogsData.filter(blog => blog.attributes.category === 'popular');
      const businessBlogs = blogsData.filter(blog => blog.attributes.category === 'Business Advice');
      const manageBlogs = blogsData.filter(blog => blog.attributes.category === 'Managing Your Business');
      const financeBlogs = blogsData.filter(blog => blog.attributes.category === 'Finance Advice');
      const spotBlogs = blogsData.filter(blog => blog.attributes.category === 'Spotlight');
      const howtoBlogs = blogsData.filter(blog => blog.attributes.category === 'How To');
      const tipsBlogs = blogsData.filter(blog => blog.attributes.category === 'tips');
      setPopular(popularBlogs.slice(0,3))
      setBusiness(businessBlogs.slice(0, 8));
      setManage(manageBlogs.slice(0,3))
      setFinance(financeBlogs.slice(0,3))
      setSpot(spotBlogs.slice(0,3))
      setHowTo(howtoBlogs.slice(0,3))
      setTips(tipsBlogs.slice(0,3))
      setFeatureCons(featuredBlogs.slice(0, 1)); // Limiting to the first featured blog
      const combinedBlogs = [
        ...featuredBlogs.slice(0, 1), // Limiting to the first featured blog
        ...popularBlogs.slice(0,3),
        ...howtoBlogs.slice(0, 3),
        ...tipsBlogs.slice(0, 3),
        ...financeBlogs.slice(0, 3),
        ...spotBlogs.slice(0, 3),
        ...manageBlogs.slice(0, 3),
        ...businessBlogs.slice(0, 3),
      ];
      
      setCombined(combinedBlogs)
    })
    .catch(error => {
      console.error('Error fetching blogs:', error);
    });
  }, []);

  const handleChange = (event, value) => {
    if (value) {
      combined.filter(blog => blog.attributes.BlogTitle === value).map(data=>{
         navigate(`/blog/for-professionals/${data.attributes.slug}`);
      })
     
    }
  };

  const onBusinessAdviceTextClick = useCallback(() => {
    navigate("/blog/for-professionals/business-advice");
  }, []);

  const onFrameContainer1Click = useCallback(() => {
    // Please sync "For Professionals | Business Advice" to the project
  }, []);

  const onManagingYourBusinessClick = useCallback(() => {
    navigate("/blog/for-professionals/managing-your-business");
  }, []);

  const onFrameContainer12Click = useCallback(() => {
    // Please sync "For Professionals | Managing Your Business" to the project
  }, []);

  const onFinanceAdviceTextClick = useCallback(() => {
    navigate("/blog/for-professionals/finance-advice");
  }, []);

  const onFrameContainer13Click = useCallback(() => {
    // Please sync "For Professionals | Finance Advice" to the project
  }, []);

  const onSpotlightTextClick = useCallback(() => {
    navigate("/blog/for-professionals/spotlight");
  }, []);

  const onFrameContainer14Click = useCallback(() => {
    // Please sync "For Professionals | Spotlight" to the project
  }, []);

  const onTipsTextClick = useCallback(() => {
    navigate("/blog/for-professionals/tips");
  }, []);

  const onFrameContainer15Click = useCallback(() => {
    // Please sync "For Professionals | Tips" to the project
  }, []);

  const onHowToTextClick = useCallback(() => {
    navigate("/blog/for-professionals/how-to");
  }, []);

  return (
    <div className={styles.forProfessional}>
      <div className={styles.forProfessionals}>
        <div className={styles.forProfessionalsInner}>
        <ToolBar/>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.blogGroup}>
            <div className={styles.blog1} onClick={onBLOGTextClick}>
              BLOG
            </div>
            <img
              className={styles.arrowrightIcon}
              alt=""
              src="/caretright.svg"
            />
            <div className={styles.forProfessionals2}>For Professionals</div>
          </div>
          <div className={styles.rectangleGroup}>
          <Autocomplete
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      options={combined.map((option) => option.attributes.BlogTitle)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search articles"
          onChange= {(e) => setSearchWord(e.target.value)}
          InputProps={{
            ...params.InputProps,
            type: "search",
            
          }}
        />
      )}
      
      onChange={handleChange}
      autoHighlight  

    />
          </div>
        </div>
        <div className={styles.forProfessionalsGroup}>
          <div className={styles.mostPopularArticles}>For Professionals</div>
          <div className={styles.elevateYourProfessionalContainer}>
            <p className={styles.elevateYourProfessional}>
              Elevate your professional journey and secure rewarding projects.
            </p>
            <p className={styles.elevateYourProfessional}>
              Take a look at our helpful articles on managing your business,
              finance, expert tips and more
            </p>
          </div>
         <ForProfessionalTab/>
        </div>
        {feature_cons.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
                 <div className={styles.rectangleContainer}>
          <img
            className={styles.rectangleIcon}
            alt=""
            src={blog.attributes.BlogImage.data.attributes.url}
          />
          <div className={styles.frameGroup}>
            <div className={styles.adaobiWilliamsSpeaksOnGettParent}>
              <b className={styles.adaobiWilliamsSpeaks}>
                <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
              </b>
              <div className={styles.loggworksHelpsYou}>
              <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
              </div>
            </div>
            <div className={styles.christinaWilliamsParent}>
              <div className={styles.christinaWilliams}>{blog.attributes.Author}</div>
              <div className={styles.ellipseDiv} />
              <div className={styles.january18th2024}>{formatDateToWords(blog.attributes.createdAt)}</div>
            </div>
            <div className={styles.frameContainer}>
              <div className={styles.forProfessionalsWrapper}>
                <div className={styles.forProfessionals4}>
                {blog.attributes.category}
                </div>
              </div>
              <div className={styles.minsRead}>{blog.attributes.duration} mins read</div>
            </div>
          </div>
        </div>
                </Link>
             ))}
       
        <div className={styles.mostPopularArticlesParent}>
          <div className={styles.mostPopularArticles}>
            Most Popular Articles
          </div>
          <div className={styles.frameDiv}>
            <div className={styles.groupParent1}>
            {popular.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
             <div className={styles.groupDiv}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild1}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent1}>
                  <div className={styles.blogGroup}>
                    <div className={styles.businessAdviceWrapper}>
                      <div className={styles.businessAdvice}>
                      {blog.attributes.category}
                      </div>
                    </div>
                    <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.navigatingChallengesLessonsParent}>
                    <b className={styles.navigatingChallengesLessons}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.embarkingOnA}
                    ><Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  /></div>
                  </div>
                  <div className={styles.christinaWilliamsGroup}>
                    <div className={styles.christinaWilliams1}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild1} />
                    <div className={styles.january18th20241}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
                </Link>
             ))}
              
            </div>
          </div>
        </div>
        <div className={styles.frameParent7}>
          <div className={styles.mostPopularArticlesParent}>
            <div
              className={styles.managingYourBusiness}
              onClick={onBusinessAdviceTextClick}
            >
              Business advice
            </div>
            <div className={styles.groupParent2}>
            {business.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
             <div className={styles.groupDiv}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild1}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent1}>
                  <div className={styles.blogGroup}>
                    <div className={styles.businessAdviceWrapper}>
                      <div className={styles.businessAdvice}>
                      {blog.attributes.category}
                      </div>
                    </div>
                    <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.navigatingChallengesLessonsParent}>
                    <b className={styles.navigatingChallengesLessons}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.embarkingOnA}
                    ><Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  /></div>
                  </div>
                  <div className={styles.christinaWilliamsGroup}>
                    <div className={styles.christinaWilliams1}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild1} />
                    <div className={styles.january18th20241}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
                </Link>
             ))}
            </div>
          </div>
          {
            business.length>3&&
            <div
            className={styles.smallButtonsWrapper}
            onClick={onBusinessAdviceTextClick}
          >
            <div className={styles.smallButtons1}>
              <div className={styles.viewMoreArticlesWrapper}>
                <div className={styles.viewMoreArticles}>
                  View More Articles
                </div>
              </div>
              <img className={styles.iconsadd} alt="" src="/caretright.svg" />
            </div>
          </div>
          }
          
        </div>
        <div className={styles.frameParent7}>
          <div className={styles.mostPopularArticlesParent}>
            <div
              className={styles.managingYourBusiness}
              onClick={onManagingYourBusinessClick}
            >
              managing your business
            </div>
            <div className={styles.groupParent2}>
            {manage.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
             <div className={styles.groupDiv}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild1}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent1}>
                  <div className={styles.blogGroup}>
                    <div className={styles.businessAdviceWrapper}>
                      <div className={styles.businessAdvice}>
                      {blog.attributes.category}
                      </div>
                    </div>
                    <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.navigatingChallengesLessonsParent}>
                    <b className={styles.navigatingChallengesLessons}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.embarkingOnA}
                    ><Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  /></div>
                  </div>
                  <div className={styles.christinaWilliamsGroup}>
                    <div className={styles.christinaWilliams1}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild1} />
                    <div className={styles.january18th20241}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
                </Link>
             ))}
            </div>
          </div>
          {
            manage.length>3&&
             <div
            className={styles.smallButtonsWrapper}
            onClick={onManagingYourBusinessClick}
          >
            <div className={styles.smallButtons1}>
              <div className={styles.viewMoreArticlesWrapper}>
                <div className={styles.viewMoreArticles}>
                  View More Articles
                </div>
              </div>
              <img className={styles.iconsadd} alt="" src="/caretright.svg" />
            </div>
          </div>
          }
         
        </div>
        <ProfBanner/>
        <div className={styles.frameParent7}>
          <div className={styles.mostPopularArticlesParent}>
            <div
              className={styles.managingYourBusiness}
              onClick={onFinanceAdviceTextClick}
            >
              finance advice
            </div>
            <div className={styles.groupParent2}>
            {finance.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
             <div className={styles.groupDiv}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild1}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent1}>
                  <div className={styles.blogGroup}>
                    <div className={styles.businessAdviceWrapper}>
                      <div className={styles.businessAdvice}>
                      {blog.attributes.category}
                      </div>
                    </div>
                    <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.navigatingChallengesLessonsParent}>
                    <b className={styles.navigatingChallengesLessons}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.embarkingOnA}
                    ><Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  /></div>
                  </div>
                  <div className={styles.christinaWilliamsGroup}>
                    <div className={styles.christinaWilliams1}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild1} />
                    <div className={styles.january18th20241}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
                </Link>
             ))}
            </div>
          </div>
          {
            finance.length>3&&
             <div
            className={styles.smallButtonsWrapper}
            onClick={onFinanceAdviceTextClick}
          >
            <div className={styles.smallButtons1}>
              <div className={styles.viewMoreArticlesWrapper}>
                <div className={styles.viewMoreArticles}>
                  View More Articles
                </div>
              </div>
              <img className={styles.iconsadd} alt="" src="/caretright.svg" />
            </div>
          </div>
          }
         
        </div>
        <div className={styles.frameParent7}>
          <div className={styles.mostPopularArticlesParent}>
            <div
              className={styles.managingYourBusiness}
              onClick={onSpotlightTextClick}
            >
              spotlight
            </div>
            <div className={styles.groupParent2}>
            {spot.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
             <div className={styles.groupDiv}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild1}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent1}>
                  <div className={styles.blogGroup}>
                    <div className={styles.businessAdviceWrapper}>
                      <div className={styles.businessAdvice}>
                      {blog.attributes.category}
                      </div>
                    </div>
                    <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.navigatingChallengesLessonsParent}>
                    <b className={styles.navigatingChallengesLessons}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.embarkingOnA}
                    ><Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  /></div>
                  </div>
                  <div className={styles.christinaWilliamsGroup}>
                    <div className={styles.christinaWilliams1}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild1} />
                    <div className={styles.january18th20241}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
                </Link>
             ))}
            </div>
          </div>
          {
            spot.length>3&&
            <div
            className={styles.smallButtonsWrapper}
            onClick={onSpotlightTextClick}
          >
            <div className={styles.smallButtons1}>
              <div className={styles.viewMoreArticlesWrapper}>
                <div className={styles.viewMoreArticles}>
                  View More Articles
                </div>
              </div>
              <img className={styles.iconsadd} alt="" src="/caretright.svg" />
            </div>
          </div>
          }
          
        </div>
        <SubsBanner/>
        <div className={styles.frameParent7}>
          <div className={styles.mostPopularArticlesParent}>
            <div
              className={styles.managingYourBusiness}
              onClick={onTipsTextClick}
            >
              Tips
            </div>
            <div className={styles.groupParent2}>
            {tips.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
             <div className={styles.groupDiv}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild1}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent1}>
                  <div className={styles.blogGroup}>
                    <div className={styles.businessAdviceWrapper}>
                      <div className={styles.businessAdvice}>
                      {blog.attributes.category}
                      </div>
                    </div>
                    <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.navigatingChallengesLessonsParent}>
                    <b className={styles.navigatingChallengesLessons}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.embarkingOnA}
                    ><Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  /></div>
                  </div>
                  <div className={styles.christinaWilliamsGroup}>
                    <div className={styles.christinaWilliams1}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild1} />
                    <div className={styles.january18th20241}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
                </Link>
             ))}
            </div>
          </div>
          {
            tips.length>3&&
            <div
            className={styles.smallButtonsWrapper}
            onClick={onTipsTextClick}
          >
            <div className={styles.smallButtons1}>
              <div className={styles.viewMoreArticlesWrapper}>
                <div className={styles.viewMoreArticles}>
                  View More Articles
                </div>
              </div>
              <img className={styles.iconsadd} alt="" src="/caretright.svg" />
            </div>
          </div>
          }
          
        </div>
        <div className={styles.forProfessionalsChild}>
          <div className={styles.mostPopularArticlesParent}>
            <div
              className={styles.managingYourBusiness}
              onClick={onHowToTextClick}
            >
              How To
            </div>
            <div className={styles.groupParent2}>
            {how_to.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
             <div className={styles.groupDiv}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild1}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent1}>
                  <div className={styles.blogGroup}>
                    <div className={styles.businessAdviceWrapper}>
                      <div className={styles.businessAdvice}>
                      {blog.attributes.category}
                      </div>
                    </div>
                    <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.navigatingChallengesLessonsParent}>
                    <b className={styles.navigatingChallengesLessons}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.embarkingOnA}
                    ><Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  /></div>
                  </div>
                  <div className={styles.christinaWilliamsGroup}>
                    <div className={styles.christinaWilliams1}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild1} />
                    <div className={styles.january18th20241}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
                </Link>
             ))}
            </div>
          </div>
        </div>
        <div className={styles.prototypeFooter}>
        <PrototypeFooter/>
        </div>
      </div>
      <div className={styles.forProfessionalsmob}>
      <div className={mobnav.navbarMobileclosed}>
          <img className={mobnav.layer2Icon} alt="" src="/layer-21.svg"
          style={{cursor:'pointer'}} onClick={()=>navigate('/')}  />
          <div className={mobnav.iconsaccountParent}>
            <div className={mobnav.iconsaccount} alt="" />
            <img className={mobnav.hamburgerIcon} alt="" src="/hamburger.svg" onClick={openMobileMenuProfProfessionalPopup} />
          </div>
        </div>
        <div className={styles.blogContainer}>
          <div className={styles.blog3} onClick={onBLOGTextClick}>BLOG</div>
          <img
            className={styles.caretrightIcon6}
            alt=""
            src="/caretright.svg"
          />
          <div className={styles.forProfessionals2}>For Professionals</div>
        </div>
        <div className={styles.rectangleParent22}>
        <Autocomplete
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      options={combined.map((option) => option.attributes.BlogTitle)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search articles"
          onChange= {(e) => setSearchWord(e.target.value)}
          InputProps={{
            ...params.InputProps,
            type: "search",
            
          }}
        />
      )}
      
      onChange={handleChange}
      autoHighlight  

    />
        </div>
        <div className={styles.frameParent56}>
          <div className={styles.forProfessionalsContainer}>
            <div className={styles.mostPopularArticles}>For Professionals</div>
            <div className={styles.elevateYourProfessional1}>
              Elevate your professional journey and secure rewarding projects.
              Take a look at our helpful articles on managing your business,
              finance, expert tips and more
            </div>
          </div>
         <ForProfessionalTab/>
        </div>
        {feature_cons.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
               <div className={styles.rectangleParent23}>
          <img
            className={styles.frameChild28}
            alt=""
            src={blog.attributes.BlogImage.data.attributes.url}
          />
          <div className={styles.rectangleParent24}>
            <div className={styles.groupChild43} />
            <div className={styles.frameParent60}>
              <div className={styles.frameParent61}>
                <div className={styles.forProfessionalsFrame}>
                  <div className={styles.forProfessionals7}>
                  {blog.attributes.category}
                  </div>
                </div>
                <div className={styles.minsRead22}>{blog.attributes.duration} mins read</div>
              </div>
              <div className={styles.frameParent62}>
                <div className={styles.adaobiWilliamsSpeaksOnGettContainer}>
                  <b className={styles.adaobiWilliamsSpeaks2}>
                  <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                  </b>
                  <div className={styles.loggworksHelpsYou16}>
                  <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                  </div>
                </div>
                <div className={styles.christinaWilliamsParent20}>
                  <div className={styles.christinaWilliams22}>
                  {blog.attributes.Author}
                  </div>
                  <div className={styles.ellipseDiv} />
                  <div className={styles.january18th202422}>
                  {formatDateToWords(blog.attributes.createdAt)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
                </Link>
             ))}
        
        <div className={styles.mostPopularArticlesGroup}>
          <div className={styles.mostPopularArticles}>
            Most Popular Articles
          </div>
          <div className={styles.frameParent63}>
          {popular.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
            <div className={styles.groupWrapper}>
              <div className={styles.rectangleParent25}>
                <div className={styles.groupChild44} />
                <img
                  className={styles.groupChild45}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent64}>
                  <div className={styles.frameParent65}>
                    <div className={styles.businessAdviceWrapper3}>
                      <div className={styles.businessAdvice6}>
                      {blog.attributes.category}
                      </div>
                    </div>
                    <div className={styles.minsRead23}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.navigatingChallengesLessonsContainer}>
                    <b className={styles.navigatingChallengesLessons2}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.loggworksHelpsYou17}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                      </div>
                  </div>
                  <div className={styles.christinaWilliamsParent21}>
                    <div className={styles.christinaWilliams23}>
                    {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild30} />
                    <div className={styles.january18th202423}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
            
          </div>
        </div>
        <div className={styles.mostPopularArticlesGroup}>
          <div className={styles.businessAdvice7}  
          onClick={onBusinessAdviceTextClick}>Business advice</div>
          <div className={styles.frameParent63}>
          {business.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
            <div className={styles.groupWrapper}>
              <div className={styles.rectangleParent25}>
                <div className={styles.groupChild44} />
                <img
                  className={styles.groupChild45}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent64}>
                  <div className={styles.frameParent65}>
                    <div className={styles.businessAdviceWrapper3}>
                      <div className={styles.businessAdvice6}>
                      {blog.attributes.category}
                      </div>
                    </div>
                    <div className={styles.minsRead23}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.navigatingChallengesLessonsContainer}>
                    <b className={styles.navigatingChallengesLessons2}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.loggworksHelpsYou17}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                      </div>
                  </div>
                  <div className={styles.christinaWilliamsParent21}>
                    <div className={styles.christinaWilliams23}>
                    {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild30} />
                    <div className={styles.january18th202423}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
          </div>
          {
            business.length>3&&
            <div className={styles.largeButtons2}
           onClick={onBusinessAdviceTextClick}>
            <b className={styles.viewMoreArticles5}>View More Articles</b>
            <img className={styles.iconsadd1} alt="" src="/iconsadd4.svg" />
          </div>
          }
          
        </div>
        <div className={styles.mostPopularArticlesGroup}>
          <div className={styles.businessAdvice7}
           onClick={onManagingYourBusinessClick}>managing your business</div>
          <div className={styles.frameParent63}>
          {manage.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
            <div className={styles.groupWrapper}>
              <div className={styles.rectangleParent25}>
                <div className={styles.groupChild44} />
                <img
                  className={styles.groupChild45}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent64}>
                  <div className={styles.frameParent65}>
                    <div className={styles.businessAdviceWrapper3}>
                      <div className={styles.businessAdvice6}>
                      {blog.attributes.category}
                      </div>
                    </div>
                    <div className={styles.minsRead23}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.navigatingChallengesLessonsContainer}>
                    <b className={styles.navigatingChallengesLessons2}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.loggworksHelpsYou17}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                      </div>
                  </div>
                  <div className={styles.christinaWilliamsParent21}>
                    <div className={styles.christinaWilliams23}>
                    {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild30} />
                    <div className={styles.january18th202423}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
          </div>
          {
            manage.length>3&&
             <div className={styles.largeButtons2}
           onClick={onManagingYourBusinessClick}>
            <b className={styles.viewMoreArticles5}>View More Articles</b>
            <img className={styles.iconsadd1} alt="" src="/iconsadd4.svg" />
          </div>
          }
         
        </div>
        <ProfBanner/>
        <div className={styles.mostPopularArticlesGroup}>
          <div className={styles.businessAdvice7}
           onClick={onFinanceAdviceTextClick}>finance advice</div>
          <div className={styles.frameParent63}>
          {finance.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
            <div className={styles.groupWrapper}>
              <div className={styles.rectangleParent25}>
                <div className={styles.groupChild44} />
                <img
                  className={styles.groupChild45}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent64}>
                  <div className={styles.frameParent65}>
                    <div className={styles.businessAdviceWrapper3}>
                      <div className={styles.businessAdvice6}>
                      {blog.attributes.category}
                      </div>
                    </div>
                    <div className={styles.minsRead23}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.navigatingChallengesLessonsContainer}>
                    <b className={styles.navigatingChallengesLessons2}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.loggworksHelpsYou17}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                      </div>
                  </div>
                  <div className={styles.christinaWilliamsParent21}>
                    <div className={styles.christinaWilliams23}>
                    {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild30} />
                    <div className={styles.january18th202423}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
          </div>
          {
            finance.length>3&&
            <div className={styles.largeButtons2}
           onClick={onFinanceAdviceTextClick}>
            <b className={styles.viewMoreArticles5}>View More Articles</b>
            <img className={styles.iconsadd1} alt="" src="/iconsadd4.svg" />
          </div>
          }
          
        </div>
        <div className={styles.mostPopularArticlesGroup}>
          <div className={styles.businessAdvice7}
           onClick={onSpotlightTextClick}>spotlight</div>
          <div className={styles.frameParent63}>
          {spot.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
            <div className={styles.groupWrapper}>
              <div className={styles.rectangleParent25}>
                <div className={styles.groupChild44} />
                <img
                  className={styles.groupChild45}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent64}>
                  <div className={styles.frameParent65}>
                    <div className={styles.businessAdviceWrapper3}>
                      <div className={styles.businessAdvice6}>
                      {blog.attributes.category}
                      </div>
                    </div>
                    <div className={styles.minsRead23}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.navigatingChallengesLessonsContainer}>
                    <b className={styles.navigatingChallengesLessons2}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.loggworksHelpsYou17}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                      </div>
                  </div>
                  <div className={styles.christinaWilliamsParent21}>
                    <div className={styles.christinaWilliams23}>
                    {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild30} />
                    <div className={styles.january18th202423}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
          </div>
          {
            spot.length>3&&
            <div className={styles.largeButtons2}
           onClick={onSpotlightTextClick}>
            <b className={styles.viewMoreArticles5}>View More Articles</b>
            <img className={styles.iconsadd1} alt="" src="/iconsadd4.svg" />
          </div>
          }
          
        </div>
        <SubsBanner/>
        <div className={styles.mostPopularArticlesGroup}>
          <div className={styles.businessAdvice7}
          onClick={onTipsTextClick}>Tips</div>
          <div className={styles.frameParent63}>
          {tips.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
            <div className={styles.groupWrapper}>
              <div className={styles.rectangleParent25}>
                <div className={styles.groupChild44} />
                <img
                  className={styles.groupChild45}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent64}>
                  <div className={styles.frameParent65}>
                    <div className={styles.businessAdviceWrapper3}>
                      <div className={styles.businessAdvice6}>
                      {blog.attributes.category}
                      </div>
                    </div>
                    <div className={styles.minsRead23}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.navigatingChallengesLessonsContainer}>
                    <b className={styles.navigatingChallengesLessons2}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.loggworksHelpsYou17}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                      </div>
                  </div>
                  <div className={styles.christinaWilliamsParent21}>
                    <div className={styles.christinaWilliams23}>
                    {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild30} />
                    <div className={styles.january18th202423}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
          </div>
          {
            tips.length>3&&
            <div className={styles.largeButtons2}
          onClick={onTipsTextClick}>
            <b className={styles.viewMoreArticles5}>View More Articles</b>
            <img className={styles.iconsadd1} alt="" src="/iconsadd4.svg" />
          </div>
          }
          
        </div>
        <div className={styles.mostPopularArticlesGroup}>
          <div className={styles.businessAdvice7}
          onClick={onHowToTextClick}>How To</div>
          <div className={styles.frameParent63}>
          {how_to.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
            <div className={styles.groupWrapper}>
              <div className={styles.rectangleParent25}>
                <div className={styles.groupChild44} />
                <img
                  className={styles.groupChild45}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent64}>
                  <div className={styles.frameParent65}>
                    <div className={styles.businessAdviceWrapper3}>
                      <div className={styles.businessAdvice6}>
                      {blog.attributes.category}
                      </div>
                    </div>
                    <div className={styles.minsRead23}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.navigatingChallengesLessonsContainer}>
                    <b className={styles.navigatingChallengesLessons2}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.loggworksHelpsYou17}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                      </div>
                  </div>
                  <div className={styles.christinaWilliamsParent21}>
                    <div className={styles.christinaWilliams23}>
                    {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild30} />
                    <div className={styles.january18th202423}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
          </div>
        </div>
        <MobileFooter/>
      </div>
      {isMobileMenuProfProfessionalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuProfProfessionalPopup}
        >
          <MenuMobile
            onClose={closeMobileMenuProfProfessionalPopup}
          />
        </PortalPopup>
      )}
    </div>
  );
};

export default ForProfessional;
