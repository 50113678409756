import { useState, useCallback, useRef, useEffect } from "react";
import Dropdown4 from "../components/Dropdown4";
import PortalPopup from "../components/PortalPopup";
import Dropdown5 from "../components/Dropdown5";
import MobileMenuConsumer1 from "../components/MobileMenuConsumer1";
import { useNavigate } from "react-router-dom";
import styles from "./Privacy.module.css";
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import MobileFooter from "../components/MobileFooter";
import LocationSearch from "../components/locationSearch.tsx";
import { FormControl, MenuItem, Select } from "@mui/material";

const Privacy = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(null);
  const open = Boolean(isDropdownOpen);
  const DivRef1 = useRef(null);
  const DivRef2 = useRef(null);
  const DivRef3 = useRef(null);
  const DivRef4 = useRef(null);
  const DivRef5 = useRef(null);
  const DivRef6 = useRef(null);
  const DivRef7 = useRef(null);
  const DivRef8 = useRef(null);
  const DivRef9 = useRef(null);
  const DivRef10 = useRef(null);
  //mobile
  const DivRef1m = useRef(null);
  const DivRef2m = useRef(null);
  const DivRef3m = useRef(null);
  const DivRef4m = useRef(null);
  const DivRef5m = useRef(null);
  const DivRef6m = useRef(null);
  const DivRef7m = useRef(null);
  const DivRef8m = useRef(null);
  const DivRef9m = useRef(null);
  const DivRef10m = useRef(null);
  const [value,setValue]=useState('Definitions')
 /* useEffect(() => {
    const handleScroll = () => {
      const div1Top = DivRef1.current.offsetTop;
      const div2Top = DivRef2.current.offsetTop;
      const div3Top = DivRef3.current.offsetTop;
      const div4Top = DivRef4.current.offsetTop;
      const div5Top = DivRef5.current.offsetTop;
      const div6Top = DivRef6.current.offsetTop;
      const div7Top = DivRef7.current.offsetTop;
      const div8Top = DivRef8.current.offsetTop;
      const div9Top = DivRef9.current.offsetTop;
      const div10Top = DivRef10.current.offsetTop;

      const scrollPosition = window.scrollY;

      if (scrollPosition >= div1Top && scrollPosition < div2Top) {
       setValue('Introduction')
      } 
      else if (scrollPosition >= div2Top && scrollPosition < div3Top) {
        setValue('Collection of your information')
      } 
      else if (scrollPosition >= div3Top && scrollPosition < div4Top) {
        setValue('Personal data')
      } 
      else if (scrollPosition >= div4Top && scrollPosition < div5Top) {
        setValue('Financial data')
      } else if (scrollPosition >= div5Top && scrollPosition < div6Top) {
        setValue('Use of your information')
      } else if (scrollPosition >= div6Top && scrollPosition < div7Top) {
        setValue('Disclosure of your information')
      } else if (scrollPosition >= div7Top && scrollPosition < div8Top) {
        setValue('By law or to protect rights')
      } else if (scrollPosition >= div8Top && scrollPosition < div9Top) {
        setValue('Third party service providers')
      } else if (scrollPosition >= div9Top && scrollPosition < div10Top) {
        setValue('Marketing communications')
      } else if (scrollPosition >= div10Top) {
        setValue('Security of your information')
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);*/


  const openDropdown = (event) => {
    setDropdownOpen(event.currentTarget);
  };
  const closeDropdown = () => {
    setDropdownOpen(null);
  };
  const [isDropdown1Open, setDropdown1Open] = useState(null);
  const open1 = Boolean(isDropdown1Open);
   const openDropdown1 = (event) => {
    setDropdown1Open(event.currentTarget);
  };
  const closeDropdown1 = () => {
    setDropdown1Open(null);
  };
  const [isMobileMenuConsumerPopupOpen, setMobileMenuConsumerPopupOpen] =
    useState(false);
  const navigate = useNavigate();

  const onPostAJobClick = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const onHowItWorksClick = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQsClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementTextClick = useCallback(() => {
    navigate("/agreement");
  }, [navigate]);

  const onHowItWorks1Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onContactUsText1Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQs1Click = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementText1Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/becometeam");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/partners");
  }, [navigate]);

  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/affiliate-program");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onLargeButtons1Click = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const onPageSelectorContainerClick = useCallback(() => {
    navigate("/professional");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHowItWorks2Click = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onFrameButtonClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onSmallButtonsClick = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const openMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(true);
  }, []);

  const closeMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(false);
  }, []);

  return (
    <>
      <div className={styles.agreement}>
        <div className={styles.careersdone}>
          <div className={styles.prototypeFooter}>
            <div className={styles.footer}>
              <div className={styles.download}>
                <div className={styles.downloadOurApps}>Download our apps</div>
                <img
                  className={styles.playstoreButtonIcon}
                  alt=""
                  src="/playstore-button9.svg"
                />
                <img
                  className={styles.appstoreButtonIcon}
                  alt=""
                  src="/appstore-button7.svg"
                />
              </div>
              <div className={styles.footerChild} />
              <img className={styles.layer2Icon} 
               style={{cursor:'pointer'}}
               onClick={()=>{navigate('/')}}
               alt="" src="/layer-281.svg" />
              <div className={styles.frameParent}>
                <div className={styles.homeownersParent}>
                  <b className={styles.homeowners}>Homeowners</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.postAJob} onClick={onPostAJobClick}>
                      Post a Job
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHowItWorksClick}
                    >
                      How it works
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onContactUsTextClick}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHelpFAQsClick}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.postAJob}
                      onClick={onUserAgreementTextClick}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.professionalsParent}>
                  <b className={styles.homeowners}>Professionals</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.signUp}
                    style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                    <div
                      className={styles.postAJob}
                      onClick={onHowItWorks1Click}
                    >
                      Blog
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onContactUsText1Click}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHelpFAQs1Click}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.postAJob}
                      onClick={onUserAgreementText1Click}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.loggworksParent}>
                  <b className={styles.homeowners}>Loggworks</b>
                  <div className={styles.postAJobParent}>
                    <div
                      className={styles.postAJob}
                      onClick={onAboutUsTextClick}
                    >
                      About us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onCareersTextClick}
                    >
                      Careers
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onPartnersTextClick}
                    >
                      Partners
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onInfluencerProgramTextClick}
                    >
                      Affiliate program
                    </div>
                    <div className={styles.blog}>Blog</div>
                  </div>
                </div>
                <div className={styles.loggworksGroup}>
                  <div className={styles.loggworks1}>
                    <b className={styles.followUs}>Follow us</b>
                  </div>
                  <div className={styles.groupParent}>
                    <div className={styles.frameGroup}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://web.facebook.com/Loggworks?_rdc=1&_rdr', '_blank')}>
                      <img
                        className={styles.frameIcon}
                        alt=""
                        src="/frame20.svg"
                      />
                      <div className={styles.facebook}>Facebook</div>
                    </div>
                    <div className={styles.frameContainer}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://twitter.com/loggworks', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame211.svg"
                      />
                      <div className={styles.twitter}>Twitter</div>
                    </div>
                    <div className={styles.groupDiv}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.instagram.com/loggworks/?utm_medium=copy_link', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame22.svg"
                      />
                      <div className={styles.twitter}>Instagram</div>
                    </div>
                    <div className={styles.frameParent1}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame23.svg"
                      />
                      <div className={styles.twitter}>YouTube</div>
                    </div>
                    <div className={styles.mdilinkedinParent}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open(' https://www.linkedin.com/company/loggworks', '_blank')}>
                      <img
                        className={styles.mdilinkedinIcon}
                        alt=""
                        src="/mdilinkedin1.svg"
                      />
                      <div className={styles.linkedin}>LinkedIn</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.copyright2022LoggworkLtdAParent}>
                <div className={styles.copyright2022Loggwork}>
                  ©Copyright 2024 loggworks  Ltd.  All Rights Reserved
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/terms')}}>
                      Terms of Use
                    </div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div
                      className={styles.copyright2022Loggwork}
                      style={{cursor:'pointer'}}
                      onClick={()=>{navigate('/privacy')}}
                    >{`Privacy Policy `}</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                     style={{cursor:'pointer'}}
                     onClick={()=>{navigate('/cookie')}}>Cookies</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.contactUsParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Contact Us
                    </div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork} style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href='https://www.loggworks.co.uk/sitemap.xml'
                    }}>Sitemap</div>
                    <div className={styles.frameChild1} />
                  </div>
                </div>
              </div>
              <div className={styles.largeButtonsParent}>
                <button
                  className={styles.largeButtons}
                  style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder38.svg"
                  />
                  <b className={styles.label}>Become a Professional</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder38.svg"
                  />
                </button>
                <button
                  className={styles.largeButtons}
                  onClick={onLargeButtons1Click}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder39.svg"
                  />
                  <b className={styles.label}>Post a Job</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder39.svg"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className={styles.frameParent2}>
            <div className={styles.frameWrapper}>
              <div className={styles.frameWrapper1}>
                <div className={styles.serviceConsumerUserAgreemenParent}>
                  <b className={styles.serviceConsumerUser}>
                  PRIVACY NOTICE
                  </b>
                  <div className={styles.thisAgreementBinds}>
                    This agreement binds Service providers and Loggworks.
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameWrapper2}>
              <div className={styles.frameParent3}>
                <div className={styles.frameParent4}>
                  <div className={styles.introductionParent}>
                  <div className={styles.weMayCollect}>
                    <Content/>
                  </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <nav className={styles.navigation}>
            <div className={styles.navigation1}>
              <img
                className={styles.navigationChild}
                alt=""
                src="/frame-665223.svg"
              />
              <div className={styles.navigationItem} />
              <div className={styles.tabSwitch}>
                <div
                  className={styles.pageSelector}
                  onClick={onPageSelectorContainerClick}
                >
                  <div className={styles.descriptionParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Professional
                    </div>
                    <div className={styles.selector} />
                  </div>
                </div>
                <div className={styles.pageSelector1}
                 style={{cursor:'pointer'}}
                 onClick={()=>{navigate('/')}}
                >
                  <div className={styles.descriptionParent}>
                    <b className={styles.copyright2022Loggwork}>Consumer</b>
                    <div className={styles.selector} />
                  </div>
                </div>
              </div>
              <LocationSearch/>
            </div>
            
          </nav>
        </div>
        <div className={styles.homeMobile}>
          <div className={styles.navbarMobileclosed}>
            <img className={styles.layer2Icon1} alt="" src="/layer-28.svg" 
            style={{cursor:'pointer'}} onClick={()=>navigate('/')} />
            <div className={styles.iconsaccountGroup}>
              <img
                className={styles.iconsaccount1}
                alt=""
                src="/iconsaccount6.svg"
              />
              <img
                className={styles.hamburgerIcon}
                alt=""
                src="/hamburger.svg"
                onClick={openMobileMenuConsumerPopup}
              />
            </div>
          </div>
          <div className={styles.homeMobileInner}>
            <div className={styles.frameWrapper3}>
              <div className={styles.serviceConsumerUserAgreemenGroup}>
                <b className={styles.serviceConsumerUser1}>
                PRIVACY NOTICE
                </b>
                <div className={styles.thisAgreementBinds1}>
                  This agreement binds Service providers and Loggworks.
                </div>
              </div>
            </div>
          </div>
         
          <div className={styles.frameParent7}>
            <div className={styles.introductionGroup}>
              <div className={styles.thisBusinessRespectsContainer1}>
                <div className={styles.thisBusinessRespects}>
                  <Content/>
                </div>
                </div>
            </div>
          </div>
          <MobileFooter/>
        </div>
      </div>
      {isMobileMenuConsumerPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuConsumerPopup}
        >
          <MobileMenuConsumer1 onClose={closeMobileMenuConsumerPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default Privacy;


const Content=()=>{
return(
 <>
 <p>Last updated March 02, 2022</p>
<p>This privacy notice for LOGGWORKS ("Company," "we," "us," or "our"),
describes how and why we might collect, store, use, and/or share
("process") your information when you use our services ("Services"),
such as when you:</p>
<ul>
<li><p>Visit our website at https://www.loggworks.co.uk, or any website
of ours that links to this privacy notice</p></li>
<li><p>Engage with us in other related ways - including any sales,
marketing, or events</p></li>
</ul>
<p><strong>Questions or concerns?</strong> Reading this privacy notice
will help you understand your privacy rights and choices. If you do not
agree with our policies and practices, please do not use our Services.
If you still have any questions or concerns, please contact us at <a
href="mailto:info@loggworks.co.uk"><u>info@loggworks.co.uk</u></a>.</p>
<br></br>
<p><strong>SUMMARY OF KEY POINTS</strong></p>
<br></br>
<p><em>This summary provides key points from our privacy notice, but you
can find out more details about</em></p>
<p><em>any of these topics by clicking the link following each key point
or by using our table of contents</em></p>
<p><em>below to find the section you are looking for.</em></p>
<p><strong>What personal information do we process?</strong> When you
visit, use, or navigate our Services, we may process personal
information depending on how you interact with LOGGWORKS and the
Services, the choices you make, and the products and features you
use.</p>
<p><strong>Do we process any sensitive personal information?</strong> We
may process sensitive personal information when necessary with your
consent or as otherwise permitted by applicable law.</p>
<p><strong>Do you receive any information from third parties?</strong>
We do not receive any information from third parties.</p>
<p><strong>How do you process my information?</strong> We process your
information to provide, improve, and administer our Services,
communicate with you, for security and fraud prevention, and to comply
with law. We may also process your information for other purposes with
your consent. We process your information only when we have a valid
legal reason to do so.</p>
<p><strong>In what situations and with which parties do we share
personal information?</strong> We may share information in specific
situations and with specific third parties.</p>
<p><strong>How do we keep your information safe?</strong> We have
organizational and technical processes and procedures in place to
protect your personal information. However, no electronic transmission
over the internet or information storage technology can be guaranteed to
be 100% secure, so we cannot promise or guarantee that hackers,
cybercriminals, or other unauthorized third parties will not be able to
defeat our security and improperly collect, access, steal, or modify
your information.</p>
<p><strong>What are your rights?</strong> Depending on where you are
located geographically, the applicable privacy law may mean you have
certain rights regarding your personal information.</p>
<p><strong>How do I exercise my rights?</strong> The easiest way to
exercise your rights is by contacting us. We will consider and act upon
any request in accordance with applicable data protection laws</p>
<br></br>
<p><strong>TABLE OF CONTENTS</strong></p>
<br></br>
<p>1. WHAT INFORMATION DO WE COLLECT?</p>
<p>2. HOW DO WE PROCESS YOUR INFORMATION?</p>
<p>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</p>
<p>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</p>
<p>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
<p>6. HOW LONG DO WE KEEP YOUR INFORMATION?</p>
<p>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
<p>8. DO WE COLLECT INFORMATION FROM MINORS?</p>
<p>9. WHAT ARE YOUR PRIVACY RIGHTS?</p>
<p>10. CONTROLS FOR DO-NOT-TRACK FEATURES</p>
<p>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
<p>12. DO WE MAKE UPDATES TO THIS NOTICE?</p>
<p>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>
<p>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
YOU?</p>
<br></br>
<p><strong>1. WHAT INFORMATION DO WE COLLECT?</strong></p>
<br></br>
<p><strong>Personal information you disclose to us</strong></p>
<p><em>In Short: We collect personal information that you provide to
us.</em></p>
<p>We collect personal information that you voluntarily provide to us
when you register on the Services, express an interest in obtaining
information about us or our products and Services, when you participate
in activities on the Services, or otherwise when you contact us.</p>
<p><strong>Personal Information Provided by You.</strong> The personal
information that we collect depends on the context of your interactions
with us and the Services, the choices you make, and the products and
features you use. The personal information we collect may include the
following:</p>
<ul>
<li><p>names</p></li>
<li><p>phone numbers</p></li>
<li><p>email addresses</p></li>
<li><p>mailing addresses</p></li>
<li><p>usernames</p></li>
<li><p>passwords</p></li>
<li><p>contact preferences</p></li>
<li><p>contact or authentication data</p></li>
<li><p>billing addresses</p></li>
<li><p>debit/credit card numbers</p></li>
</ul>
<p><strong>Sensitive Information.</strong> When necessary, with your
consent or as otherwise permitted by applicable law, we process the
following categories of sensitive information:</p>
<ul>
<li><p>financial data</p></li>
<li><p>biometric data</p></li>
<li><p>information revealing trade union membership</p></li>
<li><p>social security numbers or other government identifiers</p></li>
</ul>
<p><strong>Payment Data.</strong> We may collect data necessary to
process your payment if you make booking, make deposit, change
membership plan, and promote proflle such as your payment instrument
number (such as a credit card number), and the security code associated
with your payment instrument. All payment data is stored by Stripe. You
may find their privacy notice link(s) here:</p>
<p><a
href="https://stripe.com/gb/privacy"><u>https://stripe.com/gb/privacy</u></a>.</p>
<p>All personal information that you provide to us must be true,
complete, and accurate, and you must notify us of any changes to such
personal information.</p>
<p><strong>Information automatically collected</strong></p>
<p><em>In Short: Some information - such as your Internet Protocol (IP)
address and/or browser and device characteristics - is collected
automatically when you visit our Services.</em></p>
<p>We automatically collect certain information when you visit, use, or
navigate the Services. This information does not reveal your specific
identity (like your name or contact information) but may include device
and usage information, such as your IP address, browser and device
characteristics, operating system, language preferences, referring URLs,
device name, country, location, information about how and when you use
our Services, and other technical information. This information is
primarily needed to maintain the security and operation of our Services,
and for our internal analytics and reporting purposes.</p>
<p>Like many businesses, we also collect information through cookies and
similar technologies.</p>
<p>The information we collect includes:</p>
<ul>
<li><p><em>Log and Usage Data</em>. Log and usage data is
service-related, diagnostic, usage, and performance information our
servers automatically collect when you access or use our Services and
which we record in log files. Depending on how you interact with us,
this log data may include your IP address, device information, browser
type, and settings and information about your activity in the Services
(such as the date/time stamps associated with your usage, pages and
files viewed, searches, and other actions you take such as which
features you use), device event information (such as system activity,
error reports (sometimes called "crash dumps"), and hardware
settings).</p></li>
<li><p><em>Device Data.</em> We collect device data such as information
about your computer, phone, tablet, or other device you use to access
the Services. Depending on the device used, this device data may include
information such as your IP address (or proxy server), device and
application identification numbers, location, browser type, hardware
model, Internet service provider and/or mobile carrier, operating
system, and system configuration information.</p></li>
<li><p><em>Location Data.</em> We collect location data such as
information about your device's location, which can be either precise or
imprecise. How much information we collect depends on the type and
settings of the device you use to access the Services. For example, we
may use GPS and other technologies to collect geolocation data that
tells us your current location (based on your IP address). You can opt
out of allowing us to collect this information either by refusing access
to the information or by disabling your Location setting on your device.
However, if you choose to opt out, you may not be able to use certain
aspects of the Services.</p></li>
</ul>
<br></br>
<p><strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong></p>
<br></br>
<p><em>In Short: We process your information to provide, improve, and
administer our Services, communicate with you, for security and fraud
prevention, and to comply with law. We may also process your information
for other purposes with your consent.</em></p>
<p><strong>We process your personal information for a variety of
reasons, depending on how you interact with our Services,
including:</strong></p>
<ul>
<li><p><strong>To facilitate account creation and authentication and
otherwise manage user accounts.</strong> We may process your information
so you can create and log in to your account, as well as keep your
account in working order.</p></li>
<li><p><strong>To deliver and facilitate delivery of services to the
user.</strong> We may process your information to provide you with the
requested service.</p></li>
<li><p><strong>To respond to user inquiries/offer support to
users.</strong> We may process your information to respond to your
inquiries and solve any potential issues you might have with the
requested service.</p></li>
<li><p><strong>To send administrative information to you.</strong> We
may process your information to send you details about our products and
services, changes to our terms and policies, and other similar
information.</p></li>
<li><p><strong>To fulfil and manage your orders.</strong> We may process
your information to fulfil and manage your orders, payments, returns,
and exchanges made through the Services.</p></li>
<li><p><strong>To enable user-to-user communications.</strong> We may
process your information if you choose to use any of our offerings that
allow for communication with another user.</p></li>
<li><p><strong>To request feedback.</strong> We may process your
information when necessary to request feedback and to contact you about
your use of our Services.</p></li>
<li><p><strong>To send you marketing and promotional
communications.</strong> We may process the personal information you
send to us for our marketing purposes, if this is in accordance with
your marketing preferences. You can opt out of our marketing emails at
any time. For more information, see "WHAT ARE YOUR PRIVACY RIGHTS?"
below).</p></li>
<li><p><strong>To deliver targeted advertising to you.</strong> We may
process your information to develop and display personalized content and
advertising tailored to your interests, location, and more.</p></li>
<li><p><strong>To protect our Services</strong>. We may process your
information as part of our efforts to keep our Services safe and secure,
including fraud monitoring and prevention.</p></li>
<li><p><strong>To identify usage trends.</strong> We may process
information about how you use our Services to better understand how they
are being used so we can improve them.</p></li>
<li><p><strong>To determine the effectiveness of our marketing and
promotional campaigns</strong>. We may process your information to
better understand how to provide marketing and promotional campaigns
that are most relevant to you.</p></li>
<li><p><strong>To save or protect an individual's vital
interest.</strong> We may process your information when necessary to
save or protect an individual's vital interest, such as to prevent
harm.</p></li>
</ul>
<br></br>
<p><strong>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
INFORMATION?</strong></p>
<br></br>
<p><em>In Short: We only process your personal information when we
believe it is necessary and we have a valid legal reason (i.e., legal
basis) to do so under applicable law, like with your consent, to comply
with laws, to provide you with services to enter into or fulfil our
contractual obligations, to protect your rights, or to fulfil our
legitimate business interests.</em></p>
<p>The General Data Protection Regulation (GDP) and UK GDPR require us
to explain the valid legal bases we rely on in order to process your
personal information. As such, we may rely on the following legal bases
to process your personal information:</p>
<ul>
<li><p><strong>Consent.</strong> We may process your information if you
have given us permission (i.e., consent) to use your personal
information for a specific purpose. You can withdraw your consent at any
time.</p></li>
<li><p><strong>Performance of a Contract.</strong> We may process your
personal information when we believe it is necessary to fulfil our
contractual obligations to you, including providing our Services or at
your request prior to entering into a contract with you.</p></li>
<li><p><strong>Legitimate Interests.</strong> We may process your
information when we believe it is reasonably necessary to achieve our
legitimate business interests and those interests do not outweigh your
interests and fundamental rights and freedoms. For example, we may
process your personal information for some of the purposes described in
order to:</p>
<ul>
<li><p>Send users information about special offers and discounts on our
products and services</p></li>
<li><p>Develop and display personalized and relevant advertising content
for our users</p></li>
<li><p>Analyse how our services are used so we can improve them to
engage and retain users</p></li>
<li><p>Support our marketing activities</p></li>
<li><p>Diagnose problems and/or prevent fraudulent activities</p></li>
<li><p>Understand how our users use our products and services so we can
improve user experience</p></li>
</ul></li>
</ul>
<ul>
<li><p><strong>Legal Obligations.</strong> We may process your
information where we believe it is necessary for compliance with our
legal obligations, such as to cooperate with a law enforcement body or
regulatory agency, exercise or defend our legal rights, or disclose your
information as evidence in litigation in which we are involved.</p></li>
<li><p><strong>Vital Interests.</strong> We may process your information
where we believe it is necessary to protect your vital interests or the
vital interests of a third party, such as situations involving potential
threats to the safety of any person.</p></li>
</ul>
<br></br>
<p><strong>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
INFORMATION?</strong></p>
<br></br>
<p><em>In Short: We may share information in specific situations
described in this section and/or with the following third
parties.</em></p>
<p>We may need to share your personal information in the following
situations:</p>
<ul>
<li><p><strong>Business Transfers.</strong> We may share or transfer
your information in connection with, or during negotiations of, any
merger, sale of company assets, financing, or acquisition of all or a
portion of our business to another company.</p></li>
<li><p><strong>When we use Google Maps Platform APIs.</strong> We may
share your information with certain Google Maps Platform APIs (e.g.,
Google Maps API, Places API). To find out more about Google's Privacy
Policy, please refer to this link. We obtain and store on your device
('cache') your location. You may revoke your consent anytime by
contacting us at the contact details provided at the end of this
document.</p></li>
<li><p><strong>Other Users.</strong> When you share personal information
(for example, by posting comments, contributions, or other content to
the Services) or otherwise interact with public areas of the Services,
such personal information may be viewed by all users and may be publicly
made available outside the Services in perpetuity. Similarly, other
users will be able to view descriptions of your activity, communicate
with you within our Services, and view your profile.</p></li>
</ul>
<br></br>
<p><strong>5. DO WE USE COOKIES AND OTHER TRACKING
TECHNOLOGIES?</strong></p>
<br></br>
<p><em>In Short: We may use cookies and other tracking technologies to
collect and store your information.</em></p>
<p>We may use cookies and similar tracking technologies (like web
beacons and pixels) to access or store information. Specific information
about how we use such technologies and how you can refuse certain
cookies is set out in our Cookie Notice.</p>
<br></br>
<p><strong>6. HOW LONG DO WE KEEP YOUR INFORMATION?</strong></p>
<br></br>
<p><em>In Short: We keep your information for as long as necessary to
fulfil the purposes outlined in this privacy notice unless otherwise
required by law.</em></p>
<p>We will only keep your personal information for as long as it is
necessary for the purposes set out in this privacy notice, unless a
longer retention period is required or permitted by law (such as tax,
accounting, or other legal requirements). No purpose in this notice will
require us keeping your personal information for longer than the period
of time in which users have an account with us.</p>
<p>When we have no ongoing legitimate business need to process your
personal information, we will either delete or anonymize such
information, or, if this is not possible (for example, because your
personal information has been stored in backup archives), then we will
securely store your personal information and isolate it from any further
processing until deletion is possible.</p>
<br></br>
<p><strong>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong></p>
<br></br>
<p><em>In Short: We aim to protect your personal information through a
system of organizational and technical security measures.</em></p>
<p>We have implemented appropriate and reasonable technical and
organizational security measures designed to protect the security of any
personal information we process. However, despite our safeguards and
efforts to secure your information, no electronic transmission over the
Internet or information storage technology can be guaranteed to be 100%
secure, so we cannot promise or guarantee that hackers, cybercriminals,
or other unauthorized third parties will not be able to defeat our
security and improperly collect, access, steal, or modify your
information. Although we will do our best to protect your personal
information, transmission of personal information to and from our
Services is at your own risk. You should only access the Services within
a secure environment.</p>
<br></br>
<p><strong>8. DO WE COLLECT INFORMATION FROM MINORS?</strong></p>
<br></br>
<p><em>In Short: We do not knowingly collect data from or market to
children under 18 years of age.</em></p>
<p>We do not knowingly solicit data from or market to children under 18
years of age. By using the Services, you represent that you are at least
18 or that you are the parent or guardian of such a minor and consent to
such minor dependent's use of the Services. If we learn that personal
information from users less than 18 years of age has been collected, we
will deactivate the account and take reasonable measures to promptly
delete such data from our records. If you become aware of any data we
may have collected from children under age 18, please contact us at <a
href="mailto:info@loggworks.co.uk"><u>info@loggworks.co.uk</u></a>.</p>
<br></br>
<p><strong>9. WHAT ARE YOUR PRIVACY RIGHTS?</strong></p>
<br></br>
<p><em>In Short: In some regions, such as the European Economic Area
(EA) and United Kingdom (UK), you have rights that allow you greater
access to and control over your personal information. You may review,
change, or terminate your account at any time.</em></p>
<p>In some regions (like the EA and UK), you have certain rights under
applicable data protection laws. These may include the right (i) to
request access and obtain a copy of your personal information, (ii) to
request rectification or erasure; (lil) to restrict the processing of
your personal information; and (iv) if applicable, to data portability.
In certain circumstances, you may also have the right to object to the
processing of your personal information. You can make such a request by
contacting us by using the contact details provided in the section "HOW
CAN YOU CONTACT US ABOUT THIS NOTICE?" below.</p>
<p>We will consider and act upon any request in accordance with
applicable data protection laws.</p>
<p>If you are located in the EA or UK and you believe we are unlawfully
processing your personal information, you also have the right to
complain to your local data protection supervisory authority. You can
find their contact details here:
htps://ec.europa.eu/justice/dataprotection/bodies/authorities/index
en.htm.</p>
<p>If you are located in Switzerland, the contact details for the data
protection authorities are available here:
https://www.edoeb.admin.ch/edoeb/en/home.html.</p>
<p><strong><u>Withdrawing your consent:</u></strong> If we are relying
on your consent to process your personal information, you have the right
to withdraw your consent at any time. You can withdraw your consent at
any time by contacting us by using the contact details provided in the
section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below or updating
your preferences.</p>
<p>However, please note that this will not affect the lawfulness of the
processing before its withdrawal, nor will it affect the processing of
your personal information conducted in reliance on lawful processing
grounds other than consent.</p>
<p><strong><u>Opting out of marketing and promotional
communications:</u></strong> You can unsubscribe from our marketing and
promotional communications at any time by clicking on the unsubscribe
link in the emails that we send, or by contacting us using the details
provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
below. You will then be removed from the marketing lists - however, we
may still communicate with you, for example to send you service-related
messages that are necessary for the administration and use of your
account, to respond to service requests, or for other non-marketing
purposes.</p>
<p><strong>Account Information</strong></p>
<p>If you would at any time like to review or change the information in
your account or terminate your account, you can:</p>
<ul>
<li><p>Log in to your account settings and update your user
account.</p></li>
</ul>
<p>Upon your request to terminate your account, we will deactivate or
delete your account and information from our active databases. However,
we may retain some information in our files to prevent fraud,
troubleshoot problems, assist with any investigations, enforce our legal
terms and/or comply with applicable legal requirements.</p>
<p><strong><u>Cookies and similar technologies:</u></strong> Most Web
browsers are set to accept cookies by default. If you prefer, you can
usually choose to set your browser to remove cookies and to reject
cookies. If you choose to remove cookies or reject cookies, this could
affect certain features or services of our Services. To opt out of
interest-based advertising by advertisers on our Services visit.</p>
<p>If you have questions or comments about your privacy rights, you may
email us at <a
href="mailto:info@loggworks.co.uk"><u>info@loggworks.co.uk</u></a>.</p>
<br></br>
<p><strong>10. CONTROLS FOR DO-NOT-TRACK FEATURES</strong></p>
<br></br>
<p>Most web browsers and some mobile operating systems and mobile
applications include a Do-Not-Track ("DNT") feature or setting you can
activate to signal your privacy preference not to have data about your
online browsing activities monitored and collected. At this stage no
uniform technology standard for recognizing and implementing DNT signals
has been finalized. As such, we do not currently respond to DNT browser
signals or any other mechanism that automatically communicates your
choice not to be tracked online. If a standard for online tracking is
adopted that we must follow in the future, we will inform you about that
practice in a revised version of this privacy notice.</p>
<br></br>
<p><strong>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY
RIGHTS?</strong></p>
<br></br>
<p><em>In Short: Yes, if you are a resident of California, you are
granted specific rights regarding access to your personal
information.</em></p>
<p>California Civil Code Section 1798.83, also known as the "Shine The
Light" law, permits our users who are California residents to request
and obtain from us, once a year and free of charge, information about
categories of personal information (if any) we disclosed to third
parties for direct marketing purposes and the names and addresses of all
third parties with which we shared personal information in the
immediately preceding calendar year. If you are a California resident
and would like to make such a request, please submit your request in
writing to us using the contact information provided below.</p>
<p>If you are under 18 years of age, reside in California, and have a
registered account with Services, you have the right to request removal
of unwanted data that you publicly post on the Services. To request
removal of such data, please contact us using the contact information
provided below and include the email address associated with your
account and a statement that you reside in California. We will make sure
the data is not publicly displayed on the Services, but please be aware
that the data may not be completely or comprehensively removed from all
our systems (e.g., backups, etc.).</p>
<br></br>
<p><strong>12. DO WE MAKE UPDATES TO THIS NOTICE?</strong></p>
<br></br>
<p><em>In Short: Yes, we will update this notice as necessary to stay
compliant with relevant laws.</em></p>
<p>We may update this privacy notice from time to time. The updated
version will be indicated by an updated "Revised" date and the updated
version will be effective as soon as it is accessible. If we make
material changes to this privacy notice, we may notify you either by
prominently posting a notice of such changes or by directly sending you
a notification. We encourage you to review this privacy notice
frequently to be informed of how we are protecting your information.</p>
<br></br>
<p><strong>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong></p>
<br></br>
<p>If you have questions or comments about this notice, you may email us
at info@loggworks.co.uk or by post to:</p>
<p>LOGGWORKS</p>
<p>Hyde</p>
<p>SK14 3QL</p>
<p>United Kingdom</p>
<br></br>
<p><strong>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
FROM YOU?</strong></p>
<br></br>
<p>Based on the applicable laws of your country, you may have the right
to request access to the personal</p>
<p>information we collect from you, change that information, or delete
it in some circumstances. To request to review, update, or delete your
personal information, please email us at <a
href="mailto:info@loggworks.co.uk"><u>info@loggworks.co.uk</u></a></p>
 </>
)
}