import { useState, useCallback } from "react";
import Dropdown4 from "../components/Dropdown4";
import PortalPopup from "../components/PortalPopup";
import Dropdown5 from "../components/Dropdown5";
import { useNavigate } from "react-router-dom";
import styles from "./ProfessionalHiringGuideArti1.module.css";
import LocationSearch from "../components/locationSearch.tsx";
const ProfessionalHiringGuideArti1 = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdown1Open, setDropdown1Open] = useState(false);
  const navigate = useNavigate();

  const onPostAJob1Click = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onHowItWorksClick = useCallback(() => {
    navigate("/consumerhow-it-works");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    navigate("/consumerhelpcontactdone");
  }, [navigate]);

  const onHelpFAQsClick = useCallback(() => {
    navigate("/consumerhelpfaqdone");
  }, [navigate]);

  const onUserAgreementTextClick = useCallback(() => {
    navigate("/careersdone");
  }, [navigate]);

  const onHowItWorks1Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onContactUsText1Click = useCallback(() => {
    navigate("/consumerhelpcontact");
  }, [navigate]);

  const onHelpFAQs1Click = useCallback(() => {
    navigate("/professionalhelpfaq");
  }, [navigate]);

  const onUserAgreementText1Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-usdone");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/consumerhelpfaqdone1");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/professionalhelpcontactdone");
  }, [navigate]);

  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/professionalhelpcontact2");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onLargeButtons1Click = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onPageSelectorContainerClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHowItWorks2Click = useCallback(() => {
    navigate("/consumerhow-it-works");
  }, [navigate]);

  const openDropdown = useCallback(() => {
    setDropdownOpen(true);
  }, []);

  const closeDropdown = useCallback(() => {
    setDropdownOpen(false);
  }, []);

  const openDropdown1 = useCallback(() => {
    setDropdown1Open(true);
  }, []);

  const closeDropdown1 = useCallback(() => {
    setDropdown1Open(false);
  }, []);

  const onFrameButtonClick = useCallback(() => {
    navigate("/consumerhelpfaqdone");
  }, [navigate]);

  const onSmallButtonsClick = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onHiringGuideText1Click = useCallback(() => {
    navigate("/childcareguid");
  }, [navigate]);

  return (
    <>
      <div className={styles.professionalhiringGuidearti}>
        <div className={styles.container}>
          <div className={styles.merge}>
            <div className={styles.titleImg}>
              <div className={styles.tipsOnHiringBabysittersParent}>
                <div className={styles.tipsOnHiring}>
                  Tips on hiring Babysitters.
                </div>
                <div className={styles.articleCategory}>
                  <div className={styles.category}>BABYSITTERS</div>
                </div>
              </div>
              <img
                className={styles.titleImgChild}
                alt=""
                src="/rectangle-123081@2x.png"
              />
            </div>
            <div className={styles.contentArea}>
              <div className={styles.findingABabysitterContainer}>
                <p className={styles.findingABabysitter}>
                  Finding a babysitter you trust to care for your children can
                  be a daunting task. Here are some tips to help you find the
                  right person for the job:
                </p>
                <p className={styles.findingABabysitter}>&nbsp;</p>
                <ul className={styles.lookForABabysitterWithExp}>
                  <li className={styles.checkReferencesAskForRefe}>
                    <span className={styles.lookForA}>
                      Look for a babysitter with experience
                    </span>
                    <span>
                      : It's important to find a babysitter who has experience
                      caring for children, especially if you have younger
                      children or children with special needs. This can give you
                      peace of mind knowing that the babysitter has the skills
                      and knowledge necessary to handle any situation that may
                      arise.
                    </span>
                  </li>
                  <li className={styles.checkReferencesAskForRefe}>
                    <span className={styles.lookForA}>Check references</span>
                    <span className={styles.askForReferences}>
                      : Ask for references from previous families the babysitter
                      has worked for, and follow up on those references to make
                      sure the babysitter has a track record of being reliable
                      and responsible. This can give you an idea of the
                      babysitter's work ethic and the quality of care they
                      provide.
                    </span>
                  </li>
                  <li className={styles.checkReferencesAskForRefe}>
                    <span className={styles.lookForA}>
                      Consider certification
                    </span>
                    <span className={styles.askForReferences}>
                      : Some babysitters may have certification in areas such as
                      CPR or first aid, which can be helpful in case of an
                      emergency. This can provide an extra layer of protection
                      for your children and give you added confidence in the
                      babysitter's ability to handle unexpected situations.
                    </span>
                  </li>
                  <li className={styles.checkReferencesAskForRefe}>
                    <span className={styles.lookForA}>
                      Set clear expectations
                    </span>
                    <span className={styles.askForReferences}>
                      : Make sure the babysitter knows your expectations for the
                      job, including the duties they will be responsible for and
                      any rules or guidelines you have for your children. This
                      can help ensure that the babysitter is on the same page as
                      you and can provide the level of care you expect.
                    </span>
                  </li>
                  <li className={styles.checkReferencesAskForRefe}>
                    <span className={styles.lookForA}>
                      Discuss pay and scheduling
                    </span>
                    <span className={styles.askForReferences}>
                      : Be clear about how much you are willing to pay and when
                      you need the babysitter to be available. It's also a good
                      idea to have a backup plan in case the babysitter is not
                      available. This can help prevent misunderstandings and
                      ensure that everyone is on the same page.
                    </span>
                  </li>
                  <li className={styles.checkReferencesAskForRefe}>
                    <span className={styles.lookForA}>
                      Conduct an interview
                    </span>
                    <span className={styles.askForReferences}>
                      : Consider conducting an in-person interview to get a
                      better sense of the babysitter's personality and
                      suitability for the job. This can be a good opportunity to
                      ask any questions you may have and get a feel for whether
                      the babysitter is a good fit for your family.
                    </span>
                  </li>
                  <li className={styles.checkReferencesAskForRefe}>
                    <span className={styles.lookForA}>
                      Trust your instincts
                    </span>
                    <span className={styles.askForReferences}>
                      : Ultimately, it's important to trust your instincts and
                      choose a babysitter you feel comfortable with and who you
                      believe will be a good fit for your family. Trusting your
                      gut can help you feel confident in your decision and give
                      you peace of mind knowing your children are in good hands.
                    </span>
                  </li>
                </ul>
                <p className={styles.findingABabysitter}>&nbsp;</p>
                <p className={styles.findingABabysitter}>
                  By following these tips, you can find a babysitter who is
                  reliable, responsible, and a good fit for your family. With
                  the right person in place, you can have the peace of mind
                  knowing your children are in good hands while you are away.
                </p>
                <p className={styles.findingABabysitter}>Regenerate response</p>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.frameWrapper}>
                  <div className={styles.shareThisArticleParent}>
                    <div className={styles.shareThisArticle}>
                      Share this article:
                    </div>
                    <div className={styles.rifacebookBoxFillParent}>
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/rifacebookboxfill.svg"
                      />
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/ritwitterfill.svg"
                      />
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/rilinkedinboxfill.svg"
                      />
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/icroundmail.svg"
                      />
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/rilink.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.ad}>
                  <div className={styles.merge1}>
                    <div className={styles.text}>
                      <div className={styles.cantFindThe}>
                        Can’t find the professional you are looking for?? Let
                        the professional find you.
                      </div>
                      <div className={styles.postAJob}>
                        Post a job with your price in mind, then get offers
                      </div>
                    </div>
                    <div className={styles.smallButtons}>
                      <div className={styles.buttonWrapper}>
                        <div className={styles.button}>Post a Job</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.prototypeFooter}>
          <div className={styles.footer}>
            <div className={styles.download}>
              <div className={styles.downloadOurApps}>Download our apps</div>
              <img
                className={styles.playstoreButtonIcon}
                alt=""
                src="/playstore-button10.svg"
              />
              <img
                className={styles.appstoreButtonIcon}
                alt=""
                src="/appstore-button8.svg"
              />
            </div>
            <div className={styles.footerChild} />
            <img className={styles.layer2Icon} 
             style={{cursor:'pointer'}}
             onClick={()=>{navigate('/')}}
             alt="" src="/layer-2101.svg" />
            <div className={styles.frameGroup}>
              <div className={styles.homeownersParent}>
                <b className={styles.homeowners}>Homeowners</b>
                <div className={styles.postAJobParent}>
                  <div className={styles.howItWorks} onClick={onPostAJob1Click}>
                    Post a Job
                  </div>
                  <div
                    className={styles.howItWorks}
                    onClick={onHowItWorksClick}
                  >
                    How it works
                  </div>
                  <div
                    className={styles.howItWorks}
                    onClick={onContactUsTextClick}
                  >
                    Contact Us
                  </div>
                  <div
                    className={styles.howItWorks}
                    onClick={onHelpFAQsClick}
                  >{`Help & FAQ’s`}</div>
                  <div
                    className={styles.howItWorks}
                    onClick={onUserAgreementTextClick}
                  >
                    User Agreement
                  </div>
                </div>
              </div>
              <div className={styles.professionalsParent}>
                <b className={styles.homeowners}>Professionals</b>
                <div className={styles.postAJobParent}>
                  <div className={styles.signUp}
                  style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                  <div
                    className={styles.howItWorks}
                    onClick={onHowItWorks1Click}
                  >
                    Blog
                  </div>
                  <div
                    className={styles.howItWorks}
                    onClick={onContactUsText1Click}
                  >
                    Contact Us
                  </div>
                  <div
                    className={styles.howItWorks}
                    onClick={onHelpFAQs1Click}
                  >{`Help & FAQ’s`}</div>
                  <div
                    className={styles.howItWorks}
                    onClick={onUserAgreementText1Click}
                  >
                    User Agreement
                  </div>
                </div>
              </div>
              <div className={styles.loggworksParent}>
                <b className={styles.homeowners}>Loggworks</b>
                <div className={styles.postAJobParent}>
                  <div
                    className={styles.howItWorks}
                    onClick={onAboutUsTextClick}
                  >
                    About us
                  </div>
                  <div
                    className={styles.howItWorks}
                    onClick={onCareersTextClick}
                  >
                    Careers
                  </div>
                  <div
                    className={styles.howItWorks}
                    onClick={onPartnersTextClick}
                  >
                    Partners
                  </div>
                  <div
                    className={styles.howItWorks}
                    onClick={onInfluencerProgramTextClick}
                  >
                    Affiliate program
                  </div>
                  <div className={styles.blog}>Blog</div>
                </div>
              </div>
              <div className={styles.loggworksGroup}>
                <div className={styles.loggworks1}>
                  <b className={styles.followUs}>Follow us</b>
                </div>
                <div className={styles.groupParent}>
                  <div className={styles.frameContainer}>
                    <img
                      className={styles.frameIcon}
                      alt=""
                      src="/frame20.svg"
                    />
                    <div className={styles.facebook}>Facebook</div>
                  </div>
                  <div className={styles.groupDiv}>
                    <img
                      className={styles.frameIcon1}
                      alt=""
                      src="/frame34.svg"
                    />
                    <div className={styles.twitter}>Twitter</div>
                  </div>
                  <div className={styles.frameParent1}>
                    <img
                      className={styles.frameIcon1}
                      alt=""
                      src="/frame35.svg"
                    />
                    <div className={styles.twitter}>Instagram</div>
                  </div>
                  <div className={styles.frameParent2}>
                    <img
                      className={styles.frameIcon1}
                      alt=""
                      src="/frame36.svg"
                    />
                    <div className={styles.twitter}>YouTube</div>
                  </div>
                  <div className={styles.mdilinkedinParent}>
                    <img
                      className={styles.mdilinkedinIcon}
                      alt=""
                      src="/mdilinkedin1.svg"
                    />
                    <div className={styles.linkedin}>LinkedIn</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.copyright2022LoggworkLtdAParent}>
              <div className={styles.copyright2022Loggwork}>
                ©Copyright 2024 loggworks  Ltd. All Rights Reserved
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.termsOfUseParent}>
                  <div className={styles.copyright2022Loggwork}
                  style={{cursor:'pointer'}}
                  onClick={()=>{navigate('/terms')}}>
                    Terms of Use
                  </div>
                  <div className={styles.frameChild} />
                </div>
                <div className={styles.termsOfUseParent}>
                  <div
                    className={styles.copyright2022Loggwork}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/privacy')}}
                  >{`Privacy Policy `}</div>
                  <div className={styles.frameChild} />
                </div>
                <div className={styles.termsOfUseParent}>
                  <div className={styles.copyright2022Loggwork}
                   style={{cursor:'pointer'}}
                   onClick={()=>{navigate('/cookie')}}>Cookies</div>
                  <div className={styles.frameChild} />
                </div>
                <div className={styles.contactUsParent}>
                  <div className={styles.copyright2022Loggwork}>Contact Us</div>
                  <div className={styles.frameChild} />
                </div>
                <div className={styles.termsOfUseParent}>
                  <div className={styles.copyright2022Loggwork} style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href='https://www.loggworks.co.uk/sitemap.xml'
                    }}>Sitemap</div>
                  <div className={styles.frameChild1} />
                </div>
              </div>
            </div>
            <div className={styles.largeButtonsParent}>
              <button
                className={styles.largeButtons}
                style={{cursor:'pointer'}}
                onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
              >
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder47.svg"
                />
                <b className={styles.label}>Become a Professional</b>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder47.svg"
                />
              </button>
              <button
                className={styles.largeButtons}
                onClick={onLargeButtons1Click}
              >
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder48.svg"
                />
                <b className={styles.label}>Post a Job</b>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder48.svg"
                />
              </button>
            </div>
          </div>
        </div>
        <nav className={styles.navigation}>
          <div className={styles.navigation1}>
            <img
              className={styles.navigationChild}
              alt=""
              onClick={()=>navigate('/')}
              style={{cursor:'pointer'}}
              src="/frame-66522.svg"
            />
            <div className={styles.navigationItem} />
            <div className={styles.tabSwitch}>
              <div
                className={styles.pageSelector}
                onClick={onPageSelectorContainerClick}
              >
                <div className={styles.descriptionParent}>
                  <div className={styles.copyright2022Loggwork}>
                    Professional
                  </div>
                  <div className={styles.selector} />
                </div>
              </div>
              <div className={styles.pageSelector1}>
                <div className={styles.descriptionParent}>
                  <b className={styles.description1}>Consumer</b>
                  <div className={styles.selector1} />
                </div>
              </div>
            </div>
            <LocationSearch/>
          </div>
          <div className={styles.navigation2}>
            <div className={styles.homeParent}>
              <div className={styles.home} onClick={onHomeTextClick}>
                Home
              </div>
              <div className={styles.home} onClick={onHowItWorks2Click}>
                How it works
              </div>
              <div className={styles.services} onClick={openDropdown}>
                <div className={styles.services1}>Services</div>
                <img
                  className={styles.rifacebookBoxFillIcon}
                  alt=""
                  src="/iconschevrondown1.svg"
                />
              </div>
              <div className={styles.resources} onClick={openDropdown1}>
                <div className={styles.services1}>Resources</div>
                <img
                  className={styles.rifacebookBoxFillIcon}
                  alt=""
                  src="/iconschevrondown1.svg"
                />
              </div>
              <div className={styles.pricing}>Pricing</div>
            </div>
            <div className={styles.frameParent3}>
              <div className={styles.frameParent4}>
                <button
                  className={styles.iconshelpParent}
                  onClick={onFrameButtonClick}
                >
                  <img
                    className={styles.rifacebookBoxFillIcon}
                    alt=""
                    src="/iconshelp.svg"
                  />
                  <div className={styles.help}>Help</div>
                </button>
                <button className={styles.iconshelpParent}>
                  <img
                    className={styles.rifacebookBoxFillIcon}
                    alt=""
                    src="/iconsaccount11.svg"
                  />
                  <div className={styles.help}>Account</div>
                </button>
              </div>
              <button
                className={styles.smallButtons1}
                onClick={onSmallButtonsClick}
              >
                <div className={styles.iconsaddParent}>
                  <img
                    className={styles.iconsadd}
                    alt=""
                    src="/iconsadd15.svg"
                  />
                  <div className={styles.button1}>Post a job</div>
                </div>
              </button>
            </div>
          </div>
        </nav>
        <div className={styles.breadcrumbs}>
          <div className={styles.mdilinkedinParent}>
            <div className={styles.hiringGuide}>Hiring guide</div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright8.svg"
            />
            <div
              className={styles.hiringGuide1}
              onClick={onHiringGuideText1Click}
            >
              Hiring Childcare workers
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright9.svg"
            />
            <div className={styles.hiringGuide2}>
              Tips on hiring babysitters
            </div>
            <img
              className={styles.iconschevronRight2}
              alt=""
              src="/iconschevronright9.svg"
            />
            <div className={styles.hiringGuide3}>Hiring guide</div>
            <img
              className={styles.iconschevronRight2}
              alt=""
              src="/iconschevronright9.svg"
            />
            <div className={styles.hiringGuide3}>Hiring guide</div>
            <img
              className={styles.iconschevronRight2}
              alt=""
              src="/iconschevronright9.svg"
            />
            <div className={styles.hiringGuide3}>Hiring guide</div>
            <img
              className={styles.iconschevronRight2}
              alt=""
              src="/iconschevronright9.svg"
            />
            <div className={styles.hiringGuide3}>Hiring guide</div>
            <img
              className={styles.iconschevronRight2}
              alt=""
              src="/iconschevronright9.svg"
            />
            <div className={styles.hiringGuide3}>Hiring guide</div>
            <img
              className={styles.iconschevronRight2}
              alt=""
              src="/iconschevronright9.svg"
            />
            <div className={styles.hiringGuide3}>Hiring guide</div>
          </div>
        </div>
      </div>
      {isDropdownOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDropdown}
        >
          <Dropdown4 onClose={closeDropdown} />
        </PortalPopup>
      )}
      {isDropdown1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDropdown1}
        >
          <Dropdown5 onClose={closeDropdown1} />
        </PortalPopup>
      )}
    </>
  );
};

export default ProfessionalHiringGuideArti1;
