import { useNavigate } from "react-router-dom";
import styles from "./NewsMenu.module.css";

const NewsMenu = () => {
  const navigate =useNavigate()
  return (
    <div className={styles.newsmenu}>
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div className={styles.productUpdatesParent}
          style={{cursor:'pointer'}}
          onClick={()=>navigate('/blog/news/product-updates')}>
            <b className={styles.productUpdates}>Product Updates</b>
            <div className={styles.learnAboutThe}>
              Learn about the latest features, and innovations on Loggworks.
            </div>
          </div>
          <div className={styles.productUpdatesParent}
          style={{cursor:'pointer'}}
          onClick={()=>navigate('/blog/news/partners')}>
            <b className={styles.productUpdates}>partners</b>
            <div className={styles.learnAboutThe}>
              Explore the dynamic connections that define our valued partners.
            </div>
          </div>
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.productUpdatesParent}
          style={{cursor:'pointer'}}
          onClick={()=>navigate('/blog/news/industry-news')}>
            <b className={styles.productUpdates}>industry News</b>
            <div className={styles.learnAboutThe}>
              Gain a competitive edge with the latest industry news.
            </div>
          </div>
          <div className={styles.productUpdatesParent}
          style={{cursor:'pointer'}}
          onClick={()=>navigate('/blog/news/loggworks-in-the-news')}>
            <b className={styles.productUpdates}>loggworks in the news</b>
            <div className={styles.learnAboutThe}>
              Stay informed with breaking news and updates about Loggworks.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsMenu;
