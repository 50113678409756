import { useState, useCallback } from "react";
import MobileMenuProfProfessional from "../components/MobileMenuProfProfessional1";
import PortalPopup from "../components/PortalPopup";
import Dropdown2 from "../components/Dropdown";
import { useNavigate } from "react-router-dom";
import styles from "./ConsumerHome.module.css";
import ProfessionalNavigation1 from '../components/ProfessionalNavigation1';
import MobileFooter from '../components/MobileFooter';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TestimonyProf from "../components/TestimonyProf";
const ConsumerHome = () => {
  const [
    isMobileMenuProfProfessionalPopupOpen,
    setMobileMenuProfProfessionalPopupOpen,
  ] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const openMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(true);
  }, []);

  const closeMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(false);
  }, []);

 const onPostAJob3Click = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);


  const onHowItWorks4Click = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);


  const onContactUsText3Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);


  const onHelpFAQs3Click = useCallback(() => {
    navigate("/faq");
  }, [navigate]);


  const onUserAgreementText3Click = useCallback(() => {
    navigate("/agreement");
  }, [navigate]);


  const onHowItWorks5Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);


  const onContactUsText4Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);


  const onHelpFAQs4Click = useCallback(() => {
    navigate("/faq");
  }, [navigate]);


  const onUserAgreementText4Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);

   const onAboutUsTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);


  const onCareersTextClick = useCallback(() => {
    navigate("/becometeam");
  }, [navigate]);


  const onPartnersTextClick = useCallback(() => {
    navigate("/partners");
  }, [navigate]);


  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/affiliate-program");
  }, [navigate]);


  const onLargeButtonsClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);


  const onLargeButtons1Click = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  return (
    <>
      <div className={styles.consumerhome}>
         <div className={styles.homeMobile}>
        <div className={styles.navbarMobileclosed}>
          <img className={styles.layer2Icon} alt="" src="/layer-21.svg"
          style={{cursor:'pointer'}} onClick={()=>navigate('/')}  />
          <div className={styles.iconsaccountParent}>
            <img className={styles.iconsaccount} alt="" src="/iconsaccount21.svg" 
             onClick={()=>window.open('https://www.professional.loggworks.co.uk/', '_blank')}/>
            <img className={styles.hamburgerIcon} alt="" src="/hamburger.svg" onClick={openMobileMenuProfProfessionalPopup} />
          </div>
        </div>
        <div className={styles.homeMobileInner}>
          <div className={styles.frameParent}>
            <div className={styles.getAheadInYourChosenProfeParent}>
              <b className={styles.getAheadIn}>Get ahead in your chosen profession</b>
              <div className={styles.toJoinAs}>
                   <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    Only pay commission on completed jobs
                    </div>
                    </div>
                  <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    100% free Quote
                    </div>
                  </div>
                   <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    100% Free business listing page
                    </div>
                  </div>
                  <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    100% free Automated Job Management  
                    </div>
                  </div>
                  <p style={{textAlign:'left'}}>feature</p>
              </div>
            </div>
            <div className={styles.largeButtons} style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
              <img className={styles.iconsadd} alt="" src="/iconsadd3.svg" />
              <b className={styles.label}>Become a Professional</b>
              <img className={styles.iconsadd} alt="" src="/iconsadd4.svg" />
            </div>
          </div>
        </div>
        <div className={styles.mergeWrapper}>
          <div className={styles.merge}>
            <b className={styles.securedPaymentAnd}>Secured payment and privacy on Loggworks</b>
            <div className={styles.reasons}>
              <div className={styles.whyUs}>
                <div className={styles.privacyParent}>
                  <img className={styles.privacyIcon} alt="" src="/privacy7.svg" />
                  <div className={styles.privacyProtectionParent}>
                    <b className={styles.privacyProtection}>Cut Business Costs</b>
                    <div className={styles.youllNeverHave}>Leave the hard work and expense of web development and SEO to Loggworks. Our app also eliminates accounting costs by automating invoicing and reporting tasks.
</div>
                  </div>
                </div>
              </div>
              <div className={styles.whyUs}>
                <div className={styles.privacyParent}>
                  <img className={styles.privacyIcon} alt="" src="/privacy8.svg" />
                  <div className={styles.privacyProtectionParent}>
                    <b className={styles.privacyProtection}>Affordable Pricing</b>
                    <div className={styles.youllNeverHave}>
                      <p className={styles.cutTheCost}>Cut the cost of admin by paying a commission only on completed bookings. The Loggworks 
</p>
                      <p className={styles.cutTheCost}>pay-as-you-go model includes free registration, free offer promotion and free business reputation vetting — allowing you to scale as you grow.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.whyUs}>
                <div className={styles.privacyParent}>
                  <img className={styles.privacyIcon} alt="" src="/privacy9.svg" />
                  <div className={styles.privacyProtectionParent}>
                    <b className={styles.privacyProtection}>Find New Business</b>
                    <div className={styles.youllNeverHave}>
                      <p className={styles.cutTheCost}>Find new jobs and grow your business by using in-built features such as consumer financing,
</p>
                      <p className={styles.cutTheCost}>professional profile social sharing, vetting and special offers.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.homeMobileChild}>
          <div className={styles.frameGroup}>
            <div className={styles.howItWorksParent}>
              <b className={styles.howItWorks}>How it Works</b>
              <div className={styles.getStartedUsing}>Get started using loggworks in 4 easy steps.</div>
            </div>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                <img className={styles.frameIcon} alt="" src="/frame51.svg" />
                <div className={styles.signUpAsAServiceProviderParent}>
                  <div className={styles.signUpAs}>Sign up as a service provider.</div>
                  <div className={styles.youllNeverHave1}>
                  You choose the category and subcategory of the
type of service you offer.</div>
                </div>
              </div>
              <div className={styles.frameDiv}>
                <img className={styles.frameIcon1} alt="" src="/frame6.svg" />
                <div className={styles.signUpAsAServiceProviderParent}>
                  <div className={styles.signUpAs}>Receive requests from customers.</div>
                  <div className={styles.youllNeverHave1}>Get booking requests from service consumers, review job details and book jobs through loggworks website or mobile App.</div>
                </div>
              </div>
              <div className={styles.frameDiv}>
                <img className={styles.frameIcon2} alt="" src="/frame7.svg" />
                <div className={styles.signUpAsAServiceProviderParent}>
                  <div className={styles.signUpAs}>Send quote and invoice.</div>
                  <div className={styles.youllNeverHave1}>
                  When you get the job details, you will send a quote containing the terms and conditions of the job upon the acceptance of the quote by the customer, then an invoice will be issued for payment.
                  </div>
                </div>
              </div>
              <div className={styles.frameDiv}>
                <img className={styles.frameIcon3} alt="" src="/frame8.svg" />
                <div className={styles.signUpAsAServiceProviderParent}>
                  <div className={styles.signUpAs}>Complete the job and get paid.</div>
                  <div className={styles.youllNeverHave1}>
                  Upon job completion to the satisfaction of the client
your funds are made available to you, get the job done and get paid.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.homeMobileInner1}>
          <div className={styles.textParent}>
            <div className={styles.privacyParent}>
              <div className={styles.joinOurGrowingCommunityOfParent}>
                <b className={styles.joinOurGrowing}>Join our growing community of professionals.</b>
                <div className={styles.youllNeverHave}></div>
              </div>
              <div className={styles.largeButtons1} style={{cursor: 'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                <img className={styles.icons20pxplaceholder} alt="" src="/icons20pxplaceholder51.svg" />
                <b className={styles.label}>Become a professional</b>
                <img className={styles.icons20pxplaceholder} alt="" src="/icons20pxplaceholder51.svg" />
              </div>
            </div>
            <div className={styles.images}>
              <div className={styles.rectangleParent}>
                <img className={styles.frameChild} alt="" src="/rectangle-119551@2x.png" />
                <img className={styles.frameChild} alt="" src="/rectangle-119571@2x.png" />
              </div>
              <div className={styles.rectangleGroup}>
                <img className={styles.frameChild} alt="" src="/rectangle-119561@2x.png" />
                <img className={styles.frameChild} alt="" src="/rectangle-11958@2x.png" />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.downloadApp}>
          <div className={styles.merge1}>
            <div className={styles.image}>
              <img className={styles.home1Icon} alt="" src="/home-11@2x.png" />
            </div>
            <div className={styles.text1}>
              <div className={styles.privacyParent}>
                <div className={styles.accessLoggworksOnYourMobilParent}>
                  <b className={styles.securedPaymentAnd}>Access Loggworks on your mobile phone</b>
                  <div className={styles.enjoyTheFull}>{`Enjoy the full experience of Loggworks on our mobile app. What’s more? You get to enjoy all of our services convieniently. `}</div>
                </div>
                <div className={styles.largeButtons2}>
                  <img className={styles.icons20pxplaceholder} alt="" src="/icons20pxplaceholder8.svg" />
                  <b className={styles.label}>Coming soon</b>
                  <img className={styles.icons20pxplaceholder} alt="" src="/icons20pxplaceholder8.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.testimonials}>
          <div className={styles.merge}>
            <b className={styles.wereTrustedBy}>We’re trusted by many across the UK</b>
            <div className={styles.frameWrapper}>
              <div className={styles.merge}>
                <div className={styles.testimonials1}>
                  <div className={styles.testimonials2}>
                  <TestimonyProf/>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div className={styles.homeMobileInner2}>
          <div className={styles.frequentlyAskedQuestionsParent}>
            <b className={styles.securedPaymentAnd}>Frequently asked questions</b>
            <div className={styles.faqParent}>
              <div className={styles.faq}>
                <div className={styles.question}>
                  <div className={styles.whatIsLoggworksParent}>
                  <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>What is Loggworks?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            Loggworks is a platform connecting Home service consumers
            (Homeowners, Tenants and Property managers) to Service
            Professionals.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                </div>
              </div>
              <div className={styles.faq}>
                <div className={styles.question}>
                  <div className={styles.whatIsLoggworksParent}>
                  <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>How does Loggworks work?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            <p className={styles.signUpAsAServiceProfessio}>
              <span className={styles.signUpAs}>
                Sign up as a service professional:
              </span>
              <span>
                {" "}
                You choose the category and subcategory of the type of service
                you offer.
              </span>
            </p>
            <p className={styles.signUpAsAServiceProfessio}>
              <span className={styles.signUpAs}>Get bookings:</span>
              <span>
                {" "}
                The client messages you, gives you job details, and books you
                through the Loggworks webapp.
              </span>
            </p>
            <p className={styles.signUpAsAServiceProfessio}>
              <span className={styles.signUpAs}>Send quote and invoice:</span>
              <span>
                {" "}
                When you get the job details, you will send a quote containing
                the terms and conditions of the job upon the acceptance of the
                quote by the consumer, and then an invoice will be issued for
                payment.
              </span>
            </p>
            <p className={styles.signUpAsAServiceProfessio}>
              <span className={styles.signUpAs}>
                Get the job done and get paid:
              </span>
              <span>
                {" "}
                Upon job completion to the satisfaction of the client, your
                funds are made available to you. Get the job done and get paid.
              </span>
            </p>
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                </div>
              </div>
              <div className={styles.faq}>
                <div className={styles.question}>
                  <div className={styles.whatIsLoggworksParent}>
                  <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>Is there are signup/membership fee?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            No, there is no sign up fee.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                </div>
              </div>
              <div className={styles.faq}>
                <div className={styles.question}>
                  <div className={styles.whatIsLoggworksParent}>
                  <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>How do I get jobs?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            Getting jobs is very easy. When your profile is completed and
            verified, clients will be able to message and call you through the
            app if they want to book or request your service.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MobileFooter/>
      </div>
        <div className={styles.homeServiceProvider}>
         <ProfessionalNavigation1 />
          <div className={styles.frameParent14}>
            <div className={styles.frameWrapper1}>
              <div className={styles.frameParent15}>
                <div className={styles.getAheadInYourChosenProfeGroup}>
                  <b className={styles.getAheadIn1}>
                    Get ahead in your chosen profession
                  </b>
                  <div className={styles.toJoinAs1}>
                  <div style={{display:'flex', gap:'1rem'}}>
                   <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    Only pay commission on completed jobs
                    </div>
                  </div>
                  <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    100% free Quote
                    </div>
                  </div>
                </div>
                <div style={{display:'flex',gap:'1rem'}}>
                   <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    100% Free business listing page
                    </div>
                  </div>
                  <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    100% free Automated Job Management feature 
                    </div>
                  </div>
                </div>
                  </div>
                </div>
                <div className={styles.largeButtons1} style={{cursor: 'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder22.svg"
                  />
                  <b className={styles.label}>Become a professional</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder22.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.mergeContainer}>
              <div className={styles.frameParent5}>
                <div className={styles.howItWorksParent}>
                  <b className={styles.securedPaymentAnd1}>
                    Secured payment and privacy on Loggworks
                  </b>
                  <div className={styles.loggworksHelpsYou}>
                    Loggworks helps you get special offers, connect you to top
                    vetted and reliable service professionals while protecting
                    your payment and privacy.
                  </div>
                </div>
                <div className={styles.reasons1}>
                  <div className={styles.whyUs3}>
                    <div className={styles.privacyParent}>
                      <img
                        className={styles.privacyIcon}
                        alt=""
                        src="/privacy3.svg"
                      />
                      <div className={styles.privacyProtectionParent1}>
                        <b className={styles.privacyProtection}>
                          Cut business costs.
                        </b>
                        <div className={styles.youllNeverHave5}>
                          Leave the hard work and expense of web development and
                          SEO to Loggworks. Our app also eliminates accounting
                          costs by automating invoicing and reporting tasks.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.whyUs3}>
                    <div className={styles.privacyParent}>
                      <img
                        className={styles.privacyIcon}
                        alt=""
                        src="/privacy13.svg"
                      />
                      <div className={styles.privacyProtectionParent1}>
                        <b className={styles.privacyProtection}>
                          Affordable pricing.
                        </b>
                        <div className={styles.youllNeverHave5}>
                          <p className={styles.cutTheCost}>
                            Cut the cost of admin by paying a commission only on
                            completed bookings. The Loggworks
                          </p>
                          <p className={styles.cutTheCost}>
                            pay-as-you-go model includes free registration, free
                            offer promotion and free business reputation vetting
                            — allowing you to scale as you grow.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.whyUs3}>
                    <div className={styles.privacyParent}>
                      <img
                        className={styles.privacyIcon}
                        alt=""
                        src="/privacy21.svg"
                      />
                      <div className={styles.privacyProtectionParent1}>
                        <b className={styles.privacyProtection}>
                          Find new business.
                        </b>
                        <div className={styles.youllNeverHave5}>
                          <p className={styles.cutTheCost}>
                            Find new jobs and grow your business by using
                            in-built features such as consumer financing,
                          </p>
                          <p className={styles.cutTheCost}>
                            professional profile social sharing, vetting and
                            special offers.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.sectionTitleParent}>
              <div className={styles.howItWorksParent}>
                <b className={styles.howItWorks5}>How it works</b>
                <div className={styles.loggworksHelpsYou}>
                  Loggworks helps you get special offers, connect you to top
                  vetted and reliable service professionals while protecting
                  your payment and privacy.
                </div>
              </div>
              <div className={styles.getStartedUsing1}>
                Get started using loggworks in 4 easy steps.
              </div>
            </div>
            <div className={styles.frameParent16}>
              <img
                className={styles.frameChild1}
                alt=""
                src="/frame-67953.svg"
              />
              <div className={styles.signUpAsAServiceProviderGroup}>
                <div className={styles.signUpAs1}>
                  Sign up as a service provider.
                </div>
                <div className={styles.youllNeverHave6}>
                You choose the category and subcategory of the
type of service you offer.
                </div>
              </div>
            </div>
            <div className={styles.frameParent16}>
              <div className={styles.signUpAsAServiceProviderGroup}>
                <div className={styles.signUpAs1}>
                  Receive requests from customers.
                </div>
                <div className={styles.youllNeverHave6}>
                Get booking requests from service consumers, review job details and book jobs through loggworks website or mobile App.
                </div>
              </div>
              <img
                className={styles.frameChild1}
                alt=""
                src="/frame-67954.svg"
              />
            </div>
            <div className={styles.frameParent16}>
              <img
                className={styles.frameChild1}
                alt=""
                src="/frame-67955.svg"
              />
              <div className={styles.signUpAsAServiceProviderGroup}>
                <div className={styles.signUpAs1}>
                  Send quote and invoice.
                </div>
                <div className={styles.youllNeverHave6}>
                When you get the job details, you will send a quote
containing the terms and conditions of the job upon the acceptance of the quote by the customer, then an invoice will be issued for payment.
                </div>
              </div>
            </div>
            <div className={styles.frameParent16}>
              <div className={styles.signUpAsAServiceProviderGroup}>
                <div className={styles.signUpAs1}>
                  Complete the job and get paid.
                </div>
                <div className={styles.youllNeverHave6}>
                Upon job completion to the satisfaction of the client
your funds are made available to you, get the job done and get paid.
                </div>
              </div>
              <img
                className={styles.frameChild1}
                alt=""
                src="/frame-67956.svg"
              />
            </div>
            <div className={styles.frameWrapper2}>
              <div className={styles.textGroup}>
                <div className={styles.text2}>
                  <div className={styles.joinOurGrowingCommunityOfGroup}>
                    <b className={styles.joinOurGrowing1}>
                      Join our growing community of professionals.
                    </b>
                  </div>
                  <div className={styles.largeButtons1} style={{cursor: 'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder23.svg"
                    />
                    <b className={styles.label}>Become a professional</b>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder23.svg"
                    />
                  </div>
                </div>
                <div className={styles.images1}>
                  <div className={styles.rectangleContainer}>
                    <img
                      className={styles.frameChild5}
                      alt=""
                      src="/rectangle-119551@2x.png"
                    />
                    <img
                      className={styles.frameChild5}
                      alt=""
                      src="/rectangle-119571@2x.png"
                    />
                  </div>
                  <div className={styles.rectangleParent1}>
                    <img
                      className={styles.frameChild5}
                      alt=""
                      src="/rectangle-119561@2x.png"
                    />
                    <img
                      className={styles.frameChild5}
                      alt=""
                      src="/rectangle-119581@2x.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.downloadApp1}>
              <div className={styles.merge6}>
                <div className={styles.image1}>
                  <img
                    className={styles.home1Icon1}
                    alt=""
                    src="/home-1@2x.png"
                  />
                </div>
                <div className={styles.text3}>
                  <div className={styles.text2}>
                    <div className={styles.getAheadInYourChosenProfeGroup}>
                      <b className={styles.accessLoggworksOn1}>
                        Access Loggworks on your mobile phone
                      </b>
                      <div
                        className={styles.enjoyTheFull}
                      >{`Enjoy the full experience of Loggworks on our mobile app. What’s more? You get to enjoy all of our services convieniently. `}</div>
                    </div>
                    <div className={styles.largeButtons7}>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder24.svg"
                      />
                      <b className={styles.label}>Coming soon</b>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder24.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.rectangleParent2}>
              <div className={styles.rectangleDiv} />
              <div className={styles.zigBackgroundTestimonials}>
                <div className={styles.groupParent}>
                  <div className={styles.pxWavyParent}>
                    <img
                      className={styles.pxWavyIcon}
                      alt=""
                      src="/24px-wavy@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon1}
                      alt=""
                      src="/24px-wavy2@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon2}
                      alt=""
                      src="/24px-wavy@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon3}
                      alt=""
                      src="/24px-wavy2@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon4}
                      alt=""
                      src="/24px-wavy@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon5}
                      alt=""
                      src="/24px-wavy2@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon6}
                      alt=""
                      src="/24px-wavy@2x.png"
                    />
                  </div>
                  <div className={styles.pxWavyGroup}>
                    <img
                      className={styles.pxWavyIcon}
                      alt=""
                      src="/24px-wavy@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon1}
                      alt=""
                      src="/24px-wavy2@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon2}
                      alt=""
                      src="/24px-wavy@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon3}
                      alt=""
                      src="/24px-wavy2@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon4}
                      alt=""
                      src="/24px-wavy@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon5}
                      alt=""
                      src="/24px-wavy2@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon6}
                      alt=""
                      src="/24px-wavy@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.groupContainer}>
                  <div className={styles.pxWavyParent}>
                    <img
                      className={styles.pxWavyIcon}
                      alt=""
                      src="/24px-wavy@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon1}
                      alt=""
                      src="/24px-wavy2@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon2}
                      alt=""
                      src="/24px-wavy@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon3}
                      alt=""
                      src="/24px-wavy2@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon4}
                      alt=""
                      src="/24px-wavy@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon5}
                      alt=""
                      src="/24px-wavy2@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon6}
                      alt=""
                      src="/24px-wavy@2x.png"
                    />
                  </div>
                  <div className={styles.pxWavyGroup}>
                    <img
                      className={styles.pxWavyIcon}
                      alt=""
                      src="/24px-wavy@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon1}
                      alt=""
                      src="/24px-wavy2@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon2}
                      alt=""
                      src="/24px-wavy@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon3}
                      alt=""
                      src="/24px-wavy2@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon4}
                      alt=""
                      src="/24px-wavy@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon5}
                      alt=""
                      src="/24px-wavy2@2x.png"
                    />
                    <img
                      className={styles.pxWavyIcon6}
                      alt=""
                      src="/24px-wavy@2x.png"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.groupParent1}>
                <TestimonyProf/>
              </div>
              
              <div className={styles.weAreTrusted}>
                <b className={styles.hearFromFellow}>
                  Hear from fellow professionals on Loggworks
                </b>
                <img className={styles.frameIcon8} alt="" src="/frame27.svg" />
              </div>
            </div>
            <div className={styles.faqSection}>
              <div className={styles.container}>
                <b className={styles.howItWorks5}>Frequently asked questions</b>
                <div className={styles.faqGroup}>
                  <div className={styles.faq4}>
                    <div className={styles.question4}>
                      <div className={styles.whatIsLoggworksParent}>
                      <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>What is Loggworks?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            Loggworks is a platform connecting Home service consumers
            (Homeowners, Tenants and Property managers) to Service
            Professionals.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                      </div>
                    </div>
                   
                  </div>
                  <div className={styles.faq4}>
                    <div className={styles.question4}>
                      <div className={styles.whatIsLoggworksParent}>
                      <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>How does Loggworks work?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            <p className={styles.signUpAsAServiceProfessio}>
              <span className={styles.signUpAs}>
                Sign up as a service professional:
              </span>
              <span>
                {" "}
                You choose the category and subcategory of the type of service
                you offer.
              </span>
            </p>
            <p className={styles.signUpAsAServiceProfessio}>
              <span className={styles.signUpAs}>Get bookings:</span>
              <span>
                {" "}
                The client messages you, gives you job details, and books you
                through the Loggworks webapp.
              </span>
            </p>
            <p className={styles.signUpAsAServiceProfessio}>
              <span className={styles.signUpAs}>Send quote and invoice:</span>
              <span>
                {" "}
                When you get the job details, you will send a quote containing
                the terms and conditions of the job upon the acceptance of the
                quote by the consumer, and then an invoice will be issued for
                payment.
              </span>
            </p>
            <p className={styles.signUpAsAServiceProfessio}>
              <span className={styles.signUpAs}>
                Get the job done and get paid:
              </span>
              <span>
                {" "}
                Upon job completion to the satisfaction of the client, your
                funds are made available to you. Get the job done and get paid.
              </span>
            </p>
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                      </div>
                    </div>
                  </div>
                  <div className={styles.faq4}>
                    <div className={styles.question4}>
                      <div className={styles.whatIsLoggworksParent}>
                      <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>Is there are signup/membership fee?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            No, there is no sign up fee.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                      </div>
                    </div>
                  </div>
                  <div className={styles.faq4}>
                    <div className={styles.question4}>
                      <div className={styles.whatIsLoggworksParent}>
                      <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>How do I get jobs?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            Getting jobs is very easy. When your profile is completed and
            verified, clients will be able to message and call you through the
            app if they want to book or request your service.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.prototypeFooter}>
            <div className={styles.footer}>
              <div className={styles.download}>
                <div className={styles.downloadOurApps}>Download our apps</div>
                <img
                  className={styles.playstoreButtonIcon}
                  alt=""
                  src="/playstore-button.svg"
                />
                <img
                  className={styles.appstoreButtonIcon}
                  alt=""
                  src="/appstore-button.svg"
                />
              </div>
              <div className={styles.footerChild} />
              <img className={styles.layer2Icon1} 
               style={{cursor:'pointer'}}
               onClick={()=>{navigate('/')}}
               alt="" src="/layer-25.svg" />
              <div className={styles.frameParent21}>
                <div className={styles.homeownersParent}>
                  <b className={styles.privacyProtection}>Homeowners</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.aboutUs} onClick={onPostAJob3Click}>
                      Post a Job
                    </div>
                    <div
                      className={styles.aboutUs}
                      onClick={onHowItWorks4Click}
                    >
                      How it works
                    </div>
                    <div
                      className={styles.aboutUs}
                      onClick={onContactUsText3Click}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.aboutUs}
                      onClick={onHelpFAQs3Click}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.aboutUs}
                      onClick={onUserAgreementText3Click}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.professionalsParent}>
                  <b className={styles.privacyProtection}>Professionals</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.postAJob}
                    style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                    <div
                      className={styles.aboutUs}
                      onClick={onHowItWorks5Click}
                    >
                      Blog
                    </div>
                    <div
                      className={styles.aboutUs}
                      onClick={onContactUsText4Click}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.aboutUs}
                      onClick={onHelpFAQs4Click}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.aboutUs}
                      onClick={onUserAgreementText4Click}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.loggworksGroup}>
                  <b className={styles.privacyProtection}>Loggworks</b>
                  <div className={styles.postAJobParent}>
                    <div
                      className={styles.aboutUs}
                      onClick={onAboutUsTextClick}
                    >
                      About us
                    </div>
                    <div
                      className={styles.aboutUs}
                      onClick={onCareersTextClick}
                    >
                      Careers
                    </div>
                    <div
                      className={styles.aboutUs}
                      onClick={onPartnersTextClick}
                    >
                      Partners
                    </div>
                    <div
                      className={styles.aboutUs}
                      onClick={onInfluencerProgramTextClick}
                    >
                      Affiliate program
                    </div>
                    <div className={styles.blog}>Blog</div>
                  </div>
                </div>
                <div className={styles.loggworksContainer}>
                  <div className={styles.loggworks2}>
                    <b className={styles.followUs1}>Follow us</b>
                  </div>
                  <div className={styles.groupParent2}>
                    <div className={styles.frameParent22}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://web.facebook.com/Loggworks?_rdc=1&_rdr', '_blank')}>
                      <img
                        className={styles.frameIcon9}
                        alt=""
                        src="/frame4.svg"
                      />
                      <div className={styles.facebook1}>Facebook</div>
                    </div>
                    <div className={styles.frameParent23}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://twitter.com/loggworks', '_blank')}>
                      <img
                        className={styles.frameIcon10}
                        alt=""
                        src="/frame11.svg"
                      />
                      <div className={styles.twitter1}>Twitter</div>
                    </div>
                    <div className={styles.frameParent24}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.instagram.com/loggworks/?utm_medium=copy_link', '_blank')}>
                      <img
                        className={styles.frameIcon10}
                        alt=""
                        src="/frame21.svg"
                      />
                      <div className={styles.twitter1}>Instagram</div>
                    </div>
                    <div className={styles.frameParent25}>
                      <img
                        className={styles.frameIcon10}
                        alt=""
                        src="/frame31.svg"
                      />
                      <div className={styles.twitter1}>YouTube</div>
                    </div>
                    <div className={styles.mdilinkedinGroup}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open(' https://www.linkedin.com/company/loggworks', '_blank')}>
                      <img
                        className={styles.mdilinkedinIcon}
                        alt=""
                        src="/mdilinkedin1.svg"
                      />
                      <div className={styles.linkedin1}>LinkedIn</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.copyright2022LoggworkLtdAParent}>
                <div className={styles.copyright2022Loggwork}>
                  ©Copyright 2024 loggworks  Ltd.  All Rights Reserved
                </div>
                <div className={styles.frameParent26}>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/terms')}}>
                      Terms of Use
                    </div>
                    <div className={styles.frameChild9} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div
                      className={styles.copyright2022Loggwork}
                      style={{cursor:'pointer'}}
                      onClick={()=>{navigate('/privacy')}}
                    >{`Privacy Policy `}</div>
                    <div className={styles.frameChild9} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/cookie')}}>Cookies</div>
                    <div className={styles.frameChild9} />
                  </div>
                  <div className={styles.contactUsParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Contact Us
                    </div>
                    <div className={styles.frameChild9} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork} style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href='https://www.loggworks.co.uk/sitemap.xml'
                    }}>Sitemap</div>
                    <div className={styles.frameChild13} />
                  </div>
                </div>
              </div>
              <div className={styles.largeButtonsGroup}>
                <button
                  className={styles.largeButtons8} style={{cursor: 'pointer'}}
                  onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder221.svg"
                  />
                  <b className={styles.label8}>Become a Professional</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder221.svg"
                  />
                </button>
                <button
                  className={styles.largeButtons8}
                  onClick={onLargeButtons1Click}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder110.svg"
                  />
                  <b className={styles.label8}>Post a Job</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder110.svg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMobileMenuProfProfessionalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuProfProfessionalPopup}
        >
          <MobileMenuProfProfessional
            onClose={closeMobileMenuProfProfessionalPopup}
          />
        </PortalPopup>
      )}
      {isDropdownOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDropdown}
        >
          <Dropdown2 onClose={closeDropdown} />
        </PortalPopup>
      )}
    </>
  );
};

export default ConsumerHome;
