import { useState, useCallback } from "react";
import Dropdown4 from "../components/Dropdown4";
import PortalPopup from "../components/PortalPopup";
import Dropdown5 from "../components/Dropdown5";
import { useNavigate } from "react-router-dom";
import styles from "./Post4.module.css";
const Post4 = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdown1Open, setDropdown1Open] = useState(false);
  const navigate = useNavigate();

  const onUploadImageContainerClick = useCallback(() => {
    // Please sync "Messages" to the project
  }, []);

  const onPageSelectorContainerClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHowItWorksClick = useCallback(() => {
    navigate("/consumerhow-it-works");
  }, [navigate]);

  const openDropdown = useCallback(() => {
    setDropdownOpen(true);
  }, []);

  const closeDropdown = useCallback(() => {
    setDropdownOpen(false);
  }, []);

  const openDropdown1 = useCallback(() => {
    setDropdown1Open(true);
  }, []);

  const closeDropdown1 = useCallback(() => {
    setDropdown1Open(false);
  }, []);

  const onFrameButtonClick = useCallback(() => {
    navigate("/consumerhelpfaqdone");
  }, [navigate]);

  const onSmallButtonsClick = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  return (
    <>
      <div className={styles.post4}>
        <div className={styles.messages}>
          <div className={styles.frameParent}>
            <div className={styles.frameWrapper}>
              <div className={styles.postAJobParent}>
                <b className={styles.postAJob}>Post a job</b>
                <div className={styles.letsStartWith}>
                  Lets start with the basics
                </div>
              </div>
            </div>
            <div className={styles.uploadAnImageOptionalParent}>
              <div className={styles.uploadAnImage}>
                Upload an image (Optional)
              </div>
              <div className={styles.frameGroup}>
                <div className={styles.uploadImageParent}>
                  <div
                    className={styles.uploadImage}
                    onClick={onUploadImageContainerClick}
                  >
                    <div className={styles.imageFrame}>
                      <div className={styles.boundary} />
                      <div className={styles.imageArea} />
                      <img
                        className={styles.iconsadd}
                        alt=""
                        src="/iconsadd28.svg"
                      />
                    </div>
                    <img
                      className={styles.addcancelCircleIcon}
                      alt=""
                      src="/addcancel-circle.svg"
                    />
                  </div>
                  <div className={styles.uploadImage1}>
                    <div className={styles.imageFrame}>
                      <div className={styles.boundary} />
                      <div className={styles.imageArea} />
                      <img
                        className={styles.iconsadd}
                        alt=""
                        src="/iconsadd28.svg"
                      />
                    </div>
                    <img
                      className={styles.addcancelCircleIcon}
                      alt=""
                      src="/addcancel-circle.svg"
                    />
                  </div>
                  <div className={styles.uploadImage1}>
                    <div className={styles.imageFrame}>
                      <div className={styles.boundary} />
                      <div className={styles.imageArea} />
                      <img
                        className={styles.iconsadd}
                        alt=""
                        src="/iconsadd28.svg"
                      />
                    </div>
                    <img
                      className={styles.addcancelCircleIcon}
                      alt=""
                      src="/addcancel-circle.svg"
                    />
                  </div>
                  <div className={styles.uploadImage1}>
                    <div className={styles.imageFrame}>
                      <div className={styles.boundary} />
                      <div className={styles.imageArea} />
                      <img
                        className={styles.iconsadd}
                        alt=""
                        src="/iconsadd28.svg"
                      />
                    </div>
                    <img
                      className={styles.addcancelCircleIcon}
                      alt=""
                      src="/addcancel-circle.svg"
                    />
                  </div>
                </div>
                <div className={styles.addSupportingImages}>
                  Add supporting images to best showcase your job request
                </div>
              </div>
            </div>
            <div className={styles.largeButtons}>
              <img
                className={styles.icons20pxplaceholder}
                alt=""
                src="/icons20pxplaceholder64.svg"
              />
              <b className={styles.label}>Post Job</b>
              <img
                className={styles.icons20pxplaceholder}
                alt=""
                src="/icons20pxplaceholder64.svg"
              />
            </div>
          </div>
          <div className={styles.iconParent}>
            <div className={styles.icon}>
              <img
                className={styles.iconschevronRight}
                alt=""
                src="/iconschevronright10.svg"
              />
            </div>
            <div className={styles.postAJobProgressTracker}>
              <div className={styles.jobDetails}>Job details</div>
              <div className={styles.location}>Location</div>
              <div className={styles.budget}>Budget</div>
              <div className={styles.uploadImages}>Upload images</div>
              <img
                className={styles.progressBarIcon}
                alt=""
                src="/progress-bar.svg"
              />
            </div>
          </div>
          <nav className={styles.navigation}>
            <div className={styles.navigation1}>
              <img
                className={styles.navigationChild}
                alt=""
                src="/frame-665223.svg"
              />
              <div className={styles.navigationItem} />
              <div className={styles.tabSwitch}>
                <div
                  className={styles.pageSelector}
                  onClick={onPageSelectorContainerClick}
                >
                  <div className={styles.descriptionParent}>
                    <div className={styles.description}>Professional</div>
                    <div className={styles.selector} />
                  </div>
                </div>
                <div className={styles.pageSelector1}>
                  <div className={styles.descriptionParent}>
                    <b className={styles.description1}>Consumer</b>
                    <div className={styles.selector1} />
                  </div>
                </div>
              </div>
              <div className={styles.searchBar}>
                <div className={styles.iconssearchWrapper}>
                  <img
                    className={styles.iconschevronRight}
                    alt=""
                    src="/iconssearch.svg"
                  />
                </div>
                <div className={styles.electrician}>Electrician</div>
                <div className={styles.dividerParent}>
                  <div className={styles.divider} />
                  <div className={styles.location1}>
                    <img
                      className={styles.iconslocation}
                      alt=""
                      src="/iconslocation.svg"
                    />
                    <div className={styles.locationOrPostcode}>
                      Location or postcode
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.navigation2}>
              <div className={styles.homeParent}>
                <div className={styles.home} onClick={onHomeTextClick}>
                  Home
                </div>
                <div className={styles.howItWorks} onClick={onHowItWorksClick}>
                  How it works
                </div>
                <div className={styles.services} onClick={openDropdown}>
                  <div className={styles.services1}>Services</div>
                  <img
                    className={styles.iconschevronRight}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                <div className={styles.services} onClick={openDropdown1}>
                  <div className={styles.services1}>Resources</div>
                  <img
                    className={styles.iconschevronRight}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                <div className={styles.pricing}>Pricing</div>
              </div>
              <div className={styles.frameContainer}>
                <div className={styles.frameDiv}>
                  <button
                    className={styles.iconshelpParent}
                    onClick={onFrameButtonClick}
                  >
                    <img
                      className={styles.iconschevronRight}
                      alt=""
                      src="/iconshelp.svg"
                    />
                    <div className={styles.help}>Help</div>
                  </button>
                  <button className={styles.iconshelpParent}>
                    <img
                      className={styles.iconschevronRight}
                      alt=""
                      src="/iconsaccount2111.svg"
                    />
                    <div className={styles.help}>Account</div>
                  </button>
                </div>
                <button
                  className={styles.smallButtons}
                  onClick={onSmallButtonsClick}
                >
                  <div className={styles.iconsaddParent}>
                    <img
                      className={styles.iconsadd4}
                      alt=""
                      src="/iconsadd15.svg"
                    />
                    <div className={styles.button}>Post a job</div>
                  </div>
                </button>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {isDropdownOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDropdown}
        >
          <Dropdown4 onClose={closeDropdown} />
        </PortalPopup>
      )}
      {isDropdown1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDropdown1}
        >
          <Dropdown5 onClose={closeDropdown1} />
        </PortalPopup>
      )}
    </>
  );
};

export default Post4;
