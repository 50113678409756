import React,{useCallback}from 'react'
import styles from '../Homeblog.module.css'
import { useNavigate } from 'react-router-dom';

function ForProfessionalTab() {
  const navigate = useNavigate();
  const onForConsumersTextClick = useCallback(() => {
    navigate("/blog/for-consumers");
  }, [navigate]);

  const onForProfessionalsTextClick = useCallback(() => {
    navigate("/blog/for-professionals");
  }, [navigate]);

  const onNewsUpdatesClick = useCallback(() => {
    navigate("/blog/news");
  }, [navigate]);
  return (
    <div>
        <div className={styles.component7Parent}>
              <div className={styles.component7}
               style={{cursor:'pointer'}}
               onClick={()=>navigate('/blog/for-professionals/business-advice')}>
                <div className={styles.hiringGuides4}>Business advice</div>
              </div>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/for-professionals/managing-your-business')}>
                <div className={styles.hiringGuides4}>
                  managing your business
                </div>
              </div>
              <div className={styles.component7}
                style={{cursor:'pointer'}}
                onClick={()=>navigate('/blog/for-professionals/finance-advice')}>
                <div className={styles.hiringGuides4}>finance advice</div>
              </div>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/for-professionals/spotlight')}>
                <div className={styles.hiringGuides4}>spotlight</div>
              </div>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/for-professionals/tips')}>
                <div className={styles.hiringGuides4}>Tips</div>
              </div>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/for-professionals/how-to')}>
                <div className={styles.hiringGuides4}>How To</div>
              </div>
            </div>

            <div className={styles.frameParent51}>
              <div className={styles.tipsForFindingTheRightHomParent}>
                <div className={styles.frameParent52}>
                  <div className={styles.hiringGuidesWrapper} 
                   onClick={()=>navigate('/blog/for-professionals/business-advice')}>
                    <div className={styles.managingYourBusiness}>
                      Business advice
                    </div>
                  </div>
                  <div className={styles.hiringGuidesWrapper}
                   onClick={()=>navigate('/blog/for-professionals/managing-your-business')}>
                    <div className={styles.managingYourBusiness}>
                      managing your business
                    </div>
                  </div>
                </div>
                <div className={styles.frameParent52}>
                  <div className={styles.hiringGuidesWrapper}
                   onClick={()=>navigate('/blog/for-professionals/finance-advice')}>
                    <div className={styles.managingYourBusiness}>
                      finance advice
                    </div>
                  </div>
                  <div className={styles.hiringGuidesWrapper}
                   onClick={()=>navigate('/blog/for-professionals/spotlight')}>
                    <div className={styles.managingYourBusiness}>spotlight</div>
                  </div>
                  <div className={styles.hiringGuidesWrapper}
                   onClick={()=>navigate('/blog/for-professionals/tips')}>
                    <div className={styles.managingYourBusiness}>Tips</div>
                  </div>
                </div>
                <div className={styles.frameWrapper8}
                 onClick={()=>navigate('/blog/for-professionals/how-to')}>
                  <div className={styles.hiringGuidesWrapper}>
                    <div className={styles.managingYourBusiness}>How To</div>
                  </div>
                </div>
              </div>
            </div>
    </div>
  )
}

export default ForProfessionalTab