import { useState, useCallback, useEffect } from "react";
import Dropdown from "../components/Dropdown";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./PricePlan.module.css";
import mobstyle from "./BecomeTeam.module.css";
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import LocationSearch from "../components/locationSearch.tsx";
import MobileMenuConsumer1 from "../components/MobileMenuConsumer1";
import MobileFooter from "../components/MobileFooter";
import Priceplancomp from "../components/Priceplancomp";
import MobileMenuProfProfessional1 from "../components/MobileMenuProfProfessional1";
import { db } from "../firebase";

const PricePlan = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(null);
  const open = Boolean(isDropdownOpen);
  const [payg, setPayg]=useState(0)
  const [monthly, setMonthly]=useState(0)
  const openDropdown = (event) => {
    setDropdownOpen(event.currentTarget);
  };
  const closeDropdown = () => {
    setDropdownOpen(null);
  };
  const navigate = useNavigate();
  const [isMobileMenuConsumerPopupOpen, setMobileMenuConsumerPopupOpen] =
    useState(false);
    
  const openMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(true);
  }, []);

  const closeMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(false);
  }, []);

  const onPageSelectorContainer1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/professional");
  }, [navigate]);

  const onServicesContainerClick = useCallback(() => {
    navigate("/category");
  }, [navigate]);

  const onPricingTextClick = useCallback(() => {
    navigate("/professionalhiring-guidearticle");
  }, [navigate]);

  const onFrameContainer5Click = useCallback(() => {
    navigate("/professional-faq");
  }, [navigate]);

  const onPostAJobClick = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onHowItWorks1Click = useCallback(() => {
    navigate("/consumerhow-it-works");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    navigate("/consumerhelpcontactdone");
  }, [navigate]);

  const onHelpFAQsClick = useCallback(() => {
    navigate("/consumerhelpfaqdone");
  }, [navigate]);

  const onUserAgreementTextClick = useCallback(() => {
    navigate("/careersdone");
  }, [navigate]);

  const onHowItWorks2Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onContactUsText1Click = useCallback(() => {
    navigate("/consumerhelpcontact");
  }, [navigate]);

  const onHelpFAQs1Click = useCallback(() => {
    navigate("/professionalhelpfaq");
  }, [navigate]);

  const onUserAgreementText1Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-usdone");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/consumerhelpfaqdone1");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/professionalhelpcontactdone");
  }, [navigate]);

  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/professionalhelpcontact2");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onLargeButtons1Click = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);
  useEffect(() => {
    db.collection('pricing').doc('payg').get().then(function (snap) {
      setPayg(snap.data()?.value)
    })
    db.collection('pricing').doc('monthly').get().then(function (snap) {
      setMonthly(snap.data()?.value)
    })
  }, [])

  return (
    <>

      <div className={styles.priceplan}>
        <div className={styles.professionalhiringGuidearti}>
          <div className={styles.professionalNavigation}>
            <div className={styles.navigation}>
              <img
                className={styles.navigationChild}
                alt=""
                onClick={()=>navigate('/')}
                style={{cursor:'pointer'}}
                src="/frame-66522.svg"
              />
              <div className={styles.navigationItem} />
              <div className={styles.tabSwitch}>
                <div className={styles.pageSelector}>
                  <div className={styles.descriptionParent}>
                    <b className={styles.description}>Professional</b>
                    <div className={styles.selector} />
                  </div>
                </div>
                <div
                  className={styles.pageSelector1}
                  onClick={onPageSelectorContainer1Click}
                >
                  <div className={styles.descriptionParent}>
                    <div className={styles.copyright2022Loggwork}>Consumer</div>
                    <div className={styles.selector1} />
                  </div>
                </div>
              </div>
             {/*<LocationSearch/>*/}
            </div>
            <div className={styles.navigation1}>
              <div className={styles.homeParent}>
                <div className={styles.home} onClick={onHomeTextClick}>
                  Home
                </div>
                <div className={styles.howItWorks}>How it works</div>
                <div
                  className={styles.services}
                  onClick={onServicesContainerClick}
                >
                  <div className={styles.services1}>Categories</div>
                  <img
                    className={styles.iconschevronDown}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                <div className={styles.services} 
                 id="fade-button"
  aria-controls={open ? 'fade-menu' : undefined}
  aria-haspopup="true"
  aria-expanded={open ? 'true' : undefined}
                onClick={openDropdown}>
                  <div className={styles.services1}>Resources</div>
                  <img
                    className={styles.iconschevronDown}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={isDropdownOpen}
        open={open}
        onClose={closeDropdown}
        TransitionComponent={Fade}
      >
      <Dropdown/>
      </Menu>
                <div className={styles.pricing} onClick={onPricingTextClick}>
                  Pricing
                </div>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.frameGroup}>
                  <div
                    className={styles.iconshelpParent}
                    onClick={onFrameContainer5Click}
                  >
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/iconshelp.svg"
                    />
                    <div className={styles.help}>Help</div>
                  </div>
                  <div className={styles.iconsaccountParent}
                  style={{cursor:'pointer'}}
                  onClick={()=>window.open('https://www.professional.loggworks.co.uk/', '_blank')}>
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/iconsaccount11.svg"
                    />
                    <div className={styles.help}>Account</div>
                  </div>
                </div>
                <div className={styles.smallButtons}
                 style={{cursor:'pointer'}}
                 onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                  <div className={styles.buttonWrapper}>
                    <div className={styles.button}>Become a professional</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.frameWrapper}>
              <div className={styles.iconsaccountParent}>
                <div className={styles.chooseThePerfectPlanForYoParent}>
                  <b className={styles.chooseThePerfect}>
                    Choose the perfect plan for you
                  </b>
                  <div className={styles.loggworksConnectHomeowners}>
                    Loggworks connect homeowners to genuine and reliable service
                    providers who provide easy-to-book and hassle-free services.
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.frameParent2}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.paygParent}>
                    <div className={styles.payg}>PAYG</div>
                    <div className={styles.parent}>
                      <b className={styles.b}>{payg}%</b>
                      <div className={styles.commissionjob}>Commission/Job</div>
                    </div>
                  </div>
                </div>
                <div className={styles.frameWrapper1}>
                  <div className={styles.paygParent}>
                    <div className={styles.payg}>Monthly</div>
                    <div className={styles.parent}>
                      <div className={styles.b}>
                        <b>£{monthly}</b>
                      </div>
                      <div className={styles.commissionjob}>Commission/Job</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.featuresParent}>
                <div className={styles.features}>Features</div>
                <div className={styles.frameParent3}>
                  <div className={styles.frameParent4}>
                    <div className={styles.getAllThePerksInOurMonthWrapper}>
                      <div className={styles.getAllThe}>
                        Get all the perks in our monthly subscription plan
                      </div>
                    </div>
                    <div className={styles.sendOffersToServiceConsumeWrapper}>
                      <div className={styles.getAllThe}>
                        Send offers to service consumers directly to improve
                        chances of getting jobs.
                      </div>
                    </div>
                    <div className={styles.getAllThePerksInOurMonthWrapper}>
                      <div className={styles.getAllThe}>
                        Get jobs directly on your dashboard at no extra cost.
                      </div>
                    </div>
                  </div>
                  <div className={styles.frameParent4}>
                    <div className={styles.getAllThePerksInOurMonthWrapper}>
                      <div className={styles.getAllThe}>
                        Automatically manage your jobs with our job management
                        feature.
                      </div>
                    </div>
                    <div className={styles.sendOffersToServiceConsumeWrapper}>
                      <div className={styles.getAllThe}>
                        Send a quote to receive payment swiftly.
                      </div>
                    </div>
                    <div className={styles.getAllThePerksInOurMonthWrapper}>
                      <div className={styles.getAllThe}>
                        Share your business on any platform and get booked.
                      </div>
                    </div>
                    <div className={styles.sendOffersToServiceConsumeWrapper}>
                      <div className={styles.getAllThe}>
                        Get vetted for free to improve your business reputation
                        and increase visibility.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.prototypeFooter}>
            <div className={styles.footer}>
              <div className={styles.download}>
                <div className={styles.downloadOurApps}>Download our apps</div>
                <img
                  className={styles.playstoreButtonIcon}
                  alt=""
                  src="/playstore-button3.svg"
                />
                <img
                  className={styles.appstoreButtonIcon}
                  alt=""
                  src="/appstore-button3.svg"
                />
              </div>
              <div className={styles.footerChild} />
              <img className={styles.layer2Icon} 
               style={{cursor:'pointer'}}
               onClick={()=>{navigate('/')}}
               alt="" src="/layer-25.svg" />
              <div className={styles.frameParent5}>
                <div className={styles.homeownersParent}>
                  <b className={styles.homeowners}>Homeowners</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.postAJob} onClick={onPostAJobClick}>
                      Post a Job
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHowItWorks1Click}
                    >
                      How it works
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onContactUsTextClick}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHelpFAQsClick}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.postAJob}
                      onClick={onUserAgreementTextClick}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.professionalsParent}>
                  <b className={styles.homeowners}>Professionals</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.signUp}
                    style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                    <div
                      className={styles.postAJob}
                      onClick={onHowItWorks2Click}
                    >
                      Blog
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onContactUsText1Click}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHelpFAQs1Click}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.postAJob}
                      onClick={onUserAgreementText1Click}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.loggworksParent}>
                  <b className={styles.homeowners}>Loggworks</b>
                  <div className={styles.postAJobParent}>
                    <div
                      className={styles.postAJob}
                      onClick={onAboutUsTextClick}
                    >
                      About us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onCareersTextClick}
                    >
                      Careers
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onPartnersTextClick}
                    >
                      Partners
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onInfluencerProgramTextClick}
                    >
                      Affiliate program
                    </div>
                    <div className={styles.blog}>Blog</div>
                  </div>
                </div>
                <div className={styles.loggworksGroup}>
                  <div className={styles.loggworks1}>
                    <b className={styles.followUs}>Follow us</b>
                  </div>
                  <div className={styles.groupParent}>
                    <div className={styles.groupDiv}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://web.facebook.com/Loggworks?_rdc=1&_rdr', '_blank')}>
                      <img
                        className={styles.frameIcon}
                        alt=""
                        src="/frame20.svg"
                      />
                      <div className={styles.facebook}>Facebook</div>
                    </div>
                    <div className={styles.frameParent6}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://twitter.com/loggworks', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame211.svg"
                      />
                      <div className={styles.twitter}>Twitter</div>
                    </div>
                    <div className={styles.frameParent7}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.instagram.com/loggworks/?utm_medium=copy_link', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame22.svg"
                      />
                      <div className={styles.twitter}>Instagram</div>
                    </div>
                    <div className={styles.frameParent8}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame23.svg"
                      />
                      <div className={styles.twitter}>YouTube</div>
                    </div>
                    <div className={styles.mdilinkedinParent}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.linkedin.com/company/loggworks', '_blank')}>
                      <img
                        className={styles.mdilinkedinIcon}
                        alt=""
                        src="/mdilinkedin1.svg"
                      />
                      <div className={styles.linkedin}>LinkedIn</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.copyright2022LoggworkLtdAParent}>
                <div className={styles.copyright2022Loggwork}>
                  ©Copyright 2024 loggworks  Ltd. All Rights Reserved
                </div>
                <div className={styles.frameParent9}>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/terms')}}>
                      Terms of Use
                    </div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div
                      className={styles.copyright2022Loggwork}
                      style={{cursor:'pointer'}}
                      onClick={()=>{navigate('/privacy')}}
                    >{`Privacy Policy `}</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                     style={{cursor:'pointer'}}
                     onClick={()=>{navigate('/cookie')}}>Cookies</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.contactUsParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Contact Us
                    </div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork} style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href='https://www.loggworks.co.uk/sitemap.xml'
                    }}>Sitemap</div>
                    <div className={styles.frameChild1} />
                  </div>
                </div>
              </div>
              <div className={styles.largeButtonsParent}>
                <button
                  className={styles.largeButtons}
                  style={{cursor:'pointer'}}
                  onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder27.svg"
                  />
                  <b className={styles.label}>Become a Professional</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder27.svg"
                  />
                </button>
                <button
                  className={styles.largeButtons}
                  onClick={onLargeButtons1Click}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder28.svg"
                  />
                  <b className={styles.label}>Post a Job</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder28.svg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={mobstyle.homeMobile}>
          <div className={mobstyle.navbarMobileclosed}>
            <img className={mobstyle.layer2Icon1} alt="" src="/layer-27.svg" 
            style={{cursor:'pointer'}} onClick={()=>navigate('/')} />
            <div className={mobstyle.iconsaccountGroup}>
              <img
                className={mobstyle.iconsaccount1}
                alt=""
                src="/iconsaccount5.svg"
              />
              <img
                className={mobstyle.hamburgerIcon}
                alt=""
                src="/hamburger.svg"
                onClick={openMobileMenuConsumerPopup}
              />
            </div>
          </div>
          <div className={mobstyle.homeMobileInner}>
          <Priceplancomp
          payg={payg}
          monthly={monthly}
          />
          </div>
         <MobileFooter/>
        </div>
        {isMobileMenuConsumerPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuConsumerPopup}
        >
          <MobileMenuProfProfessional1 onClose={closeMobileMenuConsumerPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default PricePlan;
