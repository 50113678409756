import { useCallback, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./MobileMenuConsumer1.module.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationSearch from "./locationSearch2.tsx";
import { useState } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import MobileMenuProfProfessional1 from "./MobileMenuProfProfessional1";

const MobileMenuConsumer1 = ({ onClose }) => {
  const navigate = useNavigate();
  const [tab,setTab]=useState(0)
  const [address, setAddress] = useState('');
  const [error, setError] = useState(null);
  const location = useLocation(); // Use useLocation hook
  const pathname = location.pathname;
  let {prof,loc}=useParams()

  useEffect(() => {
    //getCurrentLocation();
  }, []);
const getCurrentLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;

        // Replace 'YOUR_GOOGLE_API_KEY' with your actual Google API key
        const apiKey = 'AIzaSyDrIc0MDLHaoFOaSLFKPRcGG-bM3Xpt-w0';
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

        try {
          const response = await fetch(apiUrl);
          const data = await response.json();

          if (data.status === 'OK' && data.results && data.results.length > 0) {
            const formattedAddress = data.results[0].formatted_address;
            setAddress(formattedAddress);
          } else {
            setError('Address not found');
          }
        } catch (error) {
          setError('Error fetching address');
        }
      },
      (error) => {
        setError(`Geolocation error: ${error.message}`);
      }
    );
  } else {
    setError('Geolocation is not supported by your browser');
  }
};

  const onBookingContainerClick = useCallback(() => {
    setTab(1)
  }, [navigate]);

  const onChildcareTextClick = useCallback(() => {
    // Please sync "Home Mobile" to the project
  }, []);

  const onFrameContainer2Click = useCallback(() => {
   navigate('/blog/for-consumers/hiring-guide')
  }, []);

  const onFrameContainer3Click = useCallback(() => {
   navigate('/blog/for-consumers/pricing-guides')
  }, []);

  const onFrameContainer4Click = useCallback(() => {
    navigate('/blog');
  }, []);

  return (
  <>{
    tab==0?
   <div className={styles.mobileMenuconsumer}>
    <div style={{display:'flex',justifyContent:'right',width:'80vw',padding:'1rem'}}>
      <div style={{cursor:'pointer'}}
      onClick={()=>{
        if(!pathname.includes('/consumers/')&&!pathname.includes('/professionals/')){
          navigate('/')
        }else{
          navigate('/consumers/'+prof+'/'+loc)
        }
        onClose()
        }}>
        <ClearIcon/>
      </div>
    </div>
      <div className={styles.tabsSubSwitch}>
        <div className={styles.allParent}>
          <div className={styles.all}>
            <div className={styles.booking}>Consumer</div>
          </div>
          <div className={styles.booking1} onClick={onBookingContainerClick}>
            <div className={styles.booking2}>Professional</div>
          </div>
        </div>
      </div>
      <div className={styles.inputWrapper}>
        <div className={styles.input}>
          <div className={styles.labelContainer}>
            <div className={styles.label}>Label</div>
          </div>
          <div className={styles.baseField}>
          <LocationSearch/>
          </div>
          <div className={styles.helperTextContainer}>
            <div className={styles.thisIsA}>This is a helper text</div>
            <div className={styles.div}>1/100</div>
          </div>
        </div>
      </div>
      <div className={styles.frameParent}>
        <div className={styles.homeParent}>
          <div className={styles.home} style={{marginBottom:'1rem'}} onClick={()=>{navigate("/");}}>Home</div>
          <div className={styles.home} style={{marginBottom:'0.4rem'}} onClick={()=>{navigate("/how-we-work");}}>How it works</div>
          <div className={styles.menuServices}>
            <div className={styles.services}>
                <Accordion  elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            maxHeight: '5px', // Reset max-height to allow dynamic height
            width:'5.61rem'
          }}
        >
          <a className={styles.resources}>Services</a>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.list} style={{width:'15rem'}}>
             { /*<div className={styles.catering}
               style={{cursor:'pointer'}}
               onClick={()=>{
              
                 navigate('/category/catering');
                 onClose()
               }}>Catering</div>
              <div className={styles.childcare} 
               style={{cursor:'pointer'}}
               onClick={()=>{
                  
                 navigate('/category/childcare');
                 onClose()
               }}>
                Childcare
              </div>*/}
              <div className={styles.catering}
               style={{cursor:'pointer'}}
               onClick={()=>{
                  
                 navigate('/category/cleaning');
                 onClose()
               }}>Cleaning</div>
              {/*<div className={styles.catering}
               style={{cursor:'pointer'}}
               onClick={()=>{
                  
                 navigate('/category/entertainment');
                 onClose()
               }}>Entertainment</div>
              <div className={styles.catering}
               style={{cursor:'pointer'}}
               onClick={()=>{
                  
                 navigate('/category/appliance-repair');
                 onClose()
               }}>Appliance Repair</div>
              <div className={styles.catering}
               style={{cursor:'pointer'}}
               onClick={()=>{
                  
                 navigate('/category/mobile-mechanic');
                 onClose()
               }}>Mobile Mechanic</div>
              <div className={styles.catering}
               style={{cursor:'pointer'}}
               onClick={()=>{
                  
                 navigate('/category/personal-assistance/');
                 onClose()
               }}>Personal Assistance</div>
              <div className={styles.catering}
               style={{cursor:'pointer'}}
               onClick={()=>{
                  
                 navigate('/category/pet-groomers');
                 onClose()
               }}>Pet Groomers</div>
              <div className={styles.catering}
               style={{cursor:'pointer'}}
               onClick={()=>{
                  
                 navigate('/category/recovery-services');
                 onClose()
               }}>Recovery Services</div>
              <div className={styles.catering}
               style={{cursor:'pointer'}}
               onClick={()=>{
                  
                 navigate('/category/removal-services');
                 onClose()
               }}>Removal Services</div>*/}
              <div className={styles.catering}
               style={{cursor:'pointer'}}
               onClick={()=>{
                  
                 navigate('/category/tradesmen');
                 onClose()
               }}>Tradesmen</div>
              {/*<div className={styles.catering}
               style={{cursor:'pointer'}}
               onClick={()=>{
                  
                 navigate('/category/transport-services');
                 onClose()
               }}>Transport Services</div>
              <div className={styles.catering}
               style={{cursor:'pointer'}}
               onClick={()=>{
                  
                 navigate('/category/computer-&-phone-repair');
                 onClose()
               }}>{`Computer & Phone Repair`}</div>
              <div className={styles.catering}>{`Dry Cleaning & Laundry`}</div>
              <div
                className={styles.catering}
                style={{cursor:'pointer'}}
                onClick={()=>{
                  navigate('/category/health,-wellness-&-beauty');
                  onClose()
                }}
              >{`Health, Wellness & Beauty`}</div>
              <div className={styles.catering}
               style={{cursor:'pointer'}}
               onClick={()=>{
                  
                 navigate('/category/tuition-&-classes');
                 onClose()
               }}>{`Tuition & Classes`}</div>*/}
            </div>
        </AccordionDetails>
      </Accordion>
            </div>
            
          </div>
          <div className={styles.menuServices} style={{marginBottom:'0.5rem'}}>
            <div className={styles.services}>
              <div className={styles.resources}>
                <Accordion style={{minHeight:'36px'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            maxHeight: '5px', // Reset max-height to allow dynamic height
            width:'6.52rem',
          }}
        >
          <a>Resources</a>
        </AccordionSummary>
        <AccordionDetails
         style={{
          maxHeight: 'unset', // Reset max-height to allow dynamic height
        }}>
          <div className={styles.list}>
          <div
                className={styles.hiringGuideParent}
                onClick={onFrameContainer2Click}
              >
                <div className={styles.hiringGuide}>Hiring Guide</div>
                <div className={styles.whatIsA}>What is a hiring guide?</div>
              </div>
              <div
                className={styles.hiringGuideParent}
                onClick={onFrameContainer3Click}
              >
                <div className={styles.hiringGuide}>Pricing Guide</div>
                <div className={styles.whatIsA}>What is a pricing guide?</div>
              </div>
              <div
                className={styles.hiringGuideParent}
                onClick={onFrameContainer4Click}
              >
                <div className={styles.hiringGuide}>Blog</div>
                <div className={styles.whatIsA}>Latest news and articles</div>
              </div>
              <div className={styles.trainingAcademyParent}>
                <div className={styles.hiringGuide}>{`Training & Academy`}</div>
                <div className={styles.professionalPill}>
                  <div className={styles.icons16pxplaceholderParent}>
                    <img
                      className={styles.icons16pxplaceholder}
                      alt=""
                      src="/icons16pxplaceholder11.svg"
                    />
                    <div className={styles.childcare1}>Coming Soon</div>
                  </div>
                </div>
              </div>
            </div>
        </AccordionDetails>
      </Accordion>
              </div>
                  
            </div>
            
          </div>
          <div className={styles.home} onClick={()=>navigate('/faq')}>Help</div>
        </div>
        <div className={styles.largeButtons} onClick={()=>navigate('/postjob')}>
          <img className={styles.iconsadd} alt="" src="/iconsadd24.svg" />
          <b className={styles.label1}>Post a Job</b>
          <img className={styles.iconsadd1} alt="" src="/iconsadd25.svg" />
        </div>
      </div>
    </div>
    :
    <MobileMenuProfProfessional1 onClose={onClose}/>
    }
  </>
   
  );
};

export default MobileMenuConsumer1;
