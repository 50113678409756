import { useState, useCallback } from "react";
import MobileMenuConsumer1 from "../components/MobileMenuConsumer1";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./HowWeWork.module.css";
import MobileFooter from "../components/MobileFooter";
import LocationSearch from "../components/locationSearch.tsx";
import Dropdown4 from "../components/Dropdown4";
import { Fade, Menu } from "@mui/material";
import Dropdown5 from "../components/Dropdown5";
const HowWeWork = () => {
  const [isMobileMenuConsumerPopupOpen, setMobileMenuConsumerPopupOpen] =
    useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(null);
  const open = Boolean(isDropdownOpen);
  const openDropdown = (event) => {
    setDropdownOpen(event.currentTarget);
  };
  const closeDropdown = () => {
    setDropdownOpen(null);
  };
  const [isDropdown1Open, setDropdown1Open] = useState(null);
  const open1 = Boolean(isDropdown1Open);
   const openDropdown1 = (event) => {
    setDropdown1Open(event.currentTarget);
  };
  const closeDropdown1 = () => {
    setDropdown1Open(null);
  };
  const navigate = useNavigate();

  const onPostAJobClick = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onHowItWorksClick = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQsClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementTextClick = useCallback(() => {
    navigate("/agreement");
  }, [navigate]);

  const onHowItWorks1Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onContactUsText1Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQs1Click = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementText1Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/becometeam");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/partners");
  }, [navigate]);

  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/affiliate-program");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onLargeButtons1Click = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onPageSelectorContainerClick = useCallback(() => {
    navigate("/professional");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onFrameButtonClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const openMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(true);
  }, []);

  const closeMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(false);
  }, []);

  return (
    <>
      <div className={styles.howWeWork}>
        <div className={styles.consumerhowItWorks}>
          <div className={styles.prototypeFooterParent}>
            <div className={styles.prototypeFooter}>
              <div className={styles.footer}>
                <div className={styles.download}>
                  <div className={styles.downloadOurApps}>
                    Download our apps
                  </div>
                  <img
                    className={styles.playstoreButtonIcon}
                    alt=""
                    src="/playstore-button16.svg"
                  />
                  <img
                    className={styles.appstoreButtonIcon}
                    alt=""
                    src="/appstore-button17.svg"
                  />
                </div>
                <div className={styles.footerChild} />
                <img
                  className={styles.layer2Icon}
                  alt=""
                  style={{cursor:'pointer'}}
                  onClick={()=>{navigate('/')}}
                  src="/layer-2131.svg"
                />
                <div className={styles.frameParent}>
                  <div className={styles.homeownersParent}>
                    <b className={styles.homeowners}>Homeowners</b>
                    <div className={styles.postAJobParent}>
                      <div
                        className={styles.postAJob}
                        onClick={onPostAJobClick}
                      >
                        Post a Job
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onHowItWorksClick}
                      >
                        How it works
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onContactUsTextClick}
                      >
                        Contact Us
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onHelpFAQsClick}
                      >{`Help & FAQ’s`}</div>
                      <div
                        className={styles.postAJob}
                        onClick={onUserAgreementTextClick}
                      >
                        User Agreement
                      </div>
                    </div>
                  </div>
                  <div className={styles.professionalsParent}>
                    <b className={styles.homeowners}>Professionals</b>
                    <div className={styles.postAJobParent}>
                      <div className={styles.signUp}
                      style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                      <div
                        className={styles.postAJob}
                        onClick={onHowItWorks1Click}
                      >
                        Blog
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onContactUsText1Click}
                      >
                        Contact Us
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onHelpFAQs1Click}
                      >{`Help & FAQ’s`}</div>
                      <div
                        className={styles.postAJob}
                        onClick={onUserAgreementText1Click}
                      >
                        User Agreement
                      </div>
                    </div>
                  </div>
                  <div className={styles.loggworksParent}>
                    <b className={styles.homeowners}>Loggworks</b>
                    <div className={styles.postAJobParent}>
                      <div
                        className={styles.postAJob}
                        onClick={onAboutUsTextClick}
                      >
                        About us
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onCareersTextClick}
                      >
                        Careers
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onPartnersTextClick}
                      >
                        Partners
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onInfluencerProgramTextClick}
                      >
                        Affiliate program
                      </div>
                      <div className={styles.blog}>Blog</div>
                    </div>
                  </div>
                  <div className={styles.loggworksGroup}>
                    <div className={styles.loggworks1}>
                      <b className={styles.followUs}>Follow us</b>
                    </div>
                    <div className={styles.groupParent}>
                      <div className={styles.frameGroup}
                        style={{cursor:'pointer'}}
                        onClick={()=>window.open('https://web.facebook.com/Loggworks?_rdc=1&_rdr', '_blank')}>
                        <img
                          className={styles.frameIcon}
                          alt=""
                          src="/frame20.svg"
                        />
                        <div className={styles.facebook}>Facebook</div>
                      </div>
                      <div className={styles.frameContainer}
                        style={{cursor:'pointer'}}
                        onClick={()=>window.open('https://twitter.com/loggworks', '_blank')}>
                        <img
                          className={styles.frameIcon1}
                          alt=""
                          src="/frame37.svg"
                        />
                        <div className={styles.twitter}>Twitter</div>
                      </div>
                      <div className={styles.groupDiv}
                        style={{cursor:'pointer'}}
                        onClick={()=>window.open('https://www.instagram.com/loggworks/?utm_medium=copy_link', '_blank')}>
                        <img
                          className={styles.frameIcon1}
                          alt=""
                          src="/frame24.svg"
                        />
                        <div className={styles.twitter}>Instagram</div>
                      </div>
                      <div className={styles.frameParent1}>
                        <img
                          className={styles.frameIcon1}
                          alt=""
                          src="/frame25.svg"
                        />
                        <div className={styles.twitter}>YouTube</div>
                      </div>
                      <div className={styles.mdilinkedinParent}
                        style={{cursor:'pointer'}}
                        onClick={()=>window.open('https://www.linkedin.com/company/loggworks', '_blank')}>
                        <img
                          className={styles.mdilinkedinIcon}
                          alt=""
                          src="/mdilinkedin1.svg"
                        />
                        <div className={styles.linkedin}>LinkedIn</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.copyright2022LoggworkLtdAParent}>
                  <div className={styles.copyright2022Loggwork}>
                    ©Copyright 2024 loggworks  Ltd. All Rights Reserved
                  </div>
                  <div className={styles.frameDiv}>
                    <div className={styles.termsOfUseParent}>
                      <div className={styles.copyright2022Loggwork}
                      style={{cursor:'pointer'}}
                      onClick={()=>{navigate('/terms')}}>
                        Terms of Use
                      </div>
                      <div className={styles.frameChild} />
                    </div>
                    <div className={styles.termsOfUseParent}>
                      <div
                        className={styles.copyright2022Loggwork}
                        style={{cursor:'pointer'}}
                        onClick={()=>{navigate('/privacy')}}
                      >{`Privacy Policy `}</div>
                      <div className={styles.frameChild} />
                    </div>
                    <div className={styles.termsOfUseParent}>
                      <div className={styles.copyright2022Loggwork}>
                        Cookies
                      </div>
                      <div className={styles.frameChild} />
                    </div>
                    <div className={styles.contactUsParent}>
                      <div className={styles.copyright2022Loggwork}>
                        Contact Us
                      </div>
                      <div className={styles.frameChild} />
                    </div>
                    <div className={styles.termsOfUseParent}>
                      <div className={styles.copyright2022Loggwork}>
                        Sitemap
                      </div>
                      <div className={styles.frameChild1} />
                    </div>
                  </div>
                </div>
                <div className={styles.largeButtonsParent}>
                  <button
                    className={styles.largeButtons}
                    style={{cursor:'pointer'}}
                    onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
                  >
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder55.svg"
                    />
                    <b className={styles.label}>Become a Professional</b>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder55.svg"
                    />
                  </button>
                  <button
                    className={styles.largeButtons}
                    onClick={onLargeButtons1Click}
                  >
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder56.svg"
                    />
                    <b className={styles.label}>Post a Job</b>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder56.svg"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.becomeAProfessionalSection}>
              <div className={styles.merge}>
                <div className={styles.text}>
                  <div className={styles.text1}>
                    <div className={styles.cantFindTheProfessionalYoParent}>
                      <b className={styles.cantFindThe}>
                        Can’t find the professional you are looking for??
                      </b>
                      <div className={styles.allYourPayments}>
                        All your payments are protected by a highly secure
                        Escrow account. Pay in advance or on completion. with
                        just a few clicks of a button, whenever.
                      </div>
                    </div>
                    <div className={styles.largeButtons2}
                    onClick={onLargeButtons1Click}>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder63.svg"
                      />
                      <b className={styles.label2}>Post a Job</b>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder63.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.picture}>
                  <img
                    className={styles.pictureChild}
                    alt=""
                    src="/rectangle-12297@2x.png"
                  />
                  <div className={styles.popRequest}>
                    <img
                      className={styles.popRequestChild}
                      alt=""
                      src="/frame-2256.svg"
                    />
                    <img
                      className={styles.popRequestItem}
                      alt=""
                      src="/polygon-23.svg"
                    />
                    <div className={styles.matthewPhillips}>
                      Matthew Phillips
                    </div>
                    <div className={styles.requestForCarpenter}>
                      Request For Carpenter
                    </div>
                    <div className={styles.ellipseParent}>
                      <div className={styles.ellipseDiv} />
                      <div className={styles.newServiceRequest}>
                        1 New Service Request
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.vectorParent}>
              <img className={styles.groupChild} alt="" src="/vector-1.svg" />
              <div className={styles.numberParent}>
                <div className={styles.number}>
                  <div className={styles.numberChild} />
                  <b className={styles.b}>1</b>
                </div>
                <img className={styles.frameIcon4} alt="" src="/frame49.svg" />
                <div className={styles.postAJobOrSearchForNearbParent}>
                  <div className={styles.getAPersonalized}>
                    Post a job or search for nearby service provider.
                  </div>
                  <div className={styles.easilyFindReliable}>
                    Easily find reliable home service professionals in your
                    area. Enter your location and service needed, then filter
                    results by price, availability, and ratings.
                  </div>
                </div>
              </div>
              <div className={styles.numberGroup}>
                <div className={styles.number}>
                  <div className={styles.numberChild} />
                  <b className={styles.b1}>2</b>
                </div>
                <img className={styles.frameIcon4} alt="" src="/frame50.svg" />
                <div className={styles.postAJobOrSearchForNearbParent}>
                  <div className={styles.getAPersonalized}>
                    Get a personalized quotation for the required home service.
                  </div>
                  <div className={styles.easilyFindReliable}>
                    Recieve a personalized quote from your chosen provider.
                    Compare prices and choose the best option for your budget
                    and timeline.
                  </div>
                </div>
              </div>
              <div className={styles.numberContainer}>
                <div className={styles.number}>
                  <div className={styles.numberChild} />
                  <b className={styles.b1}>3</b>
                </div>
                <img className={styles.frameIcon6} alt="" src="/frame511.svg" />
                <div className={styles.rateAndReviewYourCompletedParent}>
                  <div className={styles.rateAndReview}>
                    Rate and review your completed bookings.
                  </div>
                  <div className={styles.helpUsEnsure}>
                    Help us ensure the quality of our platform and assist other
                    users in making informed decisions by rating and reviewing
                    your provider. Earn rewards for every review submitted.
                  </div>
                </div>
              </div>
              <div className={styles.numberParent1}>
                <div className={styles.number}>
                  <div className={styles.numberChild} />
                  <b className={styles.b1}>4</b>
                </div>
                <img className={styles.frameIcon4} alt="" src="/frame52.svg" />
                <div className={styles.earnCashbacksAndRewardsParent}>
                  <div className={styles.getAPersonalized}>
                    Earn cashbacks and rewards.
                  </div>
                  <div className={styles.easilyFindReliable}>
                    Enjoy our loyalty rewards program, including cashback on
                    every booking, referral bonuses, and much more.
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameWrapper}>
              <div className={styles.frameParent2}>
                <div className={styles.findingProfessionalsHasNeveParent}>
                  <b className={styles.findingProfessionalsHas}>
                    Finding professionals has never been easier.
                  </b>
                  <div className={styles.loggworksConnectHomeowners}>
                  <div style={{display:'flex', gap:'1rem'}}>
                   <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                      100% cost-free job postings
                    </div>
                  </div>
                  <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    Get Multiple Quotes, Free of Charge
                    </div>
                  </div>
                </div>
                <div style={{display:'flex',gap:'1rem'}}>
                   <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    Payment and Privacy Protection
                    </div>
                  </div>
                  <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    Vetted and reliable Professionals
                    </div>
                  </div>
                </div>
                  </div>
                </div>
                <button className={styles.largeButtons3}
                 onClick={onLargeButtons1Click}>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder59.svg"
                  />
                  <b className={styles.label}>Post a Job</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder59.svg"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className={styles.navigation}>
            <div className={styles.navigation1}>
              <img
                className={styles.navigationChild}
                alt=""
                onClick={()=>navigate('/')}
                style={{cursor:'pointer'}}
                src="/frame-66522.svg"
              />
              <div className={styles.navigationItem} />
              <div className={styles.tabSwitch}>
                <div
                  className={styles.pageSelector}
                  onClick={onPageSelectorContainerClick}
                >
                  <div className={styles.descriptionParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Professional
                    </div>
                    <div className={styles.selector} />
                  </div>
                </div>
                <div className={styles.pageSelector1}>
                  <div className={styles.descriptionParent}>
                    <b className={styles.description1}>Consumer</b>
                    <div className={styles.selector1} />
                  </div>
                </div>
              </div>
             <LocationSearch/>
            </div>
            <div className={styles.navigation2}>
              <div className={styles.homeParent}>
                <div className={styles.home} onClick={onHomeTextClick}>
                  Home
                </div>
                <div className={styles.howItWorks2}>How it works</div>
                <div className={styles.mdilinkedinParent}
                 id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={openDropdown}>
                  <div className={styles.services1}>Services</div>
                  <img
                    className={styles.iconssearch}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                 <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={isDropdownOpen}
        open={open}
        onClose={closeDropdown}
        TransitionComponent={Fade}
      >
      <Dropdown4/>
      </Menu>
                <div className={styles.mdilinkedinParent}
                 id="fade-button2"
                aria-controls={open1 ? 'fade-menu2' : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? 'true' : undefined}
                onClick={openDropdown1}>
                  <div className={styles.services1}>Resources</div>
                  <img
                    className={styles.iconssearch}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                 <Menu
        id="fade-menu2"
        MenuListProps={{
          'aria-labelledby': 'fade-button2',
        }}
        anchorEl={isDropdown1Open}
        open={open1}
        onClose={closeDropdown1}
        TransitionComponent={Fade}
      >
      <Dropdown5/>
      </Menu>
                <div className={styles.pricing}>Pricing</div>
              </div>
              <div className={styles.frameParent3}>
                <div className={styles.frameParent4}>
                  <button
                    className={styles.iconshelpParent}
                    onClick={onFrameButtonClick}
                  >
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/iconshelp.svg"
                    />
                    <div className={styles.help}>Help</div>
                  </button>
                  <button className={styles.iconshelpParent}>
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/iconsaccount11.svg"
                    />
                    <div className={styles.help}>Account</div>
                  </button>
                </div>
                <button className={styles.smallButtons} onClick={onLargeButtons1Click}>
                  <div className={styles.iconsaddParent}>
                    <img
                      className={styles.iconsadd}
                      alt=""
                      src="/iconsadd19.svg"
                    />
                    <div className={styles.button}>Post a job</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.homeMobile}>
          <div className={styles.navbarMobileclosed}>
            <img className={styles.layer2Icon1} alt="" src="/layer-218.svg"
            style={{cursor:'pointer'}} onClick={()=>navigate('/')} />
            <div className={styles.iconsaccountGroup}>
              <img
                className={styles.iconsaccount1}
                alt=""
                src="/iconsaccount14.svg"
                onClick={()=>window.open('https://www.consumer.loggworks.co.uk/login', '_blank')}
              />
              <img
                className={styles.hamburgerIcon}
                alt=""
                src="/hamburger.svg"
                onClick={openMobileMenuConsumerPopup}
              />
            </div>
          </div>
          <div className={styles.homeMobileInner}>
            <div className={styles.frameParent5}>
              <div className={styles.findingProfessionalsHasNeveGroup}>
                <b className={styles.findingProfessionalsHas1}>
                  Finding professionals has never been easier.
                </b>
                <div className={styles.loggworksConnectHomeowners1}>
                   <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                      100% cost-free job postings
                    </div>
                  </div>
                  <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    Get Multiple Quotes, Free of Charge
                    </div>
                  </div>
                   <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    Payment and Privacy Protection
                    </div>
                  </div>
                  <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    Vetted and reliable Professionals
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.largeButtons4} onClick={onLargeButtons1Click}>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder59.svg"
                />
                <b className={styles.label2}>Post a Job</b>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder59.svg"
                />
              </div>
            </div>
          </div>
          <div className={styles.frameParent6}>
            <div className={styles.numberParent2}>
              <div className={styles.number}>
                <div className={styles.numberChild} />
                <b className={styles.b}>1</b>
              </div>
              <img className={styles.frameIcon8} alt="" src="/frame53.svg" />
              <div className={styles.searchForNearbyServiceProvParent}>
                <div className={styles.searchForNearby}>
                  Search for nearby service provider.
                </div>
                <div className={styles.easilyFindReliable1}>
                  Easily find reliable home service professionals in your area.
                  Enter your location and service needed, then filter results by
                  price, availability, and ratings.
                </div>
              </div>
            </div>
            <div className={styles.largeButtonsGroup}>
              <div className={styles.number}>
                <div className={styles.numberChild} />
                <b className={styles.b1}>2</b>
              </div>
              <img className={styles.frameIcon8} alt="" src="/frame54.svg" />
              <div className={styles.getAPersonalizedQuotationFGroup}>
                <div className={styles.searchForNearby}>
                  Get a personalized quotation for the required home service.
                </div>
                <div className={styles.easilyFindReliable}>
                  Recieve a personalized quote from your chosen provider.
                  Compare prices and choose the best option for your budget and
                  timeline.
                </div>
              </div>
            </div>
            <div className={styles.numberParent2}>
              <div className={styles.number}>
                <div className={styles.numberChild} />
                <b className={styles.b1}>3</b>
              </div>
              <img className={styles.frameIcon10} alt="" src="/frame55.svg" />
              <div className={styles.rateAndReviewYourCompletedGroup}>
                <div className={styles.rateAndReview1}>
                  Rate and review your completed bookings.
                </div>
                <div className={styles.helpUsEnsure1}>
                  Help us ensure the quality of our platform and assist other
                  users in making informed decisions by rating and reviewing
                  your provider. Earn rewards for every review submitted.
                </div>
              </div>
            </div>
            <div className={styles.numberParent2}>
              <div className={styles.number}>
                <div className={styles.numberChild} />
                <b className={styles.b1}>4</b>
              </div>
              <img className={styles.frameIcon11} alt="" src="/frame56.svg" />
              <div className={styles.earnCashbacksAndRewardsGroup}>
                <b className={styles.homeowners}>Earn cashbacks and rewards.</b>
                <div className={styles.easilyFindReliable}>
                  Enjoy our loyalty rewards program, including cashback on every
                  booking, referral bonuses, and much more.
                </div>
              </div>
            </div>
          </div>
          <MobileFooter/>
        </div>
      </div>
      {isMobileMenuConsumerPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuConsumerPopup}
        >
          <MobileMenuConsumer1 onClose={closeMobileMenuConsumerPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default HowWeWork;
