const CategoryData = () => {
  const categories = [
    {
      title: "explore",
    },
   /* {
      title: "Catering",
      image: "catering",
      hoverImg: "catering",
      options: ["kitchen porter", "short-order cook", "caterer", "others"],
    },
    {
      title: "Childcare",
      image: "childcare",
      hoverImg: "childcare",
      options: [
        "babysitters",
        "childminders",
        "Virtual Nannies",
        "Nursery",
        "others",
      ],
    },*/
    {
      title: "Cleaning",
      image: "cleaning",
      hoverImg: "cleaning",
      options: [
        "domestic cleaners",
        "window cleaners",
        "property maintenance",
        "drain and pipe cleaning",
        "domestic and commercial cleaners",
        "others",
      ],
    },
  /*  {
      title: "Entertainment",
      image: "entertainment",
      hoverImg: "entertainment",
      options: [
        "Event planner",
        "party planner",
        "Event coverage",
        "Event and party planner",
        "Event planner and coverage services",
        "DJ and disco hire",
        "function rooms and banqueting facilities",
        "organisers and planners",
        "wedding and reception venues",
        "photography and film",
        "florist",
        "others",
      ],
    },
    {
      title: "Appliance Repair",
      image: "appRepair",
      hoverImg: "appRepair",
      options: ["Domestic Appliance Repairs", "Appliance Repairs"],
    },
    {
      title: "Mobile Mechanic",
      image: "mechanic",
      hoverImg: "mechanic",
      options: ["Mobile mechanic"],
    },
    {
      title: "Personal Assistance",
      image: "pa",
      hoverImg: "pa",
      options: [
        "remote personal assistance",
        "household personal assistance",
        "others",
      ],
    },
    {
      title: "Pet Groomers",
      image: "petg",
      hoverImg: "petg",
      options: [
        "pet grooming",
        "pet training",
        "pet grooming and training",
        "dog walker",
        "others",
      ],
    },
    {
      title: "Recovery Services",
      image: "recovery",
      hoverImg: "recovery",
      options: ["recovery"],
    },
    {
      title: "Computer & Phone repair",
      image: "computer",
      hoverImg: "computer",
      options: [
        "phone",
        "repair",
        "computer repair",
        "computer and phone repair",
        "others",
      ],
    },
    {
      title: "Removal Services",
      image: "removal",
      hoverImg: "removal",
      options: [
        "removal",
        "Domestic removals",
        "Commercial removals",
        "local removals",
      ],
    },*/
    {
      title: "Tradesmen",
      image: "trade",
      hoverImg: "trade",
      options: [
        "Tradesmen & Construction",
        " Construction",
        "gardening & landscaping",
        "gardener",
        "painter",
        "decorating",
        "plumber",
        "handyman",
        "electrician",
        "builder",
        "plasterers",
        "architect",
        "carpentry and joiners",
        "groundworkers",
        "roofer",
        "tiler",
        "paving and driveway",
        "flooring",
        "locksmiths",
        "interior designers",
        "Landscaper",
        "Carpenter",
        "Plasterer",
        "Driveways/patio",
        "fencing",
        "boiler repair",
        "tree Surgeons",
        "Carpet Repairs",
        "others",
      ],
    },
   /*{
      title: "Transport Services",
      image: "transport",
      hoverImg: "transport",
      options: [
        "taxi",
        "bus and coach hire",
        "van and truck hire",
        "chauffeur and limousine hire",
        "car hire",
      ],
    },
    {
      title: "Dry Cleaning & Laundry",
      image: "dryclean",
      hoverImg: "dryclean",
      options: ["dry cleaner"],
    },
    {
      title: "Health, Wellness & Beauty",
      image: "health",
      hoverImg: "health",
      options: [
        "Counselling",
        "mentoring",
        "life coaching ",
        "Nutritionist and dieticians",
        "personal trainers ",
        "relationship and marriage counselling",
        "life coaching and mentoring",
        "therapist",
        "others",
      ],
    },
    {
      title: "Tuition & Classes",
      image: "tuition",
      hoverImg: "tuition",
      options: [
        "academic Tutor",
        "language",
        "driving lessons and instructors",
        "music",
        "guitar",
        "piano",
        "drum",
        "singing",
        "clarinet",
        "saxophone",
        "violin",
        "others",
      ],
    },*/
  ];
  return { categories };
};

export default CategoryData;
