import React, { useCallback } from 'react'
import styles from "../pages/HomePage.module.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TextField, InputAdornment, Icon, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { useNavigate } from 'react-router-dom';


function MobileFooter() {
     const navigate = useNavigate();

       const onHowItWorks5Click = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onContactUsText4Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

const onHelpFAQs3Click = useCallback(() => {
    navigate("/faq");
  }, [navigate]);
  const onHelpFAQs4Click = useCallback(() => {
    navigate("/professional-faq");
  }, [navigate]);

    const onUserAgreementText3Click = useCallback(() => {
    navigate("/agreement");
  }, [navigate]);
const onUserAgreementText4Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);
  const onHowItWorks4Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);
  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/becometeam");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/partners");
  }, [navigate]);

  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/affiliate-program");
  }, [navigate]);
  return (
    <div className={styles.mobileFooterHomeowner}>
              <div className={styles.frameParent2}>
              <div className={styles.frameParent8}>
                  <div className={styles.doYouHaveAServiceToOfferParent}>
                    <div className={styles.largeButtons8}  style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder6.svg"
                      />
                      <b className={styles.category}>Become a Professional</b>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder6.svg"
                      />
                    </div>
                    <div className={styles.largeButtons8} onClick={()=>{navigate('/postjob')}}>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder7.svg"
                      />
                      <b className={styles.category}>Post a Job</b>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder7.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.termsOfUseParent}
                   style={{cursor:'pointer'}}
                   onClick={()=>{navigate('/')}}>
                    <img
                      className={styles.layer2Child}
                      alt=""
                      src="/group-66987.svg"
                    />
                    <img
                      className={styles.layer2Item}
                      alt=""
                      src="/group-66988.svg"
                    />
                  </div>
                </div>
                <div className={styles.mobileFooterParent}>
                  <div className={styles.mobileFooter}>
                    <div className={styles.title4}>
                         <Accordion style={{width:'100%',background:'transparent'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color:'white'}}/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b style={{color:'white'}}>Homeowners</b>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.subContent}>
                      <div className={styles.featuredOn} onClick={()=>{navigate('/postjob')}}>Post a Job</div>
                      <div className={styles.featuredOn} onClick={onHowItWorks5Click}>How it works</div>
                      <div className={styles.featuredOn} onClick={onContactUsText4Click}>Contact Us</div>
                      <div className={styles.featuredOn} onClick={onHelpFAQs3Click}>{`Help & FAQ’s`}</div>
                      <div className={styles.featuredOn}  onClick={onUserAgreementText3Click}>User Agreement</div>
                    </div>
        </AccordionDetails>
      </Accordion>
                      
                    </div>
                   
                  </div>
                  <div className={styles.mobileFooter}>
                    <div className={styles.title4}>
                    <Accordion style={{width:'100%',background:'transparent'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color:'white'}}/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b style={{color:'white'}}>Professionals</b>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.subContent}>
                      <div className={styles.featuredOn}
                      onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                      <div className={styles.featuredOn} onClick={onHowItWorks4Click}>Blog</div>
                      <div className={styles.featuredOn} onClick={onContactUsText4Click}>Contact Us</div>
                      <div className={styles.featuredOn} onClick={onHelpFAQs4Click}>{`Help & FAQ’s`}</div>
                      <div className={styles.featuredOn}  onClick={onUserAgreementText4Click}>User Agreement</div>
                    </div>
        </AccordionDetails>
      </Accordion>
                     
                    </div>
                  </div>
                  <div className={styles.mobileFooter}>
                    <div className={styles.title4}>
                     <Accordion style={{width:'100%',background:'transparent'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color:'white'}}/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b style={{color:'white'}}>Loggworks</b>
        </AccordionSummary>
        <AccordionDetails>
         <div className={styles.subContent}>
                      <div className={styles.featuredOn} onClick={onAboutUsTextClick}>About Us</div>
                      <div className={styles.featuredOn} onClick={onCareersTextClick}>Careers</div>
                      <div className={styles.featuredOn} onClick={onPartnersTextClick}>Partners</div>
                      <div className={styles.featuredOn} onClick={onInfluencerProgramTextClick}>
                        Affiliate Program
                      </div>
                      <div className={styles.blog} onClick={onHowItWorks4Click}>Blog</div>
                    </div>
        </AccordionDetails>
      </Accordion>
                  
                    </div>
                    
                  </div>
                </div>
                <div className={styles.loggworksParent}>
                  <div className={styles.loggworks}>
                    <div className={styles.followUs}>Follow us</div>
                  </div>
                  <div className={styles.frameParent3}>
                    <div className={styles.frameParent4}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://web.facebook.com/Loggworks?_rdc=1&_rdr', '_blank')}>
                      <img
                        className={styles.frameIcon}
                        alt=""
                        src="/frame.svg"
                      />
                      <div className={styles.facebook}>Facebook</div>
                    </div>
                    <div className={styles.mdilinkedinParent}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://twitter.com/loggworks', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame1.svg"
                      />
                      <div className={styles.facebook}>Twitter</div>
                    </div>
                    <div className={styles.mdilinkedinParent}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.instagram.com/loggworks/?utm_medium=copy_link', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame2.svg"
                      />
                      <div className={styles.facebook}>Instagram</div>
                    </div>
                    <div className={styles.mdilinkedinParent}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame3.svg"
                      />
                      <div className={styles.facebook}>YouTube</div>
                    </div>
                    <div className={styles.mdilinkedinParent}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open(' https://www.linkedin.com/company/loggworks', '_blank')}>
                      <img
                        className={styles.mdilinkedinIcon}
                        alt=""
                        src="/mdilinkedin.svg"
                      />
                      <div className={styles.facebook}>LinkedIn</div>
                    </div>
                  </div>
                </div>
                
                <div className={styles.lineDiv} />
                <div className={styles.metalinks}>
                  <div className={styles.copyright2005}>
                    Copyright © 2024 loggworks Ltd. All Rights Reserved
                  </div>
                  <div className={styles.termsOfUse}>
                    <div className={styles.privacyPolicy}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/terms')}}>Terms of Use</div>
                    <div className={styles.termsOfUseChild} />
                    <div className={styles.privacyPolicy}
                     style={{cursor:'pointer'}}
                     onClick={()=>{navigate('/privacy')}}>Privacy Policy</div>
                  </div>
                  <div className={styles.termsOfUse}>
                    <div className={styles.privacyPolicy}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/cookie')}}>Cookies</div>
                    <div className={styles.termsOfUseChild} />
                    <div className={styles.privacyPolicy} style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href='https://www.loggworks.co.uk/sitemap.xml'
                    }}>Sitemap</div>
                  </div>
                </div>
              </div>
            </div>
  )
}

export default MobileFooter