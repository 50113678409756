import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./HiringGuides.module.css";
import ToolBar from "./components/ToolBar";
import PrototypeFooter from "../../components/PrototypeFooter";
import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import mobnav from "../ConsumerHome.module.css";
import PortalPopup from "../../components/PortalPopup";
import MobileFooter from "../../components/MobileFooter";
import MenuMobile from "./components/MenuMobile";
import SubsBanner from "./components/SubsBanner";

const HiringGuides = () => {
  const [
    isMobileMenuProfProfessionalPopupOpen,
    setMobileMenuProfProfessionalPopupOpen,
  ] = useState(false);
  const openMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(true);
  }, []);

  const closeMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(false);
  }, []);
  const navigate = useNavigate();

  const onBLOGTextClick = useCallback(() => {
    navigate("/blog");
  }, [navigate]);

  const onFORCONSUMERSTextClick = useCallback(() => {
    navigate("/blog/for-consumers");
  }, [navigate]);

  const onFrameContainerClick = useCallback(() => {
    navigate("/hiring-caterers");
  }, [navigate]);

  const formatDateToWords = (dateString) => {
    const date = new Date(dateString);
    
    // Array of month names
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    // Get the components of the date
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    
    // Construct the formatted date string
    const formattedDate = `${month} ${day}, ${year}`;
    
    return formattedDate;
  };
  const searchdata = [
    { BlogTitle: 'Hiring Caterers'},
    { BlogTitle: 'Hiring Childcare Workers'},
    { BlogTitle: 'Hiring Cleaners'},
    { BlogTitle: 'Hiring Entertainers'},
    { BlogTitle: 'Hiring Appliance Repair Workers'},
    { BlogTitle: 'Hiring Mobile Mechanics'},
    { BlogTitle: 'Hiring Personal Assistants'},
    { BlogTitle: 'Hiring Pet Groomers'},
    { BlogTitle: 'Hiring Recovery Service Workers'},
    { BlogTitle: 'Hiring Removal Service Workers'},
    { BlogTitle: 'Hiring Tradesmen'},
    { BlogTitle: 'Hiring Transport Service Workers'},
    { BlogTitle: 'Hiring Computer & Phone Repair'},
    { BlogTitle: 'Hiring Dry Cleaners & Laundrymen'},
    { BlogTitle: 'Hiring Health, Wellness & Beauty'},
    { BlogTitle: 'Hiring Tuition & Classes Workers'},
  ]
  const handleChange = (event, value) => {
    if (value) {
      const selected = value.toLowerCase().replace('hiring ', 'hiring-'); // Replace spaces with hyphens
      navigate(`/blog/for-consumers/hiring-guide/${selected}`);
    }
  };
  return (
    <div className={styles.hiringGuides}>
      <div className={styles.hiringGuides1}>
        <div className={styles.hiringGuidesInner}>
        <ToolBar/>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.blogGroup}>
            <div className={styles.blog1} onClick={onBLOGTextClick}>
              BLOG
            </div>
            <img
              className={styles.arrowrightIcon}
              alt=""
              src="/caretright.svg"
            />
            <div className={styles.blog1} onClick={onFORCONSUMERSTextClick}>
              FOR CONSUMERS
            </div>
            <img
              className={styles.arrowrightIcon}
              alt=""
              src="/caretright.svg"
            />
            <div className={styles.hiringGuides2}>Hiring Guides</div>
          </div>
          <div className={styles.rectangleGroup}>
          <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={searchdata.map((option) => option.BlogTitle)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search articles"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
        onChange={handleChange}
      />
          </div>
        </div>
        <div className={styles.hiringGuidesParent}>
          <div className={styles.hiringGuides3}>Hiring Guides</div>
          <div className={styles.outliningTheProcess}>
            Outlining the process of identifying, hiring, and selecting the best
            home service professionals for your needs.
          </div>
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.frameContainer}>
            <div className={styles.frameDiv} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-caterers')}>
              <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src="/caterer.webp"
                />
              </div>
              <div className={styles.groupDiv}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.frameWrapper2}>
                    <div className={styles.hiringCaterersParent}>
                      <b
                        className={styles.hiringCaterers}
                      >{`Hiring Caterers `}</b>
                      <div className={styles.exploreExpertAdvice}>
                        Explore expert advice around catering and selecting the
                        perfect professional to elevate your gatherings.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent}>
                  <div className={styles.exploreGuides}>Explore Guides</div>
                  <img
                    className={styles.arrowrightIcon}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.frameDiv} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-childcare-workers')}>
              <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src="/childcare.webp"
                />
              </div>
              <div className={styles.groupDiv}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.frameWrapper2}>
                    <div className={styles.hiringCaterersParent}>
                      <b className={styles.hiringCaterers}>
                        Hiring Childcare Workers
                      </b>
                      <div className={styles.exploreExpertAdvice}>
                        Explore expert advice around childcare and selecting the
                        perfect professional to enhance your child's growth
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent}>
                  <div className={styles.exploreGuides}>Explore Guides</div>
                  <img
                    className={styles.arrowrightIcon}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.frameDiv} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-cleaners')}>
              <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src="/cleaners.webp"
                />
              </div>
              <div className={styles.groupDiv}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.frameWrapper2}>
                    <div className={styles.hiringCaterersParent}>
                      <b className={styles.hiringCleaners}>Hiring Cleaners</b>
                      <div className={styles.exploreExpertAdvice}>
                        Explore expert advice around cleaning and selecting the
                        perfect professional for a pristine home environment.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent}>
                  <div className={styles.exploreGuides}>Explore Guides</div>
                  <img
                    className={styles.arrowrightIcon}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.groupParent1}
             style={{cursor:'pointer'}} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-entertainers')}>
              <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src="/entertainment.webp"
                />
              </div>
              <div className={styles.groupDiv}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.frameWrapper2}>
                    <div className={styles.hiringCaterersParent}>
                      <b className={styles.hiringCleaners}>
                        Hiring Entertainers
                      </b>
                      <div className={styles.exploreExpertAdvice}>
                        Explore expert advice around entertainment and selecting
                        the ideal professional to elevate your gatherings.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent}>
                  <div className={styles.exploreGuides}>Explore Guides</div>
                  <img
                    className={styles.arrowrightIcon}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent1}
             style={{cursor:'pointer'}} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-appliance-repair-workers')}>
              <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src="/applance-repair.webp"
                />
              </div>
              <div className={styles.groupDiv}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.frameWrapper2}>
                    <div className={styles.hiringCaterersParent}>
                      <b className={styles.hiringCleaners}>
                        Hiring Appliance Repair workers
                      </b>
                      <div className={styles.exploreExpertAdvice}>
                        Explore expert advice around appliance repair and
                        choosing professional to enhance your home solutions.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent}>
                  <div className={styles.exploreGuides}>Explore Guides</div>
                  <img
                    className={styles.arrowrightIcon}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.frameDiv} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-mobile-mechanics')}>
              <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src="/mobile mechanic.webp"
                />
              </div>
              <div className={styles.groupDiv}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.frameWrapper2}>
                    <div className={styles.hiringCaterersParent}>
                      <b className={styles.hiringCleaners}>
                        Hiring Mobile Mechanics
                      </b>
                      <div className={styles.exploreExpertAdvice}>
                        Explore expert advice around mechanics and selecting the
                        ideal professional to improve your automotive
                        experience.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent}>
                  <div className={styles.exploreGuides}>Explore Guides</div>
                  <img
                    className={styles.arrowrightIcon}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameContainer}
          >
            <div className={styles.frameDiv} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-personal-assistants')}>
              <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src="/personal assistant.webp"
                />
              </div>
              <div className={styles.groupDiv}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.frameWrapper2}>
                    <div className={styles.hiringCaterersParent}>
                      <b className={styles.hiringCleaners}>
                        Hiring Personal Assistants
                      </b>
                      <div className={styles.exploreExpertAdvice}>
                        Explore expert advice around personal assistance and
                        choosing the perfect professional to better your
                        experience.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent}>
                  <div className={styles.exploreGuides}>Explore Guides</div>
                  <img
                    className={styles.arrowrightIcon}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.frameDiv} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-pet-groomers')}>
              <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src="/pet groomers.webp"
                />
              </div>
              <div className={styles.groupDiv}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.frameWrapper2}>
                    <div className={styles.hiringCaterersParent}>
                      <b className={styles.hiringCleaners}>
                        Hiring Pet Groomers
                      </b>
                      <div className={styles.exploreExpertAdvice}>
                        Explore expert advice around pet grooming and selecting
                        the perfect professional to care for your pets.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent}>
                  <div className={styles.exploreGuides}>Explore Guides</div>
                  <img
                    className={styles.arrowrightIcon}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.frameDiv} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-recovery-service-workers')}>
              <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild15}
                  alt=""
                  src="/recovery services.webp"
                />
              </div>
              <div className={styles.groupDiv}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.frameWrapper2}>
                    <div className={styles.hiringCaterersParent}>
                      <b className={styles.hiringCleaners}>
                        Hiring Recovery Service workers
                      </b>
                      <div className={styles.exploreExpertAdvice}>
                        Explore expert advice around recovery service and
                        finding the perfect professional to better your roadside
                        experience.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent}>
                  <div className={styles.exploreGuides}>Explore Guides</div>
                  <img
                    className={styles.arrowrightIcon}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.frameDiv} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-removal-service-workers')}>
              <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild15}
                  alt=""
                  src="/removal services.webp"
                />
              </div>
              <div className={styles.groupDiv}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.frameWrapper2}>
                    <div className={styles.hiringCaterersParent}>
                      <b className={styles.hiringCleaners}>
                        Hiring Removal Service workers
                      </b>
                      <div className={styles.exploreExpertAdvice}>
                        Explore expert advice around removal service and
                        selecting the perfect professional for a seamless moving
                        experience.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent}>
                  <div className={styles.exploreGuides}>Explore Guides</div>
                  <img
                    className={styles.arrowrightIcon}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.frameDiv} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-tradesmen')}>
              <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild19}
                  alt=""
                  src="/tradesmen.webp"
                />
              </div>
              <div className={styles.groupDiv}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.frameWrapper2}>
                    <div className={styles.hiringCaterersParent}>
                      <b className={styles.hiringCleaners}>Hiring Tradesmen</b>
                      <div className={styles.exploreExpertAdvice}>
                        Explore expert advice around tradesmen and selecting the
                        perfect professional to elevate your home projects.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent}>
                  <div className={styles.exploreGuides}>Explore Guides</div>
                  <img
                    className={styles.arrowrightIcon}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.frameDiv} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-transport-service-workers')}>
              <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild19}
                  alt=""
                  src="/transport services.webp"
                />
              </div>
              <div className={styles.groupDiv}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.frameWrapper2}>
                    <div className={styles.hiringCaterersParent}>
                      <b className={styles.hiringCleaners}>
                        Hiring Transport Service workers
                      </b>
                      <div className={styles.exploreExpertAdvice}>
                        Explore expert advice around transport service and
                        selecting the perfect professional for a smooth and
                        enjoyable journey.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent}>
                  <div className={styles.exploreGuides}>Explore Guides</div>
                  <img
                    className={styles.arrowrightIcon}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.frameDiv} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-computer-&-phone-repair')}>
              <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild19}
                  alt=""
                  src="/mobile and computer epair.webp"
                />
              </div>
              <div className={styles.groupDiv}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.frameWrapper2}>
                    <div className={styles.hiringCaterersParent}>
                      <b
                        className={styles.hiringCleaners}
                      >{`Hiring Computer & Phone Repair`}</b>
                      <div
                        className={styles.exploreExpertAdvice}
                      >{`Explore expert advice around computer & phone repair and selecting the perfect professional to repair your devices.`}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent}>
                  <div className={styles.exploreGuides}>Explore Guides</div>
                  <img
                    className={styles.arrowrightIcon}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.frameDiv} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-dry-cleaners-&-laundrymen')}>
              <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild19}
                  alt=""
                  src="/dry cleaning.webp"
                />
              </div>
              <div className={styles.groupDiv}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.frameWrapper2}>
                    <div className={styles.hiringCaterersParent}>
                      <b
                        className={styles.hiringCleaners}
                      >{`Hiring Dry Cleaners & laundrymen`}</b>
                      <div className={styles.exploreExpertAdvice}>
                        Explore expert advice around dry cleaning and laundry
                        and selecting the perfect professional to care for your
                        garments.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent}>
                  <div className={styles.exploreGuides}>Explore Guides</div>
                  <img
                    className={styles.arrowrightIcon}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.frameDiv} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-health,-wellness-&-beauty')}>
              <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild19}
                  alt=""
                  src="/health and wellness.webp"
                />
              </div>
              <div className={styles.groupDiv}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.frameWrapper2}>
                    <div className={styles.hiringCaterersParent}>
                      <b
                        className={styles.hiringCleaners}
                      >{`Hiring Health, Wellness & Beauty`}</b>
                      <div
                        className={styles.exploreExpertAdvice}
                      >{`Explore expert advice around health, wellness & beauty and selecting the perfect professional for your overall well-being.`}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent}>
                  <div className={styles.exploreGuides}>Explore Guides</div>
                  <img
                    className={styles.arrowrightIcon}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameWrapper31}>
            <div className={styles.frameDiv} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-tuition-&-classes-workers')}>
              <div className={styles.rectangleParent15}>
                <div className={styles.groupChild28} />
                <img
                  className={styles.groupChild29}
                  alt=""
                  src="/online tutor.webp"
                />
              </div>
              <div className={styles.groupDiv}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.frameWrapper2}>
                    <div className={styles.hiringCaterersParent}>
                      <b
                        className={styles.hiringCleaners}
                      >{`Hiring Tuition & Classes workers`}</b>
                      <div className={styles.exploreExpertAdvice}>
                        Explore expert advice around tuition and classes and
                        selecting the perfect professional to elevate your
                        learning.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent}>
                  <div className={styles.exploreGuides}>Explore Guides</div>
                  <img
                    className={styles.arrowrightIcon}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <SubsBanner/>
        <div className={styles.prototypeFooter}>
        <PrototypeFooter/>
        </div>
      </div>
      <div className={styles.hiringGuidesMob}>
      <div className={mobnav.navbarMobileclosed}>
          <img className={mobnav.layer2Icon} alt="" src="/layer-21.svg"
          style={{cursor:'pointer'}} onClick={()=>navigate('/')}  />
          <div className={mobnav.iconsaccountParent}>
            <div className={mobnav.iconsaccount} alt="" />
            <img className={mobnav.hamburgerIcon} alt="" src="/hamburger.svg" onClick={openMobileMenuProfProfessionalPopup} />
          </div>
        </div>
        <div className={styles.blogContainer}>
          <div className={styles.blog3} onClick={onBLOGTextClick}>BLOG</div>
          <img
            className={styles.caretrightIcon2}
            alt=""
            src="/caretright.svg"
          />
          <div className={styles.blog3} onClick={onFORCONSUMERSTextClick}>FOR CONSUMERS</div>
          <img
            className={styles.caretrightIcon2}
            alt=""
            src="/caretright.svg"
          />
          <div className={styles.hiringGuides2}>Hiring Guides</div>
        </div>
        <div className={styles.rectangleParent17}>
        <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={searchdata.map((option) => option.BlogTitle)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search articles"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
        onChange={handleChange}
      />
        </div>
        <div className={styles.hiringGuidesGroup}>
          <div className={styles.hiringGuides3}>Hiring Guides</div>
          <div className={styles.outliningTheProcess1}>
            Outlining the process of identifying, hiring, and selecting the best
            home service professionals for your needs.
          </div>
        </div>
        <div className={styles.hiringGuidesMobInner}>
          <div className={styles.frameParent28}>
            <div className={styles.groupParent19} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-caterers')}>
              <div className={styles.rectangleParent18}>
                <div className={styles.groupChild32} />
                <img
                  className={styles.groupChild33}
                  alt=""
                  src="/caterer.webp"
                />
              </div>
              <div className={styles.frameParent29}>
                <div className={styles.frameWrapper36}>
                  <div className={styles.frameWrapper37}>
                    <div className={styles.hiringCaterersGroup}>
                      <b
                        className={styles.hiringCaterers1}
                      >{`Hiring Caterers `}</b>
                      <div className={styles.exploreExpertAdvice16}>
                        Explore expert advice around catering and selecting the
                        perfect professional to elevate your gatherings.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent14}>
                  <div className={styles.exploreGuides16}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon2}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent19} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-childcare-workers')}>
              <div className={styles.rectangleParent19}>
                <div className={styles.groupChild34} />
                <img
                  className={styles.groupChild33}
                  alt=""
                  src="/childcare.webp"
                />
              </div>
              <div className={styles.frameParent29}>
                <div className={styles.frameWrapper36}>
                  <div className={styles.frameWrapper37}>
                    <div className={styles.hiringCaterersGroup}>
                      <b className={styles.hiringCaterers1}>
                        Hiring Childcare Workers
                      </b>
                      <div className={styles.exploreExpertAdvice16}>
                        Explore expert advice around childcare and selecting the
                        perfect professional to enhance your child's growth
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent14}>
                  <div className={styles.exploreGuides16}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon2}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent19} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-cleaners')}>
              <div className={styles.rectangleParent20}>
                <div className={styles.groupChild36} />
                <img
                  className={styles.groupChild33}
                  alt=""
                  src="/cleaners.webp"
                />
              </div>
              <div className={styles.frameParent29}>
                <div className={styles.frameWrapper36}>
                  <div className={styles.frameWrapper37}>
                    <div className={styles.hiringCaterersGroup}>
                      <b className={styles.hiringCaterers1}>Hiring Cleaners</b>
                      <div className={styles.exploreExpertAdvice16}>
                        Explore expert advice around cleaning and selecting the
                        perfect professional for a pristine home environment.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent14}>
                  <div className={styles.exploreGuides16}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon2}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent19} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-entertainers')}>
              <div className={styles.rectangleParent20}>
                <div className={styles.groupChild36} />
                <img
                  className={styles.groupChild33}
                  alt=""
                  src="/entertainment.webp"
                />
              </div>
              <div className={styles.frameParent29}>
                <div className={styles.frameWrapper36}>
                  <div className={styles.frameWrapper37}>
                    <div className={styles.hiringCaterersGroup}>
                      <b className={styles.hiringCaterers1}>
                        Hiring Entertainers
                      </b>
                      <div className={styles.exploreExpertAdvice16}>
                        Explore expert advice around entertainment and selecting
                        the ideal professional to elevate your gatherings.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent14}>
                  <div className={styles.exploreGuides16}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon2}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent19} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-appliance-repair-workers')}>
              <div className={styles.rectangleParent18}>
                <div className={styles.groupChild32} />
                <img
                  className={styles.groupChild33}
                  alt=""
                  src="/applance-repair.webp"
                />
              </div>
              <div className={styles.frameParent29}>
                <div className={styles.frameWrapper36}>
                  <div className={styles.frameWrapper37}>
                    <div className={styles.hiringCaterersGroup}>
                      <b className={styles.hiringApplianceRepair1}>
                        Hiring Appliance Repair workers
                      </b>
                      <div className={styles.exploreExpertAdvice16}>
                        Explore expert advice around appliance repair and
                        choosing professional to enhance your home solutions.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent14}>
                  <div className={styles.exploreGuides16}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon2}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent19} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-mobile-mechanics')}>
              <div className={styles.rectangleParent18}>
                <div className={styles.groupChild32} />
                <img
                  className={styles.groupChild43}
                  alt=""
                  src="/mobile mechanic.webp"
                />
              </div>
              <div className={styles.frameParent29}>
                <div className={styles.frameWrapper36}>
                  <div className={styles.frameWrapper37}>
                    <div className={styles.hiringCaterersGroup}>
                      <b className={styles.hiringApplianceRepair1}>
                        Hiring Mobile Mechanics
                      </b>
                      <div className={styles.exploreExpertAdvice16}>
                        Explore expert advice around mechanics and selecting the
                        ideal professional to improve your automotive
                        experience.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent14}>
                  <div className={styles.exploreGuides16}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon2}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent19} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-personal-assistants')}>
              <div className={styles.rectangleParent20}>
                <div className={styles.groupChild36} />
                <img
                  className={styles.groupChild33}
                  alt=""
                  src="/personal assistant.webp"
                />
              </div>
              <div className={styles.frameParent29}>
                <div className={styles.frameWrapper36}>
                  <div className={styles.frameWrapper37}>
                    <div className={styles.hiringCaterersGroup}>
                      <b className={styles.hiringApplianceRepair1}>
                        Hiring Personal Assistants
                      </b>
                      <div className={styles.exploreExpertAdvice16}>
                        Explore expert advice around personal assistance and
                        choosing the perfect professional to better your
                        experience.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent14}>
                  <div className={styles.exploreGuides16}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon2}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent19} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-pet-groomers')}>
              <div className={styles.rectangleParent25}>
                <div className={styles.groupChild46} />
                <img
                  className={styles.groupChild33}
                  alt=""
                  src="/pet groomers.webp"
                />
              </div>
              <div className={styles.frameParent29}>
                <div className={styles.frameWrapper36}>
                  <div className={styles.frameWrapper37}>
                    <div className={styles.hiringCaterersGroup}>
                      <b className={styles.hiringApplianceRepair1}>
                        Hiring Pet Groomers
                      </b>
                      <div className={styles.exploreExpertAdvice16}>
                        Explore expert advice around pet grooming and selecting
                        the perfect professional to care for your pets.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent14}>
                  <div className={styles.exploreGuides16}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon2}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent19} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-recovery-service-workers')}>
              <div className={styles.rectangleParent20}>
                <div className={styles.groupChild36} />
                <img
                  className={styles.groupChild33}
                  alt=""
                  src="/recovery services.webp"
                />
              </div>
              <div className={styles.frameParent29}>
                <div className={styles.frameWrapper36}>
                  <div className={styles.frameWrapper37}>
                    <div className={styles.hiringCaterersGroup}>
                      <b className={styles.hiringApplianceRepair1}>
                        Hiring Recovery Service workers
                      </b>
                      <div className={styles.exploreExpertAdvice16}>
                        Explore expert advice around recovery service and
                        finding the perfect professional to better your roadside
                        experience.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent14}>
                  <div className={styles.exploreGuides16}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon2}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent19} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-removal-service-workers')}>
              <div className={styles.rectangleParent18}>
                <div className={styles.groupChild32} />
                <img
                  className={styles.groupChild51}
                  alt=""
                  src="/removal services.webp"
                />
              </div>
              <div className={styles.frameParent29}>
                <div className={styles.frameWrapper36}>
                  <div className={styles.frameWrapper37}>
                    <div className={styles.hiringCaterersGroup}>
                      <b className={styles.hiringApplianceRepair1}>
                        Hiring Removal Service workers
                      </b>
                      <div className={styles.exploreExpertAdvice16}>
                        Explore expert advice around removal service and
                        selecting the perfect professional for a seamless moving
                        experience.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent14}>
                  <div className={styles.exploreGuides16}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon2}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent19} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-tradesmen')}>
              <div className={styles.rectangleParent18}>
                <div className={styles.groupChild32} />
                <img
                  className={styles.groupChild53}
                  alt=""
                  src="/tradesmen.webp"
                />
              </div>
              <div className={styles.frameParent29}>
                <div className={styles.frameWrapper36}>
                  <div className={styles.frameWrapper37}>
                    <div className={styles.hiringCaterersGroup}>
                      <b className={styles.hiringApplianceRepair1}>
                        Hiring Tradesmen
                      </b>
                      <div className={styles.exploreExpertAdvice16}>
                        Explore expert advice around tradesmen and selecting the
                        perfect professional to elevate your home projects.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent14}>
                  <div className={styles.exploreGuides16}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon2}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent19} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-transport-service-workers')}>
              <div className={styles.rectangleParent18}>
                <div className={styles.groupChild32} />
                <img
                  className={styles.groupChild55}
                  alt=""
                  src="/transport services.webp"
                />
              </div>
              <div className={styles.frameParent29}>
                <div className={styles.frameWrapper36}>
                  <div className={styles.frameWrapper37}>
                    <div className={styles.hiringCaterersGroup}>
                      <b className={styles.hiringApplianceRepair1}>
                        Hiring Transport Service workers
                      </b>
                      <div className={styles.exploreExpertAdvice16}>
                        Explore expert advice around transport service and
                        selecting the perfect professional for a smooth and
                        enjoyable journey.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent14}>
                  <div className={styles.exploreGuides16}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon2}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent19} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-computer-&-phone-repair')}>
              <div className={styles.rectangleParent18}>
                <div className={styles.groupChild56} />
                <img
                  className={styles.groupChild33}
                  alt=""
                  src="/mobile and computer epair.webp"
                />
              </div>
              <div className={styles.frameParent29}>
                <div className={styles.frameWrapper36}>
                  <div className={styles.frameWrapper37}>
                    <div className={styles.hiringCaterersGroup}>
                      <b
                        className={styles.hiringApplianceRepair1}
                      >{`Hiring Computer & Phone Repair`}</b>
                      <div
                        className={styles.exploreExpertAdvice16}
                      >{`Explore expert advice around computer & phone repair and selecting the perfect professional to repair your devices.`}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent14}>
                  <div className={styles.exploreGuides16}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon2}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent19} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-dry-cleaners-&-laundrymen')}>
              <div className={styles.rectangleParent20}>
                <div className={styles.groupChild36} />
                <img
                  className={styles.groupChild33}
                  alt=""
                  src="/dry cleaning.webp"
                />
              </div>
              <div className={styles.frameParent29}>
                <div className={styles.frameWrapper36}>
                  <div className={styles.frameWrapper37}>
                    <div className={styles.hiringCaterersGroup}>
                      <b
                        className={styles.hiringApplianceRepair1}
                      >{`Hiring Dry Cleaners & laundrymen`}</b>
                      <div className={styles.exploreExpertAdvice16}>
                        Explore expert advice around dry cleaning and laundry
                        and selecting the perfect professional to care for your
                        garments.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent14}>
                  <div className={styles.exploreGuides16}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon2}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent19} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-health,-wellness-&-beauty')}>
              <div className={styles.rectangleParent32}>
                <div className={styles.groupChild60} />
                <img
                  className={styles.groupChild33}
                  alt=""
                  src="/health and wellness.webp"
                />
              </div>
              <div className={styles.frameParent29}>
                <div className={styles.frameWrapper36}>
                  <div className={styles.frameWrapper37}>
                    <div className={styles.hiringCaterersGroup}>
                      <b
                        className={styles.hiringApplianceRepair1}
                      >{`Hiring Health, Wellness & Beauty`}</b>
                      <div
                        className={styles.exploreExpertAdvice16}
                      >{`Explore expert advice around health, wellness & beauty and selecting the perfect professional for your overall well-being.`}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent14}>
                  <div className={styles.exploreGuides16}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon2}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent19} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-tuition-&-classes-workers')}>
              <div className={styles.rectangleParent19}>
                <div className={styles.groupChild34} />
                <img
                  className={styles.groupChild33}
                  alt=""
                  src="/online tutor.webp"
                />
              </div>
              <div className={styles.frameParent29}>
                <div className={styles.frameWrapper36}>
                  <div className={styles.frameWrapper37}>
                    <div className={styles.hiringCaterersGroup}>
                      <b
                        className={styles.hiringApplianceRepair1}
                      >{`Hiring Tuition & Classes workers`}</b>
                      <div className={styles.exploreExpertAdvice16}>
                        Explore expert advice around tuition and classes and
                        selecting the perfect professional to elevate your
                        learning.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent14}>
                  <div className={styles.exploreGuides16}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon2}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <SubsBanner/>
        <MobileFooter/>
      </div>
      {isMobileMenuProfProfessionalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuProfProfessionalPopup}
        >
          <MenuMobile
            onClose={closeMobileMenuProfProfessionalPopup}
          />
        </PortalPopup>
      )}
    </div>
  );
};

export default HiringGuides;
