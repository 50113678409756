import { useState, useCallback, useRef, useEffect } from "react";
import Dropdown4 from "../components/Dropdown4";
import PortalPopup from "../components/PortalPopup";
import Dropdown5 from "../components/Dropdown5";
import MobileMenuConsumer1 from "../components/MobileMenuConsumer1";
import { useNavigate } from "react-router-dom";
import styles from "./Agreement.module.css";
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import MobileFooter from "../components/MobileFooter";
import LocationSearch from "../components/locationSearch.tsx";
import { FormControl, MenuItem, Select } from "@mui/material";

const Agreement = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(null);
  const open = Boolean(isDropdownOpen);
  const DivRef1 = useRef(null);
  const DivRef2 = useRef(null);
  const DivRef3 = useRef(null);
  const DivRef4 = useRef(null);
  const DivRef5 = useRef(null);
  const DivRef6 = useRef(null);
  const DivRef7 = useRef(null);
  const DivRef8 = useRef(null);
  const DivRef9 = useRef(null);
  const DivRef10 = useRef(null);
  const DivRef11 = useRef(null);
  const DivRef12 = useRef(null);
  const DivRef13 = useRef(null);
  const DivRef14 = useRef(null);
  //mobile
  const DivRef1m = useRef(null);
  const DivRef2m = useRef(null);
  const DivRef3m = useRef(null);
  const DivRef4m = useRef(null);
  const DivRef5m = useRef(null);
  const DivRef6m = useRef(null);
  const DivRef7m = useRef(null);
  const DivRef8m = useRef(null);
  const DivRef9m = useRef(null);
  const DivRef10m = useRef(null);
  const DivRef11m = useRef(null);
  const DivRef12m = useRef(null);
  const DivRef13m = useRef(null);
  const DivRef14m = useRef(null);
  const [value,setValue]=useState('Definitions')
 /* useEffect(() => {
    const handleScroll = () => {
      const div1Top = DivRef1.current.offsetTop;
      const div2Top = DivRef2.current.offsetTop;
      const div3Top = DivRef3.current.offsetTop;
      const div4Top = DivRef4.current.offsetTop;
      const div5Top = DivRef5.current.offsetTop;
      const div6Top = DivRef6.current.offsetTop;
      const div7Top = DivRef7.current.offsetTop;
      const div8Top = DivRef8.current.offsetTop;
      const div9Top = DivRef9.current.offsetTop;
      const div10Top = DivRef10.current.offsetTop;

      const scrollPosition = window.scrollY;

      if (scrollPosition >= div1Top && scrollPosition < div2Top) {
       setValue('Introduction')
      } 
      else if (scrollPosition >= div2Top && scrollPosition < div3Top) {
        setValue('Collection of your information')
      } 
      else if (scrollPosition >= div3Top && scrollPosition < div4Top) {
        setValue('Personal data')
      } 
      else if (scrollPosition >= div4Top && scrollPosition < div5Top) {
        setValue('Financial data')
      } else if (scrollPosition >= div5Top && scrollPosition < div6Top) {
        setValue('Use of your information')
      } else if (scrollPosition >= div6Top && scrollPosition < div7Top) {
        setValue('Disclosure of your information')
      } else if (scrollPosition >= div7Top && scrollPosition < div8Top) {
        setValue('By law or to protect rights')
      } else if (scrollPosition >= div8Top && scrollPosition < div9Top) {
        setValue('Third party service providers')
      } else if (scrollPosition >= div9Top && scrollPosition < div10Top) {
        setValue('Marketing communications')
      } else if (scrollPosition >= div10Top) {
        setValue('Security of your information')
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);*/


  const openDropdown = (event) => {
    setDropdownOpen(event.currentTarget);
  };
  const closeDropdown = () => {
    setDropdownOpen(null);
  };
  const [isDropdown1Open, setDropdown1Open] = useState(null);
  const open1 = Boolean(isDropdown1Open);
   const openDropdown1 = (event) => {
    setDropdown1Open(event.currentTarget);
  };
  const closeDropdown1 = () => {
    setDropdown1Open(null);
  };
  const [isMobileMenuConsumerPopupOpen, setMobileMenuConsumerPopupOpen] =
    useState(false);
  const navigate = useNavigate();

  const onPostAJobClick = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const onHowItWorksClick = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQsClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementTextClick = useCallback(() => {
    navigate("/agreement");
  }, [navigate]);

  const onHowItWorks1Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onContactUsText1Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQs1Click = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementText1Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/becometeam");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/partners");
  }, [navigate]);

  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/affiliate-program");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onLargeButtons1Click = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const onPageSelectorContainerClick = useCallback(() => {
    navigate("/professional");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHowItWorks2Click = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onFrameButtonClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onSmallButtonsClick = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const openMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(true);
  }, []);

  const closeMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(false);
  }, []);

  return (
    <>
      <div className={styles.agreement}>
        <div className={styles.careersdone}>
          <div className={styles.prototypeFooter}>
            <div className={styles.footer}>
              <div className={styles.download}>
                <div className={styles.downloadOurApps}>Download our apps</div>
                <img
                  className={styles.playstoreButtonIcon}
                  alt=""
                  src="/playstore-button9.svg"
                />
                <img
                  className={styles.appstoreButtonIcon}
                  alt=""
                  src="/appstore-button7.svg"
                />
              </div>
              <div className={styles.footerChild} />
              <img className={styles.layer2Icon}
               style={{cursor:'pointer'}}
               onClick={()=>{navigate('/')}} 
               alt="" src="/layer-281.svg" />
              <div className={styles.frameParent}>
                <div className={styles.homeownersParent}>
                  <b className={styles.homeowners}>Homeowners</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.postAJob} onClick={onPostAJobClick}>
                      Post a Job
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHowItWorksClick}
                    >
                      How it works
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onContactUsTextClick}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHelpFAQsClick}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.postAJob}
                      onClick={onUserAgreementTextClick}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.professionalsParent}>
                  <b className={styles.homeowners}>Professionals</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.signUp}
                    style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                    <div
                      className={styles.postAJob}
                      onClick={onHowItWorks1Click}
                    >
                      Blog
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onContactUsText1Click}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHelpFAQs1Click}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.postAJob}
                      onClick={onUserAgreementText1Click}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.loggworksParent}>
                  <b className={styles.homeowners}>Loggworks</b>
                  <div className={styles.postAJobParent}>
                    <div
                      className={styles.postAJob}
                      onClick={onAboutUsTextClick}
                    >
                      About us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onCareersTextClick}
                    >
                      Careers
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onPartnersTextClick}
                    >
                      Partners
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onInfluencerProgramTextClick}
                    >
                      Affiliate program
                    </div>
                    <div className={styles.blog}>Blog</div>
                  </div>
                </div>
                <div className={styles.loggworksGroup}>
                  <div className={styles.loggworks1}>
                    <b className={styles.followUs}>Follow us</b>
                  </div>
                  <div className={styles.groupParent}>
                    <div className={styles.frameGroup}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://web.facebook.com/Loggworks?_rdc=1&_rdr', '_blank')}>
                      <img
                        className={styles.frameIcon}
                        alt=""
                        src="/frame20.svg"
                      />
                      <div className={styles.facebook}>Facebook</div>
                    </div>
                    <div className={styles.frameContainer}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://twitter.com/loggworks', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame211.svg"
                      />
                      <div className={styles.twitter}>Twitter</div>
                    </div>
                    <div className={styles.groupDiv}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.instagram.com/loggworks/?utm_medium=copy_link', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame22.svg"
                      />
                      <div className={styles.twitter}>Instagram</div>
                    </div>
                    <div className={styles.frameParent1}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame23.svg"
                      />
                      <div className={styles.twitter}>YouTube</div>
                    </div>
                    <div className={styles.mdilinkedinParent}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open(' https://www.linkedin.com/company/loggworks', '_blank')}>
                      <img
                        className={styles.mdilinkedinIcon}
                        alt=""
                        src="/mdilinkedin1.svg"
                      />
                      <div className={styles.linkedin}>LinkedIn</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.copyright2022LoggworkLtdAParent}>
                <div className={styles.copyright2022Loggwork}>
                  ©Copyright 2024 loggworks Ltd. All Rights Reserved
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/terms')}}>
                      Terms of Use
                    </div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div
                      className={styles.copyright2022Loggwork}
                      style={{cursor:'pointer'}}
                      onClick={()=>{navigate('/privacy')}}
                    >{`Privacy Policy `}</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/cookie')}}>Cookies</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.contactUsParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Contact Us
                    </div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork} style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href='https://www.loggworks.co.uk/sitemap.xml'
                    }}>Sitemap</div>
                    <div className={styles.frameChild1} />
                  </div>
                </div>
              </div>
              <div className={styles.largeButtonsParent}>
                <button
                  className={styles.largeButtons}
                  style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder38.svg"
                  />
                  <b className={styles.label}>Become a Professional</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder38.svg"
                  />
                </button>
                <button
                  className={styles.largeButtons}
                  onClick={onLargeButtons1Click}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder39.svg"
                  />
                  <b className={styles.label}>Post a Job</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder39.svg"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className={styles.frameParent2}>
            <div className={styles.frameWrapper}>
              <div className={styles.frameWrapper1}>
                <div className={styles.serviceConsumerUserAgreemenParent}>
                  <b className={styles.serviceConsumerUser}>
                    Service Consumer User Agreement
                  </b>
                  <div className={styles.thisAgreementBinds}>
                    This agreement binds Service providers and Loggworks.
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameWrapper2}>
              <div className={styles.frameParent3}>
             
                <div className={styles.userAgreementTabsParent}>
                  <div 
                  style={{cursor:'pointer'}}
                  onClick={()=>{
                    setValue('Definitions')
                    DivRef1.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                  className={value=='Definitions'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}>Definitions</div>
                  </div>
                  <div 
                  style={{cursor:'pointer'}}
                  onClick={()=>{
                    setValue('Content')
                    DivRef2.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                  className={value=='Content'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}>
                    Content
                    </div>
                  </div>
                  <div 
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                    setValue('Loggworks Account')
                    DivRef3.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                   className={value=='Loggworks Account'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}>Loggworks Account</div>
                  </div>
                  <div 
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                    setValue('Loggworks Service fee and payments')
                    DivRef4.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                   className={value=='Loggworks Service fee and payments'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}>Loggworks Service fee and payments</div>
                  </div>
                  <div
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                    setValue('Cancellation and Termination')
                    DivRef5.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                   className={value=='Cancellation and Termination'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}>
                    Cancellation and Termination
                    </div>
                  </div>
                  <div 
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                    setValue('Contract between Consumers and Service professionals')
                    DivRef6.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                   className={value=='Contract between Consumers and Service professionals'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}>
                    Contract between Consumers and Service professionals
                    </div>
                  </div>
                  <div
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                    setValue('Warranty Disclaimer')
                    DivRef7.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                   className={value=='Warranty Disclaimer'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}>
                    Warranty Disclaimer
                    </div>
                  </div>
                  <div 
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                    setValue('Comments and Feedbacks')
                    DivRef8.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                   className={value=='Comments and Feedbacks'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}>
                    Comments and Feedbacks
                    </div>
                  </div>
                  <div
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                    setValue('Intellectual Property Rights')
                    DivRef9.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                   className={value=='Intellectual Property Rights'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}>
                    Intellectual Property Rights
                    </div>
                  </div>
                  <div 
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                    setValue('Indemnification')
                    DivRef10.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                   className={value=='Indemnification'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}
                    >
                     Indemnification
                    </div>
                  </div>
                  <div 
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                    setValue('Limitation of Liability')
                    DivRef11.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                   className={value=='Limitation of Liability'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}
                    >
                     Limitation of Liability
                    </div>
                  </div>
                  <div 
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                    setValue('Our Rights')
                    DivRef12.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                   className={value=='Our Rights'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}
                    >
                     Our Rights
                    </div>
                  </div>
                  <div 
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                    setValue('General')
                    DivRef13.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                   className={value=='General'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}
                    >
                     General
                    </div>
                  </div>
                  <div 
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                    setValue('Chat')
                    DivRef14.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                   className={value=='Chat'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}
                    >
                     Chat
                    </div>
                  </div>
                </div>
                <div className={styles.frameParent4}>
                  <div className={styles.introductionParent}>
                  <div className={styles.weMayCollect}>
                    <Content
                    DivRef1={DivRef1}
                    DivRef2={DivRef2}
                    DivRef3={DivRef3}
                    DivRef4={DivRef4}
                    DivRef5={DivRef5}
                    DivRef6={DivRef6}
                    DivRef7={DivRef7}
                    DivRef8={DivRef8}
                    DivRef9={DivRef9}
                    DivRef10={DivRef10}
                    DivRef11={DivRef11}
                    DivRef12={DivRef12}
                    DivRef13={DivRef13}
                    DivRef14={DivRef14}
                    />
                  </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <nav className={styles.navigation}>
            <div className={styles.navigation1}>
              <img
                className={styles.navigationChild}
                alt=""
                src="/frame-665223.svg"
              />
              <div className={styles.navigationItem} />
              <div className={styles.tabSwitch}>
                <div
                  className={styles.pageSelector}
                  onClick={onPageSelectorContainerClick}
                >
                  <div className={styles.descriptionParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Professional
                    </div>
                    <div className={styles.selector} />
                  </div>
                </div>
                <div className={styles.pageSelector1}>
                  <div className={styles.descriptionParent}>
                    <b className={styles.description1}>Consumer</b>
                    <div className={styles.selector1} />
                  </div>
                </div>
              </div>
              <LocationSearch/>
            </div>
            <div className={styles.navigation2}>
              <div className={styles.homeParent}>
                <div className={styles.home} onClick={onHomeTextClick}>
                  Home
                </div>
                <div className={styles.home} onClick={onHowItWorks2Click}>
                  How it works
                </div>
                <div className={styles.services}
                id="fade-button"
  aria-controls={open ? 'fade-menu' : undefined}
  aria-haspopup="true"
  aria-expanded={open ? 'true' : undefined}
                 onClick={openDropdown}>
                  <div className={styles.services1}>Services</div>
                  <img
                    className={styles.iconssearch}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                 <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={isDropdownOpen}
        open={open}
        onClose={closeDropdown}
        TransitionComponent={Fade}
      >
      <Dropdown4/>
      </Menu>
                <div className={styles.services} 
                id="fade-button2"
 aria-controls={open1 ? 'fade-menu2' : undefined}
 aria-haspopup="true"
 aria-expanded={open1 ? 'true' : undefined}
                onClick={openDropdown1}>
                  <div className={styles.services1}>Resources</div>
                  <img
                    className={styles.iconssearch}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                   <Menu
        id="fade-menu2"
        MenuListProps={{
          'aria-labelledby': 'fade-button2',
        }}
        anchorEl={isDropdown1Open}
        open={open1}
        onClose={closeDropdown1}
        TransitionComponent={Fade}
      >
      <Dropdown5/>
      </Menu>
                <div className={styles.pricing}>Pricing</div>
              </div>
              <div className={styles.frameParent5}>
                <div className={styles.frameParent6}>
                  <button
                    className={styles.iconshelpParent}
                    onClick={onFrameButtonClick}
                  >
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/iconshelp.svg"
                    />
                    <div className={styles.help}>Help</div>
                  </button>
                  <button className={styles.iconshelpParent}>
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/iconsaccount11.svg"
                    />
                    <div className={styles.help}>Account</div>
                  </button>
                </div>
                <button
                  className={styles.smallButtons}
                  onClick={onSmallButtonsClick}
                >
                  <div className={styles.iconsaddParent}>
                    <img
                      className={styles.iconsadd}
                      alt=""
                      src="/iconsadd15.svg"
                    />
                    <div className={styles.button}>Post a job</div>
                  </div>
                </button>
              </div>
            </div>
          </nav>
        </div>
        <div className={styles.homeMobile}>
          <div className={styles.navbarMobileclosed}>
            <img className={styles.layer2Icon1} alt="" src="/layer-28.svg" 
            style={{cursor:'pointer'}} onClick={()=>navigate('/')} />
            <div className={styles.iconsaccountGroup}>
              <img
                className={styles.iconsaccount1}
                alt=""
                src="/iconsaccount6.svg"
              />
              <img
                className={styles.hamburgerIcon}
                alt=""
                src="/hamburger.svg"
                onClick={openMobileMenuConsumerPopup}
              />
            </div>
          </div>
          <div className={styles.homeMobileInner}>
            <div className={styles.frameWrapper3}>
              <div className={styles.serviceConsumerUserAgreemenGroup}>
                <b className={styles.serviceConsumerUser1}>
                  Service Consumer User Agreement
                </b>
                <div className={styles.thisAgreementBinds1}>
                  This agreement binds Service providers and Loggworks.
                </div>
              </div>
            </div>
          </div>
          <div style={{width:'90%'}}>
             <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
           value={value}
           onChange={(e)=>{
             setValue(e.target.value)
             if (e.target.value=='Definitions') {
               DivRef1m.current.scrollIntoView({ behavior: 'smooth' });
             }
             else if (e.target.value=='Content') {
               DivRef2m.current.scrollIntoView({ behavior: 'smooth' });
             }
             else if (e.target.value=='Loggworks Account') {
               DivRef3m.current.scrollIntoView({ behavior: 'smooth' });
             }
             else if (e.target.value=='Loggworks Service fee and payments') {
               DivRef4m.current.scrollIntoView({ behavior: 'smooth' });
             }
             else if (e.target.value=='Cancellation and Termination') {
               DivRef5m.current.scrollIntoView({ behavior: 'smooth' });
             }
             else if (e.target.value=='Contract between Consumers and Service professionals') {
               DivRef6m.current.scrollIntoView({ behavior: 'smooth' });
             }
             else if (e.target.value=='Warranty Disclaimer') {
               DivRef7m.current.scrollIntoView({ behavior: 'smooth' });
             }
             else if (e.target.value=='Comments and Feedbacks') {
               DivRef8m.current.scrollIntoView({ behavior: 'smooth' });
             }
             else if (e.target.value=='Intellectual Property Rights') {
               DivRef9m.current.scrollIntoView({ behavior: 'smooth' });
             }
             else if (e.target.value=='Indemnification') {
              DivRef10m.current.scrollIntoView({ behavior: 'smooth' });
            }
            else if (e.target.value=='Limitation of Liability') {
              DivRef11m.current.scrollIntoView({ behavior: 'smooth' });
            }
            else if (e.target.value=='Our Rights') {
              DivRef12m.current.scrollIntoView({ behavior: 'smooth' });
            }
            else if (e.target.value=='General') {
              DivRef13m.current.scrollIntoView({ behavior: 'smooth' });
            }
             else{
               DivRef14m.current.scrollIntoView({ behavior: 'smooth' });
             }
           }}
        >
          <MenuItem value={'Definitions'}>Definitions</MenuItem>
          <MenuItem value={'Content'}>Content</MenuItem>
          <MenuItem value={'Loggworks Account'}>Loggworks Account</MenuItem>
          <MenuItem value={'Loggworks Service fee and payments'}>Loggworks Service fee and payments</MenuItem>
          <MenuItem value={'Cancellation and Termination'}>Cancellation and Termination</MenuItem>
          <MenuItem value={'Contract between Consumers and Service professionals'}>Contract between Consumers and Service professionals</MenuItem>
          <MenuItem value={'Warranty Disclaimer'}>Warranty Disclaimer</MenuItem>
          <MenuItem value={'Comments and Feedbacks'}>Comments and Feedbacks</MenuItem>
          <MenuItem value={'Intellectual Property Rights'}>Intellectual Property Rights</MenuItem>
          <MenuItem value={'Indemnification'}>Indemnification</MenuItem>
          <MenuItem value={'Limitation of Liability'}>Limitation of Liability</MenuItem>
          <MenuItem value={'Our Rights'}>Our Rights</MenuItem>
          <MenuItem value={'General'}>General</MenuItem>
          <MenuItem value={'Chat'}>Chat</MenuItem>
        </Select>
      </FormControl>
          </div>
         
          <div className={styles.frameParent7}>
            <div className={styles.introductionGroup}>
              <div className={styles.thisBusinessRespectsContainer1}>
                <div className={styles.thisBusinessRespects}>
                  <Content
                     DivRef1={DivRef1m}
                     DivRef2={DivRef2m}
                     DivRef3={DivRef3m}
                     DivRef4={DivRef4m}
                     DivRef5={DivRef5m}
                     DivRef6={DivRef6m}
                     DivRef7={DivRef7m}
                     DivRef8={DivRef8m}
                     DivRef9={DivRef9m}
                     DivRef10={DivRef10m}
                     DivRef11={DivRef11m}
                     DivRef12={DivRef12m}
                     DivRef13={DivRef13m}
                     DivRef14={DivRef14m}
                  />
                </div>
                </div>
            </div>
          </div>
          <MobileFooter/>
        </div>
      </div>
      {isMobileMenuConsumerPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuConsumerPopup}
        >
          <MobileMenuConsumer1 onClose={closeMobileMenuConsumerPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default Agreement;


const Content=({DivRef1,DivRef2,DivRef3,DivRef4,DivRef5,DivRef6,
  DivRef7,DivRef8,DivRef9,DivRef10,DivRef11,DivRef12,DivRef13,DivRef14})=>{
return(
  <>
  <p>Please read these terms of service carefully before using the <a
href="https://loggworks.co.uk/"><u>https://loggworks.co.uk</u></a>
website and the mobile web application operated by Loggworks.</p>
<p>Your access to and use of the platform is subject to your acceptance
of and compliance with these terms. These terms apply to all visitors,
service consumers, service professionals, and others who access or use
the platform. You warrant that you are at least 18 years of age and that
you are legally capable of entering into binding contracts. If you are
under 18 years old, you warrant that you have obtained consent from your
parent or guardian, and they agree to be bound by these terms on your
behalf.</p>
<p>The <strong>Loggworks Terms of Service</strong> are comprised of the
following agreements:</p>
<p><u>The Service Consumer User Agreements</u></p>
<p><u>The Service Professional User Agreements</u></p>
<p><u>Terms of Use</u></p>
<p>The platform is for use in the United Kingdom only. You must not
access the service from any other jurisdiction. By accessing or using
the platform, you agree to be bound by these terms.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef1}>Definitions</h1>
<br></br>
<p>In these terms, except to the extent that the context otherwise
requires:</p>
<p>1.1 <strong>“Booking”</strong> means the booking of a service through
the platform when a service consumer books a service professional.</p>
<p>1.2 <strong>“Content”</strong> means information posted, linked,
stored, shared, or otherwise made available on the Platform or through
Loggworks, including text, graphics, videos or other material and
listings for Services;</p>
<p>1.3 <strong>“Feedback”</strong> <strong>or</strong>
<strong>“comment”</strong> means a star rating the consumer must
complete once the services for a job have been completed by the service
professional.</p>
<p>1.4 <strong>“Independent Person”</strong> means a person who the
consumer and the service professional agree upon to mediate a dispute or
failing agreement, a person selected by Loggworks.</p>
<p>1.5 <strong>“Intellectual Property”</strong> means and includes all
copyright and neighbouring rights, registered and unregistered
trademarks, designs, all rights in relation to the Platform and
inventions (including patents), processes, confidential information
(including trade secrets and know-how) and all other rights resulting
from intellectual activity;</p>
<p>1.6 <strong>“Job”</strong> means the service or task for which a user
engages a service professional to provide through the platform.</p>
<p>1.7 <strong>“Loggworks”</strong> means De land of Goshen Group
Limited, <mark>registered in England under No</mark>. 15387808</p>
<p>1.8 <strong>“Platform”</strong> means the Loggworks platform,
including the website Loggworks Business and the mobile application
‘Loggworks’ through which service professionals can get bookings from
users or tasks they are available to provide, and users can commission
or engage service professionals to complete a job.</p>
<p>1.9 <strong>“Price or service fee”</strong> means the sum agreed to
be paid for a service between a service professional and a user and
includes the service fee.</p>
<p>1.10 <strong>“Service(s)”</strong> means the service(s) to be
provided by a service professional to a service consumer through the
platform.</p>
<p>1.11 <strong>“Commision”</strong> means the fee Loggworks receives on
payment being made for a job or to complete a task by a service
professional.</p>
<p>1.12 <strong>“Service professionals”</strong> means businesses or
self-employed individuals who hold an account with Loggworks to provide
services or tasks in exchange for payment.</p>
<p>1.13 <strong>“Terms”</strong> means these terms and their use.</p>
<p>1.14 <strong>“Consumer” or “Service Consumer”</strong> means a
property owner who engages a service professional to provide a service
or complete a task through the platform.</p>
<p>1.15 <strong>“We”</strong> or <strong>“Us”</strong> means
Loggworks.</p>
<p>1.16 <strong>“you”</strong> means the person or business who has
signed up to use the platform, whether property owner, user, service
professional, or otherwise.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef2}>Content</h1>
<br></br>
<p>2.1 <strong>Loggworks</strong> allows you to post Content on the
Platform You are responsible for the content that you post to
<strong>Loggworks</strong>, including its legality, reliability, and
appropriateness.</p>
<p>2.2 By posting content to <strong>Loggworks</strong>, you grant us a
non-exclusive, unrestricted, and royalty-free licence to use, modify,
publicly perform, publicly display, reproduce, and distribute such
content on and through <strong>Loggworks</strong> and as otherwise
required to provide the platform for the promotion of
<strong>Loggworks</strong> and as permitted by the Terms. You retain any
and all of your rights to any content you submit, post, or display on or
through <strong>Loggworks,</strong> and you are responsible for
protecting those rights. You agree that this licence includes the right
for us to make your content available to other users of
<strong>Loggworks</strong>, who may also use your content, subject to
the Terms.</p>
<p>2.3 You represent and warrant that: the content is yours (you own
it), or you have the right to use it and grant us the rights and
licenses as provided in these Terms.</p>
<p>The posting of your content on or through <strong>Loggworks</strong>
does not violate the privacy rights, publicity rights, copyrights,
contract rights, or any other rights of any person; and you will only
post true and accurate content on the <strong>Loggworks</strong>
platform.</p>
<p>2.4 Further, you warrant that: the content will not cause you or
<strong>Loggworks</strong> to breach any law, regulation, rule, code, or
other legal obligation.</p>
<p>The content will not or could not be reasonably considered to be
obscene, inappropriate, defamatory, disparaging, indecent, seditious,
offensive, pornographic, threatening, abusive, liable to incite racial
hatred, discriminatory, harassing, fraudulent, in breach of confidence,
or in breach of privacy; The content will not be unsolicited,
undisclosed, or unauthorised advertising.</p>
<p>The content does not contain software viruses or any other computer
code, files, or programs designed to interrupt, destroy, or limit the
functionality of any computer software, hardware, or telecommunications
equipment. The content does not bring <strong>Loggworks</strong> into
disrepute.</p>
<p>The content must not, in any way whatsoever, be potentially or
harmful to <strong>Loggworks</strong> or any other person, including but
not limited to economic loss; and the content must not be false,
misleading, or deceptive.</p>
<p>2.5 You agree to keep all records necessary to establish that your
content does not violate any of the requirements of this clause and make
such records available upon reasonable request by
<strong>Loggworks</strong>. We are under no obligation to regularly
monitor the accuracy or reliability of your content incorporated into
the platform. We reserve the right to modify or remove any Content at
any time, without notice</p>
<p>2.6 You acknowledge and agree that all content you provide
<strong>Loggworks</strong> will be publicly available information, and
you bear the risks involved with such public disclosures.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef3}>Loggworks Account</h1>
<br></br>
<p>3.1 When you create an account with Loggworks, you must provide
information that is accurate, complete, and current at all times.
Failure to do so constitutes a breach of the terms, which may result in
the immediate termination of your account on the platform.</p>
<p>3.2 You are responsible for safeguarding the password that you use to
access Loggworks and for any activities or actions under your password,
whether your password is with Loggworks or a third-party service. You
must always remain in control of your account.</p>
<p>3.3 You agree not to disclose your password to any third party. You
agree to be fully responsible for activities that relate to your account
or password. You must notify Loggworks immediately upon becoming aware
of any breach of security or unauthorised use of your account.</p>
<p>3.4 You may not use as a username the name of another person or
entity that is not lawfully available for use, a name or trademark that
is subject to any rights of another person or entity other than you
without appropriate authorization, or a name that is otherwise
offensive, vulgar, or obscene.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef4}>Loggworks Service fee and payments</h1>
<br></br>
<p>4.1 If you wish to book any service made available through Loggworks,
you may be asked to provide certain information relevant to your
booking, including, without limitation, your payment details.</p>
<p>4.2 Loggworks uses the Stripe Connect account to pay the price for a
service. The payment system works as follows:</p>
<p>The service consumer posts a job or requests a service from a service
professional. A service professional receives the booking and raises a
quote and then an invoice for the consumer. Until payment of the price
is received from the service consumer, there is no confirmed
booking.</p>
<p>The consumer pays the price into the Loggworks administration account
to be held pending completion of the job. Once the price has been paid,
a job is created. Once the job is completed by the service professional,
the funds are released from the Loggworks administration account to the
service professional.</p>
<p>In the case of a dispute or cancellation, the price will be directed
by Loggworks to the appropriate party in accordance with these
terms.</p>
<p>4.3 You represent and warrant that:</p>
<p>You have the legal right to use any debit or credit card(s) or other
payment method(s) in connection with any payment made on the Platform;
and the information you supply to Loggworks is true, correct, and
complete.</p>
<p>4.4 By submitting such information, you grant Loggworks the right to
provide the information to third parties for purposes of facilitating
the payment of a service.</p>
<p>4.5 The service professional reserves the right to refuse or cancel
your booking at any time for certain reasons, including but not limited
to service availability, errors in the description or payment of the
service, errors in the booking, or other reasons.</p>
<p>4.6 Loggworks is not designed to (and does not) provide advice or any
assurance or warranty regarding the fitness for purpose and
merchantability of any services offered on the Loggworks Platform by
service professionals. We strongly advise you to exercise caution and
complete your own due diligence when conducting any jobs through
Loggworks. We provide the platform only for you to book various services
offered by service professionals.</p>
<p>4.7 All payment processing fees payable to Loggworks are
non-refundable.</p>
<p>4.8 If a job is cancelled, no service fee is payable, and the price
of the job will be returned to the service consumer.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef5}>Cancellation and Termination</h1>
<br></br>
<p>5.1 Loggworks may terminate or suspend your account immediately, in
its absolute discretion, without prior notice for any reason whatsoever,
including without limitation if Loggworks forms the view that you have
breached the Terms or if your account is considered by Loggworks to be
detrimental to the operation of our platform.</p>
<p>5.2 Loggworks is not liable for any loss or damage suffered by you as
a result of its decision to terminate your account. Upon termination,
your right to use the platform will immediately cease. If you wish to
terminate your account, you may simply discontinue using the
platform.</p>
<p>5.3 All provisions of the Terms that, by their nature, should survive
termination shall survive termination, including, without limitation,
ownership provisions, warranty disclaimers, indemnity, and limitations
of liability.</p>
<p>5.4 Loggworks shall not be liable to you or any third-party for any
claims or damages arising out of any termination or suspension, or any
other actions taken by Loggworks in connection therewith. If applicable
law requires Loggworks to provide notice of termination or cancellation,
we may give prior or subsequent notice by posting it on the platform or
by sending a communication to any address (email or otherwise) that
Loggworks has for you on record.</p>
<p>5.5 If Loggworks determines, in its absolute discretion, that you
have breached the Terms, it reserves the right to remove your account
from the platform and/or cancel or suspend your account.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef6}>Contract between Consumers and Service
professionals</h1>
<br></br>
<p>When a service professional accepts a service consumer’s request to
provide services or complete a task, a contract is formed between the
consumer and the service professional on the following terms, unless
otherwise agreed between the parties:</p>
<p>6.1 Commencement and Term</p>
<p>The contract commences with the creation of the job and will continue
until: the services to be rendered as described in the booking,
including any variations or amendments agreed before or after the
creation of the job, are completed; or otherwise agreed by the consumer
and service professional for the job.</p>
<p>6.2 Services</p>
<p>The service professional will perform the services in a proper and
professional manner and at the time and location agreed upon between the
service consumer and the service professional. The parties must perform
their obligations in accordance with any other terms or conditions
agreed by the parties during or after the creation of the contract.</p>
<p>The service professional is not permitted to subcontract the services
or any part of them to any third party without the consumer’s consent.
If a service professional does subcontract the services or any part of
them in accordance with this clause, the service professional remains
responsible for any acts or omissions of the subcontractor in completing
the services.</p>
<p>6.3 Warranties</p>
<p>Each party warrants that the information provided in the booking and
in the job is true and correct. The service professional warrants that
they have the ability to provide the services lawfully and hold all
relevant licences as required by law to perform the services.</p>
<p>6.4 Payment or Cancellation</p>
<p>Upon the creation of the job, the service consumer must pay the
agreed price for the services to the Loggworks administration account.
When the services have been completed by the service professional, they
must notify the consumer. If the service professional has completed the
services in accordance with clause <strong>(6.2)</strong>, the consumer
must confirm the job has been completed for the service.</p>
<p>On confirmation of completion of the job through the platform (in
accordance with clause 4.2), the payment is to be released to the
service professional by Loggworks. If the consumer fails to confirm the
job completion after 3 working days, the payment will be released to the
professional automatically, and if the consumer and the service
professional agree to cancel the job or the service professional cannot
be contacted to perform the services, the payment will be returned to
the consumer.</p>
<p>6.5 Limitation of Liability</p>
<p>Except for liability in relation to an implied guarantee or warranty
that cannot be excluded by law, the parties exclude any liability
arising out of or in connection with the Services, any claims by third
parties, or the contract. The liability of the user and the service
professional is limited to the price under the contract.</p>
<p>6.6 Disputes Resolution</p>
<p>If a dispute arises, the consumer and the service professional will
genuinely and reasonably attempt to resolve the dispute within 14 days
through informal negotiation. Loggworks has no obligation to any
consumer or service professional to assist or become involved in any
disputes between consumers and service professionals.</p>
<p>You are encouraged to attempt to resolve disputes (including claims
for returns or refunds) with other users directly. Loggworks may provide
your information to other parties involved in a dispute as it considers
appropriate, in its absolute discretion. Where information is provided
for this purpose, you must not use the information disclosed to you by
us for any other purpose.</p>
<p>You must cooperate with other users if a dispute arises and genuinely
attempt to resolve the dispute. Loggworks has the right to hold any
money that is the subject of a dispute in our administration account
until the dispute has been resolved.</p>
<p>If the dispute has not been resolved after 14 days, the user's must
confer at least once, at the option of either party, and provided the
independent person so agrees, in the presence of the independent person,
to attempt to resolve the dispute and failing resolution to explore and,
if possible, agree on methods of resolving the dispute by other means.
The costs of the independent person shall be met equally by the consumer
and the service professional. At any such conference, the consumer and
the service professional shall be represented by a person having
authority to agree to a resolution to the dispute. In the event that the
dispute cannot be so resolved or if at any time either party considers
that the other party is not making reasonable efforts in good faith to
resolve the dispute, either party may, by notice in writing, deliver by
hand or send by certified mail to the other party, refer such a dispute
to arbitration or litigation.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef7}>Disclaimer</h1>
<br></br>
<p>7.1 Your use of the platform is at your sole risk. The platform is
provided on an "AS IS" and "AS AVAILABLE" basis. To the extent permitted
by law, the platform is provided without warranties of any kind, whether
express or implied, including but not limited to implied warranties of
merchantability, fitness for a particular purpose, noninfringement, or
course of performance.</p>
<p>7.2 Loggworks, its subsidiaries, affiliates, and its licensors do not
warrant that the platform will function uninterrupted, secure, or
available at any particular time or location. Any errors or defects will
be corrected. The platform is free of viruses or other harmful
components, and the results of using the platform will meet your
requirements.</p>
<p>7.3 This disclaimer of liability applies to any damages or injury
caused by any failure of performance, error, omission, interruption,
deletion, defect, delay in operation or transmission, computer virus,
communication line failure, theft, destruction, or unauthorised access,
or alteration of or use of records in connection with the use or
operation of the platform, whether for breach of contract, tortious
behaviour, negligence, or any other cause of action.</p>
<p>7.4 Loggworks makes no representations or warranties of any kind, to
the extent permitted by law, express or implied, about the completeness,
accuracy, reliability, suitability, or availability with respect to the
content contained on the platform for any purpose. Any reliance you
place on such information is, therefore, strictly at your own risk.
Loggworks disclaims any express or implied warranty, representation, or
guarantee as to the effectiveness or profitability of the platform or
that the operation of the platform will be uninterrupted or error-free.
Loggworks is not liable for the consequences of any interruptions or
errors on the platform.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef8}>Comments and Feedbacks</h1>
<br></br>
<p>8.1 At the end of a job, a service consumer is advised to provide
feedback about the services provided by a service professional.</p>
<p>8.2 You can report or complain about an inappropriate or unjustified
act by contacting loggworks at <a
href="mailto:support@Loggworks.co.uk"><u>support@Loggworks.co.uk</u></a></p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef9}>Intellectual Property Rights</h1>
<br></br>
<p>9.1 The platform and its original content (excluding content provided
by users), features, and functionality are and will remain the exclusive
property of Loggworks and its licensors. The platform is protected by
copyright, trademarks, and the laws of England, Wales, and other foreign
countries. Our trademark, trade address, and intellectual property may
not be used in connection with any product or service without the prior
written consent of Loggworks. Nothing in these terms constitutes a
transfer of any intellectual property rights from us to you.</p>
<p>9.2 You are permitted to use the platform only as authorised by
Loggworks. As a consumer or service professional, you are granted a
limited, non-exclusive, revocable, and non-transferable right to use the
platform to create, display, use, and download content, subject to these
terms.</p>
<p>9.3 Loggworks intellectual property must not be used in connection
with a product or service that is not affiliated with Loggworks or in
any way brings Loggworks into disrepute.</p>
<p>9.4 You must not modify the physical or digital copies of any content
or intellectual property you obtain in any way; you must not use any
illustrations, photographs, video or audio, or any graphics separately
from any accompanying text.</p>
<p>9.5 Any opinions, advice, statements, services, offers, or other
information or content expressed or made available by any other users
are those of the respective authors or distributors and not of
Loggworks.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef10}>Indemnification</h1>
<br></br>
<p>10.1 It is your responsibility to ensure that you are entitled to
provide the service consumers (property owner, property manager, tenant)
with information, and you therefore agree to indemnify us against any
and all expenses, damages, and losses of any kind (including reasonable
legal fees and costs) incurred by us in connection with any actual or
threatened claims of any kind (including without limitation any claim of
trademark or copyright infringement, defamation, breach of
confidentiality, false or misleading advertising, or sales practices)
arising from your provision of service consumer information.</p>
<p>10.2 Loggworks shall indemnify you against any and all expenses,
damages, and losses of any kind (including reasonable legal fees and
costs) incurred by you in connection with any actual or threatened
claims of any kind (including without limitation any claim of trademark
or copyright infringement, defamation, breach of confidentiality, false
or misleading advertising, or sales practices) that any material on the
platform generated and uploaded by us infringes the intellectual
property of any third party.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef11}>Limitation of Liability</h1>
<br></br>
<p>11.1 Notwithstanding any other provision, nothing in this user
agreement shall exclude or limit either party’s liability for death or
personal injury caused by that party’s negligence, fraud, or fraudulent
misrepresentation, or any other liability that cannot lawfully be
excluded or limited.</p>
<p>11.2 If you are dissatisfied with the <strong>platform</strong> or
any of these terms of use, your sole remedy under these terms of use
shall be to discontinue use of the platform.</p>
<p>11.3 Without limiting the foregoing, Loggworks shall have no
liability for any failure or delay resulting from any matter beyond our
reasonable control.</p>
<p>11.4 Other than as set out in this Limitation of Liability section,
and notwithstanding any other provision of these Terms of Use,
<strong>Loggworks</strong> shall not be liable in contract, tort,
negligence, statutory duty, misrepresentation, or otherwise, for any
loss or damage whatsoever arising from or in any way connected with
these Terms of Use.</p>
<p>11.5 Save as expressly set out herein, all conditions, warranties,
and obligations that may be implied or incorporated into these Terms of
Use by statute, common law, or otherwise, and any liabilities arising
from them, are hereby expressly excluded to the extent permitted by
law.</p>
<p>11.6 <strong>Loggworks</strong> shall not be liable for any loss of
business, loss of profits, business interruption, loss of business
information, loss of data, or any other pecuniary loss (even where we
have been advised of the possibility of such loss or damage).</p>
<p>11.7 In the event that any limitation or exclusion of liability in
these Terms of Use proves ineffective, then Loggworks shall not be
liable to you for more than £100 in aggregate. If you register on the
website or any of the apps, then only the aggregate cap on liability
under the agreement that you enter upon registration shall apply. If you
register as both a “consumer” and a “service professional,” then only
the aggregate cap in the Service Professional User Agreement shall
apply.</p>
<p>11.8 Loggworks cannot guarantee the day or time that we will respond
to any email, telephone, or written inquiries or website form
submissions.</p>
<p>11.9 Each of the provisions of this clause shall be construed
separately and independently of the others.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef12}>Our Rights</h1>
<br></br>
<p>12.1 Loggworks reserves the right at all times to edit, refuse to
post, or remove from the service any information or materials that we
consider breaches or are likely to breach this Agreement, or which are
or may be otherwise illegal or objectionable, and to disclose any
information we deem appropriate to satisfy any applicable law,
regulation, legal process, police request, or governmental request.</p>
<p>12.2 Without prejudice to the generality of the above, Loggworks
reserves the right to terminate the provision to you of the service or
restrict your access to the service at any time and/or to terminate this
agreement immediately on notice in the event that you are in material
breach of this agreement.</p>
<p>12.3 In all other circumstances, we reserve our rights to: modify or
discontinue temporarily or permanently all or part of the Service;
terminate the provision to you of the Service or restrict your access to
the Service; and/or terminate this Agreement at any time without notice
for any reason whatsoever, without liability of any kind to you
(provided always that any such termination shall be without prejudice to
the rights and liabilities of each party accrued prior to such
termination).</p>
<p>12.4 Loggworks may vary the terms of this agreement from time to time
and shall post the revised terms on the website. If you do not agree to
the revisions made by us to the terms of this agreement, then you have
the right to stop using the service and should do so immediately. All
revisions that we make to the terms of this agreement shall become
effective on the date four business days after the date on which the
revised terms in question are posted on the. Your continued use of the
service after that date will constitute acceptance of the amended
agreement.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef13}>General</h1>
<br></br>
<p>13.1 Clause headings are inserted for convenience only and shall not
affect the interpretation of these Terms of Use.</p>
<p>13.2 If any provisions hereof are held to be illegal or
unenforceable, such provisions shall be severed, and the remainder of
these Terms of Use shall remain in full force and effect unless the
business purpose of these Terms of Use is substantially frustrated, in
which case they shall terminate without giving rise to further
liability.</p>
<p>13.3 You may not assign, transfer, or subcontract any of your rights
hereunder without our prior written consent. We may assign, transfer, or
sub-contract all or any of our rights at any time without consent.</p>
<p>13.4 No waiver shall be effective unless in writing, and no waiver
shall constitute a continuing waiver so as to prevent us from acting
upon any continuing or subsequent breach or default.</p>
<p>13.5 These agreements constitute the entire agreement as to its
subject matter and supersede and extinguish all previous communications,
representations (other than fraudulent misrepresentations), and
arrangements, whether written or oral, with the exception of the Service
Consumer User Agreement and/or Service Professional User Agreement where
these have been entered into. To the extent that there is any conflict
between them, those agreements shall apply in the following order of
precedence:</p>
<ul>
<li><p>the Service professionals User Agreement</p></li>
<li><p>the Service Consumers User Agreement; then</p></li>
<li><p>these Terms of Use.</p></li>
</ul>
<p>13.6 You acknowledge that you have placed no reliance on any
representation made but not set out expressly in these Terms of Use.</p>
<p>13.7 Any notice to be given under these Terms of Use may be given via
e-mail, regular mail, facsimile, or by hand to the address provided on
the website or otherwise as notified by one party to the other.</p>
<p>13.8 Nothing herein shall create or be deemed to create any joint
venture, principal-agent, or partnership relationship between the
parties, and neither party shall hold itself out in its advertising or
otherwise in any manner that would indicate or imply any such
relationship with the other.</p>
<p>13.9 Notwithstanding any other provision in these Terms of Use, a
person who is not a party hereto has no right under the Contracts
(Rights of Third Parties) Act 1999 to rely upon or enforce these Terms
of Use</p>
<p>13.10 These Terms of Use shall be subject to the laws of England, and
the parties shall submit to the exclusive jurisdiction of the English
courts.</p>
<p>13.11 In the event of any comments or questions regarding these Terms
of Use (including the <u>Privacy Policy),</u> please <u>contact
us</u>.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef14}>Chat</h1>
<br></br>
<p>14.1 Our chat feature enables you, the service consumer, to text,
call, and share content with service professionals on our web and mobile
app platforms. This includes, but is not limited to, sharing text,
images, and video. To protect any personal information that is shared
via chat, at no point should content be copied, shared, re-posted, or
repurposed on any other platform or site.</p>
<p>14.2 We take no responsibility for any of the content posted using
our chat feature, either by yourself or anyone you are in conversation
with via the chat feature. We do not monitor the messages sent via chat
but do retain the right to use or edit anything posted in a chat message
or remove any content that breaks this User Agreement. We also reserve
the right to pass any, or all, content deemed inappropriate or that
relates to a criminal investigation to the relevant authorities.</p>
<p>14.3 Any additional job that is agreed upon with a service
professional outside of the chat feature of the Loggworks mobile app or
web platform will not be protected under the contractual agreement and
our terms of use.</p>
<p>14.4 Should you, or anyone else, post content we deem inappropriate
or that contravenes this User Agreement, we reserve the right to
permanently remove you, or that user, from our platform.</p>
<p>14.5 If you need to report abuse or the posting of offensive,
inappropriate, or illegal content via chat, please contact us with
details, and our team will investigate.</p>
</>
)
}