import { useState, useCallback } from "react";
import Dropdown4 from "../components/Dropdown4";
import PortalPopup from "../components/PortalPopup";
import Dropdown5 from "../components/Dropdown5";
import { useNavigate } from "react-router-dom";
import styles from "./ProfessionalHiringGuideArti.module.css";
import LocationSearch from "../components/locationSearch.tsx";
const ProfessionalHiringGuideArti = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdown1Open, setDropdown1Open] = useState(false);
  const navigate = useNavigate();

  const onPostAJob1Click = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onHowItWorksClick = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQsClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementTextClick = useCallback(() => {
    navigate("/agreement");
  }, [navigate]);

  const onHowItWorks1Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onContactUsText1Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQs1Click = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementText1Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/becometeam");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/partners");
  }, [navigate]);

  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/affiliate-program");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onLargeButtons1Click = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onPageSelectorContainerClick = useCallback(() => {
    navigate("/professional");
  }, [navigate]);

  const onHowItWorks2Click = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const openDropdown = useCallback(() => {
    setDropdownOpen(true);
  }, []);

  const closeDropdown = useCallback(() => {
    setDropdownOpen(false);
  }, []);

  const openDropdown1 = useCallback(() => {
    setDropdown1Open(true);
  }, []);

  const closeDropdown1 = useCallback(() => {
    setDropdown1Open(false);
  }, []);

  const onFrameButtonClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onSmallButtonsClick = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onHiringGuideTextClick = useCallback(() => {
    navigate("/professionalhiring-guidedone1");
  }, [navigate]);

  return (
    <>
      <div className={styles.professionalhiringGuidearti}>
        <div className={styles.container}>
          <div className={styles.merge}>
            <div className={styles.titleImg}>
              <div className={styles.plumbingRepairsParent}>
                <div className={styles.plumbingRepairs}>Plumbing repairs</div>
                <div className={styles.articleCategory}>
                  <div className={styles.category}>Plumbers</div>
                </div>
              </div>
              <img
                className={styles.titleImgChild}
                alt=""
                src="/rectangle-12308@2x.png"
              />
            </div>
            <div className={styles.contentArea}>
              <div className={styles.plumbingRepairCostsContainer}>
                <p className={styles.plumbingRepairCosts}>
                  Plumbing repair costs can vary greatly depending on the
                  specific issue and the type of property. Here is a rough guide
                  to some common plumbing repair costs:
                </p>
                <ul className={styles.cloggedDrain100250Leaky}>
                  <li className={styles.waterHeaterRepair}>
                    Clogged drain: $100-$250
                  </li>
                  <li className={styles.waterHeaterRepair}>
                    Leaky faucet: $100-$200
                  </li>
                  <li className={styles.waterHeaterRepair}>
                    Running toilet: $100-$200
                  </li>
                  <li className={styles.waterHeaterRepair}>
                    Water heater repair or replacement: $500-$1,500
                  </li>
                  <li className={styles.waterHeaterRepair}>
                    Sewer line repair or replacement: $1,000-$4,000
                  </li>
                  <li className={styles.waterHeaterRepair}>
                    Water line repair or replacement: $500-$1,500
                  </li>
                  <li className={styles.waterHeaterRepair}>
                    Gas line repair or replacement: $500-$1,500
                  </li>
                </ul>
                <p className={styles.plumbingRepairCosts}>
                  It's important to note that these estimates are rough and the
                  actual cost could be higher or lower depending on the specific
                  circumstances of the repair. It's always best to get a few
                  quotes from local plumbers to get a more accurate idea of the
                  cost.
                </p>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.frameWrapper}>
                  <div className={styles.shareThisArticleParent}>
                    <div className={styles.shareThisArticle}>
                      Share this article:
                    </div>
                    <div className={styles.rifacebookBoxFillParent}>
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/rifacebookboxfill.svg"
                      />
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/ritwitterfill.svg"
                      />
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/rilinkedinboxfill.svg"
                      />
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/icroundmail.svg"
                      />
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/rilink.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.ad}>
                  <div className={styles.merge1}>
                    <div className={styles.text}>
                      <div className={styles.cantFindThe}>
                        Can’t find the professional you are looking for?? Let
                        the professional find you.
                      </div>
                      <div className={styles.postAJob}>
                        Post a job with your price in mind, then get offers
                      </div>
                    </div>
                    <div className={styles.smallButtons}>
                      <div className={styles.buttonWrapper}>
                        <div className={styles.button}>Post a Job</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.mergeWrapper}>
                  <div className={styles.merge2}>
                    <img
                      className={styles.mergeChild}
                      alt=""
                      src="/rectangle-122976@2x.png"
                    />
                    <div className={styles.text1}>
                      <div className={styles.doYouHaveAProfessionalSerParent}>
                        <div className={styles.doYouHave}>
                          Do you have a professional service to offer?
                        </div>
                        <div
                          className={styles.allYourPayments}
                        >{`All your payments are protected by a highly secure Escrow account. `}</div>
                      </div>
                      <div className={styles.largeButtons}
                       style={{cursor:'pointer'}}
                       onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                        <img
                          className={styles.icons20pxplaceholder}
                          alt=""
                          src="/icons20pxplaceholder39.svg"
                        />
                        <b className={styles.category}>Become a professional</b>
                        <img
                          className={styles.icons20pxplaceholder}
                          alt=""
                          src="/icons20pxplaceholder39.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.prototypeFooter}>
          <div className={styles.footer}>
            <div className={styles.download}>
              <div className={styles.downloadOurApps}>Download our apps</div>
              <img
                className={styles.playstoreButtonIcon}
                alt=""
                src="/playstore-button8.svg"
              />
              <img
                className={styles.appstoreButtonIcon}
                alt=""
                src="/appstore-button9.svg"
              />
            </div>
            <div className={styles.footerChild} />
            <img className={styles.layer2Icon} 
             style={{cursor:'pointer'}}
             onClick={()=>{navigate('/')}}
             alt="" src="/layer-271.svg" />
            <div className={styles.frameGroup}>
              <div className={styles.homeownersParent}>
                <b className={styles.homeowners}>Homeowners</b>
                <div className={styles.postAJobParent}>
                  <div className={styles.howItWorks} onClick={onPostAJob1Click}>
                    Post a Job
                  </div>
                  <div
                    className={styles.howItWorks}
                    onClick={onHowItWorksClick}
                  >
                    How it works
                  </div>
                  <div
                    className={styles.howItWorks}
                    onClick={onContactUsTextClick}
                  >
                    Contact Us
                  </div>
                  <div
                    className={styles.howItWorks}
                    onClick={onHelpFAQsClick}
                  >{`Help & FAQ’s`}</div>
                  <div
                    className={styles.howItWorks}
                    onClick={onUserAgreementTextClick}
                  >
                    User Agreement
                  </div>
                </div>
              </div>
              <div className={styles.professionalsParent}>
                <b className={styles.homeowners}>Professionals</b>
                <div className={styles.postAJobParent}>
                  <div className={styles.signUp}
                  style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                  <div
                    className={styles.howItWorks}
                    onClick={onHowItWorks1Click}
                  >
                    Blog
                  </div>
                  <div
                    className={styles.howItWorks}
                    onClick={onContactUsText1Click}
                  >
                    Contact Us
                  </div>
                  <div
                    className={styles.howItWorks}
                    onClick={onHelpFAQs1Click}
                  >{`Help & FAQ’s`}</div>
                  <div
                    className={styles.howItWorks}
                    onClick={onUserAgreementText1Click}
                  >
                    User Agreement
                  </div>
                </div>
              </div>
              <div className={styles.loggworksParent}>
                <b className={styles.homeowners}>Loggworks</b>
                <div className={styles.postAJobParent}>
                  <div
                    className={styles.howItWorks}
                    onClick={onAboutUsTextClick}
                  >
                    About us
                  </div>
                  <div
                    className={styles.howItWorks}
                    onClick={onCareersTextClick}
                  >
                    Careers
                  </div>
                  <div
                    className={styles.howItWorks}
                    onClick={onPartnersTextClick}
                  >
                    Partners
                  </div>
                  <div
                    className={styles.howItWorks}
                    onClick={onInfluencerProgramTextClick}
                  >
                    Influencer program
                  </div>
                  <div className={styles.blog}>Blog</div>
                </div>
              </div>
              <div className={styles.loggworksGroup}>
                <div className={styles.loggworks1}>
                  <b className={styles.followUs}>Follow us</b>
                </div>
                <div className={styles.groupParent}>
                  <div className={styles.frameContainer}>
                    <img
                      className={styles.frameIcon}
                      alt=""
                      src="/frame20.svg"
                    />
                    <div className={styles.facebook}>Facebook</div>
                  </div>
                  <div className={styles.groupDiv}>
                    <img
                      className={styles.frameIcon1}
                      alt=""
                      src="/frame37.svg"
                    />
                    <div className={styles.twitter}>Twitter</div>
                  </div>
                  <div className={styles.frameParent1}>
                    <img
                      className={styles.frameIcon1}
                      alt=""
                      src="/frame22.svg"
                    />
                    <div className={styles.twitter}>Instagram</div>
                  </div>
                  <div className={styles.frameParent2}>
                    <img
                      className={styles.frameIcon1}
                      alt=""
                      src="/frame23.svg"
                    />
                    <div className={styles.twitter}>YouTube</div>
                  </div>
                  <div className={styles.mdilinkedinParent}>
                    <img
                      className={styles.mdilinkedinIcon}
                      alt=""
                      src="/mdilinkedin1.svg"
                    />
                    <div className={styles.linkedin}>LinkedIn</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.copyright2022LoggworkLtdAParent}>
              <div className={styles.copyright2022Loggwork}>
                ©Copyright 2024 loggworks  Ltd. All Rights Reserved
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.termsOfUseParent}>
                  <div className={styles.copyright2022Loggwork}
                  style={{cursor:'pointer'}}
                  onClick={()=>{navigate('/terms')}}>
                    Terms of Use
                  </div>
                  <div className={styles.frameChild} />
                </div>
                <div className={styles.termsOfUseParent}>
                  <div
                    className={styles.copyright2022Loggwork}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/privacy')}}
                  >{`Privacy Policy `}</div>
                  <div className={styles.frameChild} />
                </div>
                <div className={styles.termsOfUseParent}>
                  <div className={styles.copyright2022Loggwork}
                   style={{cursor:'pointer'}}
                   onClick={()=>{navigate('/cookie')}}>Cookies</div>
                  <div className={styles.frameChild} />
                </div>
                <div className={styles.contactUsParent}>
                  <div className={styles.copyright2022Loggwork}>Contact Us</div>
                  <div className={styles.frameChild} />
                </div>
                <div className={styles.termsOfUseParent}>
                  <div className={styles.copyright2022Loggwork} style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href='https://www.loggworks.co.uk/sitemap.xml'
                    }}>Sitemap</div>
                  <div className={styles.frameChild1} />
                </div>
              </div>
            </div>
            <div className={styles.largeButtonsParent}>
              <button
                className={styles.largeButtons1}
                style={{cursor:'pointer'}}
                onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
              >
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder38.svg"
                />
                <b className={styles.label1}>Become a Professional</b>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder38.svg"
                />
              </button>
              <button
                className={styles.largeButtons1}
                onClick={onLargeButtons1Click}
              >
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder39.svg"
                />
                <b className={styles.label1}>Post a Job</b>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder39.svg"
                />
              </button>
            </div>
          </div>
        </div>
        <nav className={styles.navigation}>
          <div className={styles.navigation1}>
            <img
              className={styles.navigationChild}
              alt=""
              src="/frame-665223.svg"
            />
            <div className={styles.navigationItem} />
            <div className={styles.tabSwitch}>
              <div
                className={styles.pageSelector}
                onClick={onPageSelectorContainerClick}
              >
                <div className={styles.descriptionParent}>
                  <div className={styles.copyright2022Loggwork}>
                    Professional
                  </div>
                  <div className={styles.selector} />
                </div>
              </div>
              <div className={styles.pageSelector1}>
                <div className={styles.descriptionParent}>
                  <b className={styles.description1}>Consumer</b>
                  <div className={styles.selector1} />
                </div>
              </div>
            </div>
           <LocationSearch/>
          </div>
          <div className={styles.navigation2}>
            <div className={styles.homeParent}>
              <div className={styles.home}>Home</div>
              <div className={styles.howItWorks2} onClick={onHowItWorks2Click}>
                How it works
              </div>
              <div className={styles.services} onClick={openDropdown}>
                <div className={styles.home}>Services</div>
                <img
                  className={styles.rifacebookBoxFillIcon}
                  alt=""
                  src="/iconschevrondown1.svg"
                />
              </div>
              <div className={styles.resources} onClick={openDropdown1}>
                <div className={styles.home}>Resources</div>
                <img
                  className={styles.rifacebookBoxFillIcon}
                  alt=""
                  src="/iconschevrondown1.svg"
                />
              </div>
              <div className={styles.pricing}>Pricing</div>
            </div>
            <div className={styles.frameParent3}>
              <div className={styles.frameParent4}>
                <button
                  className={styles.iconshelpParent}
                  onClick={onFrameButtonClick}
                >
                  <img
                    className={styles.rifacebookBoxFillIcon}
                    alt=""
                    src="/iconshelp.svg"
                  />
                  <div className={styles.help}>Help</div>
                </button>
                <button className={styles.iconshelpParent}>
                  <img
                    className={styles.rifacebookBoxFillIcon}
                    alt=""
                    src="/iconsaccount11.svg"
                  />
                  <div className={styles.help}>Account</div>
                </button>
              </div>
              <button
                className={styles.smallButtons1}
                onClick={onSmallButtonsClick}
              >
                <div className={styles.iconsaddParent}>
                  <img
                    className={styles.iconsadd}
                    alt=""
                    src="/iconsadd15.svg"
                  />
                  <div className={styles.button1}>Post a job</div>
                </div>
              </button>
            </div>
          </div>
        </nav>
        <div className={styles.breadcrumbs}>
          <div className={styles.mdilinkedinParent}>
            <div
              className={styles.hiringGuide}
              onClick={onHiringGuideTextClick}
            >
              Pricing Guide
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright6.svg"
            />
            <div className={styles.hiringGuide1}>Plumbing repairs</div>
            <img
              className={styles.iconschevronRight1}
              alt=""
              src="/iconschevronright7.svg"
            />
            <div className={styles.hiringGuide2}>Plumbing repairs</div>
            <img
              className={styles.iconschevronRight1}
              alt=""
              src="/iconschevronright7.svg"
            />
            <div className={styles.hiringGuide3}>Hiring guide</div>
            <img
              className={styles.iconschevronRight1}
              alt=""
              src="/iconschevronright7.svg"
            />
            <div className={styles.hiringGuide3}>Hiring guide</div>
            <img
              className={styles.iconschevronRight1}
              alt=""
              src="/iconschevronright7.svg"
            />
            <div className={styles.hiringGuide3}>Hiring guide</div>
            <img
              className={styles.iconschevronRight1}
              alt=""
              src="/iconschevronright7.svg"
            />
            <div className={styles.hiringGuide3}>Hiring guide</div>
            <img
              className={styles.iconschevronRight1}
              alt=""
              src="/iconschevronright7.svg"
            />
            <div className={styles.hiringGuide3}>Hiring guide</div>
            <img
              className={styles.iconschevronRight1}
              alt=""
              src="/iconschevronright7.svg"
            />
            <div className={styles.hiringGuide3}>Hiring guide</div>
          </div>
        </div>
      </div>
      {isDropdownOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDropdown}
        >
          <Dropdown4 onClose={closeDropdown} />
        </PortalPopup>
      )}
      {isDropdown1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDropdown1}
        >
          <Dropdown5 onClose={closeDropdown1} />
        </PortalPopup>
      )}
    </>
  );
};

export default ProfessionalHiringGuideArti;
