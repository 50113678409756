import React, { useState } from 'react';
import { TextField, Button, Snackbar, Alert, Collapse, IconButton, Typography, CircularProgress } from '@mui/material';
import styles from "../HiringSelect.module.css";
import axios from 'axios';

function SubsBanner() {
    const [email, setEmail] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const closeSnackbar = () => {
    console.log('Setting timeout to close snackbar');
    setTimeout(() => {
      setOpenSnackbar(false);
      console.log(false);
    }, 2000); // 5000 milliseconds = 5 seconds
  };
  
  const handleSubmit = async () => {
    try {
      setLoading(true)
   await axios.put(
          'https://api.sendgrid.com/v3/marketing/contacts',
          {
            contacts: [{ email }],
            list_ids: ['c3442403-ab98-4262-b061-3ff08dcddb5e'],
          },
          {
            headers: {
              'content-type': 'application/json',
              Authorization: `Bearer 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`,
            },
          }
        );
   
        setSnackbarSeverity('success');
        setSnackbarMessage(
        'Your email has been successfully added to the mailing list. Welcome 👋'
      );
      setEmail('')
        setOpenSnackbar(true);
      } catch (error) {
        console.error('Error:', error);
        setSnackbarSeverity('error');
        setSnackbarMessage(
          'Oops, there was a problem with your subscription, please try again or contact us'
        );
      }
      setLoading(false)
      closeSnackbar();
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  return (
    <div style={{width:'100%'}}>
     
  <div className={styles.subscribeForNewsletter}>
          <div className={styles.subscribeForNewsletterInner}>
          <Collapse in={openSnackbar}>
          <>
              <Typography variant='h3' align='center' fontStyle={'bold'}>Yayy!!</Typography>
         <p style={{textAlign:'center',fontSize:'20px'}}>You have successfully subscribed to the newsletter</p>
              </>
      </Collapse>
      <Collapse in={!openSnackbar}>
         <div className={styles.frameParent7}>
              <div className={styles.frameParent8}>
                <div className={styles.getFreeInsightsAndLatestUParent}>
                  <div className={styles.getFreeInsights}>
                    Get free insights and latest updates
                  </div>
                  <div className={styles.subscribeToOurContainer}>
                    <p className={styles.exploreExpertAdvice}>
                      Subscribe to our blog and get free tips,
                      inspiration
                    </p>
                    <p className={styles.exploreExpertAdvice}>
                      and resources delivered directly to your inbox.
                    </p>
                  </div>
                </div>
                
                <div className={styles.smallButtonsParent}>
                  <button className={styles.smallButtons2}
                  onClick={handleSubmit}>
                    <div className={styles.iconsaddParent}>
                      <img
                        className={styles.iconsadd}
                        alt=""
                        src="/iconsadd17.svg"
                      />
                      <div className={styles.button}>{!loading?'Subscribe': <CircularProgress 
                     style={{color:'white'}}
                      />}</div>
                    </div>
                  </button>
                  <div className={styles.rectangleParent3}>
                  <input
                      placeholder=" Type your email address"
                      style={{
                        background: "white",
                        width: "100%",
                        height: "48px",
                        border:  snackbarSeverity=='error'? "2px solid red" : "2px solid #ccc",
                        borderRadius: "4px",
                        padding: "8px",
                        boxSizing: "border-box",
                      }}
                      value={email}
                      onChange={handleEmailChange}
                      />
                      </div>
                </div>
                
              </div>
              <div className={styles.byClickingSubscribeContainer}>
              {snackbarSeverity=='error'&&<p style={{color:'red',
                      fontSize:'15px',position:'relative',textAlign:'left'}}>{snackbarMessage}</p>}
                By clicking “Subscribe” you agree to Loggworks 
                <a className={styles.privacyPolicy} style={{color:'green'}} href='https://www.loggworks.co.uk/privacy'>Privacy Policy</a> and
                consent to Loggworks using your contact data for newsletter
                purposes
              </div>
            </div>
      </Collapse>
           
            
          </div>
        </div>

        <div className={styles.frameWrapper4}>
            <div className={styles.frameParent29}>
            <Collapse in={openSnackbar}>
            <Typography variant='h4' align='center'>Yayy!!</Typography>
         <p style={{textAlign:'center',fontSize:'18px'}}>You have successfully subscribed to the newsletter</p>
         </Collapse>
      <Collapse in={!openSnackbar}>
        <div className={styles.frameParent16}>
                <div className={styles.getFreeInsightsAndLatestUGroup}>
                  <div className={styles.getFreeInsights1}>
                    Get free insights and latest updates
                  </div>
                  <div className={styles.subscribeToOur1}>
                    Subscribe to our blog and get free tips,
                    inspiration and resources delivered directly to your inbox.
                  </div>
                </div>
                <div className={styles.frameWrapper5}>
                  <div className={styles.groupParent5}>
                    <div className={styles.rectangleParent12}>
                    <input
                      placeholder=" Type your email address"
                      style={{
                        background: "white",
                        width: "100%",
                        height: "48px",
                        border:  snackbarSeverity=='error'? "2px solid red" : "2px solid #ccc",
                        borderRadius: "4px",
                        padding: "8px",
                        boxSizing: "border-box",
                      }}
                      value={email}
        onChange={handleEmailChange}
                      />
                      
                    </div>
                    <button className={styles.smallButtons4}
                    onClick={handleSubmit}>
                      <div className={styles.iconsaddParent}>
                        <img
                          className={styles.iconsadd}
                          alt=""
                          src="/iconsadd17.svg"
                        />
                        <div className={styles.button}>{!loading?'Subscribe': <CircularProgress
                        style={{color:'white'}}
                        />}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div className={styles.byClickingSubscribeContainer1}>
              {snackbarSeverity=='error'&&<p style={{color:'red',
                      fontSize:'15px',position:'relative'}}>{snackbarMessage}</p>}
                By clicking “Subscribe” you agree to Loggworks 
                <a className={styles.privacyPolicy} style={{color:'green'}} href='https://www.loggworks.co.uk/privacy'>Privacy Policy</a> and
                consent to Loggworks using your contact data for newsletter
                purposes
              </div>
      </Collapse>
              
            </div>
          </div>
    </div>
  )
}

export default SubsBanner