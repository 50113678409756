import React, {useCallback} from 'react'
import styles from '../Homeblog.module.css'
import { useNavigate } from 'react-router-dom';

function ForConsumerTab() {
    const navigate = useNavigate();
    const onForConsumersTextClick = useCallback(() => {
      navigate("/blog/for-consumers");
    }, [navigate]);
  
    const onForProfessionalsTextClick = useCallback(() => {
      navigate("/blog/for-professionals");
    }, [navigate]);
  
    const onNewsUpdatesClick = useCallback(() => {
      navigate("/blog/news");
    }, [navigate]);
  return (
    <div>
         <div className={styles.component7Parent}>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/for-consumers/hiring-guide/')}>
                <div className={styles.hiringGuides}>Hiring Guides</div>
              </div>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/for-consumers/pricing-guides')}>
                <div className={styles.hiringGuides}>Pricing Guides</div>
              </div>
              <div className={styles.component7}
               style={{cursor:'pointer'}}
               onClick={()=>navigate('/blog/for-consumers/tips')}>
                <div className={styles.hiringGuides}>Tips</div>
              </div>
              <div className={styles.component7}
               style={{cursor:'pointer'}}
               onClick={()=>navigate('/blog/for-consumers/how-to')}>
                <div className={styles.hiringGuides}>How To</div>
              </div>
            </div>
         
            <div className={styles.frameParent51}>
              <div className={styles.frameParent52}>
                <div className={styles.hiringGuidesWrapper}
                onClick={()=>navigate('/blog/for-consumers/hiring-guide/')}>
                  <div className={styles.pricingGuides}>Hiring Guides</div>
                </div>
                <div className={styles.hiringGuidesWrapper}
                onClick={()=>navigate('/blog/for-consumers/pricing-guides/')}>
                  <div className={styles.pricingGuides}>Pricing Guides</div>
                </div>
                <div className={styles.hiringGuidesWrapper}
                onClick={()=>navigate('/blog/for-consumers/tips/')}>
                  <div className={styles.pricingGuides}>Tips</div>
                </div>
              </div>
              <div className={styles.frameParent52}>
                <div className={styles.hiringGuidesWrapper}
                onClick={()=>navigate('/blog/for-consumers/how-to/')}>
                  <div className={styles.pricingGuides}>How To</div>
                </div>
                <div className={styles.pricingGuidesContainer}
                onClick={()=>navigate('/blog/for-consumers/pricing-guides/')}>
                  <div className={styles.pricingGuides}>Pricing Guides</div>
                </div>
                <div className={styles.pricingGuidesContainer}
                onClick={()=>navigate('/blog/for-consumers/tips/')}>
                  <div className={styles.pricingGuides}>Tips</div>
                </div>
              </div>
            </div>
    </div>
  )
}

export default ForConsumerTab