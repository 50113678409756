import React, { useState, useCallback, useEffect } from "react";
import { TextField, InputAdornment, Icon, Accordion, AccordionSummary, AccordionDetails, Autocomplete } from "@mui/material";
import MobileMenuConsumer1 from "../components/MobileMenuConsumer1";
import PortalPopup from "../components/PortalPopup";
import Dropdown4 from "../components/Dropdown4";
import Dropdown5 from "../components/Dropdown5";
import { Link, useNavigate } from "react-router-dom";
import styles from "./HomePage.module.css";
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationSearch from "../components/locationSearch.tsx";
import LocationSearch2 from "../components/locationSearch2.tsx";
import axios from "axios";
import Testimony from "../components/Testimony";
import blogstyle from "./blogs/Homeblog.module.css";
import { Helmet } from "react-helmet-async";


const HomePage = () => {
  const [isMobileMenuConsumerPopupOpen, setMobileMenuConsumerPopupOpen] =
    useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(null);
  const open = Boolean(isDropdownOpen);
  const openDropdown = (event) => {
    setDropdownOpen(event.currentTarget);
  };
  const closeDropdown = () => {
    setDropdownOpen(null);
  };
  const [isDropdown1Open, setDropdown1Open] = useState(null);
  const open1 = Boolean(isDropdown1Open);
   const openDropdown1 = (event) => {
    setDropdown1Open(event.currentTarget);
  };
  const closeDropdown1 = () => {
    setDropdown1Open(null);
  };
  const navigate = useNavigate();
  const [viewCats, setViewCats]=useState(0)
  const [viewLoc, setLoc]=useState(0)
  const [address, setAddress] = useState('');
  const [error, setError] = useState(null);
  const [trend, setTrend]=useState([])

  useEffect(() => {
   getCurrentLocation();
  }, []);
const getCurrentLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;

        // Replace 'YOUR_GOOGLE_API_KEY' with your actual Google API key
        const apiKey = 'AIzaSyDrIc0MDLHaoFOaSLFKPRcGG-bM3Xpt-w0';
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

        try {
          const response = await fetch(apiUrl);
          const data = await response.json();

          if (data.status === 'OK' && data.results && data.results.length > 0) {
            const formattedAddress = data.results[0].formatted_address;
            setAddress(formattedAddress);
          } else {
            setError('Address not found');
          }
        } catch (error) {
          setError('Error fetching address');
        }
      },
      (error) => {
        alert(error.message+" Please allow us to get your location as it is crucial for loggworks to work.")
        setError(`Geolocation error: ${error.message}`);
      }
    );
  } else {
    alert("Your browser is blocking us from getting your location")
    setError('Geolocation is not supported by your browser');
  }
};
const formatDateToWords = (dateString) => {
  const date = new Date(dateString);
  
  // Array of month names
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  
  // Get the components of the date
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  
  // Construct the formatted date string
  const formattedDate = `${month} ${day}, ${year}`;
  
  return formattedDate;
};
useEffect(() => {
  Promise.all([
    axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/blogs?populate=*'),
    axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/for-professionals?populate=*'),
    axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/news?populate=*')
  ])
    .then(([blogsResponse, professionalsResponse, newsResponse]) => {
      const blogsData = blogsResponse.data.data.filter(blog => blog.attributes.category === 'trending').map(blog => ({ ...blog, type: 'blogs' }));;
      const professionalsData = professionalsResponse.data.data.filter(blog => blog.attributes.category === 'trending').map(blog => ({ ...blog, type: 'professionals' }));;
      const newsData = newsResponse.data.data.filter(blog => blog.attributes.category === 'trending').map(blog => ({ ...blog, type: 'news' }));;
      
      const combined = [...blogsData, ...professionalsData, ...newsData];
      setTrend(combined.slice(0, 3));
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
}, []);


  const openMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(true);
  }, []);

  const closeMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(false);
  }, []);


  const onInputContainerClick = useCallback(() => {
    // Please sync "Home Mobile" to the project
  }, []);

  const onLargeButtonsContainer1Click = useCallback(() => {
   
  }, []);

  const onLargeButtonsContainer6Click = useCallback(() => {
    // Please sync "Home Mobile" to the project
  }, []);

  const onPostAJob3Click = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onHowItWorks3Click = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onContactUsText3Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQs3Click = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementText3Click = useCallback(() => {
    navigate("/agreement");
  }, [navigate]);

  const onHowItWorks4Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onContactUsText4Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQs4Click = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementText4Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/becometeam");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/partners");
  }, [navigate]);

  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/affiliate-program");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onLargeButtons1Click = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onBlogBottomPosts3Click = useCallback(() => {
    navigate("/professionalhiring-guidearticledone");
  }, [navigate]);

  const onLargeButtons2Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onLargeButtons3Click = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onServicesWebContainer15Click = useCallback(() => {
    navigate("/search-results");
  }, [navigate]);

  const onFrameContainer142Click = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onCategoriesContainer35Click = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onLargeButtons4Click = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onSearchBarClick = useCallback(() => {
    navigate("/search-results");
  }, [navigate]);

  const onLargeButtons5Click = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onPageSelectorContainerClick = useCallback(() => {
    navigate("/professional");
  }, [navigate]);

  const onHowItWorks5Click = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onFrameButtonClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onSmallButtonsClick = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  return (
    <>
     <Helmet>
                <meta charSet="utf-8" />
                <title>LoggWorks Innovative Woodworking Solutions | loggworks</title>
    <meta name="description" content="Ensure your woodworking projects with LoggWorks' innovative solutions. From custom designs to expert craftsmanship, we're here to bring your vision to life."/>
      </Helmet>
      <div className={styles.homepage}>
        <div className={styles.homeMobile}>
          <div className={styles.navbarMobileclosed}>
            <img className={styles.layer2Icon} alt="" src="/layer-2.svg" 
            style={{cursor:'pointer'}} onClick={()=>navigate('/')} />
            <div className={styles.iconsaccountParent}>
              <img
                className={styles.iconsaccount}
                alt=""
                src="/iconsaccount.svg"
                onClick={()=>window.open('https://www.consumer.loggworks.co.uk/login', '_blank')}
              />
              <img
                className={styles.hamburgerIcon}
                alt=""
                src="/hamburger.svg"
                onClick={openMobileMenuConsumerPopup}
              />
            </div>
          </div>
          <div className={styles.homepageContent}>
            <div className={styles.homepageContentInner}>
              <div className={styles.frameParent}>
                <div className={styles.findReliableHomeServiceProParent}>
                  <b className={styles.findReliableHome}>
                    Find reliable home service providers near you
                  </b>
                  <div className={styles.loggworksConnectHomeowners}>
                  <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                      100% cost-free job postings
                    </div>
                  </div>
                  <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    Get Multiple Quotes, Free of Charge
                    </div>
                  </div>
                  <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    Payment and Privacy Protection
                    </div>
                  </div>
                  <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    Vetted and reliable Professionals
                    </div>
                  </div>
                  </div>
                </div>
                <div className={styles.btnCta}>
                <div className={styles.input} onClick={onInputContainerClick}>
                    <div className={styles.labelContainer}>
                      <div className={styles.label}>Label</div>
                    </div>
                    <div className={styles.baseField}>
                         <LocationSearch2/>
                    </div>
                  </div>
                  <div className={styles.largeButtons} onClick={()=>navigate('/postjob')}>
                    <img
                      className={styles.iconsadd}
                      alt=""
                      src="/iconsadd.svg"
                    />
                    <b className={styles.category}>Post a Job</b>
                    <img
                      className={styles.iconsadd1}
                      alt=""
                      src="/iconsadd1.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.homepageContentChild}>
              <div className={styles.featuredOnParent}>
                <div className={styles.featuredOn}>PARTNERS:</div>
                <div className={styles.theTelegraphLogotypeLogo1Parent}>
                  <img
                    className={styles.mangologo}
                    alt=""
                    src="/mangopay_powered-4311aa3e.svg"
                  />
                   <img
                    className={styles.mangologo}
                    alt=""
                    src="/Google-Cloud-Logo.png"
                  />
                </div>
              </div>
            </div>
            <div className={styles.mergeWrapper}>
              <div className={styles.merge}>
                <h1>
                <b className={styles.securedPaymentAnd}>
                  Secured payment and privacy on Loggworks
                </b>
                </h1>
                <div className={styles.reasons}>
                  <div className={styles.whyUs}>
                    <div className={styles.privacyParent}>
                      <img
                        className={styles.privacyIcon}
                        alt=""
                        src="/privacy10.svg"
                      />
                      <div className={styles.privacyProtectionParent}>
                        <b className={styles.privacyProtection}>
                          Privacy Protection.
                        </b>
                        <div className={styles.youllNeverHave}>
                          You’ll never have to share your personal information
                          with tradesmen or home service professionals on
                          Loggworks. Communicate with service providers securely
                          through our direct messaging feature until you’re
                          ready to make a booking.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.whyUs}>
                    <div className={styles.privacyParent}>
                      <img
                        className={styles.privacyIcon}
                        alt=""
                        src="/privacy11.svg"
                      />
                      <div className={styles.privacyProtectionParent}>
                        <b className={styles.privacyProtection}>
                          Payment Protection.
                        </b>
                        <div className={styles.youllNeverHave}>
                          All your payments are protected by a highly secure
                          Escrow account. Pay in advance or on completion with
                          just a few clicks of a button, whenever you want.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.whyUs}>
                    <div className={styles.privacyParent}>
                      <img
                        className={styles.privacyIcon}
                        alt=""
                        src="/privacy12.svg"
                      />
                      <div className={styles.privacyProtectionParent}>
                        <b className={styles.privacyProtection}>
                          Trusted Professionals.
                        </b>
                        <div className={styles.youllNeverHave}>
                          <p className={styles.weUseThe}>
                            We use the Self-Employed Quality Control Scheme
                            (SEQCS) to comprehensively screen all home service professionals on Loggworks — for your
                            peace of mind.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.explorePopularServicesParent}>
              <b className={styles.explorePopularServices}>
                Explore popular services
              </b>
              <div className={styles.scrollFrame}>
                <div className={styles.component6Parent}>
                  <div className={styles.component6}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/tradesmen/electrician'
                   }}>
                    <img
                      className={styles.imageIcon}
                      alt=""
                      src="/electr.webp"
                    />
                    <div className={styles.serviceWrapper}>
                      <div className={styles.service}>Electrician</div>
                    </div>
                  </div>
                  <div className={styles.popularServices}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/tradesmen/plumber'
                   }}>
                    <img
                      className={styles.imageIcon}
                      alt=""
                      src="/plumber.webp"
                    />
                    <div className={styles.serviceWrapper}>
                      <div className={styles.service}>Plumber</div>
                    </div>
                  </div>
                  <div className={styles.popularServices}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/tradesmen/plasterers'
                   }}>
                    <img
                      className={styles.imageIcon2}
                      alt=""
                      src="/plaster.webp"
                    />
                    <div className={styles.serviceWrapper}>
                      <div className={styles.service}>Plasters</div>
                    </div>
                  </div>
                  <div className={styles.popularServices}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/cleaning/all'
                   }}>
                    <img
                      className={styles.imageIcon}
                      alt=""
                      src="/domestic.webp"
                    />
                    <div className={styles.serviceWrapper}>
                      <div className={styles.service}>Cleaners</div>
                    </div>
                  </div>
                  <div className={styles.popularServices}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/tradesmen/handan'
                   }}>
                    <img
                      className={styles.imageIcon2}
                      alt=""
                      src="/handy.webp"
                    />
                    <div className={styles.serviceWrapper}>
                      <div className={styles.service}>Handyman</div>
                    </div>
                  </div>
                  <div className={styles.component6}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/tradesmen/tiler'
                   }}>
                    <img
                      className={styles.imageIcon2}
                      alt=""
                      src="/tiler.webp"
                    />
                    <div className={styles.serviceWrapper}>
                      <div className={styles.service}>Tiler</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.becomeAProfessionalSection}>
              <div className={styles.merge1}>
                <div className={styles.picture}>
                  <img
                    className={styles.pictureChild}
                    alt=""
                    src="/rectangle-122972@2x.png"
                  />
                  <div className={styles.popRequest}>
                    <img
                      className={styles.popRequestChild}
                      alt=""
                      src="/frame-225.svg"
                    />
                    <img
                      className={styles.popRequestItem}
                      alt=""
                      src="/polygon-2.svg"
                    />
                    <div className={styles.matthewPhillips}>
                      Matthew Phillips
                    </div>
                    <div className={styles.requestForCarpenter}>
                      Request For Carpenter
                    </div>
                    <div className={styles.ellipseParent}>
                      <div className={styles.frameChild} />
                      <div className={styles.newServiceRequest}>
                        1 New Service Request
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.text}>
                  <div className={styles.privacyParent}>
                    <div className={styles.doYouHaveAServiceToOfferParent}>
                      <b className={styles.doYouHave}>
                        Do you have a service to offer?
                      </b>
                      <div className={styles.youllNeverHave}>
                        All your payments are protected by a highly secure
                        Escrow account. Pay in advance or on completion. with
                        just a few clicks of a button, whenever.
                      </div>
                    </div>
                    <div
                      className={styles.largeButtons1}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
                    >
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder.svg"
                      />
                      <b className={styles.category}>Become a professional</b>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<div className={styles.servicesByCategories}>
              <div className={styles.servicesByCategoriesInner} style={viewCats==0?{height:'353px'}:{}}>
                <div className={styles.exploreServicesByCategoriesParent}>
                  <b className={styles.privacyProtection}>
                    Explore services by categories
                  </b>
                  <div className={styles.vStack}>
                    <div className={styles.div1}>
                      <div className={styles.categories}
                      onClick={()=>{
                        if (error) {
                          getCurrentLocation()
                          return
                        }
                       window.location.href= '/category/catering'
                      }}
                      >
                        <div className={styles.categories1}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories16.svg"
                            />
                          </div>
                          <div className={styles.computerPhone}>Catering</div>
                        </div>
                      </div>
                      <div className={styles.categories}
                       onClick={()=>{
                        if (error) {
                          getCurrentLocation()
                          return
                        }
                       window.location.href= '/category/childcare'
                      }}>
                        <div className={styles.categories1}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories17.svg"
                            />
                          </div>
                          <div className={styles.computerPhone}>Childcare</div>
                        </div>
                      </div>
                      <div className={styles.categories}
                      onClick={()=>{
                        if (error) {
                          getCurrentLocation()
                          return
                        }
                       window.location.href= '/category/cleaning'
                      }}>
                        <div className={styles.categories1}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories.svg"
                            />
                          </div>
                          <div className={styles.computerPhone}>Cleaning</div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.categoriesParent}>
                      <div className={styles.categories}
                      onClick={()=>{
                        if (error) {
                          getCurrentLocation()
                          return
                        }
                       window.location.href= '/category/entertainment'
                      }}>
                        <div className={styles.categories1}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories19.svg"
                            />
                          </div>
                          <div className={styles.computerPhone}>
                            Entertainment
                          </div>
                        </div>
                      </div>
                      <div className={styles.categories}
                      onClick={()=>{
                        if (error) {
                          getCurrentLocation()
                          return
                        }
                       window.location.href= '/category/appliance%20repair'
                      }}>
                        <div className={styles.categories1}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories20.svg"
                            />
                          </div>
                          <div className={styles.computerPhone}>
                            Appliance Repair
                          </div>
                        </div>
                      </div>
                      <div className={styles.categories}
                      onClick={()=>{
                        if (error) {
                          getCurrentLocation()
                          return
                        }
                       window.location.href= '/category/mobile%20mechanic'
                      }}>
                        <div className={styles.categories1}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories21.svg"
                            />
                          </div>
                          <div className={styles.computerPhone}>
                            Mobile Mechanic
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.categoriesParent}>
                      <div className={styles.categories}
                      onClick={()=>{
                        if (error) {
                          getCurrentLocation()
                          return
                        }
                       window.location.href= '/category/personal%20assistance'
                      }}>
                        <div className={styles.categories1}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories22.svg"
                            />
                          </div>
                          <div className={styles.computerPhone}>
                            Personal Assistance
                          </div>
                        </div>
                      </div>
                      <div className={styles.categories}
                      onClick={()=>{
                        if (error) {
                          getCurrentLocation()
                          return
                        }
                       window.location.href= '/category/pet%20groomers'
                      }}>
                        <div className={styles.categories1}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories23.svg"
                            />
                          </div>
                          <div className={styles.computerPhone}>
                            Pet Groomers
                          </div>
                        </div>
                      </div>
                      <div className={styles.categories}
                      onClick={()=>{
                        if (error) {
                          getCurrentLocation()
                          return
                        }
                       window.location.href= '/category/recovery%20services'
                      }}>
                        <div className={styles.categories1}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories24.svg"
                            />
                          </div>
                          <div className={styles.computerPhone}>
                            Recovery Services
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.categoriesParent}>
                      <div className={styles.categories}
                      onClick={()=>{
                        if (error) {
                          getCurrentLocation()
                          return
                        }
                       window.location.href= '/category/computer%20&%20phone%20repair/'
                      }}>
                        <div className={styles.categories1}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories25.svg"
                            />
                          </div>
                          <div
                            className={styles.computerPhone}
                          >{`Computer & Phone repair`}</div>
                        </div>
                      </div>
                      <div className={styles.categories}
                      onClick={()=>{
                        if (error) {
                          getCurrentLocation()
                          return
                        }
                       window.location.href= '/category/removal%20services/'
                      }}>
                        <div className={styles.categories1}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories26.svg"
                            />
                          </div>
                          <div className={styles.computerPhone}>
                            Removal Services
                          </div>
                        </div>
                      </div>
                      <div className={styles.categories}
                      onClick={()=>{
                        if (error) {
                          getCurrentLocation()
                          return
                        }
                       window.location.href= '/category/tradesmen'
                      }}>
                        <div className={styles.categories1}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories27.svg"
                            />
                          </div>
                          <div className={styles.computerPhone}>Tradesmen</div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.categoriesParent}>
                      <div className={styles.categories}
                      onClick={()=>{
                        if (error) {
                          getCurrentLocation()
                          return
                        }
                       window.location.href= '/category/transport%20services'
                      }}>
                        <div className={styles.categories1}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories28.svg"
                            />
                          </div>
                          <div className={styles.computerPhone}>
                            Transport Services
                          </div>
                        </div>
                      </div>
                      <div className={styles.categories}
                      onClick={()=>{
                        if (error) {
                          getCurrentLocation()
                          return
                        }
                       window.location.href= '/category/dry%20cleaning%20&%20laundry'
                      }}>
                        <div className={styles.categories1}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories1.svg"
                            />
                          </div>
                          <div
                            className={styles.computerPhone}
                          >{`Dry Cleaning & Laundry`}</div>
                        </div>
                      </div>
                      <div className={styles.categories}
                      onClick={()=>{
                        if (error) {
                          getCurrentLocation()
                          return
                        }
                       window.location.href= '/category/health,%20wellness%20&%20beauty'
                      }}>
                        <div className={styles.categories1}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories30.svg"
                            />
                          </div>
                          <div
                            className={styles.computerPhone}
                          >{`Health, Wellness & Beauty`}</div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.categories30}>
                      <div className={styles.categories1}
                      onClick={()=>{
                        if (error) {
                          getCurrentLocation()
                          return
                        }
                       window.location.href= '/category/tuition%20&%20classes'
                      }}>
                        <div className={styles.iconscategoriesWrapper}>
                          <img
                            className={styles.iconscategories}
                            alt=""
                            src="/iconscategories2.svg"
                          />
                        </div>
                        <div
                          className={styles.computerPhone}
                        >{`Tuition & Classes`}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.largeButtons2} onClick={()=>{viewCats==0?setViewCats(1):setViewCats(0)}}>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder1.svg"
                />
                <b className={styles.category}>{viewCats==0?'See more':'See less'}</b>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder1.svg"
                />
              </div>
            </div>*/}
            <div className={styles.titleParent}>
              <div className={styles.title}>
                <div className={styles.title1}>Tradesmen</div>
                <div className={styles.iconsaddParent} style={{cursor:'pointer'}}
                    onClick={()=>{
                      navigate('/category/tradesmen')
                    }}>
                     <p>+ View all</p>
                </div>
              </div>
              <div className={styles.scrollFrame1}>
                <div className={styles.hStack}>
                  <div className={styles.servicesWeb}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/tradesmen/construction'
                   }}>
                    <img
                      className={styles.imgIcon}
                      alt=""
                      src="/const.webp"
                    />
                    <div className={styles.profiledescription}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Construction</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/tradesmen/gardening%20&%20landscaping'
                   }}>
                    <img
                      className={styles.imgIcon}
                      alt=""
                      src="/img13@2x.png"
                    />
                    <div className={styles.profiledescription}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div
                            className={styles.viewAll}
                          >{`Gardening & landscaping`}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/tradesmen/painters'
                   }}>
                    <img
                      className={styles.imgIcon}
                      alt=""
                      src="/img14@2x.png"
                    />
                    <div className={styles.profiledescription}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Painters</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/tradesmen/electrician'
                   }}>
                    <img
                      className={styles.imgIcon}
                      alt=""
                      src="/electr.webp"
                    />
                    <div className={styles.profiledescription}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Electricians</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.titleGroup}>
                <div className={styles.title1}>Cleaning</div>
                <div className={styles.iconsaddParent} style={{cursor:'pointer'}}
                    onClick={()=>{
                      navigate('/category/cleaning')
                    }}>
                     <p>+ View all</p>
                </div>
              </div>
              <div className={styles.scrollFrame2}>
                <div className={styles.hStack}>
                  <div className={styles.servicesWeb}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/cleaning/domestic cleaners'
                   }}>
                    <img
                      className={styles.imgIcon}
                      alt=""
                      src="/domestic.webp"
                    />
                    <div className={styles.profiledescription}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Domestic Cleaners</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/cleaning/window cleaners'
                   }}>
                    <img
                      className={styles.imgIcon}
                      alt=""
                      src="/window.webp"
                    />
                    <div className={styles.profiledescription}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Window Cleaners</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/cleaning/property maintenance'
                   }}>
                    <img
                      className={styles.imgIcon}
                      alt=""
                      src="/property.webp"
                    />
                    <div className={styles.profiledescription}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Property Maintenance</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/cleaning/drain and pipe cleaning'
                   }}>
                    <img
                      className={styles.imgIcon}
                      alt=""
                      src="/drain.webp"
                    />
                    <div className={styles.profiledescription}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Drain and Pipe Cleaning</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className={styles.titleGroup}>
                <div className={styles.title1}>Childcare</div>
                <div className={styles.iconsaddParent}>
                 
                </div>
              </div>
              <div className={styles.scrollFrame2}>
                <div className={styles.hStack}>
                  <div className={styles.servicesWeb}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/childcare/babysitters'
                   }}>
                    <img
                      className={styles.imgIcon}
                      alt=""
                      src="/img16@2x.png"
                    />
                    <div className={styles.profiledescription}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Babysitters</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/childcare/childminders'
                   }}>
                    <img
                      className={styles.imgIcon}
                      alt=""
                      src="/img17@2x.png"
                    />
                    <div className={styles.profiledescription}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Childminders</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/childcare/nannies'
                   }}>
                    <img
                      className={styles.imgIcon}
                      alt=""
                      src="/img18@2x.png"
                    />
                    <div className={styles.profiledescription}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Nannies</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/childcare/nursery'
                   }}>
                    <img
                      className={styles.imgIcon}
                      alt=""
                      src="/img19@2x.png"
                    />
                    <div className={styles.profiledescription}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Nursery</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.titleContainer}>
                <div className={styles.title1}>Entertainment</div>
                <div className={styles.iconsaddParent}>
                
                </div>
              </div>
              <div className={styles.scrollFrame3}>
                <div className={styles.hStack}>
                  <div className={styles.servicesWeb}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/entertainment/event%20planners'
                   }}>
                    <img
                      className={styles.imgIcon}
                      alt=""
                      src="/img20@2x.png"
                    />
                    <div className={styles.profiledescription}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Event planners</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      if (error) {
                        getCurrentLocation()
                        return
                      }
                     window.location.href= '/search/'+address+'/entertainment/event%20coverage'
                    }}>
                    <img
                      className={styles.imgIcon}
                      alt=""
                      src="/img21@2x.png"
                    />
                    <div className={styles.profiledescription}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Event coverage</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      if (error) {
                        getCurrentLocation()
                        return
                      }
                     window.location.href= '/search/'+address+'/entertainment/photography%20and%20film'
                    }}>
                    <img
                      className={styles.imgIcon}
                      alt=""
                      src="/img22@2x.png"
                    />
                    <div className={styles.profiledescription}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>
                            Photography and film
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      if (error) {
                        getCurrentLocation()
                        return
                      }
                     window.location.href= '/search/'+address+'/entertainment/disk%20jockeys'
                    }}>
                    <img
                      className={styles.imgIcon}
                      alt=""
                      src="/img23@2x.png"
                    />
                    <div className={styles.profiledescription}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Disk Jockeys</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>*/}
            </div>
            <div className={styles.mergeWrapper}>
              <div className={styles.merge1}>
                <div className={styles.text2}>
                  <div className={styles.privacyParent}>
                    <div className={styles.doYouHaveAServiceToOfferParent}>
                      <b className={styles.securedPaymentAnd}>
                        Can’t find the professional you are looking for?
                      </b>
                      <div className={styles.allYourPayments1}>
                        All your payments are protected by a highly secure
                        Escrow account. Pay in advance or on completion with
                        just a few clicks of a button, whenever.
                      </div>
                    </div>
                    <div className={styles.largeButtons} onClick={()=>navigate('/postjob')}>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder2.svg"
                      />
                      <b className={styles.category}>Post a Job</b>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder2.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.picture1}>
                  <img
                    className={styles.pictureItem}
                    alt=""
                    src="/rectangle-122973@2x.png"
                  />
                  <div className={styles.popRequest1}>
                    <img
                      className={styles.popRequestInner}
                      alt=""
                      src="/frame-2251.svg"
                    />
                    <img
                      className={styles.polygonIcon}
                      alt=""
                      src="/polygon-21.svg"
                    />
                    <div className={styles.matthewPhillips1}>
                      Matthew Phillips
                    </div>
                    <div className={styles.requestForCarpenter1}>
                      Request For Carpenter
                    </div>
                    <div className={styles.ellipseGroup}>
                      <div className={styles.frameItem} />
                      <div className={styles.newServiceRequest}>
                        1 New Service Request
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.testimonials}>
              <div className={styles.merge}>
                <b className={styles.wereTrustedBy}>
                  We’re trusted by many across the UK
                </b>
                <div className={styles.frameGroup}>
                  <div className={styles.merge}>
                    <Testimony/>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.viewHomeServiceProfessionalParent}>
              <div className={styles.viewHomeServiceProfessional} style={viewLoc==0?{height:'353px'}:{}}>
                <div className={styles.viewHomeServiceProfessional1}>
                  <div className={styles.viewHomeService}>
                    View Home service professionals in your area.
                  </div>
                  <div className={styles.states}>
                    <div className={styles.statesParent}>
                      <div className={styles.states1}>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/london/tradesmen/all';
                        }}>London</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/bristol/tradesmen/all';
                        }}>Bristol</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/leicester/tradesmen/all';
                        }}>Leicester</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/northampton/tradesmen/all';
                        }}>Northampton</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/york/tradesmen/all';
                        }}>York</div>
                      </div>
                      <div className={styles.states1}>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/manchester/tradesmen/all';
                        }}>Manchester</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/sheffield/tradesmen/all';
                        }}>Sheffield</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/aberdeen/tradesmen/all';
                        }}>Aberdeen</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/peterborough/tradesmen/all';
                        }}>Peterborough</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/bolton,%20lancashire/tradesmen/all';
                        }}>
                          Bolton, Lancashire
                        </div>
                      </div>
                    </div>
                    <div className={styles.statesParent}>
                      <div className={styles.states1}>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/birmingham/tradesmen/all';
                        }}>Birmingham</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/belfast/tradesmen/all';
                        }}>Belfast</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/coventry/tradesmen/all';
                        }}>Coventry</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/reading/tradesmen/all';
                        }}>Reading</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/derby/tradesmen/all';
                        }}>Derby</div>
                      </div>
                      <div className={styles.states1}>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/glasgow/tradesmen/all';
                        }}>Glasgow</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/edinburgh/tradesmen/all';
                        }}>Edinburgh</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/southampton/tradesmen/all';
                        }}>Southampton</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/doncaster/tradesmen/all';
                        }}>Doncaster</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/oxford/tradesmen/all';
                        }}>Oxford</div>
                      </div>
                    </div>
                    <div className={styles.statesParent}>
                      <div className={styles.states1}>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/newcastle/tradesmen/all';
                        }}>Newcastle</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/norwich/tradesmen/all';
                        }}>Nottingham</div>
                        <div className={styles.featuredOn}>Norwich</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/hull/tradesmen/all';
                        }}>Hull</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/exeter/tradesmen/all';
                        }}>Exeter</div>
                      </div>
                      <div className={styles.states1}>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/liverpool/tradesmen/all';
                        }}>Liverpool</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/cardiff/tradesmen/all';
                        }}>Cardiff</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/swansea/tradesmen/all';
                        }}>Swansea</div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/bradford, west yorkshire/tradesmen/all';
                        }}>
                          Bradford, West Yorkshire
                        </div>
                        <div className={styles.featuredOn}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          window.location.href = '/search/leeds, west yorkshire/tradesmen/all';
                        }}>
                          Leeds, West Yorkshire
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.largeButtons4} onClick={()=>viewLoc==0?setLoc(1):setLoc(0)}>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder3.svg"
                />
                <b className={styles.category}>{viewLoc==0?'View more':'View less'}</b>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder3.svg"
                />
              </div>
            </div>
            <div className={styles.downloadApp}>
              <div className={styles.merge6}>
                <div className={styles.image}>
                  <img
                    className={styles.home1Icon}
                    alt=""
                    src="/home-12@2x.png"
                  />
                </div>
                <div className={styles.text4}>
                  <div className={styles.privacyParent}>
                    <div className={styles.accessLoggworksOnYourMobilParent}>
                      <b className={styles.securedPaymentAnd}>
                        Access Loggworks on your mobile phone
                      </b>
                      <div
                        className={styles.enjoyTheFull}
                      >{`Enjoy the full experience of Loggworks on our mobile app. What’s more? You get to enjoy all of our services convieniently. `}</div>
                    </div>
                    <div className={styles.largeButtons5}>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder4.svg"
                      />
                      <b className={styles.category}>Coming soon</b>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder4.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.scrollFrameParent}>
              <div className={styles.scrollFrame4}>
                <div className={styles.blogBottomPostsParent}>  
                  {trend?.map(blog => (
                  <Link key={blog.id} to={
                    blog.type=='blogs'?'/blog/for-consumers/'+blog.attributes.slug:
                    blog.type=='professionals'?'/blog/for-professionals/'+blog.attributes.slug:'/blog/news/'+blog.attributes.slug}>
                   <div className={blogstyle.rectangleParent13}>
                  <div className={blogstyle.groupChild24} />
                  <img
                    className={blogstyle.groupChild25}
                    alt=""
                    src={blog.attributes.BlogImage.data.attributes.url}
                  />
                  <div className={blogstyle.frameParent44}>
                    <div className={blogstyle.frameParent45}>
                      <div className={blogstyle.businessAdviceContainer}>
                        <div className={blogstyle.businessAdvice1}>
                        {blog.attributes.category}
                        </div>
                      </div>
                      <div className={blogstyle.minsRead15}>{blog.attributes.duration} mins read</div>
                    </div>
                    <div className={blogstyle.howToPricingYourServicesAGroup}>
                      <b className={blogstyle.howToPricing1}>
                      {blog.attributes.BlogTitle}
                      </b>
                      <div
                        className={blogstyle.loggworksHelpsYou8}
                      >
                        {blog.attributes.description}
                      </div>
                    </div>
                    <div className={blogstyle.christinaWilliamsParent13}>
                      <div className={blogstyle.christinaWilliams15}>
                        {blog.attributes.Author}
                      </div>
                      <div className={blogstyle.frameChild21} />
                      <div className={blogstyle.january18th202415}>
                      {formatDateToWords(blog.attributes.createdAt)}
                      </div>
                    </div>
                  </div>
                </div>
                    </Link>
             ))}
                </div>
              </div>
              <div
                className={styles.largeButtons6}
                onClick={onLargeButtons2Click}>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder5.svg"
                />
                <b className={styles.category}>Checkout Our Blog</b>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder5.svg"
                />
              </div>
            </div>
            <div className={styles.mobileFooterHomeowner}>
              <div className={styles.frameParent2}>
              <div className={styles.frameParent8}>
                  <div className={styles.doYouHaveAServiceToOfferParent}>
                    <div className={styles.largeButtons8}  
                     style={{cursor:'pointer'}}
                     onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder6.svg"
                      />
                      <b className={styles.category}>Become a Professional</b>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder6.svg"
                      />
                    </div>
                    <div className={styles.largeButtons8} onClick={()=>navigate('/postjob')}>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder7.svg"
                      />
                      <b className={styles.category}>Post a Job</b>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder7.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.termsOfUseParent}
                   style={{cursor:'pointer'}}
                   onClick={()=>{navigate('/')}}>
                    <img
                      className={styles.layer2Child}
                      alt=""
                      src="/group-66987.svg"
                    />
                    <img
                      className={styles.layer2Item}
                      alt=""
                      src="/group-66988.svg"
                    />
                  </div>
                </div>
                <div className={styles.mobileFooterParent}>
                  <div className={styles.mobileFooter}>
                    <div className={styles.title4}>
                         <Accordion style={{width:'100%',background:'transparent'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color:'white'}}/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b style={{color:'white'}}>Homeowners</b>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.subContent}>
                      <div className={styles.featuredOn} onClick={()=>navigate('/postjob')}>Post a Job</div>
                      <div className={styles.featuredOn} onClick={onHowItWorks5Click}>How it works</div>
                      <div className={styles.featuredOn} onClick={onContactUsText4Click}>Contact Us</div>
                      <div className={styles.featuredOn} onClick={onHelpFAQs4Click}>{`Help & FAQ’s`}</div>
                      <div className={styles.featuredOn}  onClick={onUserAgreementText3Click}>User Agreement</div>
                    </div>
        </AccordionDetails>
      </Accordion>
                      
                    </div>
                   
                  </div>
                  <div className={styles.mobileFooter}>
                    <div className={styles.title4}>
                    <Accordion style={{width:'100%',background:'transparent'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color:'white'}}/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b style={{color:'white'}}>Professionals</b>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.subContent}>
                      <div className={styles.featuredOn}
                      style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                      <div className={styles.featuredOn} onClick={onHowItWorks4Click}>Blog</div>
                      <div className={styles.featuredOn} onClick={onContactUsText4Click}>Contact Us</div>
                      <div className={styles.featuredOn} onClick={onHelpFAQs4Click}>{`Help & FAQ’s`}</div>
                      <div className={styles.featuredOn}  onClick={onUserAgreementText3Click}>User Agreement</div>
                    </div>
        </AccordionDetails>
      </Accordion>
                     
                    </div>
                  </div>
                  <div className={styles.mobileFooter}>
                    <div className={styles.title4}>
                     <Accordion style={{width:'100%',background:'transparent'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color:'white'}}/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b style={{color:'white'}}>Loggworks</b>
        </AccordionSummary>
        <AccordionDetails>
         <div className={styles.subContent}>
                      <div className={styles.featuredOn} onClick={onAboutUsTextClick}>About Us</div>
                      <div className={styles.featuredOn} onClick={onCareersTextClick}>Careers</div>
                      <div className={styles.featuredOn} onClick={onPartnersTextClick}>Partners</div>
                      <div className={styles.featuredOn} onClick={onInfluencerProgramTextClick}>
                        Affiliate Program
                      </div>
                      <div className={styles.blog} onClick={onHowItWorks4Click}>Blog</div>
                    </div>
        </AccordionDetails>
      </Accordion>
                  
                    </div>
                    
                  </div>
                </div>
                <div className={styles.loggworksParent}>
                  <div className={styles.loggworks}>
                    <div className={styles.followUs}>Follow us</div>
                  </div>
                  <div className={styles.frameParent3}>
                    <div className={styles.frameParent4}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://web.facebook.com/Loggworks?_rdc=1&_rdr', '_blank')}>
                      <img
                        className={styles.frameIcon}
                        alt=""
                        src="/frame.svg"
                      />
                      <div className={styles.facebook}>Facebook</div>
                    </div>
                    <div className={styles.mdilinkedinParent}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://twitter.com/loggworks', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame1.svg"
                      />
                      <div className={styles.facebook}>Twitter</div>
                    </div>
                    <div className={styles.mdilinkedinParent}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.instagram.com/loggworks/?utm_medium=copy_link', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame2.svg"
                      />
                      <div className={styles.facebook}>Instagram</div>
                    </div>
                    <div className={styles.mdilinkedinParent}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame3.svg"
                      />
                      <div className={styles.facebook}>YouTube</div>
                    </div>
                    <div className={styles.mdilinkedinParent}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open(' https://www.linkedin.com/company/loggworks', '_blank')}>
                      <img
                        className={styles.mdilinkedinIcon}
                        alt=""
                        src="/mdilinkedin.svg"
                      />
                      <div className={styles.facebook}>LinkedIn</div>
                    </div>
                  </div>
                </div>
                
                <div className={styles.lineDiv} />
                <div className={styles.metalinks}>
                  <div className={styles.copyright2005}>
                    Copyright © 2024 loggworks Ltd. All Rights Reserved
                  </div>
                  <div className={styles.termsOfUse}>
                    <div className={styles.privacyPolicy}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/terms')}}>Terms of Use</div>
                    <div className={styles.termsOfUseChild} />
                    <div className={styles.privacyPolicy}
                     style={{cursor:'pointer'}}
                     onClick={()=>{navigate('/privacy')}}>Privacy Policy</div>
                  </div>
                  <div className={styles.termsOfUse}>
                    <div className={styles.privacyPolicy}
                     style={{cursor:'pointer'}}
                     onClick={()=>{navigate('/cookie')}}>Cookies</div>
                    <div className={styles.termsOfUseChild} />
                    <div className={styles.privacyPolicy} style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href='https://www.loggworks.co.uk/sitemap.xml'
                    }}>Sitemap</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.homedone}>
          <div className={styles.prototypeFooterParent}>
            <div className={styles.prototypeFooter}>
              <div className={styles.footer}>
                <div className={styles.download}>
                  <div className={styles.downloadOurApps}>
                    Download our apps
                  </div>
                  <img
                    className={styles.playstoreButtonIcon}
                    alt=""
                    src="/playstore-button2.svg"
                  />
                  <img
                    className={styles.appstoreButtonIcon}
                    alt=""
                    src="/appstore-button2.svg"
                  />
                </div>
                <div className={styles.footerChild} />
                <img
                style={{cursor:'pointer'}}
                onClick={()=>{navigate('/')}}
                  className={styles.layer2Icon1}
                  alt=""
                  src="/layer-22.svg"
                />
                <div className={styles.frameParent9}>
                  <div className={styles.homeownersParent}>
                    <b className={styles.privacyProtection}>Homeowners</b>
                    <div className={styles.postAJobParent}>
                      <div
                        className={styles.aboutUs}
                        onClick={onPostAJob3Click}
                      >
                        Post a Job
                      </div>
                      <div
                        className={styles.aboutUs}
                        onClick={onHowItWorks3Click}
                      >
                        How it works
                      </div>
                      <div
                        className={styles.aboutUs}
                        onClick={onContactUsText3Click}
                      >
                        Contact Us
                      </div>
                      <div
                        className={styles.aboutUs}
                        onClick={onHelpFAQs3Click}
                      >{`Help & FAQ’s`}</div>
                      <div
                        className={styles.aboutUs}
                        onClick={onUserAgreementText3Click}
                      >
                        User Agreement
                      </div>
                    </div>
                  </div>
                  <div className={styles.professionalsParent}>
                    <b className={styles.privacyProtection}>Professionals</b>
                    <div className={styles.postAJobParent}>
                      <div className={styles.featuredOn}
                      style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                      <div
                        className={styles.aboutUs}
                        onClick={onHowItWorks4Click}
                      >
                        Blog
                      </div>
                      <div
                        className={styles.aboutUs}
                        onClick={onContactUsText4Click}
                      >
                        Contact Us
                      </div>
                      <div
                        className={styles.aboutUs}
                        onClick={onHelpFAQs4Click}
                      >{`Help & FAQ’s`}</div>
                      <div
                        className={styles.aboutUs}
                        onClick={onUserAgreementText4Click}
                      >
                        User Agreement
                      </div>
                    </div>
                  </div>
                  <div className={styles.loggworksGroup}>
                    <b className={styles.privacyProtection}>Loggworks</b>
                    <div className={styles.postAJobParent}>
                      <div
                        className={styles.aboutUs}
                        onClick={onAboutUsTextClick}
                      >
                        About us
                      </div>
                      <div
                        className={styles.aboutUs}
                        onClick={onCareersTextClick}
                      >
                        Careers
                      </div>
                      <div
                        className={styles.aboutUs}
                        onClick={onPartnersTextClick}
                      >
                        Partners
                      </div>
                      <div
                        className={styles.aboutUs}
                        onClick={onInfluencerProgramTextClick}
                      >
                        Affiliate program
                      </div>
                      <div className={styles.blog}>Blog</div>
                    </div>
                  </div>
                  <div className={styles.loggworksContainer}>
                    <div className={styles.loggworks2}>
                      <b className={styles.followUs1}>Follow us</b>
                    </div>
                    <div className={styles.groupParent}>
                      <div className={styles.groupDiv} 
                       style={{cursor:'pointer'}}
                    onClick={()=>window.open('https://web.facebook.com/Loggworks?_rdc=1&_rdr', '_blank')}>
                        <img
                          className={styles.frameIcon4}
                          alt=""
                          src="/frame4.svg"
                        />
                        <div className={styles.facebook1}>Facebook</div>
                      </div>
                      <div className={styles.frameParent10}
                        style={{cursor:'pointer'}}
                        onClick={()=>window.open('https://twitter.com/loggworks', '_blank')}>
                        <img
                          className={styles.frameIcon5}
                          alt=""
                          src="/frame11.svg"
                        />
                        <div className={styles.twitter1}>Twitter</div>
                      </div>
                      <div className={styles.frameParent11}
                        style={{cursor:'pointer'}}
                        onClick={()=>window.open('https://www.instagram.com/loggworks/?utm_medium=copy_link', '_blank')}>
                        <img
                          className={styles.frameIcon5}
                          alt=""
                          src="/frame21.svg"
                        />
                        <div className={styles.twitter1}>Instagram</div>
                      </div>
                      <div className={styles.frameParent12}>
                        <img
                          className={styles.frameIcon5}
                          alt=""
                          src="/frame31.svg"
                        />
                        <div className={styles.twitter1}>YouTube</div>
                      </div>
                      <div className={styles.mdilinkedinGroup}
                        style={{cursor:'pointer'}}
                        onClick={()=>window.open('https://www.linkedin.com/company/loggworks', '_blank')}>
                        <img
                          className={styles.mdilinkedinIcon}
                          alt=""
                          src="/mdilinkedin1.svg"
                        />
                        <div className={styles.linkedin1}>LinkedIn</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.copyright2022LoggworkLtdAParent}>
                  <div className={styles.viewAll}>
                    ©Copyright 2024 loggworks  Ltd. All Rights Reserved
                  </div>
                  <div className={styles.frameParent13}>
                    <div className={styles.termsOfUseParent}>
                      <div className={styles.viewAll}
                      style={{cursor:'pointer'}}
                      onClick={()=>{navigate('/terms')}}>Terms of Use</div>
                      <div className={styles.frameChild1} />
                    </div>
                    <div className={styles.termsOfUseParent}>
                      <div className={styles.viewAll}
                       style={{cursor:'pointer'}}
                       onClick={()=>{navigate('/privacy')}}>{`Privacy Policy `}</div>
                      <div className={styles.frameChild1} />
                    </div>
                    <div className={styles.termsOfUseParent}>
                      <div className={styles.viewAll}
                       style={{cursor:'pointer'}}
                       onClick={()=>{navigate('/cookie')}}>Cookies</div>
                      <div className={styles.frameChild1} />
                    </div>
                    <div className={styles.contactUsParent}>
                      <div className={styles.viewAll}>Contact Us</div>
                      <div className={styles.frameChild1} />
                    </div>
                    <div className={styles.termsOfUseParent}>
                      <div className={styles.viewAll} style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href='https://www.loggworks.co.uk/sitemap.xml'
                    }}>Sitemap</div>
                      <div className={styles.frameChild5} />
                    </div>
                  </div>
                </div>
                <div className={styles.largeButtonsGroup}>
                  <button
                    className={styles.largeButtons9}
                    style={{cursor:'pointer'}}
                    onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
                  >
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder14.svg"
                    />
                    <b className={styles.label10}>Become a Professional</b>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder14.svg"
                    />
                  </button>
                  <button
                    className={styles.largeButtons9}
                    onClick={()=>navigate('/postjob')}
                  >
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder15.svg"
                    />
                    <b className={styles.label10}>Post a Job</b>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder15.svg"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.frameWrapper}>
              <div className={styles.exploreServicesByCategoriesGroup}>
                <div className={styles.blogBottomPostsGroup}>
                {trend?.map(blog => (
                 <Link key={blog.id} to={
                  blog.type=='blogs'?'/blog/for-consumers/'+blog.attributes.slug:
                  blog.type=='professionals'?'/blog/for-professionals/'+blog.attributes.slug:'/blog/news/'+blog.attributes.slug}>
                 <div className={blogstyle.frameWrapper}>
                <div className={blogstyle.groupContainer}>
                  <div className={blogstyle.rectangleGroup}>
                    <div className={blogstyle.groupItem} />
                    <img
                      className={blogstyle.groupInner}
                      alt=""
                      src={blog.attributes.BlogImage.data.attributes.url}
                    />
                    <div className={blogstyle.frameParent2}>
                      <div className={blogstyle.frameParent3}>
                        <div className={blogstyle.businessAdviceWrapper}>
                          <div className={blogstyle.businessAdvice}>
                          {blog.attributes.category}
                          </div>
                        </div>
                        <div className={blogstyle.minsRead1}>{blog.attributes.duration} mins read</div>
                      </div>
                      <div className={blogstyle.howToPricingYourServicesAParent}>
                        <b className={blogstyle.howToPricing}>
                        {blog.attributes.BlogTitle}
                        </b>
                        <div
                          className={blogstyle.embarkingOnA}
                        >{blog.attributes.description}</div>
                      </div>
                      <div className={blogstyle.christinaWilliamsGroup}>
                        <div className={blogstyle.christinaWilliams1}>
                          {blog.attributes.Author}
                        </div>
                        <div className={blogstyle.ellipseDiv} />
                        <div className={blogstyle.january18th20241}>
                        {formatDateToWords(blog.attributes.createdAt)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                  </Link>
             ))}
                 
                 
                </div>
                <button
                  className={styles.largeButtons11}
                  onClick={onLargeButtons2Click}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder16.svg"
                  />
                  <b className={styles.label10}>Checkout Our Blog</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder16.svg"
                  />
                </button>
              </div>
            </div>
            <div className={styles.downloadApp1}>
              <div className={styles.merge13}>
                <div className={styles.image1}>
                  <img
                    className={styles.home1Icon1}
                    alt=""
                    src="/home-1@2x.png"
                  />
                </div>
                <div className={styles.text5}>
                  <div className={styles.frameParent15}>
                    <div className={styles.accessLoggworksOnYourMobilGroup}>
                      <b className={styles.accessLoggworksOn1}>
                        Access Loggworks on your mobile phone
                      </b>
                      <div
                        className={styles.enjoyTheFull}
                      >{`Enjoy the full experience of Loggworks on our mobile app. What’s more? You get to enjoy all of our services convieniently. `}</div>
                    </div>
                    <div className={styles.largeButtons12}>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder17.svg"
                      />
                      <b className={styles.category}>Coming soon</b>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder17.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.viewHomeServiceProfessional2}>
              <div className={styles.viewHomeServiceProfessional3}>
                <div className={styles.viewHomeService1}>
                  View Home service professionals in your area.
                </div>
                <div className={styles.states7}>
                  <div className={styles.mobileFooterParent}>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/london/tradesmen/all';
                    }}>London</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/bristol/tradesmen/all';
                    }}>Bristol</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/leicester/tradesmen/all';
                    }}>Leicester</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/northampton/tradesmen/all';
                    }}>Northampton</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/york/tradesmen/all';
                    }}>York</div>
                  </div>
                  <div className={styles.mobileFooterParent}>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/manchester/tradesmen/all';
                    }}>Manchester</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/sheffield/tradesmen/all';
                    }}>Sheffield</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/aberdeen/tradesmen/all';
                    }}>Aberdeen</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/peterborough/tradesmen/all';
                    }}>Peterborough</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/bolton, lancashire/tradesmen/all';
                    }}>Bolton, Lancashire</div>
                  </div>
                  <div className={styles.mobileFooterParent}>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/birmingham/tradesmen/all';
                    }}>Birmingham</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/belfast/tradesmen/all';
                    }}>Belfast</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/coventry/tradesmen/all';
                    }}>Coventry</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/reading /tradesmen/all';
                    }}>Reading</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/derby/tradesmen/all';
                    }}>Derby</div>
                  </div>
                  <div className={styles.mobileFooterParent}>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/glasgow/tradesmen/all';
                    }}>Glasgow</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/edinburgh/tradesmen/all';
                    }}>Edinburgh</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/southampton/tradesmen/all';
                    }}>Southampton</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/doncaster/tradesmen/all';
                    }}>Doncaster</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/oxford/tradesmen/all';
                    }}>Oxford</div>
                  </div>
                  <div className={styles.mobileFooterParent}>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/newcastle/tradesmen/all';
                    }}>Newcastle</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/nottingham/tradesmen/all';
                    }}>Nottingham</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/norwich/tradesmen/all';
                    }}>Norwich</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/hull/tradesmen/all';
                    }}>Hull</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/exeter/tradesmen/all';
                    }}>Exeter</div>
                  </div>
                  <div className={styles.mobileFooterParent}>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/liverpool/tradesmen/all';
                    }}>Liverpool</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/cardiff/tradesmen/all';
                    }}>Cardiff</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/swansea/tradesmen/all';
                    }}>Swansea</div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/bradford, west yorkshire/tradesmen/all';
                    }}>
                      Bradford, West Yorkshire
                    </div>
                    <div className={styles.viewAll}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href = '/search/leeds, west yorkshire/tradesmen/all';
                    }}>Leeds, West Yorkshire</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.testimonials3}>
              <b className={styles.wereTrustedBy1}>
                We’re trusted by many across the UK
              </b>
            
              <div className={styles.testimonialsGroup}>
               <Testimony/>
              </div>
            </div>
            <div className={styles.becomeAProfessionalSection2}>
              <div className={styles.merge17}>
                <div className={styles.text6}>
                  <div className={styles.frameParent15}>
                    <div className={styles.cantFindTheProfessionalYoGroup}>
                      <b className={styles.cantFindThe1}>
                        Can’t find the professional you are looking for?
                      </b>
                      <div className={styles.allYourPayments1}>
                        All your payments are protected by a highly secure
                        Escrow account. Pay in advance or on completion with
                        just a few clicks of a button, whenever.
                      </div>
                    </div>
                    <button
                      className={styles.largeButtons11}
                      onClick={()=>navigate('/postjob')}
                    >
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder18.svg"
                      />
                      <b className={styles.label10}>Post a Job</b>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder18.svg"
                      />
                    </button>
                  </div>
                </div>
                <div className={styles.picture2}>
                  <img
                    className={styles.pictureInner}
                    alt=""
                    src="/rectangle-12297@2x.png"
                  />
                  <div className={styles.popRequest2}>
                    <img
                      className={styles.popRequestChild1}
                      alt=""
                      src="/frame-2252.svg"
                    />
                    <img
                      className={styles.popRequestChild2}
                      alt=""
                      src="/polygon-22.svg"
                    />
                    <div className={styles.matthewPhillips2}>
                      Matthew Phillips
                    </div>
                    <div className={styles.requestForCarpenter2}>
                      Request For Carpenter
                    </div>
                    <div className={styles.ellipseContainer}>
                      <div className={styles.frameChild7} />
                      <div className={styles.newServiceRequest}>
                        1 New Service Request
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameWrapper1}>
              <div className={styles.categoriesBreakdownParent}>
                <div className={styles.categoriesBreakdown}>
                  <div className={styles.header}>
                    <div className={styles.title7}>Tradesmen</div>
                    <div className={styles.iconsaddParent1} style={{cursor:'pointer'}}
                    onClick={()=>{
                      navigate('/category/tradesmen')
                    }}>
                     <p>+ View all</p>
                    </div>
                  </div>
                  <div className={styles.servicesWeb12}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      if (error) {
                        getCurrentLocation()
                        return
                      }
                     window.location.href= '/search/'+address+'/tradesmen/construction'
                    }}>
                    <img className={styles.imgIcon} alt="" src="/const.webp" />
                    <div className={styles.homepageContent}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Construction</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb13}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/tradesmen/gardening%20&%20lanscaping'
                   }}>
                    <img className={styles.imgIcon} alt="" src="/img1@2x.png" />
                    <div className={styles.homepageContent}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div
                            className={styles.viewAll}
                          >{`Gardening & landscaping`}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb14}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/tradesmen/painters'
                   }}>
                    <img className={styles.imgIcon} alt="" src="/img2@2x.png" />
                    <div className={styles.homepageContent}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Painters</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.servicesWeb15}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      if (error) {
                        getCurrentLocation()
                        return
                      }
                     window.location.href= '/search/'+address+'/tradesmen/electrician'
                    }}
                  >
                    <img className={styles.imgIcon} alt="" src="/electr.webp" />
                    <div className={styles.homepageContent}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Electricians</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.categoriesBreakdown}>
                  <div className={styles.header}>
                    <div className={styles.title7}>Cleaning</div>
                    <div
                      className={styles.iconsaddParent2}
                      style={{cursor:'pointer'}}
                    onClick={()=>{
                      navigate('/category/cleaning')
                    }}>
                     <p>+ View all</p>
                    </div>
                  </div>
                  <div className={styles.servicesWeb12}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/cleaning/domestic cleaners'
                   }}>
                    <img className={styles.imgIcon} alt="" src="/domestic.webp" />
                    <div className={styles.homepageContent}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Domestic Cleaners</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb13}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      if (error) {
                        getCurrentLocation()
                        return
                      }
                     window.location.href= '/search/'+address+'/cleaning/window cleaners'
                    }}>
                    <img className={styles.imgIcon} alt="" src="/window.webp" />
                    <div className={styles.homepageContent}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Window Cleaners</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb14}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      if (error) {
                        getCurrentLocation()
                        return
                      }
                     window.location.href= '/search/'+address+'/cleaning/property maintenance'
                    }}>
                    <img className={styles.imgIcon} alt="" src="/property.webp" />
                    <div className={styles.homepageContent}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Property Maintenance</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb19}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      if (error) {
                        getCurrentLocation()
                        return
                      }
                     window.location.href= '/search/'+address+'/cleaning/drain and pipe cleaning'
                    }}>
                    <img className={styles.imgIcon} alt="" src="/drain.webp" />
                    <div className={styles.homepageContent}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Drain and Pipe Cleaning</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               {/* <div className={styles.categoriesBreakdown}>
                  <div className={styles.header}>
                    <div className={styles.title7}>Childcare</div>
                    <div
                      className={styles.iconsaddParent2}
                      //onClick={onFrameContainer142Click}
                    >
                     
                    </div>
                  </div>
                  <div className={styles.servicesWeb12}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/childcare/babysitters'
                   }}>
                    <img className={styles.imgIcon} alt="" src="/img4@2x.png" />
                    <div className={styles.homepageContent}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Babysitters</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb13}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      if (error) {
                        getCurrentLocation()
                        return
                      }
                     window.location.href= '/search/'+address+'/childcare/childminders'
                    }}>
                    <img className={styles.imgIcon} alt="" src="/img5@2x.png" />
                    <div className={styles.homepageContent}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Childminders</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb14}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      if (error) {
                        getCurrentLocation()
                        return
                      }
                     window.location.href= '/search/'+address+'/childcare/nannies'
                    }}>
                    <img className={styles.imgIcon} alt="" src="/img6@2x.png" />
                    <div className={styles.homepageContent}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Nannies</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb19}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      if (error) {
                        getCurrentLocation()
                        return
                      }
                     window.location.href= '/search/'+address+'/childcare/nursery'
                    }}>
                    <img className={styles.imgIcon} alt="" src="/img7@2x.png" />
                    <div className={styles.homepageContent}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Nursery</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.categoriesBreakdown}>
                  <div className={styles.header}>
                    <div className={styles.title7}>Entertainment</div>
                    <div className={styles.iconsaddParent1}>
                     
                    </div>
                  </div>
                  <div className={styles.servicesWeb12}
                    style={{cursor:'pointer'}}
                    onClick={()=>{
                      if (error) {
                        getCurrentLocation()
                        return
                      }
                     window.location.href= '/search/'+address+'/entertainment/event%20planners'
                    }}>
                    <img className={styles.imgIcon} alt="" src="/img8@2x.png" />
                    <div className={styles.homepageContent}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Event planners</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb13}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/entertainment/event%20coverage'
                   }}>
                    <img className={styles.imgIcon} alt="" src="/img9@2x.png" />
                    <div className={styles.homepageContent}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Event coverage</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb14}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/entertainment/photography%20and%20film'
                   }}>
                    <img
                      className={styles.imgIcon}
                      alt=""
                      src="/img10@2x.png"
                    />
                    <div className={styles.homepageContent}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>
                            Photography and film
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.servicesWeb19}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/entertainment/disk%20jockeys'
                   }}>
                    <img
                      className={styles.imgIcon}
                      alt=""
                      src="/img11@2x.png"
                    />
                    <div className={styles.homepageContent}>
                      <div className={styles.profiledescriptionInner}>
                        <div className={styles.electriciansWrapper}>
                          <div className={styles.viewAll}>Disk Jockeys</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>*/}
              </div>
            </div>
            <div className={styles.frameWrapper2}>
               <div className={styles.exploreServicesByCategoriesGroup}>
                <b className={styles.exploreServicesBy1}>
                  Explore services by categories
                </b>
            </div>
            </div>
           
           { /*<div className={styles.frameWrapper2}>
              <div className={styles.exploreServicesByCategoriesGroup}>
                <b className={styles.exploreServicesBy1}>
                  Explore services by categories
                </b>
                <div className={styles.categoriesParent1}>
                  <div className={styles.categories32}>
                    <div className={styles.categoriesParent2}>
                      <div className={styles.categories33}>
                        <div className={styles.categories1}
                        style={{cursor:'pointer'}}
                        onClick={()=>{
                          if (error) {
                            getCurrentLocation()
                            return
                          }
                         window.location.href= '/category/catering'
                        }}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories11.svg"
                            />
                          </div>
                          <div className={styles.computerPhone16}>Catering</div>
                        </div>
                      </div>
                      <div
                        className={styles.categories35}
                      >
                        <div className={styles.categories1}
                         style={{cursor:'pointer'}}
                         onClick={()=>{
                           if (error) {
                             getCurrentLocation()
                             return
                           }
                          window.location.href= '/category/childcare'
                         }}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories3.svg"
                            />
                          </div>
                          <div className={styles.computerPhone16}>
                            Childcare
                          </div>
                        </div>
                      </div>
                      <div className={styles.categories33}>
                        <div className={styles.categories1}
                         style={{cursor:'pointer'}}
                         onClick={()=>{
                           if (error) {
                             getCurrentLocation()
                             return
                           }
                          window.location.href= '/category/cleaning'
                         }}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories29.svg"
                            />
                          </div>
                          <div className={styles.computerPhone16}>Cleaning</div>
                        </div>
                      </div>
                      <div className={styles.categories33}>
                        <div className={styles.categories1}
                         style={{cursor:'pointer'}}
                         onClick={()=>{
                           if (error) {
                             getCurrentLocation()
                             return
                           }
                          window.location.href= '/category/entertainment/'
                         }}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories31.svg"
                            />
                          </div>
                          <div className={styles.computerPhone16}>
                            Entertainment
                          </div>
                        </div>
                      </div>
                      <div className={styles.categories33}>
                        <div className={styles.categories1}
                         style={{cursor:'pointer'}}
                         onClick={()=>{
                           if (error) {
                             getCurrentLocation()
                             return
                           }
                          window.location.href= '/category/appliance%20repair'
                         }}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories4.svg"
                            />
                          </div>
                          <div className={styles.computerPhone16}>
                            Appliance Repair
                          </div>
                        </div>
                      </div>
                      <div className={styles.categories33}>
                        <div className={styles.categories1}
                         style={{cursor:'pointer'}}
                         onClick={()=>{
                           if (error) {
                             getCurrentLocation()
                             return
                           }
                          window.location.href= '/category/mobile%20mechanic'
                         }}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories5.svg"
                            />
                          </div>
                          <div className={styles.computerPhone16}>
                            Mobile Mechanic
                          </div>
                        </div>
                      </div>
                      <div className={styles.categories33}>
                        <div className={styles.categories1}
                         style={{cursor:'pointer'}}
                         onClick={()=>{
                           if (error) {
                             getCurrentLocation()
                             return
                           }
                          window.location.href= '/category/personal%20assistance'
                         }}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories6.svg"
                            />
                          </div>
                          <div className={styles.computerPhone16}>
                            Personal Assistance
                          </div>
                        </div>
                      </div>
                      <div className={styles.categories33}>
                        <div className={styles.categories1}
                         style={{cursor:'pointer'}}
                         onClick={()=>{
                           if (error) {
                             getCurrentLocation()
                             return
                           }
                          window.location.href= '/category/pet%20groomers'
                         }}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories7.svg"
                            />
                          </div>
                          <div className={styles.computerPhone16}>
                            Pet Groomers
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.categories32}>
                    <div className={styles.categoriesParent2}>
                      <div className={styles.categories33}>
                        <div className={styles.categories1}
                         style={{cursor:'pointer'}}
                         onClick={()=>{
                           if (error) {
                             getCurrentLocation()
                             return
                           }
                          window.location.href= '/category/recovery%20services'
                         }}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories8.svg"
                            />
                          </div>
                          <div className={styles.computerPhone16}>
                            Recovery Services
                          </div>
                        </div>
                      </div>
                      <div className={styles.categories33}>
                        <div className={styles.categories1}
                         style={{cursor:'pointer'}}
                         onClick={()=>{
                           if (error) {
                             getCurrentLocation()
                             return
                           }
                          window.location.href= '/category/computer%20&%20phone%20repair/'
                         }}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories9.svg"
                            />
                          </div>
                          <div
                            className={styles.computerPhone16}
                          >{`Computer & Phone repair`}</div>
                        </div>
                      </div>
                      <div className={styles.categories33}>
                        <div className={styles.categories1}
                         style={{cursor:'pointer'}}
                         onClick={()=>{
                           if (error) {
                             getCurrentLocation()
                             return
                           }
                          window.location.href= '/category/removal%20services'
                         }}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories10.svg"
                            />
                          </div>
                          <div className={styles.computerPhone16}>
                            Removal Services
                          </div>
                        </div>
                      </div>
                      <div className={styles.categories33}>
                        <div className={styles.categories1}
                         style={{cursor:'pointer'}}
                         onClick={()=>{
                           if (error) {
                             getCurrentLocation()
                             return
                           }
                          window.location.href= '/category/tradesmen'
                         }}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories111.svg"
                            />
                          </div>
                          <div className={styles.computerPhone16}>
                            Tradesmen
                          </div>
                        </div>
                      </div>
                      <div className={styles.categories33}>
                        <div className={styles.categories1}
                         style={{cursor:'pointer'}}
                         onClick={()=>{
                           if (error) {
                             getCurrentLocation()
                             return
                           }
                          window.location.href= '/category/transport%20services/'
                         }}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories12.svg"
                            />
                          </div>
                          <div className={styles.computerPhone16}>
                            Transport Services
                          </div>
                        </div>
                      </div>
                      <div className={styles.categories33}>
                        <div className={styles.categories1}
                         style={{cursor:'pointer'}}
                         onClick={()=>{
                           if (error) {
                             getCurrentLocation()
                             return
                           }
                          window.location.href= '/category/dry%20cleaning%20&%20laundry'
                         }}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories13.svg"
                            />
                          </div>
                          <div
                            className={styles.computerPhone16}
                          >{`Dry Cleaning & Laundry`}</div>
                        </div>
                      </div>
                      <div className={styles.categories33}>
                        <div className={styles.categories1}
                         style={{cursor:'pointer'}}
                         onClick={()=>{
                           if (error) {
                             getCurrentLocation()
                             return
                           }
                          window.location.href= '/category/health,%20wellness%20&%20beauty'
                         }}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories14.svg"
                            />
                          </div>
                          <div
                            className={styles.computerPhone16}
                          >{`Health, Wellness & Beauty`}</div>
                        </div>
                      </div>
                      <div className={styles.categories33}>
                        <div className={styles.categories1}
                         style={{cursor:'pointer'}}
                         onClick={()=>{
                           if (error) {
                             getCurrentLocation()
                             return
                           }
                          window.location.href= '/category/tuition%20&%20classes'
                         }}>
                          <div className={styles.iconscategoriesWrapper}>
                            <img
                              className={styles.iconscategories}
                              alt=""
                              src="/iconscategories15.svg"
                            />
                          </div>
                          <div
                            className={styles.computerPhone16}
                          >{`Tuition & Classes`}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}
            <div className={styles.becomeAProfessionalSection3}>
              <div className={styles.merge18}>
                <div className={styles.picture3}>
                  <img
                    className={styles.rectangleIcon}
                    alt=""
                    src="/rectangle-122971@2x.webp"
                  />
                  <div className={styles.popRequest3}>
                    <img
                      className={styles.popRequestChild1}
                      alt=""
                      src="/frame-22511.svg"
                    />
                    <img
                      className={styles.popRequestChild2}
                      alt=""
                      src="/polygon-22.svg"
                    />
                    <div className={styles.matthewPhillips2}>
                      Matthew Phillips
                    </div>
                    <div className={styles.requestForCarpenter2}>
                      Request For Carpenter
                    </div>
                    <div className={styles.ellipseContainer}>
                      <div className={styles.frameChild7} />
                      <div className={styles.newServiceRequest}>
                        1 New Service Request
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.text8}>
                  <div className={styles.frameParent15}>
                    <div className={styles.cantFindTheProfessionalYoGroup}>
                      <b className={styles.cantFindThe1}>
                        Do you have a service to offer?
                      </b>
                      <div className={styles.allYourPayments1}>
                        All your payments are protected by a highly secure
                        Escrow account. Pay in advance or on completion with
                        just a few clicks of a button, whenever.
                      </div>
                    </div>
                    <button
                      className={styles.largeButtons11}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
                    >
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder19.svg"
                      />
                      <b className={styles.label10}>Become a professional</b>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder19.svg"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameWrapper3}>
              <div className={styles.explorePopularServicesGroup}>
                <b className={styles.securedPaymentAnd}>
                  Explore popular services
                </b>
                <div className={styles.component6Group}>
                  <div className={styles.component61}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/tradesmen/electrician'
                   }}>
                    <img
                      className={styles.imageIcon}
                      alt=""
                      src="/electr.webp"
                    />
                    <div className={styles.serviceWrapper}>
                      <div className={styles.service}>Electrician</div>
                    </div>
                  </div>
                  <div className={styles.popularServices}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/tradesmen/plumber'
                   }}>
                    <img
                      className={styles.imageIcon}
                      alt=""
                      src="/plumber.webp"
                    />
                    <div className={styles.serviceWrapper}>
                      <div className={styles.service}>Plumber</div>
                    </div>
                  </div>
                  <div className={styles.popularServices}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/tradesmen/plasterers'
                   }}>
                    <img
                      className={styles.imageIcon2}
                      alt=""
                      src="/plaster.webp"
                    />
                    <div className={styles.serviceWrapper}>
                      <div className={styles.service}>Plasters</div>
                    </div>
                  </div>
                  <div className={styles.popularServices}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/cleaning/all'
                   }}>
                    <img
                      className={styles.imageIcon}
                      alt=""
                      src="/domestic.webp"
                    />
                    <div className={styles.serviceWrapper}>
                      <div className={styles.service}>Cleaners</div>
                    </div>
                  </div>
                  <div className={styles.popularServices}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/tradesmen/handyman'
                   }}>
                    <img
                      className={styles.imageIcon2}
                      alt=""
                      src="/handy.webp"
                    />
                    <div className={styles.serviceWrapper}>
                      <div className={styles.service}>Handyman</div>
                    </div>
                  </div>
                  <div className={styles.component61}
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                     if (error) {
                       getCurrentLocation()
                       return
                     }
                    window.location.href= '/search/'+address+'/tradesmen/tiler'
                   }}>
                    <img
                      className={styles.imageIcon2}
                      alt=""
                      src="/tiler.webp"
                    />
                    <div className={styles.serviceWrapper}>
                      <div className={styles.service}>Tiler</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.mergeContainer}>
              <div className={styles.frameParent2}>
                <div className={styles.sectionTitle}>
                  <h1>
                     <b className={styles.securedPaymentAnd1}>
                    Secured payment and privacy on Loggworks
                  </b>
                  </h1>
                  <div className={styles.loggworksHelpsYou6}>
                    Loggworks helps you get special offers, connect you to top
                    vetted and reliable service professionals while protecting
                    your payment and privacy.
                  </div>
                </div>
                <div className={styles.reasons1}>
                  <div className={styles.whyUs3}>
                    <div className={styles.privacyParent}>
                      <img
                        className={styles.privacyIcon}
                        alt=""
                        src="/privacy7.svg"
                      />
                      <div className={styles.privacyProtectionParent1}>
                        <b className={styles.privacyProtection}>
                          Privacy Protection.
                        </b>
                        <div className={styles.youllNeverHave}>
                          You’ll never have to share your personal information
                          with tradesmen or home service professionals on
                          Loggworks. Communicate with service providers securely
                          through our direct messaging feature until you’re
                          ready to make a booking.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.whyUs3}>
                    <div className={styles.privacyParent}>
                      <img
                        className={styles.privacyIcon}
                        alt=""
                        src="/privacy8.svg"
                      />
                      <div className={styles.privacyProtectionParent1}>
                        <b className={styles.privacyProtection}>
                          Payment Protection.
                        </b>
                        <div className={styles.youllNeverHave}>
                          All your payments are protected by a highly secure
                          Escrow account. Pay in advance or on completion with
                          just a few clicks of a button, whenever you want.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.whyUs3}>
                    <div className={styles.privacyParent}>
                      <img
                        className={styles.privacyIcon}
                        alt=""
                        src="/privacy9.svg"
                      />
                      <div className={styles.privacyProtectionParent1}>
                        <b className={styles.privacyProtection}>
                          Trusted Professionals.
                        </b>
                        <div className={styles.youllNeverHave}>
                          <p className={styles.weUseThe}>
                            We use the Self-Employed Quality Control Scheme
                            (SEQCS) to comprehensively screen all home service professionals on Loggworks — for your
                            peace of mind.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.featuredOn1}>
              <div className={styles.featuredOnGroup}>
                <div className={styles.featuredOn2}>PARTNERS:</div>
                <div className={styles.theTelegraphLogotypeLogo1Group}>
                
                  <img
                    className={styles.f3f0cef1014c0b5e48861Icon1}
                    alt=""
                    src="/mangopay_powered-4311aa3e.svg"
                  />
                   <img
                    className={styles.f3f0cef1014c0b5e48861Icon1}
                    style={{width:'200px'}}
                    alt=""
                    src="/Google-Cloud-Logo.png"
                  />
                </div>
              </div>
            </div>
            <div className={styles.heroSection}>
              <img
                className={styles.heroSectionChild}
                alt=""
                src="/rectangle-11868@2x.webp"
              />
              <div className={styles.findReliableHomeServiceProGroup}>
                <b className={styles.findReliableHome1}>
                  Find reliable home service providers near you
                </b>
                <div className={styles.loggworksConnectHomeowners1}>
                <div style={{display:'flex', gap:'1rem'}}>
                   <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                      100% cost-free job postings
                    </div>
                  </div>
                  <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    Get Multiple Quotes, Free of Charge
                    </div>
                  </div>
                </div>
                <div style={{display:'flex',gap:'1rem'}}>
                   <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    Payment and Privacy Protection
                    </div>
                  </div>
                  <div style={{display:'flex',}}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                    Vetted and reliable Professionals
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <div className={styles.searchBarWrapper}>
              <div className={styles.baseField}>
                <LocationSearch2/>
              </div>
                
              </div>
              <button
                className={styles.largeButtons15}
                onClick={()=>navigate('/postjob')}
              >
                <img className={styles.iconsadd} alt="" src="/iconsadd6.svg" />
                <b className={styles.label10}>Post a Job</b>
                <img className={styles.iconsadd1} alt="" src="/iconsadd7.svg" />
              </button>
            </div>
          </div>
          <nav className={styles.navigation}>
            <div className={styles.navigation1}>
              <img
                className={styles.navigationChild}
                alt=""
                onClick={()=>navigate('/')}
                style={{cursor:'pointer'}}
                src="/frame-66522.svg"
              />
              <div className={styles.navigationItem} />
              <div className={styles.tabSwitch}>
                <div
                  className={styles.pageSelector}
                  onClick={onPageSelectorContainerClick}
                >
                  <div className={styles.descriptionParent}>
                    <div className={styles.viewAll}>Professional</div>
                    <div className={styles.selector} />
                  </div>
                </div>
                <div className={styles.pageSelector1}>
                  <div className={styles.descriptionParent}>
                    <b className={styles.facebook}>Consumer</b>
                    <div className={styles.selector1} />
                  </div>
                </div>
              </div>
             <LocationSearch/>
            </div>
            <div className={styles.navigation2}>
              <div className={styles.homeParent}>
                <div className={styles.home}>Home</div>
                <div
                  className={styles.howItWorks5}
                  onClick={onHowItWorks5Click}
                >
                  How it works
                </div>
                <div className={styles.services} 
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={openDropdown}>
                  <div className={styles.services1}>Services</div>
                  <img
                    className={styles.iconscategories}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                 <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={isDropdownOpen}
        open={open}
        onClose={closeDropdown}
        TransitionComponent={Fade}
      >
      <Dropdown4/>
      </Menu>
                <div className={styles.services}
                 id="fade-button2"
                aria-controls={open1 ? 'fade-menu2' : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? 'true' : undefined}
                onClick={openDropdown1}>
                  <div className={styles.services1}>Resources</div>
                  <img
                    className={styles.iconscategories}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                           <Menu
        id="fade-menu2"
        MenuListProps={{
          'aria-labelledby': 'fade-button2',
        }}
        anchorEl={isDropdown1Open}
        open={open1}
        onClose={closeDropdown1}
        TransitionComponent={Fade}
      >
      <Dropdown5/>
      </Menu>
                <div className={styles.pricing}>Pricing</div>
              </div>
              <div className={styles.frameParent17}>
                <div className={styles.frameParent18}>
                  <button
                    className={styles.iconshelpParent}
                    onClick={onFrameButtonClick}
                  >
                    <img
                      className={styles.iconscategories}
                      alt=""
                      src="/iconshelp.svg"
                    />
                    <div className={styles.help}>Help</div>
                  </button>
                  <button className={styles.iconshelpParent} onClick={()=>window.open('https://www.consumer.loggworks.co.uk/login', '_blank')}>
                    <img
                      className={styles.iconscategories}
                      alt=""
                      src="/iconsaccount1.svg"
                    />
                    <div className={styles.help}>Account</div>
                  </button>
                </div>
                <button
                  className={styles.smallButtons}
                  onClick={()=>navigate('/postjob')}
                >
                  <div className={styles.iconsaddParent4}>
                    <img
                      className={styles.iconsadd10}
                      alt=""
                      src="/iconsadd8.svg"
                    />
                    <div className={styles.button}>Post a job</div>
                  </div>
                </button>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {isMobileMenuConsumerPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuConsumerPopup}
        >
          <MobileMenuConsumer1 onClose={closeMobileMenuConsumerPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default HomePage;
