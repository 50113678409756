import { useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Trendingpage.module.css";
import PrototypeFooter from "../../components/PrototypeFooter";
import { Autocomplete, TextField } from "@mui/material";
import mobnav from "../ConsumerHome.module.css";
import { useState } from "react";
import ToolBar from "./components/ToolBar";
import MobileFooter from "../../components/MobileFooter";
import axios from "axios";
import NoArticle from "./components/Empty";
import MenuMobile from "./components/MenuMobile";
import PortalPopup from "../../components/PortalPopup";
import SubsBanner from "./components/SubsBanner";
import Highlighter from "react-highlight-words";

const Trendingpage = () => {
  const [
    isMobileMenuProfProfessionalPopupOpen,
    setMobileMenuProfProfessionalPopupOpen,
  ] = useState(false);
  const openMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(true);
  }, []);

  const closeMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(false);
  }, []);
  const [trend, setTrend]=useState([])
  const [searchWord, setSearchWord] = useState("");
  useEffect(() => {
    let trendprof = [];
    let trendcons = [];
    let trendnews = [];
  
    const fetchData = async () => {
      try {
        const [blogsResponse, professionalsResponse, newsResponse] = await Promise.all([
          axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/blogs?populate=*'),
          axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/for-professionals?populate=*'),
          axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/news?populate=*')
        ]);
  
        trendcons = blogsResponse.data.data.filter(blog => blog.attributes.category === 'trending').map(blog => ({ ...blog, type: 'blogs' }));
        trendprof = professionalsResponse.data.data.filter(blog => blog.attributes.category === 'trending').map(blog => ({ ...blog, type: 'professionals' }));
        trendnews = newsResponse.data.data.filter(blog => blog.attributes.category === 'trending').map(blog => ({ ...blog, type: 'news' }));
  
        const combined = [...trendcons, ...trendprof, ...trendnews];
        setTrend(combined);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  
  const navigate = useNavigate();

  const onBLOGTextClick = useCallback(() => {
    navigate("/blog");
  }, [navigate]);

 
  const handleChange = (event, value) => {
    if (value) {
      trend.filter(blog => blog.attributes.BlogTitle === value).map(data=>{
        if (blog.type=='blogs') {
          navigate(`/blog/for-consumers/${data.attributes.slug}`);
        }else if (blog.type=='professionals') {
          navigate(`/blog/for-professionals/${data.attributes.slug}`);
        }else if (blog.type=='news') {
          navigate(`/blog/news/${data.attributes.slug}`);
        }
         
      })
     
    }
  };
  const searchBlogs = async (searchText) => {
    if (searchText.trim() === '') {
      try {
        const [blogsResponse, professionalsResponse, newsResponse] = await Promise.all([
          axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/blogs?populate=*'),
          axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/for-professionals?populate=*'),
          axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/news?populate=*')
        ]);
  
        const trendcons = blogsResponse.data.data.filter(blog => blog.attributes.category === 'trending').map(blog => ({ ...blog, type: 'blogs' }));
        const trendprof = professionalsResponse.data.data.filter(blog => blog.attributes.category === 'trending').map(blog => ({ ...blog, type: 'professionals' }));
        const trendnews = newsResponse.data.data.filter(blog => blog.attributes.category === 'trending').map(blog => ({ ...blog, type: 'news' }));
  
        const combined = [...trendcons, ...trendprof, ...trendnews];
        setTrend(combined);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      return;
    }
  
    const filteredTrend = trend.filter(blog => {
      const categoryMatch = blog.attributes.category.toLowerCase().includes(searchText.toLowerCase());
      const subcategoryMatch = blog.attributes.subcategory.toLowerCase().includes(searchText.toLowerCase());
      const titleMatch = blog.attributes.BlogTitle.toLowerCase().includes(searchText.toLowerCase());
      const descriptionMatch = blog.attributes.description.toLowerCase().includes(searchText.toLowerCase());
  
      return categoryMatch || subcategoryMatch || titleMatch || descriptionMatch;
    });
  
    setTrend(filteredTrend);
  };
  
  const formatDateToWords = (dateString) => {
    const date = new Date(dateString);
    
    // Array of month names
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    // Get the components of the date
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    
    // Construct the formatted date string
    const formattedDate = `${month} ${day}, ${year}`;
    
    return formattedDate;
  };
  return (
    <div className={styles.trendingpage}>
      <div className={styles.trendweb}>
        <div className={styles.trendwebInner}>
       <ToolBar/>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.blogGroup}>
            <div className={styles.blog1} onClick={onBLOGTextClick}>
              BLOG
            </div>
            <img
              className={styles.arrowrightIcon}
              alt=""
              src="/caretright.svg"
            />
            <div className={styles.trendingArticles}>Trending Articles</div>
          </div>
          <div className={styles.rectangleGroup}>
          <Autocomplete
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      options={trend.map((option) => option.attributes.BlogTitle)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search articles"
          onChange= {(e) => {
            searchBlogs(e.target.value)
            setSearchWord(e.target.value)}}
          InputProps={{
            ...params.InputProps,
            type: "search",
            
          }}
        />
      )}
      
      onChange={handleChange}
      autoHighlight  

    />
          </div>
        </div>
        <div className={styles.trendingArticlesParent}>
          <div className={styles.trendingArticles1}>Trending Articles</div>
          {
            trend.length<=0?
            <>
            <NoArticle/>
            </>
            :
             <div className={styles.frameContainer}>
          {trend.map(blog => (
                <Link key={blog.id} to={
                blog.type=='blogs'?'/blog/for-consumers/'+blog.attributes.slug:
                blog.type=='professionals'?'/blog/for-professionals/'+blog.attributes.slug:'/blog/news/'+blog.attributes.slug}>
<div className={styles.frameDiv}>
              <div
                className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameGroup}>
                  <div className={styles.blogGroup}>
                    <div className={styles.businessAdviceWrapper}>
                      <div className={styles.businessAdvice}>
                      {blog.attributes.category}
                      </div>
                    </div>
                    <div className={styles.minsRead}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.howToPricingYourServicesAParent}>
                    <b className={styles.howToPricing}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.loggworksHelpsYou}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                    </div>
                  </div>
                  <div className={styles.christinaWilliamsParent}>
                    <div className={styles.christinaWilliams}>
                    {blog.attributes.Author}
                    </div>
                    <div className={styles.ellipseDiv} />
                    <div className={styles.january18th2024}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
          </div>
          }
         
        </div>
        <div className={styles.subscribeForNewsletterParent}>
        <SubsBanner/>
          <div className={styles.prototypeFooter}>
          <PrototypeFooter/>
          </div>
        </div>
      </div>
      <div className={styles.trendmob}>
      <div className={mobnav.navbarMobileclosed}>
          <img className={mobnav.layer2Icon} alt="" src="/layer-21.svg"
          style={{cursor:'pointer'}} onClick={()=>navigate('/')}  />
          <div className={mobnav.iconsaccountParent}>
            <div className={mobnav.iconsaccount} alt="" />
            <img className={mobnav.hamburgerIcon} alt="" src="/hamburger.svg" onClick={openMobileMenuProfProfessionalPopup} />
          </div>
        </div>
        <div className={styles.frameParent28}>
          <div className={styles.termsOfUseParent}>
            <div className={styles.blog3} onClick={onBLOGTextClick}>BLOG</div>
            <img
              className={styles.caretrightIcon1}
              alt=""
              src="/caretright.svg"
            />
            <div className={styles.trendingArticles}>Trending Articles</div>
          </div>
          <div className={styles.rectangleParent10}>
          <Autocomplete
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      options={trend.map((option) => option.attributes.BlogTitle)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search articles"
          onChange= {(e) => {
            searchBlogs(e.target.value)
            setSearchWord(e.target.value)}}
          InputProps={{
            ...params.InputProps,
            type: "search",
            
          }}
        />
      )}
      
      onChange={handleChange}
      autoHighlight  

    />
          </div>
        </div>
        <div className={styles.trendingArticlesGroup}>
          <div className={styles.trendingArticles3}>Trending Articles</div>
          {
            trend.length<=0?
            <>
            <NoArticle/>
            </>
            :
            <div className={styles.groupParent6}>
          {trend.map(blog => (
                <Link key={blog.id} to={
                  blog.type=='blogs'?'/blog/for-consumers/'+blog.attributes.slug:
                  blog.type=='professionals'?'/blog/for-professionals/'+blog.attributes.slug:'/blog/news/'+blog.attributes.slug}>
                   <div className={styles.rectangleParent11}>
              <div className={styles.groupChild20} />
              <img
                className={styles.groupChild21}
                alt=""
                src={blog.attributes.BlogImage.data.attributes.url}
              />
              <div className={styles.frameParent29}>
                <div className={styles.frameParent30}>
                  <div className={styles.hiringGuideWrapper}>
                    <div className={styles.hiringGuide}>{blog.attributes.category}</div>
                  </div>
                  <div className={styles.minsRead10}>{blog.attributes.duration} mins read</div>
                </div>
                <div className={styles.howToPricingYourServicesAParent2}>
                  <b className={styles.howToPricing4}>
                  <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                  </b>
                  <div
                    className={styles.loggworksHelpsYou4}
                  >
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                    </div>
                </div>
                <div className={styles.christinaWilliamsParent8}>
                  <div className={styles.christinaWilliams10}>
                  {blog.attributes.Author}
                  </div>
                  <div className={styles.frameChild15} />
                  <div className={styles.january18th202410}>
                  {formatDateToWords(blog.attributes.createdAt)}
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
           
          </div>
          }
          
        </div>
        <div className={styles.mobileFooterHomeownerParent}>
        <SubsBanner/>
         <MobileFooter/>
          
        </div>
        {isMobileMenuProfProfessionalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuProfProfessionalPopup}
        >
          <MenuMobile
            onClose={closeMobileMenuProfProfessionalPopup}
          />
        </PortalPopup>
      )}
      </div>
    </div>
  );
};

export default Trendingpage;
