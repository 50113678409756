import { useState, useCallback } from "react";
import Dropdown4 from "../components/Dropdown4";
import PortalPopup from "../components/PortalPopup";
import Dropdown5 from "../components/Dropdown5";
import MobileMenuConsumer1 from "../components/MobileMenuConsumer1";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./AboutUs.module.css";
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import MobileFooter from "../components/MobileFooter";
import LocationSearch from "../components/locationSearch.tsx";

const AboutUs = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(null);
  const open = Boolean(isDropdownOpen);
  const categories = [
    {
      title: "Catering",
      image: "catering",
      hoverImg: [
        '/cate/kitchen.webp',
        '/cate/short.webp',
        '/cate/cater.webp',
        '/cate/other.webp',
      ],
      options: [
        "kitchen porter", 
        "short-order cook", 
        "caterer", 
        "others"
      ],
    },
    {
      title: "Childcare",
      image: "childcare",
      hoverImg: [
        '/cate/baby.webp',
        '/cate/childmind.webp',
        '/cate/nann.webp',
        '/cate/nursery.webp',
        '/cate/other.webp',
      ],
      options: [
        "babysitters",
        "childminders",
        "Virtual Nannies",
        "Nursery",
        "others",
      ],
    },
    {
      title: "Cleaning",
      image: "cleaning",
      hoverImg: [
        '/cate/domestic.webp',
        '/cate/window.webp',
        '/cate/property.webp',
        '/cate/drain.webp',
        '/cate/domesticclean.webp',
        '/cate/other.webp',
      ],
      options: [
        "domestic cleaners",
        "window cleaners",
        "property maintenance",
        "drain and pipe cleaning",
        "domestic and commercial cleaners",
        "others",
      ],
    },
    {
      title: "Entertainment",
      image: "entertainment",
      hoverImg: [
        '/cate/event.webp',
        '/cate/planner.webp',
        '/cate/coverage.webp',
        '/cate/eventparty.webp',
        '/cate/eandcservices.webp',
        '/cate/dj.webp',
        '/cate/funcroomandbanqueting.webp',
        '/cate/organisers.webp',
        '/cate/wedding.webp',
        '/cate/photography.webp',
        '/cate/florist.webp',
        '/cate/other.webp',
      ],
      options: [
        "Event planner",
        "party planner",
        "Event coverage",
        "Event and party planner",
        "Event planner and coverage services",
        "DJ and disco hire",
        "function rooms and banqueting facilities",
        "organisers and planners",
        "wedding and reception venues",
        "photography and film",
        "florist",
        "others",
      ],
    },
    {
      title: "Appliance Repair",
      image: "appRepair",
      hoverImg:[
        '/cate/appl1.webp',
        '/cate/applrepairs.webp',
        '/cate/other.webp',
      ],
      options: [
        "Domestic Appliance Repairs", 
      "Appliance Repairs",'others'],
    },
    {
      title: "Mobile Mechanic",
      image: "mechanic",
      hoverImg: [
        '/cate/mobilemec.webp',
        '/cate/other.webp',
      ],
      options: ["Mobile mechanic",'others'],
    },
    {
      title: "Personal Assistance",
      image: "pa",
      hoverImg: [
        '/cate/passist.webp',
        '/cate/householdpa.webp',
        '/cate/other.webp',
      ],
      options: [
        "remote personal assistance",
        "household personal assistance",
        "others",
      ],
    },
    {
      title: "Pet Groomers",
      image: "petg",
      hoverImg: [
        '/cate/petg.webp',
        '/cate/pettr.webp',
        '/cate/petgandt.webp',
        '/cate/dogwalk.webp',
        '/cate/other.webp',
      ],
      options: [
        "pet grooming",
        "pet training",
        "pet grooming and training",
        "dog walker",
        "others",
      ],
    },
    {
      title: "Recovery Services",
      image: "recovery",
      hoverImg: [
        '/cate/recovery.webp',
        '/cate/other.webp',
      ],
      options: ["recovery",'others'],
    },
    {
      title: "Computer & Phone repair",
      image: "computer",
      hoverImg: [
        '/cate/phone.webp',
        '/cate/comp.webp',
        '/cate/compandphone.webp',
        '/cate/other.webp',
      ],
      options: [
        "phone repair",
        "computer repair",
        "computer and phone repair",
        "others",
      ],
    },
    {
      title: "Removal Services",
      image: "removal",
      hoverImg: [
        '/cate/removal.webp',
        '/cate/domesticrem.webp',
        '/cate/commercialre.webp',
        '/cate/other.webp',
      ],
      options: [
        "removal",
        "Domestic removals",
        "Commercial removals",
        "local removals",
      ],
    },
    {
      title: "Tradesmen",
      image: "trade",
      hoverImg: [
        '/cate/trades&const.webp',
        '/cate/const.webp',
        '/cate/gard&land.webp',
        '/cate/gard.webp',
        '/cate/paint.webp',
        '/cate/decor.webp',
        '/cate/plumber.webp',
        '/cate/handy.webp',
        '/cate/electr.webp',
        '/cate/builder.webp',
        '/cate/plaster.webp',
        '/cate/architect.webp',
        '/cate/carp&joiners.webp',
        '/cate/groundwal.webp',
        '/cate/roofer.webp',
        '/cate/tiler.webp',
        '/cate/paving&drive.webp',
        '/cate/floor.webp',
        '/cate/locksm.webp',
        '/cate/interiordes.webp',
        '/cate/landsca.webp',
        '/cate/carp.webp',
        '/cate/plaster.webp',
        '/cate/driveways_patio.webp',
        '/cate/fencing.webp',
        '/cate/boiler_repair.webp',
        '/cate/treesurg.webp',
        '/cate/carpre.webp',
        '/cate/other.webp',
      ],
      options: [
        "Tradesmen & Construction",
        "Construction",
        "gardening & landscaping",
        "gardener",
        "painter",
        "decorating",
        "plumber",
        "handyman",
        "electrician",
        "builder",
        "plasterers",
        "architect",
        "carpentry and joiners",
        "groundworkers",
        "roofer",
        "tiler",
        "paving and driveway",
        "flooring",
        "locksmiths",
        "interior designers",
        "Landscaper",
        "Carpenter",
        "Plasterer",
        "Driveways/patio",
        "fencing",
        "boiler repair",
        "tree Surgeons",
        "Carpet Repairs",
        "others",
      ],
    },
    {
      title: "Transport Services",
      image: "transport",
      hoverImg: [
        '/cate/taxi.webp',
        '/cate/bus&coa.webp',
        '/cate/van&truc.webp',
        '/cate/chauffeur.webp',
        '/cate/other.webp',
      ],
      options: [
        "taxi",
        "bus and coach hire",
        "van and truck hire",
        "chauffeur and limousine hire",
        "car hire",
      ],
    },
    {
      title: "Dry Cleaning & Laundry",
      image: "dryclean",
      hoverImg: [
        '/cate/dryclean.webp',
        '/cate/other.webp',
      ],
      options: ["dry cleaner",'others'],
    },
    {
      title: "Health, Wellness & Beauty",
      image: "health",
      hoverImg: [
        '/cate/counsel.webp',
        '/cate/ment.webp',
        '/cate/lifecoa.webp',
        '/cate/nutri.webp',
        '/cate/pt.webp',
        '/cate/relat&marr.webp',
        '/cate/lifecoa&ment.webp',
        '/cate/thera.webp',
        '/cate/other.webp',
      ],
      options: [
        "Counselling",
        "mentoring",
        "life coaching ",
        "Nutritionist and dieticians",
        "personal trainers ",
        "relationship and marriage counselling",
        "life coaching and mentoring",
        "therapist",
        "others",
      ],
    },
    {
      title: "Tuition & Classes",
      image: "tuition",
      hoverImg: [
        '/cate/academ.webp',
        '/cate/langu.webp',
        '/cate/drivingless.webp',
        '/cate/music.webp',
        '/cate/guit.webp',
        '/cate/piano.webp',
        '/cate/drum.webp',
        '/cate/signing.webp',
        '/cate/clarin.webp',
        '/cate/saxo.webp',
        '/cate/violin.webp',
        '/cate/other.webp',
      ],
      options: [
        "academic Tutor",
        "language",
        "driving lessons and instructors",
        "music",
        "guitar",
        "piano",
        "drum",
        "singing",
        "clarinet",
        "saxophone",
        "violin",
        "others",
      ],
    },
  ];
  const openDropdown = (event) => {
    setDropdownOpen(event.currentTarget);
  };
  const closeDropdown = () => {
    setDropdownOpen(null);
  };
  const [isDropdown1Open, setDropdown1Open] = useState(null);
  let {id}=useParams()
  const open1 = Boolean(isDropdown1Open);
   const openDropdown1 = (event) => {
    setDropdown1Open(event.currentTarget);
  };
  const closeDropdown1 = () => {
    setDropdown1Open(null);
  };
  const [isMobileMenuConsumerPopupOpen, setMobileMenuConsumerPopupOpen] =
    useState(false);
  const navigate = useNavigate();

  const onPostAJobClick = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const onHowItWorksClick = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    navigate("/consumerhelpcontactdone");
  }, [navigate]);

  const onHelpFAQsClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementTextClick = useCallback(() => {
    navigate("/careersdone");
  }, [navigate]);

  const onHowItWorks1Click = useCallback(() => {
    navigate("/blog");
  }, [navigate]);

  const onContactUsText1Click = useCallback(() => {
    navigate("/consumerhelpcontact");
  }, [navigate]);

  const onHelpFAQs1Click = useCallback(() => {
    navigate("/professionalhelpfaq");
  }, [navigate]);

  const onUserAgreementText1Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-usdone");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/consumerhelpfaqdone1");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/professionalhelpcontactdone");
  }, [navigate]);

  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/professionalhelpcontact2");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onLargeButtons1Click = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const onSubCategoriesContainerClick = useCallback(() => {
    navigate("/search-results1");
  }, [navigate]);

  const onPageSelectorContainerClick = useCallback(() => {
    navigate("/professional");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHowItWorks2Click = useCallback(() => {
    navigate("/consumerhow-it-works");
  }, [navigate]);

  const onFrameButtonClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onSmallButtonsClick = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const openMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(true);
  }, []);

  const closeMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(false);
  }, []);

  const onProfessionalCategoriesContainerClick = useCallback(() => {
    // Please sync "Home Mobile" to the project
  }, []);

  return (
    <>
      <div>
        <div className={styles.aboutUs}>
          <nav className={styles.navigation}>
            <div className={styles.navigation1}>
              <div className={styles.groupParent}>
                <img
                  className={styles.frameItem}
                  alt=""
                  onClick={()=>navigate('/')}
                style={{cursor:'pointer'}}
                  src="/frame-66522.svg"
                />
              </div>
              <div className={styles.navigationChild} />
              <div className={styles.tabSwitch}>
                <div
                  className={styles.pageSelector}
                  onClick={onPageSelectorContainerClick}
                >
                  <div className={styles.descriptionParent}>
                    <div className={styles.description}>Professional</div>
                    <div className={styles.selector} />
                  </div>
                </div>
                <div className={styles.pageSelector1}>
                  <div className={styles.descriptionParent}>
                    <b className={styles.description1}>Consumer</b>
                    <div className={styles.selector1} />
                  </div>
                </div>
              </div>
              <LocationSearch/>
            </div>
            <div className={styles.navigation2}>
              <div className={styles.homeParent}>
                <div className={styles.home}
                 onClick={onHomeTextClick}
                 style={{cursor:'pointer'}}>Home</div>
                <div className={styles.howItWorks} onClick={onHowItWorksClick}>
                  How it works
                </div>
                <div className={styles.services}  id="fade-button"
  aria-controls={open ? 'fade-menu' : undefined}
  aria-haspopup="true"
  aria-expanded={open ? 'true' : undefined}
                onClick={openDropdown}>
                  <div className={styles.home}>Services</div>
                  <img
                    className={styles.iconssearch}
                    alt=""
                    src="/iconschevrondown21.svg"
                  />
                </div>
                <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={isDropdownOpen}
        open={open}
        onClose={closeDropdown}
        TransitionComponent={Fade}
      >
      <Dropdown4/>
      </Menu>
                <div className={styles.resources} 
                 id="fade-button2"
                 aria-controls={open1 ? 'fade-menu2' : undefined}
                 aria-haspopup="true"
                 aria-expanded={open1 ? 'true' : undefined}
                 onClick={openDropdown1}>
                  <div className={styles.home}>Resources</div>
                  <img
                    className={styles.iconssearch}
                    alt=""
                    src="/iconschevrondown21.svg"
                  />
                </div>
                <Menu
        id="fade-menu2"
        MenuListProps={{
          'aria-labelledby': 'fade-button2',
        }}
        anchorEl={isDropdown1Open}
        open={open1}
        onClose={closeDropdown1}
        TransitionComponent={Fade}
      >
      <Dropdown5/>
      </Menu>
                <div className={styles.pricing}>Pricing</div>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.frameGroup}>
                  <button
                    className={styles.iconshelpParent}
                    onClick={onFrameButtonClick}
                  >
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/iconshelp.svg"
                    />
                    <div className={styles.help}>Help</div>
                  </button>
                  <button className={styles.iconshelpParent}>
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/iconsaccount2.svg"
                    />
                    <div className={styles.help}>Account</div>
                  </button>
                </div>
                <button
                  className={styles.smallButtons}
                  onClick={onSmallButtonsClick}
                >
                  <div className={styles.iconsaddParent}>
                    <img
                      className={styles.iconsadd}
                      alt=""
                      src="/iconsadd.svg"
                    />
                    <div className={styles.button}>Post a job</div>
                  </div>
                </button>
              </div>
            </div>
          </nav>
          <div className={styles.pageTitleParent}>
            <div className={styles.pageTitle}>
              <div className={styles.childcareParent}>
                <div className={styles.childcare}>{id}</div>
                <div className={styles.loggworksConnectHomeowners}>
                  Loggworks connect homeowners to genuine and reliable service
                  providers who provide easy-to-book and hassle-free services.
                </div>
              </div>
            </div>
            <div className={styles.container}>
              <div className={styles.whatTypeOfServiceDoYouNeParent}>
                <div className={styles.whatTypeOf}>
                  What type of service do you need?
                </div>
                <div className={styles.frameContainer}>
                  <div className={styles.subCategoriesParent}>
                  {categories.map((category) => {
  if (category.title.toLowerCase() === id.toLowerCase()) {
    return category.options.map((sub, index) => (
      <div
        key={index}
        className={styles.subCategories}
        onClick={()=>{
          if(sub!='others'){
            navigate('/consumers/'+sub+'/your location')
          }else{
            navigate('/consumers/'+category.title+'/your location')
          }
        }}
      >
        <img
          className={styles.imgIcon}
          alt=""
          src={category.hoverImg[index]}
        />
        <div className={styles.careWorkersWrapper}>
          <div className={styles.careWorkers}>{sub}</div>
        </div>
      </div>
    ));
  }
  return null; // or any default content if no matching category is found
})}

                  </div>
               
                </div>
              </div>
            </div>
            <div className={styles.prototypeFooter}>
              <div className={styles.footer}>
                <div className={styles.download}>
                  <div className={styles.downloadOurApps}>
                    Download our apps
                  </div>
                  <img
                    className={styles.playstoreButtonIcon}
                    alt=""
                    src="/playstore-button.svg"
                  />
                  <img
                    className={styles.appstoreButtonIcon}
                    alt=""
                    src="/appstore-button.svg"
                  />
                </div>
                <div className={styles.footerChild} />
                <img className={styles.layer2Icon}
                 style={{cursor:'pointer'}}
                 onClick={()=>{navigate('/')}}
                  alt="" src="/layer-22.svg" />
                <div className={styles.frameParent1}>
                  <div className={styles.homeownersParent}>
                    <b className={styles.homeowners}>Homeowners</b>
                    <div className={styles.postAJobParent}>
                      <div
                        className={styles.postAJob}
                        onClick={onPostAJobClick}
                      >
                        Post a Job
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onHowItWorksClick}
                      >
                        How it works
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onContactUsTextClick}
                      >
                        Contact Us
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onHelpFAQsClick}
                      >{`Help & FAQ’s`}</div>
                      <div
                        className={styles.postAJob}
                        onClick={onUserAgreementTextClick}
                      >
                        User Agreement
                      </div>
                    </div>
                  </div>
                  <div className={styles.professionalsParent}>
                    <b className={styles.homeowners}>Professionals</b>
                    <div className={styles.postAJobParent}>
                      <div className={styles.signUp}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                      <div
                        className={styles.postAJob}
                        onClick={onHowItWorks1Click}
                      >
                        Blog
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onContactUsText1Click}
                      >
                        Contact Us
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onHelpFAQs1Click}
                      >{`Help & FAQ’s`}</div>
                      <div
                        className={styles.postAJob}
                        onClick={onUserAgreementText1Click}
                      >
                        User Agreement
                      </div>
                    </div>
                  </div>
                  <div className={styles.loggworksParent}>
                    <b className={styles.homeowners}>Loggworks</b>
                    <div className={styles.postAJobParent}>
                      <div
                        className={styles.postAJob}
                        onClick={onAboutUsTextClick}
                      >
                        About us
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onCareersTextClick}
                      >
                        Careers
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onPartnersTextClick}
                      >
                        Partners
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onInfluencerProgramTextClick}
                      >
                        Influencer program
                      </div>
                      <div className={styles.blog}>Blog</div>
                    </div>
                  </div>
                  <div className={styles.loggworksGroup}>
                    <div className={styles.loggworks1}>
                      <b className={styles.followUs}>Follow us</b>
                    </div>
                    <div className={styles.groupContainer}>
                      <div className={styles.groupDiv}
                       style={{cursor:'pointer'}}
                       onClick={()=>window.open('https://web.facebook.com/Loggworks?_rdc=1&_rdr', '_blank')}>
                        <img
                          className={styles.frameIcon}
                          alt=""
                          src="/frame.svg"
                        />
                        <div className={styles.facebook}>Facebook</div>
                      </div>
                      <div className={styles.frameParent2}
                       style={{cursor:'pointer'}}
                       onClick={()=>window.open('https://mobile.twitter.com/loggworks', '_blank')}>
                        <img
                          className={styles.frameIcon1}
                          alt=""
                          src="/frame61.svg"
                        />
                        <div className={styles.twitter}>Twitter</div>
                      </div>
                      <div className={styles.frameParent3}
                       style={{cursor:'pointer'}}
                       onClick={()=>window.open('https://www.instagram.com/loggworks/?utm_medium=copy_link', '_blank')}>
                        <img
                          className={styles.frameIcon1}
                          alt=""
                          src="/frame71.svg"
                        />
                        <div className={styles.twitter}>Instagram</div>
                      </div>
                      <div className={styles.frameParent4}>
                        <img
                          className={styles.frameIcon1}
                          alt=""
                          src="/frame81.svg"
                        />
                        <div className={styles.twitter}>YouTube</div>
                      </div>
                      <div className={styles.mdilinkedinParent}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.linkedin.com/company/loggworks', '_blank')}>
                        <img
                          className={styles.mdilinkedinIcon}
                          alt=""
                          src="/mdilinkedin2.svg"
                        />
                        <div className={styles.linkedin}>LinkedIn</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.copyright2022LoggworkLtdAParent}>
                  <div className={styles.description}>
                    ©Copyright 2024 loggworks Ltd. All Rights Reserved
                  </div>
                  <div className={styles.frameParent5}>
                    <div className={styles.termsOfUseParent}
                     style={{cursor:'pointer'}}
                     onClick={()=>{navigate('/terms')}}>
                      <div className={styles.description}>Terms of Use</div>
                      <div className={styles.frameInner} />
                    </div>
                    <div className={styles.termsOfUseParent}>
                      <div
                        className={styles.description}
                        style={{cursor:'pointer'}}
                        onClick={()=>{navigate('/privacy')}}
                      >{`Privacy Policy `}</div>
                      <div className={styles.frameInner} />
                    </div>
                    <div className={styles.termsOfUseParent}>
                      <div className={styles.description}>Cookies</div>
                      <div className={styles.frameInner} />
                    </div>
                    <div className={styles.contactUsParent}>
                      <div className={styles.description}>Contact Us</div>
                      <div className={styles.frameInner} />
                    </div>
                    <div className={styles.termsOfUseParent}>
                      <div className={styles.description} style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href='https://www.loggworks.co.uk/sitemap.xml'
                    }}>Sitemap</div>
                      <div className={styles.frameChild3} />
                    </div>
                  </div>
                </div>
                <div className={styles.largeButtonsParent}>
                  <button
                    className={styles.largeButtons}
                    style={{cursor:'pointer'}}
                    onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
                  >
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder12.svg"
                    />
                    <b className={styles.label}>Become a Professional</b>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder12.svg"
                    />
                  </button>
                  <button
                    className={styles.largeButtons}
                    onClick={onLargeButtons1Click}
                  >
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder10.svg"
                    />
                    <b className={styles.label}>Post a Job</b>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder10.svg"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.homeMobile}>
        <div className={styles.navbarMobileclosed}>
            <img className={styles.layer2Icon1} alt="" src="/layer-219.svg" 
            style={{cursor:'pointer'}} onClick={()=>navigate('/')} />
            <div className={styles.iconsaccountGroup}>
              <img
                className={styles.iconsaccount1}
                alt=""
                src="/iconsaccount141.svg"
              />
              <img
                className={styles.hamburgerIcon}
                alt=""
                src="/hamburger.svg"
                onClick={openMobileMenuConsumerPopup}
              />
            </div>
          </div>
          <div className={styles.homeMobileInner}>
            <div className={styles.childcareWrapper}>
              <div className={styles.childcare1}>{id}</div>
            </div>
          </div>
          <div className={styles.whatTypeOf1}>
            What type of service do you need?
          </div>
          <div className={styles.professionalCategoriesParent}>
          {categories.map((category) => {
  if (category.title.toLowerCase() === id.toLowerCase()) {
    return category.options.map((sub, index) => (
       <div className={styles.professionalCategories1}  
       key={index}
       onClick={()=>{
        if(sub!='others'){
        navigate('/consumers/'+sub+'/your location')
      }else{
        navigate('/consumers/'+category.title+'/your location')
      }
      }}
       >
       <img
         className={styles.professionalCategoriesChild}
         alt=""
         src={category.hoverImg[index]}
       />
       <div className={styles.categoriesDesc}>
         <div className={styles.categoryTitle}>
           <div className={styles.titleDescWrapper}>
             <div className={styles.titleDesc}>
               <div className={styles.titleIcon}>
                 <div className={styles.catering}>{sub}</div>
                 <img
                   className={styles.iconsadd1}
                   alt=""
                   src="/iconsadd31.svg"
                 />
               </div>
              
             </div>
           </div>
         </div>
        
       </div>
     </div>
    ));
  }
  return null; // or any default content if no matching category is found
})}

           
          </div>
          <MobileFooter/>
        </div>
      </div>
      {isMobileMenuConsumerPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuConsumerPopup}
        >
          <MobileMenuConsumer1 onClose={closeMobileMenuConsumerPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default AboutUs;
