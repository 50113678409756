import { useNavigate, useParams } from "react-router-dom";
import MobileFooter from "../components/MobileFooter";
import PrototypeFooter from "../components/PrototypeFooter";
import styles from "./CustFinding.module.css";
import mobnav from "./HomePage.module.css";
import { useCallback, useEffect, useState } from "react";
import PortalPopup from "../components/PortalPopup";
import MobileMenuConsumer1 from "../components/MobileMenuConsumer1";
import LocationSearch from "../components/locationSearch.tsx";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { db } from "../firebase";
import { Helmet } from "react-helmet-async";


const CustFinding = () => {
  const [isMobileMenuConsumerPopupOpen, setMobileMenuConsumerPopupOpen] =
  useState(false);
  let {prof,loc}=useParams()
  prof = prof!='short-order cook'?prof.replace(/-/g, ' ').trim(): prof.trim();
  loc = loc.replace(/-/g, ' ').trim();
  const [img1,setImg1]=useState('')
  const [img2,setImg2]=useState('')
  const [img3,setImg3]=useState('')
  const [img4,setImg4]=useState('')
  const [img5,setImg5]=useState('')
  const [link1,setLink1]=useState('')
  const [link2,setLink2]=useState('')
  const [link3,setLink3]=useState('')
  const [address,setAddress]=useState('')
  const [error,setError]=useState('')
  const navigate = useNavigate()
  const openMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(true);
  }, []);

  const closeMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(false);
  }, []);

  function combineAndExport(locations, categories) {
    const combinedList = [];
  
    // Add header row with 'URL' and 'Lastmod' columns
    combinedList.push('URL,Lastmod');
  
    locations.forEach(location => {
      categories.forEach(category => {
        const combinedItem = `www.loggworks.co.uk/professionals/${category.replace(/ /g, '-')}/${location.replace(/ /g, '-')}`;
        // Add each combined URL along with current date as 'Lastmod'
        const currentDate = new Date().toISOString().split('T')[0];
        const row = `"${combinedItem}","${currentDate}"`;
        combinedList.push(row);
      });
    });
  
    const csvContent = combinedList.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
  
    // Create a link element and trigger a click to start the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'sitemap.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  

// Example usage:
const locationList = [
  'Aberdeen',
  'Basingstoke',
  'Bath',
  'Bedford',
  'Beverley',
  'Birmingham',
  'Blackburn',
  'Bolton',
  'Bournemouth',
  'Bradford',
  'Brighton & Hove',
  'Bristol',
  'Burnley',
  'Bury',
  'Cambridge',
  'Canterbury',
  'Cardiff',
  'Carlisle',
  'Chelmsford',
  'Cheltenham',
  'Chester',
  'Chichester',
  'Colchester',
  'Coventry',
  'Crawley',
  'Croydon',
  'Darlington',
  'Derby',
  'Doncaster',
  'Dudley',
  'Dundee',
  'Durham',
  'Edinburgh',
  'Ely',
  'Exeter',
  'Glasgow',
  'Gloucester',
  'Guildford',
  'Harrogate',
  'Hereford',
  'High Wycombe',
  'Huddersfield',
  'Inverness',
  'Ipswich',
  'Kingston upon Hull',
  'Lancaster',
  'Leeds',
  'Leicester',
  'Lichfield',
  'Lincoln',
  'Liverpool',
  'London',
  'Luton',
  'Maidstone',
  'Manchester',
  'Milton Keynes',
  'Newcastle upon Tyne',
  'Newport',
  'Northampton',
  'Norwich',
  'Nottingham',
  'Oldham',
  'Oxford',
  'Paisley',
  'Perth',
  'Peterborough',
  'Plymouth',
  'Portsmouth',
  'Reading',
  'Ripon',
  'Rochdale',
  'Salisbury',
  'Scarborough',
  'Sheffield',
  'Shrewsbury',
  'Slough',
  'Southampton',
  'Southend-on-Sea',
  'St Albans',
  'St Edmunds',
  'Stirling',
  'Stockport',
  'Stoke-on-Trent',
  'Sunderland',
  'Swansea',
  'Swindon',
  'Telford',
  'Walsall',
  'Watford',
  'Wells',
  'Winchester',
  'Wolverhampton',
  'Worcester',
  'York',
];
const categoryList = [
  'Wedding Caterer',
      'Corporate Event Caterer',
      'Private Party Caterer',
      'Cuisine-Specific Caterer',
      'Italian Food Caterer',
      'Indian Food Caterer',
      'Chinese Food Caterer',
      'African Food Caterer',
      'Vegetarian and Vegan Caterer',
      'Gluten-Free Caterer',
      'Buffet Caterer',
      'Sit-down Meal Caterer',
      'Mobile Caterer',
      'Wine Caterer',
      'Mobile Bar Services',
      'Gourmet caterer',
      'filipino caterer',
      'Halal and Kosher Caterer',
      'Catering Equipment Rental',
      'Furniture and Decor Rentals for Events',
      'Linen and Tableware Rentals',
      'Mexican food caterer',
      'Sushi Caterer','Catering',
      'Nursery School Teachers',
      'Preschool Teachers',
      'Montessori Teachers',
      'Special Education Teachers for Early Years',
      'Childcare Providers:',
      'Registered Childminders',
      'Au Pairs',
      'After-School Care Providers',
      'Child Psychologists',
      'Speech and Language Therapists',
      'Occupational Therapists for Children',
      'Early Childhood Development Consultants',
      'Pediatric Nurses',
      'Childcare Health Consultants',
      'First Aid Professional',
      "Children's Music Teachers",
      'Art Teachers',
      'Swimming Coach',
      'Gymnastics Coach',
      'Tennis Coach',
      'Dance Instructors',
      'Parenting Coaches',
      'Childcare Advisors',
      'Lactation Consultants',
      'Family Therapists',
      'Inclusion Coordinators',
      'Special Needs Childcare Workers',
      'Behavioral Therapists for Children',
      'Sign Language Interpreter',
      'Chess Coach','Childcare',
      'Carpet and Upholstery Cleaners',
      'Window Cleaners',
      'Pressure Washers',
      'Office Cleaners',
      'Retail Space Cleaners',
      'Industrial Cleaners',
      'House Cleaners',
      'Apartment Cleaners',
      'Maid Services',
      'Move-Out Cleaners',
      'Move-in Cleaners',
      'Construction Site Cleaners','Cleaning',
      'Engagement Party Planners',
      'Birthday planner','Entertainment',
      'Whirlpool Repair Professionals', 
      'Samsung Repair Specialists',
      'LG Repair Experts',
      'General Electric (GE) Repair Technicians',
      'Frigidaire Repair Services',
      'Maytag Washing Machine Repair Experts',
      'Bosch Washing Machine Specialists',
      'Samsung Washing Machine Technicians',
      'LG Washing Machine Repair Professionals',
      'Whirlpool Washing Machine Repair Services',
      'GE Oven and Range Repair Specialists',
      'Samsung Oven and Range Repair Services',
      'LG Oven and Range Repair Technicians',
      'Bosch Oven and Range Repair Experts',
      'Whirlpool Oven and Range Repair Professionals',
      'Bosch Dishwasher Repair Experts',
      'KitchenAid Dishwasher Repair Specialists',
      'Samsung Dishwasher Repair Technicians',
      'GE Dishwasher Repair Services',
      'Whirlpool Dishwasher Repair Professionals',
      'Samsung Microwave Repair Specialists',
      'LG Microwave Repair Experts',
      'Panasonic Microwave Repair Technicians',
      'Whirlpool Microwave Repair Services',
      'GE Microwave Repair Professionals',
      'LG Air Conditioner Repair Experts',
      'Samsung Air Conditioner Repair Specialists',
      'Carrier Air Conditioner Repair Technicians',
      'Whirlpool Air Conditioner Repair Services',
      'GE Air Conditioner Repair Professionals',
      'Samsung Dryer Repair Specialists',
      'LG Dryer Repair Experts',
      'Whirlpool Dryer Repair Technicians',
      'Bosch Dryer Repair Services',
      'Maytag Dryer Repair Professionals','Appliance Repair',
      'Mobile Mechanic',
      'Ford Specialists',
      'Chevrolet Mechanics',
      'Dodge Experts',
      'Tesla Technicians',
      'BMW Specialists',
      'Mercedes-Benz Mechanics',
      'Audi Technicians',
      'Volkswagen Experts',
      'Volvo Specialists',
      'Land Rover and Jaguar Mechanics',
      'Toyota Specialists',
      'Honda Mechanics',
      'Nissan Technicians',
      'Subaru Experts',
      'Mazda Specialists',
      'Mitsubishi Mechanics',
      'Hyundai Specialists',
      'Kia Mechanics',
      'Porsche Technicians',
      'Ferrari Specialists',
      'Lamborghini Mechanics',
      'Maserati Experts',
      'Aston Martin Technicians',
      'Renault Specialists',
      'Peugeot Mechanics',
      'Fiat Technicians',
      'Skoda Experts',
      'Electric Vehicle Specialists',
      'Hybrid Vehicle Experts',
      'Classic Car Specialists',
      'Personal Assistance',
      'remote personal assistance',
      'household personal assistance',
      'Pet Groomers',
      'Dog Trainers', 
      'Dog Groomers', 
      'Canine Behavior Specialists',
      'Dog Walkers',
      'Veterinary Professionals',
      'Dog Boarding and Daycare Providers',
      'Cat Behavior Consultants',
      'Feline Groomers',
      'Cat Boarding and Sitting Services',
      'Avian Trainers',
      'Bird Care Specialists',
      'Bird Boarding and Sitting Services',
      'Goldfish specialist',
      'Rabbits specialist',
      'Guinea Pig specialist',
      'Hamsters Specialist',
      'Exotic Pet Specialists',
      'Equine Trainers',
      'Horse Groomer',
      'Recovery Services',
      'Car Recovery',
      'Removal Services',
      'ear wax removal',
      'tree removal',
      'furniture removal',
      'weed removal',
      'pest removal',
      'pet urine removal',
      'pigeon removal',
      'junk removal',
      'mould removal',
      'leaf removal',
      'lice removal',
      'large rock removal',
      'hazardous waste removal',
      'hoarding removal',
      'horse waste removal',
      'animal removal',
      'animal waste removal',
      'body removal',
      'odour removal',
      'Tradesmen',
      'Computer & Phone repair',
      'Apple Repair Technicians',
      'macOS Repair Specialist',
      'Microsoft Surface Repair Specialists',
      'Windows OS Expert',
      'Hardware and Software Repair',
      'Dell Certified Repair Technicians',
      'HP Laptop and Desktop Repair Experts',
      'HP Hardware and Software Expert',
      'Lenovo Certified Repair Technicians',
      'Lenovo Hardware and Software Experts',
      'Asus Device Repair Specialists',
      'Phone Repair Specialists',
      'iPhone Repair Experts',
      'Specialists in iOS Troubleshooting',
      'Apple Hardware Repair Technicians',
      'Samsung Galaxy Repair Specialists',
      'Samsung Hardware and Software Expert',
      'Google Pixel Repair Technicians',
      'Android OS Experts',
      'Huawei Phone Repair Experts',
      'Huawei Hardware and Software Experts',
      'OnePlus Phone Repair Specialists',
      'OnePlus Experts',
      'LG Phone Repair Technicians',
      'Dry Cleaning & Laundry',
      'Health, Wellness & Beauty',
      'Counselling',
      'Yoga and Pilates Instructors',
      'Mental Health Therapists and Counselors',
      'Chiropractors',
      'Physiotherapists',
      'Acupuncturists',
      'Homeopathic and Naturopathic Practitioners',
      'Massage Therapists',
      'Reflexologists',
      'Aromatherapists',
      'Reiki and Energy Healers',
      'Meditation and Mindfulness Coaches',
      'Herbalists',
      'Wellness Retreat Coordinators',
      'Hairstylists and Barbers',
      'Makeup Artists',
      'Estheticians (Skin Care Specialists)',
      'Nail Technicians',
      'Eyelash and Eyebrow Technicians',
      'Cosmetic Tattoo Artists',
      'Tanning Specialists',
      'Ayurvedic Consultants',
      'Osteopaths',
      'Hypnotherapists',
      'Cupping Therapists',
      'Gym Instructors',
      'Martial Arts Trainers',
      'Dance Instructors',
      'Sports Coaches',
      'Crystal Healing Practitioners',
      'Sound Therapists',
      'Shamanic Healers',
      'Tai Chi and Qigong Instructors',
      'Traditional Chinese Medicine Practitioners',
      'Ayurvedic Medicine Experts',
      'Homeopathy Specialists',
      'Naturopathic Doctors',
      'Dermatologists',
      'Aesthetic Medicine Professionals (e.g., Botox, Fillers)',
      'Trichologists (Hair and Scalp Specialists)',
      'Permanent Makeup Artists',
      'Spa Therapists',
      'Sauna Therapy Specialists',
      'Hydrotherapy Experts',
      'Zumba Instructors',
      'Crossfit Coaches',
      'Piloxing Instructors',
      'Aqua Fitness Instructors',
      'Doulas and Midwives',
      'Postnatal Fitness Coaches',
      'Lactation Consultants',
      'Pregnancy Yoga and Pilates Instructors',
      'Pediatric Nutritionists',
      'Child Psychologists',
      'Adolescent Yoga Instructors',
      'Geriatric Fitness Specialists',
      'Senior Yoga and Tai Chi Instructors',
      'Memory Care Specialists',
      'Makeup and Beauty Workshops',
      'Wellness and Mindfulness Workshops',
      'DIY Natural Beauty Product Classes',
      'Life Coaches',
      'Wellness Coaches',
      'Stress Management Coaches',
      'Tuition & Classes',
      'Mathematics Tutors',
      'Science Tutors',
      'English and Literature Experts',
      'History and Social Studies Tutors',
      'Foreign Language Tutors',
      'Exam Preparation Specialists',
      'Piano and Keyboard Instructors',
      'Guitar and String Instrument Experts',
      'Vocal and Singing Coaches',
      'Art Teachers (Painting, Drawing)',
      'Drama and Acting Tutors',
      'Dance Instructors (Ballet, Contemporary, Hip-Hop, etc.)',
      'Coding and Programming Experts',
      'Graphic Design Instructors',
      'Digital Marketing Educators',
      'Web Development Tutors',
      'Tutors for Dyslexia and Learning Disabilities',
      'Autism Spectrum Disorder Educators',
      'Speech and Language Therapists',
      'Preschool Education Experts',
      'Early Reading and Math Instructors',
      'Montessori and Waldorf Educators',
      'Adult Language Teachers',
      'Continuing Education Instructors',
      'Career Development and Skills Trainers',
      'Personal Training and Fitness Coaches',
      'Nutrition and Health Educators',
      'Yoga and Wellness Teachers',
      'Culinary and Cooking Instructors',
      'Photography Tutors',
      'Gardening and Horticulture Educators',
      'Craft and DIY Experts',
      'Healthcare Training Professionals',
      'Legal and Paralegal Studies Instructors',
      'Ecology and Conservation Educators',
      'Sustainable Living Instructors',
      'Renewable Energy Experts',
      'Algebra Tutors',
      'Calculus Tutors',
      'Geometry Tutors',
      'Trigonometry Tutors',
      'Statistics Tutors',
      'Physics Tutors',
      'Chemistry Tutors',
      'Biology Tutors',
      'Earth Science Tutors',
      'Environmental Science Tutors',
      'English Grammar and Composition Tutors',
      'Literature Analysis Tutors',
      'Creative Writing Tutors',
      'English as a Second Language (ESL) Tutors',
      'Reading Comprehension Specialists',
      'World History Tutors',
      'American/European/Asian History Tutors',
      'Geography Tutors',
      'Government and Politics Tutors',
      'Economics Tutors',
      'French Language Tutors',
      'Spanish Language Tutors',
      'Mandarin Chinese Tutors',
      'German Language Tutors',
      'Italian Language Tutors',
      'Arabic Language Tutors',
      'Japanese Language Tutors',
      'Russian Language Tutors',
      'SAT Prep Tutors',
      'GCSE Prep Tutors',
      'A-Levels Prep Tutors',
      'ACT Prep Tutors',
      'Advanced Placement (AP) Exam Tutors',
      'International Baccalaureate (IB) Tutors',
      'Tradesmen & Construction',
      'Construction',
      'gardening & landscaping',
      'gardener',
      'painter',
      'decorating',
      'plumber',
      'handyman',
      'electrician',
      'builder',
      'plasterers',
      'architect',
      'carpentry and joiners',
      'groundworkers',
      'roofer',
      'tiler',
      'paving and driveway',
      'flooring',
      'locksmiths',
      'interior designers',
      'Landscaper',
      'Carpenter',
      'Plasterer',
      'Driveways/patio',
      'fencing',
      'boiler repair',
      'Tree Surgeons',
      'Carpet Repairs',
      'taxi', 
      'bus and coach hire', 
      'van and truck hire', 
      'chauffeur and limousine hire',
      'car hire',
];



  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
  
          // Replace 'YOUR_GOOGLE_API_KEY' with your actual Google API key
          const apiKey = 'AIzaSyDrIc0MDLHaoFOaSLFKPRcGG-bM3Xpt-w0';
          const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
  
          try {
            const response = await fetch(apiUrl);
            const data = await response.json();
  
            if (data.status === 'OK' && data.results && data.results.length > 0) {
              const formattedAddress = data.results[0].formatted_address;
              setAddress(formattedAddress);
            } else {
              setError('Address not found');
            }
          } catch (error) {
            console.log(error)
            setError('Error fetching address');
          }
        },
        (error) => {
          setError(`Geolocation error: ${error.message}`);
        }
      );
    } else {
      setError('Geolocation is not supported by your browser');
    }
    console.log(error)
  };
  const categories = [
    {
      title: "Catering",
      options: 
      [
      "/find/caterer_01_4x.webp", 
      "/find/caterer_02_4x.webp", 
      "/find/caterer_03_4x.webp", 
      "/find/caterer_04_4x.webp",
      "/find/caterer_5.webp"
    ],
    links:[
      '/kitchen porter/'+loc,
      '/caterer/'+loc,
      '/short-order porter/'+loc,
    ]
    },
    {
      title: "Childcare",
      options: [
      "/find/childcare_01_4x.webp", 
      "/find/childcare_02_4x.webp", 
      "/find/childcare_03_4x.webp", 
      "/find/childcare_04_4x.webp",
      "/find/childcare_5.webp"
      ],
      links:[
        '/babysitters/'+loc,
        '/virtual nannies/'+loc,
        '/childminders/'+loc,
      ]
    },
    {
      title: "Cleaning",
      options: [
        "/find/cleaner_01_4x.webp", 
        "/find/cleaner_02_4x.webp", 
        "/find/cleaner_03_4x.webp", 
        "/find/cleaner_04_4x.webp",
        "/find/cleaner_5.webp"
      ],
      links:[
        '/domestic cleaners/'+loc,
        '/property maintainance/'+loc,
        '/window cleaners/'+loc,
      ]
    },
    {
      title: "Entertainment",
      options: [
        "/find/events_01_4x.webp", 
        "/find/events_02_4x.webp", 
        "/find/events_03_4x.webp", 
        "/find/events_04_4x.webp",
        "/find/event_5.webp"
      ],
      links:[
        '/event planner/'+loc,
        '/dj and disco hire/'+loc,
        '/photography and film/'+loc,
      ]
    },
    {
      title: "Appliance Repair",
      options: [
        "/find/appl_01_4x.webp", 
        "/find/appl_02_4x.webp", 
        "/find/appl_03_4x.webp", 
        "/find/appl_04_4x.webp",
        "/find/appl_5.webp"
      ],
      links:[
        '/appliance repairs/'+loc,
        '/appliance repairs/'+loc,
        '/domestic appliance repairs/'+loc,
      ]
    },
    {
      title: "Mobile Mechanic",
      options: [
        "/find/mobilemec_01_4x.webp", 
        "/find/mobilemec_02_4x.webp", 
        "/find/mobilemec_03_4x.webp", 
        "/find/mobilemec_04_4x.webp",
        "/find/mobile_5.webp"
      ],
      links:[
        '/mobile mechanic/'+loc,
        '/mobile mechanic/'+loc,
        '/mobile mechanic/'+loc,
      ]
    },
    {
      title: "Personal Assistance",
      options: [
        "/find/passist_01_4x.webp", 
        "/find/passist_02_4x.webp", 
        "/find/passist_03_4x.webp", 
        "/find/passist_04_4x.webp",
        "/find/passist_5.webp"
      ],
      links:[
        '/remote personal assistance/'+loc,
        '/remote personal assistance/'+loc,
        '/household personal assistance/'+loc,
      ]
    },
    {
      title: "Pet Groomers",
      options: [
        "/find/pet_01_4x.webp", 
      "/find/pet_02_4x.webp", 
      "/find/pet_03_4x.webp", 
      "/find/pet_04_4x.webp",
      "/find/pet_5.webp"
      ],
      links:[
        '/pet grooming/'+loc,
        '/pet training/'+loc,
        '/dog walker/'+loc,
      ]
    },
    {
      title: "Recovery Services",
      options: [
        "/find/recovery_01_4x.webp", 
        "/find/recovery_02_4x.webp", 
        "/find/recovery_03_4x.webp", 
        "/find/recovery_04_4x.webp",
        "/find/recov_5.webp"
      ],
      links:[
        '/recovery/'+loc,
        '/recovery/'+loc,
        '/recovery/'+loc,
      ]
    },
    {
      title: "Computer & Phone repair",
      options: [
        "/find/comp_01_4x.webp", 
        "/find/comp_02_4x.webp", 
        "/find/comp_03_4x.webp", 
        "/find/comp_04_4x.webp",
        "/find/comp_5.webp"
      ],
      links:[
        '/phone repair/'+loc,
        '/computer repair/'+loc,
        '/computer and phone repair/'+loc,
      ]
    },
    {
      title: "Removal Services",
      options: [
        "/find/removal_01_4x.webp", 
      "/find/removal_02_4x.webp", 
      "/find/removal_03_4x.webp", 
      "/find/removal_04_4x.webp",
      "/find/remov_5.webp"
      ],
      links:[
        '/removal/'+loc,
        '/commercial removals/'+loc,
        '/domestic removals/'+loc,
      ]
    },
    {
      title: "Tradesmen",
      options: [
        "/find/plumber_01_4x.webp", 
      "/find/plumber_02_4x.webp", 
      "/find/gard_03_4x.webp", 
      "/find/plumber_04_4x.webp",
      "/find/trades_5.webp"
      ],
      links:[
        '/plumber/'+loc,
        '/plumber/'+loc,
        '/gardener/'+loc,
      ]
    },
    {
      title: "Transport Services",
      options: [
        "/find/trans_01_4x.webp", 
      "/find/trans_02_4x.webp", 
      "/find/trans_03_4x.webp", 
      "/find/trans_04_4x.webp",
      "/find/trans_5.webp"
      ],
      links:[
        '/car hire/'+loc,
        '/chauffeur and limousine hire/'+loc,
        '/van and truck hire/'+loc,
      ]
    },
    {
      title: "Dry Cleaning & Laundry",
      options: [
        "/find/dryclean_01_4x.webp", 
        "/find/dryclean_02_4x.webp", 
        "/find/dryclean_03_4x.webp", 
        "/find/dryclean_04_4x.webp",
        "/find/dryclean_5.webp"
      ],
      links:[
        '/dry cleaner/'+loc,
        '/dry cleaner/'+loc,
        '/dry cleaner/'+loc,
      ]
    },
    {
      title: "Health, Wellness & Beauty",
      options: [
        "/find/health_01_4x.webp", 
        "/find/health_02_4x.webp", 
        "/find/health_03_4x.webp", 
        "/find/health_04_4x.webp",
        "/find/health_5.webp"
      ],
      links:[
        '/therapist/'+loc,
        '/personal trainers/'+loc,
        '/nutritionist and dieticians/'+loc,
      ]
    },
    {
      title: "Tuition & Classes",
      options: [
        "/find/tuition_01_4x.webp", 
      "/find/tuition_02_4x.webp", 
      "/find/tuition_03_4x.webp", 
      "/find/tuition_04_4x.webp",
      "/find/tuition_5.webp"
      ],
      links:[
        '/academic tutor/'+loc,
        '/music/'+loc,
        '/driving lessons and instructors/'+loc,
      ]
    },
  ];
  useEffect(() => {
    //combineAndExport(locationList, categoryList);
    getCurrentLocation();
    const usersRef = db.collection('categories');
    const tagRef = db.collection('serviceTags');
  
    const querySnapshot = usersRef.get();
    const tagSnapshot = tagRef.get();
  
    querySnapshot.then(function (userSnap) {
      userSnap.forEach(function (doc) {
        const data = doc.data();
        const id = doc.id;
  
        if (id.toLowerCase() === prof.toLowerCase()) {
          categories.map((x)=>{
            if (x.title.toLowerCase()==id.toLowerCase()) {
              setImg1(x.options[0])
              setImg2(x.options[1])
              setImg3(x.options[2])
              setImg4(x.options[3])
              setImg5(x.options[4])
              setLink1(x.links[0])
              setLink2(x.links[1])
              setLink3(x.links[2])
            }
          })
        } else if(data.subcategories.includes(prof)) {
          categories.map((x)=>{
            if (x.title.toLowerCase()==id.toLowerCase()) {
              setImg1(x.options[0])
              setImg2(x.options[1])
              setImg3(x.options[2])
              setImg4(x.options[3])
              setImg5(x.options[4])
              setLink1(x.links[0])
              setLink2(x.links[1])
              setLink3(x.links[2])
            }
          })
        }
        else{
          tagSnapshot.then(function (userSnap) {
            userSnap.forEach(function (doc) {
              const data = doc.data();
              const id = doc.id;
        
              if (id.toLowerCase() === prof.toLowerCase()) {
                categories.map((x)=>{
                  if (x.title.toLowerCase()==id.toLowerCase()) {
                    setImg1(x.options[0])
                    setImg2(x.options[1])
                    setImg3(x.options[2])
                    setImg4(x.options[3])
                    setImg5(x.options[4])
                    setLink1(x.links[0])
              setLink2(x.links[1])
              setLink3(x.links[2])
                  }
                })
              } else if(data.subcategories.includes(prof)) {
                categories.map((x)=>{
                  if (x.title.toLowerCase()==id.toLowerCase()) {
                    setImg1(x.options[0])
                    setImg2(x.options[1])
                    setImg3(x.options[2])
                    setImg4(x.options[3])
                    setImg5(x.options[4])
                    setLink1(x.links[0])
              setLink2(x.links[1])
              setLink3(x.links[2])
                  }
                })
              }
            });
          });
        }
      });
    });
  }, [])
  const handleSubmit = (e) => {
    e.preventDefault();
    const usersRef = db.collection('categories');
    const tagRef = db.collection('serviceTags');
  
    const querySnapshot = usersRef.get();
    const tagSnapshot = tagRef.get();
  
    querySnapshot.then(function (userSnap) {
      userSnap.forEach(function (doc) {
        const data = doc.data();
        const id = doc.id;
  
        if (id.toLowerCase() === prof.toLowerCase()) {
          if (!address) {
            getCurrentLocation();
            return;
          }
          window.location.href = '/search/' + address + '/' + id + '/all';
        } else if(data.subcategories.includes(prof)) {
          if (!address) {
            getCurrentLocation();
            return;
          }
          window.location.href = '/search/' + address + '/' + id + '/' + prof;
        }
        else{
          tagSnapshot.then(function (userSnap) {
            userSnap.forEach(function (doc) {
              const data = doc.data();
              const id = doc.id;
        
              if (id.toLowerCase() === prof.toLowerCase()) {
                if (!address) {
                  getCurrentLocation();
                  return;
                }
                window.location.href = '/search/' + address + '/' + id + '/all';
              } else if(data.subcategories.includes(prof)) {
                if (!address) {
                  getCurrentLocation();
                  return;
                }
                window.location.href = '/search/' + address + '/' + id + '/' + prof;
              }
            });
          });
        }
      });
    });
  };
  return (
    <div className={styles.custfinding}>
     
                {
                  prof.toLowerCase()=='appliance repair'?
                   <Helmet>
                <meta charSet="utf-8" /> 
                  <title>{prof} Services in {loc} | LoggWorks</title>
                  <meta name="description" 
                  content={`LoggWorks offers expert appliance repair services in ${loc}. Trust our skilled technicians to fix your appliances efficiently and affordably. Contact us today.`}/>
                  </Helmet>
                  :
                  prof.toLowerCase()=='aromatherapy'?
                  <Helmet>
               <meta charSet="utf-8" /> 
                 <title>Aromatherapy Practitioners in {loc} | LoggWorks</title>
                 <meta name="description" 
                 content={`Explore premium aromatherapy practitioners in ${loc} at LoggWorks. Our skilled professionals offer customized aromatherapy sessions for relaxation and well-being.`}/>
                 </Helmet>
                  :
                  prof.toLowerCase()=='childcare'?
                  <Helmet>
               <meta charSet="utf-8" /> 
                 <title>Aromatherapy Practitioners in {loc} | LoggWorks</title>
                 <meta name="description" 
                 content={`Explore premium aromatherapy practitioners in ${loc} at LoggWorks. Our skilled professionals offer customized aromatherapy sessions for relaxation and well-being.`}/>
                 </Helmet>
                 :
                 prof.toLowerCase()=='chinese food caterer'?
                 <Helmet>
              <meta charSet="utf-8" /> 
                <title>Find Chinese Food Caterer Jobs In {loc}</title>
                <meta name="description" 
                content={`Indulge in authentic Chinese cuisine with LoggWorks' catering services in ${loc}. Experience exquisite flavors and impeccable service for your event.`}/>
                </Helmet>
                :
                prof.toLowerCase()=='crossfit coaches'?
                 <Helmet>
              <meta charSet="utf-8" /> 
                <title>Find Crossfit Coaches Jobs In {loc}</title>
                <meta name="description" 
                content={`Find expert Crossfit coaches in ${loc} at LoggWorks. Our professionals offer personalized training to help you reach your fitness goals. Contact us today.`}/>
                </Helmet>
                :
                prof.toLowerCase()=='cuisine specific caterers'?
                 <Helmet>
              <meta charSet="utf-8" /> 
                <title>{loc} Cuisine-specific Caterers Services | Loggworks </title>
                <meta name="description" 
                content={`Explore cuisine-specific catering services in ${loc} with LoggWorks. From exquisite menus to impeccable service, we cater to your culinary needs. Contact us today.`}/>
                </Helmet>
                 :
                 prof.toLowerCase()=='handyman'?
                  <Helmet>
               <meta charSet="utf-8" /> 
                 <title>{loc} Handyman Professional Services | Loggworks </title>
                 <meta name="description" 
                 content={`Get professional handyman services in ${loc} with LoggWorks. Our skilled team provides reliable solutions for all your home repair and maintenance needs.`}/>
                 </Helmet>
                :
                prof.toLowerCase()=='indian food caterer'?
                  <Helmet>
               <meta charSet="utf-8" /> 
                 <title>Find Indian Food Caterer Jobs In {loc} </title>
                 <meta name="description" 
                 content={`Savor the authentic flavors of Indian cuisine with LoggWorks' catering services in ${loc}. Experience exquisite dishes and exceptional service for your event.`}/>
                 </Helmet>
                 :
                 prof.toLowerCase()=='microsoft surface repair specialists'?
                   <Helmet>
                <meta charSet="utf-8" /> 
                  <title>Microsoft Surface Repair Specialists in {loc} | Loggworks </title>
                  <meta name="description" 
                  content={`Trust LoggWorks for Microsoft Surface repair specialists in ${loc}. Our skilled technicians ensure reliable solutions for your device needs. Contact us today.`}/>
                  </Helmet>
                  :
                  prof.toLowerCase()=='paving and driveway'?
                    <Helmet>
                 <meta charSet="utf-8" /> 
                   <title>Find paving and driveway Jobs In {loc} </title>
                   <meta name="description" 
                   content={`Ensure your property with LoggWorks' paving and driveway services in ${loc}. Our expert team delivers quality installations for lasting curb appeal.`}/>
                   </Helmet>
                    :
                    prof.toLowerCase()=='tradesmen & construction'?
                      <Helmet>
                   <meta charSet="utf-8" /> 
                     <title>Find Tradesmen & Construction Jobs In {loc} </title>
                     <meta name="description" 
                     content={`Find reliable tradesmen and construction services in ${loc} with LoggWorks. Our skilled professionals deliver quality workmanship for your projects.`}/>
                     </Helmet>
                      :
                      prof.toLowerCase()=='window cleaners'?
                        <Helmet>
                     <meta charSet="utf-8" /> 
                       <title>{loc} Window Cleaning Services | Loggworks </title>
                       <meta name="description" 
                       content={`Experience top-notch window cleaning services in ${loc} with LoggWorks. Our professional team ensures sparkling results for your property. Contact us today.`}/>
                       </Helmet>
                       :
                       prof.toLowerCase()=='african food caterer'?
                         <Helmet>
                      <meta charSet="utf-8" /> 
                        <title>African Food Catering Services in {loc} | Loggworks </title>
                        <meta name="description" 
                        content={`Indulge in the vibrant flavors of African cuisine with LoggWorks' catering services in ${loc}. Experience authentic dishes and exceptional service for your event.`}/>
                        </Helmet>
                         :
                         prof.toLowerCase()=='carpet and upholstery cleaners'?
                           <Helmet>
                        <meta charSet="utf-8" /> 
                          <title>Carpet Upholstery Cleaners in {loc} | Loggworks</title>
                          <meta name="description" 
                          content={`Refresh your carpets and upholstery in ${loc} with LoggWorks. Our professional cleaners deliver exceptional service for a spotless home. Contact us today.`}/>
                          </Helmet>
                          :
                          prof.toLowerCase()=='cleaning'?
                            <Helmet>
                         <meta charSet="utf-8" /> 
                           <title>{loc} Cleaning Services Professional Cleaners | LoggWorks</title>
                           <meta name="description" 
                           content={`Ensure your space with professional cleaning services in ${loc} from LoggWorks. Our dedicated cleaners ensure a pristine environment. Contact us today.`}/>
                           </Helmet>
                            :
                            prof.toLowerCase()=='corporate event caterer'?
                              <Helmet>
                           <meta charSet="utf-8" /> 
                             <title>{loc} Corporate Event Catering Services | Loggworks</title>
                             <meta name="description" 
                             content={`Ensure your corporate events in ${loc} with LoggWorks' catering services. Experience exceptional cuisine and service tailored to your needs. Contact us today!`}/>
                             </Helmet>
                              :
                              prof.toLowerCase()=='wedding caterer'?
                                <Helmet>
                             <meta charSet="utf-8" /> 
                               <title>{loc} Wedding Caterer Memorable Cuisine | Loggworks</title>
                               <meta name="description" 
                               content={`Experience your wedding with LoggWorks' catering services in ${loc}. Experience unforgettable cuisine and impeccable service for your special day.`}/>
                               </Helmet>
                :
                  <Helmet>
                    <meta charSet="utf-8" /> 
                   <title>Find {prof} Jobs In {loc}</title>
                <meta name="description" 
                content={`Find the best, vetted ${prof} in ${loc} with LoggWorks. Our platform ensures expertise, reliability, and competitive pricing. Experience safe, secure, and quality ${prof} services in ${loc}. Elevate your standards with us.`}/>
                  </Helmet>
                 }
                
      <div className={styles.findingpage}>
        <div className={styles.frameParent}>
        <img className={styles.frameChild} alt="" 
        onClick={()=>navigate('/')}
        style={{cursor:'pointer'}}
        src="/frame-66522.svg" />
          <LocationSearch/>
          <div className={styles.frameDiv}>
            <div className={styles.professionalParent}
            style={{cursor:'pointer'}}
            onClick={()=>{
              navigate('/professionals/'+prof+'/'+loc)
            }}>
              <div className={styles.professional}>Professional</div>
              <div className={styles.selector} />
            </div>
            <div className={styles.consumerParent}
            style={{cursor:'pointer'}}
            onClick={()=>{
            }}>
              <b className={styles.consumer}>Consumer</b>
              <div className={styles.selector1} />
            </div>
          </div>
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.frameContainer}>
            <div className={styles.groupParent1}>
              <div className={styles.findTheBestCaterersInLewiParent}>
                <h1>
                  <b className={styles.findTheBestContainer}>
                  <p className={styles.findTheBest}>Find the best</p>
                  <p className={styles.findTheBest}>
                    <span>{`${prof} in `}</span>
                    <span className={styles.lewisham}>{loc}</span>
                  </p>
                </b>
                </h1>
                
                <img className={styles.groupInner} alt="" src="/vector-4.svg" />
              </div>
              <div className={styles.frameParent1}>
                <div className={styles.frameParent2}>
                  <div className={styles.checksquareParent}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                      100% cost-free job postings
                    </div>
                  </div>
                  <div className={styles.checksquareParent}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                      Get Multiple Quotes, Free of Charge
                    </div>
                  </div>
                </div>
                <div className={styles.frameParent2}>
                  <div className={styles.checksquareParent}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                      Payment and Privacy Protection
                    </div>
                  </div>
                  <div className={styles.checksquareParent}>
                    <img
                      className={styles.checksquareIcon}
                      alt=""
                      src="/checksquare.svg"
                    />
                    <div className={styles.costFreeJobPostings}>
                      Vetted and reliable Professionals
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className={styles.largeButtons}
            onClick={()=>window.location.href='https://www.loggworks.co.uk/postjob'}>
              <img className={styles.mappinIcon} alt="" src="/iconsadd3.svg" />
              <b className={styles.label}>{`Post a Job For Free `}</b>
              <img className={styles.iconsclose} alt="" src="/iconsadd4.svg" />
            </button>
          </div>
          <div className={styles.groupParent2}>
            <div className={styles.rectangleContainer}>
              <img
                className={styles.rectangleIcon}
                alt=""
                src={img1}
                style={{cursor:'pointer'}}
                onClick={()=>{
                  navigate('/consumers'+link1)
                }}
              />
              
            </div>
            <div className={styles.rectangleParent1}>
              <img
                className={styles.frameChild1}
                alt=""
                src={img2}
                style={{cursor:'pointer'}}
                onClick={()=>{
                  navigate('/consumers'+link2)
                }}
              />
            </div>
            <div className={styles.rectangleContainer}>
              <img
                className={styles.rectangleIcon}
                alt=""
                src={img3}
                style={{cursor:'pointer'}}
                onClick={()=>{
                  navigate('/consumers'+link3)
                }}
              />
              
            </div>
          </div>
        </div>
        <div className={styles.frameParent4}>
          <div className={styles.frameParent5}>
            <div className={styles.ellipseParent}>
              <img className={styles.vectorIcon} alt="" src="/vector.svg" />
              <div className={styles.kitchenOrganisation}>HOW IT WORKS</div>
            </div>
            <div className={styles.enjoyTheResultsContainer}>
              <p className={styles.findTheBest}>enjoy the results</p>
              <p className={styles.findTheBest}>of the expert work</p>
              <p className={styles.findTheBest}>with LoggWorks</p>
            </div>
          </div>
          <div className={styles.frameParent6}>
            <div className={styles.professionalCaterersHelpInParent}>
              <div className={styles.rectangleParent2}>
                <div className={styles.rectangleDiv} />
                <img
                  className={styles.frame678931}
                  alt=""
                  src="/frame-67893-1@2x.png"
                />
              </div>
              <div className={styles.professionalCaterersHelpInParent}>
                <div className={styles.postAJobOrSearchForNearbParent}>
                  <div className={styles.postAJob}>
                    Post a job or search for nearby service provider.
                  </div>
                  <div className={styles.recieveAPersonalized}>
                    Post a job or Search for reliable home service professionals
                    in your area. Enter your location and service needed, then
                    filter results by miles, sub category, and ratings.
                  </div>
                </div>
                <div className={styles.largeButtons1}>
                  <img
                    className={styles.mappinIcon}
                    alt=""
                    src="/iconsadd3.svg"
                  />
                  <b className={styles.label}>Post your Job For Free</b>
                  <img
                    className={styles.iconsclose}
                    alt=""
                    src="/iconsadd4.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent4}>
              <img
                className={styles.groupIcon}
                alt=""
                src="/group-67104@2x.png"
              />
              <div className={styles.professionalCaterersHelpInParent}>
                <div className={styles.postAJobOrSearchForNearbParent}>
                  <div className={styles.postAJob}>
                    Get a personalized quotation for the required home service.
                  </div>
                  <div className={styles.recieveAPersonalized}>
                    Recieve a personalized quote from your chosen provider.
                    Compare prices and choose the best option for your budget
                    and timeline.
                  </div>
                </div>
                <div className={styles.largeButtons1}>
                  <img
                    className={styles.mappinIcon}
                    alt=""
                    src="/iconsadd3.svg"
                  />
                  <b className={styles.label}>Post your Job For Free</b>
                  <img
                    className={styles.iconsclose}
                    alt=""
                    src="/iconsadd4.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent5}>
              <div className={styles.rectangleParent2}>
                <div className={styles.rectangleDiv} />
                <div className={styles.review1Parent}>
                  <img
                    className={styles.review1Icon}
                    alt=""
                    src="/review-1@2x.png"
                  />
                  <div className={styles.notif}>
                    <div className={styles.notifChild} />
                    <div className={styles.jobMarkedAsCompletedParent}>
                      <div className={styles.jobMarkedAs}>
                        Job marked as completed
                      </div>
                      <img
                        className={styles.fluentcheckmarkStarburst16Icon}
                        alt=""
                        src="/fluentcheckmarkstarburst16filled.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameParent9}>
                <div className={styles.postAJobOrSearchForNearbParent}>
                  <div className={styles.postAJob}>
                    Rate and review your completed bookings.
                  </div>
                  <div className={styles.recieveAPersonalized}>
                    Help us ensure the quality of our platform and assist other
                    users in making informed decisions by rating and reviewing
                    your provider. Earn rewards for every review submitted.
                  </div>
                </div>
                <div className={styles.largeButtons1}>
                  <img
                    className={styles.mappinIcon}
                    alt=""
                    src="/iconsadd3.svg"
                  />
                  <b className={styles.label}>Post your Job For Free</b>
                  <img
                    className={styles.iconsclose}
                    alt=""
                    src="/iconsadd4.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent6}>
              <div className={styles.rectangleParent2}>
                <div className={styles.rectangleDiv} />
                <div className={styles.referABusinessAndEarn200Parent}>
                  <div className={styles.referABusiness}>
                    Refer a business and earn 200 points
                  </div>
                  <div className={styles.sendThemYourUniqueReferralParent}>
                    <div className={styles.sendThemYour}>
                      Send them your unique referral link
                    </div>
                    <div className={styles.youGet200}>
                      You get 200 Points when they get their first boooking
                    </div>
                    <img
                      className={styles.feathercheckCircleIcon}
                      alt=""
                      src="/feathercheckcircle.svg"
                    />
                    <img
                      className={styles.feathercheckCircleIcon1}
                      alt=""
                      src="/feathercheckcircle1.svg"
                    />
                  </div>
                  <div className={styles.rectangleParent5}>
                    <div className={styles.groupChild4} />
                    <b className={styles.abc123g}>ABC123G</b>
                    <div className={styles.yourReferralCode}>
                      Your referral code
                    </div>
                    <b className={styles.copyCode}>
                      <p className={styles.findTheBest}>Copy</p>
                      <p className={styles.findTheBest}>Code</p>
                    </b>
                    <div className={styles.lineDiv} />
                  </div>
                  <div className={styles.shareLinkReferralViaParent}>
                    <div className={styles.shareLinkReferral}>
                      Share link referral via
                    </div>
                    <div className={styles.instagram1Parent}>
                      <img
                        className={styles.instagram1Icon}
                        alt=""
                        src="/011instagram-1.svg"
                      />
                      <img
                        className={styles.whatsapp1Icon}
                        alt=""
                        src="/003whatsapp-1.svg"
                      />
                      <img
                        className={styles.facebook1Icon}
                        alt=""
                        src="/001facebook-1.svg"
                      />
                      <img
                        className={styles.twitter1Icon}
                        alt=""
                        src="/013twitter-1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameParent9}>
                <div className={styles.postAJobOrSearchForNearbParent}>
                  <div className={styles.postAJob}>
                    Earn cashbacks and rewards.
                  </div>
                  <div className={styles.recieveAPersonalized}>
                    Enjoy our loyalty rewards program, including cashback on
                    every booking, referral bonuses, and much more.
                  </div>
                </div>
                <div className={styles.largeButtons1}>
                  <img
                    className={styles.mappinIcon}
                    alt=""
                    src="/iconsadd3.svg"
                  />
                  <b className={styles.label}>Post your Job For Free</b>
                  <img
                    className={styles.iconsclose}
                    alt=""
                    src="/iconsadd4.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.steps} />
        </div>
        <div className={styles.frameParent11}>
          <div className={styles.professionalCaterersHelpInParent}>
            <div className={styles.professionalCaterersHelpContainer}>
              <p className={styles.findTheBest}>Professional</p>
              <p className={styles.findTheBest}>
                <span className={styles.lewisham}>{`${prof} `}</span>
                <span>help in</span>
              </p>
              <p className={styles.findTheBest}>your neighbourhood</p>
            </div>
            <div className={styles.yourSearchFor}>
              Your search for the best Professional service is now complete!
              Customers worldwide entrust our skilled professionals with a wide
              array of home projects, both minor and significant.
            </div>
          </div>
          <div className={styles.iconBenefits}>
            <div className={styles.frameParent12}>
              <div className={styles.frameParent13}>
                <div className={styles.identificationcardParent}>
                  <img
                    className={styles.identificationcardIcon}
                    alt=""
                    src="/identificationcard.svg"
                  />
                  <div className={styles.rigorousBackgroundChecksContainer}>
                    <p className={styles.findTheBest}>Rigorous</p>
                    <p className={styles.findTheBest}>Background Checks</p>
                  </div>
                </div>
                <div className={styles.ensuringYourSafety}>
                  Ensuring your safety and peace of mind, each of our experts
                  undergoes a thorough background verification.
                </div>
              </div>
              <div className={styles.frameParent13}>
                <div className={styles.identificationcardParent}>
                  <img
                    className={styles.identificationcardIcon}
                    alt=""
                    src="/smiley.svg"
                  />
                  <div className={styles.rigorousBackgroundChecksContainer}>
                    <p className={styles.findTheBest}>Committed to</p>
                    <p className={styles.findTheBest}>Your Satisfaction</p>
                  </div>
                </div>
                <div className={styles.ensuringYourSafety}>
                  Our Happiness Guarantee pledges a steadfast commitment to your
                  contentment with our services.
                </div>
              </div>
            </div>
            <div className={styles.frameParent12}>
              <div className={styles.frameParent13}>
                <div className={styles.identificationcardParent}>
                  <img
                    className={styles.identificationcardIcon}
                    alt=""
                    src="/info.svg"
                  />
                  <div className={styles.rigorousBackgroundChecksContainer}>
                    <p className={styles.findTheBest}>Efficient and</p>
                    <p className={styles.findTheBest}>Courteous Support</p>
                  </div>
                </div>
                <div className={styles.ensuringYourSafety}>
                  Experience prompt and amiable customer assistance, dedicated
                  to addressing your needs swiftly and effectively.
                </div>
              </div>
              <div className={styles.frameParent13}>
                <div className={styles.identificationcardParent}>
                  <img
                    className={styles.identificationcardIcon}
                    alt=""
                    src="/lock.svg"
                  />
                  <div className={styles.rigorousBackgroundChecksContainer}>
                    Payment and Privacy Protection
                  </div>
                </div>
                <div
                  className={styles.ensuringYourSafety}
                >{`You’ll never have to share your personal information with tradesmen or home service professionals on Loggworks. `}</div>
              </div>
            </div>
            <div className={styles.frameParent13}>
              <div className={styles.identificationcardParent}>
                <img
                  className={styles.identificationcardIcon}
                  alt=""
                  src="/coins.svg"
                />
                <div className={styles.rigorousBackgroundChecksContainer}>
                  Pricing Flexibility
                </div>
              </div>
              <div className={styles.ensuringYourSafety}>
                We allow you choose your price because you get multiple quotes
                from service providers at no cost.
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameParent19}>
          <div className={styles.findMoreCaterersNearYouParent}>
            <div className={styles.findMoreCaterersContainer}>
              <span>{`Find More `}</span>
              <span className={styles.lewisham}>{prof}</span>
              <span> Near You</span>
            </div>
            <div className={styles.frameParent20}>
             <img
             src={img4}
             style={{width:'90%',height:'100%'}}
             />
            </div>
          </div>
          <button className={styles.largeButtons5}
          onClick={handleSubmit}>
            <img
              className={styles.mappinIcon}
              alt=""
              src="/magnifyingglass.svg"
            />
            <b className={styles.label5}>{`Explore ${prof} near you `}</b>
            <img className={styles.iconsclose} alt="" src="/iconsadd4.svg" />
          </button>
        </div>
        <div className={styles.testimonialParent}>
          <div className={styles.testimonial}>
            <div className={styles.professionalCaterersHelpInParent}>
              <div className={styles.professionalCaterersHelpContainer}>
                Join thousand of satisfied Customers
              </div>
              <div className={styles.yourSearchFor}>
                Hear what customers worldwide have to say about Loggworks and
                our professionals.
              </div>
            </div>
            <div className={styles.testimonialInner}>
              <div className={styles.frameWrapper3}>
                <div className={styles.asASeniorCitizenIOftenRParent}>
                  <div className={styles.asASenior}>
                    As a senior citizen, I often rely on external help for
                    various household tasks. Loggworks has been a game-changer
                    for me. The professionals I’ve hired through their platform
                    have consistently been reliable, skillful, and respectful.
                    The flexibility in pricing, especially getting multiple
                    quotes, allows me to choose services within my budget. Their
                    commitment to customer satisfaction and prompt support makes
                    me feel valued and taken care of. Loggworks is truly a
                    reliable partner for home services.
                  </div>
                  <div className={styles.groupWrapper}>
                    <div className={styles.joeMillsParent}>
                      <b className={styles.joeMills}>Joe Mills</b>
                      <div className={styles.retired}>Retired</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.prototypeFooter}>
          <PrototypeFooter/>
          </div>
        </div>
      </div>
      <div className={styles.findingpageMob}>
      <div className={mobnav.navbarMobileclosed}>
            <img className={mobnav.layer2Icon} alt="" src="/layer-2.svg" 
            style={{cursor:'pointer'}} onClick={()=>navigate('/')} />
            <div className={mobnav.iconsaccountParent}>
              <img
                className={mobnav.iconsaccount}
                alt=""
                src="/iconsaccount.svg"
                onClick={()=>window.open('https://www.consumer.loggworks.co.uk/login', '_blank')}
              />
              <img
                className={mobnav.hamburgerIcon}
                alt=""
                src="/hamburger.svg"
                onClick={openMobileMenuConsumerPopup}
              />
            </div>
          </div>
        <div className={styles.frameParent43}>
          <div className={styles.professionalCaterersHelpInParent}>
            <div className={styles.groupParent8}>
              <div className={styles.findTheBestCaterersInLewiGroup}>
                <h1>
                   <b className={styles.findTheBestContainer1}>
                  <p className={styles.findTheBest}>Find the best</p>
                  <span className={styles.findTheBest}>{`${prof} in `}</span>
                  <p className={styles.lewisham1}>{loc}</p>
                </b>
                </h1>
                <img
                  className={styles.groupChild5}
                  alt=""
                  src="/vector-41.svg"
                />
              </div>
              <div className={styles.frameParent45}>
                <div className={styles.checksquareParent}>
                  <img
                    className={styles.iconschevronDown}
                    alt=""
                    src="/checksquare.svg"
                  />
                  <div className={styles.label5}>
                    100% cost-free job postings
                  </div>
                </div>
                <div className={styles.checksquareParent}>
                  <img
                    className={styles.iconschevronDown}
                    alt=""
                    src="/checksquare.svg"
                  />
                  <div className={styles.label5}>
                    Get Multiple Quotes, Free of Charge
                  </div>
                </div>
                <div className={styles.checksquareParent}>
                  <img
                    className={styles.iconschevronDown}
                    alt=""
                    src="/checksquare.svg"
                  />
                  <div className={styles.label5}>
                    Payment and Privacy Protection
                  </div>
                </div>
                <div className={styles.checksquareParent}>
                  <img
                    className={styles.iconschevronDown}
                    alt=""
                    src="/checksquare.svg"
                  />
                  <div className={styles.label5}>
                    Vetted and reliable Professionals
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.largeButtonsWrapper}>
              <button className={styles.largeButtons8}
              onClick={()=>window.location.href='https://www.loggworks.co.uk/postjob'}>
                <img
                  className={styles.mappinIcon}
                  alt=""
                  src="/iconsadd.svg"
                />
                <b className={styles.label}>{`Post a Job For Free `}</b>
                <img
                  className={styles.iconsclose}
                  alt=""
                  src="/iconsadd10.svg"
                />
              </button>
            </div>
          </div>
          <div className={styles.frameParent46}>
            <div className={styles.rectangleParent6}>
            <Carousel
          showArrows={true} // Display navigation arrows
          infiniteLoop={true} // Enable infinite looping
          showStatus={false} // Hide status indicators
          showThumbs={false} // Hide thumbnail navigation
          autoPlay={true} // Disable auto-play
          className={styles.frameChild9}
        >
         <img
                className={styles.frameChild9}
                alt=""
                src={img1}
                style={{cursor:'pointer'}}
                onClick={()=>{
                  navigate('/consumers'+link1)
                }}
              />
               <img
                className={styles.frameChild9}
                alt=""
                src={img2}
                style={{cursor:'pointer'}}
                onClick={()=>{
                  navigate('/consumers'+link2)
                }}
              />
               <img
                className={styles.frameChild9}
                alt=""
                src={img3}
                style={{cursor:'pointer'}}
                onClick={()=>{
                  navigate('/consumers'+link3)
                }}
              />
        </Carousel>
            </div>
          </div>
        </div>
        <div className={styles.rectangleParent7}>
          <div className={styles.groupChild6} />
          <div className={styles.frameParent47}>
            <div className={styles.frameParent48}>
              <div className={styles.ellipseParent}>
                <img className={styles.mappinIcon} alt="" src="/steps.svg" />
                <div className={styles.howItWorks3}>HOW IT WORKS</div>
              </div>
              <b className={styles.enjoyTheResults1}>
                enjoy the results of the expert work with LoggWorks
              </b>
            </div>
            <div className={styles.iconBenefitsMobile}>
              <div className={styles.mobileFooterParent}>
                <div className={styles.rectangleParent8}>
                  <div className={styles.groupChild7} />
                  <img
                    className={styles.frame6789311}
                    alt=""
                    src="/frame-67893-1@2x.png"
                  />
                </div>
                <div className={styles.frameParent50}>
                  <div className={styles.postAJobOrSearchForNearbParent}>
                    <div className={styles.postAJob3}>
                      Post a job or search for nearby service provider.
                    </div>
                    <div className={styles.postAJob4}>
                      Post a job or Search for reliable home service
                      professionals in your area. Enter your location and
                      service needed, then filter results by miles, sub
                      category, and ratings.
                    </div>
                  </div>
                  <button className={styles.largeButtons9}
                  onClick={()=>window.location.href='https://www.loggworks.co.uk/postjob'}>
                    <img
                      className={styles.mappinIcon}
                      alt=""
                      src="/iconsadd.svg"
                    />
                    <b className={styles.label}>{`Post a Job For Free `}</b>
                    <img
                      className={styles.iconsclose}
                      alt=""
                      src="/iconsadd10.svg"
                    />
                  </button>
                </div>
              </div>
              <div className={styles.groupParent10}>
                <div className={styles.rectangleParent8}>
                  <div className={styles.groupChild7} />
                  <div className={styles.groupFrame}>
                    <div className={styles.frameWrapper5}>
                      <div className={styles.frameParent51}>
                        <div className={styles.rectangleParent10}>
                          <img
                            className={styles.frameChild14}
                            alt=""
                            src="/rectangle-12260@2x.png"
                          />
                          <div className={styles.rectangleParent11}>
                            <div className={styles.frameChild15} />
                            <div className={styles.viewQuoteParent}>
                              <div className={styles.viewQuote}>View quote</div>
                              <img
                                className={styles.iconseye}
                                alt=""
                                src="/iconseye.svg"
                              />
                            </div>
                          </div>
                        </div>
                        <div className={styles.smallButtonsParent}>
                          <div className={styles.smallButtons}>
                            <div className={styles.professionalsPills}>
                              <div className={styles.button}>Decline</div>
                            </div>
                          </div>
                          <div className={styles.smallButtons1}>
                            <div className={styles.professionalsPills}>
                              <div className={styles.button1}>Accept</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.frameParent52}>
                  <div className={styles.postAJobOrSearchForNearbParent}>
                    <div className={styles.postAJob3}>
                      Get a personalized quotation for the required home
                      service.
                    </div>
                    <div className={styles.postAJob4}>
                      Recieve a personalized quote from your chosen provider.
                      Compare prices and choose the best option for your budget
                      and timeline.
                    </div>
                  </div>
                  <button className={styles.largeButtons9}
                  onClick={()=>window.location.href='https://www.loggworks.co.uk/postjob'}>
                    <img
                      className={styles.mappinIcon}
                      alt=""
                      src="/iconsadd.svg"
                    />
                    <b className={styles.label}>{`Post a Job For Free `}</b>
                    <img
                      className={styles.iconsclose}
                      alt=""
                      src="/iconsadd10.svg"
                    />
                  </button>
                </div>
              </div>
              <div className={styles.groupParent11}>
                <div className={styles.rectangleParent12}>
                  <div className={styles.groupChild9} />
                  <div className={styles.review1Group}>
                    <img
                      className={styles.review1Icon1}
                      alt=""
                      src="/review-1@2x.png"
                    />
                    <div className={styles.notif1}>
                      <div className={styles.notifItem} />
                      <div className={styles.jobMarkedAsCompletedGroup}>
                        <div className={styles.jobMarkedAs1}>
                          Job marked as completed
                        </div>
                        <img
                          className={styles.fluentcheckmarkStarburst16Icon1}
                          alt=""
                          src="/fluentcheckmarkstarburst16filled1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.frameParent53}>
                  <div className={styles.postAJobOrSearchForNearbParent}>
                    <div className={styles.postAJob3}>
                      Rate and review your completed bookings.
                    </div>
                    <div className={styles.postAJob4}>
                      Help us ensure the quality of our platform and assist
                      other users in making informed decisions by rating and
                      reviewing your provider. Earn rewards for every review
                      submitted.
                    </div>
                  </div>
                  <button className={styles.largeButtons9}
                  onClick={()=>window.location.href='https://www.loggworks.co.uk/postjob'}>
                    <img
                      className={styles.mappinIcon}
                      alt=""
                      src="/iconsadd.svg"
                    />
                    <b className={styles.label}>{`Post a Job For Free `}</b>
                    <img
                      className={styles.iconsclose}
                      alt=""
                      src="/iconsadd10.svg"
                    />
                  </button>
                </div>
              </div>
              <div className={styles.groupParent12}>
                <div className={styles.rectangleParent12}>
                  <div className={styles.groupChild9} />
                  <div className={styles.referABusinessAndEarn200Group}>
                    <div className={styles.referABusiness1}>
                      Refer a business and earn 200 points
                    </div>
                    <div className={styles.sendThemYourUniqueReferralGroup}>
                      <div className={styles.sendThemYour1}>
                        Send them your unique referral link
                      </div>
                      <div className={styles.youGet2001}>
                        You get 200 Points when they get their first boooking
                      </div>
                      <img
                        className={styles.feathercheckCircleIcon2}
                        alt=""
                        src="/feathercheckcircle2.svg"
                      />
                      <img
                        className={styles.feathercheckCircleIcon3}
                        alt=""
                        src="/feathercheckcircle3.svg"
                      />
                    </div>
                    <div className={styles.rectangleParent14}>
                      <div className={styles.groupChild11} />
                      <b className={styles.abc123g1}>ABC123G</b>
                      <div className={styles.yourReferralCode1}>
                        Your referral code
                      </div>
                      <b className={styles.copyCode1}>
                        <p className={styles.findTheBest}>Copy</p>
                        <p className={styles.findTheBest}>Code</p>
                      </b>
                      <div className={styles.groupChild12} />
                    </div>
                    <div className={styles.shareLinkReferralViaGroup}>
                      <div className={styles.shareLinkReferral1}>
                        Share link referral via
                      </div>
                      <div className={styles.instagram1Group}>
                        <img
                          className={styles.instagram1Icon1}
                          alt=""
                          src="/011instagram-11.svg"
                        />
                        <img
                          className={styles.whatsapp1Icon1}
                          alt=""
                          src="/003whatsapp-11.svg"
                        />
                        <img
                          className={styles.facebook1Icon1}
                          alt=""
                          src="/001facebook-11.svg"
                        />
                        <img
                          className={styles.twitter1Icon1}
                          alt=""
                          src="/013twitter-11.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.frameParent53}>
                  <div className={styles.postAJobOrSearchForNearbParent}>
                    <div className={styles.postAJob3}>
                      Earn cashbacks and rewards.
                    </div>
                    <div className={styles.postAJob4}>
                      Enjoy our loyalty rewards program, including cashback on
                      every booking, referral bonuses, and much more.
                    </div>
                  </div>
                  <button className={styles.largeButtons9}
                  onClick={()=>window.location.href='https://www.loggworks.co.uk/postjob'}>
                    <img
                      className={styles.mappinIcon}
                      alt=""
                      src="/iconsadd.svg"
                    />
                    <b className={styles.label}>{`Post a Job For Free `}</b>
                    <img
                      className={styles.iconsclose}
                      alt=""
                      src="/iconsadd10.svg"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.professionalCaterersHelpInParent}>
          <div className={styles.postAJobOrSearchForNearbParent}>
            <b className={styles.joinThousandOfContainer}>
              <p className={styles.findTheBest}>
                <span className={styles.professional2}>{`Professional `}</span>
                <span className={styles.lewisham}>{prof}</span>
                <span> help</span>
              </p>
              <p className={styles.findTheBest}>in your neighbourhood</p>
            </b>
            <div className={styles.yourSearchFor1}>
              Your search for the best Professional service is now complete!
              Customers worldwide entrust our skilled professionals with a wide
              array of home projects, both minor and significant.
            </div>
          </div>
          <div className={styles.iconBenefitsMobile}>
            <div className={styles.postAJobOrSearchForNearbParent}>
              <div className={styles.identificationcardGroup}>
                <img
                  className={styles.identificationcardIcon}
                  alt=""
                  src="/identificationcard.svg"
                />
                <div className={styles.rigorousBackgroundChecks}>
                  Rigorous Background Checks
                </div>
              </div>
              <div className={styles.ensuringYourSafetyContainer}>
                <p className={styles.findTheBest}>
                  Ensuring your safety and peace of mind,
                </p>
                <p className={styles.findTheBest}>
                  each of our experts undergoes a thorough background
                  verification.
                </p>
              </div>
            </div>
            <div className={styles.postAJobOrSearchForNearbParent}>
              <div className={styles.identificationcardGroup}>
                <img
                  className={styles.identificationcardIcon}
                  alt=""
                  src="/smiley.svg"
                />
                <div className={styles.rigorousBackgroundChecks}>
                  Committed to Your Satisfaction
                </div>
              </div>
              <div className={styles.ourHappinessGuarantee1}>
                Our Happiness Guarantee pledges a steadfast commitment to your
                contentment with our services.
              </div>
            </div>
            <div className={styles.frameParent58}>
              <div className={styles.infoGroup}>
                <img
                  className={styles.identificationcardIcon}
                  alt=""
                  src="/info.svg"
                />
                <div className={styles.efficientAndCourteous}>
                  Efficient and Courteous Support
                </div>
              </div>
              <div className={styles.experiencePromptAnd1}>
                Experience prompt and amiable customer assistance, dedicated to
                addressing your needs swiftly and effectively.
              </div>
            </div>
            <div className={styles.frameParent58}>
              <div className={styles.infoGroup}>
                <img
                  className={styles.identificationcardIcon}
                  alt=""
                  src="/lock.svg"
                />
                <div className={styles.efficientAndCourteous}>
                  Payment and Privacy Protection
                </div>
              </div>
              <div
                className={styles.experiencePromptAnd1}
              >{`You’ll never have to share your personal information with tradesmen or home service professionals on Loggworks. `}</div>
            </div>
            <div className={styles.frameParent58}>
              <div className={styles.infoGroup}>
                <img
                  className={styles.identificationcardIcon}
                  alt=""
                  src="/coins.svg"
                />
                <div className={styles.efficientAndCourteous}>
                  Pricing Flexibility
                </div>
              </div>
              <div className={styles.experiencePromptAnd1}>
                We allow you choose your price because you get multiple quotes
                from service providers at no cost.
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameParent61}>
          <div className={styles.professionalCaterersHelpInParent}>
            <div className={styles.findMoreCaterersNearYouWrapper}>
              <b className={styles.joinThousandOfContainer}>
                <span>{`Find More `}</span>
                <span className={styles.lewisham}>{prof}</span>
                <span> Near You</span>
              </b>
            </div>
            <div className={styles.professionalsParent1}>
            <img
             src={img5}
             style={{width:'90%',height:'100%'}}
             />
            </div>
          </div>
          <div className={styles.largeButtons9}
           onClick={handleSubmit}>
            <img
              className={styles.mappinIcon}
              alt=""
              src="/magnifyingglass.svg"
            />
            <b className={styles.label5}>Explore {prof} Near You</b>
            <img className={styles.iconsclose} alt="" src="/iconsadd10.svg" />
          </div>
        </div>
        <div className={styles.mobileFooterHomeownerParent}>
          <div className={styles.testimonialMobile}>
            <div className={styles.postAJobOrSearchForNearbParent}>
              <b className={styles.joinThousandOfContainer}>
                <p className={styles.findTheBest}>Join thousand of</p>
                <p className={styles.findTheBest}>satisfied Customers</p>
              </b>
              <div className={styles.yourSearchFor1}>
                Hear what customers worldwide have to say about Loggworks and
                our professionals.
              </div>
            </div>
            <div className={styles.testimonialMobileInner}>
              <div className={styles.frameWrapper6}>
                <div className={styles.postAJobOrSearchForNearbParent}>
                  <div className={styles.asASenior1}>
                    As a senior citizen, I often rely on external help for
                    various household tasks. Loggworks has been a game-changer
                    for me. The professionals I’ve hired through their platform
                    have consistently been reliable, skillful, and respectful.
                    The flexibility in pricing, especially getting multiple
                    quotes, allows me to choose services within my budget. Their
                    commitment to customer satisfaction and prompt support makes
                    me feel valued and taken care of. Loggworks is truly a
                    reliable partner for home services.
                  </div>
                  <div className={styles.frameWrapper7}>
                    <div className={styles.frameWrapper8}>
                      <div className={styles.findMoreCaterersNearYouWrapper}>
                        <b className={styles.joeMills1}>Joe Mills</b>
                        <div className={styles.retired1}>Retired</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <MobileFooter/>
        </div>
      </div>
      {isMobileMenuConsumerPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuConsumerPopup}
        >
          <MobileMenuConsumer1 onClose={closeMobileMenuConsumerPopup} />
        </PortalPopup>
      )}
    </div>
  );
};

export default CustFinding;
