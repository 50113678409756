import { ChevronDownIcon, PlusIcon } from "@heroicons/react/24/outline";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useState,useEffect } from "react";
import ModalPopup from "./modalPopup.tsx";
import AddressAutocomplete from 'mui-address-autocomplete';
import { auth, db, storage } from "../firebase";
import { serverTimestamp } from 'firebase/firestore'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Paper } from "@mui/material";

const PostJobForm: React.FC<{
  activeStep: number;
  setActiveStep: any;
  postId: any
}> = ({ activeStep, setActiveStep , postId}) => {
  const [optionSelected, setOptionSelected] = useState<any>(null);
  const [formFilled, setFormFilled] = useState<any>(false);
  const [optionSelectedIndex, setOptionSelectedIndex] = useState<any>(0);
  const [addy1, setAddy1] = useState('')
  const [city, setCity] = useState('')
  const [reg, setReg] = useState('')
  const [zip, setZip] = useState('')
  const [country, setCountry] = useState('')
  const [address, setAddress] = useState<any>('')
  const [lat, setLat] = useState(0)
  const [lng, setLng] = useState(0)
  const [title, setTitle] = useState('')
  const [others, setOthers] = useState('')
  const [desc, setDesc] = useState('')
  const [name, setName] = useState('')
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [image1,setImage1] = useState('')
  const [image2,setImage2] = useState('')
  const [image3,setImage3] = useState('')
  const [image4,setImage4] = useState('')
  const [loading,setLoading]=useState(false)
  useEffect(() => {
   
  }, [])
  const handleSelect = async (selectedAddress:any) => {
    setAddress(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const selectedResult = results[0];
      const { formatted_address, address_components, geometry } = selectedResult;

      const city = getAddressComponentValue(address_components, 'locality');
      const state = getAddressComponentValue(address_components, 'administrative_area_level_1');
      const country = getAddressComponentValue(address_components, 'country');
      const zip = getAddressComponentValue(address_components, 'postal_code');

      setAddy1(formatted_address);
      setCity(city);
      setReg(state);
      setCountry(country);
      setZip(zip);

      const { lat, lng } = await getLatLng(selectedResult);
      setLat(lat);
      setLng(lng);
    } catch (error) {
      console.error(error);
    }
  };
  function getAddressComponentValue(addressComponents:any, type:any) {
    const component = addressComponents.find((component:any) =>
      component.types.includes(type)
    );
  
    return component ? component.long_name : '';
  }
  const [modalPop, setModalPopUp] = useState(false);

  const generateToken = (len:any) => {
    let result = [];
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charLength = characters.length;
    for (let i = 0; i < len; i++) {
        result.push(characters.charAt(Math.floor(Math.random() * charLength)));
    }
    return result.join('');
  }
const onImagechange= (event:any) =>
    {
        if (event.target.files && event.target.files[0]) {

            let id_pic = generateToken(7)
            const uploadTask = storage.ref(`images/${id_pic}.png`).put(event.target.files[0]);
      uploadTask.on('state_changed', 
      (snapshot) => {
        // progrss function ....
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      }, 
      (error) => {
           // error function ....
        console.log(error);
        
      }, 
    () => {
        // complete function ....
        storage.ref('images').child(id_pic+".png").getDownloadURL().then(url => {
          if(image1=='')
          {
            setImage1(url)
          }
          else if(image2=='')
          {
            setImage2(url)
          }
          else if(image3=='')
          {
            setImage3(url)
          }
          else
          {
            setImage4(url)
          }
        })
    });
          }
    }
const handleSubmit =(e: any): void => {
  e.preventDefault();
  const key = db.collection("tmp").doc().id
  db.collection('onboarding').doc(key).set({
    image1: image1,
    image2: image2,
    image3: image3,
    image4: image4,
  })
  const commonData:any = {
    title: title,
    cate: optionSelected.title.toLowerCase(),
    subCate: others||optionSelected.options[optionSelectedIndex],
    desc: desc,
    addy1: addy1,
    zip: zip,
    reg: reg,
    city: city,
    lat: lat,
    lng: lng,
    country: country,
    to: to,
    from: from,
    name: name,
    key
  };
  const params = new URLSearchParams(commonData);
  window.location.href='https://www.consumer.loggworks.co.uk/manage-job/'+ params.toString()
}
  return (
    <>
    {
      !loading?
<div className="all-forms">
{activeStep == 0 && (
          <form action="" className="text-grey20">
            <div className="flex flex-wrap mb-6">
              <div className="x-3 my-2 md:mb-0 w-full">
                <label
                  className="capitalize tracking-wide text-md"
                  id="grid-first-name"
                >
                  Title
                </label>
                <input
                  className="appearance-none w-full text-gray-700 border border-red-500 rounded p-4 my-2 leading-tight focus:outline-none focus:bg-white bg-lightWhite border border-grey80"
                  id="grid-first-name"
                  type="text"
                  name="title"
                  value={title}
                  onChange={(e)=>setTitle(e.target.value)}
                />
              </div>
              <div className="x-3 my-2 md:mb-0 w-full">
                <label
                  className="capitalize tracking-wide text-md"
                  id="grid-first-name"
                >
                  Provide a brief description of the job
                </label>
                <textarea
                  className="appearance-none w-full text-gray-700 border border-red-500 rounded p-4 my-2 leading-tight focus:outline-none focus:bg-white bg-lightWhite border border-grey80 h-[108px]"
                  id="grid-first-name"
                  placeholder=""
                  value={desc}
                  onChange={(e)=>setDesc(e.target.value)}
                />
              </div>
              <div
                className="x-3 my-2 md:mb-0 w-full"
                onClick={() => setModalPopUp(true)}
              >
                <label
                  className="capitalize tracking-wide text-md"
                  id="grid-first-name"
                >
                  Category
                </label>

                <div className="appearance-none w-full text-gray-700 border border-red-500 rounded p-4 my-2 leading-tight focus:outline-none focus:bg-white bg-lightWhite border border-grey80 flex items-center justify-between cursor-pointer">
                  <h1>
                  {
                       others? `Others (${others})`:
                    optionSelected? `${optionSelected.title} (${optionSelected.options[optionSelectedIndex]})`
                      : " Choose a category"}
                  </h1>
                  <span>
                    <ChevronDownIcon width={24} height={24} />
                  </span>
                </div>
              </div>
            </div>

            <button
              className="bg-green text-white w-full h-[48px] font-bold py-2 px-4 rounded"
              onClick={(e) =>{ 
                e.preventDefault()
                if (title==''||
                desc=='') {
                  alert('Please fill all the fields')
              return
            }
            if(optionSelected==null){
              if(others==''){
                alert('Please fill all the fields')
                return
              }
            }
                setActiveStep(activeStep + 1)}}
            >
              Enter location & Date
            </button>
          
          </form>
        )}

        {activeStep == 1 && (
          <form action="" className="text-grey20">
            <div className="flex flex-wrap mb-6">
              <div className="x-3 my-2 md:mb-0 w-full">
                <label
                  className="capitalize tracking-wide text-md"
                  id="grid-first-name"
                >
                  Where do you need the job done?
                </label>
                <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }:any) => (
        <div>
          <TextField
            {...getInputProps({
              placeholder: 'Address',
              className: 'form-control',
            })}
            fullWidth
          />
          <Paper className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion:any, index:any) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
              return (
                <MenuItem key={index} {...getSuggestionItemProps(suggestion, { className })}>
                  {suggestion.description}
                </MenuItem>
              );
            })}
          </Paper>
        </div>
      )}
    </PlacesAutocomplete>
        <p className="text-[12px]">
                  Street address, Postcode, P.O. Box
                </p>
              </div>
            </div>

            <button
              className="bg-green text-white w-full h-[48px] font-bold py-2 px-4 rounded"
              onClick={(e) =>{ 
                e.preventDefault()
                if (address=='') {
                      alert('Please fill all the fields')
                  return
                }
                setActiveStep(activeStep + 1)}}
            >
              Enter a budget
            </button>
            <button
               className="bg-white text-[black] w-full h-[48px] py-2 px-4 rounded"
              onClick={(e) =>{ 
                e.preventDefault()
                setActiveStep(activeStep - 1)}}
            >
              Back
            </button>
          </form>
        )}
        {activeStep == 2 && (
          <form action="" className="text-grey20">
            <div className="flex flex-wrap mb-6">
              <div className="x-3 my-2 md:mb-0 w-full">
                <label
                  className="capitalize tracking-wide text-md"
                  id="grid-first-name"
                >
                  From
                </label>
                <div className="relative">
                  <input
                    className="appearance-none w-full text-gray-700 border border-red-500 rounded p-4 my-2 leading-tight focus:outline-none focus:bg-white bg-lightWhite border border-grey80"
                    id="grid-first-name"
                    type="number"
                    name="title"
                    value={from}
                    onChange={(e)=>setFrom(e.target.value)}
                    inputMode="numeric"  
                    pattern="[0-9]*"
                    onKeyPress={(e) => {
                      const isNumberOrPeriod = /[0-9.]/.test(e.key);
                      if (!isNumberOrPeriod) {
                        e.preventDefault();
                      }
                    }}
                  />

                  <div className="absolute right-2 top-[20px]">
                    <img
                      src={"/assets/icons/euro.svg"}
                      width={24}
                      height={24}
                      alt="euro"
                    />
                  </div>
                </div>
              </div>
              <div className="x-3 my-2 md:mb-0 w-full">
                <label
                  className="capitalize tracking-wide text-md"
                  id="grid-first-name"
                >
                  To
                </label>
                <div className="relative">
                  <input
                    className="appearance-none w-full text-gray-700 border border-red-500 rounded p-4 my-2 leading-tight focus:outline-none focus:bg-white bg-lightWhite border border-grey80"
                    id="grid-first-name"
                    type="number"
                    name="title"
                    value={to}
                    onChange={(e)=>setTo(e.target.value)}
                    inputMode="numeric"  
                    pattern="[0-9]*"
                    onKeyPress={(e) => {
                      const isNumberOrPeriod = /[0-9.]/.test(e.key);
                      if (!isNumberOrPeriod) {
                        e.preventDefault();
                      }
                    }}
                  />

                  <div className="absolute right-2 top-[20px]">
                    <img
                      src={"/assets/icons/euro.svg"}
                      width={24}
                      height={24}
                      alt="euro"
                    />
                  </div>
                </div>
              </div>
            </div>

            <button
              className="bg-green text-white w-full h-[48px] font-bold py-2 px-4 rounded"
              onClick={(e) =>{ 
                e.preventDefault()
                if (from==''||to=='') {
                      alert('Please fill all the fields')
                  return
                }
                setActiveStep(activeStep + 1)}}
            >
              Upload Images
            </button>
            <button
               className="bg-white text-[black] w-full h-[48px] py-2 px-4 rounded"
              onClick={(e) =>{ 
                e.preventDefault()
                setActiveStep(activeStep - 1)}}
            >
              Back
            </button>
          </form>
        )}
        {activeStep == 3 && (
          <form action="" onSubmit={handleSubmit} className="text-grey20">
            <h1 className="capitalize text-md font-semibold">
              Upload an image (Optional)
            </h1>
            <div className="max-sm:overflow-x-scroll max-sm:scrolling-touch max-sm:overflow-x-hidden  ">
              <div className="flex justify-between my-6 max-sm:w-[130%]">
              {image1 ? (
                      <div className="rounded w-[101px] h-[108px] my-2 focus:outline-none bg-grey80  md:mb-0 relative ">
                        <div className="" id="grid-first-name">
                          <img
                            src={image1}
                            width={101}
                            height={108}
                            alt=""
                            objectFit="cover"
                            objectPosition="center"
                            className="object-center object-cover min-h-[108px] max-h-[108px]"
                          />
                         <div
  onClick={() => setImage1('')}
  className={`absolute w-[24px] h-[24px] rounded-full flex justify-center items-center text-white -bottom-2 -right-3 ${
    image1 ? 'bg-[red]' : 'bg-[#7E57CF]'
  }`}
>
  {image1 ? (
    <CancelOutlinedIcon width={16} height={16} />
  ) : (
    <PlusIcon width={16} height={16} />
  )}
</div>
                        </div>
                      </div>
              ) : (
                <div className="border border-dashed border-[#CCCCCC] rounded w-[101px] h-[108px] my-2 focus:outline-none bg-grey80  md:mb-0 relative ">
                  <label className="text-gray-700  " id="grid-first-name">
                    <div className="absolute top-[37px] left-[37px]">
                      <PlusIcon width={24} height={24} />
                    </div>
                    <input
                      id="grid-first-name"
                      type="file"
                      name="title"
                      onChange={onImagechange}
                      hidden
                    />

                    <div className="absolute w-[24px] h-[24px] rounded-full bg-[#7E57CF] flex justify-center items-center text-white -bottom-2 -right-3">
                      <PlusIcon width={16} height={16} />
                    </div>
                  </label>
                </div>
              )}
              {image2 ? (
                <div className="rounded w-[101px] h-[108px] my-2 focus:outline-none bg-grey80  md:mb-0 relative ">
                  <div className="" id="grid-first-name">
                    <img
                      src={image2}
                      width={101}
                      height={108}
                      alt=""
                      objectFit="cover"
                      objectPosition="center"
                      className="object-center object-cover min-h-[108px] max-h-[108px]"
                    />
                        <div
  onClick={() => setImage2('')}
  className={`absolute w-[24px] h-[24px] rounded-full flex justify-center items-center text-white -bottom-2 -right-3 ${
    image2 ? 'bg-[red]' : 'bg-[#7E57CF]'
  }`}
>
  {image2 ? (
    <CancelOutlinedIcon width={16} height={16} />
  ) : (
    <PlusIcon width={16} height={16} />
  )}
</div>
                  </div>
                </div>
              ) : (
                <div className="border border-dashed border-[#CCCCCC] rounded w-[101px] h-[108px] my-2 focus:outline-none bg-grey80  md:mb-0 relative ">
                  <label className="text-gray-700  " id="grid-first-name">
                    <div className="absolute top-[37px] left-[37px]">
                      <PlusIcon width={24} height={24} />
                    </div>
                    <input
                      id="grid-first-name"
                      type="file"
                      name="title"
                      onChange={onImagechange}
                      hidden
                    />

                    <div className="absolute w-[24px] h-[24px] rounded-full bg-[#7E57CF] flex justify-center items-center text-white -bottom-2 -right-3">
                      <PlusIcon width={16} height={16} />
                    </div>
                  </label>
                </div>
              )}
              {image3 ? (
                <div className="rounded w-[101px] h-[108px] my-2 focus:outline-none bg-grey80  md:mb-0 relative ">
                  <div className="" id="grid-first-name">
                    <img
                      src={image3}
                      width={101}
                      height={108}
                      alt=""
                      objectFit="cover"
                      objectPosition="center"
                      className="object-center object-cover min-h-[108px] max-h-[108px]"
                    />
                        <div
  onClick={() => setImage3('')}
  className={`absolute w-[24px] h-[24px] rounded-full flex justify-center items-center text-white -bottom-2 -right-3 ${
    image3 ? 'bg-[red]' : 'bg-[#7E57CF]'
  }`}
>
  {image3 ? (
    <CancelOutlinedIcon width={16} height={16} />
  ) : (
    <PlusIcon width={16} height={16} />
  )}
</div>
                  </div>
                </div>
              ) : (
                <div className="border border-dashed border-[#CCCCCC] rounded w-[101px] h-[108px] my-2 focus:outline-none bg-grey80  md:mb-0 relative ">
                  <label className="text-gray-700  " id="grid-first-name">
                    <div className="absolute top-[37px] left-[37px]">
                      <PlusIcon width={24} height={24} />
                    </div>
                    <input
                      id="grid-first-name"
                      type="file"
                      name="title"
                      onChange={onImagechange}
                      hidden
                    />

                    <div className="absolute w-[24px] h-[24px] rounded-full bg-[#7E57CF] flex justify-center items-center text-white -bottom-2 -right-3">
                      <PlusIcon width={16} height={16} />
                    </div>
                  </label>
                </div>
              )}
              {image4 ? (
                <div className="rounded w-[101px] h-[108px] my-2 focus:outline-none bg-grey80  md:mb-0 relative ">
                  <div className="" id="grid-first-name">
                    <img
                      src={image4}
                      width={101}
                      height={108}
                      alt=""
                      objectFit="cover"
                      objectPosition="center"
                      className="object-center object-cover min-h-[108px] max-h-[108px]"
                    />
                       <div
  onClick={() => setImage4('')}
  className={`absolute w-[24px] h-[24px] rounded-full flex justify-center items-center text-white -bottom-2 -right-3 ${
    image4 ? 'bg-[red]' : 'bg-[#7E57CF]'
  }`}
>
  {image4 ? (
    <CancelOutlinedIcon width={16} height={16} />
  ) : (
    <PlusIcon width={16} height={16} />
  )}
</div>
                  </div>
                </div>
              ) : (
                <div className="border border-dashed border-[#CCCCCC] rounded w-[101px] h-[108px] my-2 focus:outline-none bg-grey80  md:mb-0 relative ">
                  <label className="text-gray-700  " id="grid-first-name">
                    <div className="absolute top-[37px] left-[37px]">
                      <PlusIcon width={24} height={24} />
                    </div>
                    <input
                      id="grid-first-name"
                      type="file"
                      name="title"
                      onChange={onImagechange}
                      hidden
                    />

                    <div className="absolute w-[24px] h-[24px] rounded-full bg-[#7E57CF] flex justify-center items-center text-white -bottom-2 -right-3">
                      <PlusIcon width={16} height={16} />
                    </div>
                  </label>
                </div>
              )}
              </div>
            </div>
            <p className="mb-4">
              Add supporting images to best showcase your job request
            </p>
            <button
              className="bg-green text-white w-full h-[48px] font-bold py-2 px-4 rounded"
              onClick={() => {
                setActiveStep(activeStep), setFormFilled(true);
              }}
            >
             {postId==''?'Post Job':'Update Job'}
            </button>
            <button
               className="bg-white text-[black] w-full h-[48px] py-2 px-4 rounded"
              onClick={(e) =>{ 
                e.preventDefault()
                setActiveStep(activeStep - 1)}}
            >
              Back
            </button>
          </form>
        )}
      </div>

      :null
    }
      
      {modalPop && (
        <ModalPopup
        modalPop={modalPop}
        setModalPopUp={setModalPopUp}
        optionSelected={optionSelected}
        optionSelectedIndex={optionSelectedIndex}
        setOptionSelected={setOptionSelected}
        setOthers={setOthers}
        others={others}
        setOptionSelectedIndex={setOptionSelectedIndex}
        />
      )}
    </>
  );
};

export default PostJobForm;
