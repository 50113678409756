import { useCallback, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./HiringSelect.module.css";
import ToolBar from "./components/ToolBar";
import PrototypeFooter from "../../components/PrototypeFooter";
import { Autocomplete, TextField } from "@mui/material";
import mobnav from "../ConsumerHome.module.css";
import { useState } from "react";
import MobileFooter from "../../components/MobileFooter";
import PortalPopup from "../../components/PortalPopup";
import MenuMobile from "./components/MenuMobile";
import axios from "axios";
import ProfBanner from "./components/ProfBanner";
import NoArticle from "./components/Empty";
import SubsBanner from "./components/SubsBanner";
import Highlighter from "react-highlight-words";

const HowTo_p = () => {
  const [
    isMobileMenuProfProfessionalPopupOpen,
    setMobileMenuProfProfessionalPopupOpen,
  ] = useState(false);
  const openMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(true);
  }, []);

  const closeMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(false);
  }, []);
  let id = 'how-to'
  const [searchWord, setSearchWord] = useState("");
  const [forcons,setForcons]=useState([])
  const navigate = useNavigate();

  const onBLOGTextClick = useCallback(() => {
    navigate("/blog");
  }, [navigate]);

  const onFORCONSUMERSTextClick = useCallback(() => {
    navigate("/blog/for-professionals");
  }, [navigate]);

  const formatDateToWords = (dateString) => {
    const date = new Date(dateString);
    
    // Array of month names
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    // Get the components of the date
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    
    // Construct the formatted date string
    const formattedDate = `${month} ${day}, ${year}`;
    
    return formattedDate;
  };

  useEffect(() => {
    axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/for-professionals?populate=*')
    .then(response => {
      const blogsData = response.data.data;
       // Limiting to the first 3 blogs
      const featuredBlogs = blogsData.filter(blog => blog.attributes.category === 'How To');
      setForcons(featuredBlogs);
    })
    .catch(error => {
      console.error('Error fetching blogs:', error);
    });

  }, []);
  const handleChange = (event, value) => {
    if (value) {
      forcons.filter(blog => blog.attributes.BlogTitle === value).map(data=>{
         navigate(`/blog/for-professionals/${data.attributes.slug}`);
      })
     
    }
  };
  const searchBlogs = (searchText) => {
    if (searchText.trim() === '') {
      axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/for-professionals?populate=*')
      .then(response => {
        const blogsData = response.data.data;
         // Limiting to the first 3 blogs
        const featuredBlogs = blogsData.filter(blog => blog.attributes.category === 'How To');
        setForcons(featuredBlogs);
      })
      .catch(error => {
        console.error('Error fetching blogs:', error);
      });
      return;
    }
  
    const filteredTrend = forcons.filter(blog => {
      const categoryMatch = blog.attributes.category.toLowerCase().includes(searchText.toLowerCase());
      const subcategoryMatch = blog.attributes.subcategory.toLowerCase().includes(searchText.toLowerCase());
      const titleMatch = blog.attributes.BlogTitle.toLowerCase().includes(searchText.toLowerCase());
      const descriptionMatch = blog.attributes.description.toLowerCase().includes(searchText.toLowerCase());
      
      return categoryMatch || subcategoryMatch || titleMatch || descriptionMatch;
    });
  
    setForcons(filteredTrend);
  };
  return (
    <div className={styles.hiringselect}>
      <div className={styles.hiringCaterers}>
        <div className={styles.hiringCaterersInner}>
        <ToolBar/>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.blogGroup}>
            <div className={styles.blog1} onClick={onBLOGTextClick}>
              BLOG
            </div>
            <img
              className={styles.arrowrightIcon}
              alt=""
              src="/caretright.svg"
            />
            <div className={styles.blog1} onClick={onFORCONSUMERSTextClick}>
              FOR PROFESSIONALS
            </div>
            <img
              className={styles.arrowrightIcon}
              alt=""
              src="/caretright.svg"
            />
            <div className={styles.hiringCaterers1}>{id.replace(/-/g, ' ')}</div>
          </div>
          <div className={styles.rectangleGroup}>
          <Autocomplete
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      options={forcons.map((option) => option.attributes.BlogTitle)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search articles"
          onChange= {(e) => {
            searchBlogs(e.target.value)
            setSearchWord(e.target.value)}}
          InputProps={{
            ...params.InputProps,
            type: "search",
            
          }}
        />
      )}
      
      onChange={handleChange}
      autoHighlight  

    />
          </div>
        </div>
        <div className={styles.hiringCaterersParent}>
          <div className={styles.hiringCaterers2}>{id.replace(/-/g, ' ')}</div>
          <div className={styles.exploreExpertAdviceContainer}>
            <p className={styles.exploreExpertAdvice}>
            Get access to valuable resources for professionals, providing step-by-step or instructional content, guides, and tutorials.
            </p>
            <p className={styles.exploreExpertAdvice}>
             
            </p>
          </div>
        </div>
       {/*<div className={styles.hiringCaterersChild}>
          <div className={styles.groupWrapper}>
            <div className={styles.rectangleContainer}>
              <div className={styles.groupInner} />
              <div className={styles.frameContainer}>
                <div className={styles.groupContainer}>
                  <div className={styles.searchArticles}>Filter resources</div>
                  <img
                    className={styles.arrowrightIcon}
                    alt=""
                    src="/caretdown.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>*/}
         {
            forcons.length<=0?
            <>
            <NoArticle/>
            </>
            :
            <div className={styles.frameDiv}>
        {forcons.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
                <div className={styles.groupDiv}>
            <div className={styles.rectangleDiv} />
            <img
              className={styles.rectangleIcon}
              alt=""
              src={blog.attributes.BlogImage.data.attributes.url}
            />
            <div className={styles.frameGroup}>
              <div className={styles.blogGroup}>
                <div className={styles.hiringCaterersWrapper}>
                  <div className={styles.kitchenPorter}>{id.replace(/-/g, ' ')}</div>
                </div>
                <div className={styles.minsRead}> {blog.attributes.duration} mins read</div>
              </div>
              <div className={styles.unlockingEfficiencyYourComParent}>
                <b className={styles.unlockingEfficiencyYour}>
                <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                </b>
                <div
                  className={styles.loggworksHelpsYou}
                >
                   <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                  </div>
              </div>
              <div className={styles.christinaWilliamsParent}>
                <div className={styles.christinaWilliams}>
                  {blog.attributes.Author}
                </div>
                <div className={styles.ellipseDiv} />
                <div className={styles.january18th2024}>{formatDateToWords(blog.attributes.createdAt)}</div>
              </div>
            </div>
          </div>
                </Link>
       
             ))}
         
        </div>
          }
        
        
        <SubsBanner/>
        <div className={styles.prototypeFooter}>
        <PrototypeFooter/>
        </div>
      </div>
      <div className={styles.hiringCaterers6}>
      <div className={mobnav.navbarMobileclosed}>
          <img className={mobnav.layer2Icon} alt="" src="/layer-21.svg"
          style={{cursor:'pointer'}} onClick={()=>navigate('/')}  />
          <div className={mobnav.iconsaccountParent}>
            <div className={mobnav.iconsaccount} alt="" />
            <img className={mobnav.hamburgerIcon} alt="" src="/hamburger.svg" onClick={openMobileMenuProfProfessionalPopup} />
          </div>
        </div>
        <div className={styles.frameParent15}>
          <div className={styles.termsOfUseParent}>
            <div className={styles.blog3} onClick={onBLOGTextClick}>BLOG</div>
            <img
              className={styles.caretrightIcon3}
              alt=""
              src="/caretright.svg"
            />
            <div className={styles.blog3} onClick={onFORCONSUMERSTextClick}>FOR PROFESSIONALS</div>
           <div className={styles.caretrightParent}>
            <img
              className={styles.caretrightIcon3}
              alt=""
              src="/caretright.svg"
            />
            <div className={styles.hiringGuides1}>{id.replace(/-/g, ' ')}</div>
          </div>
          </div>
         
        </div>
        <div className={styles.rectangleParent4}>
        <Autocomplete
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      options={forcons.map((option) => option.attributes.BlogTitle)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search articles"
          onChange= {(e) => {
            searchBlogs(e.target.value)
            setSearchWord(e.target.value)}}
          InputProps={{
            ...params.InputProps,
            type: "search",
            
          }}
        />
      )}
      
      onChange={handleChange}
      autoHighlight  

    />
        </div>
        <div className={styles.hiringCaterersGroup}>
          <div className={styles.hiringCaterers2}>{id.replace(/-/g, ' ')}</div>
          <div className={styles.exploreExpertAdviceContainer1}>
            <p className={styles.exploreExpertAdvice}>
            Get access to valuable resources for professionals, providing step-by-step or instructional content, guides, and tutorials.
            </p>
          </div>
        </div>
        {/*<div className={styles.hiringCaterersInner1}>
          <div className={styles.groupFrame}>
            <div className={styles.groupFrame}>
              <div className={styles.groupChild7} />
              <div className={styles.frameWrapper3}>
                <div className={styles.groupContainer}>
                  <div className={styles.searchArticles}>Filter resources</div>
                  <img
                    className={styles.caretdownIcon4}
                    alt=""
                    src="/caretdown.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>*/}
         {
            forcons.length<=0?
            <>
            <NoArticle/>
            </>
            :
             <div className={styles.hiringCaterersInner2}>
          <div className={styles.frameParent16}>
          {forcons.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
               <div className={styles.groupWrapper1}>
              <div className={styles.rectangleParent6}>
                <div className={styles.groupChild8} />
                <img
                  className={styles.groupChild9}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent17}>
                  <div className={styles.frameParent18}>
                    <div className={styles.kitchenPorterContainer}>
                      <div className={styles.others}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead3}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.unlockingEfficiencyYourComGroup}>
                    <b className={styles.unlockingEfficiencyYour1}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div className={styles.weAreCommitted}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                    </div>
                  </div>
                  <div className={styles.christinaWilliamsParent1}>
                    <div className={styles.christinaWilliams3}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild8} />
                    <div className={styles.january18th20243}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
       
             ))}
            
          </div>
        </div>
          }
       
        
        <div className={styles.frameParent28}>
        <SubsBanner/>
         <MobileFooter/>
        </div>
      </div>
      {isMobileMenuProfProfessionalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuProfProfessionalPopup}
        >
          <MenuMobile
            onClose={closeMobileMenuProfProfessionalPopup}
          />
        </PortalPopup>
      )}
    </div>
  );
};

export default HowTo_p;
