import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./PricingGuide1.module.css";
import ToolBar from "./components/ToolBar";
import PrototypeFooter from "../../components/PrototypeFooter";
import mobnav from "../ConsumerHome.module.css";
import MobileFooter from "../../components/MobileFooter";
import PortalPopup from "../../components/PortalPopup";
import MenuMobile from "./components/MenuMobile";
import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import SubsBanner from "./components/SubsBanner";

const PricingGuide1 = () => {
  const [
    isMobileMenuProfProfessionalPopupOpen,
    setMobileMenuProfProfessionalPopupOpen,
  ] = useState(false);
  const openMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(true);
  }, []);
  const [pricing, setPricing]=useState([])

  const closeMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(false);
  }, []);
  const navigate = useNavigate();

  const onBLOGTextClick = useCallback(() => {
    navigate("/blog");
  }, [navigate]);

  const onFORCONSUMERSTextClick = useCallback(() => {
    navigate("/blog/for-consumers");
  }, [navigate]);

  const onGroupContainerClick = useCallback(() => {
    // Please sync "Blog Post" to the project
  }, []);
  useEffect(() => {
    axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/blogs?populate=*')
    .then(response => {
      const blogsData = response.data.data;
      // Limiting to the first 3 blogs
      const pricingBlogs = blogsData.filter(blog => blog.attributes.category === 'Pricing Guides');
      setPricing(pricingBlogs);
    })
    .catch(error => {
      console.error('Error fetching blogs:', error);
    });
  }, []);
  const formatDateToWords = (dateString) => {
    const date = new Date(dateString);
    
    // Array of month names
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    // Get the components of the date
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    
    // Construct the formatted date string
    const formattedDate = `${month} ${day}, ${year}`;
    
    return formattedDate;
  };
  const searchdata = [
    { BlogTitle: 'Caterers'},
    { BlogTitle: 'Childcare Workers'},
    { BlogTitle: 'Cleaners'},
    { BlogTitle: 'Entertainers'},
    { BlogTitle: 'Appliance Repair Workers'},
    { BlogTitle: 'Mobile Mechanics'},
    { BlogTitle: 'Personal Assistants'},
    { BlogTitle: 'Pet Groomers'},
    { BlogTitle: 'Recovery Service Workers'},
    { BlogTitle: 'Removal Service Workers'},
    { BlogTitle: 'Tradesmen'},
    { BlogTitle: 'Transport Service Workers'},
    { BlogTitle: 'Computer & Phone Repair'},
    { BlogTitle: 'Dry Cleaners & Laundrymen'},
    { BlogTitle: 'Health, Wellness & Beauty'},
    { BlogTitle: 'Tuition & Classes Workers'},
  ]
  const handleChange = (event, value) => {
    if (value) {
      const selected = value.toLowerCase(); // Replace spaces with hyphens
      navigate(`/blog/for-consumers/pricing-guides/pricing-${selected}`);
    }
  };
  return (
    <div className={styles.pricingguide}>
      <div className={styles.pricingGuides}>
        <div className={styles.pricingGuidesInner}>
         <ToolBar/>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.blogGroup}>
            <div className={styles.blog1} onClick={onBLOGTextClick}>
              BLOG
            </div>
            <img
              className={styles.arrowrightIcon}
              alt=""
              src="/caretright.svg"
            />
            <div className={styles.blog1} onClick={onFORCONSUMERSTextClick}>
              FOR CONSUMERS
            </div>
            <img
              className={styles.arrowrightIcon}
              alt=""
              src="/caretright.svg"
            />
            <div className={styles.pricingGuides1}>PRICING Guides</div>
          </div>
          <div className={styles.rectangleGroup}>
          <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={searchdata.map((option) => option.BlogTitle)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search category"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
        onChange={handleChange}
      />
          </div>
        </div>
        <div className={styles.pricingGuidesParent}>
          <div className={styles.otherCategories}>Pricing Guides</div>
          <div className={styles.getClearInsights}>
            Get clear insights and explore the price ranges for different
            services, allowing you to budget effectively and make informed
            decisions for your home.
          </div>
        </div>
        <div className={styles.popularCategoriesParent}>
          <div className={styles.popularCategories}>Popular Categories</div>
          <div className={styles.frameDiv}>
          
              <Link  to={'/blog/for-consumers/pricing-guides/pricing-entertainers'}>
               <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src={'/entertainment.webp'}
                />
                <div className={styles.frameContainer}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainersWrapper}>
                      <div className={styles.entertainers}>Entertainers</div>
                    </div>
                  </div>
                </div>
              </div>
                </Link>
                <Link  to={'/blog/for-consumers/pricing-guides/pricing-caterers'}>
               <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src={'/caterer.webp'}
                />
                <div className={styles.frameContainer}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainersWrapper}>
                      <div className={styles.entertainers}>Catering</div>
                    </div>
                  </div>
                </div>
              </div>
                </Link>
                <Link  to={'/blog/for-consumers/pricing-guides/pricing-tradesmen'}>
               <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src={'/tradesmen.webp'}
                />
                <div className={styles.frameContainer}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainersWrapper}>
                      <div className={styles.entertainers}>Tradesman</div>
                    </div>
                  </div>
                </div>
              </div>
                </Link>
                <Link  to={'/blog/for-consumers/pricing-guides/pricing-recovery-services'}>
               <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src={'/recovery services.webp'}
                />
                <div className={styles.frameContainer}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainersWrapper}>
                      <div className={styles.entertainers}>Recovery Services</div>
                    </div>
                  </div>
                </div>
              </div>
                </Link>
                <Link  to={'/blog/for-consumers/pricing-guides/pricing-cleaners'}>
               <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src={'/cleaners.webp'}
                />
                <div className={styles.frameContainer}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainersWrapper}>
                      <div className={styles.entertainers}>Cleaning</div>
                    </div>
                  </div>
                </div>
              </div>
                </Link>
                <Link  to={'/blog/for-consumers/pricing-guides/pricing-pet-groomers'}>
               <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src={'/pet groomers.webp'}
                />
                <div className={styles.frameContainer}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainersWrapper}>
                      <div className={styles.entertainers}>Pet Groomers</div>
                    </div>
                  </div>
                </div>
              </div>
                </Link>
                <Link  to={'/blog/for-consumers/pricing-guides/pricing-transport-services'}>
               <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src={'/transport services.webp'}
                />
                <div className={styles.frameContainer}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainersWrapper}>
                      <div className={styles.entertainers}>Transport Services</div>
                    </div>
                  </div>
                </div>
              </div>
                </Link>
                <Link  to={'/blog/for-consumers/pricing-guides/pricing-dry-cleaning'}>
               <div className={styles.rectangleContainer}>
                <div className={styles.groupInner} />
                <img
                  className={styles.rectangleIcon}
                  alt=""
                  src={'/dry cleaning.webp'}
                />
                <div className={styles.frameContainer}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainersWrapper}>
                      <div className={styles.entertainers}>Dry Cleaning & Laundry</div>
                    </div>
                  </div>
                </div>
              </div>
                </Link>
          
       
          </div>
        </div>
        <div className={styles.otherCategoriesParent}>
          <div className={styles.otherCategories}>Other Categories</div>
          <br></br>
          <div className={styles.frameWrapper16}>
            <div className={styles.frameGroup}>
              <div className={styles.frameParent1}>
                <div className={styles.frameWrapper17} onClick={()=>navigate('/blog/for-consumers/pricing-guides/pricing-childcare-workers')}>
                  <div className={styles.blogGroup}>
                    <img className={styles.icons} alt="" src="/icons.svg" />
                    <div className={styles.searchCategory}>Childcare</div>
                  </div>
                </div>
                <div className={styles.frameWrapper17} onClick={()=>navigate('/blog/for-consumers/pricing-guides/pricing-appliance-repair-workers')}>
                  <div className={styles.blogGroup}>
                    <img className={styles.icons} alt="" src="/icons1.svg" />
                    <div className={styles.searchCategory}>
                      Appliance Repair
                    </div>
                  </div>
                </div>
                <div className={styles.frameWrapper17} onClick={()=>navigate('/blog/for-consumers/pricing-guides/pricing-mobile-mechanics')}>
                  <div className={styles.blogGroup}>
                    <img className={styles.icons} alt="" src="/icons2.svg" />
                    <div className={styles.searchCategory}>Mobile Mechanic</div>
                  </div>
                </div>
                <div className={styles.frameWrapper17} onClick={()=>navigate('/blog/for-consumers/pricing-guides/pricing-personal-assistants')}>
                  <div className={styles.blogGroup}>
                    <img className={styles.icons} alt="" src="/icons3.svg" />
                    <div className={styles.searchCategory}>
                      Personal Assistance
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameParent1}>
                <div className={styles.frameWrapper17} onClick={()=>navigate('/blog/for-consumers/pricing-guides/pricing-computer-&-phone-repair')}>
                  <div className={styles.blogGroup}>
                    <img className={styles.icons} alt="" src="/icons4.svg" />
                    <div
                      className={styles.searchCategory}
                    >{`Computer & Phone repair`}</div>
                  </div>
                </div>
                <div className={styles.frameWrapper17} onClick={()=>navigate('/blog/for-consumers/pricing-guides/pricing-removal-service-workers')}>
                  <div className={styles.blogGroup}>
                    <img className={styles.icons} alt="" src="/icons5.svg" />
                    <div className={styles.searchCategory}>
                      Removal Services
                    </div>
                  </div>
                </div>
                <div className={styles.frameWrapper17} onClick={()=>navigate('/blog/for-consumers/pricing-guides/pricing-health,-wellness-&-beauty')}>
                  <div className={styles.blogGroup}>
                    <img className={styles.icons} alt="" src="/icons6.svg" />
                    <div
                      className={styles.searchCategory}
                    >{`Health, Wellness & Beauty`}</div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper17} onClick={()=>navigate('/blog/for-consumers/pricing-guides/pricing-tuition-&-classes')}>
                <div className={styles.blogGroup}>
                  <img className={styles.icons} alt="" src="/icons7.svg" />
                  <div
                    className={styles.searchCategory}
                  >{`Tuition & Classes`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.subscribeForNewsletterParent}>
        <SubsBanner/>
          <PrototypeFooter/>
        </div>
      </div>
      <div className={styles.pricingGuidemob}>
      <div className={mobnav.navbarMobileclosed}>
          <img className={mobnav.layer2Icon} alt="" src="/layer-21.svg"
          style={{cursor:'pointer'}} onClick={()=>navigate('/')}  />
          <div className={mobnav.iconsaccountParent}>
            <div className={mobnav.iconsaccount} alt="" />
            <img className={mobnav.hamburgerIcon} alt="" src="/hamburger.svg" onClick={openMobileMenuProfProfessionalPopup} />
          </div>
        </div>
        <div className={styles.blogContainer}>
          <div className={styles.blog3} onClick={onBLOGTextClick}>BLOG</div>
          <img
            className={styles.caretrightIcon2}
            alt=""
            src="/caretright2.svg"
          />
          <div className={styles.blog3} onClick={onFORCONSUMERSTextClick}>FOR CONSUMERS</div>
          <img
            className={styles.caretrightIcon2}
            alt=""
            src="/caretright2.svg"
          />
          <div className={styles.pricingGuides1}>PRICING Guides</div>
        </div>
        <div className={styles.rectangleParent8}>
        <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={searchdata.map((option) => option.BlogTitle)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search category"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
        onChange={handleChange}
      />
        </div>
        <div className={styles.pricingGuidesGroup}>
          <div className={styles.otherCategories}>Pricing Guides</div>
          <div className={styles.getClearInsights1}>
            Get clear insights and explore the price ranges for different
            services, allowing you to budget effectively and make informed
            decisions for your home.
          </div>
        </div>
        <div className={styles.popularCategoriesGroup}>
          <div className={styles.otherCategories}>Popular Categories</div>
          <div className={styles.groupParent5}>
              <Link to={'/blog/for-consumers/pricing-guides/pricing-entertainers'}>
                  <div className={styles.rectangleParent9}>
              <div className={styles.groupChild16} />
              <img
                className={styles.groupChild17}
                alt=""
                src={'/entertainment.webp'}
              />
              <div className={styles.frameWrapper27}>
                <div className={styles.entertainersWrapper}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainers1}>Entertainers</div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
                <Link to={'/blog/for-consumers/pricing-guides/pricing-caterers'}>
                  <div className={styles.rectangleParent9}>
              <div className={styles.groupChild16} />
              <img
                className={styles.groupChild17}
                alt=""
                src={'/caterer.webp'}
              />
              <div className={styles.frameWrapper27}>
                <div className={styles.entertainersWrapper}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainers1}>Catering</div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
                <Link to={'/blog/for-consumers/pricing-guides/pricing-tradesmen'}>
                  <div className={styles.rectangleParent9}>
              <div className={styles.groupChild16} />
              <img
                className={styles.groupChild17}
                alt=""
                src={'/tradesmen.webp'}
              />
              <div className={styles.frameWrapper27}>
                <div className={styles.entertainersWrapper}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainers1}>Tradesman</div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
                <Link to={'/blog/for-consumers/pricing-guides/pricing-recovery-services'}>
                  <div className={styles.rectangleParent9}>
              <div className={styles.groupChild16} />
              <img
                className={styles.groupChild17}
                alt=""
                src={'/recovery services.webp'}
              />
              <div className={styles.frameWrapper27}>
                <div className={styles.entertainersWrapper}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainers1}>Recovery Services</div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
                <Link to={'/blog/for-consumers/pricing-guides/pricing-cleaners'}>
                  <div className={styles.rectangleParent9}>
              <div className={styles.groupChild16} />
              <img
                className={styles.groupChild17}
                alt=""
                src={'/cleaners.webp'}
              />
              <div className={styles.frameWrapper27}>
                <div className={styles.entertainersWrapper}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainers1}>Cleaning</div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
                <Link to={'/blog/for-consumers/pricing-guides/pricing-pet-groomers'}>
                  <div className={styles.rectangleParent9}>
              <div className={styles.groupChild16} />
              <img
                className={styles.groupChild17}
                alt=""
                src={'/pet groomers.webp'}
              />
              <div className={styles.frameWrapper27}>
                <div className={styles.entertainersWrapper}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainers1}>Pet Groomers</div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
                <Link to={'/blog/for-consumers/pricing-guides/pricing-transport-services'}>
                  <div className={styles.rectangleParent9}>
              <div className={styles.groupChild16} />
              <img
                className={styles.groupChild17}
                alt=""
                src={'/transport services.webp'}
              />
              <div className={styles.frameWrapper27}>
                <div className={styles.entertainersWrapper}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainers1}>Transport Services</div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
                <Link to={'/blog/for-consumers/pricing-guides/pricing-dry-cleaning'}>
                  <div className={styles.rectangleParent9}>
              <div className={styles.groupChild16} />
              <img
                className={styles.groupChild17}
                alt=""
                src={'/dry cleaning.webp'}
              />
              <div className={styles.frameWrapper27}>
                <div className={styles.entertainersWrapper}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainers1}>Dry Cleaning & Laundry</div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
            
          </div>
        </div>
        <div className={styles.otherCategoriesGroup}>
          <div className={styles.otherCategories}>Other Categories</div>
          <div className={styles.frameParent11}>
            <div className={styles.frameWrapper43} onClick={()=>navigate('/blog/for-consumers/pricing-guides/pricing-childcare-workers')}>
              <div className={styles.termsOfUseParent}>
                <img className={styles.icons8} alt="" src="/icons.svg" />
                <div className={styles.searchCategory}>Childcare</div>
              </div>
            </div>
            <div className={styles.frameWrapper43} onClick={()=>navigate('/blog/for-consumers/pricing-guides/pricing-appliance-repair-workers')}>
              <div className={styles.termsOfUseParent}>
                <img className={styles.icons8} alt="" src="/icons1.svg" />
                <div className={styles.searchCategory}>Appliance Repair</div>
              </div>
            </div>
            <div className={styles.frameWrapper43} onClick={()=>navigate('/blog/for-consumers/pricing-guides/pricing-mobile-mechanics')}>
              <div className={styles.termsOfUseParent}>
                <img className={styles.icons8} alt="" src="/icons2.svg" />
                <div className={styles.searchCategory}>Mobile Mechanic</div>
              </div>
            </div>
            <div className={styles.frameWrapper43} onClick={()=>navigate('/blog/for-consumers/pricing-guides/pricing-personal-assistants')}>
              <div className={styles.termsOfUseParent}>
                <img className={styles.icons8} alt="" src="/icons3.svg" />
                <div className={styles.searchCategory}>Personal Assistance</div>
              </div>
            </div>
            <div className={styles.frameWrapper43} onClick={()=>navigate('/blog/for-consumers/pricing-guides/pricing-computer-&-phone-repair')}>
              <div className={styles.termsOfUseParent}>
                <img className={styles.icons8} alt="" src="/icons4.svg" />
                <div
                  className={styles.computerPhone1}
                >{`Computer & Phone repair`}</div>
              </div>
            </div>
            <div className={styles.frameWrapper43} onClick={()=>navigate('/blog/for-consumers/pricing-guides/pricing-removal-service-workers')}>
              <div className={styles.termsOfUseParent}>
                <img className={styles.icons8} alt="" src="/icons5.svg" />
                <div className={styles.searchCategory}>Removal Services</div>
              </div>
            </div>
            <div className={styles.frameWrapper43} onClick={()=>navigate('/blog/for-consumers/pricing-guides/pricing-health,-wellness-&-beauty')}>
              <div className={styles.termsOfUseParent}>
                <img className={styles.icons8} alt="" src="/icons6.svg" />
                <div
                  className={styles.searchCategory}
                >{`Health, Wellness & Beauty`}</div>
              </div>
            </div>
            <div className={styles.frameWrapper43} onClick={()=>navigate('/blog/for-consumers/pricing-guides/pricing-tuition-&-classes')}>
              <div className={styles.termsOfUseParent}>
                <img className={styles.icons8} alt="" src="/icons7.svg" />
                <div
                  className={styles.searchCategory}
                >{`Tuition & Classes`}</div>
              </div>
            </div>
          </div>
        </div>
        <SubsBanner/>
        <MobileFooter/>
      </div>
      {isMobileMenuProfProfessionalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuProfProfessionalPopup}
        >
          <MenuMobile
            onClose={closeMobileMenuProfProfessionalPopup}
          />
        </PortalPopup>
      )}
    </div>
  );
};

export default PricingGuide1;
