import { useCallback } from "react";
import styles from '../Homeblog.module.css'
import { useNavigate } from 'react-router-dom';

function NewsTab() {
  const navigate = useNavigate();
  const onForConsumersTextClick = useCallback(() => {
    navigate("/blog/for-consumers");
  }, [navigate]);

  const onForProfessionalsTextClick = useCallback(() => {
    navigate("/blog/for-professionals");
  }, [navigate]);

  const onNewsUpdatesClick = useCallback(() => {
    navigate("/blog/news");
  }, [navigate]);
  return (
    <div>
                  <div className={styles.component7Parent}>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/news/product-updates')}>
                <div className={styles.hiringGuides4}>Product Updates</div>
              </div>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/news/partners')}>
                <div className={styles.hiringGuides4}>partners</div>
              </div>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/news/industry-news')}>
                <div className={styles.hiringGuides4}>industry News</div>
              </div>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/news/loggworks-in-the-news')}>
                <div className={styles.hiringGuides4}>
                  loggworks in the news
                </div>
              </div>
            </div>

          <div className={styles.frameParent51}>
              <div className={styles.tipsForFindingTheRightHomParent}>
                <div className={styles.frameParent52}>
                  <div className={styles.hiringGuidesWrapper}
                  onClick={()=>navigate('/blog/news/product-updates')}>
                    <div className={styles.managingYourBusiness}>
                      Product Updates
                    </div>
                  </div>
                  <div className={styles.hiringGuidesWrapper}
                  onClick={()=>navigate('/blog/news/partners')}>
                    <div className={styles.managingYourBusiness}>partners</div>
                  </div>
                </div>
                <div className={styles.frameParent52}>
                  <div className={styles.hiringGuidesWrapper}
                  onClick={()=>navigate('/blog/news/industry-news')}>
                    <div className={styles.managingYourBusiness}>
                      industry News
                    </div>
                  </div>
                  <div className={styles.hiringGuidesWrapper}
                  onClick={()=>navigate('/blog/news/loggworks-in-the-news')}>
                    <div className={styles.managingYourBusiness}>
                      loggworks in the news
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </div>
  )
}

export default NewsTab