import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Dropdown.module.css";
const Dropdown = ({ onClose }) => {
  const navigate = useNavigate();

  const onWebsiteDropdownContainerClick = useCallback(() => {
    navigate("/professionalhiring-guidedone");
  }, [navigate]);

  const onWebsiteDropdownContainer1Click = useCallback(() => {
    navigate("/professionalhiring-guidedone1");
  }, [navigate]);

  const onWebsiteDropdownContainer2Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  return (
    <div className={styles.dropdown}>
      <img className={styles.dropdownChild} alt="" src="/vector-2.svg" />
      <div className={styles.container}>
        <div
          className={styles.websiteDropdown}
          onClick={onWebsiteDropdownContainerClick}
        >
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Hiring guide</div>
                <div className={styles.whatIsA}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div
          className={styles.websiteDropdown}
          onClick={onWebsiteDropdownContainer1Click}
        >
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Pricing guide</div>
                <div className={styles.whatIsA}>What is a pricing guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div
          className={styles.websiteDropdown2}
          onClick={onWebsiteDropdownContainer2Click}
        >
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Blog</div>
                <div className={styles.whatIsA}>Latest news and articles</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown3}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>{`Training & Academy `}</div>
                <div
                  className={styles.whatIsA3}
                >{`What is training & academy?`}</div>
              </div>
              <div className={styles.professionalPill3}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare3}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown4}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Menu</div>
                <div className={styles.whatIsA3}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight4}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown4}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Menu</div>
                <div className={styles.whatIsA3}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight4}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown4}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Menu</div>
                <div className={styles.whatIsA3}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight4}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown4}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Menu</div>
                <div className={styles.whatIsA3}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight4}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown4}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Menu</div>
                <div className={styles.whatIsA3}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight4}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown4}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Menu</div>
                <div className={styles.whatIsA3}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight4}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown4}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Menu</div>
                <div className={styles.whatIsA3}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight4}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown4}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Menu</div>
                <div className={styles.whatIsA3}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight4}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown4}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Menu</div>
                <div className={styles.whatIsA3}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight4}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown4}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Menu</div>
                <div className={styles.whatIsA3}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight4}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown4}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Menu</div>
                <div className={styles.whatIsA3}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight4}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown4}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Menu</div>
                <div className={styles.whatIsA3}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight4}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown4}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Menu</div>
                <div className={styles.whatIsA3}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight4}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown4}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Menu</div>
                <div className={styles.whatIsA3}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight4}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown4}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Menu</div>
                <div className={styles.whatIsA3}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight4}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown4}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Menu</div>
                <div className={styles.whatIsA3}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight4}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown4}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}>
                <div className={styles.menu}>Menu</div>
                <div className={styles.whatIsA3}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder1.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight4}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
