import { useState, useCallback } from "react";
import Dropdown4 from "../components/Dropdown4";
import PortalPopup from "../components/PortalPopup";
import Dropdown5 from "../components/Dropdown5";
import MobileMenuConsumer1 from "../components/MobileMenuConsumer1";
import { useNavigate } from "react-router-dom";
import styles from "./Blogview.module.css";
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import MobileFooter from "../components/MobileFooter";

const Blogview = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(null);
  const open = Boolean(isDropdownOpen);
  const openDropdown = (event) => {
    setDropdownOpen(event.currentTarget);
  };
  const closeDropdown = () => {
    setDropdownOpen(null);
  };
  const [isDropdown1Open, setDropdown1Open] = useState(null);
  const open1 = Boolean(isDropdown1Open);
   const openDropdown1 = (event) => {
    setDropdown1Open(event.currentTarget);
  };
  const closeDropdown1 = () => {
    setDropdown1Open(null);
  };
  const [isMobileMenuConsumerPopupOpen, setMobileMenuConsumerPopupOpen] =
    useState(false);
  const navigate = useNavigate();

  const onPageSelectorContainerClick = useCallback(() => {
    navigate("/professional");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHowItWorksClick = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onFrameButtonClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onSmallButtonsClick = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const onHiringGuideText1Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onPostAJob2Click = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const onHowItWorks1Click = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQsClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementTextClick = useCallback(() => {
    navigate("/agreement");
  }, [navigate]);

  const onHowItWorks2Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onContactUsText1Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQs1Click = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementText1Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/becometeam");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/partners");
  }, [navigate]);

  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/affiliate-program");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onLargeButtons1Click = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const openMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(true);
  }, []);

  const closeMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(false);
  }, []);

  return (
    <>
      <div className={styles.blogview}>
        <div className={styles.professionalhiringGuidearti}>
          <nav className={styles.navigation}>
            <div className={styles.navigation1}>
              <img
                className={styles.navigationChild}
                alt=""
                onClick={()=>navigate('/')}
                style={{cursor:'pointer'}}
                src="/frame-66522.svg"
              />
              <div className={styles.navigationItem} />
              <div className={styles.tabSwitch}>
                <div
                  className={styles.pageSelector}
                  onClick={onPageSelectorContainerClick}
                >
                  <div className={styles.descriptionParent}>
                    <div className={styles.description}>Professional</div>
                    <div className={styles.selector} />
                  </div>
                </div>
                <div className={styles.pageSelector1}>
                  <div className={styles.descriptionParent}>
                    <b className={styles.description1}>Consumer</b>
                    <div className={styles.selector1} />
                  </div>
                </div>
              </div>
              <div className={styles.searchBar}>
                <div className={styles.iconssearchWrapper}>
                  <img
                    className={styles.iconssearch}
                    alt=""
                    src="/iconssearch.svg"
                  />
                </div>
                <div className={styles.electrician}>Electrician</div>
                <div className={styles.dividerParent}>
                  <div className={styles.divider} />
                  <div className={styles.location}>
                    <img
                      className={styles.iconslocation}
                      alt=""
                      src="/iconslocation.svg"
                    />
                    <div className={styles.locationOrPostcode}>
                      Location or postcode
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.navigation2}>
              <div className={styles.homeParent}>
                <div className={styles.home} onClick={onHomeTextClick}>
                  Home
                </div>
                <div className={styles.home} onClick={onHowItWorksClick}>
                  How it works
                </div>
                <div className={styles.services} 
                id="fade-button"
  aria-controls={open ? 'fade-menu' : undefined}
  aria-haspopup="true"
  aria-expanded={open ? 'true' : undefined}
                onClick={openDropdown}>
                  <div className={styles.services1}>Services</div>
                  <img
                    className={styles.iconssearch}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={isDropdownOpen}
        open={open}
        onClose={closeDropdown}
        TransitionComponent={Fade}
      >
      <Dropdown4/>
      </Menu>
                <div className={styles.resources} 
                id="fade-button2"
 aria-controls={open1 ? 'fade-menu2' : undefined}
 aria-haspopup="true"
 aria-expanded={open1 ? 'true' : undefined}
                onClick={openDropdown1}>
                  <div className={styles.services1}>Resources</div>
                  <img
                    className={styles.iconssearch}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                 <Menu
        id="fade-menu2"
        MenuListProps={{
          'aria-labelledby': 'fade-button2',
        }}
        anchorEl={isDropdown1Open}
        open={open1}
        onClose={closeDropdown1}
        TransitionComponent={Fade}
      >
      <Dropdown5/>
      </Menu>
                <div className={styles.pricing}>Pricing</div>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.frameGroup}>
                  <button
                    className={styles.iconshelpParent}
                    onClick={onFrameButtonClick}
                  >
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/iconshelp.svg"
                    />
                    <div className={styles.help}>Help</div>
                  </button>
                  <button className={styles.iconshelpParent}>
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/iconsaccount11.svg"
                    />
                    <div className={styles.help}>Account</div>
                  </button>
                </div>
                <button
                  className={styles.smallButtons}
                  onClick={onSmallButtonsClick}
                >
                  <div className={styles.iconsaddParent}>
                    <img
                      className={styles.iconsadd}
                      alt=""
                      src="/iconsadd16.svg"
                    />
                    <div className={styles.button}>Post a job</div>
                  </div>
                </button>
              </div>
            </div>
          </nav>
          <div className={styles.breadcrumbs}>
            <div className={styles.hiringGuideParent}>
              <div className={styles.hiringGuide}>Home</div>
              <img
                className={styles.iconschevronRight}
                alt=""
                src="/iconschevronright8.svg"
              />
              <div
                className={styles.hiringGuide1}
                onClick={onHiringGuideText1Click}
              >
                Blog
              </div>
              <img
                className={styles.iconschevronRight1}
                alt=""
                src="/iconschevronright9.svg"
              />
              <div className={styles.hiringGuide2}>
                5 Tips for Finding the Right Home Service Professionals
              </div>
              <img
                className={styles.iconschevronRight}
                alt=""
                src="/iconschevronright9.svg"
              />
              <div className={styles.hiringGuide3}>Hiring guide</div>
              <img
                className={styles.iconschevronRight}
                alt=""
                src="/iconschevronright9.svg"
              />
              <div className={styles.hiringGuide3}>Hiring guide</div>
              <img
                className={styles.iconschevronRight}
                alt=""
                src="/iconschevronright9.svg"
              />
              <div className={styles.hiringGuide3}>Hiring guide</div>
              <img
                className={styles.iconschevronRight}
                alt=""
                src="/iconschevronright9.svg"
              />
              <div className={styles.hiringGuide3}>Hiring guide</div>
              <img
                className={styles.iconschevronRight}
                alt=""
                src="/iconschevronright9.svg"
              />
              <div className={styles.hiringGuide3}>Hiring guide</div>
              <img
                className={styles.iconschevronRight}
                alt=""
                src="/iconschevronright9.svg"
              />
              <div className={styles.hiringGuide3}>Hiring guide</div>
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.merge}>
              <div className={styles.titleImg}>
                <div className={styles.tipsForFindingTheRightHomParent}>
                  <div className={styles.tipsForFinding}>
                    5 Tips for Finding the Right Home Service Professionals.
                  </div>
                  <div className={styles.articleCategory}>
                    <div className={styles.category}>ADVICE</div>
                  </div>
                </div>
                <img
                  className={styles.titleImgChild}
                  alt=""
                  src="/rectangle-123082@2x.png"
                />
              </div>
              <div className={styles.contentArea}>
                <div className={styles.findingABabysitterContainer}>
                  <p className={styles.findingABabysitter}>
                    Finding a babysitter you trust to care for your children can
                    be a daunting task. Here are some tips to help you find the
                    right person for the job:
                  </p>
                  <p className={styles.findingABabysitter}>&nbsp;</p>
                  <ul className={styles.lookForABabysitterWithExp}>
                    <li className={styles.checkReferencesAskForRefe}>
                      <span className={styles.lookForA}>
                        Look for a babysitter with experience
                      </span>
                      <span>
                        : It's important to find a babysitter who has experience
                        caring for children, especially if you have younger
                        children or children with special needs. This can give
                        you peace of mind knowing that the babysitter has the
                        skills and knowledge necessary to handle any situation
                        that may arise.
                      </span>
                    </li>
                    <li className={styles.checkReferencesAskForRefe}>
                      <span className={styles.lookForA}>Check references</span>
                      <span className={styles.askForReferences}>
                        : Ask for references from previous families the
                        babysitter has worked for, and follow up on those
                        references to make sure the babysitter has a track
                        record of being reliable and responsible. This can give
                        you an idea of the babysitter's work ethic and the
                        quality of care they provide.
                      </span>
                    </li>
                    <li className={styles.checkReferencesAskForRefe}>
                      <span className={styles.lookForA}>
                        Consider certification
                      </span>
                      <span className={styles.askForReferences}>
                        : Some babysitters may have certification in areas such
                        as CPR or first aid, which can be helpful in case of an
                        emergency. This can provide an extra layer of protection
                        for your children and give you added confidence in the
                        babysitter's ability to handle unexpected situations.
                      </span>
                    </li>
                    <li className={styles.checkReferencesAskForRefe}>
                      <span className={styles.lookForA}>
                        Set clear expectations
                      </span>
                      <span className={styles.askForReferences}>
                        : Make sure the babysitter knows your expectations for
                        the job, including the duties they will be responsible
                        for and any rules or guidelines you have for your
                        children. This can help ensure that the babysitter is on
                        the same page as you and can provide the level of care
                        you expect.
                      </span>
                    </li>
                    <li className={styles.checkReferencesAskForRefe}>
                      <span className={styles.lookForA}>
                        Discuss pay and scheduling
                      </span>
                      <span className={styles.askForReferences}>
                        : Be clear about how much you are willing to pay and
                        when you need the babysitter to be available. It's also
                        a good idea to have a backup plan in case the babysitter
                        is not available. This can help prevent
                        misunderstandings and ensure that everyone is on the
                        same page.
                      </span>
                    </li>
                    <li className={styles.checkReferencesAskForRefe}>
                      <span className={styles.lookForA}>
                        Conduct an interview
                      </span>
                      <span className={styles.askForReferences}>
                        : Consider conducting an in-person interview to get a
                        better sense of the babysitter's personality and
                        suitability for the job. This can be a good opportunity
                        to ask any questions you may have and get a feel for
                        whether the babysitter is a good fit for your family.
                      </span>
                    </li>
                    <li className={styles.checkReferencesAskForRefe}>
                      <span className={styles.lookForA}>
                        Trust your instincts
                      </span>
                      <span className={styles.askForReferences}>
                        : Ultimately, it's important to trust your instincts and
                        choose a babysitter you feel comfortable with and who
                        you believe will be a good fit for your family. Trusting
                        your gut can help you feel confident in your decision
                        and give you peace of mind knowing your children are in
                        good hands.
                      </span>
                    </li>
                  </ul>
                  <p className={styles.findingABabysitter}>&nbsp;</p>
                  <p className={styles.findingABabysitter}>
                    By following these tips, you can find a babysitter who is
                    reliable, responsible, and a good fit for your family. With
                    the right person in place, you can have the peace of mind
                    knowing your children are in good hands while you are away.
                  </p>
                  <p className={styles.findingABabysitter}>
                    Regenerate response
                  </p>
                </div>
                <div className={styles.frameContainer}>
                  <div className={styles.frameWrapper}>
                    <div className={styles.shareThisArticleParent}>
                      <div className={styles.shareThisArticle}>
                        Share this article:
                      </div>
                      <div className={styles.rifacebookBoxFillParent}>
                        <img
                          className={styles.iconssearch}
                          alt=""
                          src="/rifacebookboxfill.svg"
                        />
                        <img
                          className={styles.iconssearch}
                          alt=""
                          src="/ritwitterfill.svg"
                        />
                        <img
                          className={styles.iconssearch}
                          alt=""
                          src="/rilinkedinboxfill.svg"
                        />
                        <img
                          className={styles.iconssearch}
                          alt=""
                          src="/icroundmail.svg"
                        />
                        <img
                          className={styles.iconssearch}
                          alt=""
                          src="/rilink.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.ad}>
                    <div className={styles.merge1}>
                      <div className={styles.text}>
                        <div className={styles.doYouHave}>
                          Do you have a professional service to offer?
                        </div>
                        <div className={styles.postAJob}>
                          Post a job with your price in mind, then get offers
                        </div>
                      </div>
                      <div className={styles.smallButtons1}  style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                        <div className={styles.buttonWrapper}>
                          <div className={styles.button1}>
                            Become a Professional
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.ad1}>
                    <div className={styles.merge1}>
                      <div className={styles.text}>
                        <div className={styles.doYouHave}>
                          Can’t find the professional you are looking for?? Let
                          the professional find you.
                        </div>
                        <div className={styles.postAJob}>
                          Post a job with your price in mind, then get offers
                        </div>
                      </div>
                      <div className={styles.smallButtons1}>
                        <div className={styles.buttonWrapper}>
                          <div className={styles.button1}>Post a Job</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.prototypeFooter}>
            <div className={styles.footer}>
              <div className={styles.download}>
                <div className={styles.downloadOurApps}>Download our apps</div>
                <img
                  className={styles.playstoreButtonIcon}
                  alt=""
                  src="/playstore-button121.svg"
                />
                <img
                  className={styles.appstoreButtonIcon}
                  alt=""
                  src="/appstore-button13.svg"
                />
              </div>
              <div className={styles.footerChild} />
              <img className={styles.layer2Icon} 
               style={{cursor:'pointer'}}
               onClick={()=>{navigate('/')}}
               alt="" src="/layer-2101.svg" />
              <div className={styles.frameDiv}>
                <div className={styles.homeownersParent}>
                  <b className={styles.homeowners}>Homeowners</b>
                  <div className={styles.postAJobParent}>
                    <div
                      className={styles.contactUs}
                      onClick={onPostAJob2Click}
                    >
                      Post a Job
                    </div>
                    <div
                      className={styles.contactUs}
                      onClick={onHowItWorks1Click}
                    >
                      How it works
                    </div>
                    <div
                      className={styles.contactUs}
                      onClick={onContactUsTextClick}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.contactUs}
                      onClick={onHelpFAQsClick}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.contactUs}
                      onClick={onUserAgreementTextClick}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.professionalsParent}>
                  <b className={styles.homeowners}>Professionals</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.signUp}
                    style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                    <div
                      className={styles.contactUs}
                      onClick={onHowItWorks2Click}
                    >
                      Blog
                    </div>
                    <div
                      className={styles.contactUs}
                      onClick={onContactUsText1Click}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.contactUs}
                      onClick={onHelpFAQs1Click}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.contactUs}
                      onClick={onUserAgreementText1Click}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.loggworksParent}>
                  <b className={styles.homeowners}>Loggworks</b>
                  <div className={styles.postAJobParent}>
                    <div
                      className={styles.contactUs}
                      onClick={onAboutUsTextClick}
                    >
                      About us
                    </div>
                    <div
                      className={styles.contactUs}
                      onClick={onCareersTextClick}
                    >
                      Careers
                    </div>
                    <div
                      className={styles.contactUs}
                      onClick={onPartnersTextClick}
                    >
                      Partners
                    </div>
                    <div
                      className={styles.contactUs}
                      onClick={onInfluencerProgramTextClick}
                    >
                      Affiliate program
                    </div>
                    <div className={styles.blog}>Blog</div>
                  </div>
                </div>
                <div className={styles.loggworksGroup}>
                  <div className={styles.loggworks1}>
                    <b className={styles.followUs}>Follow us</b>
                  </div>
                  <div className={styles.groupParent}>
                    <div className={styles.groupDiv}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://web.facebook.com/Loggworks?_rdc=1&_rdr', '_blank')}>
                      <img
                        className={styles.frameIcon}
                        alt=""
                        src="/frame20.svg"
                      />
                      <div className={styles.facebook}>Facebook</div>
                    </div>
                    <div className={styles.frameParent1}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://twitter.com/loggworks', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame34.svg"
                      />
                      <div className={styles.twitter}>Twitter</div>
                    </div>
                    <div className={styles.frameParent2}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.instagram.com/loggworks/?utm_medium=copy_link', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame35.svg"
                      />
                      <div className={styles.twitter}>Instagram</div>
                    </div>
                    <div className={styles.frameParent3}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame36.svg"
                      />
                      <div className={styles.twitter}>YouTube</div>
                    </div>
                    <div className={styles.hiringGuideParent}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.linkedin.com/company/loggworks', '_blank')}>
                      <img
                        className={styles.mdilinkedinIcon}
                        alt=""
                        src="/mdilinkedin1.svg"
                      />
                      <div className={styles.linkedin}>LinkedIn</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.copyright2022LoggworkLtdAParent}>
                <div className={styles.description}>
                  ©Copyright 2024 loggworks Ltd. All Rights Reserved
                </div>
                <div className={styles.frameParent4}>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.description}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/terms')}}>Terms of Use</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div
                      className={styles.description}
                      style={{cursor:'pointer'}}
                      onClick={()=>{navigate('/privacy')}}
                    >{`Privacy Policy `}</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.description}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/cookie')}}>Cookies</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.contactUsParent}>
                    <div className={styles.description}>Contact Us</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.description} style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href='https://www.loggworks.co.uk/sitemap.xml'
                    }}>Sitemap</div>
                    <div className={styles.frameChild1} />
                  </div>
                </div>
              </div>
              <div className={styles.largeButtonsParent}>
                <button
                  className={styles.largeButtons}
                  style={{cursor:'pointer'}}
                  onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder47.svg"
                  />
                  <b className={styles.label}>Become a Professional</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder47.svg"
                  />
                </button>
                <button
                  className={styles.largeButtons}
                  onClick={onLargeButtons1Click}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder48.svg"
                  />
                  <b className={styles.label}>Post a Job</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder48.svg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.homeMobile}>
          <div className={styles.navbarMobileclosed}>
            <img className={styles.layer2Icon1} alt="" src="/layer-215.svg" 
            style={{cursor:'pointer'}} onClick={()=>navigate('/')} />
            <div className={styles.iconsaccountGroup}>
              <img
                className={styles.iconsaccount1}
                alt=""
                src="/iconsaccount10.svg"
              />
              <img
                className={styles.hamburgerIcon}
                alt=""
                src="/hamburger.svg"
                onClick={openMobileMenuConsumerPopup}
              />
            </div>
          </div>
          <div className={styles.merge}>
            <div className={styles.titleImg1}>
              <div className={styles.tipsForFindingTheRightHomGroup}>
                <div className={styles.tipsForFinding1}>
                  5 Tips for Finding the Right Home Service Professionals.
                </div>
                <div className={styles.articleCategory}>
                  <div className={styles.category}>ADVICE</div>
                </div>
              </div>
              <img
                className={styles.titleImgItem}
                alt=""
                src="/rectangle-123083@2x.png"
              />
            </div>
            <div className={styles.findingABabysitterYouTrustParent}>
              <div className={styles.discussPayAndContainer}>
                <p className={styles.findingABabysitter}>
                  Finding a babysitter you trust to care for your children can
                  be a daunting task. Here are some tips to help you find the
                  right person for the job:
                </p>
                <p className={styles.findingABabysitter}>&nbsp;</p>
                <ul className={styles.lookForABabysitterWithExp}>
                  <li className={styles.checkReferencesAskForRefe}>
                    <span className={styles.lookForA}>
                      Look for a babysitter with experience
                    </span>
                    <span>
                      : It's important to find a babysitter who has experience
                      caring for children, especially if you have younger
                      children or children with special needs. This can give you
                      peace of mind knowing that the babysitter has the skills
                      and knowledge necessary to handle any situation that may
                      arise.
                    </span>
                  </li>
                  <li className={styles.checkReferencesAskForRefe}>
                    <span className={styles.lookForA}>Check references</span>
                    <span className={styles.askForReferences}>
                      : Ask for references from previous families the babysitter
                      has worked for, and follow up on those references to make
                      sure the babysitter has a track record of being reliable
                      and responsible. This can give you an idea of the
                      babysitter's work ethic and the quality of care they
                      provide.
                    </span>
                  </li>
                  <li className={styles.checkReferencesAskForRefe}>
                    <span className={styles.lookForA}>
                      Consider certification
                    </span>
                    <span className={styles.askForReferences}>
                      : Some babysitters may have certification in areas such as
                      CPR or first aid, which can be helpful in case of an
                      emergency. This can provide an extra layer of protection
                      for your children and give you added confidence in the
                      babysitter's ability to handle unexpected situations.
                    </span>
                  </li>
                  <li>
                    <span className={styles.lookForA}>
                      Set clear expectations
                    </span>
                    <span className={styles.askForReferences}>
                      : Make sure the babysitter knows your expectations for the
                      job, including the duties they will be responsible for and
                      any rules or guidelines you have for your children. This
                      can help ensure that the babysitter is on the same page as
                      you and can provide the level of care you expect.
                    </span>
                  </li>
                </ul>
              </div>
              <div className={styles.ad2}>
                <div className={styles.loggworksContainer}>
                  <div className={styles.text2}>
                    <div className={styles.doYouHave1}>
                      Do you have a professional service to offer?
                    </div>
                    <div className={styles.postAJob}>
                      Post a job with your price in mind, then get offers
                    </div>
                  </div>
                  <div className={styles.smallButtons1}  style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                    <div className={styles.buttonWrapper}>
                      <div className={styles.button1}>
                        Become a Professional
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.discussPayAndContainer}>
                <p className={styles.findingABabysitter}>
                  <span className={styles.lookForA}>
                    Discuss pay and scheduling
                  </span>
                  <span>
                    : Be clear about how much you are willing to pay and when
                    you need the babysitter to be available. It's also a good
                    idea to have a backup plan in case the babysitter is not
                    available. This can help prevent misunderstandings and
                    ensure that everyone is on the same page.
                  </span>
                </p>
                <ul className={styles.lookForABabysitterWithExp}>
                  <li className={styles.checkReferencesAskForRefe}>
                    <span className={styles.lookForA}>
                      Conduct an interview
                    </span>
                    <span>
                      : Consider conducting an in-person interview to get a
                      better sense of the babysitter's personality and
                      suitability for the job. This can be a good opportunity to
                      ask any questions you may have and get a feel for whether
                      the babysitter is a good fit for your family.
                    </span>
                  </li>
                  <li className={styles.checkReferencesAskForRefe}>
                    <span className={styles.lookForA}>
                      Trust your instincts
                    </span>
                    <span className={styles.askForReferences}>
                      : Ultimately, it's important to trust your instincts and
                      choose a babysitter you feel comfortable with and who you
                      believe will be a good fit for your family. Trusting your
                      gut can help you feel confident in your decision and give
                      you peace of mind knowing your children are in good hands.
                    </span>
                  </li>
                </ul>
                <p className={styles.findingABabysitter}>&nbsp;</p>
                <p className={styles.findingABabysitter}>
                  By following these tips, you can find a babysitter who is
                  reliable, responsible, and a good fit for your family. With
                  the right person in place, you can have the peace of mind
                  knowing your children are in good hands while you are away.
                </p>
                <p className={styles.findingABabysitter}>Regenerate response</p>
              </div>
              <div className={styles.ad3}>
                <div className={styles.loggworksContainer}>
                  <div className={styles.text2}>
                    <div className={styles.doYouHave1}>
                      Can’t find the professional you are looking for?? Let the
                      professional find you.
                    </div>
                    <div className={styles.postAJob}>
                      Post a job with your price in mind, then get offers
                    </div>
                  </div>
                  <div className={styles.smallButtons1}>
                    <div className={styles.buttonWrapper}>
                      <div className={styles.button1}>Post a Job</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper1}>
                <div className={styles.tipsForFindingTheRightHomGroup}>
                  <div className={styles.shareThisArticle1}>
                    Share this article:
                  </div>
                  <div className={styles.rifacebookBoxFillParent}>
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/rifacebookboxfill1.svg"
                    />
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/ritwitterfill1.svg"
                    />
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/rilinkedinboxfill1.svg"
                    />
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/icroundmail1.svg"
                    />
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/rilink1.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
         <MobileFooter/>
        </div>
      </div>
      {isMobileMenuConsumerPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuConsumerPopup}
        >
          <MobileMenuConsumer1 onClose={closeMobileMenuConsumerPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default Blogview;
