import {
  ChevronDownIcon,
  ChevronUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import CategoryData from "./categoryData.tsx";
import { db } from "../firebase.js";
import { MenuItem } from "@mui/material";
import Highlighter from "react-highlight-words";

const ModalPopup: React.FC<{
  modalPop: boolean;
  setModalPopUp: (e: any) => any;
  setOptionSelected: any;
  optionSelected: any;
  setOptionSelectedIndex: any;
  optionSelectedIndex:any;
  setOthers:any;
  others:any
}> = ({
  modalPop,
  setModalPopUp,
  optionSelected,
  setOptionSelected,
  setOptionSelectedIndex,
  optionSelectedIndex,
  setOthers,
  others
}) => {
  const { categories } = CategoryData();

  const [optionToggle, setOptionToggle] = useState(
    Array(categories.length).fill(false)
  );
  const [selectedOption, setSelectedOption] = useState<number>(-1);
    const [catIndex, setCatIndex] = useState(-1);
    const [catOptionIndex, setCatOptionIndex] = useState(-1);
    const [sugg,setSugg]=useState([])
    const [value,setValue]=useState('')
    const [otherDial,setOtherDial]=useState(false)
    const [title,setTitle]=useState('')
    console.log("....", selectedOption);

    const handleToggle = (e: any, index: number) => {
      const newToggle = optionToggle.map((value, i) => i === index);
      setOptionToggle(newToggle);
      setSelectedOption(index);
      setCatOptionIndex(-1);
    };

    const [searchValue, setSearchValue] = useState("");
  const data = () => {
    if (searchValue) {
      const filteredCategories = categories.filter((category: any) =>
        category.title.toLowerCase().includes(searchValue.toLowerCase())
      );
      return filteredCategories;
    }
    return categories;
  };
    const handleSelected = (index: number, optionIndex: number) => {
      setOptionSelected(data()[index]);
      setSelectedOption(index);
    };

    console.log("Checking", optionSelected);

    const handleCheckBox = (mainIndex: number, optionIndex: number) => {
      if (mainIndex === catIndex && optionIndex === catOptionIndex) {
        return true;
      }
      return false;
    };
    async function getServiceTags(categ:any) {
      setValue(categ)
      try {
        const usersRef = db.collection('serviceTags')
    
        // Query Firestore for users
        const querySnapshot = await usersRef.get();
          const subs:any = [];
    
        querySnapshot.forEach((doc:any) => {
          const data = doc.data();
  
          data.id = doc.id;
          if(doc.id==title){
            data.subcategories.map((subs:any) => subs)
            .filter((x:any) => {
             if (x.toLowerCase().includes(categ.toLowerCase())||x.toLowerCase()==categ.toLowerCase()) {
              subs.push(x);
             }else{
              setSugg([]);
             }
            });
          }
        });
          setSugg(subs);
      } catch (error) {
        console.error('Error fetching users:', error);
        return [];
      }
    }
  return (
    <div className="relative">
      {" "}
      <div className="fixed modal-container z-50 w-full bg-green md:p-4 top-0 left-0 h-full flex items-center justify-center max-sm:mt-10">
        <div className=" max-sm:w-full max-sm:max-w-2xl  mx-auto ">
          <div className="bg-white  md:w-[511px] md:h-[500px] lp:w-[411px] lp:h-[500px] lg:w-[511px] lg:h-[500px] h-screen rounded-md shadow dark:bg-gray-700 md:px-8 px-6 py-6">
            <div className="flex items-start justify-between dark:border-gray-600">
              <h3 className="text-xl font-semibold text-dark">Categories</h3>
              <div
                className="close cursor-pointer"
                onClick={() => setModalPopUp(false)}
              >
                <XMarkIcon width={24} height={24} />
              </div>
            </div>

            <form action="" className="">
              <div className="search">
                <input
                  type="text"
                  className="appearance-none w-full text-gray-700 border border-red-500 rounded p-4 my-5 leading-tight focus:outline-none focus:bg-white bg-lightWhite border border-grey80"
                  placeholder="Search"
                  onChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                />
              </div>

              <div className="flex flex-col h-[550px] md:h-[330px] lp:h-[330px] lg:h-[330px] justify-between">
  <div className="max-h-[660px] overflow-y-auto">
    {categories
      .filter((x: any) =>
        x.title.toLowerCase().includes(searchValue.toLowerCase())
      )
      .map((option: any, index: number) => (
        option.title !== 'explore' ? (
          <div className="py-4 cursor-pointer" key={index}>
            <div
              className="option_head flex items-center justify-between"
              onClick={(e) => {
                handleToggle(e, index);
                setCatIndex(index);
                setTitle(option.title)
              }}
            >
              <div className="flex items-center">
                <img
                  src={`/assets/icons/category/${option.image}.svg`}
                  width={24}
                  height={24}
                  alt=""
                  className="mr-3"
                />
                <p>{option.title}</p>
              </div>
              {optionToggle[index] ? (
                <ChevronUpIcon width={24} height={24} />
              ) : (
                <ChevronDownIcon width={24} height={24} />
              )}
            </div>

            {optionToggle[index] && (
              <div className="option ml-5 mt-4">
                <ul>
                  {option?.options?.map((x: any, i: number) => (
                    <li
                      className="flex items-center mb-3"
                      key={i}
                      onClick={() => {
                        setOtherDial(true)
                        handleSelected(index, i);
                        setOptionSelectedIndex(i);
                        setCatOptionIndex(i);
                      }}
                    >
                      <label className="w-[24px] h-[24px] border border-green rounded-full flex items-center justify-center">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          className="form-checkbox rounded-full"
                          checked={handleCheckBox(index, i) ? true : false}
                        />
                      </label>
                      <p className="mx-3 capitalize">{x!='others'?x:`Others ${others?`(${others})`:''}`}</p>
                    </li>
                  ))}
                </ul>
  {optionSelected&&otherDial?
    optionSelected.options[optionSelectedIndex]=='others'?
    <>
    <div className="relative">
      <input
        type="text"
        value={value}
        onChange={(e)=>getServiceTags(e.target.value)}
        className="border border-gray-300 rounded-md py-2 px-4 w-full pr-10 focus:outline-none focus:border-blue-500"
        placeholder="Enter your job request type"
      />
        {
        value==''?
<button className="absolute inset-y-0 right-0 px-4 bg-blue-500 text-white rounded-md"
      style={{background:'white', color:'#2FA500', 
      borderColor:'#2FA500',borderStyle:'solid',borderWidth:'1.5px',margin:'5px'}}
      onClick={(e)=>{
        e.preventDefault()
        setOthers(value)
        setOtherDial(false)
        }}>
        Done
      </button>
      :
      <button className="absolute inset-y-0 right-0 px-4 bg-blue-500 text-white rounded-md bg-green"
      style={{color:'white',margin:'5px'}}
      onClick={(e)=>{
        e.preventDefault()
        setOthers(value)
        setOtherDial(false)
        }}>
        Add
      </button>
      }
      
    </div>
    <div>
    <b style={{color:"black",margin:'10px 0px'}}>Suggestions</b>
        {
         sugg.map((x:any, index:any) => (
          <MenuItem key={index}
          onClick={()=>{
            setValue(x)
          }}>
            <Highlighter
highlightClassName="YourHighlightClass"
searchWords={[value]}
autoEscape={true}
textToHighlight= {x}
/>
            </MenuItem> 
        ))
          }  
    </div>
    </>
    :null
    :null
    }
    
              </div>
            )}
          </div>
        ) : null
      ))}
  </div>

  <button
    className="relative bg-green text-white w-full h-[48px] font-bold py-2 px-4 rounded"
    onClick={() => setModalPopUp(!modalPop)}
  >
    Select Category
  </button>
</div>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPopup;