import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./News.module.css";
import ToolBar from "./components/ToolBar";
import PrototypeFooter from "../../components/PrototypeFooter";
import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import mobnav from "../ConsumerHome.module.css";
import MobileFooter from "../../components/MobileFooter";
import PortalPopup from "../../components/PortalPopup";
import MenuMobile from "./components/MenuMobile";
import { useEffect } from "react";
import axios from "axios";
import NewsTab from "./components/NewsTab";
import SubsBanner from "./components/SubsBanner";
import Highlighter from "react-highlight-words";

const News = () => {
  const [
    isMobileMenuProfProfessionalPopupOpen,
    setMobileMenuProfProfessionalPopupOpen,
  ] = useState(false);
  const openMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(true);
  }, []);

  const closeMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(false);
  }, []);
  const navigate = useNavigate();

  const onBLOGTextClick = useCallback(() => {
    navigate("/blog");
  }, [navigate]);

  const onProductUpdatesTextClick = useCallback(() => {
    navigate('/blog/news/product-updates')
  }, []);

  const onFrameContainer1Click = useCallback(() => {
    // Please sync "News & Updates | Product Updates" to the project
  }, []);

  const onPartnersTextClick = useCallback(() => {
    navigate('/blog/news/partners')
  }, []);

  const onFrameContainer12Click = useCallback(() => {
    // Please sync "News & Updates | Partners" to the project
  }, []);

  const onIndustryNewsTextClick = useCallback(() => {
    navigate('/blog/news/industry-news')
  }, []);

  const onFrameContainer13Click = useCallback(() => {
    // Please sync "News & Updates | Industry News" to the project
  }, []);

  const onLoggworksInTheClick = useCallback(() => {
    navigate('/blog/news/loggworks-in-the-news')
  }, []);

  const onFrameContainer14Click = useCallback(() => {
    // Please sync "News & Updates | Loggworks In The News" to the project
  }, []);
  const [industry, setIndustry]=useState([])
  const [product, setProduct]=useState([])
  const [partner, setPartner]=useState([])
  const [loggworks, setLoggworks]=useState([])
  const [popular, setPopular]=useState([])
  const [combined, setCombined]=useState([])
  const [searchWord, setSearchWord] = useState("");
  const [feature_cons, setFeatureCons]=useState([])

  useEffect(() => {
    axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/news?populate=*')
    .then(response => {
      const blogsData = response.data.data;
      // Limiting to the first 3 blogs
      const featuredBlogs = blogsData.filter(blog => blog.attributes.category === 'featured');
      const popularBlogs = blogsData.filter(blog => blog.attributes.category === 'popular');
      const productBlogs = blogsData.filter(blog => blog.attributes.category === 'Product Updates');
      const partnerBlogs = blogsData.filter(blog => blog.attributes.category === 'Partners');
      const loggworksBlogs = blogsData.filter(blog => blog.attributes.category === 'Loggworks In The News');
      const industryBlogs = blogsData.filter(blog => blog.attributes.category === 'Industry News');
      setPopular(popularBlogs.slice(0, 3))
      setProduct(productBlogs.slice(0, 3));
      setPartner(partnerBlogs.slice(0,3))
      setLoggworks(loggworksBlogs.slice(0,3))
      setIndustry(industryBlogs.slice(0,3))
      setFeatureCons(featuredBlogs.slice(0, 1)); // Limiting to the first featured blog
      const combinedBlogs = [
        ...featuredBlogs.slice(0, 1), // Limiting to the first featured blog
        ...popularBlogs.slice(0,3),
        ...productBlogs.slice(0, 3),
        ...partnerBlogs.slice(0, 3),
        ...loggworksBlogs.slice(0, 3),
        ...industryBlogs.slice(0, 3),
      ];
      
      setCombined(combinedBlogs)
    })
    .catch(error => {
      console.error('Error fetching blogs:', error);
    });
  }, []);
  const formatDateToWords = (dateString) => {
    const date = new Date(dateString);
    
    // Array of month names
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    // Get the components of the date
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    
    // Construct the formatted date string
    const formattedDate = `${month} ${day}, ${year}`;
    
    return formattedDate;
  };
  const handleChange = (event, value) => {
    if (value) {
      combined.filter(blog => blog.attributes.BlogTitle === value).map(data=>{
         navigate(`/blog/news/${data.attributes.slug}`);
      })
     
    }
  };
  const searchBlogs = (searchText) => {
   
  };
  return (
    <div className={styles.news}>
      <div className={styles.newsUpdates}>
        <div className={styles.newsUpdatesInner}>
        <ToolBar/>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.blogGroup}>
            <div className={styles.blog1} onClick={onBLOGTextClick}>
              BLOG
            </div>
            <img
              className={styles.arrowrightIcon}
              alt=""
              src="/caretright.svg"
            />
            <div className={styles.newsUpdates2}>{`News & Updates`}</div>
          </div>
          <div className={styles.rectangleGroup}>
          <Autocomplete
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      options={combined.map((option) => option.attributes.BlogTitle)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search articles"
          onChange= {(e) => {
            searchBlogs(e.target.value)
            setSearchWord(e.target.value)}}
          InputProps={{
            ...params.InputProps,
            type: "search",
            
          }}
        />
      )}
      
      onChange={handleChange}
      autoHighlight  

    />
          </div>
        </div>
        <div className={styles.newsUpdatesGroup}>
          <div className={styles.mostPopularArticles}>{`News & Updates`}</div>
          <div className={styles.readAboutTheContainer}>
            <p className={styles.readAboutThe}>
              Read about the latest features, announcements, events and
              developments
            </p>
            <p className={styles.readAboutThe}>related to Loggworks.</p>
          </div> 
          <NewsTab/>
        </div>
        {feature_cons.map(blog => (
                <Link key={blog.id} to={'/blog/news/'+blog.attributes.slug}>
                 <div className={styles.rectangleContainer}>
          <img
            className={styles.rectangleIcon}
            alt=""
            src={blog.attributes.BlogImage.data.attributes.url}
          />
          <div className={styles.frameGroup}>
            <div className={styles.enhancedUserExperienceWhatParent}>
              <b className={styles.enhancedUserExperience}>
              <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
              </b>
              <div className={styles.loggworksHelpsYou}>
              <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
              </div>
            </div>
            <div className={styles.christinaWilliamsParent}>
              <div className={styles.christinaWilliams}>{blog.attributes.Author}</div>
              <div className={styles.ellipseDiv} />
              <div className={styles.january18th2024}>{formatDateToWords(blog.attributes.createdAt)}</div>
            </div>
            <div className={styles.frameContainer}>
              <div className={styles.newsUpdatesWrapper}>
                <div className={styles.newsUpdates4}>{blog.attributes.category}</div>
              </div>
              <div className={styles.minsRead}>{blog.attributes.duration} mins read</div>
            </div>
          </div>
        </div>
                </Link>
             ))}

        
        <div className={styles.mostPopularArticlesParent}>
          <div className={styles.mostPopularArticles}>
            Most Popular Articles
          </div>
          <div className={styles.frameDiv}>
            <div className={styles.groupParent1}>
            {popular.map(blog => (
                <Link key={blog.id} to={'/blog/news/'+blog.attributes.slug}>
                  <div className={styles.groupDiv}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild1}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent1}>
                  <div className={styles.blogGroup}>
                    <div className={styles.partnersWrapper}>
                      <div className={styles.partners}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.loggworksAndGoogleElevateIParent}>
                    <b className={styles.loggworksAndGoogle}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.embarkingOnA}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                    </div>
                  </div>
                  <div className={styles.christinaWilliamsGroup}>
                    <div className={styles.christinaWilliams1}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild1} />
                    <div className={styles.january18th20241}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
                </Link>
             ))}
              
            </div>
          </div>
        </div>
        <div className={styles.frameParent7}>
          <div className={styles.mostPopularArticlesParent}>
            <div
              className={styles.productUpdates}
              onClick={onProductUpdatesTextClick}
            >
              Product Updates
            </div>
            <div className={styles.groupParent2}>
            {product.map(blog => (
                <Link key={blog.id} to={'/blog/news/'+blog.attributes.slug}>
                  <div className={styles.groupDiv}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild1}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent1}>
                  <div className={styles.blogGroup}>
                    <div className={styles.partnersWrapper}>
                      <div className={styles.partners}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.loggworksAndGoogleElevateIParent}>
                    <b className={styles.loggworksAndGoogle}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.embarkingOnA}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                    </div>
                  </div>
                  <div className={styles.christinaWilliamsGroup}>
                    <div className={styles.christinaWilliams1}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild1} />
                    <div className={styles.january18th20241}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
                </Link>
             ))}
            </div>
          </div>
          {
            product.length>3&&
             <div
            className={styles.smallButtonsWrapper}
            onClick={onProductUpdatesTextClick}
          >
            <div className={styles.smallButtons1}>
              <div className={styles.viewMoreArticlesWrapper}>
                <div className={styles.viewMoreArticles}>
                  View More Articles
                </div>
              </div>
              <img className={styles.iconsadd} alt="" src="/caretright.svg" />
            </div>
          </div>
          }
         
        </div>
        <div className={styles.frameParent7}>
          <div className={styles.mostPopularArticlesParent}>
            <div
              className={styles.productUpdates}
              onClick={onPartnersTextClick}
            >
              partners
            </div>
            <div className={styles.groupParent2}>
            {partner.map(blog => (
                <Link key={blog.id} to={'/blog/news/'+blog.attributes.slug}>
                  <div className={styles.groupDiv}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild1}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent1}>
                  <div className={styles.blogGroup}>
                    <div className={styles.partnersWrapper}>
                      <div className={styles.partners}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.loggworksAndGoogleElevateIParent}>
                    <b className={styles.loggworksAndGoogle}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.embarkingOnA}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                    </div>
                  </div>
                  <div className={styles.christinaWilliamsGroup}>
                    <div className={styles.christinaWilliams1}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild1} />
                    <div className={styles.january18th20241}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
                </Link>
             ))}
            </div>
          </div>
          {
            partner.length>3&&
            <div
            className={styles.smallButtonsWrapper}
            onClick={onPartnersTextClick}
          >
            <div className={styles.smallButtons1}>
              <div className={styles.viewMoreArticlesWrapper}>
                <div className={styles.viewMoreArticles}>
                  View More Articles
                </div>
              </div>
              <img className={styles.iconsadd} alt="" src="/caretright.svg" />
            </div>
          </div>
          }
          
        </div>
        <div className={styles.frameParent7}>
          <div className={styles.mostPopularArticlesParent}>
            <div
              className={styles.productUpdates}
              onClick={onIndustryNewsTextClick}
            >
              industry News
            </div>
            <div className={styles.groupParent2}>
            {industry.map(blog => (
                <Link key={blog.id} to={'/blog/news/'+blog.attributes.slug}>
                  <div className={styles.groupDiv}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild1}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent1}>
                  <div className={styles.blogGroup}>
                    <div className={styles.partnersWrapper}>
                      <div className={styles.partners}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.loggworksAndGoogleElevateIParent}>
                    <b className={styles.loggworksAndGoogle}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.embarkingOnA}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                    </div>
                  </div>
                  <div className={styles.christinaWilliamsGroup}>
                    <div className={styles.christinaWilliams1}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild1} />
                    <div className={styles.january18th20241}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
                </Link>
             ))}
            </div>
          </div>
          {
            industry.length>3&&
            <div
            className={styles.smallButtonsWrapper}
            onClick={onIndustryNewsTextClick}
          >
            <div className={styles.smallButtons1}>
              <div className={styles.viewMoreArticlesWrapper}>
                <div className={styles.viewMoreArticles}>
                  View More Articles
                </div>
              </div>
              <img className={styles.iconsadd} alt="" src="/caretright.svg" />
            </div>
          </div>
          }
          
        </div>
        <SubsBanner/>
        <div className={styles.frameParent7}>
          <div className={styles.mostPopularArticlesParent}>
            <div
              className={styles.productUpdates}
              onClick={onLoggworksInTheClick}
            >
              loggworks in the news
            </div>
            <div className={styles.groupParent2}>
            {loggworks.map(blog => (
                <Link key={blog.id} to={'/blog/news/'+blog.attributes.slug}>
                  <div className={styles.groupDiv}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild1}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent1}>
                  <div className={styles.blogGroup}>
                    <div className={styles.partnersWrapper}>
                      <div className={styles.partners}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.loggworksAndGoogleElevateIParent}>
                    <b className={styles.loggworksAndGoogle}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.embarkingOnA}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                    </div>
                  </div>
                  <div className={styles.christinaWilliamsGroup}>
                    <div className={styles.christinaWilliams1}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild1} />
                    <div className={styles.january18th20241}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
                </Link>
             ))}
            </div>
          </div>
          {
            loggworks.length>3&&
             <div
            className={styles.smallButtonsWrapper}
            onClick={onLoggworksInTheClick}
          >
            <div className={styles.smallButtons1}>
              <div className={styles.viewMoreArticlesWrapper}>
                <div className={styles.viewMoreArticles}>
                  View More Articles
                </div>
              </div>
              <img className={styles.iconsadd} alt="" src="/caretright.svg" />
            </div>
          </div>
          }
         
        </div>
        <div className={styles.prototypeFooter}>
        <PrototypeFooter/>
        </div>
      </div>
      <div className={styles.newsUpdatesmob}>
      <div className={mobnav.navbarMobileclosed}>
          <img className={mobnav.layer2Icon} alt="" src="/layer-21.svg"
          style={{cursor:'pointer'}} onClick={()=>navigate('/')}  />
          <div className={mobnav.iconsaccountParent}>
            <div className={mobnav.iconsaccount} alt="" />
            <img className={mobnav.hamburgerIcon} alt="" src="/hamburger.svg" onClick={openMobileMenuProfProfessionalPopup} />
          </div>
        </div>
        <div className={styles.blogContainer}>
          <div className={styles.blog3} onClick={onBLOGTextClick}>BLOG</div>
          <img
            className={styles.caretrightIcon5}
            alt=""
            src="/caretright.svg"
          />
          <div className={styles.newsUpdates2}>{`News & Updates`}</div>
        </div>
        <div className={styles.rectangleParent16}>
        <Autocomplete
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      options={combined.map((option) => option.attributes.BlogTitle)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search articles"
          onChange= {(e) => {
            searchBlogs(e.target.value)
            setSearchWord(e.target.value)}}
          InputProps={{
            ...params.InputProps,
            type: "search",
            
          }}
        />
      )}
      
      onChange={handleChange}
      autoHighlight  

    />
        </div>
        <div className={styles.frameParent43}>
          <div className={styles.newsUpdatesContainer}>
            <div className={styles.mostPopularArticles}>{`News & Updates`}</div>
            <div className={styles.readAboutTheContainer1}>
              <p className={styles.readAboutThe}>
                Read about the latest features, announcements, events and
                developments
              </p>
              <p className={styles.readAboutThe}>related to Loggworks.</p>
            </div>
          </div>
          <NewsTab/>
        </div>
        {feature_cons.map(blog => (
                <Link key={blog.id} to={'/blog/news/'+blog.attributes.slug}>
             <div className={styles.rectangleParent17}>
          <img
            className={styles.frameChild21}
            alt=""
            src={blog.attributes.BlogImage.data.attributes.url}
          />
          <div className={styles.rectangleParent18}>
            <div className={styles.groupChild31} />
            <div className={styles.frameParent47}>
              <div className={styles.frameParent48}>
                <div className={styles.newsUpdatesFrame}>
                  <div className={styles.newsUpdates7}>{blog.attributes.category}</div>
                </div>
                <div className={styles.minsRead16}>{blog.attributes.duration} mins read</div>
              </div>
              <div className={styles.frameParent49}>
                <div className={styles.enhancedUserExperienceWhatGroup}>
                  <b className={styles.enhancedUserExperience1}>
                  <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                  </b>
                  <div className={styles.loggworksHelpsYou8}>
                  <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                  </div>
                </div>
                <div className={styles.christinaWilliamsParent14}>
                  <div className={styles.christinaWilliams16}>
                  {blog.attributes.Author}
                  </div>
                  <div className={styles.ellipseDiv} />
                  <div className={styles.january18th202416}>
                  {formatDateToWords(blog.attributes.createdAt)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
                </Link>
             ))}
       
        <div className={styles.mostPopularArticlesGroup}>
          <div className={styles.mostPopularArticles}>
            Most Popular Articles
          </div>
          <div className={styles.frameParent50}>
          {popular.map(blog => (
                <Link key={blog.id} to={'/blog/news/'+blog.attributes.slug}>
           <div className={styles.groupWrapper}>
              <div className={styles.rectangleParent19}>
                <div className={styles.groupChild32} />
                <img
                  className={styles.groupChild33}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent51}>
                  <div className={styles.frameParent52}>
                    <div className={styles.partnersWrapper3}>
                      <div className={styles.partners7}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead17}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.loggworksAndGoogleElevateIContainer}>
                    <b className={styles.loggworksAndGoogle2}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.loggworksHelpsYou9}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                      </div>
                  </div>
                  <div className={styles.christinaWilliamsParent15}>
                    <div className={styles.christinaWilliams17}>
                    {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild23} />
                    <div className={styles.january18th202417}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
            
          </div>
        </div>
        <div className={styles.mostPopularArticlesGroup}>
          <div className={styles.mostPopularArticles}
           onClick={onProductUpdatesTextClick}>Product Updates</div>
          <div className={styles.frameParent50}>
          {product.map(blog => (
                <Link key={blog.id} to={'/blog/news/'+blog.attributes.slug}>
           <div className={styles.groupWrapper}>
              <div className={styles.rectangleParent19}>
                <div className={styles.groupChild32} />
                <img
                  className={styles.groupChild33}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent51}>
                  <div className={styles.frameParent52}>
                    <div className={styles.partnersWrapper3}>
                      <div className={styles.partners7}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead17}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.loggworksAndGoogleElevateIContainer}>
                    <b className={styles.loggworksAndGoogle2}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.loggworksHelpsYou9}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                      </div>
                  </div>
                  <div className={styles.christinaWilliamsParent15}>
                    <div className={styles.christinaWilliams17}>
                    {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild23} />
                    <div className={styles.january18th202417}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
              {
            product.length>3&&
             <div className={styles.largeButtons2}
            onClick={onProductUpdatesTextClick}>
              <b className={styles.viewMoreArticles4}>View More Articles</b>
              <img className={styles.iconsadd1} alt="" src="/iconsadd4.svg" />
            </div>
          }
           
          </div>
        </div>
        <div className={styles.mostPopularArticlesGroup}>
          <div className={styles.partners8}
          onClick={onPartnersTextClick}>partners</div>
          <div className={styles.frameParent50}>
          {partner.map(blog => (
                <Link key={blog.id} to={'/blog/news/'+blog.attributes.slug}>
           <div className={styles.groupWrapper}>
              <div className={styles.rectangleParent19}>
                <div className={styles.groupChild32} />
                <img
                  className={styles.groupChild33}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent51}>
                  <div className={styles.frameParent52}>
                    <div className={styles.partnersWrapper3}>
                      <div className={styles.partners7}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead17}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.loggworksAndGoogleElevateIContainer}>
                    <b className={styles.loggworksAndGoogle2}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.loggworksHelpsYou9}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                      </div>
                  </div>
                  <div className={styles.christinaWilliamsParent15}>
                    <div className={styles.christinaWilliams17}>
                    {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild23} />
                    <div className={styles.january18th202417}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
              {
            partner.length>3&&
             <div className={styles.largeButtons2}
            onClick={onPartnersTextClick}>
              <b className={styles.viewMoreArticles4}>View More Articles</b>
              <img className={styles.iconsadd1} alt="" src="/iconsadd4.svg" />
            </div>
          }
           
          </div>
        </div>
        <div className={styles.mostPopularArticlesGroup}>
          <div className={styles.partners8} 
          onClick={onIndustryNewsTextClick}>industry News</div>
          <div className={styles.frameParent50}>
          {industry.map(blog => (
                <Link key={blog.id} to={'/blog/news/'+blog.attributes.slug}>
           <div className={styles.groupWrapper}>
              <div className={styles.rectangleParent19}>
                <div className={styles.groupChild32} />
                <img
                  className={styles.groupChild33}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent51}>
                  <div className={styles.frameParent52}>
                    <div className={styles.partnersWrapper3}>
                      <div className={styles.partners7}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead17}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.loggworksAndGoogleElevateIContainer}>
                    <b className={styles.loggworksAndGoogle2}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.loggworksHelpsYou9}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                      </div>
                  </div>
                  <div className={styles.christinaWilliamsParent15}>
                    <div className={styles.christinaWilliams17}>
                    {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild23} />
                    <div className={styles.january18th202417}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
              {
            industry.length>3&&
               <div className={styles.largeButtons2}
            onClick={onIndustryNewsTextClick}>
              <b className={styles.viewMoreArticles4}>View More Articles</b>
              <img className={styles.iconsadd1} alt="" src="/iconsadd4.svg" />
            </div>
          }
         
          </div>
        </div>
        <SubsBanner/>
        <div className={styles.mostPopularArticlesGroup}>
          <div className={styles.partners8}
          onClick={onLoggworksInTheClick}>loggworks in the news</div>
          <div className={styles.frameParent50}>
          {loggworks.map(blog => (
                <Link key={blog.id} to={'/blog/news/'+blog.attributes.slug}>
           <div className={styles.groupWrapper}>
              <div className={styles.rectangleParent19}>
                <div className={styles.groupChild32} />
                <img
                  className={styles.groupChild33}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent51}>
                  <div className={styles.frameParent52}>
                    <div className={styles.partnersWrapper3}>
                      <div className={styles.partners7}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead17}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.loggworksAndGoogleElevateIContainer}>
                    <b className={styles.loggworksAndGoogle2}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.loggworksHelpsYou9}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                      </div>
                  </div>
                  <div className={styles.christinaWilliamsParent15}>
                    <div className={styles.christinaWilliams17}>
                    {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild23} />
                    <div className={styles.january18th202417}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
              {
            loggworks.length>3&&
            <div className={styles.largeButtons2}
            onClick={onLoggworksInTheClick}>
              <b className={styles.viewMoreArticles4}>View More Articles</b>
              <img className={styles.iconsadd1} alt="" src="/iconsadd4.svg" />
            </div>
          }
            
          </div>
        </div>
       <MobileFooter/>
      </div>
      {isMobileMenuProfProfessionalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuProfProfessionalPopup}
        >
          <MenuMobile
            onClose={closeMobileMenuProfProfessionalPopup}
          />
        </PortalPopup>
      )}
    </div>
  );
};

export default News;
