import { useState, useCallback } from "react";
import { Alert, Snackbar,TextField } from "@mui/material";
import Dropdown4 from "../components/Dropdown4";
import PortalPopup from "../components/PortalPopup";
import Dropdown5 from "../components/Dropdown5";
import MobileMenuConsumer1 from "../components/MobileMenuConsumer1";
import { useNavigate } from "react-router-dom";
import styles from "./ContactUs.module.css";
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import MobileFooter from "../components/MobileFooter";
import LocationSearch from "../components/locationSearch.tsx";
import axios from "axios";
import emailjs from '@emailjs/browser';

const ContactUs = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(null);
  const open = Boolean(isDropdownOpen);
  const [sent,setSent]=useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    subject: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    emailjs.send('service_aevay3b', 'template_17iyssx', formData, 'gzpbhzMxxKH0LNmix')
    .then((result) => {
      console.log(result.text);
      setFormData({  firstName: '',
      lastName: '',
      email: '',
      message: '',
      subject: '' });
      setSent(true)
    }, (error) => {
      console.log(error);
      alert('Failed to send message. Please try again later.');
    });
  };
  const openDropdown = (event) => {
    setDropdownOpen(event.currentTarget);
  };
  const closeDropdown = () => {
    setDropdownOpen(null);
  };
  const [isDropdown1Open, setDropdown1Open] = useState(null);
  const open1 = Boolean(isDropdown1Open);
   const openDropdown1 = (event) => {
    setDropdown1Open(event.currentTarget);
  };
  const closeDropdown1 = () => {
    setDropdown1Open(null);
  };
  const [isMobileMenuConsumerPopupOpen, setMobileMenuConsumerPopupOpen] =
    useState(false);
  const navigate = useNavigate();

  const onPostAJobClick = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onHowItWorksClick = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQsClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementTextClick = useCallback(() => {
    navigate("/agreement");
  }, [navigate]);

  const onHowItWorks1Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onContactUsText1Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQs1Click = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementText1Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/becometeam");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/partners");
  }, [navigate]);

  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/affiliate-program");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onLargeButtons1Click = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onFrameContainer26Click = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onPageSelectorContainerClick = useCallback(() => {
    navigate("/professional");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHowItWorks2Click = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onFrameButtonClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onSmallButtonsClick = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const openMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(true);
  }, []);

  const closeMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(false);
  }, []);

  return (
    <>
      <div className={styles.contactUs}>
        <div className={styles.consumerhelpcontactdone}>
          <div className={styles.prototypeFooterParent}>
            <div className={styles.prototypeFooter}>
              <div className={styles.footer}>
                <div className={styles.download}>
                  <div className={styles.downloadOurApps}>
                    Download our apps
                  </div>
                  <img
                    className={styles.playstoreButtonIcon}
                    alt=""
                    src="/playstore-button14.svg"
                  />
                  <img
                    className={styles.appstoreButtonIcon}
                    alt=""
                    src="/appstore-button15.svg"
                  />
                </div>
                <div className={styles.footerChild} />
                <img
                  className={styles.layer2Icon}
                  alt=""
                  style={{cursor:'pointer'}}
                  onClick={()=>{navigate('/')}}
                  src="/layer-2111.svg"
                />
                <div className={styles.frameParent}>
                  <div className={styles.homeownersParent}>
                    <b className={styles.homeowners}>Homeowners</b>
                    <div className={styles.postAJobParent}>
                      <div
                        className={styles.postAJob}
                        onClick={onPostAJobClick}
                      >
                        Post a Job
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onHowItWorksClick}
                      >
                        How it works
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onContactUsTextClick}
                      >
                        Contact Us
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onHelpFAQsClick}
                      >{`Help & FAQ’s`}</div>
                      <div
                        className={styles.postAJob}
                        onClick={onUserAgreementTextClick}
                      >
                        User Agreement
                      </div>
                    </div>
                  </div>
                  <div className={styles.professionalsParent}>
                    <b className={styles.homeowners}>Professionals</b>
                    <div className={styles.postAJobParent}>
                      <div className={styles.signUp}
                      style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                      <div
                        className={styles.postAJob}
                        onClick={onHowItWorks1Click}
                      >
                        Blog
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onContactUsText1Click}
                      >
                        Contact Us
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onHelpFAQs1Click}
                      >{`Help & FAQ’s`}</div>
                      <div
                        className={styles.postAJob}
                        onClick={onUserAgreementText1Click}
                      >
                        User Agreement
                      </div>
                    </div>
                  </div>
                  <div className={styles.loggworksParent}>
                    <b className={styles.homeowners}>Loggworks</b>
                    <div className={styles.postAJobParent}>
                      <div
                        className={styles.postAJob}
                        onClick={onAboutUsTextClick}
                      >
                        About us
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onCareersTextClick}
                      >
                        Careers
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onPartnersTextClick}
                      >
                        Partners
                      </div>
                      <div
                        className={styles.postAJob}
                        onClick={onInfluencerProgramTextClick}
                      >
                        Affiliate program
                      </div>
                      <div className={styles.blog}>Blog</div>
                    </div>
                  </div>
                  <div className={styles.loggworksGroup}>
                    <div className={styles.loggworks1}>
                      <b className={styles.followUs}>Follow us</b>
                    </div>
                    <div className={styles.groupParent}>
                      <div className={styles.frameGroup}
                        style={{cursor:'pointer'}}
                        onClick={()=>window.open('https://web.facebook.com/Loggworks?_rdc=1&_rdr', '_blank')}>
                        <img
                          className={styles.frameIcon}
                          alt=""
                          src="/frame20.svg"
                        />
                        <div className={styles.facebook}>Facebook</div>
                      </div>
                      <div className={styles.frameContainer}
                        style={{cursor:'pointer'}}
                        onClick={()=>window.open('https://twitter.com/loggworks', '_blank')}>
                        <img
                          className={styles.frameIcon1}
                          alt=""
                          src="/frame37.svg"
                        />
                        <div className={styles.twitter}>Twitter</div>
                      </div>
                      <div className={styles.groupDiv}
                        style={{cursor:'pointer'}}
                        onClick={()=>window.open('https://www.instagram.com/loggworks/?utm_medium=copy_link', '_blank')}>
                        <img
                          className={styles.frameIcon1}
                          alt=""
                          src="/frame24.svg"
                        />
                        <div className={styles.twitter}>Instagram</div>
                      </div>
                      <div className={styles.frameParent1}>
                        <img
                          className={styles.frameIcon1}
                          alt=""
                          src="/frame25.svg"
                        />
                        <div className={styles.twitter}>YouTube</div>
                      </div>
                      <div className={styles.mdilinkedinParent}
                        style={{cursor:'pointer'}}
                        onClick={()=>window.open('https://www.linkedin.com/company/loggworks', '_blank')}>
                        <img
                          className={styles.mdilinkedinIcon}
                          alt=""
                          src="/mdilinkedin1.svg"
                        />
                        <div className={styles.linkedin}>LinkedIn</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.copyright2022LoggworkLtdAParent}>
                  <div className={styles.copyright2022Loggwork}>
                  ©Copyright 2024 loggworks  Ltd.  All Rights Reserved
                  </div>
                  <div className={styles.frameDiv}>
                    <div className={styles.termsOfUseParent}>
                      <div className={styles.copyright2022Loggwork}
                      style={{cursor:'pointer'}}
                      onClick={()=>{navigate('/terms')}}>
                        Terms of Use
                      </div>
                      <div className={styles.frameChild} />
                    </div>
                    <div className={styles.termsOfUseParent}>
                      <div
                        className={styles.copyright2022Loggwork}
                        style={{cursor:'pointer'}}
                        onClick={()=>{navigate('/privacy')}}
                      >{`Privacy Policy `}</div>
                      <div className={styles.frameChild} />
                    </div>
                    <div className={styles.termsOfUseParent}>
                      <div className={styles.copyright2022Loggwork}>
                        Cookies
                      </div>
                      <div className={styles.frameChild} />
                    </div>
                    <div className={styles.contactUsParent}>
                      <div className={styles.copyright2022Loggwork}>
                        Contact Us
                      </div>
                      <div className={styles.frameChild} />
                    </div>
                    <div className={styles.termsOfUseParent}>
                      <div className={styles.copyright2022Loggwork}>
                        Sitemap
                      </div>
                      <div className={styles.frameChild1} />
                    </div>
                  </div>
                </div>
                <div className={styles.largeButtonsParent}>
                  <button
                    className={styles.largeButtons}
                    style={{cursor:'pointer'}}
                    onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
                  >
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder57.svg"
                    />
                    <b className={styles.label}>Become a Professional</b>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder57.svg"
                    />
                  </button>
                  <button
                    className={styles.largeButtons}
                    onClick={onLargeButtons1Click}
                  >
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder58.svg"
                    />
                    <b className={styles.label}>Post a Job</b>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder58.svg"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.frameParent2}>
              <form onSubmit={handleSubmit} className={styles.frameParent3}>
                <div className={styles.frameParent4}>
                  <div className={styles.inputParent}>
                    <div className={styles.input}>
                      <div className={styles.labelContainer}>
                        <div className={styles.label2}>First name</div>
                      </div>
                      <TextField
                        className={styles.baseField}
                        color="primary"
                        variant="outlined"
                        type="text"
                        size="medium"
                        name="firstName"
                        required
                        value={formData.firstName}
                        onChange={handleChange}
                        margin="none"
                      />
                      <div className={styles.helperTextContainer}>
                        <div className={styles.thisIsA}>
                          This is a helper text
                        </div>
                        <div className={styles.div}>1/100</div>
                      </div>
                    </div>
                    <div className={styles.input}>
                      <div className={styles.labelContainer}>
                        <div className={styles.label2}>Lastname</div>
                      </div>
                      <TextField
                        className={styles.baseField}
                        fullWidth
                        color="primary"
                        variant="outlined"
                        type="text"
                        size="medium"
                        required
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        margin="none"
                      />
                      <div className={styles.helperTextContainer}>
                        <div className={styles.thisIsA}>
                          This is a helper text
                        </div>
                        <div className={styles.div}>1/100</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.input2}>
                    <div className={styles.labelContainer}>
                      <div className={styles.label2}>Email address</div>
                    </div>
                    <TextField
                      className={styles.baseField}
                      fullWidth
                      color="primary"
                      variant="outlined"
                      type="text"
                      size="medium"
                      required
                      margin="none"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <div className={styles.helperTextContainer}>
                      <div className={styles.thisIsA}>
                        This is a helper text
                      </div>
                      <div className={styles.div}>1/100</div>
                    </div>
                  </div>
                  <div className={styles.input2}>
                    <div className={styles.labelContainer}>
                      <div className={styles.label2}>Subject</div>
                    </div>
                    <TextField
                      className={styles.baseField}
                      fullWidth
                      color="primary"
                      variant="outlined"
                      type="text"
                      size="medium"
                      required
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      margin="none"
                    />
                    <div className={styles.helperTextContainer}>
                      <div className={styles.thisIsA}>
                        This is a helper text
                      </div>
                      <div className={styles.div}>1/100</div>
                    </div>
                  </div>
                  <div className={styles.input4}>
                    <div className={styles.labelContainer}>
                      <div className={styles.label2}>
                        What would you like to talk about
                      </div>
                    </div>
                    <TextField
                      className={styles.baseField4}
                      color="primary"
                      variant="outlined"
                      multiline
                      margin="none"
                      required
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      fullWidth
                      rows={4}
                    />
                    <div className={styles.helperTextContainer}>
                      <div className={styles.thisIsA}>
                        This is a helper text
                      </div>
                      <div className={styles.div}>1/100</div>
                    </div>
                  </div>
                </div>
                <button type="submit" className={styles.largeButtons2} style={{backgroundColor:'#2fa500'}}>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder59.svg"
                  />
                  <b className={styles.label}>Submit</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder59.svg"
                  />
                </button>
              </form>
              <div className={styles.valuesCard}>
                <div className={styles.frameParent5}>
                  <img
                    className={styles.frameChild2}
                    alt=""
                    src="/frame-665224.svg"
                  />
                  <div className={styles.manchesterParent}>
                    <div className={styles.manchester}>Manchester</div>
                    <div className={styles.infologgworkscoukParent}>
                      <div className={styles.infologgworkscouk}>
                        Info@loggworks.co.uk
                      </div>
                      <div className={styles.rifacebookBoxFillParent}>
                        <img
                          className={styles.rifacebookBoxFillIcon}
                          alt=""
                          src="/rifacebookboxfill2.svg"
                        />
                        <img
                          className={styles.rifacebookBoxFillIcon}
                          alt=""
                          src="/ritwitterfill2.svg"
                        />
                        <img
                          className={styles.rifacebookBoxFillIcon}
                          alt=""
                          src="/rilinkedinboxfill2.svg"
                        />
                        <img
                          className={styles.rifacebookBoxFillIcon}
                          alt=""
                          src="/riinstagramfill.svg"
                        />
                        <img
                          className={styles.rifacebookBoxFillIcon}
                          alt=""
                          src="/riyoutubefill.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.twoTabSwitch}>
              <div
                className={styles.tab1Wrapper}
                onClick={onFrameContainer26Click}
              >
                <div className={styles.infologgworkscouk}>
                  Frequently Asked Questions
                </div>
              </div>
              <div className={styles.tab2Wrapper}>
                <div className={styles.tab2}>Contact Us</div>
              </div>
            </div>
            <div className={styles.frameWrapper}>
              <div className={styles.frameWrapper1}>
                <div className={styles.getAllTheHelpYouNeedParent}>
                  <b className={styles.getAllThe}>Get all the help you need.</b>
                  <div className={styles.loggworksConnectHomeowners}>
                    Loggworks connect homeowners to genuine and reliable service
                    providers who provide easy-to-book and hassle-free services.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav className={styles.navigation}>
            <div className={styles.navigation1}>
              <img
                className={styles.navigationChild}
                alt=""
                onClick={()=>navigate('/')}
                style={{cursor:'pointer'}}
                src="/frame-66522.svg"
              />
              <div className={styles.navigationItem} />
              <div className={styles.tabSwitch}>
                <div
                  className={styles.pageSelector}
                  onClick={onPageSelectorContainerClick}
                >
                  <div className={styles.descriptionParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Professional
                    </div>
                    <div className={styles.selector} />
                  </div>
                </div>
                <div className={styles.pageSelector1}>
                  <div className={styles.descriptionParent}>
                    <b className={styles.description1}>Consumer</b>
                    <div className={styles.selector1} />
                  </div>
                </div>
              </div>
              <LocationSearch/>
            </div>
            <div className={styles.navigation2}>
              <div className={styles.homeParent}>
                <div className={styles.home} onClick={onHomeTextClick}>
                  Home
                </div>
                <div
                  className={styles.howItWorks2}
                  onClick={onHowItWorks2Click}
                >
                  How it works
                </div>
                <div className={styles.services} 
                 id="fade-button"
  aria-controls={open ? 'fade-menu' : undefined}
  aria-haspopup="true"
  aria-expanded={open ? 'true' : undefined}
                onClick={openDropdown}>
                  <div className={styles.services1}>Services</div>
                  <img
                    className={styles.rifacebookBoxFillIcon}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={isDropdownOpen}
        open={open}
        onClose={closeDropdown}
        TransitionComponent={Fade}
      >
      <Dropdown4/>
      </Menu>
                <div className={styles.services} 
                id="fade-button2"
 aria-controls={open1 ? 'fade-menu2' : undefined}
 aria-haspopup="true"
 aria-expanded={open1 ? 'true' : undefined}
                onClick={openDropdown1}>
                  <div className={styles.services1}>Resources</div>
                  <img
                    className={styles.rifacebookBoxFillIcon}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                  <Menu
        id="fade-menu2"
        MenuListProps={{
          'aria-labelledby': 'fade-button2',
        }}
        anchorEl={isDropdown1Open}
        open={open1}
        onClose={closeDropdown1}
        TransitionComponent={Fade}
      >
      <Dropdown5/>
      </Menu>
                <div className={styles.pricing}>Pricing</div>
              </div>
              <div className={styles.frameParent6}>
                <div className={styles.frameParent7}>
                  <button
                    className={styles.iconshelpParent}
                    onClick={onFrameButtonClick}
                  >
                    <img
                      className={styles.rifacebookBoxFillIcon}
                      alt=""
                      src="/iconshelp1.svg"
                    />
                    <div className={styles.help}>Help</div>
                  </button>
                  <button className={styles.iconshelpParent}>
                    <img
                      className={styles.rifacebookBoxFillIcon}
                      alt=""
                      src="/iconsaccount11.svg"
                    />
                    <div className={styles.account}>Account</div>
                  </button>
                </div>
                <button
                  className={styles.smallButtons}
                  onClick={onSmallButtonsClick}
                >
                  <div className={styles.iconsaddParent}>
                    <img
                      className={styles.iconsadd}
                      alt=""
                      src="/iconsadd19.svg"
                    />
                    <div className={styles.button}>Post a job</div>
                  </div>
                </button>
              </div>
            </div>
          </nav>
        </div>
        <div className={styles.homeMobile}>
          <div className={styles.navbarMobileclosed}>
            <img className={styles.layer2Icon1} alt="" src="/layer-217.svg" 
            style={{cursor:'pointer'}} onClick={()=>navigate('/')} />
            <div className={styles.iconsaccountGroup}>
              <img
                className={styles.iconsaccount1}
                alt=""
                src="/iconsaccount13.svg"
                onClick={()=>window.open('https://www.consumer.loggworks.co.uk/login', '_blank')}
              />
              <img
                className={styles.hamburgerIcon}
                alt=""
                src="/hamburger.svg"
                onClick={openMobileMenuConsumerPopup}
              />
            </div>
          </div>
          <div className={styles.homeMobileInner}>
            <div className={styles.getAllTheHelpYouNeedGroup}>
              <div className={styles.getAllThe1}>
                Get all the help you need.
              </div>
              <div className={styles.loggworksConnectHomeowners1}>
                Loggworks connect homeowners to genuine and reliable service
                providers who provide easy-to-book and hassle-free services.
              </div>
            </div>
          </div>
          <div className={styles.twoTabSwitchWrapper}>
            <div className={styles.twoTabSwitch1}>
              <div className={styles.tab1Container} onClick={onFrameContainer26Click}>
                <div className={styles.infologgworkscouk}>FAQ</div>
              </div>
              <div className={styles.tab2Wrapper}>
                <div className={styles.tab2}>Contact Us</div>
              </div>
            </div>
          </div>
          <div className={styles.frameParent8}>
            <form onSubmit={handleSubmit} className={styles.inputGroup}>
              <div className={styles.inputGroup}>
                <div className={styles.input2}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label2}>First name</div>
                  </div>
                <TextField
                        className={styles.baseField5}
                        color="primary"
                        variant="outlined"
                        type="text"
                        required
                        size="medium"
                        margin="none"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                  <div className={styles.helperTextContainer}>
                    <div className={styles.thisIsA}>This is a helper text</div>
                    <div className={styles.div}>1/100</div>
                  </div>
                </div>
                <div className={styles.input2}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label2}>Lastname</div>
                  </div>
                  <TextField
                        className={styles.baseField5}
                        color="primary"
                        variant="outlined"
                        type="text"
                        size="medium"
                        margin="none"
                        required
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                  <div className={styles.helperTextContainer}>
                    <div className={styles.thisIsA}>This is a helper text</div>
                    <div className={styles.div}>1/100</div>
                  </div>
                </div>
              </div>
              <div className={styles.input2}>
                <div className={styles.labelContainer}>
                  <div className={styles.label2}>Email address</div>
                </div>
                <TextField
                        className={styles.baseField5}
                        color="primary"
                        variant="outlined"
                        type="text"
                        size="medium"
                        margin="none"
                        required
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                <div className={styles.helperTextContainer}>
                  <div className={styles.thisIsA}>This is a helper text</div>
                  <div className={styles.div}>1/100</div>
                </div>
              </div>
              <div className={styles.input2}>
                <div className={styles.labelContainer}>
                  <div className={styles.label2}>Subject</div>
                </div>
                <TextField
                        className={styles.baseField5}
                        color="primary"
                        variant="outlined"
                        type="text"
                        size="medium"
                        margin="none"
                        required
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                      />
                <div className={styles.helperTextContainer}>
                  <div className={styles.thisIsA}>This is a helper text</div>
                  <div className={styles.div}>1/100</div>
                </div>
              </div>
              <div className={styles.input4}>
                <div className={styles.labelContainer}>
                  <div className={styles.label2}>
                    What would you like to talk about
                  </div>
                </div>
                <TextField
                        className={styles.baseField9}
                        color="primary"
                        variant="outlined"
                        type="text"
                        size="medium"
                        margin="none"
                        required
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        multiline
                        rows={4}
                      />
                <div className={styles.helperTextContainer}>
                  <div className={styles.thisIsA}>This is a helper text</div>
                  <div className={styles.div}>1/100</div>
                </div>
              </div>
              <button type="submit" className={styles.largeButtons2} style={{backgroundColor:'#2fa500'}}>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder59.svg"
                  />
                  <b className={styles.label}>Submit</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder59.svg"
                  />
                </button>
            </form>
            <div className={styles.valuesCard1}>
              <div className={styles.frameParent5}>
                <img
                  className={styles.frameChild2}
                  alt=""
                  src="/frame-665225.svg"
                />
                <div className={styles.inputGroup}>
                  <div className={styles.signUp}>
                    Manchester
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.signUp}>
                     info@loggworks.co.uk
                    </div>
                    <div className={styles.rifacebookBoxFillGroup}>
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/rifacebookboxfill3.svg"
                      />
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/ritwitterfill3.svg"
                      />
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/rilinkedinboxfill3.svg"
                      />
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/riinstagramfill1.svg"
                      />
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/riyoutubefill1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MobileFooter/>
        </div>
      </div>
      <Snackbar
                    open={Boolean(sent)}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    autoHideDuration={8000} 
                    onClose={() => setSent(false)}
                >
                    <Alert
                        onClose={() => setSent(false)} 
                        severity="success"
                        variant='filled'
                    >
                       We will get back to you as soon as possible
                    </Alert>
                </Snackbar>
      {isMobileMenuConsumerPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuConsumerPopup}
        >
          <MobileMenuConsumer1 onClose={closeMobileMenuConsumerPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default ContactUs;
