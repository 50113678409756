import React from 'react'
import styles from '../ForProfessional.module.css'

function ProfBanner() {
  return (
    <div>
          <div className={styles.groupParent4}>
          <img
            className={styles.groupIcon}
            alt=""
            src="/group-1000004594@2x.png"
          />
          <div className={styles.findTheBestJobsInYourFieParent}>
            <b className={styles.findTheBest}>
              Find the Best Jobs In Your Field
            </b>
            <div className={styles.createAFree}>
              Create a free profile and dive into a vast pool of service jobs
              without any charges. Pay commission only when job is successfully
              completed.
            </div>
            <button className={styles.smallButtons3}
            onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
              <div className={styles.loggworkscoukParent}>
                <img className={styles.arrowrightIcon} alt="" src="/plus.svg" />
                <div className={styles.loggworkscouk}>
                  Create A Free Profile
                </div>
              </div>
            </button>
          </div>
        </div>
        <div className={styles.groupParent12}>
        <div
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "0px 10px",
        textAlign: "left",
        fontSize: "20px",
        color: "#fff",
        fontFamily: "Inter",
      }}
    >
      <div
        style={{
          width: "335px",
          position: "relative",
          height: "496px",
          zIndex: "0",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            borderRadius: "6px",
            backgroundColor: "#2e2e2e",
            width: "335px",
            height: "496px",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "24px",
            left: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "24px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "16px 0px",
              }}
            >
              <b
                style={{
                  width: "287px",
                  position: "relative",
                  lineHeight: "28.8px",
                  display: "inline-block",
                }}
              >
                <p style={{ margin: "" }}>Find the Best Jobs In</p>
                <p style={{ margin: "" }}>Your Field</p>
              </b>
              <div
                style={{
                  width: "287px",
                  position: "relative",
                  fontSize: "12px",
                  lineHeight: "21px",
                  display: "inline-block",
                }}
              >
                Create a free profile and dive into a vast pool of service jobs
                without any charges. Pay commission only when job is
                successfully completed.
              </div>
            </div>
            <div
              style={{
                width: "192px",
                position: "relative",
                height: "48px",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              <div
               onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  borderRadius: "5px",
                  backgroundColor: "#2fa500",
                  border: "1px solid #2fa500",
                  boxSizing: "border-box",
                  width: "192px",
                  height: "48px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "8px 0px",
                  gap: "0px 8px",
                }}
              >
                <b
                  style={{
                    position: "relative",
                    lineHeight: "24px",
                    textTransform: "capitalize",
                  }}
                >
                  Create A Free Profile
                </b>
                <img
                  style={{
                    width: "20px",
                    position: "relative",
                    height: "20px",
                    overflow: "hidden",
                    flexShrink: "0",
                    display: "none",
                  }}
                  alt=""
                  src="/iconsadd10.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        style={{
          width: "335px",
          position: "absolute",
          margin: "0",
          top: "233px",
          left: "0px",
          height: "263px",
          zIndex: "1",
        }}
        alt=""
        src="/mask-group2.svg"
      />
    </div>
        </div>
    </div>
  )
}

export default ProfBanner