import { useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./ForConsumers.module.css";
import ToolBar from "./components/ToolBar";
import { Autocomplete, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PrototypeFooter from "../../components/PrototypeFooter";
import mobnav from "../ConsumerHome.module.css";
import { useState } from "react";
import MobileFooter from "../../components/MobileFooter";
import PortalPopup from "../../components/PortalPopup";
import MenuMobile from "./components/MenuMobile";
import axios from "axios";
import ForConsumerTab from "./components/ForConsumerTab";
import CustBanner from "./components/CustBanner";
import SubsBanner from "./components/SubsBanner";
import Highlighter from "react-highlight-words";

const ForConsumers = () => {
  const [
    isMobileMenuProfProfessionalPopupOpen,
    setMobileMenuProfProfessionalPopupOpen,
  ] = useState(false);
  const openMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(true);
  }, []);

  const closeMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(false);
  }, []);
  const [combined, setCombined]=useState([])
  const [tips, setTips]=useState([])
  const [how_to, setHowTo]=useState([])
  const [popular, setPopular]=useState([])
  const [feature_cons, setFeatureCons]=useState([])
  const [searchWord, setSearchWord] = useState("");

  const navigate = useNavigate();

  const useStyles = makeStyles((theme) => ({
    '@global': {
      '.MuiAutocomplete-option[data-focus="true"]': {
          background: 'blue'
      }
  }
  }));

  const classes = useStyles();
  const formatDateToWords = (dateString) => {
    const date = new Date(dateString);
    
    // Array of month names
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    // Get the components of the date
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    
    // Construct the formatted date string
    const formattedDate = `${month} ${day}, ${year}`;
    
    return formattedDate;
  };

  const onBLOGTextClick = useCallback(() => {
    navigate("/blog");
  }, [navigate]);

  const onHiringGuidesTextClick = useCallback(() => {
    navigate("/blog/for-consumers/hiring-guide");
  }, [navigate]);

  const onPricing1Click = useCallback(() => {
    navigate("/blog/for-consumers/pricing-guides");
  }, [navigate]);

  const onTipsTextClick = useCallback(() => {
    navigate("/blog/for-consumers/tips");
  }, []);

  const onHowToTextClick = useCallback(() => {
    navigate("/blog/for-consumers/how-to");
  }, []);

  const onFrameContainer13Click = useCallback(() => {
    navigate("/blog/for-consumers/how-to");
  }, []);
  useEffect(() => {
    const searchdatahiring = [
      { BlogTitle: 'Hiring Caterers'},
      { BlogTitle: 'Hiring Childcare Workers'},
      { BlogTitle: 'Hiring Cleaners'},
      { BlogTitle: 'Hiring Entertainers'},
      { BlogTitle: 'Hiring Appliance Repair Workers'},
      { BlogTitle: 'Hiring Mobile Mechanics'},
      { BlogTitle: 'Hiring Personal Assistants'},
      { BlogTitle: 'Hiring Pet Groomers'},
      { BlogTitle: 'Hiring Recovery Service Workers'},
      { BlogTitle: 'Hiring Removal Service Workers'},
      { BlogTitle: 'Hiring Tradesmen'},
      { BlogTitle: 'Hiring Transport Service Workers'},
      { BlogTitle: 'Hiring Computer & Phone Repair'},
      { BlogTitle: 'Hiring Dry Cleaners & Laundrymen'},
      { BlogTitle: 'Hiring Health, Wellness & Beauty'},
      { BlogTitle: 'Hiring Tuition & Classes Workers'},
    ]
    const searchdatapricing = [
      { BlogTitle: 'Caterers'},
      { BlogTitle: 'Childcare Workers'},
      { BlogTitle: 'Cleaners'},
      { BlogTitle: 'Entertainers'},
      { BlogTitle: 'Appliance Repair Workers'},
      { BlogTitle: 'Mobile Mechanics'},
      { BlogTitle: 'Personal Assistants'},
      { BlogTitle: 'Pet Groomers'},
      { BlogTitle: 'Recovery Service Workers'},
      { BlogTitle: 'Removal Service Workers'},
      { BlogTitle: 'Tradesmen'},
      { BlogTitle: 'Transport Service Workers'},
      { BlogTitle: 'Computer & Phone Repair'},
      { BlogTitle: 'Dry Cleaners & Laundrymen'},
      { BlogTitle: 'Health, Wellness & Beauty'},
      { BlogTitle: 'Tuition & Classes Workers'},
    ]
    axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/blogs?populate=*')
    .then(response => {
      const blogsData = response.data.data;
      // Limiting to the first 3 blogs
      const featuredBlogs = blogsData.filter(blog => blog.attributes.category === 'featured');
      const popularBlogs = blogsData.filter(blog => blog.attributes.category === 'popular');
      const howtoBlogs = blogsData.filter(blog => blog.attributes.category === 'How To');
      const tipsBlogs = blogsData.filter(blog => blog.attributes.category === 'Tips');
      setPopular(popularBlogs.slice(0,3))
      setHowTo(howtoBlogs.slice(0,3))
      setTips(tipsBlogs.slice(0,3))
      setFeatureCons(featuredBlogs.slice(0, 1)); // Limiting to the first featured blog
      const combinedBlogs = [
        ...featuredBlogs.slice(0, 1), // Limiting to the first featured blog
        ...popularBlogs.slice(0,3),
        ...howtoBlogs.slice(0, 3),
        ...tipsBlogs.slice(0, 3)
      ];
      
      setCombined(combinedBlogs)
    })
    .catch(error => {
      console.error('Error fetching blogs:', error);
    });
  }, []);
 
  const handleChange = (event, value) => {
    if (value) {
      combined.filter(blog => blog.attributes.BlogTitle === value).map(data=>{
         navigate(`/blog/for-consumers/${data.attributes.slug}`);
      })
     
    }
  };
  
  return (
    <div className={styles.forConsumers}>
      <div className={styles.forConsumers1}>
        <div className={styles.forConsumersInner}>
        <ToolBar/>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.blogGroup}>
            <div className={styles.blog1} onClick={onBLOGTextClick}>
              BLOG
            </div>
            <img
              className={styles.arrowrightIcon}
              alt=""
              src="/caretright.svg"
            />
            <div className={styles.forConsumers3}>FOR CONSUMERS</div>
          </div>
          <div className={styles.rectangleGroup}>
          <Autocomplete
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      options={combined.map((option) => option.attributes.BlogTitle)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search articles"
          onChange= {(e) => setSearchWord(e.target.value)}
          InputProps={{
            ...params.InputProps,
            type: "search",
            
          }}
        />
      )}
      
      onChange={handleChange}
      autoHighlight  

    />
          </div>
        </div>
        <div className={styles.forConsumersGroup}>
          <div className={styles.mostPopularArticles}>For Consumers</div>
          <div className={styles.letUsBeContainer}>
            <p className={styles.letUsBe}>
              Let us be your go-to for all things hiring and pricing.
            </p>
            <p className={styles.letUsBe}>
              Take a look at our helpful articles on hiring strategies, pricing,
              expert tips and more.
            </p>
          </div>
          <ForConsumerTab/>
        </div>
        {feature_cons.map(blog => (
                <Link key={blog.id} to={'/blog/for-consumers/'+blog.attributes.slug}>
            <div className={styles.rectangleContainer}>
          <img
            className={styles.rectangleIcon}
            alt=""
            src={blog.attributes.BlogImage.data.attributes.url}
          />
          <div className={styles.frameGroup}>
            <div className={styles.tipsForFindingTheRightHomParent}>
              <b className={styles.tipsForFinding}>
              <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
              </b>
              <div className={styles.loggworksHelpsYou}>
              <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
              </div>
            </div>
            <div className={styles.christinaWilliamsParent}>
              <div className={styles.christinaWilliams}>{blog.attributes.Author}</div>
              <div className={styles.ellipseDiv} />
              <div className={styles.january18th2024}>{blog.attributes.createAt}</div>
            </div>
            <div className={styles.frameContainer}>
              <div className={styles.forConsumersWrapper}>
                <div className={styles.forConsumers5}>{blog.attributes.category}</div>
              </div>
              <div className={styles.minsRead}>{blog.attributes.duration} mins read</div>
            </div>
          </div>
        </div>
                </Link>
       
             ))}
        
        <div className={styles.mostPopularArticlesParent}>
          <div className={styles.mostPopularArticles}>
            Most Popular Articles
          </div>
          <div className={styles.frameDiv}>
            <div className={styles.groupParent1}>
            {popular.map(blog => (
                <Link key={blog.id} to={'/blog/for-consumers/'+blog.attributes.slug}>
               <div className={styles.groupDiv}>
                <div className={styles.groupInner} />
                <img
                  className={styles.groupChild1}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent1}>
                  <div className={styles.blogGroup}>
                    <div className={styles.hiringGuidesWrapper}>
                      <div className={styles.howTo}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.howToPricingYourServicesAParent}>
                    <b className={styles.howToPricing}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    
                    </b>
                    <div
                      className={styles.embarkingOnA}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                      </div>
                  </div>
                  <div className={styles.christinaWilliamsGroup}>
                    <div className={styles.christinaWilliams1}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild1} />
                    <div className={styles.january18th20241}>
                   {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
                </Link>
       
             ))}
              
            </div>
          </div>
        </div>
        <div className={styles.frameParent7}>
          <div className={styles.mostPopularArticlesParent}>
            <div
              className={styles.pricingGuides}
              onClick={onHiringGuidesTextClick}
            >
              Hiring Guides
            </div>
            <div className={styles.groupParent1}>
              <div className={styles.groupParent2} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-caterers')}>
                <div className={styles.rectangleParent3}>
                  <div className={styles.groupChild5} />
                  <img
                    className={styles.groupChild6}
                    alt=""
                    src="/caterer.webp"
                  />
                </div>
                <div className={styles.frameParent9}>
                  <div className={styles.frameWrapper1}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.hiringCaterersParent}>
                        <b
                          className={styles.hiringCaterers}
                        >{`Hiring Caterers `}</b>
                        <div className={styles.exploreExpertAdvice}>
                          Explore expert advice around catering and selecting
                          the perfect professional to elevate your gatherings.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.exploreGuidesParent}>
                    <div className={styles.exploreGuides}>Explore Guides</div>
                    <img
                      className={styles.arrowrightIcon}
                      alt=""
                      src="/arrowright.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.groupParent2} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-childcare-workers')}>
                <div className={styles.rectangleParent3}>
                  <div className={styles.groupChild5} />
                  <img
                    className={styles.groupChild6}
                    alt=""
                    src="/childcare.webp"
                  />
                </div>
                <div className={styles.frameParent9}>
                  <div className={styles.frameWrapper1}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.hiringCaterersParent}>
                        <b className={styles.hiringCaterers}>
                          Hiring Childcare Workers
                        </b>
                        <div className={styles.exploreExpertAdvice}>
                          Explore expert advice around childcare and selecting
                          the perfect professional to enhance your child's
                          growth
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.exploreGuidesParent}>
                    <div className={styles.exploreGuides}>Explore Guides</div>
                    <img
                      className={styles.arrowrightIcon}
                      alt=""
                      src="/arrowright.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.groupParent2} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-cleaners')}>
                <div className={styles.rectangleParent3}>
                  <div className={styles.groupChild5} />
                  <img
                    className={styles.groupChild6}
                    alt=""
                    src="/cleaners.webp"
                  />
                </div>
                <div className={styles.frameParent9}>
                  <div className={styles.frameWrapper1}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.hiringCaterersParent}>
                        <b className={styles.hiringCleaners}>Hiring Cleaners</b>
                        <div className={styles.exploreExpertAdvice}>
                          Explore expert advice around cleaning and selecting
                          the perfect professional for a pristine home
                          environment.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.exploreGuidesParent}>
                    <div className={styles.exploreGuides}>Explore Guides</div>
                    <img
                      className={styles.arrowrightIcon}
                      alt=""
                      src="/arrowright.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.groupParent1}>
              <div className={styles.groupParent2} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-entertainers')}>
                <div className={styles.rectangleParent3}>
                  <div className={styles.groupChild5} />
                  <img
                    className={styles.groupChild6}
                    alt=""
                    src="/entertainment.webp"
                  />
                </div>
                <div className={styles.frameParent9}>
                  <div className={styles.frameWrapper1}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.hiringCaterersParent}>
                        <b className={styles.hiringCleaners}>
                          Hiring Entertainers
                        </b>
                        <div className={styles.exploreExpertAdvice}>
                          Explore expert advice around entertainment and
                          selecting the ideal professional to elevate your
                          gatherings.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.exploreGuidesParent}>
                    <div className={styles.exploreGuides}>Explore Guides</div>
                    <img
                      className={styles.arrowrightIcon}
                      alt=""
                      src="/arrowright.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.groupParent2} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-appliance-repair-workers')}>
                <div className={styles.rectangleParent3}>
                  <div className={styles.groupChild5} />
                  <img
                    className={styles.groupChild6}
                    alt=""
                    src="/applance-repair.webp"
                  />
                </div>
                <div className={styles.frameParent9}>
                  <div className={styles.frameWrapper1}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.hiringCaterersParent}>
                        <b className={styles.hiringCleaners}>
                          Hiring Appliance Repair workers
                        </b>
                        <div className={styles.exploreExpertAdvice}>
                          Explore expert advice around appliance repair and
                          choosing professional to enhance your home solutions.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.exploreGuidesParent}>
                    <div className={styles.exploreGuides}>Explore Guides</div>
                    <img
                      className={styles.arrowrightIcon}
                      alt=""
                      src="/arrowright.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.groupParent2} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-mobile-mechanics')}>
                <div className={styles.rectangleParent3}>
                  <div className={styles.groupChild5} />
                  <img
                    className={styles.groupChild6}
                    alt=""
                    src="/mobile mechanic.webp"
                  />
                </div>
                <div className={styles.frameParent9}>
                  <div className={styles.frameWrapper1}>
                    <div className={styles.frameWrapper2}>
                      <div className={styles.hiringCaterersParent}>
                        <b className={styles.hiringCleaners}>
                          Hiring Mobile Mechanics
                        </b>
                        <div className={styles.exploreExpertAdvice}>
                          Explore expert advice around mechanics and selecting
                          the ideal professional to improve your automotive
                          experience.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.exploreGuidesParent}>
                    <div className={styles.exploreGuides}>Explore Guides</div>
                    <img
                      className={styles.arrowrightIcon}
                      alt=""
                      src="/arrowright.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.smallButtonsWrapper}
            onClick={onHiringGuidesTextClick}
          >
            <div className={styles.smallButtons1}>
              <div className={styles.viewMoreHiringGuidesWrapper}>
                <div className={styles.viewMoreHiring}>
                  View More hiring guides
                </div>
              </div>
              <img className={styles.iconsadd} alt="" src="/caretright.svg" />
            </div>
          </div>
        </div>
        <CustBanner/>
        <div className={styles.frameParent18}>
          <div className={styles.pricingGuidesParent}>
            <div
              className={styles.pricingGuides}
              onClick={onPricing1Click}
            >
              Pricing Guides
            </div>
            <div className={styles.groupParent8}>
            <Link  to={'/blog/for-consumers/pricing-guides/pricing-entertainers'}>
               <div className={styles.rectangleParent9}>
                <div className={styles.groupChild17} />
                <img
                  className={styles.groupChild18}
                  alt=""
                  src={'/entertainment.webp'}
                />
                <div className={styles.frameWrapper13}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainersWrapper}>
                      <div className={styles.entertainers}>Entertainers</div>
                    </div>
                  </div>
                </div>
              </div>
                </Link>
                <Link  to={'/blog/for-consumers/pricing-guides/pricing-caterers'}>
               <div className={styles.rectangleParent9}>
                <div className={styles.groupChild17} />
                <img
                  className={styles.groupChild18}
                  alt=""
                  src={'/caterer.webp'}
                />
                <div className={styles.frameWrapper13}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainersWrapper}>
                      <div className={styles.entertainers}>Catering</div>
                    </div>
                  </div>
                </div>
              </div>
                </Link>
                <Link  to={'/blog/for-consumers/pricing-guides/pricing-tradesmen'}>
               <div className={styles.rectangleParent9}>
                <div className={styles.groupChild17} />
                <img
                  className={styles.groupChild18}
                  alt=""
                  src={'/tradesmen.webp'}
                />
                <div className={styles.frameWrapper13}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainersWrapper}>
                      <div className={styles.entertainers}>Tradesman</div>
                    </div>
                  </div>
                </div>
              </div>
                </Link>
                <Link  to={'/blog/for-consumers/pricing-guides/pricing-recovery-services'}>
               <div className={styles.rectangleParent9}>
                <div className={styles.groupChild17} />
                <img
                  className={styles.groupChild18}
                  alt=""
                  src={'/recovery services.webp'}
                />
                <div className={styles.frameWrapper13}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainersWrapper}>
                      <div className={styles.entertainers}>Recovery Services</div>
                    </div>
                  </div>
                </div>
              </div>
                </Link>
                <Link  to={'/blog/for-consumers/pricing-guides/pricing-cleaners'}>
               <div className={styles.rectangleParent9}>
                <div className={styles.groupChild17} />
                <img
                  className={styles.groupChild18}
                  alt=""
                  src={'/cleaners.webp'}
                />
                <div className={styles.frameWrapper13}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainersWrapper}>
                      <div className={styles.entertainers}>Cleaning</div>
                    </div>
                  </div>
                </div>
              </div>
                </Link>
                <Link  to={'/blog/for-consumers/pricing-guides/pricing-pet-groomers'}>
               <div className={styles.rectangleParent9}>
                <div className={styles.groupChild17} />
                <img
                  className={styles.groupChild18}
                  alt=""
                  src={'/pet groomers.webp'}
                />
                <div className={styles.frameWrapper13}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainersWrapper}>
                      <div className={styles.entertainers}>Pet Groomers</div>
                    </div>
                  </div>
                </div>
              </div>
                </Link>
                <Link  to={'/blog/for-consumers/pricing-guides/pricing-transport-services'}>
               <div className={styles.rectangleParent9}>
                <div className={styles.groupChild17} />
                <img
                  className={styles.groupChild18}
                  alt=""
                  src={'/transport services.webp'}
                />
                <div className={styles.frameWrapper13}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainersWrapper}>
                      <div className={styles.entertainers}>Transport Services</div>
                    </div>
                  </div>
                </div>
              </div>
                </Link>
                <Link  to={'/blog/for-consumers/pricing-guides/pricing-dry-cleaning'}>
               <div className={styles.rectangleParent9}>
                <div className={styles.groupChild17} />
                <img
                  className={styles.groupChild18}
                  alt=""
                  src={'/dry cleaning.webp'}
                />
                <div className={styles.frameWrapper13}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainersWrapper}>
                      <div className={styles.entertainers}>Dry Cleaning & Laundry</div>
                    </div>
                  </div>
                </div>
              </div>
                </Link>
            </div>
          </div>
          <div
            className={styles.smallButtonsWrapper}
            onClick={onPricing1Click}
          >
            <div className={styles.smallButtons1}>
              <div className={styles.viewMoreHiringGuidesWrapper}>
                <div className={styles.viewMoreHiring}>
                  View More Pricing Guides
                </div>
              </div>
              <img className={styles.iconsadd} alt="" src="/caretright.svg" />
            </div>
          </div>
        </div>
        <div className={styles.forConsumersChild}>
          <div className={styles.mostPopularArticlesParent}>
            <div className={styles.tips1} onClick={onTipsTextClick}>
              Tips
            </div>
            <div className={styles.groupParent10}>
            {tips.map(blog => (
                <Link key={blog.id} to={'/blog/for-consumers/'+blog.attributes.slug}>
             <div className={styles.rectangleParent17}>
                <div className={styles.groupChild33} />
                <img
                  className={styles.groupChild2}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent19}>
                  <div className={styles.blogGroup}>
                    <div className={styles.hiringGuidesWrapper}>
                      <div className={styles.howTo}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.howToPricingYourServicesAParent}>
                    <b className={styles.howToPricing}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div className={styles.embarkingOnA}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                    </div>
                  </div>
                  <div className={styles.christinaWilliamsGroup}>
                    <div className={styles.christinaWilliams1}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild1} />
                    <div className={styles.january18th20241}>
                   {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
                </Link>
       
             ))}
              
            </div>
          </div>
        </div>
        <SubsBanner/>
        <div className={styles.forConsumersInner1}>
          <div className={styles.frameParent27}>
            <div className={styles.mostPopularArticlesParent}>
              <div className={styles.tips1} onClick={onHowToTextClick}>
                How To
              </div>
              <div className={styles.groupParent10}>
              {how_to.map(blog => (
                <Link key={blog.id} to={'/blog/for-consumers/'+blog.attributes.slug}>
             <div className={styles.rectangleParent17}>
                <div className={styles.groupChild33} />
                <img
                  className={styles.groupChild2}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent19}>
                  <div className={styles.blogGroup}>
                    <div className={styles.hiringGuidesWrapper}>
                      <div className={styles.howTo}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.howToPricingYourServicesAParent}>
                    <b className={styles.howToPricing}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div className={styles.embarkingOnA}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                    </div>
                  </div>
                  <div className={styles.christinaWilliamsGroup}>
                    <div className={styles.christinaWilliams1}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild1} />
                    <div className={styles.january18th20241}>
                   {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
                </Link>
       
             ))}
              </div>
            </div>
            {
            how_to.length>3&&
             <div
              className={styles.smallButtonsWrapper}
              onClick={onFrameContainer13Click}
            >
              <div className={styles.smallButtons1}>
                <div className={styles.viewMoreHiringGuidesWrapper}>
                  <div className={styles.viewMoreHiring}>
                    View More Articles
                  </div>
                </div>
                <img className={styles.iconsadd} alt="" src="/caretright.svg" />
              </div>
            </div>
          }
          </div>
        </div>
        <div className={styles.prototypeFooter}>
        <PrototypeFooter/>
        </div>
      </div>
      <div className={styles.forConsumersmob}>
      <div className={mobnav.navbarMobileclosed}>
          <img className={mobnav.layer2Icon} alt="" src="/layer-21.svg"
          style={{cursor:'pointer'}} onClick={()=>navigate('/')}  />
          <div className={mobnav.iconsaccountParent}>
            <div className={mobnav.iconsaccount} alt="" />
            <img className={mobnav.hamburgerIcon} alt="" src="/hamburger.svg" onClick={openMobileMenuProfProfessionalPopup} />
          </div>
        </div>
        <div className={styles.blogContainer}>
          <div className={styles.blog3} onClick={onBLOGTextClick}>BLOG</div>
          <img
            className={styles.caretrightIcon4}
            alt=""
            src="/caretright.svg"
          />
          <div className={styles.forConsumers6}>FOR CONSUMERS</div>
        </div>
        <div className={styles.rectangleParent24}>
        <Autocomplete
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      options={combined.map((option) => option.attributes.BlogTitle)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search articles"
          onChange= {(e) => setSearchWord(e.target.value)}
          InputProps={{
            ...params.InputProps,
            type: "search",
            
          }}
        />
      )}
      
      onChange={handleChange}
      autoHighlight  

    />
        </div>
        <div className={styles.frameParent40}>
          <div className={styles.findTheBestHomeServiceProParent}>
            <div className={styles.mostPopularArticles}>For Consumers</div>
            <div className={styles.letUsBe1}>
              Let us be your go-to for all things hiring and pricing. Take a
              look at our helpful articles on hiring strategies, pricing, expert
              tips and more.
            </div>
          </div>
          <div className={styles.frameParent41}>
            <ForConsumerTab/>
          </div>
        </div>
        {feature_cons.map(blog => (
                <Link key={blog.id} to={'/blog/for-consumers/'+blog.attributes.slug}>
                 <div className={styles.rectangleParent25}>
          <img
            className={styles.frameChild15}
            alt=""
            src={blog.attributes.BlogImage.data.attributes.url}
          />
          <div className={styles.rectangleParent26}>
            <div className={styles.groupChild47} />
            <div className={styles.frameParent44}>
              <div className={styles.frameParent45}>
                <div className={styles.forConsumersFrame}>
                  <div className={styles.forConsumers8}>{blog.attributes.category}</div>
                </div>
                <div className={styles.minsRead10}>{blog.attributes.duration} mins read</div>
              </div>
              <div className={styles.frameParent46}>
                <div className={styles.tipsForFindingTheRightHomGroup}>
                  <b className={styles.tipsForFinding1}>
                  <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                  </b>
                  <div className={styles.loggworksHelpsYou6}>
                  <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                  </div>
                </div>
                <div className={styles.christinaWilliamsParent8}>
                  <div className={styles.christinaWilliams10}>
                  {blog.attributes.Author}
                  </div>
                  <div className={styles.ellipseDiv} />
                  <div className={styles.january18th202410}>
                 {formatDateToWords(blog.attributes.createdAt)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
                </Link>
             ))}
        
        <div className={styles.mostPopularArticlesGroup}>
          <div className={styles.mostPopularArticles}>
            Most Popular Articles
          </div>
          <div className={styles.frameParent47}>
          {popular.map(blog => (
                <Link key={blog.id} to={'/blog/for-consumers/'+blog.attributes.slug}>
                 <div className={styles.entertainersWrapper}>
              <div className={styles.rectangleParent27}>
                <div className={styles.groupChild48} />
                <img
                  className={styles.groupChild49}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent48}>
                  <div className={styles.frameParent49}>
                    <div className={styles.hiringGuidesFrame}>
                      <div className={styles.hiringGuides7}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead11}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.healthWellnessBeautyPricGroup}>
                    <b className={styles.howToPricing4}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.loggworksHelpsYou7}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                    </div>
                  </div>
                  <div className={styles.christinaWilliamsParent9}>
                    <div className={styles.christinaWilliams11}>
                    {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild17} />
                    <div className={styles.january18th202411}>
                   {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
           
          </div>
        </div>
        <div className={styles.mostPopularArticlesGroup}>
          <div className={styles.mostPopularArticles}>Hiring Guides</div>
          <div className={styles.frameParent54}>
            <div className={styles.groupParent15} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-caterers')}>
              <div className={styles.rectangleParent30}>
                <div className={styles.groupChild54} />
                <img
                  className={styles.groupChild55}
                  alt=""
                  src="/caterer.webp"
                />
              </div>
              <div className={styles.frameParent55}>
                <div className={styles.frameWrapper31}>
                  <div className={styles.frameWrapper32}>
                    <div className={styles.hiringCaterersGroup}>
                      <b
                        className={styles.hiringCaterers1}
                      >{`Hiring Caterers `}</b>
                      <div className={styles.exploreExpertAdvice6}>
                        Explore expert advice around catering and selecting the
                        perfect professional to elevate your gatherings.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent4}>
                  <div className={styles.exploreGuides6}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon4}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent15} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-childcare-workers')}>
              <div className={styles.rectangleParent31}>
                <div className={styles.groupChild56} />
                <img
                  className={styles.groupChild57}
                  alt=""
                  src="/childcare.webp"
                />
              </div>
              <div className={styles.frameParent55}>
                <div className={styles.frameWrapper31}>
                  <div className={styles.frameWrapper32}>
                    <div className={styles.hiringCaterersGroup}>
                      <b className={styles.hiringCaterers1}>
                        Hiring Childcare Workers
                      </b>
                      <div className={styles.exploreExpertAdvice6}>
                        Explore expert advice around childcare and selecting the
                        perfect professional to enhance your child's growth
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent4}>
                  <div className={styles.exploreGuides6}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon4}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent15} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-cleaners')}>
              <div className={styles.rectangleParent32}>
                <div className={styles.groupChild58} />
                <img
                  className={styles.groupChild59}
                  alt=""
                  src="/cleaners.webp"
                />
              </div>
              <div className={styles.frameParent55}>
                <div className={styles.frameWrapper31}>
                  <div className={styles.frameWrapper32}>
                    <div className={styles.hiringCaterersGroup}>
                      <b className={styles.hiringCaterers1}>Hiring Cleaners</b>
                      <div className={styles.exploreExpertAdvice6}>
                        Explore expert advice around cleaning and selecting the
                        perfect professional for a pristine home environment.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent4}>
                  <div className={styles.exploreGuides6}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon4}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent15} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-entertainers')}>
              <div className={styles.rectangleParent33}>
                <div className={styles.groupChild58} />
                <img
                  className={styles.groupChild61}
                  alt=""
                  src="/entertainment.webp"
                />
              </div>
              <div className={styles.frameParent55}>
                <div className={styles.frameWrapper31}>
                  <div className={styles.frameWrapper32}>
                    <div className={styles.hiringCaterersGroup}>
                      <b className={styles.hiringCaterers1}>
                        Hiring Entertainers
                      </b>
                      <div className={styles.exploreExpertAdvice6}>
                        Explore expert advice around entertainment and selecting
                        the ideal professional to elevate your gatherings.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent4}>
                  <div className={styles.exploreGuides6}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon4}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent15} onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-appliance-repair-workers')}>
              <div className={styles.rectangleParent34}>
                <div className={styles.groupChild54} />
                <img
                  className={styles.groupChild61}
                  alt=""
                  src="/applance-repair.webp"
                />
              </div>
              <div className={styles.frameParent55}>
                <div className={styles.frameWrapper31}>
                  <div className={styles.frameWrapper32}>
                    <div className={styles.hiringCaterersGroup}>
                      <b className={styles.hiringApplianceRepair1}>
                        Hiring Appliance Repair workers
                      </b>
                      <div className={styles.exploreExpertAdvice6}>
                        Explore expert advice around appliance repair and
                        choosing professional to enhance your home solutions.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent4}>
                  <div className={styles.exploreGuides6}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon4}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.groupParent15}onClick={()=>navigate('/blog/for-consumers/hiring-guide/hiring-mobile-mechanics')}>
              <div className={styles.rectangleParent35}>
                <div className={styles.groupChild54} />
                <img
                  className={styles.groupChild65}
                  alt=""
                  src="/mobile mechanic.webp"
                />
              </div>
              <div className={styles.frameParent55}>
                <div className={styles.frameWrapper31}>
                  <div className={styles.frameWrapper32}>
                    <div className={styles.hiringCaterersGroup}>
                      <b className={styles.hiringApplianceRepair1}>
                        Hiring Mobile Mechanics
                      </b>
                      <div className={styles.exploreExpertAdvice6}>
                        Explore expert advice around mechanics and selecting the
                        ideal professional to improve your automotive
                        experience.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.exploreGuidesParent4}>
                  <div className={styles.exploreGuides6}>Explore Guides</div>
                  <img
                    className={styles.caretrightIcon4}
                    alt=""
                    src="/arrowright.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.largeButtons2}
          onClick={onHiringGuidesTextClick}>
            <b className={styles.viewMoreHiring1}>View More hiring guides</b>
            <img className={styles.iconsadd1} alt="" src="/iconsadd10.svg" />
          </div>
        </div>
        <CustBanner/>
        <div className={styles.mostPopularArticlesGroup}>
          <div className={styles.mostPopularArticles}
           onClick={onPricing1Click}>Pricing Guides</div>
          <div className={styles.groupParent21}>

              <Link to={'/blog/for-consumers/pricing-guides/pricing-entertainers'}>
                  <div className={styles.rectangleParent37}>
              <div className={styles.groupChild66} />
              <img
                className={styles.groupChild67}
                alt=""
                src={'/entertainment.webp'}
              />
              <div className={styles.frameWrapper43}>
                <div className={styles.entertainersWrapper}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainers1}>Entertainers</div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
                <Link to={'/blog/for-consumers/pricing-guides/pricing-caterers'}>
                  <div className={styles.rectangleParent37}>
              <div className={styles.groupChild66} />
              <img
                className={styles.groupChild67}
                alt=""
                src={'/caterer.webp'}
              />
              <div className={styles.frameWrapper43}>
                <div className={styles.entertainersWrapper}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainers1}>Catering</div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
                <Link to={'/blog/for-consumers/pricing-guides/pricing-tradesmen'}>
                  <div className={styles.rectangleParent37}>
              <div className={styles.groupChild66} />
              <img
                className={styles.groupChild67}
                alt=""
                src={'/tradesmen.webp'}
              />
              <div className={styles.frameWrapper43}>
                <div className={styles.entertainersWrapper}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainers1}>Tradesman</div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
                <Link to={'/blog/for-consumers/pricing-guides/pricing-recovery-services'}>
                  <div className={styles.rectangleParent37}>
              <div className={styles.groupChild66} />
              <img
                className={styles.groupChild67}
                alt=""
                src={'/recovery services.webp'}
              />
              <div className={styles.frameWrapper43}>
                <div className={styles.entertainersWrapper}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainers1}>Recovery Services</div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
                <Link to={'/blog/for-consumers/pricing-guides/pricing-cleaners'}>
                  <div className={styles.rectangleParent37}>
              <div className={styles.groupChild66} />
              <img
                className={styles.groupChild67}
                alt=""
                src={'/cleaners.webp'}
              />
              <div className={styles.frameWrapper43}>
                <div className={styles.entertainersWrapper}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainers1}>Cleaning</div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
                <Link to={'/blog/for-consumers/pricing-guides/pricing-pet-groomers'}>
                  <div className={styles.rectangleParent37}>
              <div className={styles.groupChild66} />
              <img
                className={styles.groupChild67}
                alt=""
                src={'/pet groomers.webp'}
              />
              <div className={styles.frameWrapper43}>
                <div className={styles.entertainersWrapper}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainers1}>Pet Groomers</div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
                <Link to={'/blog/for-consumers/pricing-guides/pricing-transport-services'}>
                  <div className={styles.rectangleParent37}>
              <div className={styles.groupChild66} />
              <img
                className={styles.groupChild67}
                alt=""
                src={'/transport services.webp'}
              />
              <div className={styles.frameWrapper43}>
                <div className={styles.entertainersWrapper}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainers1}>Transport Services</div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
                <Link to={'/blog/for-consumers/pricing-guides/pricing-dry-cleaning'}>
                  <div className={styles.rectangleParent37}>
              <div className={styles.groupChild66} />
              <img
                className={styles.groupChild67}
                alt=""
                src={'/dry cleaning.webp'}
              />
              <div className={styles.frameWrapper43}>
                <div className={styles.entertainersWrapper}>
                  <div className={styles.entertainersWrapper}>
                    <div className={styles.entertainers1}>Dry Cleaning & Laundry</div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
          </div>
          <div className={styles.largeButtons2}
           onClick={onPricing1Click}>
            <b className={styles.viewMoreHiring1}>View More Pricing Guides</b>
            <img className={styles.iconsadd1} alt="" src="/iconsadd10.svg" />
          </div>
        </div>
        <div className={styles.mostPopularArticlesGroup}>
          <div className={styles.mostPopularArticles}
          onClick={onTipsTextClick}>Tips</div>
          <div className={styles.frameParent47}>
           {tips.map(blog => (
                <Link key={blog.id} to={'/blog/for-consumers/'+blog.attributes.slug}>
                  <div className={styles.entertainersWrapper}>
              <div className={styles.rectangleParent45}>
                <div className={styles.groupChild82} />
                <img
                  className={styles.groupChild51}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent63}>
                  <div className={styles.frameParent49}>
                    <div className={styles.hiringGuidesFrame}>
                      <div className={styles.hiringGuides7}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead11}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.healthWellnessBeautyPricGroup}>
                    <b className={styles.howToPricing4}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.loggworksHelpsYou7}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                      </div>
                  </div>
                  <div className={styles.christinaWilliamsParent9}>
                    <div className={styles.christinaWilliams11}>
                    {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild17} />
                    <div className={styles.january18th202411}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
           
          </div>
        </div>
        <SubsBanner/>
        <div className={styles.mostPopularArticlesGroup}>
          <div className={styles.mostPopularArticles} onClick={onHowToTextClick}>How To</div>
          <div className={styles.frameParent47}>
          {how_to.map(blog => (
                <Link key={blog.id} to={'/blog/for-consumers/'+blog.attributes.slug}>
                  <div className={styles.entertainersWrapper}>
              <div className={styles.rectangleParent45}>
                <div className={styles.groupChild82} />
                <img
                  className={styles.groupChild51}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent63}>
                  <div className={styles.frameParent49}>
                    <div className={styles.hiringGuidesFrame}>
                      <div className={styles.hiringGuides7}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead11}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.healthWellnessBeautyPricGroup}>
                    <b className={styles.howToPricing4}>
                    <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.BlogTitle}
  />
                    </b>
                    <div
                      className={styles.loggworksHelpsYou7}
                    >
                      <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight= {blog.attributes.description}
  />
                      </div>
                  </div>
                  <div className={styles.christinaWilliamsParent9}>
                    <div className={styles.christinaWilliams11}>
                    {blog.attributes.Author}
                    </div>
                    <div className={styles.frameChild17} />
                    <div className={styles.january18th202411}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
          </div>
          {
            how_to.length>3&&
            <div className={styles.largeButtons2} onClick={onHowToTextClick}>
            <b className={styles.viewMoreHiring1}>View More Articles</b>
            <img className={styles.iconsadd1} alt="" src="/iconsadd4.svg" />
          </div>
          }
        </div>
        <MobileFooter/>
      </div>
      {isMobileMenuProfProfessionalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuProfProfessionalPopup}
        >
          <MenuMobile
            onClose={closeMobileMenuProfProfessionalPopup}
          />
        </PortalPopup>
      )}
    </div>
  );
};

export default ForConsumers;
