import { useState, useCallback } from "react";
import Dropdown4 from "../components/Dropdown4";
import PortalPopup from "../components/PortalPopup";
import Dropdown5 from "../components/Dropdown5";
import MobileMenuConsumer1 from "../components/MobileMenuConsumer1";
import { useNavigate } from "react-router-dom";
import styles from "./FAQ1.module.css";
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import MobileFooter from "../components/MobileFooter";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationSearch from "../components/locationSearch.tsx";
import PrototypeFooter from "../components/PrototypeFooter";
import ProfessionalNavigation1 from "../components/ProfessionalNavigation1";
import MobileMenuProfProfessional1 from "../components/MobileMenuProfProfessional1";
const FAQ2 = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(null);
  const open = Boolean(isDropdownOpen);
  const openDropdown = (event) => {
    setDropdownOpen(event.currentTarget);
  };
  const closeDropdown = () => {
    setDropdownOpen(null);
  };
  const [isDropdown1Open, setDropdown1Open] = useState(null);
  const open1 = Boolean(isDropdown1Open);
   const openDropdown1 = (event) => {
    setDropdown1Open(event.currentTarget);
  };
  const closeDropdown1 = () => {
    setDropdown1Open(null);
  };
  const [isMobileMenuConsumerPopupOpen, setMobileMenuConsumerPopupOpen] =
    useState(false);
  const navigate = useNavigate();

  const onPostAJobClick = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onHowItWorksClick = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQsClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementTextClick = useCallback(() => {
    navigate("/agreement");
  }, [navigate]);

  const onHowItWorks1Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onContactUsText1Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQs1Click = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementText1Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/becometeam");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/partners");
  }, [navigate]);

  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/affiliate-program");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onLargeButtons1Click = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onFrameContainer22Click = useCallback(() => {
    navigate("/professional-contact-us");
  }, [navigate]);

  const onPageSelectorContainerClick = useCallback(() => {
    navigate("/professional");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHowItWorks2Click = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onFrameButtonClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onSmallButtonsClick = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const openMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(true);
  }, []);

  const closeMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(false);
  }, []);

  return (
    <>
      <div className={styles.faq}>
        <div className={styles.consumerhelpfaqdone}>
        <ProfessionalNavigation1 faq={true}/>
          <div className={styles.prototypeFooterParent}>
            <div className={styles.frameParent2}>
              <div className={styles.frameWrapper}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.getAllTheHelpYouNeedParent}>
                    <b className={styles.getAllThe}>
                      Get all the help you need.
                    </b>
                    <div className={styles.loggworksConnectHomeowners}>
                      Loggworks connect homeowners to genuine and reliable
                      service providers who provide easy-to-book and hassle-free
                      services.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper2}>
                <div className={styles.twoTabSwitchParent}>
                  <div className={styles.twoTabSwitch}>
                    <div className={styles.tab1Wrapper}>
                      <div className={styles.tab1}>
                        Frequently Asked Questions
                      </div>
                    </div>
                    <div
                      className={styles.tab2Wrapper}
                      onClick={onFrameContainer22Click}
                    >
                      <div className={styles.tab2}>Contact Us</div>
                    </div>
                  </div>
                  <div className={styles.faqParent}>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                        <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>What is Loggworks?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            Loggworks is a platform connecting Home service consumers
            (Homeowners, Tenants and Property managers) to Service
            Professionals.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </div>
                      </div>
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                          <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>How does Loggworks work?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            <p className={styles.signUpAsAServiceProfessio}>
              <span className={styles.signUpAs}>
                Sign up as a service professional:
              </span>
              <span>
                {" "}
                You choose the category and subcategory of the type of service
                you offer.
              </span>
            </p>
            <p className={styles.signUpAsAServiceProfessio}>
              <span className={styles.signUpAs}>Get bookings:</span>
              <span>
                {" "}
                The client messages you, gives you job details, and books you
                through the Loggworks webapp.
              </span>
            </p>
            <p className={styles.signUpAsAServiceProfessio}>
              <span className={styles.signUpAs}>Send quote and invoice:</span>
              <span>
                {" "}
                When you get the job details, you will send a quote containing
                the terms and conditions of the job upon the acceptance of the
                quote by the consumer, and then an invoice will be issued for
                payment.
              </span>
            </p>
            <p className={styles.signUpAsAServiceProfessio}>
              <span className={styles.signUpAs}>
                Get the job done and get paid:
              </span>
              <span>
                {" "}
                Upon job completion to the satisfaction of the client, your
                funds are made available to you. Get the job done and get paid.
              </span>
            </p>
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                          
                        </div>
                      </div>
                     
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                                             <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>Is there are signup/membership fee?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            No, there is no sign up fee.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                         
                        </div>
                      </div>
                     
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                            <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>How do I get jobs?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            Getting jobs is very easy. When your profile is completed and
            verified, clients will be able to message and call you through the
            app if they want to book or request your service.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </div>
                      </div>
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                            <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> Why must I request a job rating?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            Ratings and reviews are KPIs for getting more bookings; this is what
            property owners look out for when they want to book or request your
            service. You must always request ratings and reviews upon completion
            of every job, because this helps build your reputation.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </div>
                      </div>
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                            <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> How do i get jobs as a new Loggworks member?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            <p className={styles.signUpAsAServiceProfessio}>
              Your first time on Loggworks might be a little bit difficult to
              get jobs with no reviews and ratings, but these are a few tips to
              win jobs as a new member:
            </p>
            <ul className={styles.getYourProfileVerifiedByA}>
              <li className={styles.getYourProfile}>
                Get your profile verified by adding necessary license and
                certifications, references, and appropriate documents for proof
                of identification.
              </li>
              <li className={styles.getYourProfile}>
                Give offers and start with a low-cost service fee; this will
                enable you to get more reviews and ratings.
              </li>
              <li className={styles.getYourProfile}>
                Be more professional with your clients, and always make sure you
                give fast reply to their messages.
              </li>
              <li className={styles.getYourProfile}>
                once the job is completed, always ask for ratings to get more
                jobs.
              </li>
            </ul>
            <p className={styles.signUpAsAServiceProfessio}>
              These tips will help you build up your profile and earn you more
              jobs.
            </p>
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </div>
                      </div>
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                            <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> How do i create a quote?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            To create a quote, click on the Attach icon from the message screen
            of the client you are chatting with, select quote, click on + sign
            to create a quote, enter the details of the job information provided
            by your customer, save it, and then send it to the appropriate
            client. You can also edit it by clicking the quote icon from the
            home screen, then clicking on the edit option.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </div>
                      </div>
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                            <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>How do i promote my business?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            To create a promotion, click on the promote button from the profile,
            select offer, complete the terms and conditions of the offer, save
            it, and then publish your promotion. You can promote offers, deals
            of the day, and your profile for more reach.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </div>
                      </div>
                    </div>
                 
                  </div>
                </div>
              </div>
            </div>
           <PrototypeFooter/>
          </div>
         
        </div>
        <div className={styles.homeMobile}>
          <div className={styles.navbarMobileclosed}>
            <img className={styles.layer2Icon1} alt="" src="/layer-217.svg" 
            style={{cursor:'pointer'}} onClick={()=>navigate('/')} />
            <div className={styles.iconsaccountGroup}>
              <img
                className={styles.iconsaccount1}
                alt=""
                src="/iconsaccount13.svg"
                onClick={()=>window.open('https://www.consumer.loggworks.co.uk/login', '_blank')}
              />
              <img
                className={styles.hamburgerIcon}
                alt=""
                src="/hamburger.svg"
                onClick={openMobileMenuConsumerPopup}
              />
            </div>
          </div>
          <div className={styles.homeMobileInner}>
            <div className={styles.getAllTheHelpYouNeedGroup}>
              <div className={styles.getAllThe1}>
                Get all the help you need.
              </div>
              <div className={styles.loggworksConnectHomeowners1}>
                Loggworks connect homeowners to genuine and reliable service
                providers who provide easy-to-book and hassle-free services.
              </div>
            </div>
          </div>
          <div className={styles.twoTabSwitchWrapper}>
            <div className={styles.twoTabSwitch1}>
              <div className={styles.tab1Wrapper}>
                <div className={styles.tab1}>FAQ</div>
              </div>
              <div className={styles.tab2Container} onClick={onFrameContainer22Click}>
                <div className={styles.tab2}>Contact Us</div>
              </div>
            </div>
          </div>
          <div className={styles.faqGroup}>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>What is Loggworks?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            Loggworks is a platform connecting Home service consumers
            (Homeowners, Tenants and Property managers) to Service
            Professionals.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                </div>
              </div>
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>How does Loggworks work?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            <p className={styles.signUpAsAServiceProfessio}>
              <span className={styles.signUpAs}>
                Sign up as a service professional:
              </span>
              <span>
                {" "}
                You choose the category and subcategory of the type of service
                you offer.
              </span>
            </p>
            <p className={styles.signUpAsAServiceProfessio}>
              <span className={styles.signUpAs}>Get bookings:</span>
              <span>
                {" "}
                The client messages you, gives you job details, and books you
                through the Loggworks webapp.
              </span>
            </p>
            <p className={styles.signUpAsAServiceProfessio}>
              <span className={styles.signUpAs}>Send quote and invoice:</span>
              <span>
                {" "}
                When you get the job details, you will send a quote containing
                the terms and conditions of the job upon the acceptance of the
                quote by the consumer, and then an invoice will be issued for
                payment.
              </span>
            </p>
            <p className={styles.signUpAsAServiceProfessio}>
              <span className={styles.signUpAs}>
                Get the job done and get paid:
              </span>
              <span>
                {" "}
                Upon job completion to the satisfaction of the client, your
                funds are made available to you. Get the job done and get paid.
              </span>
            </p>
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                </div>
              </div>
             
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <div className={styles.whatIsLoggworks13}>
                    <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>Is there are signup/membership fee?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            No, there is no sign up fee.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>How do I get jobs?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            Getting jobs is very easy. When your profile is completed and
            verified, clients will be able to message and call you through the
            app if they want to book or request your service.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                </div>
              </div>
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <div className={styles.whatIsLoggworks13}>
                    <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>Why must I request a job rating?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            Ratings and reviews are KPIs for getting more bookings; this is what
            property owners look out for when they want to book or request your
            service. You must always request ratings and reviews upon completion
            of every job, because this helps build your reputation.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <div className={styles.whatIsLoggworks13}>
                    <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>How do i get jobs as a new Loggworks member?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            <p className={styles.signUpAsAServiceProfessio}>
              Your first time on Loggworks might be a little bit difficult to
              get jobs with no reviews and ratings, but these are a few tips to
              win jobs as a new member:
            </p>
            <ul className={styles.getYourProfileVerifiedByA}>
              <li className={styles.getYourProfile}>
                Get your profile verified by adding necessary license and
                certifications, references, and appropriate documents for proof
                of identification.
              </li>
              <li className={styles.getYourProfile}>
                Give offers and start with a low-cost service fee; this will
                enable you to get more reviews and ratings.
              </li>
              <li className={styles.getYourProfile}>
                Be more professional with your clients, and always make sure you
                give fast reply to their messages.
              </li>
              <li className={styles.getYourProfile}>
                once the job is completed, always ask for ratings to get more
                jobs.
              </li>
            </ul>
            <p className={styles.signUpAsAServiceProfessio}>
              These tips will help you build up your profile and earn you more
              jobs.
            </p>
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <div className={styles.whatIsLoggworks13}>
                    <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>How do i create a quote?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            To create a quote, click on the Attach icon from the message screen
            of the client you are chatting with, select quote, click on + sign
            to create a quote, enter the details of the job information provided
            by your customer, save it, and then send it to the appropriate
            client. You can also edit it by clicking the quote icon from the
            home screen, then clicking on the edit option.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <div className={styles.whatIsLoggworks13}>
                    <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> How do i promote my business?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            To create a promotion, click on the promote button from the profile,
            select offer, complete the terms and conditions of the offer, save
            it, and then publish your promotion. You can promote offers, deals
            of the day, and your profile for more reach.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                  
                </div>
              </div>
            </div>
           
          </div>
         <MobileFooter/>
        </div>
      </div>
      {isMobileMenuConsumerPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuConsumerPopup}
        >
          <MobileMenuProfProfessional1 onClose={closeMobileMenuConsumerPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default FAQ2;
