import { useEffect, useState } from "react";
import { Rating } from "@mui/material";
import { db } from "../../../firebase";

const StarReview: React.FC<{uid:any}> = ({uid}) => {
  const [star, setStar] = useState<any[]>(Array(4).fill(""));
  const [totalstar,setTotal]=useState(0)
  const [rating,setRating]=useState(0)
  useEffect(() => {
    db.collection("reviews")
    .where("uid", "==", uid)
    .get()
    .then((querySnapshot) => {
      let totalRating = 0;
      let numberOfReviews = querySnapshot.size;
     

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // Assuming 'stars' is the field containing the star rating in each document
        const rating = data.stars;

       
        totalRating += rating;
      });
      setTotal(numberOfReviews)
      if (numberOfReviews > 0) {
        console.log(totalRating)
        const avgRating = totalRating / numberOfReviews;
        setRating(Math.round(avgRating));
      }
    })
  }, [])
  
  return (
    <div className="stars flex items-center">
       <Rating name="read-only" value={rating} readOnly style={{color:'#2FA500',fontSize:'1rem'}}/>
    </div>
  );
};

export default StarReview;
