import React from 'react';
import { Link } from 'react-router-dom';

const NoArticle = () => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40rem', // 100% of viewport height
    flexDirection:'column',
    width:'100%'
  };

  const textStyle = {
    fontSize: '24px',
    textWeight:'bold',
  };

  return (
    <div style={containerStyle}>
       <b style={textStyle}>No articles yet</b>
      <br></br>
      <Link to={'/blog'} style={{color:'#2FA500'}}>Go to blog</Link> 
    </div>
  );
};

export default NoArticle;
