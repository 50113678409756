import { useState, useCallback } from "react";
import Dropdown from "../components/Dropdown";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./ConsumerContact.module.css";
const ConsumerContact = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const onPostAJobClick = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onHowItWorksClick = useCallback(() => {
    navigate("/consumerhow-it-works");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    navigate("/consumerhelpcontactdone");
  }, [navigate]);

  const onHelpFAQsClick = useCallback(() => {
    navigate("/consumerhelpfaqdone");
  }, [navigate]);

  const onUserAgreementTextClick = useCallback(() => {
    navigate("/careersdone");
  }, [navigate]);

  const onHowItWorks1Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onContactUsText1Click = useCallback(() => {
    navigate("/consumerhelpcontact");
  }, [navigate]);

  const onHelpFAQs1Click = useCallback(() => {
    navigate("/professionalhelpfaq");
  }, [navigate]);

  const onUserAgreementText1Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-usdone");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/consumerhelpfaqdone1");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/professionalhelpcontactdone");
  }, [navigate]);

  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/professionalhelpcontact2");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onLargeButtons1Click = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onFrameContainer26Click = useCallback(() => {
    navigate("/professionalhelpfaq");
  }, [navigate]);

  const onPageSelectorContainer1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onServicesContainerClick = useCallback(() => {
    navigate("/professionalhelpcontact1");
  }, [navigate]);

  const openDropdown = useCallback(() => {
    setDropdownOpen(true);
  }, []);

  const closeDropdown = useCallback(() => {
    setDropdownOpen(false);
  }, []);

  const onPricingTextClick = useCallback(() => {
    navigate("/professionalhiring-guidearticle");
  }, [navigate]);

  const onFrameContainer36Click = useCallback(() => {
    navigate("/professionalhelpfaq");
  }, [navigate]);

  return (
    <>
      <div className={styles.consumercontact}>
        <div className={styles.consumerhelpcontact}>
          <div className={styles.prototypeFooter}>
            <div className={styles.footer}>
              <div className={styles.download}>
                <div className={styles.downloadOurApps}>Download our apps</div>
                <img
                  className={styles.playstoreButtonIcon}
                  alt=""
                  src="/playstore-button17.svg"
                />
                <img
                  className={styles.appstoreButtonIcon}
                  alt=""
                  src="/appstore-button2.svg"
                />
              </div>
              <div className={styles.footerChild} />
              <img className={styles.layer2Icon} 
               style={{cursor:'pointer'}}
               onClick={()=>{navigate('/')}}
               alt="" src="/layer-2141.svg" />
              <div className={styles.frameParent}>
                <div className={styles.homeownersParent}>
                  <b className={styles.homeowners}>Homeowners</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.postAJob} onClick={onPostAJobClick}>
                      Post a Job
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHowItWorksClick}
                    >
                      How it works
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onContactUsTextClick}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHelpFAQsClick}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.postAJob}
                      onClick={onUserAgreementTextClick}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.professionalsParent}>
                  <b className={styles.homeowners}>Professionals</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.signUp}
                    style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                    <div
                      className={styles.postAJob}
                      onClick={onHowItWorks1Click}
                    >
                      Blog
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onContactUsText1Click}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHelpFAQs1Click}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.postAJob}
                      onClick={onUserAgreementText1Click}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.loggworksParent}>
                  <b className={styles.homeowners}>Loggworks</b>
                  <div className={styles.postAJobParent}>
                    <div
                      className={styles.postAJob}
                      onClick={onAboutUsTextClick}
                    >
                      About us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onCareersTextClick}
                    >
                      Careers
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onPartnersTextClick}
                    >
                      Partners
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onInfluencerProgramTextClick}
                    >
                      Affiliate program
                    </div>
                    <div className={styles.blog}>Blog</div>
                  </div>
                </div>
                <div className={styles.loggworksGroup}>
                  <div className={styles.loggworks1}>
                    <b className={styles.followUs}>Follow us</b>
                  </div>
                  <div className={styles.groupParent}>
                    <div className={styles.frameGroup}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://web.facebook.com/Loggworks?_rdc=1&_rdr', '_blank')}>
                      <img
                        className={styles.frameIcon}
                        alt=""
                        src="/frame20.svg"
                      />
                      <div className={styles.facebook}>Facebook</div>
                    </div>
                    <div className={styles.frameContainer}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://twitter.com/loggworks', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame211.svg"
                      />
                      <div className={styles.twitter}>Twitter</div>
                    </div>
                    <div className={styles.groupDiv} 
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.instagram.com/loggworks/?utm_medium=copy_link', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame24.svg"
                      />
                      <div className={styles.twitter}>Instagram</div>
                    </div>
                    <div className={styles.frameParent1}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame25.svg"
                      />
                      <div className={styles.twitter}>YouTube</div>
                    </div>
                    <div className={styles.mdilinkedinParent}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.linkedin.com/company/loggworks', '_blank')}>
                      <img
                        className={styles.mdilinkedinIcon}
                        alt=""
                        src="/mdilinkedin1.svg"
                      />
                      <div className={styles.linkedin}>LinkedIn</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.copyright2022LoggworkLtdAParent}>
                <div className={styles.copyright2022Loggwork}>
                  ©Copyright 2024 loggworks  Ltd. All Rights Reserved
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/terms')}}>
                      Terms of Use
                    </div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div
                      className={styles.copyright2022Loggwork}
                      style={{cursor:'pointer'}}
                      onClick={()=>{navigate('/privacy')}}
                    >{`Privacy Policy `}</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/cookie')}}>Cookies</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.contactUsParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Contact Us
                    </div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork} style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href='https://www.loggworks.co.uk/sitemap.xml'
                    }}>Sitemap</div>
                    <div className={styles.frameChild1} />
                  </div>
                </div>
              </div>
              <div className={styles.largeButtonsParent}>
                <button
                  className={styles.largeButtons}
                  style={{cursor:'pointer'}}
                  onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder67.svg"
                  />
                  <b className={styles.label}>Become a Professional</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder67.svg"
                  />
                </button>
                <button
                  className={styles.largeButtons}
                  onClick={onLargeButtons1Click}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder12.svg"
                  />
                  <b className={styles.label}>Post a Job</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder12.svg"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className={styles.frameParent2}>
            <div className={styles.frameParent3}>
              <div className={styles.frameParent4}>
                <div className={styles.inputParent}>
                  <div className={styles.input}>
                    <div className={styles.labelContainer}>
                      <div className={styles.label2}>First name</div>
                    </div>
                    <div className={styles.baseField}>
                      <img
                        className={styles.iconsplaceholder}
                        alt=""
                        src="/iconsplaceholder7.svg"
                      />
                      <div className={styles.userInput}>User input</div>
                      <div className={styles.dot}>
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                      </div>
                    </div>
                    <div className={styles.helperTextContainer}>
                      <div className={styles.thisIsA}>
                        This is a helper text
                      </div>
                      <div className={styles.div}>1/100</div>
                    </div>
                  </div>
                  <div className={styles.input}>
                    <div className={styles.labelContainer}>
                      <div className={styles.label2}>Lastname</div>
                    </div>
                    <div className={styles.baseField}>
                      <img
                        className={styles.iconsplaceholder}
                        alt=""
                        src="/iconsplaceholder7.svg"
                      />
                      <div className={styles.userInput}>User input</div>
                      <div className={styles.dot}>
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                      </div>
                    </div>
                    <div className={styles.helperTextContainer}>
                      <div className={styles.thisIsA}>
                        This is a helper text
                      </div>
                      <div className={styles.div}>1/100</div>
                    </div>
                  </div>
                </div>
                <div className={styles.input2}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label2}>Email address</div>
                  </div>
                  <div className={styles.baseField}>
                    <img
                      className={styles.iconsplaceholder}
                      alt=""
                      src="/iconsplaceholder7.svg"
                    />
                    <div className={styles.userInput}>User input</div>
                    <div className={styles.dot}>
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                    </div>
                  </div>
                  <div className={styles.helperTextContainer}>
                    <div className={styles.thisIsA}>This is a helper text</div>
                    <div className={styles.div}>1/100</div>
                  </div>
                </div>
                <div className={styles.input2}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label2}>Subject</div>
                  </div>
                  <div className={styles.baseField}>
                    <img
                      className={styles.iconsplaceholder}
                      alt=""
                      src="/iconsplaceholder7.svg"
                    />
                    <div className={styles.userInput}>User input</div>
                    <div className={styles.dot}>
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                    </div>
                  </div>
                  <div className={styles.helperTextContainer}>
                    <div className={styles.thisIsA}>This is a helper text</div>
                    <div className={styles.div}>1/100</div>
                  </div>
                </div>
                <div className={styles.input4}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label2}>
                      What would you like to talk about
                    </div>
                  </div>
                  <div className={styles.baseField4}>
                    <img
                      className={styles.iconsplaceholder}
                      alt=""
                      src="/iconsplaceholder7.svg"
                    />
                    <div className={styles.userInput}>User input</div>
                    <div className={styles.dot}>
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                    </div>
                  </div>
                  <div className={styles.helperTextContainer}>
                    <div className={styles.thisIsA}>This is a helper text</div>
                    <div className={styles.div}>1/100</div>
                  </div>
                </div>
              </div>
              <div className={styles.largeButtons2}>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder16.svg"
                />
                <b className={styles.label7}>Submit</b>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder16.svg"
                />
              </div>
            </div>
            <div className={styles.valuesCard}>
              <div className={styles.frameParent5}>
                <img
                  className={styles.frameChild2}
                  alt=""
                  onClick={()=>navigate('/')}
                style={{cursor:'pointer'}}
                  src="/frame-66522.svg"
                />
                <div className={styles.manchesterParent}>
                  <div className={styles.manchester}>Manchester</div>
                  <div className={styles.infologgworkscoukParent}>
                    <div className={styles.infologgworkscouk}>
                      Info@loggworks.co.uk
                    </div>
                    <div className={styles.rifacebookBoxFillParent}>
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/rifacebookboxfill2.svg"
                      />
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/ritwitterfill2.svg"
                      />
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/rilinkedinboxfill2.svg"
                      />
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/riinstagramfill2.svg"
                      />
                      <img
                        className={styles.rifacebookBoxFillIcon}
                        alt=""
                        src="/riyoutubefill2.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.twoTabSwitch}>
            <div
              className={styles.tab1Wrapper}
              onClick={onFrameContainer26Click}
            >
              <div className={styles.infologgworkscouk}>
                Frequently Asked Questions
              </div>
            </div>
            <div className={styles.tab2Wrapper}>
              <div className={styles.tab2}>Contact Us</div>
            </div>
          </div>
          <div className={styles.consumerhelpcontactInner}>
            <div className={styles.frameWrapper}>
              <div className={styles.getAllTheHelpYouNeedParent}>
                <b className={styles.getAllThe}>Get all the help you need.</b>
                <div className={styles.loggworksConnectHomeowners}>
                  Loggworks connect homeowners to genuine and reliable service
                  providers who provide easy-to-book and hassle-free services.
                </div>
              </div>
            </div>
          </div>
          <div className={styles.professionalNavigation}>
            <div className={styles.navigation}>
              <img
                className={styles.navigationChild}
                alt=""
                onClick={()=>navigate('/')}
                style={{cursor:'pointer'}}
                src="/frame-66522.svg"
              />
              <div className={styles.navigationItem} />
              <div className={styles.tabSwitch}>
                <div className={styles.pageSelector}>
                  <div className={styles.descriptionParent}>
                    <b className={styles.description}>Professional</b>
                    <div className={styles.selector} />
                  </div>
                </div>
                <div
                  className={styles.pageSelector1}
                  onClick={onPageSelectorContainer1Click}
                >
                  <div className={styles.descriptionParent}>
                    <div className={styles.copyright2022Loggwork}>Consumer</div>
                    <div className={styles.selector1} />
                  </div>
                </div>
              </div>
              <div className={styles.searchBar}>
                <div className={styles.iconssearchWrapper}>
                  <img
                    className={styles.rifacebookBoxFillIcon}
                    alt=""
                    src="/iconssearch.svg"
                  />
                </div>
                <div className={styles.electrician}>Electrician</div>
                <div className={styles.dividerParent}>
                  <div className={styles.divider} />
                  <div className={styles.location}>
                    <img
                      className={styles.iconslocation}
                      alt=""
                      src="/iconslocation.svg"
                    />
                    <div className={styles.locationOrPostcode}>
                      Location or postcode
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.navigation1}>
              <div className={styles.homeParent}>
                <div className={styles.home} onClick={onHomeTextClick}>
                  Home
                </div>
                <div className={styles.howItWorks2}>How it works</div>
                <div
                  className={styles.services}
                  onClick={onServicesContainerClick}
                >
                  <div className={styles.services1}>Categories</div>
                  <img
                    className={styles.iconschevronDown}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                <div className={styles.services} onClick={openDropdown}>
                  <div className={styles.services1}>Resources</div>
                  <img
                    className={styles.iconschevronDown}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                <div className={styles.home} onClick={onPricingTextClick}>
                  Pricing
                </div>
              </div>
              <div className={styles.frameParent6}>
                <div className={styles.frameParent7}>
                  <div
                    className={styles.iconshelpParent}
                    onClick={onFrameContainer36Click}
                  >
                    <img
                      className={styles.rifacebookBoxFillIcon}
                      alt=""
                      src="/iconshelp1.svg"
                    />
                    <div className={styles.help}>Help</div>
                  </div>
                  <div className={styles.iconsaccountParent}>
                    <img
                      className={styles.rifacebookBoxFillIcon}
                      alt=""
                      src="/iconsaccount11.svg"
                    />
                    <div className={styles.help}>Account</div>
                  </div>
                </div>
                <div className={styles.smallButtons} style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                  <div className={styles.buttonWrapper}>
                    <div className={styles.button}>Become a professional</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDropdownOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDropdown}
        >
          <Dropdown onClose={closeDropdown} />
        </PortalPopup>
      )}
    </>
  );
};

export default ConsumerContact;
