import { useState, useCallback } from "react";
import Dropdown4 from "../components/Dropdown4";
import PortalPopup from "../components/PortalPopup";
import Dropdown5 from "../components/Dropdown5";
import MobileMenuConsumer1 from "../components/MobileMenuConsumer1";
import FrameComponent1 from "../components/FrameComponent1";
import { useNavigate } from "react-router-dom";
import styles from "./SearchResult.module.css";
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import MobileFooter from "../components/MobileFooter";
import Search from "./search/Search.tsx";
import LocationSearch2 from "../components/locationSearch2.tsx";
import LocationSearch from "../components/locationSearch.tsx";

const SearchResult = () => {
 const [isDropdownOpen, setDropdownOpen] = useState(null);
  const open = Boolean(isDropdownOpen);
  const openDropdown = (event) => {
    setDropdownOpen(event.currentTarget);
  };
  const closeDropdown = () => {
    setDropdownOpen(null);
  };
  const [isDropdown1Open, setDropdown1Open] = useState(null);
  const open1 = Boolean(isDropdown1Open);
   const openDropdown1 = (event) => {
    setDropdown1Open(event.currentTarget);
  };
  const closeDropdown1 = () => {
    setDropdown1Open(null);
  };
  const [isMobileMenuConsumerPopupOpen, setMobileMenuConsumerPopupOpen] =
    useState(false);
  const [isFrame1Open, setFrame1Open] = useState(false);
  const navigate = useNavigate();

  const onPostAJob3Click = useCallback(() => {
    navigate('/postjob')
   }, [navigate]);
 
   const onHowItWorks3Click = useCallback(() => {
     navigate("/how-we-work");
   }, [navigate]);
 
   const onContactUsText3Click = useCallback(() => {
     navigate("/contact-us");
   }, [navigate]);
 
   const onHelpFAQs3Click = useCallback(() => {
     navigate("/faq");
   }, [navigate]);
 
   const onUserAgreementText3Click = useCallback(() => {
     navigate("/agreement");
   }, [navigate]);
 
   const onHowItWorks4Click = useCallback(() => {
    navigate('/blog');
   }, [navigate]);
 
   const onContactUsText4Click = useCallback(() => {
     navigate("/contact-us");
   }, [navigate]);
 
   const onHelpFAQs4Click = useCallback(() => {
     navigate("/faq");
   }, [navigate]);
 
   const onUserAgreementText4Click = useCallback(() => {
     navigate("/careers");
   }, [navigate]);
 
   const onAboutUsTextClick = useCallback(() => {
     navigate("/about-us");
   }, [navigate]);
 
   const onCareersTextClick = useCallback(() => {
     navigate("/becometeam");
   }, [navigate]);
 
   const onPartnersTextClick = useCallback(() => {
     navigate("/partners");
   }, [navigate]);
 
   const onInfluencerProgramTextClick = useCallback(() => {
     navigate("/affiliate-program");
   }, [navigate]);
 
   const onLargeButtonsClick = useCallback(() => {
     navigate("/home-service-provider");
   }, [navigate]);
 
   const onLargeButtons1Click = useCallback(() => {
    navigate('/postjob')
   }, [navigate]);
 
   const onBlogBottomPosts3Click = useCallback(() => {
     navigate("/professionalhiring-guidearticledone");
   }, [navigate]);
 
   const onLargeButtons2Click = useCallback(() => {
    navigate('/blog');
   }, [navigate]);
 
   const onLargeButtons3Click = useCallback(() => {
    navigate('/postjob')
   }, [navigate]);
 
   const onServicesWebContainer15Click = useCallback(() => {
     navigate("/search-results");
   }, [navigate]);
 
   const onFrameContainer142Click = useCallback(() => {
     navigate("/about-us");
   }, [navigate]);
 
   const onCategoriesContainer35Click = useCallback(() => {
     navigate("/about-us");
   }, [navigate]);
 
   const onLargeButtons4Click = useCallback(() => {
     navigate("/home-service-provider");
   }, [navigate]);
 
   const onSearchBarClick = useCallback(() => {
     navigate("/search-results");
   }, [navigate]);
 
   const onLargeButtons5Click = useCallback(() => {
    navigate('/postjob')
   }, [navigate]);
 
   const onPageSelectorContainerClick = useCallback(() => {
     navigate("/professional");
   }, [navigate]);
 
   const onHowItWorks5Click = useCallback(() => {
     navigate("/how-we-work");
   }, [navigate]);
   const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);
 
   const onFrameButtonClick = useCallback(() => {
     navigate("/faq");
   }, [navigate]);
 
   const onSmallButtonsClick = useCallback(() => {
    navigate('/postjob')
   }, [navigate]);

  const openMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(true);
  }, []);

  const closeMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(false);
  }, []);

  const openFrame1 = useCallback(() => {
    setFrame1Open(true);
  }, []);

  const closeFrame1 = useCallback(() => {
    setFrame1Open(false);
  }, []);

  return (
    <>
      <div className={styles.searchresult}>
        <div className={styles.searchResults}>
           <nav className={styles.navigation}>
            <div className={styles.navigation1}>
              <img
                className={styles.navigationChild}
                alt=""
                onClick={()=>navigate('/')}
                style={{cursor:'pointer'}}
                src="/frame-66522.svg"
              />
              <div className={styles.navigationItem} />
              <div className={styles.tabSwitch}>
                <div
                  className={styles.pageSelector}
                  onClick={onPageSelectorContainerClick}
                >
                  <div className={styles.descriptionParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Professional
                    </div>
                    <div className={styles.selector} />
                  </div>
                </div>
                <div className={styles.pageSelector1}>
                  <div className={styles.descriptionParent}>
                    <b className={styles.filter}>Consumer</b>
                    <div className={styles.selector1} />
                  </div>
                </div>
              </div>
              <LocationSearch/>
            </div>
            <div className={styles.navigation2}>
              <div className={styles.homeParent}>
                <div className={styles.home} onClick={onHomeTextClick}>
                  Home
                </div>
                <div className={styles.home} onClick={onHowItWorks3Click}>
                  How it works
                </div>
                <div className={styles.services}
                id="fade-button"
  aria-controls={open ? 'fade-menu' : undefined}
  aria-haspopup="true"
  aria-expanded={open ? 'true' : undefined}
                 onClick={openDropdown}>
                  <div className={styles.filters1}>Services</div>
                  <img
                    className={styles.iconschevronDown}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={isDropdownOpen}
        open={open}
        onClose={closeDropdown}
        TransitionComponent={Fade}
      >
      <Dropdown4/>
      </Menu>
                <div className={styles.services} 
                id="fade-button2"
 aria-controls={open1 ? 'fade-menu2' : undefined}
 aria-haspopup="true"
 aria-expanded={open1 ? 'true' : undefined}
                onClick={openDropdown1}>
                  <div className={styles.filters1}>Resources</div>
                  <img
                    className={styles.iconschevronDown}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                  <Menu
        id="fade-menu2"
        MenuListProps={{
          'aria-labelledby': 'fade-button2',
        }}
        anchorEl={isDropdown1Open}
        open={open1}
        onClose={closeDropdown1}
        TransitionComponent={Fade}
      >
      <Dropdown5/>
      </Menu>
                <div className={styles.pricing}>Pricing</div>
              </div>
              <div className={styles.frameParent23}>
                <div className={styles.frameParent24}>
                  <button
                    className={styles.iconshelpParent}
                    onClick={onFrameButtonClick}
                  >
                    <img
                      className={styles.iconschevronDown}
                      alt=""
                      src="/iconshelp.svg"
                    />
                    <div className={styles.help}>Help</div>
                  </button>
                  <button className={styles.iconshelpParent}
                  onClick={()=>window.open('https://www.consumer.loggworks.co.uk/login', '_blank')}>
                    <img
                      className={styles.iconschevronDown}
                      alt=""
                      src="/iconsaccount11.svg"
                    />
                    <div className={styles.help}>Account</div>
                  </button>
                </div>
                <button
                  className={styles.smallButtons9}
                  onClick={onSmallButtonsClick}
                >
                  <div className={styles.iconsaddParent}>
                    <img
                      className={styles.iconsstar}
                      alt=""
                      src="/iconsadd16.svg"
                    />
                    <div className={styles.button9}>Post a job</div>
                  </div>
                </button>
              </div>
            </div>
          </nav>
          <div className={styles.mergeParent}>
            <Search width={'100%'}/>
          </div>
          <div className={styles.prototypeFooter}>
            <div className={styles.footer}>
              <div className={styles.download}>
                <div className={styles.downloadOurApps}>Download our apps</div>
                <img
                  className={styles.playstoreButtonIcon}
                  alt=""
                  src="/playstore-button121.svg"
                />
                <img
                  className={styles.appstoreButtonIcon}
                  alt=""
                  src="/appstore-button121.svg"
                />
              </div>
              <div className={styles.footerChild} />
              <img className={styles.layer2Icon} 
               style={{cursor:'pointer'}}
               onClick={()=>{navigate('/')}}
               alt="" src="/layer-2101.svg" />
              <div className={styles.frameParent}>
                <div className={styles.homeownersParent}>
                  <b className={styles.homeowners}>Homeowners</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.postAJob} onClick={onPostAJob3Click}>
                      Post a Job
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHowItWorks3Click}
                    >
                      How it works
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onContactUsText3Click}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHelpFAQs3Click}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.postAJob}
                      onClick={onUserAgreementText3Click}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.professionalsParent}>
                  <b className={styles.homeowners}>Professionals</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.signUp}
                    style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                    <div
                      className={styles.postAJob}
                      onClick={onBlogBottomPosts3Click}
                    >
                      Blog
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onContactUsText4Click}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHelpFAQs4Click}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.postAJob}
                      onClick={onUserAgreementText4Click}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.loggworksParent}>
                  <b className={styles.homeowners}>Loggworks</b>
                  <div className={styles.postAJobParent}>
                    <div
                      className={styles.postAJob}
                      onClick={onAboutUsTextClick}
                    >
                      About us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onCareersTextClick}
                    >
                      Careers
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onPartnersTextClick}
                    >
                      Partners
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onInfluencerProgramTextClick}
                    >
                      Affiliate program
                    </div>
                    <div className={styles.blog}>Blog</div>
                  </div>
                </div>
                <div className={styles.loggworksGroup}>
                  <div className={styles.loggworks1}>
                    <b className={styles.followUs}>Follow us</b>
                  </div>
                  <div className={styles.groupParent}>
                    <div className={styles.frameGroup}>
                      <img
                        className={styles.frameIcon}
                        alt=""
                        src="/frame20.svg"
                      />
                      <div className={styles.facebook}>Facebook</div>
                    </div>
                    <div className={styles.frameContainer}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame37.svg"
                      />
                      <div className={styles.twitter}>Twitter</div>
                    </div>
                    <div className={styles.groupDiv}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame22.svg"
                      />
                      <div className={styles.twitter}>Instagram</div>
                    </div>
                    <div className={styles.frameParent1}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame23.svg"
                      />
                      <div className={styles.twitter}>YouTube</div>
                    </div>
                    <div className={styles.mdilinkedinParent}>
                      <img
                        className={styles.mdilinkedinIcon}
                        alt=""
                        src="/mdilinkedin1.svg"
                      />
                      <div className={styles.linkedin}>LinkedIn</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.copyright2022LoggworkLtdAParent}>
                <div className={styles.copyright2022Loggwork}>
                  ©Copyright 2024 loggworks  Ltd. All Rights Reserved
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/terms')}}>
                      Terms of Use
                    </div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div
                      className={styles.copyright2022Loggwork}
                      style={{cursor:'pointer'}}
                      onClick={()=>{navigate('/privacy')}}
                    >{`Privacy Policy `}</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                     style={{cursor:'pointer'}}
                     onClick={()=>{navigate('/cookie')}}>Cookies</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.contactUsParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Contact Us
                    </div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork} style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href='https://www.loggworks.co.uk/sitemap.xml'
                    }}>Sitemap</div>
                    <div className={styles.frameChild1} />
                  </div>
                </div>
              </div>
              <div className={styles.largeButtonsParent}>
                <button
                  className={styles.largeButtons}
                  onClick={onLargeButtonsClick}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder47.svg"
                  />
                  <b className={styles.label}>Become a Professional</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder47.svg"
                  />
                </button>
                <button
                  className={styles.largeButtons}
                  onClick={onLargeButtons1Click}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder48.svg"
                  />
                  <b className={styles.label}>Post a Job</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder48.svg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.homeMobile}>
          <div className={styles.navbarMobileclosed}>
            <img className={styles.layer2Icon1} alt="" src="/layer-214.svg" onClick={()=>{navigate('/')}} />
            <div className={styles.iconsaccountGroup}>
              <img
                className={styles.iconsaccount1}
                alt=""
                src="/iconsaccount9.svg"
                onClick={()=>window.open('https://www.consumer.loggworks.co.uk/login', '_blank')}
              />
              <img
                className={styles.hamburgerIcon}
                alt=""
                src="/hamburger.svg"
                onClick={openMobileMenuConsumerPopup}
              />
            </div>
          </div>
          <div className={styles.inputWrapper}>
            <div className={styles.input49}>
            <LocationSearch2/>
            </div>
          </div>
         
          <div className={styles.promotionsGroup}>
            <Search width={'100vw'}/>
          </div>
          <MobileFooter/>
        </div>
      </div>
      {isMobileMenuConsumerPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuConsumerPopup}
        >
          <MobileMenuConsumer1 onClose={closeMobileMenuConsumerPopup} />
        </PortalPopup>
      )}
      {isFrame1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeFrame1}
        >
          <FrameComponent1 onClose={closeFrame1} />
        </PortalPopup>
      )}
    </>
  );
};

export default SearchResult;
