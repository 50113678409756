import React from 'react'
import styles from '../ForConsumers.module.css'
import { useNavigate } from 'react-router-dom'

function CustBanner() {
    const navigate = useNavigate()
  return (
    <div>
         <div className={styles.frameParent16}>
          <div className={styles.mostPopularArticlesGroup}>
            <div className={styles.findTheBestHomeServiceProParent}>
              <b className={styles.findTheBest}>
                Find the Best Home Service Professionals On Loggworks
              </b>
              <div className={styles.postJobsForContainer}>
                <p className={styles.letUsBe}>
                  Post jobs for free on Loggworks and find reliable home service
                  professionals in your area. Choose
                </p>
                <p
                  className={styles.letUsBe}
                >{`the best options for your budgets and timeline. `}</p>
              </div>
            </div>
            <button className={styles.smallButtons2}
            onClick={()=>navigate('/postjob')}>
              <div className={styles.loggworkscoukParent}>
                <img className={styles.arrowrightIcon} alt="" src="/plus.svg" />
                <div className={styles.loggworkscouk}>Post A Job For Free</div>
              </div>
            </button>
          </div>
          <img className={styles.maskGroupIcon} alt="" src="/mask-group.svg" />
        </div>

        <div className={styles.rectangleParent36}>
        <div
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "0px 10px",
        textAlign: "left",
        fontSize: "20px",
        color: "#fff",
        fontFamily: "Inter",
      }}
    >
      <div
        style={{
          width: "335px",
          position: "relative",
          height: "496px",
          zIndex: "0",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            borderRadius: "6px",
            backgroundColor: "#2e2e2e",
            width: "335px",
            height: "496px",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "24px",
            left: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "24px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "16px 0px",
              }}
            >
              <b
                style={{
                  width: "287px",
                  position: "relative",
                  lineHeight: "28.8px",
                  display: "inline-block",
                }}
              >
                <p style={{ margin: "" }}>Find the Best Home</p>
                <p style={{ margin: "" }}>Service Professionals</p>
                <p style={{ margin: "" }}>On Loggworks</p>
              </b>
              <div
                style={{
                  width: "287px",
                  position: "relative",
                  fontSize: "12px",
                  lineHeight: "21px",
                  display: "inline-block",
                }}
              >{`Post jobs for free on Loggworks and find reliable home service professionals in your area. Choose the best options for your budgets and timeline. `}</div>
            </div>
            <div
              style={{
                width: "192px",
                position: "relative",
                height: "48px",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              <div
               onClick={()=>navigate('/postjob')}
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  borderRadius: "5px",
                  backgroundColor: "#2fa500",
                  border: "1px solid #2fa500",
                  boxSizing: "border-box",
                  width: "192px",
                  height: "48px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "8px 0px",
                  gap: "0px 8px",
                }}
              >
                <b
                  style={{
                    position: "relative",
                    lineHeight: "24px",
                    textTransform: "capitalize",
                  }}
                >
                  Post A Job For Free
                </b>
                <img
                  style={{
                    width: "20px",
                    position: "relative",
                    height: "20px",
                    overflow: "hidden",
                    flexShrink: "0",
                    display: "none",
                  }}
                  alt=""
                  src="/iconsadd4.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        style={{
          width: "335px",
          position: "absolute",
          margin: "0",
          top: "272px",
          left: "0px",
          height: "224px",
          zIndex: "1",
        }}
        alt=""
        src="/mask-group3.svg"
      />
    </div>
        </div>
    </div>
  )
}

export default CustBanner