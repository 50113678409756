
import {
  HomeIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import CategoryData from "./categoryData.tsx";
import { useNavigate } from "react-router-dom";
import styles from './Header.module.css'
import { db } from "../firebase.js";
import { MenuItem } from "@mui/material";
import Highlighter from "react-highlight-words";

const LocationSearch = () => {
  const { categories } = CategoryData();
  const [openSearch, setOpenSearch] = useState(false);
  const [isInput, setIsInput] = useState(false);
  const [cate, setCate]=useState('')
  const [lat, setLat]=useState('')
  const [lng, setLng]=useState('')
  const [postcode, setPostCode]=useState('')
  const [sugg, setSugg] = useState<any[]>([]);
  const [sub_sugg, setSub_Sugg] = useState<any[]>([]);
  const Goto = useNavigate()
  const [address, setAddress] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    getCurrentLocation();
  }, []);
const getCurrentLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;

        // Replace 'YOUR_GOOGLE_API_KEY' with your actual Google API key
        const apiKey = 'AIzaSyDrIc0MDLHaoFOaSLFKPRcGG-bM3Xpt-w0';
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

        try {
          const response = await fetch(apiUrl);
          const data = await response.json();

          if (data.status === 'OK' && data.results && data.results.length > 0) {
            const formattedAddress = data.results[0].formatted_address;
            setAddress(formattedAddress);
          } else {
            setError('Address not found');
          }
        } catch (error) {
          setError('Error fetching address');
        }
      },
      (error) => {
        setError(`Geolocation error: ${error.message}`);
      }
    );
  } else {
    setError('Geolocation is not supported by your browser');
  }
};

  async function postcodeToLatLng(postcode:any) {
    console.log(postcode)
    // Replace 'YOUR_API_KEY' with your actual Google Maps API key
    const apiKey = 'AIzaSyDrIc0MDLHaoFOaSLFKPRcGG-bM3Xpt-w0';
  
    // Construct the geocoding API URL
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      postcode
    )}&key=${apiKey}`;
  
    try {
      // Fetch data from the API
      const response = await fetch(apiUrl);
      const data = await response.json();
  
      // Check if the API request was successful
      if (data.status === 'OK' && data.results.length > 0) {
        // Extract lat/lng from the first result
        const location = data.results[0].geometry.location;
        const lat = location.lat;
        const lng = location.lng;
        setLat(location.lat)
        setLng(location.lng)
        const userLocation = { lat: location.lat, lng: location.lng }; // Example user location
const maxDistanceMiles = 5; // Maximum distance in miles

  
        return { lat, lng };
      } else {
        // Handle API errors or invalid postcode
        console.error('Geocoding API error:', data.status);
        return null;
      }
    } catch (error) {
      console.error('Error fetching geocoding data:', error);
      return null;
    }
  }
  
 
  
  const handleOnChange = (e: any) => {
    console.log(e.target.name)
    if (e.target.name=='cate') {
      setCate(e.target.value)
      getUsersWithinDistanceAndCategory(e.target.value)
      if (e.target.value.length <= 0) {
        setOpenSearch(false);
        setIsInput(false);
      } else {
        setIsInput(true);
        setOpenSearch(true);
      }
      const userLocation = { lat: lat, lng: lng }; // Example user location
      const maxDistanceMiles = 5; // Maximum distance in miles
   
    }
    else{
      setPostCode(e.target.value)
      postcodeToLatLng(e.target.value)
      .then((result) => {
        if (result) {
          console.log('Latitude:', result.lat);
          console.log('Longitude:', result.lng);
        } else {
          console.log('Failed to convert postcode to lat/lng.');
        }
      })
      .catch((error) => {
        console.error('An error occurred:', error);
      });
    }
   
  };
  const findClosestMatch = (userInput:any, category:any) => {
    const options = category.subcategories || [];
  
    // Check for an exact match with the category title
    if (userInput.toLowerCase() === category.id.toLowerCase()) {
      return { title: category.id, closestOption: '', distance: 0 };
    }
  
    // Check for an exact match with any option
    const exactOption = options.find((option:any) => userInput.toLowerCase() === option.toLowerCase());
    if (exactOption) {
      return { title: category.id, closestOption: exactOption, distance: 0 };
    }
  
    // Find the option with the minimum Levenshtein distance
    const closestOption = options.reduce((closest:any, option:any) => {
      const distance = levenshteinDistance(userInput, option.toLowerCase());
  
      if (distance < closest.distance || userInput.toLowerCase().includes(option.toLowerCase())) {
        return { option, distance };
      }
  
      return closest;
    }, { option: '', distance: Infinity });
  
    return { title: category.title, closestOption: closestOption.option, distance: closestOption.distance };
  };
  const levenshteinDistance = (s1:any, s2:any) => {
    const m = s1.length;
    const n = s2.length;
    const dp = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0));
  
    for (let i = 0; i <= m; i++) {
      for (let j = 0; j <= n; j++) {
        if (i === 0) {
          dp[i][j] = j;
        } else if (j === 0) {
          dp[i][j] = i;
        } else {
          dp[i][j] = Math.min(
            dp[i - 1][j - 1] + (s1.charAt(i - 1) === s2.charAt(j - 1) ? 0 : 1),
            dp[i][j - 1] + 1,
            dp[i - 1][j] + 1
          );
        }
      }
    }
  
    return dp[m][n];
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const usersRef = db.collection('categories');
    const tagRef = db.collection('serviceTags');
  
    const querySnapshot = usersRef.get();
    const tagSnapshot = tagRef.get();
  
    querySnapshot.then(function (userSnap: any) {
      userSnap.forEach(function (doc: any) {
        const data = doc.data();
        const id = doc.id;
  
        if (id === cate) {
          if (isInput) {
            setOpenSearch(false);
            if (postcode) {
              window.location.href = '/search/' + postcode + '/' + id + '/all';
            } else {
              if (error) {
                getCurrentLocation();
                return;
              }
              window.location.href = '/search/' + address + '/' + id + '/all';
            }
          }
        } else if(data.subcategories.includes(cate)) {
          if (isInput) {
            setOpenSearch(false);
            if (postcode) {
              window.location.href = '/search/' + postcode + '/' + id + '/' + cate;
            } else {
              if (error) {
                getCurrentLocation();
                return;
              }
              window.location.href = '/search/' + address + '/' + id + '/' + cate;
            }
          }
        }
        else{
          tagSnapshot.then(function (userSnap: any) {
            userSnap.forEach(function (doc: any) {
              const data = doc.data();
              const id = doc.id;
        
              if (id === cate) {
                if (isInput) {
                  setOpenSearch(false);
                  if (postcode) {
                    window.location.href = '/search/' + postcode + '/' + id + '/all';
                  } else {
                    if (error) {
                      getCurrentLocation();
                      return;
                    }
                    window.location.href = '/search/' + address + '/' + id + '/all';
                  }
                }
              } else if(data.subcategories.includes(cate)) {
                if (isInput) {
                  setOpenSearch(false);
                  if (postcode) {
                    window.location.href = '/search/' + postcode + '/' + id + '/' + cate;
                  } else {
                    if (error) {
                      getCurrentLocation();
                      return;
                    }
                    window.location.href = '/search/' + address + '/' + id + '/' + cate;
                  }
                }
              }
            });
          });
        }
      });
    });
  };
  

  const closeBackDrop = (e: any) => {
    if (e.target.classList.contains("closeBackdrop" || "")) {
      setOpenSearch(false);
      console.log(e.target);
    }
  };

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  async function getUsersWithinDistanceAndCategory(categ:any) {
    try {
      const usersRef = db.collection('categories');
const tagRef = db.collection('serviceTags');

const querySnapshot = await usersRef.get();
const tagSnapshot = await tagRef.get();
let suggestions: any[] = [];
let subs: any[] = [];

querySnapshot.forEach((doc) => {
  const data = doc.data();
  data.id = doc.id;

  if (doc.id.toLowerCase().startsWith(categ.toLowerCase()) || doc.id.toLowerCase() === categ.toLowerCase()) {
    if (!suggestions.some((item) => item.id === data.id)) {
      suggestions.push(data);
    }
  } else {
    data.subcategories
      .filter((x: any) => {
        if (x.toLowerCase().startsWith(categ.toLowerCase()) || x.toLowerCase() === categ.toLowerCase()) {
          if (!suggestions.some((item) => item.id === data.id)) {
            suggestions.push(data);
          }
          if (!subs.includes(x) && !subs.includes('others')) {
            subs.push(x);
          }
        }
        return false; // Added to satisfy the filter callback
      });
  }
});

tagSnapshot.forEach((doc) => {
  const data = doc.data();
  data.id = doc.id;

  if (doc.id.toLowerCase().startsWith(categ.toLowerCase()) || doc.id.toLowerCase() === categ.toLowerCase()) {
    if (!suggestions.some((item) => item.id === data.id)) {
      suggestions.push(data);
    }
  } else {
    data.subcategories
      .filter((x: any) => {
        if (x.toLowerCase().startsWith(categ.toLowerCase()) || x.toLowerCase() === categ.toLowerCase()) {
          if (!suggestions.some((item) => item.id === data.id)) {
            suggestions.push(data);
          }
          if (!subs.includes(x) && !subs.includes('others')) {
            subs.push(x);
          }
        }
        return false; // Added to satisfy the filter callback
      });
  }
});


        setSugg(suggestions);
        setSub_Sugg(subs)
    } catch (error) {
      console.error('Error fetching users:', error);
      return [];
    }
  }
  

  
  const [hover, setHover] = useState(Array(categories.length).fill(false));

  const onHoverEnter = (index: number) => {
    const newArr = [...hover];
    newArr[index] = true;
    setHover(newArr);
  };

  const onHoverLeave = (index: number) => {
    const newArr = [...hover];
    newArr[index] = false;
    setHover(newArr);
  };
  function highlightMatchingLetters(str1, str2) {
    let result = '';
  
    for (let i = 0; i < Math.min(str1.length, str2.length); i++) {
      if (str1[i] === str2[i]) {
        // Highlight the matching letters, for example, by wrapping them in a span with a class
        result += `<b>${str1[i]}</b>`;
      } else {
        result += str1[i];
      }
    }
  
    return result;
  }

  return (
    <div className={styles.searchBar1}>
       <div className="location_search max-md:mt-5 max-md:mb-4 w-full md:w-[420px] h-[44px] text-[16px] border border-grey rounded max-sm:max-w-[100%] max-sm:min-w-[100%] max-md:w-[420px] max-md:mx-auto">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="relative flex items-center w-full text-grey40 ">
          <div className="">
            <input
              type="text"
              id="default-search"
              className="w-full pl-2 text-[16px] text-gray-900 outline-none"
               style={{background:'transparent'}}
              placeholder="Service"
              name={'cate'}
              value={cate}
              onChange={(e) => handleOnChange(e)}
            />
          </div>
          <div className="flex items-center justify-between">
            <div className="col-span-6 relative border-l border-grey flex items-center py-[3px]">
              <div className="mx-[6px]">
                <MapPinIcon width={20} height={24} />
              </div>
              <input
                type="text"
                id="default-search"
                className="                               
                w-full outline-none text-gray-900 md:pr-10 text-[16px]"
                placeholder="Town/postcode"
                name={'zip'}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
            <button
              type="submit"
              
              className={`py-[9px] text-grey40 px-4 items-center ${
                isInput && "bg-green text-white"
              }`}
            >
              <MagnifyingGlassIcon width={20} height={24} />
            </button>
          </div>
        </div>

        {openSearch && (
          <div className="searchDetail max-md:h-screen max-md:absolute w-full left-0 px-3">
            <div className="md:relative ">
              <div
                className={`md:fixed absolute md:bg-modalDrop z-50 w-full top-[1px] md:top-[60px] left-0 h-full closeBackdrop ${
                  !isSticky && "max-md:bg-modalDrop"
                }`}
                onClick={closeBackDrop}
              >
                <div
                  className="mx-auto text-grey40 closeBackdrop"
                  style={{maxWidth:'1430px',width:'100%'}}
                  onClick={closeBackDrop}
                >
                  <div className="modal-content relative bg-white md:w-[420px] h-[369px] rounded py-4 shadow dark:bg-gray-700 px-4 md:px-4 md:left-[20.5%]"
                  style={{overflowY:'auto'}}>
                    <ul className="text-[16px] mt-7">
                    <b style={{color:"black"}}>suggested services</b>
                   { sugg.map((x:any, index:any) => (
                           <MenuItem key={index}
                           onClick={()=>{
                            setCate(x.id)
                            setOpenSearch(false);
                          }}>
                            <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[cate]}
    autoEscape={true}
    textToHighlight= {x.id}
  /></MenuItem> 
                            ))
                        }
                        <br></br>
                  <b style={{color:"black"}}>suggested subservices</b>
                   { sub_sugg.length==0?
                    sugg.map((x, index:any) => (
                      x.subcategories.map((data:any)=>(
                        <MenuItem key={index}
                        onClick={()=>{
                          setCate(data)
                          setOpenSearch(false);
                        }}>
                          <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[cate]}
    autoEscape={true}
    textToHighlight= {data}
  />
                         </MenuItem> 
                      ))
                    ))
                    :
                    sub_sugg.map((x, index:any) => (
                      <MenuItem key={index}
                      onClick={()=>{
                        setCate(x)
                        setOpenSearch(false);
                      }}>
                        <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={[cate]}
    autoEscape={true}
    textToHighlight= {x}
  />
                        </MenuItem> 
                    ))
                    }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
    </div>
   
  );
};

export default LocationSearch;
