import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Postview.module.css";
import ToolBar from "./components/ToolBar";
import PrototypeFooter from "../../components/PrototypeFooter";
import { useState } from "react";
import mobnav from "../ConsumerHome.module.css";
import MobileFooter from "../../components/MobileFooter";
import PortalPopup from "../../components/PortalPopup";
import MenuMobile from "./components/MenuMobile";
import axios from "axios";
import ReactMarkdown from 'react-markdown';
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import ProfBanner from "./components/ProfBanner";
import SubsBanner from "./components/SubsBanner";
import { Helmet } from "react-helmet-async";

const Postview2 = () => {
  const [
    isMobileMenuProfProfessionalPopupOpen,
    setMobileMenuProfProfessionalPopupOpen,
  ] = useState(false);
  const openMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(true);
  }, []);
  let {id}=useParams()

  const closeMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(false);
  }, []);
  const [blog, setBlog]=useState()
  useEffect(() => {
    axios.get(`https://loggworks-cms-1213ad49d34a.herokuapp.com/api/for-professionals?filters[slug][$eq]=${id}&populate=*`)
    .then(response => {
      const blogsData = response.data.data[0];
      console.log(blogsData)
     setBlog(blogsData)
    })
    .catch(error => {
      console.error('Error fetching blogs:', error);
    });
  }, []);
  const navigate = useNavigate();

  const onBLOGTextClick = useCallback(() => {
    navigate("/blog");
  }, [navigate]);

  const onTrendingArticlesTextClick = useCallback(() => {
    navigate("/blog/for-professionals");
  }, [navigate]);

  const url = window.location.href;
  const handleShareFacebook = () => {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(shareUrl, '_blank');
  };

  const handleShareTwitter = () => {
    const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`;
    window.open(shareUrl, '_blank');
  };

  const handleShareLinkedIn = () => {
    const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
    window.open(shareUrl, '_blank');
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(url);
    alert('Link copied to clipboard!');
  };
  const formatDateToWords = (dateString) => {
    const date = new Date(dateString);
    
    // Array of month names
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    // Get the components of the date
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    
    // Construct the formatted date string
    const formattedDate = `${month} ${day}, ${year}`;
    
    return formattedDate;
  };
  if (blog) {
    return (
    <div className={styles.postview}>
      <Helmet>
                <meta charSet="utf-8" /> 
                  <title>{blog?.attributes.BlogTitle}</title>
                  <meta name="description" 
                  content={blog?.attributes.description}/>
                  <meta property="og:image" content={blog?.attributes.BlogImage.data.attributes.url}/>
        </Helmet>
     <div className={styles.postweb}>
        <div className={styles.postwebInner}>
          <ToolBar/>
        </div>
        <div className={styles.blogGroup}>
          <div className={styles.blog1} onClick={onBLOGTextClick}>
            BLOG
          </div>
          <img
            className={styles.arrowrightIcon}
            alt=""
            src="/caretright1.svg"
          />
          <div
            className={styles.trendingArticles}
            onClick={onTrendingArticlesTextClick}
          >
            For Professionals
          </div>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.frameGroup}>
            <div className={styles.businessAdviceWrapper}>
              <div className={styles.businessAdvice}>{blog?.attributes.category}</div>
            </div>
            <div className={styles.minReadWrapper}>
              <div className={styles.minRead}>{blog?.attributes.duration} min read</div>
            </div>
          </div>
          <div className={styles.rifacebookBoxFillParent}>
            <img
              className={styles.rifacebookBoxFillIcon}
              alt=""
              src="/rifacebookboxfill.svg"
              onClick={handleShareFacebook}
            />
            <img
              className={styles.rifacebookBoxFillIcon}
              alt=""
              src="/ritwitterfill.svg"
              onClick={handleShareTwitter}
            />
            <img
              className={styles.rifacebookBoxFillIcon}
              alt=""
              src="/rilinkedinboxfill.svg"
              onClick={handleShareLinkedIn}
            />
            <img
              className={styles.rifacebookBoxFillIcon}
              alt=""
              src="/rilink.svg"
              onClick={handleCopyLink}
            />
          </div>
        </div>
        <div className={styles.howToPricingYourServicesAParent}>
          <b className={styles.howToPricingContainer}>
            <p className={styles.howToPricing}>
              {blog?.attributes.BlogTitle}
            </p>
            
          </b>
          <div className={styles.byChristinaWilliamsParent}>
            <div className={styles.byChristinaWilliams}>
              By {blog?.attributes.Author}
            </div>
            <div className={styles.frameInner} />
            <div className={styles.january18th2024}>{formatDateToWords(blog?.attributes.createdAt)}</div>
          </div>
        </div>
        <img
          className={styles.postwebChild}
          alt=""
          src={blog?.attributes.BlogImage.data.attributes.url}
        />
        <div className={styles.frameContainer}>
          <div className={styles.frameDiv}>
            <div className={styles.inTheBustlingWorldOfHomeWrapper}>
              <div className={styles.inTheBustling}>
               <div className="w-full max-w-full prose lg:prose-xl">
    <div dangerouslySetInnerHTML={{ __html: blog?.attributes.Content.replace(/<a /g, '<a style="color: green;" ') }} />
</div>

              </div>
            </div>
           
          </div>
        </div>
        <div className={styles.frameParent1}>
         <ProfBanner/>
          <div className={styles.subscribeForNewsletterParent}>
          <SubsBanner/>
           <PrototypeFooter/>
          </div>
        </div>
      </div>
      <div className={styles.postmob}>
      <div className={mobnav.navbarMobileclosed}>
          <img className={mobnav.layer2Icon} alt="" src="/layer-21.svg"
          style={{cursor:'pointer'}} onClick={()=>navigate('/')}  />
          <div className={mobnav.iconsaccountParent}>
            <div className={mobnav.iconsaccount} alt="" />
            <img className={mobnav.hamburgerIcon} alt="" src="/hamburger.svg" onClick={openMobileMenuProfProfessionalPopup} />
          </div>
        </div>
        <div className={styles.frameParent10}>
          <div className={styles.termsOfUseParent}>
            <div className={styles.blog3} onClick={onBLOGTextClick}>BLOG</div>
            <img
              className={styles.caretrightIcon1}
              alt=""
              src="/caretright2.svg"
            />
            <div className={styles.trendingArticles1}>For Professionals</div>
          </div>
          <div className={styles.frameParent11}>
            <div className={styles.frameParent12}>
              <div className={styles.businessAdviceContainer}>
                <div className={styles.businessAdvice1}>{blog?.attributes.category}</div>
              </div>
              <div className={styles.minsRead}>{blog?.attributes.duration} mins read</div>
            </div>
            <div className={styles.howToPricingYourServicesAGroup}>
              <div className={styles.howToPricing1}>
              {blog?.attributes.BlogTitle}
              </div>
              <div className={styles.christinaWilliamsParent}>
                <div className={styles.christinaWilliams}>
                  {blog?.attributes.Author}
                </div>
                <div className={styles.ellipseDiv} />
                <div className={styles.january18th20241}>
                {formatDateToWords(blog?.attributes.createdAt)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rifacebookBoxFillGroup}>
          <img
            className={styles.rifacebookBoxFillIcon1}
            alt=""
            src="/rifacebookboxfill.svg"
            onClick={handleShareFacebook}
          />
          <img
            className={styles.rifacebookBoxFillIcon1}
            alt=""
            src="/ritwitterfill.svg"
            onClick={handleShareTwitter}
          />
          <img
            className={styles.rifacebookBoxFillIcon1}
            alt=""
            src="/rilinkedinboxfill.svg"
            onClick={handleShareLinkedIn}
          />
          <img
            className={styles.rifacebookBoxFillIcon1}
            alt=""
            src="/rilink.svg"
            onClick={handleCopyLink}
          />
        </div>
        <img
          className={styles.postmobChild}
          alt=""
          src={blog?.attributes.BlogImage.data.attributes.url}
        />
        <div className={styles.frameParent13}>
          <div className={styles.frameParent14}>
            <div className={styles.whyPricingCanGetConfusingGroup}>
              <div className={styles.inTheBustling1}>
               <div className="w-full max-w-full prose lg:prose-xl">
    <div dangerouslySetInnerHTML={{ __html: blog?.attributes.Content.replace(/<a /g, '<a style="color: green;" ') }} />
</div>

              </div>
            </div>
          </div>
         
        </div>
       <ProfBanner/>
        <div className={styles.frameParent16}>
        <SubsBanner/>
          <MobileFooter/>
        </div>
      </div>
      {isMobileMenuProfProfessionalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuProfProfessionalPopup}
        >
          <MenuMobile
            onClose={closeMobileMenuProfProfessionalPopup}
          />
        </PortalPopup>
      )}
    </div>
  );
  }
  
};

export default Postview2;
