import { useState, useCallback } from "react";
import Dropdown4 from "../components/Dropdown4";
import PortalPopup from "../components/PortalPopup";
import Dropdown5 from "../components/Dropdown5";
import MobileMenuConsumer1 from "../components/MobileMenuConsumer1";
import { useNavigate } from "react-router-dom";
import styles from "./About.module.css";
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import MobileFooter from "../components/MobileFooter";
import LocationSearch from "../components/locationSearch.tsx";

const About = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(null);
  const open = Boolean(isDropdownOpen);
  const openDropdown = (event) => {
    setDropdownOpen(event.currentTarget);
  };
  const closeDropdown = () => {
    setDropdownOpen(null);
  };
  const [isDropdown1Open, setDropdown1Open] = useState(null);
  const open1 = Boolean(isDropdown1Open);
   const openDropdown1 = (event) => {
    setDropdown1Open(event.currentTarget);
  };
  const closeDropdown1 = () => {
    setDropdown1Open(null);
  };

  const [isMobileMenuConsumerPopupOpen, setMobileMenuConsumerPopupOpen] =
    useState(false);
  const navigate = useNavigate();

  const onPostAJobClick = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const onHowItWorksClick = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQsClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementTextClick = useCallback(() => {
    navigate("/agreement");
  }, [navigate]);

  const onHowItWorks1Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onContactUsText1Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQs1Click = useCallback(() => {
    navigate("/professional-faq");
  }, [navigate]);

  const onUserAgreementText1Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/becometeam");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/partners");
  }, [navigate]);

  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/affiliate-program");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onLargeButtons1Click = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const onPageSelectorContainerClick = useCallback(() => {
    navigate("/professional");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHowItWorks2Click = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onFrameButtonClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onSmallButtonsClick = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const openMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(true);
  }, []);

  const closeMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(false);
  }, []);

  return (
    <>
      <div className={styles.about}>
        <div className={styles.aboutUsdone}>
          <div className={styles.prototypeFooter}>
            <div className={styles.footer}>
              <div className={styles.download}>
                <div className={styles.downloadOurApps}>Download our apps</div>
                <img
                  className={styles.playstoreButtonIcon}
                  alt=""
                  src="/playstore-button8.svg"
                />
                <img
                  className={styles.appstoreButtonIcon}
                  alt=""
                  src="/appstore-button7.svg"
                />
              </div>
              <div className={styles.footerChild} />
              <img className={styles.layer2Icon} 
               style={{cursor:'pointer'}}
               onClick={()=>{navigate('/')}}
               alt="" src="/layer-271.svg" />
              <div className={styles.frameParent}>
                <div className={styles.homeownersParent}>
                  <b className={styles.homeowners}>Homeowners</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.postAJob} onClick={onPostAJobClick}>
                      Post a Job
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHowItWorksClick}
                    >
                      How it works
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onContactUsTextClick}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHelpFAQsClick}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.postAJob}
                      onClick={onUserAgreementTextClick}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.professionalsParent}>
                  <b className={styles.homeowners}>Professionals</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.signUp}
                    style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                    <div
                      className={styles.postAJob}
                      onClick={onHowItWorks1Click}
                    >
                      Blog
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onContactUsText1Click}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHelpFAQs1Click}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.postAJob}
                      onClick={onUserAgreementText1Click}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.loggworksParent}>
                  <b className={styles.homeowners}>Loggworks</b>
                  <div className={styles.postAJobParent}>
                    <div
                      className={styles.postAJob}
                      onClick={onAboutUsTextClick}
                    >
                      About us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onCareersTextClick}
                    >
                      Careers
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onPartnersTextClick}
                    >
                      Partners
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onInfluencerProgramTextClick}
                    >
                      Affiliate program
                    </div>
                    <div className={styles.blog}>Blog</div>
                  </div>
                </div>
                <div className={styles.loggworksGroup}>
                  <div className={styles.loggworks1}>
                    <b className={styles.followUs}>Follow us</b>
                  </div>
                  <div className={styles.groupParent}>
                    <div className={styles.frameGroup} 
                    style={{cursor:'pointer'}}
                    onClick={()=>window.open('https://web.facebook.com/Loggworks?_rdc=1&_rdr', '_blank')}>
                      <img
                        className={styles.frameIcon}
                        alt=""
                        src="/frame20.svg"
                      />
                      <div className={styles.facebook}>Facebook</div>
                    </div>
                    <div className={styles.frameContainer}
                    style={{cursor:'pointer'}}
                    onClick={()=>window.open('https://mobile.twitter.com/loggworks', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame37.svg"
                      />
                      <div className={styles.twitter}>Twitter</div>
                    </div>
                    <div className={styles.groupDiv}
                    style={{cursor:'pointer'}}
                    onClick={()=>window.open('https://www.instagram.com/loggworks/?utm_medium=copy_link', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame22.svg"
                      />
                      <div className={styles.twitter}>Instagram</div>
                    </div>
                    <div className={styles.frameParent1}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame23.svg"
                      />
                      <div className={styles.twitter}>YouTube</div>
                    </div>
                    <div className={styles.mdilinkedinParent}
                     style={{cursor:'pointer'}}
                     onClick={()=>window.open('https://www.linkedin.com/company/loggworks', '_blank')}
                   >
                      <img
                        className={styles.mdilinkedinIcon}
                        alt=""
                        src="/mdilinkedin1.svg"
                      />
                      <div className={styles.linkedin}>LinkedIn</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.copyright2022LoggworkLtdAParent}>
                <div className={styles.copyright2022Loggwork}>
                  ©Copyright 2024 loggworks Ltd. All Rights Reserved
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/terms')}}>
                      Terms of Use
                    </div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div
                      className={styles.copyright2022Loggwork}
                      style={{cursor:'pointer'}}
                      onClick={()=>{navigate('/privacy')}}
                    >{`Privacy Policy `}</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/cookie')}}>Cookies</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.contactUsParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Contact Us
                    </div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork} style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href='https://www.loggworks.co.uk/sitemap.xml'
                    }}>Sitemap</div>
                    <div className={styles.frameChild1} />
                  </div>
                </div>
              </div>
              <div className={styles.largeButtonsParent}>
                <button
                  className={styles.largeButtons}
                  style={{cursor:'pointer'}}
                  onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder38.svg"
                  />
                  <b className={styles.label}>Become a Professional</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder38.svg"
                  />
                </button>
                <button
                  className={styles.largeButtons}
                  onClick={onLargeButtons1Click}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder39.svg"
                  />
                  <b className={styles.label}>Post a Job</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder39.svg"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className={styles.frameParent2}>
            <div className={styles.frameWrapper}>
              <div className={styles.helpingHomeownersFindTrusteParent}>
                <b className={styles.helpingHomeownersFind}>
                  Helping Homeowners Find Trusted Pros.
                </b>
                <div className={styles.weKnowThat}>
                  We know that finding reliable, professionals for home repairs,
                  and maintenance can be a daunting task. That's why we've
                  created a platform that helps you find the best professionals
                  in your area.
                </div>
              </div>
            </div>
            <img
              className={styles.rectangleIcon}
              alt=""
              src="/rectangle-11964@2x.png"
            />
            <div className={styles.featuredOn}>
              <div className={styles.featuredOnParent}>
                <div className={styles.featuredOn1}>PARTNERS:</div>
                <div className={styles.theTelegraphLogotypeLogo1Parent}>
                  <img
                    className={styles.featureslogo}
                    alt=""
                    src="/mangopay_powered-4311aa3e.svg"
                  />
                  <img
                    className={styles.featureslogo}
                    style={{width:'200px'}}
                    alt=""
                    src="/Google-Cloud-Logo.png"
                  />
                
                </div>
              </div>
            </div>
            <div className={styles.frameWrapper1}>
              <div className={styles.ourMissionParent}>
                <b className={styles.ourMission}>Our Mission.</b>
                <div className={styles.weAreA}>
                  We are a company that connects home service professionals to
                  home service consumers. Our mission is to make it easy for
                  homeowners to find the right professionals for their home
                  needs, and for professionals to find new clients and grow
                  their businesses.
                </div>
              </div>
            </div>
            <div className={styles.reasonsWrapper}>
              <div className={styles.reasons}>
                <div className={styles.whyUs}>
                  <div className={styles.privacyParent}>
                    <img
                      className={styles.privacyIcon}
                      alt=""
                      src="/jobscomp.svg"
                    />
                    <div className={styles.privacyProtectionParent}>
                      <b className={styles.homeowners}>50K+ Jobs Completed</b>
                      <div className={styles.youllNeverHave}>
                       
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.whyUs1}>
                  <div className={styles.privacyGroup}>
                    <img
                      className={styles.privacyIcon}
                      alt=""
                      src="/profAb.svg"
                    />
                    <div className={styles.privacyProtectionParent}>
                      <b className={styles.homeowners}>
                        1000+ Active Professionals
                      </b>
                      <div className={styles.youllNeverHave}>
                       
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.whyUs1}>
                  <div className={styles.privacyGroup}>
                    <img
                      className={styles.privacyIcon}
                      alt=""
                      src="/starab.svg"
                    />
                    <div className={styles.privacyProtectionParent}>
                      <b className={styles.homeowners}>5 Star Trust Ratings</b>
                      <div className={styles.youllNeverHave}>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameWrapper2}>
              <div className={styles.valuesWeHoldDearParent}>
                <b className={styles.valuesWeHold}>Values we hold dear.</b>
                <div className={styles.frameParent3}>
                  <div className={styles.frameParent4}>
                    <div className={styles.frameWrapper3}>
                      <div className={styles.privacyParent1}>
                        <img
                          className={styles.privacyIcon}
                          alt=""
                          src="/ab_privacy.svg"
                        />
                        <div className={styles.customerSatisfactionParent}>
                          <b className={styles.homeowners}>
                            Customer satisfaction
                          </b>
                          <div className={styles.youllNeverHave}>
                            We pride in providing excellent service to customers
                            and ensuring that their needs are met.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.frameWrapper3}>
                      <div className={styles.privacyParent1}>
                        <img
                          className={styles.privacyIcon}
                          alt=""
                          src="/ab_privacy.svg"
                        />
                        <div className={styles.customerSatisfactionParent}>
                          <b className={styles.homeowners}>Professionalism</b>
                          <div className={styles.youllNeverHave}>
                            We maintain high standards of quality and
                            professionalism in all aspects of our business
                            dealings.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.frameParent4}>
                    <div className={styles.frameWrapper3}>
                      <div className={styles.privacyParent1}>
                        <img
                          className={styles.privacyIcon}
                          alt=""
                          src="/ab_privacy.svg"
                        />
                        <div className={styles.customerSatisfactionParent}>
                          <b className={styles.homeowners}>Teamwork</b>
                          <div className={styles.youllNeverHave}>
                            We are working together with others towards a common
                            goal and supporting one another in the process.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.frameWrapper3}>
                      <div className={styles.privacyParent1}>
                        <img
                          className={styles.privacyIcon}
                          alt=""
                          src="/ab_privacy.svg"
                        />
                        <div className={styles.customerSatisfactionParent}>
                          <b className={styles.homeowners}>
                            Creativity and innovation
                          </b>
                          <div className={styles.youllNeverHave}>
                            At Loggworks we are open to new ideas and constantly
                            seeking ways to improve and evolve the business.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.mergeParent}>
              <div className={styles.merge}>
                <div className={styles.ttileSubtext}>
                  <b className={styles.joinOurGrowing}>
                    Join our growing team.
                  </b>
                  <div className={styles.weAreAlways}>
                    We are always on the lookout for talented and driven
                    individuals to join our team. We believe that our employees
                    are the driving force behind our success, and we are
                    committed to providing them with the support and resources
                    they need to thrive.
                  </div>
                </div>
                <button className={styles.largeButtons2}>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder34.svg"
                  />
                  <b className={styles.label}>View Current Openings</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder34.svg"
                  />
                </button>
              </div>
              <img className={styles.icon} alt="" src="/23.svg" />
              <img className={styles.icon1} alt="" src="/40.svg" />
            </div>
          </div>
          <nav className={styles.navigation}>
            <div className={styles.navigation1}>
              <img
                className={styles.navigationChild}
                alt=""
                src="/frame-665223.svg"
              />
              <div className={styles.navigationItem} />
              <div className={styles.tabSwitch}>
                <div
                  className={styles.pageSelector}
                  onClick={onPageSelectorContainerClick}
                >
                  <div className={styles.descriptionParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Professional
                    </div>
                    <div className={styles.selector} />
                  </div>
                </div>
                <div className={styles.pageSelector1}>
                  <div className={styles.descriptionParent}>
                    <b className={styles.description1}>Consumer</b>
                    <div className={styles.selector1} />
                  </div>
                </div>
              </div>
              
              <LocationSearch/>
            </div>
            <div className={styles.navigation2}>
              <div className={styles.homeParent}>
                <div className={styles.home} onClick={onHomeTextClick}>
                  Home
                </div>
                <div className={styles.home} onClick={onHowItWorks2Click}>
                  How it works
                </div>
                <div className={styles.services} 
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={openDropdown}>
                  <div className={styles.services1}>Services</div>
                  <img
                    className={styles.iconssearch}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={isDropdownOpen}
        open={open}
        onClose={closeDropdown}
        TransitionComponent={Fade}
      >
      <Dropdown4/>
      </Menu>
                <div className={styles.services} 
                id="fade-button2"
 aria-controls={open1 ? 'fade-menu2' : undefined}
 aria-haspopup="true"
 aria-expanded={open1 ? 'true' : undefined}
                onClick={openDropdown1}>
                  <div className={styles.services1}>Resources</div>
                  <img
                    className={styles.iconssearch}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                <Menu
        id="fade-menu2"
        MenuListProps={{
          'aria-labelledby': 'fade-button2',
        }}
        anchorEl={isDropdown1Open}
        open={open1}
        onClose={closeDropdown1}
        TransitionComponent={Fade}
      >
      <Dropdown5/>
      </Menu>
                <div className={styles.pricing}>Pricing</div>
              </div>
              <div className={styles.frameParent6}>
                <div className={styles.frameParent7}>
                  <button
                    className={styles.iconshelpParent}
                    onClick={onFrameButtonClick}
                  >
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/iconshelp.svg"
                    />
                    <div className={styles.help}>Help</div>
                  </button>
                  <button className={styles.iconshelpParent}>
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/iconsaccount11.svg"
                    />
                    <div className={styles.help}>Account</div>
                  </button>
                </div>
                <button
                  className={styles.smallButtons}
                  onClick={onSmallButtonsClick}
                >
                  <div className={styles.iconsaddParent}>
                    <img
                      className={styles.iconsadd}
                      alt=""
                      src="/iconsadd15.svg"
                    />
                    <div className={styles.button}>Post a job</div>
                  </div>
                </button>
              </div>
            </div>
          </nav>
        </div>
        <div className={styles.homeMobile}>
          <div className={styles.navbarMobileclosed}>
            <img className={styles.layer2Icon1} alt="" src="/layer-28.svg" 
            style={{cursor:'pointer'}} onClick={()=>navigate('/')} />
            <div className={styles.iconsaccountGroup}>
              <img
                className={styles.iconsaccount1}
                alt=""
                src="/iconsaccount6.svg"
              />
              <img
                className={styles.hamburgerIcon}
                alt=""
                src="/hamburger.svg"
                onClick={openMobileMenuConsumerPopup}
              />
            </div>
          </div>
          <div className={styles.frameParent8}>
            <div className={styles.frameWrapper7}>
              <div className={styles.helpingHomeownersFindTrusteGroup}>
                <b className={styles.helpingHomeownersFind1}>
                  Helping Homeowners Find Trusted Pros.
                </b>
                <div className={styles.weKnowThat1}>
                  We know that finding reliable, professionals for home repairs,
                  and maintenance can be a daunting task. That's why we've
                  created a platform that helps you find the best professionals
                  in your area.
                </div>
              </div>
            </div>
            <img
              className={styles.frameChild2}
              alt=""
              src="/rectangle-119641@2x.png"
            />
            <div className={styles.frameWrapper8}>
              <div className={styles.featuredOnGroup}>
                <div className={styles.signUp}>PARTNERS:</div>
                <div className={styles.theTelegraphLogotypeLogo1Group}>
                <img
                    className={styles.mangologo}
                    alt=""
                    src="/mangopay_powered-4311aa3e.svg"
                  />
                  <img
                    className={styles.mangologo}
                    alt=""
                    src="/Google-Cloud-Logo.png"
                  />
                 
                </div>
              </div>
            </div>
            <div className={styles.frameWrapper9}>
              <div className={styles.ourMissionGroup}>
                <b className={styles.ourMission1}>Our Mission.</b>
                <div className={styles.weKnowThat1}>
                  We are a company that connects home service professionals to
                  home service consumers. Our mission is to make it easy for
                  homeowners to find the right professionals for their home
                  needs, and for professionals to find new clients and grow
                  their businesses.
                </div>
              </div>
            </div>
            <div className={styles.reasonsContainer}>
              <div className={styles.reasons1}>
                <div className={styles.whyUs3}>
                  <div className={styles.privacyParent5}>
                    <img
                      className={styles.privacyIcon}
                      alt=""
                      src="/jobscomp.svg"
                    />
                    <div className={styles.privacyProtectionParent1}>
                      <b className={styles.homeowners}>50K+ Jobs Completed</b>
                      <div className={styles.youllNeverHave}>
                       
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.whyUs4}>
                  <div className={styles.privacyParent6}>
                    <img
                      className={styles.privacyIcon}
                      alt=""
                      src="/profAb.svg"
                    />
                    <div className={styles.privacyProtectionParent1}>
                      <b className={styles.homeowners}>
                        1000+ Active Professionals
                      </b>
                      <div className={styles.youllNeverHave}>
                       
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.whyUs4}>
                  <div className={styles.privacyParent6}>
                    <img
                      className={styles.privacyIcon}
                      alt=""
                      src="/starab.svg"
                    />
                    <div className={styles.privacyProtectionParent1}>
                      <b className={styles.homeowners}>5 Star Trust Ratings</b>
                      <div className={styles.youllNeverHave}>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameWrapper10}>
              <div className={styles.privacyGroup}>
                <b className={styles.ourMission1}>Values we hold dear.</b>
                <div className={styles.frameParent9}>
                  <div className={styles.ourMissionGroup}>
                    <div className={styles.frameWrapper11}>
                      <div className={styles.privacyParent8}>
                        <img
                          className={styles.privacyIcon}
                          alt=""
                          src="/ab_privacy.svg"
                        />
                        <div className={styles.customerSatisfactionParent}>
                          <b className={styles.homeowners}>
                            Customer satisfaction
                          </b>
                          <div className={styles.youllNeverHave}>
                            We pride in providing excellent service to customers
                            and ensuring that their needs are met.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.frameWrapper11}>
                      <div className={styles.privacyParent8}>
                        <img
                          className={styles.privacyIcon}
                          alt=""
                          src="/ab_privacy.svg"
                        />
                        <div className={styles.customerSatisfactionParent}>
                          <b className={styles.homeowners}>Professionalism</b>
                          <div className={styles.youllNeverHave}>
                            We maintain high standards of quality and
                            professionalism in all aspects of our business
                            dealings.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.ourMissionGroup}>
                    <div className={styles.frameWrapper11}>
                      <div className={styles.privacyParent8}>
                        <img
                          className={styles.privacyIcon}
                          alt=""
                          src="/ab_privacy.svg"
                        />
                        <div className={styles.customerSatisfactionParent}>
                          <b className={styles.homeowners}>Teamwork</b>
                          <div className={styles.youllNeverHave}>
                            We are working together with others towards a common
                            goal and supporting one another in the process.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.frameWrapper11}>
                      <div className={styles.privacyParent8}>
                        <img
                          className={styles.privacyIcon}
                          alt=""
                          src="/ab_privacy.svg"
                        />
                        <div className={styles.customerSatisfactionParent}>
                          <b className={styles.homeowners}>
                            Creativity and innovation
                          </b>
                          <div className={styles.youllNeverHave}>
                            At Loggworks we are open to new ideas and constantly
                            seeking ways to improve and evolve the business.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.mergeGroup}>
              <div className={styles.merge1}>
                <div className={styles.privacyParent6}>
                  <b className={styles.ourMission1}>Join our growing team.</b>
                  <div className={styles.weAreAlways1}>
                    We are always on the lookout for talented and driven
                    individuals to join our team. We believe that our employees
                    are the driving force behind our success, and we are
                    committed to providing them with the support and resources
                    they need to thrive.
                  </div>
                </div>
                <div className={styles.largeButtons3}>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder43.svg"
                  />
                  <b className={styles.label3}>View Current Openings</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder43.svg"
                  />
                </div>
              </div>
              <img className={styles.icon2} alt="" src="/231.svg" />
              <img className={styles.icon3} alt="" src="/401.svg" />
            </div>
            <MobileFooter/>
          </div>
        </div>
      </div>
      {isMobileMenuConsumerPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuConsumerPopup}
        >
          <MobileMenuConsumer1 onClose={closeMobileMenuConsumerPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default About;
