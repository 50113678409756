import { useState, useCallback } from "react";
import Dropdown from "./Dropdown";
import PortalPopup from "./PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./ProfessionalNavigation1.module.css";
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';

const ProfessionalNavigation1 = ({faq}) => {
 const [isDropdownOpen, setDropdownOpen] = useState(null);
  const open = Boolean(isDropdownOpen);
  const openDropdown = (event) => {
    setDropdownOpen(event.currentTarget);
  };
  const closeDropdown = () => {
    setDropdownOpen(null);
  };
  const navigate = useNavigate();

  const onPageSelectorContainer1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/professional");
  }, [navigate]);

  const onServicesContainerClick = useCallback(() => {
    navigate("/category");
  }, [navigate]);

  const onPricingTextClick = useCallback(() => {
    navigate("/priceplan");
  }, [navigate]);

  const onFrameContainer5Click = useCallback(() => {
    navigate("/professional-faq");
  }, [navigate]);

  return (
    <>
      <div className={styles.professionalNavigation}>
        <div className={styles.navigation}>
          <img
            className={styles.navigationChild}
            alt=""
            src="/frame-665221.svg"
          />
          <div className={styles.navigationItem} />
          <div className={styles.tabSwitch}>
            <div className={styles.pageSelector}>
              <div className={styles.descriptionParent}>
                <b className={styles.description}>Professional</b>
                <div className={styles.selector} />
              </div>
            </div>
            <div
              className={styles.pageSelector1}
              onClick={onPageSelectorContainer1Click}
            >
              <div className={styles.descriptionParent}>
                <div className={styles.description1}>Consumer</div>
                <div className={styles.selector1} />
              </div>
            </div>
          </div>
          <div className={styles.searchBar}>
            <div className={styles.iconssearchWrapper}>
              <img
                className={styles.iconssearch}
                alt=""
                src="/iconssearch.svg"
              />
            </div>
            <div className={styles.electrician}>Electrician</div>
            <div className={styles.dividerParent}>
              <div className={styles.divider} />
              <div className={styles.location}>
                <img
                  className={styles.iconslocation}
                  alt=""
                  src="/iconslocation.svg"
                />
                <div className={styles.locationOrPostcode}>
                  Location or postcode
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.navigation1}>
          <div className={styles.homeParent}>
            <div className={styles.home} style={!faq?{color:'#2fa500'}:{}} onClick={onHomeTextClick}>
              Home
            </div>
            <div className={styles.howItWorks}>How it works</div>
            <div className={styles.services} onClick={onServicesContainerClick}>
              <div className={styles.services1}>Categories</div>
              <img
                className={styles.iconschevronDown}
                alt=""
                src="/iconschevrondown1.svg"
              />
            </div>
            <div className={styles.services} 
            id="fade-button"
  aria-controls={open ? 'fade-menu' : undefined}
  aria-haspopup="true"
  aria-expanded={open ? 'true' : undefined}
            onClick={openDropdown}>
              <div className={styles.services1}>Resources</div>
              <img
                className={styles.iconschevronDown}
                alt=""
                src="/iconschevrondown1.svg"
              />
            </div>
            <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={isDropdownOpen}
        open={open}
        onClose={closeDropdown}
        TransitionComponent={Fade}
      >
      <Dropdown/>
      </Menu>
            <div className={styles.pricing} onClick={onPricingTextClick}>
              Pricing
            </div>
          </div>
          <div className={styles.frameParent}>
            <div className={styles.frameGroup}>
              <div
                className={styles.iconshelpParent}
                onClick={onFrameContainer5Click}
              >
                {
                !faq?
                <img
                  className={styles.iconssearch}
                  alt=""
                  src="/iconshelp.svg"
                />
                :
                <img
                  className={styles.iconssearch}
                  alt=""
                  src="/iconshelp1.svg"
                />
              }
                <div className={styles.help} style={faq?{color:'#2fa500'}:{}}>Help</div>
              </div>
              <div className={styles.iconsaccountParent} style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/', '_blank')}>
                <img
                  className={styles.iconssearch}
                  alt=""
                  src="/iconsaccount2.svg"
                />
                <div className={styles.help}>Account</div>
              </div>
            </div>
            <div className={styles.smallButtons} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
              <div className={styles.buttonWrapper}>
                <div className={styles.button}>Become a professional</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default ProfessionalNavigation1;
