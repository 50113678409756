import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import BecomeAprofessionalArticle from "./pages/BecomeAprofessionalArticle";
import ConsumerHome from "./pages/ConsumerHome";
import WeAchieve5 from "./pages/WeAchieve5";
import WeAchieve4 from "./pages/WeAchieve4";
import EarnMoney from "./pages/EarnMoney";
import WeAchieve3 from "./pages/WeAchieve3";
import WeAchieve2 from "./pages/WeAchieve2";
import WeAchieve from "./pages/WeAchieve";
import BecomeTeam from "./pages/BecomeTeam";
import About from "./pages/About";
import Agreement from "./pages/Agreement";
import ProfessionalHiringGuideArti from "./pages/ProfessionalHiringGuideArti";
import ChildcareGuid from "./pages/ChildcareGuid";
import ProfessionalHiringGuide from "./pages/ProfessionalHiringGuide";
import SearchResult from "./pages/SearchResult";
import Blogview from "./pages/Blogview";
import ContactUs from "./pages/ContactUs";
import FAQ1 from "./pages/FAQ1";
import HowWeWork from "./pages/HowWeWork";
import PricePlan from "./pages/PricePlan";
import AboutUs from "./pages/AboutUs";
import ProfessionalHiringGuideArti1 from "./pages/ProfessionalHiringGuideArti1";
import Post4 from "./pages/Post4";
import Post3 from "./pages/Post3";
import Post2 from "./pages/Post2";
import Post1 from "./pages/Post1";
import ConsumerContact from "./pages/ConsumerContact";
import ConsumerFAQ from "./pages/ConsumerFAQ";
import CAREERS from "./pages/CAREERS";
import Search from "./pages/Search";
import { useEffect } from "react";
import './tailcss.css'
import FAQ2 from "./pages/FAQ2";
import ContactUs2 from "./pages/ContactUs2";
import TermandCond from "./pages/TermandCond";
import Privacy from "./pages/Privacy";
import Cookie from "./pages/Cookie";
import ProfFinding from "./pages/ProfFinding";
import CustFinding from "./pages/CustFinding";
import Homeblog from "./pages/blogs/Homeblog";
import ForConsumers from "./pages/blogs/ForConsumers";
import ForProfessional from "./pages/blogs/ForProfessional";
import News from "./pages/blogs/News";
import Trendingpage from "./pages/blogs/Trendingpage";
import Postview from "./pages/blogs/Postview";
import PricingGuide1 from "./pages/blogs/PricingGuide1";
import HiringGuides from "./pages/blogs/HiringGuides";
import HiringSelect from "./pages/blogs/HiringSelect";
import Postview2 from "./pages/blogs/Postview2";
import Postview4 from "./pages/blogs/Postview4";
import Postview3 from "./pages/blogs/Postview3";
import Tips_c from "./pages/blogs/Tips_c";
import Tips_p from "./pages/blogs/Tips_p";
import HowTo_c from "./pages/blogs/HowTo_c";
import HowTo_p from "./pages/blogs/HowTo_p";
import Business from "./pages/blogs/Business";
import Managing from "./pages/blogs/Managing";
import Finance from "./pages/blogs/Finance";
import Spotlight from "./pages/blogs/Spotlight";
import Product from "./pages/blogs/Product";
import Partner from "./pages/blogs/Partner";
import Industry from "./pages/blogs/Industry";
import Loggworks from "./pages/blogs/Loggworks";
import Postview5 from "./pages/blogs/Postview5";
import PricingSelect from "./pages/blogs/PricingSelect";
import NotFoundPage from "./components/NotFoundPage";
import { HelmetProvider } from 'react-helmet-async';


function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);


  return (
    <HelmetProvider>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="/category"
        element={<BecomeAprofessionalArticle />}
      />
      <Route path="/professional" element={<ConsumerHome />} />
      <Route path="/training" element={<WeAchieve5 />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/ambassadors" element={<WeAchieve4 />} />
      <Route path="/affiliate-program" element={<EarnMoney />} />
      <Route path="/retailers" element={<WeAchieve3 />} />
      <Route path="/trade-associations" element={<WeAchieve2 />} />
      <Route path="/partners" element={<WeAchieve />} />
      <Route path="/becometeam" element={<BecomeTeam />} />
      <Route path="/about" element={<About />} />
      <Route path="/agreement" element={<Agreement />} />
      <Route path="/cookie" element={<Cookie />} />
      <Route path="/professionals/:prof/:loc" element={<ProfFinding />} />
      <Route path="/consumers/:prof/:loc" element={<CustFinding />} />
      <Route
        path="/professionalhiring-guidearticledone2"
        element={<ProfessionalHiringGuideArti />}
      />
      <Route path="/childcareguid1" element={<ChildcareGuid />} />
      <Route
        path="/blog/for-consumers/hiring-guide"
        element={<HiringGuides />}
      />
      <Route
        path="/blog/for-consumers/pricing-guides"
        element={<PricingGuide1 />}
      />
      <Route
        path="/blog/for-consumers/pricing-guides/view/:id"
        element={<Postview5 />}
      />
      <Route
        path="/blog/for-consumers/hiring-guide/:id"
        element={<HiringSelect />}
      />
      <Route
        path="/blog/for-consumers/pricing-guides/:id"
        element={<PricingSelect />}
      />
      <Route path="/search/:postcode/:category/:subcate" element={<SearchResult />} />
      <Route path="/blogview" element={<Blogview />} />
      <Route path="/terms" element={<TermandCond />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/blog" element={<Homeblog />} />
      <Route path="/blog/trending" element={<Trendingpage />} />
      
      <Route path="/blog/for-consumers/tips" element={<Tips_c />} />
      <Route path="/blog/for-consumers/how-to" element={<HowTo_c />} />
      <Route path="/blog/for-professionals/tips" element={<Tips_p />} />
      <Route path="/blog/for-professionals/how-to" element={<HowTo_p />} />
      <Route path="/blog/for-professionals/business-advice" element={<Business />} />
      <Route path="/blog/for-professionals/managing-your-business" element={<Managing />} />
      <Route path="/blog/for-professionals/finance-advice" element={<Finance />} />
      <Route path="/blog/for-professionals/spotlight" element={<Spotlight />} />
      <Route path="/blog/for-consumers/:id" element={<Postview />} />
      <Route path="/blog/for-professionals/:id" element={<Postview2 />} />
       <Route path="/blog/trending/:id" element={<Postview3 />} />
      <Route path="/blog/news/:id" element={<Postview4 />} />
      <Route path="/blog/news/product-updates" element={<Product />} />
      <Route path="/blog/news/partners" element={<Partner />} />
      <Route path="/blog/news/industry-news" element={<Industry />} />
      <Route path="/blog/news/loggworks-in-the-news" element={<Loggworks />} />
     
      <Route path="/blog/for-consumers" element={<ForConsumers />} />
      <Route path="/blog/for-professionals" element={<ForProfessional />} />
      <Route path="/blog/news" element={<News />} />
      <Route path="/category/:id" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/professional-contact-us" element={<ContactUs2 />} />
      <Route path="/faq" element={<FAQ1 />} />
      <Route path="/professional-faq" element={<FAQ2 />} />
      <Route path="/how-we-work" element={<HowWeWork />} />
      <Route path="/priceplan" element={<PricePlan />} />
      <Route path="/about-us" element={<About />} />
      <Route
        path="/professionalhiring-guidearticledone1"
        element={<ProfessionalHiringGuideArti1 />}
      />
      <Route path="/post4" element={<Post4 />} />
      <Route path="/post3" element={<Post3 />} />
      <Route path="/post2" element={<Post2 />} />
      <Route path="/postjob" element={<Post1 />} />
      <Route path="/consumercontact" element={<ConsumerContact />} />
      <Route path="/consumerfaq" element={<ConsumerFAQ />} />
      <Route path="/careers" element={<CAREERS />} />
    </Routes>
    </HelmetProvider>
    
  );
}
export default App;
