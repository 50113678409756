import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Dropdown4.module.css";
const Dropdown4 = ({ onClose }) => {
  const navigate = useNavigate();
  const [address, setAddress] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    //getCurrentLocation();
  }, []);
const getCurrentLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;

        // Replace 'YOUR_GOOGLE_API_KEY' with your actual Google API key
        const apiKey = 'AIzaSyDrIc0MDLHaoFOaSLFKPRcGG-bM3Xpt-w0';
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

        try {
          const response = await fetch(apiUrl);
          const data = await response.json();

          if (data.status === 'OK' && data.results && data.results.length > 0) {
            const formattedAddress = data.results[0].formatted_address;
            setAddress(formattedAddress);
          } else {
            setError('Address not found');
          }
        } catch (error) {
          setError('Error fetching address');
        }
      },
      (error) => {
        setError(`Geolocation error: ${error.message}`);
      }
    );
  } else {
    setError('Geolocation is not supported by your browser');
  }
};

  const onWebsiteDropdownContainer1Click = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  return (
    <div className={styles.dropdown}>
      <img className={styles.dropdownChild} alt="" src="/vector-2.svg" />
      <div className={styles.container}>
       {/* <div className={styles.websiteDropdown}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge} 
              style={{cursor:'pointer'}}
              onClick={()=>{
             
                navigate('/category/catering/');
              }}>
                <div className={styles.menu}>Catering</div>
                <div className={styles.whatIsA}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder11.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div
          className={styles.websiteDropdown1}
        >
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}
              style={{cursor:'pointer'}}
              onClick={()=>{
         
                navigate('/category/childcare');
              }}>
                <div className={styles.menu}>Childcare</div>
                <div className={styles.whatIsA}>What is a pricing guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder11.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>*/}
        <div className={styles.websiteDropdown}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}
              style={{cursor:'pointer'}}
              onClick={()=>{
            
                navigate('/category/cleaning');
              }}>
                <div className={styles.menu}>Cleaning</div>
                <div className={styles.whatIsA}>What is a blog?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder11.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
       {/* <div className={styles.websiteDropdown}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}
              style={{cursor:'pointer'}}
              onClick={()=>{
              
                navigate('/category/entertainment');
              }}>
                <div className={styles.menu}>Entertainment</div>
                <div
                  className={styles.whatIsA}
                >{`What is training & academy?`}</div>
              </div>
              <div className={styles.professionalPill3}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder11.svg"
                  />
                  <div className={styles.childcare3}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}
              style={{cursor:'pointer'}}
              onClick={()=>{
            
                navigate('/category/appliance-repair');
              }}>
                <div className={styles.menu}>Appliance Repair</div>
                <div className={styles.whatIsA}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder11.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown5}>
          <div className={styles.container1}>
            <div className={styles.content5}>
              <div className={styles.merge}
              style={{cursor:'pointer'}}
              onClick={()=>{
            
                navigate('/category/door-supervisor');
              }}>
                <div className={styles.menu}>Door Supervisor</div>
                <div className={styles.whatIsA}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder11.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight5}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}
              style={{cursor:'pointer'}}
              onClick={()=>{
            
                navigate('/category/mobile-mechanic');
              }}>
                <div className={styles.menu}>Mobile Mechanic</div>
                <div className={styles.whatIsA}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder11.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}
              style={{cursor:'pointer'}}
              onClick={()=>{
             
                navigate('/category/personal-Assistance');
              }}>
                <div className={styles.menu}>Personal Assistance</div>
                <div className={styles.whatIsA}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder11.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}
              style={{cursor:'pointer'}}
              onClick={()=>{
            
                navigate('/category/pet-groomers');
              }}>
                <div className={styles.menu}>Pet Groomers</div>
                <div className={styles.whatIsA}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder11.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}
              style={{cursor:'pointer'}}
              onClick={()=>{
            
                navigate('/category/recovery-services');
              }}>
                <div className={styles.menu}>Recovery Services</div>
                <div className={styles.whatIsA}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder11.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}
              style={{cursor:'pointer'}}
              onClick={()=>{
             
                navigate('/category/removal-services');
              }}>
                <div className={styles.menu}>Removal Services</div>
                <div className={styles.whatIsA}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder11.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>*/}
        <div className={styles.websiteDropdown}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}
              style={{cursor:'pointer'}}
              onClick={()=>{
             
                navigate('/category/tradesmen');
              }}>
                <div className={styles.menu}>Trademen</div>
                <div className={styles.whatIsA}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder11.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
       {/* <div className={styles.websiteDropdown}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}
              style={{cursor:'pointer'}}
              onClick={()=>{
             
                navigate('/category/transport-services');
              }}>
                <div className={styles.menu}>Transport Services</div>
                <div className={styles.whatIsA}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder11.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}
              style={{cursor:'pointer'}}
              onClick={()=>{
            
                navigate('/category/computer-&-phone-repair');
              }}>
                <div className={styles.menu}>{`Computer & Phone Repair`}</div>
                <div className={styles.whatIsA}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder11.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}
              style={{cursor:'pointer'}}
              onClick={()=>{
          
                navigate('/category/dry-cleaning-&-laundry');
              }}>
                <div className={styles.menu}>{`Dry Cleaning & Laundry`}</div>
                <div className={styles.whatIsA}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder11.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}
              style={{cursor:'pointer'}}
              onClick={()=>{
        
                navigate('/category/health,-wellness-&-beauty');
              }}>
                <div className={styles.menu}>{`Health, Wellness & Beauty`}</div>
                <div className={styles.whatIsA}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder11.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.websiteDropdown}>
          <div className={styles.container1}>
            <div className={styles.content}>
              <div className={styles.merge}
              style={{cursor:'pointer'}}
              onClick={()=>{
                if (error) {
                  getCurrentLocation()
                  return
                }
                navigate('/category/tuition-&-classes');
              }}>
                <div className={styles.menu}>{`Tuition & Classes`}</div>
                <div className={styles.whatIsA}>What is a hiring guide?</div>
              </div>
              <div className={styles.professionalPill}>
                <div className={styles.icons16pxplaceholderParent}>
                  <img
                    className={styles.icons16pxplaceholder}
                    alt=""
                    src="/icons16pxplaceholder11.svg"
                  />
                  <div className={styles.childcare}>Coming Soon</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconschevronRight}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>*/}
      
      </div>
    </div>
  );
};

export default Dropdown4;
