import { useNavigate } from "react-router-dom";
import styles from "./MenuProfessional.module.css";

const MenuProfessional = () => {
  const navigate = useNavigate()
  return (
    <div className={styles.menuprofessional}>
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div className={styles.businessAdviceParent}
          style={{cursor:'pointer'}}
          onClick={()=>navigate('/blog/for-professionals/business-advice')}>
            <b className={styles.businessAdvice}>Business advice</b>
            <div className={styles.yourGoToSource}>
              Your go-to source for expert business advice and strategies.
            </div>
          </div>
          <div className={styles.businessAdviceParent}
          style={{cursor:'pointer'}}
          onClick={()=>navigate('/blog/for-professionals/managing-your-business')}>
            <b className={styles.businessAdvice}>managing your business</b>
            <div className={styles.yourGoToSource}>
              Explore navigating the intricacies of managing your business.
            </div>
          </div>
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.businessAdviceParent}
          style={{cursor:'pointer'}}
          onClick={()=>navigate('/blog/for-professionals/finance-advice')}>
            <b className={styles.businessAdvice}>finance advice</b>
            <div className={styles.yourGoToSource}>
              Gain expert insights on financial management and success
            </div>
          </div>
          <div className={styles.businessAdviceParent}
          style={{cursor:'pointer'}}
          onClick={()=>navigate('/blog/for-professionals/spotlight')}>
            <b className={styles.businessAdvice}>spotlight</b>
            <div className={styles.yourGoToSource}>
              Introspect into the experiences of professionals on Loggworks
            </div>
          </div>
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.businessAdviceParent}
          style={{cursor:'pointer'}}
          onClick={()=>navigate('/blog/for-professionals/tips')}>
            <b className={styles.businessAdvice}>Tips</b>
            <div className={styles.yourGoToSource}>
              Find valuable insights on how make the most out of Loggworks.
            </div>
          </div>
          <div className={styles.businessAdviceParent}
          style={{cursor:'pointer'}}
          onClick={()=>navigate('/blog/for-professionals/how-to')}>
            <b className={styles.businessAdvice}>How To</b>
            <div className={styles.yourGoToSource}>
              Get access to instructional content, guides, and tutorials.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuProfessional;
