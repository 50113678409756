import { useState, useCallback } from "react";
import Dropdown4 from "../components/Dropdown4";
import PortalPopup from "../components/PortalPopup";
import Dropdown5 from "../components/Dropdown5";
import MobileMenuConsumer1 from "../components/MobileMenuConsumer1";
import { useNavigate } from "react-router-dom";
import styles from "./FAQ1.module.css";
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import MobileFooter from "../components/MobileFooter";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationSearch from "../components/locationSearch.tsx";
import PrototypeFooter from "../components/PrototypeFooter";
const FAQ1 = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(null);
  const open = Boolean(isDropdownOpen);
  const openDropdown = (event) => {
    setDropdownOpen(event.currentTarget);
  };
  const closeDropdown = () => {
    setDropdownOpen(null);
  };
  const [isDropdown1Open, setDropdown1Open] = useState(null);
  const open1 = Boolean(isDropdown1Open);
   const openDropdown1 = (event) => {
    setDropdown1Open(event.currentTarget);
  };
  const closeDropdown1 = () => {
    setDropdown1Open(null);
  };
  const [isMobileMenuConsumerPopupOpen, setMobileMenuConsumerPopupOpen] =
    useState(false);
  const navigate = useNavigate();

  const onPostAJobClick = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onHowItWorksClick = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQsClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementTextClick = useCallback(() => {
    navigate("/agreement");
  }, [navigate]);

  const onHowItWorks1Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onContactUsText1Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQs1Click = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementText1Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/becometeam");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/partners");
  }, [navigate]);

  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/affiliate-program");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onLargeButtons1Click = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onFrameContainer22Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onPageSelectorContainerClick = useCallback(() => {
    navigate("/professional");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHowItWorks2Click = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onFrameButtonClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onSmallButtonsClick = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const openMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(true);
  }, []);

  const closeMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(false);
  }, []);

  return (
    <>
      <div className={styles.faq}>
        <div className={styles.consumerhelpfaqdone}>
           <nav className={styles.navigation}>
            <div className={styles.navigation1}>
              <img
                className={styles.navigationChild}
                alt=""
                onClick={()=>navigate('/')}
                style={{cursor:'pointer'}}
                src="/frame-66522.svg"
              />
              <div className={styles.navigationItem} />
              <div className={styles.tabSwitch}>
                <div
                  className={styles.pageSelector}
                  onClick={onPageSelectorContainerClick}
                >
                  <div className={styles.descriptionParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Professional
                    </div>
                    <div className={styles.selector} />
                  </div>
                </div>
                <div className={styles.pageSelector1}>
                  <div className={styles.descriptionParent}>
                    <b className={styles.description1}>Consumer</b>
                    <div className={styles.selector1} />
                  </div>
                </div>
              </div>
             <LocationSearch/>
            </div>
            <div className={styles.navigation2}>
              <div className={styles.homeParent}>
                <div className={styles.home} onClick={onHomeTextClick}>
                  Home
                </div>
                <div className={styles.home} onClick={onHowItWorks2Click}>
                  How it works
                </div>
                <div className={styles.services} 
                id="fade-button"
  aria-controls={open ? 'fade-menu' : undefined}
  aria-haspopup="true"
  aria-expanded={open ? 'true' : undefined}
                onClick={openDropdown}>
                  <div className={styles.services1}>Services</div>
                  <img
                    className={styles.iconssearch}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={isDropdownOpen}
        open={open}
        onClose={closeDropdown}
        TransitionComponent={Fade}
      >
      <Dropdown4/>
      </Menu>
                <div className={styles.services} 
id="fade-button2"
 aria-controls={open1 ? 'fade-menu2' : undefined}
 aria-haspopup="true"
 aria-expanded={open1 ? 'true' : undefined}
                onClick={openDropdown1}>
                  <div className={styles.services1}>Resources</div>
                  <img
                    className={styles.iconssearch}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                    <Menu
        id="fade-menu2"
        MenuListProps={{
          'aria-labelledby': 'fade-button2',
        }}
        anchorEl={isDropdown1Open}
        open={open1}
        onClose={closeDropdown1}
        TransitionComponent={Fade}
      >
      <Dropdown5/>
      </Menu>
                <div className={styles.pricing}>Pricing</div>
              </div>
              <div className={styles.frameParent3}>
                <div className={styles.frameParent4}>
                  <button
                    className={styles.iconshelpParent}
                    onClick={onFrameButtonClick}
                  >
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/iconshelp1.svg"
                    />
                    <div className={styles.help}>Help</div>
                  </button>
                  <button className={styles.iconshelpParent} onClick={()=>window.open('https://www.consumer.loggworks.co.uk/login', '_blank')}>
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/iconsaccount11.svg"
                    />
                    <div className={styles.account}>Account</div>
                  </button>
                </div>
                <button
                  className={styles.smallButtons}
                  onClick={onSmallButtonsClick}
                >
                  <div className={styles.iconsaddParent}>
                    <img
                      className={styles.iconsadd13}
                      alt=""
                      src="/iconsadd19.svg"
                    />
                    <div className={styles.button}>Post a job</div>
                  </div>
                </button>
              </div>
            </div>
          </nav>
          <div className={styles.prototypeFooterParent}>
            <div className={styles.frameParent2}>
              <div className={styles.frameWrapper}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.getAllTheHelpYouNeedParent}>
                    <b className={styles.getAllThe}>
                      Get all the help you need.
                    </b>
                    <div className={styles.loggworksConnectHomeowners}>
                      Loggworks connect homeowners to genuine and reliable
                      service providers who provide easy-to-book and hassle-free
                      services.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper2}>
                <div className={styles.twoTabSwitchParent}>
                  <div className={styles.twoTabSwitch}>
                    <div className={styles.tab1Wrapper}>
                      <div className={styles.tab1}>
                        Frequently Asked Questions
                      </div>
                    </div>
                    <div
                      className={styles.tab2Wrapper}
                      onClick={onFrameContainer22Click}
                    >
                      <div className={styles.tab2}>Contact Us</div>
                    </div>
                  </div>
                  <div className={styles.faqParent}>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                        <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>What is Loggworks?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            Loggworks is a platform connecting Home service consumers
            (Homeowners, Tenants and Property managers) to Service
            Professionals.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </div>
                      </div>
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                          <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>How does Loggworks work?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            <ul className={styles.searchForNearbyServiceProf}>
              <li className={styles.getQuotationForServiceRequ}>
                <span className={styles.searchForNearby}>
                  Search for nearby service professionals:
                </span>
                <span>
                  {" "}
                  Our web app allow you to search for service professionals that
                  are providing specific service. 
                </span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span className={styles.searchForNearby}>
                  Get quotation for service required:
                </span>
                <span className={styles.afterTheDetails}>
                  {" "}
                  After the details of the job has been shared with the service
                  professional, the professional will send a quote to the
                  consumer.
                </span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span className={styles.searchForNearby}>
                  Rate and review completed bookings:
                </span>
                <span className={styles.afterTheDetails}>
                  {" "}
                  Once bookings have been completed, consumers will be prompt to
                  rate and review the job done.
                </span>
              </li>
              <li>
                <span className={styles.searchForNearby}>
                  Earn cash-back and rewards:
                </span>
                <span className={styles.afterTheDetails}>
                  {" "}
                  Once ratings and reviews have been submitted to service
                  providers, you will be rewarded for your efforts.
                </span>
              </li>
            </ul>
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                          
                        </div>
                      </div>
                     
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                                             <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> Why should I use Loggworks?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            Loggworks protects both payment and privacy. Your funds are held in
            our escrow infrastructure powered by MangoPay when you use our
            payment protection. When the job is finished to your liking, you can
            release payments within three working days by clicking the complete
            button in your booking section. With our privacy protection, you can
            communicate in the app or share your contact information. Loggworks
            does not guarantee any jobs booked outside of the platform.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                         
                        </div>
                      </div>
                     
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                            <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> Do you vet service professionals who sign-up to loggworks?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            <p className={styles.doYouVet}>
              Do you vet service providers who sign up to Loggworks?
            </p>
            <p className={styles.doYouVet}>
              Service providers who sign up to Loggworks are vetted through a
              scheme called the Self-Employed Quality Control Scheme (SEQCS).
            </p>
            <p className={styles.doYouVet}>&nbsp;</p>
            <p className={styles.doYouVet}>
              Loggworks SEQCS is a scheme that involves the use of information
              service companies, trade standard organisations, and relevant
              regulatory bodies in the service industry to vet service
              professionals who are self-employed individuals on our platform.
              These organisations work with other technologies in order to
              minimise the risk posed by rogue service providers to property
              owners, property managers, and tenants. This scheme ensures that
              these self-employed individuals or businesses have the right
              licences and certifications to operate in their respective fields
              of service before they can use our platform.
            </p>
            <p className={styles.doYouVet}>&nbsp;</p>
            <p className={styles.doYouVet}>
              This scheme, among other things, prevents fraud, helps in
              identifying quality service providers, and ensures that
              self-employed individuals or businesses are recognised for their
              quality work.
            </p>
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </div>
                      </div>
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                            <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>  How can you guarantee the quality of a service profession's work?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            <p className={styles.doYouVet}>
              <span>{`The service providers on Loggworks operate as independent businesses and do not work for Loggworks, so the quality of their work cannot be guaranteed. We can only certify that every service professionals is vetted through our self-employed quality control scheme (SEQCS) and agrees to abide by our `}</span>
              <span className={styles.codeOfConduct}>
                code of conduct (Href)
              </span>
              <span className={styles.beforeTheyAre}>
                {" "}
                before they are allowed on our app or website.
              </span>
            </p>
            <p className={styles.doYouVet}>
              It is advisable to read the reviews, comments, and ratings left by
              previous customers about the service provider's quality of work,
              reliability, and value for money. By doing so, you will be able to
              make an informed decision.
            </p>
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </div>
                      </div>
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                            <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> Do you disable service professionals' accounts?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            <p className={styles.doYouVet}>
              Yes, we do. Loggworks is committed to minimising the risks posed
              by rogue service providers to property owners and honest
              professionals.
            </p>
            <p className={styles.doYouVet}>
              For this reason, we will close the accounts of service providers
              if:
            </p>
            <ul className={styles.searchForNearbyServiceProf}>
              <li className={styles.getQuotationForServiceRequ}>
                <span>
                  They are not willing to resolve fair and reasonable negative
                  feedback or complaints.
                </span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span>
                  They received negative reviews or serious complaints from a
                  homeowner/Tenants during their first few months with
                  Loggworks.
                </span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span>
                  They received three instances of poor customer service within
                  the last 12 months (based on negative feedback or a complaint
                  being raised).
                </span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span>
                  After investigating an issue or complaint, we confirmed that
                  there is sufficient cause to remove a Professional from our
                  platform.
                </span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span>
                  They behave inappropriately, including by being racist or
                  sexist, threatening, bullying, stealing, or abusing the trust
                  of customers.
                </span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span>
                  They receive serious complaints relating to their work or
                  personal conduct from outside the Loggworks platform.
                </span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span>We receive feedback on them from trading standards.</span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span>
                  They set up multiple Loggworks accounts for personal gain.
                </span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span>They can no longer pass identity validation.</span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span>
                  They can no longer meet the financial stability criteria that
                  we have set.
                </span>
              </li>
            </ul>
            <p className={styles.doYouVet}>&nbsp;</p>
            <p className={styles.doYouVet}>
              <span className={styles.searchForNearby}>NOTE:</span>
              <span>
                {" "}
                This list is by no means exhaustive and is therefore intended to
                protect both homeowners and honest tradespeople. For example, if
                a tradesperson’s business becomes financially challenged and
                they receive a complaint, we may choose to close their account.
              </span>
            </p>
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </div>
                      </div>
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                            <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> How do I choose the most reliable service professional?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            <p className={styles.doYouVet}>
              Our search feature is equipped with options for better
              decision-making when choosing a service provider. The most
              reliable service provider is selected based on the following
              criteria: ratings, level, reviews, and how long they have been a
              member of Loggworks.
            </p>
            <p className={styles.doYouVet}>&nbsp;</p>
            <p className={styles.doYouVet}>
              Recommendations are given based on our transparent monitoring
              system and the KPIs used in rating the service providers. These
              KPIs are points that are earned by the service providers to level
              up, the unbiased reviews, and the ratings that were given by the
              consumers. With these KPIs, you are now in a position to select
              the most reliable service provider for the job.
            </p>
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </div>
                      </div>
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                            <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> How many service professionals can I contact before I book one?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            With loggworks you can contact as many service professionals as you
            want, depending on your preferences and needs.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </div>
                      </div>
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                            <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>Can I make changes or update the service I have booked?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div
            className={styles.sushiMostWesterners}
          >{`Bookings can only be updated or changed 24 hours before the appointment date with the service provider. To cancel a booking, click on booking> upcoming, then reschedule or cancel`}</div>
      
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </div>
                      </div>
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                            <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>Should I rate the service professional after the job is completed?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            <p
              className={styles.doYouVet}
            >{`Yes, it is important that you leave a rating after the job has been completed. This helps us recommend reliable service providers to existing and prospective customers. Additionally, it helps other consumers make informed decisions while searching for a reliable search provider. To leave a rating, click on booking > past booking, then click rate the job of the service provider.`}</p>
            <p className={styles.doYouVet}>&nbsp;</p>
            <p className={styles.doYouVet}>
              NOTE: Once you click on completed, a prompt page pops up for
              ratings, reviews, and a survey. It is important that you complete
              it so as to serve you better.
            </p>
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </div>
                      </div>
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                            <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> How do I share a service professional profile that I like with
              others?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            You can share the profile of your preferred service providers with
            anyone by viewing their profile, then clicking the share button to
            share on any platform.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </div>
                      </div>
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                            <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> Is my payment protected?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            Yes, payments are protected via the MangoPay escrow infrastructure.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </div>
                      </div>
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                            <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>What if there’s a dispute?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            Dispute resolution is completed after 14 days of investigation.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </div>
                      </div>
                    </div>
                    <div className={styles.faq1}>
                      <div className={styles.question}>
                        <div className={styles.whatIsLoggworksParent}>
                            <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> What if I have a dispute with a service provider after completing
              a job?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            We would advise appropriately upon the completion of our
            investigation.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           <PrototypeFooter/>
          </div>
         
        </div>
        <div className={styles.homeMobile}>
          <div className={styles.navbarMobileclosed}>
            <img className={styles.layer2Icon1} alt="" src="/layer-217.svg" 
            style={{cursor:'pointer'}} onClick={()=>navigate('/')} />
            <div className={styles.iconsaccountGroup}>
              <img
                className={styles.iconsaccount1}
                alt=""
                src="/iconsaccount13.svg"
                onClick={()=>window.open('https://www.consumer.loggworks.co.uk/login', '_blank')}
              />
              <img
                className={styles.hamburgerIcon}
                alt=""
                src="/hamburger.svg"
                onClick={openMobileMenuConsumerPopup}
              />
            </div>
          </div>
          <div className={styles.homeMobileInner}>
            <div className={styles.getAllTheHelpYouNeedGroup}>
              <div className={styles.getAllThe1}>
                Get all the help you need.
              </div>
              <div className={styles.loggworksConnectHomeowners1}>
                Loggworks connect homeowners to genuine and reliable service
                providers who provide easy-to-book and hassle-free services.
              </div>
            </div>
          </div>
          <div className={styles.twoTabSwitchWrapper}>
            <div className={styles.twoTabSwitch1}>
              <div className={styles.tab1Wrapper}>
                <div className={styles.tab1}>FAQ</div>
              </div>
              <div className={styles.tab2Container} onClick={onFrameContainer22Click}>
                <div className={styles.tab2}>Contact Us</div>
              </div>
            </div>
          </div>
          <div className={styles.faqGroup}>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>What is Loggworks?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            Loggworks is a platform connecting Home service consumers
            (Homeowners, Tenants and Property managers) to Service
            Professionals.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                </div>
              </div>
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>How does Loggworks work?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ul className={styles.searchForNearbyServiceProf}>
              <li className={styles.getQuotationForServiceRequ}>
                <span className={styles.searchForNearby}>
                  Search for nearby service professionals:
                </span>
                <span>
                  {" "}
                  Our web app allow you to search for service professionals that
                  are providing specific service. 
                </span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span className={styles.searchForNearby}>
                  Get quotation for service required:
                </span>
                <span className={styles.afterTheDetails}>
                  {" "}
                  After the details of the job has been shared with the service
                  professional, the professional will send a quote to the
                  consumer.
                </span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span className={styles.searchForNearby}>
                  Rate and review completed bookings:
                </span>
                <span className={styles.afterTheDetails}>
                  {" "}
                  Once bookings have been completed, consumers will be prompt to
                  rate and review the job done.
                </span>
              </li>
              <li>
                <span className={styles.searchForNearby}>
                  Earn cash-back and rewards:
                </span>
                <span className={styles.afterTheDetails}>
                  {" "}
                  Once ratings and reviews have been submitted to service
                  providers, you will be rewarded for your efforts.
                </span>
              </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
                </div>
              </div>
             
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <div className={styles.whatIsLoggworks13}>
                    <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> Why should I use Loggworks?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            Loggworks protects both payment and privacy. Your funds are held in
            our escrow infrastructure powered by MangoPay when you use our
            payment protection. When the job is finished to your liking, you can
            release payments within three working days by clicking the complete
            button in your booking section. With our privacy protection, you can
            communicate in the app or share your contact information. Loggworks
            does not guarantee any jobs booked outside of the platform.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> Do you vet service professionals who sign-up to loggworks?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            <p className={styles.doYouVet}>
              Do you vet service providers who sign up to Loggworks?
            </p>
            <p className={styles.doYouVet}>
              Service providers who sign up to Loggworks are vetted through a
              scheme called the Self-Employed Quality Control Scheme (SEQCS).
            </p>
            <p className={styles.doYouVet}>&nbsp;</p>
            <p className={styles.doYouVet}>
              Loggworks SEQCS is a scheme that involves the use of information
              service companies, trade standard organisations, and relevant
              regulatory bodies in the service industry to vet service
              professionals who are self-employed individuals on our platform.
              These organisations work with other technologies in order to
              minimise the risk posed by rogue service providers to property
              owners, property managers, and tenants. This scheme ensures that
              these self-employed individuals or businesses have the right
              licences and certifications to operate in their respective fields
              of service before they can use our platform.
            </p>
            <p className={styles.doYouVet}>&nbsp;</p>
            <p className={styles.doYouVet}>
              This scheme, among other things, prevents fraud, helps in
              identifying quality service providers, and ensures that
              self-employed individuals or businesses are recognised for their
              quality work.
            </p>
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                </div>
              </div>
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <div className={styles.whatIsLoggworks13}>
                    <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> How can you guarantee the quality of a service profession's work?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            <p className={styles.doYouVet}>
              <span>{`The service providers on Loggworks operate as independent businesses and do not work for Loggworks, so the quality of their work cannot be guaranteed. We can only certify that every service professionals is vetted through our self-employed quality control scheme (SEQCS) and agrees to abide by our `}</span>
              <span className={styles.codeOfConduct}>
                code of conduct (Href)
              </span>
              <span className={styles.beforeTheyAre}>
                {" "}
                before they are allowed on our app or website.
              </span>
            </p>
            <p className={styles.doYouVet}>
              It is advisable to read the reviews, comments, and ratings left by
              previous customers about the service provider's quality of work,
              reliability, and value for money. By doing so, you will be able to
              make an informed decision.
            </p>
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <div className={styles.whatIsLoggworks13}>
                    <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> Do you disable service professionals' accounts?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            <p className={styles.doYouVet}>
              Yes, we do. Loggworks is committed to minimising the risks posed
              by rogue service providers to property owners and honest
              professionals.
            </p>
            <p className={styles.doYouVet}>
              For this reason, we will close the accounts of service providers
              if:
            </p>
            <ul className={styles.searchForNearbyServiceProf}>
              <li className={styles.getQuotationForServiceRequ}>
                <span>
                  They are not willing to resolve fair and reasonable negative
                  feedback or complaints.
                </span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span>
                  They received negative reviews or serious complaints from a
                  homeowner/Tenants during their first few months with
                  Loggworks.
                </span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span>
                  They received three instances of poor customer service within
                  the last 12 months (based on negative feedback or a complaint
                  being raised).
                </span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span>
                  After investigating an issue or complaint, we confirmed that
                  there is sufficient cause to remove a Professional from our
                  platform.
                </span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span>
                  They behave inappropriately, including by being racist or
                  sexist, threatening, bullying, stealing, or abusing the trust
                  of customers.
                </span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span>
                  They receive serious complaints relating to their work or
                  personal conduct from outside the Loggworks platform.
                </span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span>We receive feedback on them from trading standards.</span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span>
                  They set up multiple Loggworks accounts for personal gain.
                </span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span>They can no longer pass identity validation.</span>
              </li>
              <li className={styles.getQuotationForServiceRequ}>
                <span>
                  They can no longer meet the financial stability criteria that
                  we have set.
                </span>
              </li>
            </ul>
            <p className={styles.doYouVet}>&nbsp;</p>
            <p className={styles.doYouVet}>
              <span className={styles.searchForNearby}>NOTE:</span>
              <span>
                {" "}
                This list is by no means exhaustive and is therefore intended to
                protect both homeowners and honest tradespeople. For example, if
                a tradesperson’s business becomes financially challenged and
                they receive a complaint, we may choose to close their account.
              </span>
            </p>
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <div className={styles.whatIsLoggworks13}>
                    <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> How do I choose the most reliable service professional?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            <p className={styles.doYouVet}>
              Our search feature is equipped with options for better
              decision-making when choosing a service provider. The most
              reliable service provider is selected based on the following
              criteria: ratings, level, reviews, and how long they have been a
              member of Loggworks.
            </p>
            <p className={styles.doYouVet}>&nbsp;</p>
            <p className={styles.doYouVet}>
              Recommendations are given based on our transparent monitoring
              system and the KPIs used in rating the service providers. These
              KPIs are points that are earned by the service providers to level
              up, the unbiased reviews, and the ratings that were given by the
              consumers. With these KPIs, you are now in a position to select
              the most reliable service provider for the job.
            </p>
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <div className={styles.whatIsLoggworks13}>
                    <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>How many service professionals can I contact before I book one?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            With loggworks you can contact as many service professionals as you
            want, depending on your preferences and needs.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <div className={styles.whatIsLoggworks13}>
                    <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> Can I make changes or update the service I have booked?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div
            className={styles.sushiMostWesterners}
          >{`Bookings can only be updated or changed 24 hours before the appointment date with the service provider. To cancel a booking, click on booking> upcoming, then reschedule or cancel`}</div>
    
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <div className={styles.whatIsLoggworks13}>
                    <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>Should I rate the service professional after the job is completed?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            <p
              className={styles.doYouVet}
            >{`Yes, it is important that you leave a rating after the job has been completed. This helps us recommend reliable service providers to existing and prospective customers. Additionally, it helps other consumers make informed decisions while searching for a reliable search provider. To leave a rating, click on booking > past booking, then click rate the job of the service provider.`}</p>
            <p className={styles.doYouVet}>&nbsp;</p>
            <p className={styles.doYouVet}>
              NOTE: Once you click on completed, a prompt page pops up for
              ratings, reviews, and a survey. It is important that you complete
              it so as to serve you better.
            </p>
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <div className={styles.whatIsLoggworks13}>
                    <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> How do I share a service professional profile that I like with
              others?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            You can share the profile of your preferred service providers with
            anyone by viewing their profile, then clicking the share button to
            share on any platform.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <div className={styles.whatIsLoggworks13}>
                    <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> Is my payment protected?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            Yes, payments are protected via the MangoPay escrow infrastructure.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <div className={styles.whatIsLoggworks13}>
                    <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>What if there’s a dispute?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            Dispute resolution is completed after 14 days of investigation.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className={styles.faq14}>
              <div className={styles.question13}>
                <div className={styles.whatIsLoggworksParent}>
                  <div className={styles.whatIsLoggworks13}>
                    <Accordion style={{width:'100%'}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b> What if I have a dispute with a service provider after completing
              a job?</b>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className={styles.sushiMostWesterners}>
            We would advise appropriately upon the completion of our
            investigation.
          </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </div>
                  
                </div>
              </div>
            </div>
           
          </div>
         <MobileFooter/>
        </div>
      </div>
      {isMobileMenuConsumerPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuConsumerPopup}
        >
          <MobileMenuConsumer1 onClose={closeMobileMenuConsumerPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default FAQ1;
