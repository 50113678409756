
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Testimony.module.css";

const Consumers = () => {
  const isDesktop = window.innerWidth > 970;
  const testimonies = [
    {
      name: "George Harrison",
      text: "Using Loggworks made finding home services a breeze; posting jobs for free saved me money and hassle."
    },
    {
      name: "Owolabi Abiola",
      text: "Knowing my payment and privacy were protected gave me peace of mind throughout the entire process."
    },
    {
      name: "Oliver Bennett",
      text: "I loved the booking reminder feature; it ensured I never missed an appointment again."
    },
    {
      name: "Leo Harris",
      text: "The platform's user-friendly interface made it easy to navigate and find the services I needed."
    },
    {
      name: "Lily Thompson",
      text: "The booking management feature was a lifesaver, helping me keep track of all my appointments in one place."
    },
    {
      name: "Aisha Lawal",
      text: "The offers and deal of the day promotions were a pleasant surprise, allowing me to save money on services I needed."
    },
    {
      name: "Chisom  Nwabueze",
      text: "Getting multiple quotes helped me find the best deal for my budget and needs."
    },
    {
      name: "Mia Carter",
      text: "Working with vetted professionals through Loggworks ensured I received top-notch service every time."
    },
    {
      name: "Hassan Hussien",
      text: "I appreciated the transparency and reliability of Loggworks; it made the entire home service experience stress-free."
    },
    {
      name: "Nabeel Singh",
      text: "Overall, Loggworks exceeded my expectations with its range of features and benefits; I'll definitely be using it again in the future."
    }
  ];

  // Custom previous arrow component
  const CustomPrevArrow = (props) => (
    <div
      className="custom-prev-arrow"
      onClick={props.onClick}
      style={{ zIndex: 10, position: "absolute", left: 0, top: "50%", transform: "translateY(-50%)" }}
    >
      {/* Your custom previous arrow icon or image */}
      <img src="/group-1000005188.svg" alt="Previous" style={{ width: isDesktop ? "56px":'30px', height: isDesktop ? "56px":'30px' }} />
    </div>
  );

  // Custom next arrow component
  const CustomNextArrow = (props) => (
    <div
      className="custom-next-arrow"
      onClick={props.onClick}
      style={{ zIndex: 10, position: "absolute", right: 0, top: "50%", transform: "translateY(-50%)" }}
    >
      {/* Your custom next arrow icon or image */}
      <img src="/group-1000005188.svg" alt="Next" style={{ width: isDesktop ? "56px":'30px', height: isDesktop ? "56px":'30px', transform: "rotate(180deg)" }} />
    </div>
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />
  };

  
  return (
    <div
    className={styles.testimony}
      style={{
        borderRadius: "5px",
        margin:isDesktop &&'5% 0px',
        boxSizing: "border-box",
        overflow: "hidden",
        textAlign: "center",
        fontSize: "24px",
        color: "rgba(46, 46, 46, 0.9)",
        fontFamily: "Inter",
        height:isDesktop ?'400px':'500px'
      }}
    >
    
     <div
        style={{
          position: isDesktop &&"absolute",
          top: "16px",
          left: isDesktop ?"5%":'',
          right:isDesktop ?'5%':'',
          borderRadius: "8px",
          backgroundColor: "#edf9e6",
          width: isDesktop ? "" : "100%",
          height: isDesktop ?"466px":'500px',
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: isDesktop ?"134px 81px":'2rem',
          boxSizing: "border-box",
          gap: isDesktop &&"119px",
        }}
      >
         <Slider {...settings}>
           {testimonies.map((testimonial, index) => (
   <div key={index}>
   
   <div
     style={{
       display: "flex",
       flexDirection: "column",
       alignItems: "center",
       justifyContent: "flex-start",
       gap: "48px",
     }}
   >
     <div
       style={{
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
         justifyContent: "flex-start",
         gap: "32px",
         fontSize:!isDesktop &&'20px'
       }}
     >
      
      <div style={{ width: "70%", lineHeight: "36px" }}>"{testimonial.text}"</div>
              <div style={{ fontWeight: "600" }}>{testimonial.name}</div>
     </div>
    
   </div>
 
   </div>
      ))}
         </Slider>
       
      </div>
    </div>
  );
};

export default Consumers;
