import { useState, useCallback } from "react";
import Dropdown4 from "../components/Dropdown4";
import PortalPopup from "../components/PortalPopup";
import Dropdown5 from "../components/Dropdown5";
import MobileMenuConsumer1 from "../components/MobileMenuConsumer1";
import Dropdown3 from "../components/Dropdown3";
import { useNavigate } from "react-router-dom";
import styles from "./WeAchieve4.module.css";
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import MobileFooter from "../components/MobileFooter";
import LocationSearch from "../components/locationSearch.tsx";
const WeAchieve4 = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(null);
  const open = Boolean(isDropdownOpen);
  const openDropdown = (event) => {
    setDropdownOpen(event.currentTarget);
  };
  const closeDropdown = () => {
    setDropdownOpen(null);
  };
  const [isDropdown1Open, setDropdown1Open] = useState(null);
  const open1 = Boolean(isDropdown1Open);
   const openDropdown1 = (event) => {
    setDropdown1Open(event.currentTarget);
  };
  const closeDropdown1 = () => {
    setDropdown1Open(null);
  };
  const [isMobileMenuConsumerPopupOpen, setMobileMenuConsumerPopupOpen] =
    useState(false);
  const [isDropdown2Open, setDropdown2Open] = useState(false);
  const navigate = useNavigate();

  const onPostAJobClick = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onHowItWorksClick = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQsClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementTextClick = useCallback(() => {
    navigate("/agreement");
  }, [navigate]);

  const onHowItWorks1Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onContactUsText1Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQs1Click = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementText1Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/becometeam");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/partners");
  }, [navigate]);

  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/affiliate-program");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onLargeButtons1Click = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const onLargeButtonsContainerClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='frameContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onAllContainerClick = useCallback(() => {
    navigate("/partners");
  }, [navigate]);

  const onBookingContainerClick = useCallback(() => {
    navigate("/trade-associations");
  }, [navigate]);

  const onPaymentContainerClick = useCallback(() => {
    navigate("/retailers");
  }, [navigate]);

  const onMessageContainerClick = useCallback(() => {
    navigate("/training");
  }, [navigate]);

  const onPageSelectorContainerClick = useCallback(() => {
    navigate("/professional");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHowItWorks2Click = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onFrameButtonClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onSmallButtonsClick = useCallback(() => {
   navigate('/postjob')
  }, [navigate]);

  const openMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(true);
  }, []);

  const closeMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(false);
  }, []);

  const openDropdown2 = useCallback(() => {
    setDropdown2Open(true);
  }, []);

  const closeDropdown2 = useCallback(() => {
    setDropdown2Open(false);
  }, []);

  return (
    <>
      <div className={styles.weachieve4}>
        <div className={styles.professionalhelpcontact}>
          <div className={styles.prototypeFooter}>
            <div className={styles.footer}>
              <div className={styles.download}>
                <div className={styles.downloadOurApps}>Download our apps</div>
                <img
                  className={styles.playstoreButtonIcon}
                  alt=""
                  src="/playstore-button5.svg"
                />
                <img
                  className={styles.appstoreButtonIcon}
                  alt=""
                  src="/appstore-button4.svg"
                />
              </div>
              <div className={styles.footerChild} />
              <img className={styles.layer2Icon} 
               style={{cursor:'pointer'}}
               onClick={()=>{navigate('/')}}
               alt="" src="/layer-251.svg" />
              <div className={styles.frameParent}>
                <div className={styles.homeownersParent}>
                  <b className={styles.homeowners}>Homeowners</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.postAJob} onClick={onPostAJobClick}>
                      Post a Job
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHowItWorksClick}
                    >
                      How it works
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onContactUsTextClick}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHelpFAQsClick}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.postAJob}
                      onClick={onUserAgreementTextClick}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.professionalsParent}>
                  <b className={styles.homeowners}>Professionals</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.signUp}
                    style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                    <div
                      className={styles.postAJob}
                      onClick={onHowItWorks1Click}
                    >
                      Blog
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onContactUsText1Click}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHelpFAQs1Click}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.postAJob}
                      onClick={onUserAgreementText1Click}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.loggworksParent}>
                  <b className={styles.homeowners}>Loggworks</b>
                  <div className={styles.postAJobParent}>
                    <div
                      className={styles.postAJob}
                      onClick={onAboutUsTextClick}
                    >
                      About us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onCareersTextClick}
                    >
                      Careers
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onPartnersTextClick}
                    >
                      Partners
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onInfluencerProgramTextClick}
                    >
                      Affiliate program
                    </div>
                    <div className={styles.blog}>Blog</div>
                  </div>
                </div>
                <div className={styles.loggworksGroup}>
                  <div className={styles.loggworks1}>
                    <b className={styles.followUs}>Follow us</b>
                  </div>
                  <div className={styles.groupParent}>
                    <div className={styles.frameGroup}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://web.facebook.com/Loggworks?_rdc=1&_rdr', '_blank')}>
                      <img
                        className={styles.frameIcon}
                        alt=""
                        src="/frame33.svg"
                      />
                      <div className={styles.facebook}>Facebook</div>
                    </div>
                    <div className={styles.frameContainer}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://twitter.com/loggworks', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame34.svg"
                      />
                      <div className={styles.twitter}>Twitter</div>
                    </div>
                    <div className={styles.groupDiv}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.instagram.com/loggworks/?utm_medium=copy_link', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame35.svg"
                      />
                      <div className={styles.twitter}>Instagram</div>
                    </div>
                    <div className={styles.frameParent1}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame36.svg"
                      />
                      <div className={styles.twitter}>YouTube</div>
                    </div>
                    <div className={styles.mdilinkedinParent}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.linkedin.com/company/loggworks', '_blank')}>
                      <img
                        className={styles.mdilinkedinIcon}
                        alt=""
                        src="/mdilinkedin1.svg"
                      />
                      <div className={styles.linkedin}>LinkedIn</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.copyright2022LoggworkLtdAParent}>
                <div className={styles.copyright2022Loggwork}>
                  ©Copyright 2024 loggworks  Ltd. All Rights Reserved
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/terms')}}>
                      Terms of Use
                    </div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div
                      className={styles.copyright2022Loggwork}
                      style={{cursor:'pointer'}}
                      onClick={()=>{navigate('/privacy')}}
                    >{`Privacy Policy `}</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                     style={{cursor:'pointer'}}
                     onClick={()=>{navigate('/cookie')}}>Cookies</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.contactUsParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Contact Us
                    </div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork} style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href='https://www.loggworks.co.uk/sitemap.xml'
                    }}>Sitemap</div>
                    <div className={styles.frameChild1} />
                  </div>
                </div>
              </div>
              <div className={styles.largeButtonsParent}>
                <button
                  className={styles.largeButtons}
                  style={{cursor:'pointer'}}
                  onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder321.svg"
                  />
                  <b className={styles.label}>Become a Professional</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder321.svg"
                  />
                </button>
                <button
                  className={styles.largeButtons}
                  onClick={onLargeButtons1Click}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder33.svg"
                  />
                  <b className={styles.label}>Post a Job</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder33.svg"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className={styles.frameParent2}>
            <div className={styles.frameWrapper}>
              <div className={styles.frameParent3}>
                <div className={styles.togetherWeAchieveMoreParent}>
                  <b className={styles.togetherWeAchieve}>
                    Together we achieve more
                  </b>
                  <div className={styles.loggworksConnectHomeowners}>
                    Loggworks connect homeowners to genuine and reliable service
                    providers who provide easy-to-book and hassle-free services.
                  </div>
                </div>
                <div
                  className={styles.largeButtons2}
                  onClick={onLargeButtonsContainerClick}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder34.svg"
                  />
                  <b className={styles.label2}>Contact Us</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder34.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.tabsSubSwitch}>
              <div className={styles.allParent}>
                <div className={styles.all} onClick={onAllContainerClick}>
                  <div className={styles.booking}>All</div>
                </div>
                <div className={styles.all} onClick={onBookingContainerClick}>
                  <div className={styles.booking}>Trade associations</div>
                </div>
                <div className={styles.all} onClick={onPaymentContainerClick}>
                  <div className={styles.booking}>Retailers</div>
                </div>
                <div className={styles.promotion}>
                  <div className={styles.booking4}>Ambassadors</div>
                </div>
                <div className={styles.all} onClick={onMessageContainerClick}>
                  <div className={styles.booking}>Training</div>
                </div>
              </div>
            </div>
            <div className={styles.partners1}>
              <div className={styles.mergeParent}>
                <div className={styles.merge}>
                  <div className={styles.text}>
                    <div className={styles.tradeAssociations}>Ambassadors</div>
                    <div className={styles.theseCompaniesOr}>
                      Ambassadorial partners are typically individuals who love
                      and want to represent our brand and promotes our products
                      or services to others.
                    </div>
                  </div>
                  <div className={styles.logos}>
                    <div className={styles.row1}>
                      <div className={styles.partners2}>
                        <div className={styles.name}>
                          <div className={styles.gusto}>ZIp Recruiter</div>
                        </div>
                        <div className={styles.logobetterhelp}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logobetterhelp@2x.png"
                          />
                        </div>
                        <div className={styles.logogusto}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logogusto@2x.png"
                          />
                        </div>
                        <div className={styles.logojeep}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logojeep@2x.png"
                          />
                        </div>
                        <div className={styles.logohawaiianPunch}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logohawaiian-punch@2x.png"
                          />
                        </div>
                        <div className={styles.logoikea}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoikea@2x.png"
                          />
                        </div>
                        <div className={styles.logoironMan}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoiron-man@2x.png"
                          />
                        </div>
                        <div className={styles.logoconfluence}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoconfluence@2x.png"
                          />
                        </div>
                        <div className={styles.logonewYorkRangers}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logonew-york-rangers@2x.png"
                          />
                        </div>
                        <div className={styles.logozipRecruiter}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logozip-recruiter@2x.png"
                          />
                        </div>
                      </div>
                      <div className={styles.partners2}>
                        <div className={styles.name}>
                          <div className={styles.gusto}>New Yor Rangers</div>
                        </div>
                        <div className={styles.logobetterhelp}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logobetterhelp1@2x.png"
                          />
                        </div>
                        <div className={styles.logogusto}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logogusto1@2x.png"
                          />
                        </div>
                        <div className={styles.logojeep}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logojeep1@2x.png"
                          />
                        </div>
                        <div className={styles.logohawaiianPunch}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logohawaiian-punch1@2x.png"
                          />
                        </div>
                        <div className={styles.logoikea}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoikea1@2x.png"
                          />
                        </div>
                        <div className={styles.logoironMan}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoiron-man1@2x.png"
                          />
                        </div>
                        <div className={styles.logoconfluence}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoconfluence1@2x.png"
                          />
                        </div>
                        <div className={styles.logonewYorkRangers2}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logonew-york-rangers1@2x.png"
                          />
                        </div>
                        <div className={styles.logozipRecruiter1}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logozip-recruiter1@2x.png"
                          />
                        </div>
                      </div>
                      <div className={styles.partners2}>
                        <div className={styles.name}>
                          <div className={styles.gusto}>Confluence</div>
                        </div>
                        <div className={styles.logobetterhelp}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logobetterhelp2@2x.png"
                          />
                        </div>
                        <div className={styles.logogusto}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logogusto2@2x.png"
                          />
                        </div>
                        <div className={styles.logojeep}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logojeep2@2x.png"
                          />
                        </div>
                        <div className={styles.logohawaiianPunch}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logohawaiian-punch2@2x.png"
                          />
                        </div>
                        <div className={styles.logoikea}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoikea2@2x.png"
                          />
                        </div>
                        <div className={styles.logoironMan}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoiron-man2@2x.png"
                          />
                        </div>
                        <div className={styles.logoconfluence2}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoconfluence2@2x.png"
                          />
                        </div>
                        <div className={styles.logonewYorkRangers}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logonew-york-rangers2@2x.png"
                          />
                        </div>
                        <div className={styles.logozipRecruiter1}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logozip-recruiter2@2x.png"
                          />
                        </div>
                      </div>
                      <div className={styles.partners2}>
                        <div className={styles.name}>
                          <div className={styles.gusto}>Iron Man</div>
                        </div>
                        <div className={styles.logobetterhelp}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logobetterhelp3@2x.png"
                          />
                        </div>
                        <div className={styles.logogusto}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logogusto3@2x.png"
                          />
                        </div>
                        <div className={styles.logojeep}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logojeep3@2x.png"
                          />
                        </div>
                        <div className={styles.logohawaiianPunch}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logohawaiian-punch3@2x.png"
                          />
                        </div>
                        <div className={styles.logoikea}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoikea3@2x.png"
                          />
                        </div>
                        <div className={styles.logoironMan3}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoiron-man3@2x.png"
                          />
                        </div>
                        <div className={styles.logoconfluence}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoconfluence3@2x.png"
                          />
                        </div>
                        <div className={styles.logonewYorkRangers}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logonew-york-rangers3@2x.png"
                          />
                        </div>
                        <div className={styles.logozipRecruiter1}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logozip-recruiter3@2x.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.row1}>
                      <div className={styles.partners2}>
                        <div className={styles.name}>
                          <div className={styles.gusto}>Ikea</div>
                        </div>
                        <div className={styles.logobetterhelp}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logobetterhelp4@2x.png"
                          />
                        </div>
                        <div className={styles.logogusto}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logogusto4@2x.png"
                          />
                        </div>
                        <div className={styles.logojeep}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logojeep4@2x.png"
                          />
                        </div>
                        <div className={styles.logohawaiianPunch}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logohawaiian-punch4@2x.png"
                          />
                        </div>
                        <div className={styles.logoikea4}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoikea4@2x.png"
                          />
                        </div>
                        <div className={styles.logoironMan}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoiron-man4@2x.png"
                          />
                        </div>
                        <div className={styles.logoconfluence}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoconfluence4@2x.png"
                          />
                        </div>
                        <div className={styles.logonewYorkRangers}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logonew-york-rangers4@2x.png"
                          />
                        </div>
                        <div className={styles.logozipRecruiter1}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logozip-recruiter4@2x.png"
                          />
                        </div>
                      </div>
                      <div className={styles.partners2}>
                        <div className={styles.name}>
                          <div className={styles.gusto}>Hawaiian Punch</div>
                        </div>
                        <div className={styles.logobetterhelp}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logobetterhelp5@2x.png"
                          />
                        </div>
                        <div className={styles.logogusto}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logogusto5@2x.png"
                          />
                        </div>
                        <div className={styles.logojeep}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logojeep5@2x.png"
                          />
                        </div>
                        <div className={styles.logohawaiianPunch5}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logohawaiian-punch5@2x.png"
                          />
                        </div>
                        <div className={styles.logoikea}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoikea5@2x.png"
                          />
                        </div>
                        <div className={styles.logoironMan}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoiron-man5@2x.png"
                          />
                        </div>
                        <div className={styles.logoconfluence}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoconfluence5@2x.png"
                          />
                        </div>
                        <div className={styles.logonewYorkRangers}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logonew-york-rangers5@2x.png"
                          />
                        </div>
                        <div className={styles.logozipRecruiter1}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logozip-recruiter5@2x.png"
                          />
                        </div>
                      </div>
                      <div className={styles.partners2}>
                        <div className={styles.name}>
                          <div className={styles.gusto}>Jeep</div>
                        </div>
                        <div className={styles.logobetterhelp}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logobetterhelp6@2x.png"
                          />
                        </div>
                        <div className={styles.logogusto}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logogusto6@2x.png"
                          />
                        </div>
                        <div className={styles.logojeep6}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logojeep6@2x.png"
                          />
                        </div>
                        <div className={styles.logohawaiianPunch}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logohawaiian-punch6@2x.png"
                          />
                        </div>
                        <div className={styles.logoikea}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoikea6@2x.png"
                          />
                        </div>
                        <div className={styles.logoironMan}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoiron-man6@2x.png"
                          />
                        </div>
                        <div className={styles.logoconfluence}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoconfluence6@2x.png"
                          />
                        </div>
                        <div className={styles.logonewYorkRangers}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logonew-york-rangers6@2x.png"
                          />
                        </div>
                        <div className={styles.logozipRecruiter1}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logozip-recruiter6@2x.png"
                          />
                        </div>
                      </div>
                      <div className={styles.partners2}>
                        <div className={styles.name}>
                          <div className={styles.gusto}>Gusto</div>
                        </div>
                        <div className={styles.logobetterhelp}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logobetterhelp7@2x.png"
                          />
                        </div>
                        <div className={styles.logogusto7}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logogusto7@2x.png"
                          />
                        </div>
                        <div className={styles.logojeep}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logojeep7@2x.png"
                          />
                        </div>
                        <div className={styles.logohawaiianPunch}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logohawaiian-punch7@2x.png"
                          />
                        </div>
                        <div className={styles.logoikea}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoikea7@2x.png"
                          />
                        </div>
                        <div className={styles.logoironMan}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoiron-man7@2x.png"
                          />
                        </div>
                        <div className={styles.logoconfluence}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoconfluence7@2x.png"
                          />
                        </div>
                        <div className={styles.logonewYorkRangers}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logonew-york-rangers7@2x.png"
                          />
                        </div>
                        <div className={styles.logozipRecruiter1}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logozip-recruiter7@2x.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.row3}>
                      <div className={styles.partners2}>
                        <div className={styles.name}>
                          <div className={styles.gusto}>Betterhelp</div>
                        </div>
                        <div className={styles.logobetterhelp8}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logobetterhelp8@2x.png"
                          />
                        </div>
                        <div className={styles.logogusto}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logogusto8@2x.png"
                          />
                        </div>
                        <div className={styles.logojeep}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logojeep8@2x.png"
                          />
                        </div>
                        <div className={styles.logohawaiianPunch}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logohawaiian-punch8@2x.png"
                          />
                        </div>
                        <div className={styles.logoikea}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoikea8@2x.png"
                          />
                        </div>
                        <div className={styles.logoironMan}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoiron-man8@2x.png"
                          />
                        </div>
                        <div className={styles.logoconfluence}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logoconfluence8@2x.png"
                          />
                        </div>
                        <div className={styles.logonewYorkRangers}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logonew-york-rangers8@2x.png"
                          />
                        </div>
                        <div className={styles.logozipRecruiter1}>
                          <img
                            className={styles.logobetterhelpIcon}
                            alt=""
                            src="/logozip-recruiter8@2x.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img className={styles.imgIcon} alt="" src="/img26@2x.png" />
              </div>
            </div>
            <div className={styles.frameWrapper1}>
              <div className={styles.frameParent4}>
                <div className={styles.ourAmbassadorialPartnersParent}>
                  <div className={styles.tradeAssociations}>
                    Our Ambassadorial partners
                  </div>
                  <div className={styles.loggworksConnectHomeowners1}>
                    Loggworks connect homeowners to genuine and reliable service
                    providers who provide easy-to-book and hassle-free services.
                  </div>
                </div>
                <div className={styles.frameParent5}>
                  <div className={styles.partnersParent}>
                    <div className={styles.partners11}>
                      <div className={styles.name9}>
                        <div className={styles.gusto}>ZIp Recruiter</div>
                      </div>
                      <div className={styles.logobetterhelp9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logobetterhelp9@2x.png"
                        />
                      </div>
                      <div className={styles.logogusto9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logogusto9@2x.png"
                        />
                      </div>
                      <div className={styles.logojeep9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logojeep9@2x.png"
                        />
                      </div>
                      <div className={styles.logohawaiianPunch9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logohawaiian-punch9@2x.png"
                        />
                      </div>
                      <div className={styles.logoikea9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoikea9@2x.png"
                        />
                      </div>
                      <div className={styles.logoironMan9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoiron-man9@2x.png"
                        />
                      </div>
                      <div className={styles.logoconfluence9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoconfluence9@2x.png"
                        />
                      </div>
                      <div className={styles.logonewYorkRangers18}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logonew-york-rangers9@2x.png"
                        />
                      </div>
                      <div className={styles.logozipRecruiter9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logozip-recruiter9@2x.png"
                        />
                      </div>
                    </div>
                    <div className={styles.partners11}>
                      <div className={styles.name9}>
                        <div className={styles.gusto}>New Yor Rangers</div>
                      </div>
                      <div className={styles.logobetterhelp9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logobetterhelp9@2x.png"
                        />
                      </div>
                      <div className={styles.logogusto9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logogusto9@2x.png"
                        />
                      </div>
                      <div className={styles.logojeep9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logojeep9@2x.png"
                        />
                      </div>
                      <div className={styles.logohawaiianPunch9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logohawaiian-punch9@2x.png"
                        />
                      </div>
                      <div className={styles.logoikea9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoikea9@2x.png"
                        />
                      </div>
                      <div className={styles.logoironMan9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoiron-man9@2x.png"
                        />
                      </div>
                      <div className={styles.logoconfluence9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoconfluence9@2x.png"
                        />
                      </div>
                      <div className={styles.logonewYorkRangers20}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logonew-york-rangers9@2x.png"
                        />
                      </div>
                      <div className={styles.logozipRecruiter10}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logozip-recruiter9@2x.png"
                        />
                      </div>
                    </div>
                    <div className={styles.partners11}>
                      <div className={styles.name9}>
                        <div className={styles.gusto}>Confluence</div>
                      </div>
                      <div className={styles.logobetterhelp9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logobetterhelp9@2x.png"
                        />
                      </div>
                      <div className={styles.logogusto9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logogusto9@2x.png"
                        />
                      </div>
                      <div className={styles.logojeep9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logojeep9@2x.png"
                        />
                      </div>
                      <div className={styles.logohawaiianPunch9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logohawaiian-punch9@2x.png"
                        />
                      </div>
                      <div className={styles.logoikea9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoikea9@2x.png"
                        />
                      </div>
                      <div className={styles.logoironMan9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoiron-man9@2x.png"
                        />
                      </div>
                      <div className={styles.logoconfluence11}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoconfluence9@2x.png"
                        />
                      </div>
                      <div className={styles.logonewYorkRangers18}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logonew-york-rangers9@2x.png"
                        />
                      </div>
                      <div className={styles.logozipRecruiter10}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logozip-recruiter9@2x.png"
                        />
                      </div>
                    </div>
                    <div className={styles.partners11}>
                      <div className={styles.name9}>
                        <div className={styles.gusto}>Iron Man</div>
                      </div>
                      <div className={styles.logobetterhelp9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logobetterhelp9@2x.png"
                        />
                      </div>
                      <div className={styles.logogusto9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logogusto9@2x.png"
                        />
                      </div>
                      <div className={styles.logojeep9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logojeep9@2x.png"
                        />
                      </div>
                      <div className={styles.logohawaiianPunch9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logohawaiian-punch9@2x.png"
                        />
                      </div>
                      <div className={styles.logoikea9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoikea9@2x.png"
                        />
                      </div>
                      <div className={styles.logoironMan12}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoiron-man9@2x.png"
                        />
                      </div>
                      <div className={styles.logoconfluence9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoconfluence9@2x.png"
                        />
                      </div>
                      <div className={styles.logonewYorkRangers18}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logonew-york-rangers9@2x.png"
                        />
                      </div>
                      <div className={styles.logozipRecruiter10}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logozip-recruiter9@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.partnersGroup}>
                    <div className={styles.partners11}>
                      <div className={styles.name9}>
                        <div className={styles.gusto}>Darlene Robertson</div>
                      </div>
                      <img
                        className={styles.avatarIcon}
                        alt=""
                        src="/avatar.svg"
                      />
                    </div>
                    <div className={styles.partners11}>
                      <div className={styles.name9}>
                        <div className={styles.gusto}>Dianne Russell</div>
                      </div>
                      <img
                        className={styles.avatarIcon}
                        alt=""
                        src="/avatar1.svg"
                      />
                    </div>
                    <div className={styles.partners11}>
                      <div className={styles.name9}>
                        <div className={styles.gusto}>Guy Hawkins</div>
                      </div>
                      <img
                        className={styles.avatarIcon}
                        alt=""
                        src="/avatar2.svg"
                      />
                    </div>
                    <div className={styles.partners11}>
                      <div className={styles.name9}>
                        <div className={styles.gusto}>Jacob Jones</div>
                      </div>
                      <img
                        className={styles.avatarIcon}
                        alt=""
                        src="/avatar3.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.partnersWrapper}>
                    <div className={styles.partners11}>
                      <div className={styles.name9}>
                        <div className={styles.gusto}>Betterhelp</div>
                      </div>
                      <div className={styles.logobetterhelp13}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logobetterhelp9@2x.png"
                        />
                      </div>
                      <div className={styles.logogusto9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logogusto9@2x.png"
                        />
                      </div>
                      <div className={styles.logojeep9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logojeep9@2x.png"
                        />
                      </div>
                      <div className={styles.logohawaiianPunch9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logohawaiian-punch9@2x.png"
                        />
                      </div>
                      <div className={styles.logoikea9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoikea9@2x.png"
                        />
                      </div>
                      <div className={styles.logoironMan9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoiron-man9@2x.png"
                        />
                      </div>
                      <div className={styles.logoconfluence9}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoconfluence9@2x.png"
                        />
                      </div>
                      <div className={styles.logonewYorkRangers18}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logonew-york-rangers9@2x.png"
                        />
                      </div>
                      <div className={styles.logozipRecruiter10}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logozip-recruiter9@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav className={styles.navigation}>
            <div className={styles.navigation1}>
              <img
                className={styles.navigationChild}
                alt=""
                src="/frame-665222.svg"
              />
              <div className={styles.navigationItem} />
              <div className={styles.tabSwitch}>
                <div
                  className={styles.pageSelector}
                  onClick={onPageSelectorContainerClick}
                >
                  <div className={styles.descriptionParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Professional
                    </div>
                    <div className={styles.selector} />
                  </div>
                </div>
                <div className={styles.pageSelector1}>
                  <div className={styles.descriptionParent}>
                    <b className={styles.description1}>Consumer</b>
                    <div className={styles.selector1} />
                  </div>
                </div>
              </div>
             <LocationSearch/>
            </div>
            <div className={styles.navigation2}>
              <div className={styles.homeParent}>
                <div className={styles.home} onClick={onHomeTextClick}>
                  Home
                </div>
                <div className={styles.home} onClick={onHowItWorks2Click}>
                  How it works
                </div>
                <div className={styles.services}
                 id="fade-button"
  aria-controls={open ? 'fade-menu' : undefined}
  aria-haspopup="true"
  aria-expanded={open ? 'true' : undefined}
                onClick={openDropdown}>
                  <div className={styles.services1}>Services</div>
                  <img
                    className={styles.iconssearch}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={isDropdownOpen}
        open={open}
        onClose={closeDropdown}
        TransitionComponent={Fade}
      >
      <Dropdown4/>
      </Menu>
                <div className={styles.services} 
                id="fade-button2"
 aria-controls={open1 ? 'fade-menu2' : undefined}
 aria-haspopup="true"
 aria-expanded={open1 ? 'true' : undefined}
                onClick={openDropdown1}>
                  <div className={styles.services1}>Resources</div>
                  <img
                    className={styles.iconssearch}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                </div>
                 <Menu
        id="fade-menu2"
        MenuListProps={{
          'aria-labelledby': 'fade-button2',
        }}
        anchorEl={isDropdown1Open}
        open={open1}
        onClose={closeDropdown1}
        TransitionComponent={Fade}
      >
      <Dropdown5/>
      </Menu>
                <div className={styles.pricing}>Pricing</div>
              </div>
              <div className={styles.frameParent6}>
                <div className={styles.frameParent7}>
                  <button
                    className={styles.iconshelpParent}
                    onClick={onFrameButtonClick}
                  >
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/iconshelp.svg"
                    />
                    <div className={styles.help}>Help</div>
                  </button>
                  <button className={styles.iconshelpParent}>
                    <img
                      className={styles.iconssearch}
                      alt=""
                      src="/iconsaccount11.svg"
                    />
                    <div className={styles.help}>Account</div>
                  </button>
                </div>
                <button
                  className={styles.smallButtons}
                  onClick={onSmallButtonsClick}
                >
                  <div className={styles.iconsaddParent}>
                    <img
                      className={styles.iconsadd}
                      alt=""
                      src="/iconsadd15.svg"
                    />
                    <div className={styles.button}>Post a job</div>
                  </div>
                </button>
              </div>
            </div>
          </nav>
        </div>
        <div className={styles.homeMobile}>
          <div className={styles.navbarMobileclosed}>
            <img className={styles.layer2Icon1} alt="" src="/layer-23.svg" 
            style={{cursor:'pointer'}} onClick={()=>navigate('/')} />
            <div className={styles.iconsaccountGroup}>
              <img
                className={styles.iconsaccount1}
                alt=""
                src="/iconsaccount3.svg"
                onClick={()=>window.open('https://www.consumer.loggworks.co.uk/login', '_blank')}
              />
              <img
                className={styles.hamburgerIcon}
                alt=""
                src="/hamburger.svg"
                onClick={openMobileMenuConsumerPopup}
              />
            </div>
          </div>
          <div className={styles.homeMobileInner}>
            <div className={styles.frameParent8}>
              <div className={styles.togetherWeAchieveMoreGroup}>
                <b className={styles.togetherWeAchieve1}>
                  Together we achieve more.
                </b>
                <div className={styles.loggworksConnectHomeowners2}>
                  Loggworks connect homeowners to genuine and reliable service
                  providers who provide easy-to-book and hassle-free services.
                </div>
              </div>
              <div className={styles.largeButtons3}>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder35.svg"
                />
                <b className={styles.label2}>Contact Us</b>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder35.svg"
                />
              </div>
            </div>
          </div>
          <div className={styles.input} onClick={openDropdown2}>
            <div className={styles.labelContainer}>
              <div className={styles.label4}>Label</div>
            </div>
            <div className={styles.baseField}>
              <img
                className={styles.iconschevronDown2}
                alt=""
                src="/iconschevrondown4.svg"
              />
              <div className={styles.userInput}>Ambassadors</div>
              <div className={styles.dot}>
                <div className={styles.dotChild} />
                <div className={styles.dotChild} />
                <div className={styles.dotChild} />
                <div className={styles.dotChild} />
                <div className={styles.dotChild} />
                <div className={styles.dotChild} />
                <div className={styles.dotChild} />
                <div className={styles.dotChild} />
                <div className={styles.dotChild} />
              </div>
            </div>
            <div className={styles.helperTextContainer}>
              <div className={styles.thisIsA}>This is a helper text</div>
              <div className={styles.div}>1/100</div>
            </div>
          </div>
          <div className={styles.partners20}>
            <div className={styles.mergeGroup}>
              <div className={styles.merge1}>
                <div className={styles.text}>
                  <div className={styles.ambassadors}>Ambassadors</div>
                  <div className={styles.theseCompaniesOr}>
                    Ambassadorial partners are typically individuals who love
                    and want to represent our brand and promotes our products or
                    services to others.
                  </div>
                </div>
                <div className={styles.logos1}>
                  <div className={styles.row1}>
                    <div className={styles.partners2}>
                      <div className={styles.name}>
                        <div className={styles.gusto18}>ZIp Recruiter</div>
                      </div>
                      <div className={styles.logobetterhelp}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logobetterhelp10@2x.png"
                        />
                      </div>
                      <div className={styles.logogusto}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logogusto10@2x.png"
                        />
                      </div>
                      <div className={styles.logojeep}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logojeep10@2x.png"
                        />
                      </div>
                      <div className={styles.logohawaiianPunch}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logohawaiian-punch10@2x.png"
                        />
                      </div>
                      <div className={styles.logoikea}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoikea10@2x.png"
                        />
                      </div>
                      <div className={styles.logoironMan}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoiron-man10@2x.png"
                        />
                      </div>
                      <div className={styles.logoconfluence}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoconfluence10@2x.png"
                        />
                      </div>
                      <div className={styles.logonewYorkRangers}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logonew-york-rangers10@2x.png"
                        />
                      </div>
                      <div className={styles.logozipRecruiter}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logozip-recruiter10@2x.png"
                        />
                      </div>
                    </div>
                    <div className={styles.partners2}>
                      <div className={styles.name}>
                        <div className={styles.gusto18}>New Yor Rangers</div>
                      </div>
                      <div className={styles.logobetterhelp}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logobetterhelp11@2x.png"
                        />
                      </div>
                      <div className={styles.logogusto}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logogusto11@2x.png"
                        />
                      </div>
                      <div className={styles.logojeep}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logojeep11@2x.png"
                        />
                      </div>
                      <div className={styles.logohawaiianPunch}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logohawaiian-punch11@2x.png"
                        />
                      </div>
                      <div className={styles.logoikea}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoikea11@2x.png"
                        />
                      </div>
                      <div className={styles.logoironMan}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoiron-man11@2x.png"
                        />
                      </div>
                      <div className={styles.logoconfluence}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoconfluence11@2x.png"
                        />
                      </div>
                      <div className={styles.logonewYorkRangers2}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logonew-york-rangers11@2x.png"
                        />
                      </div>
                      <div className={styles.logozipRecruiter1}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logozip-recruiter11@2x.png"
                        />
                      </div>
                    </div>
                    <div className={styles.partners2}>
                      <div className={styles.name}>
                        <div className={styles.gusto18}>Confluence</div>
                      </div>
                      <div className={styles.logobetterhelp}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logobetterhelp12@2x.png"
                        />
                      </div>
                      <div className={styles.logogusto}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logogusto12@2x.png"
                        />
                      </div>
                      <div className={styles.logojeep}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logojeep12@2x.png"
                        />
                      </div>
                      <div className={styles.logohawaiianPunch}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logohawaiian-punch12@2x.png"
                        />
                      </div>
                      <div className={styles.logoikea}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoikea12@2x.png"
                        />
                      </div>
                      <div className={styles.logoironMan}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoiron-man12@2x.png"
                        />
                      </div>
                      <div className={styles.logoconfluence2}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoconfluence12@2x.png"
                        />
                      </div>
                      <div className={styles.logonewYorkRangers}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logonew-york-rangers12@2x.png"
                        />
                      </div>
                      <div className={styles.logozipRecruiter1}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logozip-recruiter12@2x.png"
                        />
                      </div>
                    </div>
                    <div className={styles.partners2}>
                      <div className={styles.name}>
                        <div className={styles.gusto18}>Iron Man</div>
                      </div>
                      <div className={styles.logobetterhelp}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logobetterhelp13@2x.png"
                        />
                      </div>
                      <div className={styles.logogusto}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logogusto13@2x.png"
                        />
                      </div>
                      <div className={styles.logojeep}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logojeep13@2x.png"
                        />
                      </div>
                      <div className={styles.logohawaiianPunch}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logohawaiian-punch13@2x.png"
                        />
                      </div>
                      <div className={styles.logoikea}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoikea13@2x.png"
                        />
                      </div>
                      <div className={styles.logoironMan3}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoiron-man13@2x.png"
                        />
                      </div>
                      <div className={styles.logoconfluence}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoconfluence13@2x.png"
                        />
                      </div>
                      <div className={styles.logonewYorkRangers}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logonew-york-rangers13@2x.png"
                        />
                      </div>
                      <div className={styles.logozipRecruiter1}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logozip-recruiter13@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.row1}>
                    <div className={styles.partners2}>
                      <div className={styles.name}>
                        <div className={styles.gusto18}>Ikea</div>
                      </div>
                      <div className={styles.logobetterhelp}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logobetterhelp14@2x.png"
                        />
                      </div>
                      <div className={styles.logogusto}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logogusto14@2x.png"
                        />
                      </div>
                      <div className={styles.logojeep}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logojeep14@2x.png"
                        />
                      </div>
                      <div className={styles.logohawaiianPunch}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logohawaiian-punch14@2x.png"
                        />
                      </div>
                      <div className={styles.logoikea4}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoikea14@2x.png"
                        />
                      </div>
                      <div className={styles.logoironMan}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoiron-man14@2x.png"
                        />
                      </div>
                      <div className={styles.logoconfluence}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoconfluence14@2x.png"
                        />
                      </div>
                      <div className={styles.logonewYorkRangers}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logonew-york-rangers14@2x.png"
                        />
                      </div>
                      <div className={styles.logozipRecruiter1}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logozip-recruiter14@2x.png"
                        />
                      </div>
                    </div>
                    <div className={styles.partners2}>
                      <div className={styles.name}>
                        <div className={styles.gusto18}>Hawaiian Punch</div>
                      </div>
                      <div className={styles.logobetterhelp}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logobetterhelp15@2x.png"
                        />
                      </div>
                      <div className={styles.logogusto}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logogusto15@2x.png"
                        />
                      </div>
                      <div className={styles.logojeep}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logojeep15@2x.png"
                        />
                      </div>
                      <div className={styles.logohawaiianPunch5}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logohawaiian-punch15@2x.png"
                        />
                      </div>
                      <div className={styles.logoikea}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoikea15@2x.png"
                        />
                      </div>
                      <div className={styles.logoironMan}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoiron-man15@2x.png"
                        />
                      </div>
                      <div className={styles.logoconfluence}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoconfluence15@2x.png"
                        />
                      </div>
                      <div className={styles.logonewYorkRangers}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logonew-york-rangers15@2x.png"
                        />
                      </div>
                      <div className={styles.logozipRecruiter1}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logozip-recruiter15@2x.png"
                        />
                      </div>
                    </div>
                    <div className={styles.partners2}>
                      <div className={styles.name}>
                        <div className={styles.gusto18}>Jeep</div>
                      </div>
                      <div className={styles.logobetterhelp}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logobetterhelp16@2x.png"
                        />
                      </div>
                      <div className={styles.logogusto}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logogusto16@2x.png"
                        />
                      </div>
                      <div className={styles.logojeep6}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logojeep16@2x.png"
                        />
                      </div>
                      <div className={styles.logohawaiianPunch}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logohawaiian-punch16@2x.png"
                        />
                      </div>
                      <div className={styles.logoikea}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoikea16@2x.png"
                        />
                      </div>
                      <div className={styles.logoironMan}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoiron-man16@2x.png"
                        />
                      </div>
                      <div className={styles.logoconfluence}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoconfluence16@2x.png"
                        />
                      </div>
                      <div className={styles.logonewYorkRangers}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logonew-york-rangers16@2x.png"
                        />
                      </div>
                      <div className={styles.logozipRecruiter1}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logozip-recruiter16@2x.png"
                        />
                      </div>
                    </div>
                    <div className={styles.partners2}>
                      <div className={styles.name}>
                        <div className={styles.gusto18}>Gusto</div>
                      </div>
                      <div className={styles.logobetterhelp}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logobetterhelp17@2x.png"
                        />
                      </div>
                      <div className={styles.logogusto7}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logogusto17@2x.png"
                        />
                      </div>
                      <div className={styles.logojeep}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logojeep17@2x.png"
                        />
                      </div>
                      <div className={styles.logohawaiianPunch}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logohawaiian-punch17@2x.png"
                        />
                      </div>
                      <div className={styles.logoikea}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoikea17@2x.png"
                        />
                      </div>
                      <div className={styles.logoironMan}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoiron-man17@2x.png"
                        />
                      </div>
                      <div className={styles.logoconfluence}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoconfluence17@2x.png"
                        />
                      </div>
                      <div className={styles.logonewYorkRangers}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logonew-york-rangers17@2x.png"
                        />
                      </div>
                      <div className={styles.logozipRecruiter1}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logozip-recruiter17@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.row3}>
                    <div className={styles.partners2}>
                      <div className={styles.name}>
                        <div className={styles.gusto18}>Betterhelp</div>
                      </div>
                      <div className={styles.logobetterhelp8}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logobetterhelp18@2x.png"
                        />
                      </div>
                      <div className={styles.logogusto}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logogusto18@2x.png"
                        />
                      </div>
                      <div className={styles.logojeep}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logojeep18@2x.png"
                        />
                      </div>
                      <div className={styles.logohawaiianPunch}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logohawaiian-punch18@2x.png"
                        />
                      </div>
                      <div className={styles.logoikea}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoikea18@2x.png"
                        />
                      </div>
                      <div className={styles.logoironMan}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoiron-man18@2x.png"
                        />
                      </div>
                      <div className={styles.logoconfluence}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logoconfluence18@2x.png"
                        />
                      </div>
                      <div className={styles.logonewYorkRangers}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logonew-york-rangers18@2x.png"
                        />
                      </div>
                      <div className={styles.logozipRecruiter1}>
                        <img
                          className={styles.logobetterhelpIcon}
                          alt=""
                          src="/logozip-recruiter18@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img className={styles.imgIcon1} alt="" src="/img27@2x.png" />
            </div>
          </div>
          <div className={styles.homeMobileChild}>
            <div className={styles.frameParent4}>
              <div className={styles.ourAmbassadorialPartnersParent}>
                <div className={styles.ambassadors}>
                  Our Ambassadorial partners
                </div>
                <div className={styles.loggworksConnectHomeowners1}>
                  Loggworks connect homeowners to genuine and reliable service
                  providers who provide easy-to-book and hassle-free services.
                </div>
              </div>
              <div className={styles.frameParent5}>
                <div className={styles.partnersParent}>
                  <div className={styles.partners11}>
                    <div className={styles.name9}>
                      <div className={styles.gusto}>ZIp Recruiter</div>
                    </div>
                    <div className={styles.logobetterhelp9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logobetterhelp9@2x.png"
                      />
                    </div>
                    <div className={styles.logogusto9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logogusto9@2x.png"
                      />
                    </div>
                    <div className={styles.logojeep9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logojeep9@2x.png"
                      />
                    </div>
                    <div className={styles.logohawaiianPunch9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logohawaiian-punch9@2x.png"
                      />
                    </div>
                    <div className={styles.logoikea9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logoikea9@2x.png"
                      />
                    </div>
                    <div className={styles.logoironMan9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logoiron-man9@2x.png"
                      />
                    </div>
                    <div className={styles.logoconfluence9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logoconfluence9@2x.png"
                      />
                    </div>
                    <div className={styles.logonewYorkRangers18}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logonew-york-rangers9@2x.png"
                      />
                    </div>
                    <div className={styles.logozipRecruiter9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logozip-recruiter9@2x.png"
                      />
                    </div>
                  </div>
                  <div className={styles.partners11}>
                    <div className={styles.name9}>
                      <div className={styles.gusto}>New Yor Rangers</div>
                    </div>
                    <div className={styles.logobetterhelp9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logobetterhelp9@2x.png"
                      />
                    </div>
                    <div className={styles.logogusto9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logogusto9@2x.png"
                      />
                    </div>
                    <div className={styles.logojeep9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logojeep9@2x.png"
                      />
                    </div>
                    <div className={styles.logohawaiianPunch9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logohawaiian-punch9@2x.png"
                      />
                    </div>
                    <div className={styles.logoikea9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logoikea9@2x.png"
                      />
                    </div>
                    <div className={styles.logoironMan9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logoiron-man9@2x.png"
                      />
                    </div>
                    <div className={styles.logoconfluence9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logoconfluence9@2x.png"
                      />
                    </div>
                    <div className={styles.logonewYorkRangers20}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logonew-york-rangers9@2x.png"
                      />
                    </div>
                    <div className={styles.logozipRecruiter10}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logozip-recruiter9@2x.png"
                      />
                    </div>
                  </div>
                  <div className={styles.partners11}>
                    <div className={styles.name9}>
                      <div className={styles.gusto}>Confluence</div>
                    </div>
                    <div className={styles.logobetterhelp9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logobetterhelp9@2x.png"
                      />
                    </div>
                    <div className={styles.logogusto9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logogusto9@2x.png"
                      />
                    </div>
                    <div className={styles.logojeep9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logojeep9@2x.png"
                      />
                    </div>
                    <div className={styles.logohawaiianPunch9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logohawaiian-punch9@2x.png"
                      />
                    </div>
                    <div className={styles.logoikea9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logoikea9@2x.png"
                      />
                    </div>
                    <div className={styles.logoironMan9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logoiron-man9@2x.png"
                      />
                    </div>
                    <div className={styles.logoconfluence11}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logoconfluence9@2x.png"
                      />
                    </div>
                    <div className={styles.logonewYorkRangers18}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logonew-york-rangers9@2x.png"
                      />
                    </div>
                    <div className={styles.logozipRecruiter10}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logozip-recruiter9@2x.png"
                      />
                    </div>
                  </div>
                  <div className={styles.partners11}>
                    <div className={styles.name9}>
                      <div className={styles.gusto}>Iron Man</div>
                    </div>
                    <div className={styles.logobetterhelp9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logobetterhelp9@2x.png"
                      />
                    </div>
                    <div className={styles.logogusto9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logogusto9@2x.png"
                      />
                    </div>
                    <div className={styles.logojeep9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logojeep9@2x.png"
                      />
                    </div>
                    <div className={styles.logohawaiianPunch9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logohawaiian-punch9@2x.png"
                      />
                    </div>
                    <div className={styles.logoikea9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logoikea9@2x.png"
                      />
                    </div>
                    <div className={styles.logoironMan12}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logoiron-man9@2x.png"
                      />
                    </div>
                    <div className={styles.logoconfluence9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logoconfluence9@2x.png"
                      />
                    </div>
                    <div className={styles.logonewYorkRangers18}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logonew-york-rangers9@2x.png"
                      />
                    </div>
                    <div className={styles.logozipRecruiter10}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logozip-recruiter9@2x.png"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.mobileFooterParent}>
                  <div className={styles.partners11}>
                    <div className={styles.name9}>
                      <div className={styles.gusto}>Darlene Robertson</div>
                    </div>
                    <img
                      className={styles.avatarIcon}
                      alt=""
                      src="/avatar4.svg"
                    />
                  </div>
                  <div className={styles.partners11}>
                    <div className={styles.name9}>
                      <div className={styles.gusto}>Dianne Russell</div>
                    </div>
                    <img
                      className={styles.avatarIcon}
                      alt=""
                      src="/avatar5.svg"
                    />
                  </div>
                  <div className={styles.partners11}>
                    <div className={styles.name9}>
                      <div className={styles.gusto}>Guy Hawkins</div>
                    </div>
                    <img
                      className={styles.avatarIcon}
                      alt=""
                      src="/avatar6.svg"
                    />
                  </div>
                  <div className={styles.partners11}>
                    <div className={styles.name9}>
                      <div className={styles.gusto}>Jacob Jones</div>
                    </div>
                    <img
                      className={styles.avatarIcon}
                      alt=""
                      src="/avatar7.svg"
                    />
                  </div>
                </div>
                <div className={styles.partnersWrapper}>
                  <div className={styles.partners11}>
                    <div className={styles.name9}>
                      <div className={styles.gusto}>Betterhelp</div>
                    </div>
                    <div className={styles.logobetterhelp13}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logobetterhelp9@2x.png"
                      />
                    </div>
                    <div className={styles.logogusto9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logogusto9@2x.png"
                      />
                    </div>
                    <div className={styles.logojeep9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logojeep9@2x.png"
                      />
                    </div>
                    <div className={styles.logohawaiianPunch9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logohawaiian-punch9@2x.png"
                      />
                    </div>
                    <div className={styles.logoikea9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logoikea9@2x.png"
                      />
                    </div>
                    <div className={styles.logoironMan9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logoiron-man9@2x.png"
                      />
                    </div>
                    <div className={styles.logoconfluence9}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logoconfluence9@2x.png"
                      />
                    </div>
                    <div className={styles.logonewYorkRangers18}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logonew-york-rangers9@2x.png"
                      />
                    </div>
                    <div className={styles.logozipRecruiter10}>
                      <img
                        className={styles.logobetterhelpIcon}
                        alt=""
                        src="/logozip-recruiter9@2x.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MobileFooter/>
        </div>
      </div>
      {isMobileMenuConsumerPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuConsumerPopup}
        >
          <MobileMenuConsumer1 onClose={closeMobileMenuConsumerPopup} />
        </PortalPopup>
      )}
      {isDropdown2Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDropdown2}
        >
          <Dropdown3 onClose={closeDropdown2} />
        </PortalPopup>
      )}
    </>
  );
};

export default WeAchieve4;
