import {
  AdjustmentsHorizontalIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  StarIcon,
} from "@heroicons/react/24/outline";
import Promotions from "./searchComponents/promotion.tsx";
import DealsOfTheDay from "./searchComponents/dealsOfDay.tsx";
import OthersResults from "./searchComponents/others.tsx";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase.js";
import { Avatar } from "@mui/material";
import StarReview from "./cards/starReview.tsx";
const Search = ({width}) => {
  const { postcode, category,subcate} = useParams();
  
  const [lastDoc, setLastDoc] = useState(null);
  const [lastDoc2, setLastDoc2] = useState(null);
  const [lastDoc3, setLastDoc3] = useState(null);

  const handleNewLastDoc = (newLastDoc:any) => {
    setLastDoc(newLastDoc);
  };
  const handleNewLastDoc2 = (newLastDoc:any) => {
    setLastDoc2(newLastDoc);
  };
  const handleNewLastDoc3 = (newLastDoc:any) => {
    setLastDoc3(newLastDoc);
  };
  const filterOptions = [
    {
      name: "star rating",
      options: [5, 4, 3, 2, 1],
    },
    {
      name: "distance",
      options: [5, 10, 20, 60, 80, 100],
    },
   
  ];
  const [sortOption, setSortOption] = useState("relevance");
  const [optionCategory, setOptionCategory] = useState(0);
  const [optionIndex, setOptionIndex] = useState<any>(0);
  const [openSortBox, setOpenSortBox] = useState(false);

  const [openFilter, setOpenFilter] = useState(false);
  const [fitercate, setFilterCate] = useState<any[]>([]);
  const [openOptionCategory, setOpenOptionCategory] = useState(
    Array(filterOptions.length).fill(true)
  );
  const handleSelected = (option: string, optionIndex: number) => {
    console.log(option);
    setSortOption(option);

    setOptionIndex(optionIndex);
  };

  const handleFiltersSelected = (
    categoryIndex: number,
    optionIndex: number
  ) => {
    if (categoryIndex >= 0) {
      setOpenOptionCategory((prevOpen) =>
        prevOpen.map((open, i) => (i === categoryIndex ? !open : open))
      );
    }
  };

  const options = ["relevance", "distance", "most Reviewed", "ratings"];
  const [profile, setProf] = useState<any[]>([]);
  const [deals, setDeals] = useState<any[]>([]);
  const [promo, setPromo] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [profileImages, setProfileImages] = useState<string[]>([]);
  const [gal, setGal] = useState<string[]>([]);
  const [cate, setCate] = useState<string[]>([]);
  const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [combinedData, setCombinedData] = useState([]);
 const [miles,setMiles] =useState(5)
  const [stars,setStars] =useState(5)
  const [old,setOld]= useState<string>('')
  const [currentloc, setCurrentLoc] = useState<{ lat: number; lng: number } | null>(null);
  const Goto = useNavigate()

  // Function to fetch deals from Firestore
  const fetchProf = async () => {
    try {
      if (!userLocation) return;
     

      const dealsRef = db.collection("users");
  
      // Create a geohash or use Haversine formula to calculate distance
      const maxDistanceMiles = 8;
  
      const querySnapshot = await dealsRef.get();
  
      const newDeals = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      // Convert category to lowercase for case-insensitive comparison
      const lowercaseCategory = category?.toLowerCase();

      // Calculate distance and filter deals within the specified distance and category
      const filteredDeals = newDeals.filter((deal:any) => {
        const distance = calculateDistance(userLocation, {
          lat: deal.lat,
          lng: deal.lng,
        });
        console.log(distance)
        // Perform case-insensitive comparison
        if (subcate!='all') {
          return (
            distance <= miles &&
            deal.category?.toLowerCase() === lowercaseCategory &&
            deal.sub_cate?.toLowerCase() === subcate.toLowerCase() &&
            deal.verification >= 35
          );
        } else {
          return (
            distance <= miles &&
            deal.category?.toLowerCase() === lowercaseCategory &&
            deal.verification >= 35
          );
        }
      });

      const categoryRef = db.collection("categories").get();
      const tagRef = db.collection("serviceTags").get();
      const cateSnap=(await categoryRef).docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      const cateSnap2=(await tagRef).docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      const catefiltered:any = []
      cateSnap.forEach((sub) => {
        if (sub.id?.toLowerCase() === lowercaseCategory) {
          catefiltered.push(sub);
        }
      });
      
      cateSnap2.forEach((sub:any) => {
        if (sub.id?.toLowerCase() === lowercaseCategory) {
          const existingItemIndex = catefiltered.findIndex((item:any) => item.id.toLowerCase() === lowercaseCategory);
          if (existingItemIndex !== -1) {
            sub.subcategories.map((x)=>{
              catefiltered[existingItemIndex].subcategories.push(x);
            })
          } else {
            catefiltered.push(sub);
          }
        }
      });
      setFilterCate(catefiltered)

      setProf(filteredDeals);
    } catch (error) {
      console.error("Error fetching deals: ", error);
    }
  };
  
  const fetchDeals = async () => {
    try {
      if (!userLocation) return;
      const dealsRef = db.collection("promo");

      // Create a geohash or use Haversine formula to calculate distance
      const maxDistanceMiles = 5;
      // You can convert maxDistanceMiles to the appropriate unit used in your database (e.g., kilometers)

      // Query Firestore for deals
      const querySnapshot = await dealsRef
        .where("type", "==", "Deal of the day")
        .get();

        const newDeals = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() })) // Include the 'id' in the deal object
        .filter((deal:any) => {
          // Calculate the distance between the user and the deal using geohash or Haversine
          const distance = calculateDistance(userLocation, {
            lat: deal.lat,
            lng: deal.lng,
          });
          
          // Filter deals within the specified distance
          return distance <= miles;
        })
        .slice(0, 6); // Load the initial 6 deals

      setDeals(newDeals);
    } catch (error) {
      console.error("Error fetching deals: ", error);
    }
  };
  const fetchPromo = async () => {
    try {
      if (!userLocation) return;
      const dealsRef = db.collection("promo");

      // Create a geohash or use Haversine formula to calculate distance
      const maxDistanceMiles = 5;
      // You can convert maxDistanceMiles to the appropriate unit used in your database (e.g., kilometers)

      // Query Firestore for deals
      const querySnapshot = await dealsRef
        .where("type", "==", "My Profile")
        .get();

      const newDeals = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((deal:any) => {
          // Calculate the distance between the user and the deal using geohash or Haversine
          const distance = calculateDistance(userLocation, {
            lat: deal.lat,
            lng: deal.lng,
          });
          
          // Filter deals within the specified distance
          return distance <= miles;
        })
        .slice(0, 6); // Load the initial 6 deals

      setPromo(newDeals);
     
    } catch (error) {
      console.error("Error fetching deals: ", error);
    }
  };
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLoc({lat:latitude,lng:longitude})
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    }
    if (!userLocation) {
      postcodeToLatLng(postcode)
      return
    }
   
    // Fetch deals when the component mounts or when userLocation changes
    const fetchData = async () => {
      try {
      
        // Use Promise.all to wait for all asynchronous functions to resolve
        await Promise.all([fetchDeals(), fetchProf(), fetchPromo()]);
       
        console.log('All data fetched successfully!');
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };
    if(profile.length==0){ 
      console.log(profile.length)
      fetchData()
    }
   
  }, [currentloc]);
  
  const [distance, setDistance] = useState<any>(null);



  const getGal = async (id:any) => {
    try {
      const galleryRef = db.collection('users').doc(id).collection('gallery');
      const snapshot = await galleryRef.get();
      
      const images:any[] = [];
      snapshot.forEach((doc) => {
        images.push(doc.data().imageUrl);
      });
  
      // Assuming the first image in the gallery is the profile image
      const profileImageUrl = images.length > 0 ? images[0] : ''; 

      console.log(profileImageUrl)
      setLoading(false)
      return profileImageUrl;
    } catch (error) {
      console.error('Error fetching profile image: ', error);
      return ''; // Returning an empty string as a default value
    }
  };
  const getProfile = async (id:any) => {
    try {
      const snapshot = await db.collection('users').doc(id).get();
      return snapshot.data()?.profileImage;
    } catch (error) {
      console.error('Error fetching prof ile image: ', error);
      // You can return a default image or handle the error as needed
      return ''; // Returning an empty string as a default value
    }
  }
const getCate = async (id:any) => {
  try {
    const snapshot = await db.collection('users').doc(id).get();
    return snapshot.data()?.sub_cate;
  } catch (error) {
    console.error('Error fetching profile image: ', error);
    // You can return a default image or handle the error as needed
    return ''; // Returning an empty string as a default value
  }
}
useEffect(() => {
  const combined = [];

const addType = (arr:any, type:any) => arr.map((item:any) => ({ ...item, type }));

for (let i = 0; i < profile.length; i += 4) {
  combined.push(...addType(profile.slice(i, i + 4), 'profile'));
  combined.push(...addType(deals.slice(i, i + 4), 'deals'));
  combined.push(...addType(promo.slice(i, i + 4), 'promo'));
}

// Remove duplicates based on 'id'
const uniqueCombined = combined.reduce((acc, item) => {
  if (!acc.find((existingItem:any) => existingItem.id === item.id)) {
    acc.push(item);
  }
  return acc;
}, []);


  console.log("Combined:", combined);
  setCombinedData(uniqueCombined);
}, [profile]);
  // Calculate the distance between two coordinates using the Haversine formula
  const calculateDistance = (coord1: { lat: number; lng: number }, coord2: { lat: number; lng: number }) => {
    const radiusOfEarthMiles = 3958.8; // Approximate radius of the Earth in miles
    const lat1 = toRadians(coord1.lat);
    const lat2 = toRadians(coord2.lat);
    const deltaLat = toRadians(coord2.lat - coord1.lat);
    const deltaLng = toRadians(coord2.lng - coord1.lng);

    const a =
      Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
   
    return radiusOfEarthMiles * c;
  };

  // Convert degrees to radians
  const toRadians = (degrees: number) => {
    return degrees * (Math.PI / 180);
  };
  function truncateText(text:any, maxLength:any) {
    if (text.length <= maxLength) {
      return text; // No need to truncate if it's shorter than the max length
    } else {
      return text.slice(0, maxLength) + "..."; // Truncate and add ellipsis
    }
  }
  async function postcodeToLatLng(postcode:any) {
    // Replace 'YOUR_API_KEY' with your actual Google Maps API key
    const apiKey = 'AIzaSyDrIc0MDLHaoFOaSLFKPRcGG-bM3Xpt-w0';
  
    // Construct the geocoding API URL
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      postcode
    )}&key=${apiKey}`;
  
    try {
      // Fetch data from the API
      const response = await fetch(apiUrl);
      const data = await response.json();
  
      // Check if the API request was successful
      if (data.status === 'OK' && data.results.length > 0) {
        // Extract lat/lng from the first result
        const location = data.results[0].geometry.location;
        const lat = location.lat;
        const lng = location.lng;
        setUserLocation({ lat: lat, lng: lng });
      } else {
        // Handle API errors or invalid postcode
        console.error('Geocoding API error:', data.status);
      }
    } catch (error) {
      console.error('Error fetching geocoding data:', error);
    }
  }

  return (
    <div className="flex " style={{width:width}}>
     <>
          <div className="main col-span-9 bg-white relative md:mb-16 md:px-10 px-3" style={width=='1440px'?{width:'90%'}:{width:'100%'}}>
          <div className="mobile_filter md:hidden flex items-center justify-between text-[16px]">
              <div
                className="flex items-center "
                onClick={() => {
                  setOpenFilter(!openFilter), setOpenSortBox(false);
                }}
              >
                <AdjustmentsHorizontalIcon width={20} height={20} />
                <p className="ml-1">Filter</p>
              </div>

              <div
                className="border border-grey rounded-md px-4 py-1 flex items-center"
                onClick={() => {
                  setOpenSortBox(!openSortBox), setOpenFilter(false);
                }}
              >
                <p>Sort</p>
                <ChevronDownIcon width={20} height={20} />
              </div>
            </div>

            <div className="mobile_options md:hidden">
              {openFilter && (
                <div className="">
                  <div className="absolute bg-white top-4 left-0 w-full z-50 all_promotions overflow-y-scroll h-[calc(90vh-4rem)] px-4 mt-7">
                    {filterOptions.map((category, categoryIndex) => (
                      <div className="rating" key={`sorting-${categoryIndex}`}>
                        <div
                          className="head flex items-center justify-between cursor-pointer"
                          onClick={() => {
                            handleFiltersSelected(categoryIndex, -1);
                          }}
                        >
                          <h1 className="font-bold text-[16px] capitalize mt-4">
                            {category.name}
                          </h1>
                          <ChevronDownIcon width={24} height={24} />
                        </div>
                        {openOptionCategory[categoryIndex] && (
                          <div className="sortOptions">
                            <div className="option my-3">
                              <ul>
                                {category.options.map((x: any, i: number) => (
                                  <li
                                    className="flex items-center mb-3 capitalize"
                                    key={i}
                                    onClick={() => {
                                      // handleSelected(x);
                                      setOptionIndex(i);
                                      if (category.name=='distance') {
                                        setMiles(x)
                                        Promise.all([fetchDeals(), fetchProf(), fetchPromo()]);
                                      }else if(category.name=='star rating'){
                                        Promise.all([fetchDeals(), fetchProf(), fetchPromo()]);
                                        setStars(x)
                                      }
                                    }}
                                  >
                                    <label className="w-[24px] relative h-[24px] border border-grey bg-[#DFDFDF] rounded flex items-center justify-center">
                                      <input
                                        type="checkbox"
                                        name=""
                                        id=""
                                        className="form-checkbox-square w-[24px] h-[24px] rounded"
                                        checked={category.name === "distance"?x==miles?true:false:category.name=='star rating'?x==stars?true:false:false}
                                      />
                                    </label>
                                    <p className="ml-3 mr-1 text-[18px]">{x}</p>
                                    {category.name === "distance" && (
                                      <p>miles</p>
                                    )}
                                    {category.name === "star rating" && (
                                      <>
                                        <p className="mr-2">Star</p>

                                        <div className="flex items-center">
                                          {Array(5)
                                            .fill("")
                                            .reverse()
                                            .map((x, i) => (
                                              <div
                                                className="text-green  mr-1"
                                                key={i}
                                              >
                                                <StarIcon
                                                  width={16}
                                                  height={16}
                                                />
                                              </div>
                                            ))}
                                        </div>
                                      </>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    {fitercate.map((category, categoryIndex) => (
                  <div className="rating" key={`sorting-${categoryIndex}`}>
                    <div
                      className="head flex items-center justify-between cursor-pointer"
                      onClick={() => {
                        handleFiltersSelected(categoryIndex, -1);
                      }}
                    >
                      <h1 className="font-bold text-[16px] capitalize py-2">
                        {category.id}
                      </h1>
                      <ChevronDownIcon width={24} height={24} />
                    </div>
                    {openOptionCategory[categoryIndex] && (
                      <div className="sortOptions">
                        <div className="option my-3">
                          <ul>
                            {category.subcategories.map((x: any, i: number) => (
                              <li
                                className="flex items-center text-[18px] mb-3 capitalize"
                                key={i}
                                onClick={() => {
                                  // handleSelected(x);
                                  setOptionIndex(i);
                                  Goto('/search/'+postcode+'/'+category.id+'/'+x.toLowerCase())
                                }}
                              >
                                <label className="w-[24px] relative h-[24px] border border-grey bg-[#DFDFDF] rounded flex items-center justify-center">
                                  <input
                                    type="checkbox"
                                    name=""
                                    id=""
                                    className="form-checkbox-square w-[24px] h-[24px] rounded"
                                    checked={subcate&&x.toLowerCase()==subcate.toLowerCase()}
                                  />
                                </label>
                                <p className="ml-3 mr-1 text-[18px]">{x}</p>
                               
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                  </div>
                </div>
                
              )}
 
              {openSortBox && (
                <div className="sortOptions">
                  <div className="option ml-5 mt-4">
                    <ul>
                      {options.map((x: any, i: number) => (
                        <li
                          className="flex items-center mb-3 capitalize"
                          key={i}
                          onClick={() => {
                            handleSelected(x, i);
                            setOptionIndex(i);
                           
                          }}
                        >
                          <label className="w-[24px] h-[24px] border border-green rounded-full flex items-center justify-center">
                            <input
                              type="checkbox"
                              name=""
                              id=""
                              className="form-checkbox rounded-full "
                              checked={optionIndex === i}
                            />
                          </label>
                          <p className="mx-3 text-[18px]">{x}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>

            <div className="nav_head flex items-center md:py-3 py-3  border-b  border-grey80">
              <div className="flex items-center w-full md:justify-between md:justify-center">
                <h1 className="font-bold capitalize text-[17px] max-md:text-left md:text-[20px] mt-2">
                  {subcate!='all'&&subcate||category} near {postcode}
                </h1>
              </div>
            </div>
            {
              combinedData.length<=0?
              <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "60px",
                textAlign: "center",
                fontSize: "24px",
                color: "#626262",
                fontFamily: "Inter",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "26px",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "400px",
                    height: "400px",
                    overflow: "hidden",
                    flexShrink: "0",
                  }}
                  alt=""
                  src={"/assets/searchamico-1.svg"}
                />
                <div style={{ position: "relative", lineHeight: "100%" }}>
                  <p
                    style={{
                      marginBlockStart: "",
                      marginBlockEnd: "10px",
                      fontWeight: "600",
                    }}
                  >
                    No results found
                  </p>
                  <p style={{ margin: "", fontSize: "14px" }}>
                    We couldn’t find the service professional you’re looking for.
                  </p>
                </div>
              </div>
              <div
                style={{
                  borderRadius: "4px",
                  backgroundColor: "#2fa500",
                  width: "244px",
                  height: "40px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "8px 20px",
                  boxSizing: "border-box",
                  fontSize: "14px",
                  color: "#fff",
                  cursor:'pointer'
                }}
                onClick={()=>{Goto('/postjob')}}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "100%",
                      fontWeight: "600",
                    }}
                    
                  >
                    Post a job
                  </div>
                </div>
              </div>
            </div>
            :null
            }
            <div className="grid grid-cols-9 max-sm:grid-cols-10  gap-5">
            {combinedData.map((data:any,i) => (
                <>
                {data.type === 'profile' && (
                           <div className="col-span-3 max-sm:col-span-5 shadow" key={i}>
                           <div className="card  bg-white my-3 relative">
                     
                       <div className=" h-[140px] relative"
                       onClick={()=>{
                        window.open('https://www.consumer.loggworks.co.uk/user/'+data.id, '_blank')
                       }}>
                  <img
                      key={i}
                      src={loading&&getGal(data.id)}
                      alt=""
                      className="w-full h-full rounded-t-lg object-cover"
                    />
                         </div>
                 
                       <div className="card_content md:p-3 p-2">
                         <div className="title">
                            <div className="flex items-center">
                             <div className="max-sm:hidden">
                               <Avatar
                                 src={data.profileImage}
                                 alt="brand_logo "
                                 className="mr-2 max-sm:hidden rounded-full max-h-[20px] max-w-[20px]"
                               />
                             </div>
                 
                             <h4 className="max-sm:text-[14px] font-bold">{data.org_name}</h4>
                           </div>
                          
                 
                           <div className="flex items-center my-1 md:text-[14px] text-[9px]">
                           <StarReview uid={data.id}/>
                            <Otherinfo uid={data.id}/>
                           </div>
                 
                           <div className="flex items-center mb-2 md:text-[14px] text-[12px]">
                             <h5>{truncateText(data.category, 10)}</h5> <span className="mx-1">|</span>{" "}
                             <h5>{(calculateDistance(userLocation, {
              lat: data.lat,
              lng: data.lng,
            })).toFixed(2)} miles away</h5>
                           </div>
                         </div>
                       </div>
                     </div>
                           </div>
                )}
                </>
                
              ))}
               </div>
               {
               combinedData.length>20?
                <div className="flex justify-center mt-16">
              <button className="mx-auto text-green font-bold py-2 px-8 border-2 border-green rounded mb-4">
                Load More 
              </button>
            </div>
            :null
               }
          </div>
          <div className="max-sm:hidden bg-white md:z-20 py-2 px-3 md:col-span-3 md:-ml-8 relative z-50 h-[2250px]" 
          style={{width:'29%',overflowY:'auto',overflowX:'hidden'}}>
          <div className="w-[292px] py-3">
              <div
                className="head flex items-center justify-between rounded  py-3 px-5 border border-grey80 text-[16px] cursor-pointer"
                onClick={() => setOpenSortBox(!openSortBox)}
              >
                <h1 className="font-bold">Sort By</h1>

                <h1 className="capitalize">{sortOption}</h1>
                <ChevronDownIcon width={24} height={24} />
              </div>

              {openSortBox && (
                <div className="sortOptions">
                  <div className="option ml-5 mt-4">
                    <ul>
                      {options.map((x: any, i: number) => (
                        <li
                          className="flex items-center mb-3 capitalize"
                          key={i}
                          onClick={() => {
                            handleSelected(x, i);
                            setOptionIndex(i);
                          }}
                        >
                          <label className="w-[24px] h-[24px] border border-green rounded-full flex items-center justify-center">
                            <input
                              type="checkbox"
                              name=""
                              id=""
                              className="form-checkbox rounded-full "
                              checked={optionIndex === i}
                            />
                          </label>
                          <p className="mx-3 text-[18px]">{x}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}

              <h1 className="text-[20px] mt-3">Filters</h1>
              <div className="all_promotions md:overflow-y-scroll max-md:flex mt-7">
                {filterOptions.map((category, categoryIndex) => (
                  <div className="rating" key={`sorting-${categoryIndex}`}>
                    <div
                      className="head flex items-center justify-between cursor-pointer"
                      onClick={() => {
                        handleFiltersSelected(categoryIndex, -1);
                      }}
                    >
                      <h1 className="font-bold text-[16px] capitalize py-2">
                        {category.name}
                      </h1>
                      <ChevronDownIcon width={24} height={24} />
                    </div>
                    {openOptionCategory[categoryIndex] && (
                      <div className="sortOptions">
                        <div className="option my-3">
                          <ul>
                            {category.options.map((x: any, i: number) => (
                              <li
                                className="flex items-center text-[18px] mb-3 capitalize"
                                key={i}
                                onClick={() => {
                                  // handleSelected(x);
                                  setOptionIndex(i);
                                  if (category.name=='distance') {
                                    setMiles(x)
                                    Promise.all([fetchDeals(), fetchProf(), fetchPromo()]);
                                  }else if(category.name=='star rating'){
                                    Promise.all([fetchDeals(), fetchProf(), fetchPromo()]);
                                    setStars(x)
                                  }
                                }}
                              >
                                <label className="w-[24px] relative h-[24px] border border-grey bg-[#DFDFDF] rounded flex items-center justify-center">
                                  <input
                                    type="checkbox"
                                    name=""
                                    id=""
                                    className="form-checkbox-square w-[24px] h-[24px] rounded"
                                    checked={category.name === "distance"?x==miles?true:false:category.name=='star rating'?x==stars?true:false:false}
                                  />
                                </label>
                                <p className="ml-3 mr-1 text-[18px]">{x}</p>
                                {category.name === "distance" && <p>miles</p>}
                                {category.name === "star rating" && (
                                  <>
                                    <p className="mr-2">Star</p>

                                    <div className="flex items-center">
                                      {Array(5)
                                        .fill("")
                                        .reverse()
                                        .map((x, i) => (
                                          <div
                                            className="text-green  mr-1"
                                            key={i}
                                          >
                                            <StarIcon width={16} height={16} />
                                          </div>
                                        ))}
                                    </div>
                                  </>
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                 {fitercate.map((category, categoryIndex) => (
                  <div className="rating" key={`sorting-${categoryIndex}`}>
                    <div
                      className="head flex items-center justify-between cursor-pointer"
                      onClick={() => {
                        handleFiltersSelected(categoryIndex, -1);
                      }}
                    >
                      <h1 className="font-bold text-[16px] capitalize py-2">
                        {category.id}
                      </h1>
                      <ChevronDownIcon width={24} height={24} />
                    </div>
                    {openOptionCategory[categoryIndex] && (
                      <div className="sortOptions">
                        <div className="option my-3">
                          <ul>
                            {category.subcategories.map((x: any, i: number) => (
                              <li
                                className="flex items-center text-[18px] mb-3 capitalize"
                                key={i}
                                onClick={() => {
                                  // handleSelected(x);
                                  setOptionIndex(i);
                                  Goto('/search/'+postcode+'/'+category.id+'/'+x.toLowerCase())
                                }}
                              >
                                <label className="w-[24px] relative h-[24px] border border-grey bg-[#DFDFDF] rounded flex items-center justify-center">
                                  <input
                                    type="checkbox"
                                    name=""
                                    id=""
                                    className="form-checkbox-square w-[24px] h-[24px] rounded"
                                    checked={subcate&&x.toLowerCase()==subcate.toLowerCase()}
                                  />
                                </label>
                                <p className="ml-3 mr-1 text-[18px]">{x}</p>
                               
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                <div className="bg-[#F6F6F6] py-10 px-10 rounded-lg my-10">
                  <h1 className="text-[20px] font-semibold">
                    Do you have a professional service to offer?
                  </h1>
                  <p className="text-[16px] my-3 text-grey20">
                    Post a job with your price in mind, then get offers
                  </p>
                  <button className="bg-green text-white w-full h-[32px] text-[14px] font-bold px-4 rounded">
                    Become a Professional
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
        </div>
  );
};

export default Search;

const Otherinfo: React.FC<{uid:any}>=({uid})=>{
  const [totalstar,setTotal]=useState(0)
  const [rating,setRating]=useState(0)
  useEffect(() => {
    db.collection("reviews")
    .where("uid", "==", uid)
    .get()
    .then((querySnapshot) => {
      let totalRating = 0;
      let numberOfReviews = querySnapshot.size;
     

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // Assuming 'stars' is the field containing the star rating in each document
        const rating = data.stars;

       
        totalRating += rating;
      });
      setTotal(numberOfReviews)
      if (numberOfReviews > 0) {
        console.log(totalRating)
        const avgRating = totalRating / numberOfReviews;
        setRating(Math.round(avgRating));
      }
    })
  }, [uid])
  
return(
  <>
 <p className="mx-1">{rating.toFixed(1)}</p>
                  <p>({totalstar})</p>
  </>
)
}