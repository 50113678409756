
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Testimony.module.css";

const TestimonyProf = () => {
  const isDesktop = window.innerWidth > 970;
  const testimonies = [
    {
      name: "Benjamin Reynolds",
      cate:'Handyman',
      text: "Loggworks is a game-changer for my business; I only pay commissions on completed jobs, which significantly reduces my upfront costs."
    },
    {
      name: "James Wilson",
      cate:'Gas Engineer',
      text: "The free business tools provided by Loggworks are invaluable; they streamline operations and help me manage my business efficiently."
    },
    {
      name: "David Smith",
      cate:'Cleaner',
      text: "The automated job management feature simplifies scheduling and coordination, saving me time and ensuring smooth workflow."
    },
    {
      name: "Ethan Taylor",
      cate:'Plumber',
      text: "The automated reminder feature is a lifesaver; it ensures that appointments are not missed, keeping my clients satisfied and my reputation intact."
    },
    {
      name: "Samuel Wright",
      cate:'Electrician',
      text: "I appreciate the free business promotion offered by Loggworks; it helps me reach a wider audience and attract more customers."
    },
    {
      name: "Vikram Patel",
      cate:'Cleaner',
      text: "Having a free business profile listing on Loggworks boosts my visibility and credibility in the market, leading to increased inquiries and bookings."
    },
    {
      name: "Devendra Patel",
      cate:'Cleaner',
      text: "One of the best perks of using Loggworks is the free vetting of professionals; it gives me peace of mind knowing that I'm working with trustworthy partners."
    },
    {
      name: "Kwame Asante",
      cate:'Cleaner',
      text: "Loggworks' commission-based model aligns with my business goals, allowing me to focus on delivering quality service without worrying about fixed costs."
    },
    {
      name: "Oluwaseun Adeboye",
      cate:'Painter',
      text: "The platform's user-friendly interface makes it easy to navigate and manage my business, even for someone with minimal technical skills."
    },
    {
      name: "Fatima Bah",
      cate:'Cleaner',
      text: "Overall, Loggworks has revolutionized the way I run my home service business; the benefits it offers have significantly contributed to my success and growth."
    },
  ];

  // Custom previous arrow component
  const CustomPrevArrow = (props) => (
    <div
      className="custom-prev-arrow"
      onClick={props.onClick}
      style={{ zIndex: 10, position: "absolute", left: 0, top: "50%", transform: "translateY(-50%)" }}
    >
      {/* Your custom previous arrow icon or image */}
      <img src="/group-1000005188.svg" alt="Previous" style={{ width: isDesktop ? "56px":'30px', height: isDesktop ? "56px":'30px' }} />
    </div>
  );

  // Custom next arrow component
  const CustomNextArrow = (props) => (
    <div
      className="custom-next-arrow"
      onClick={props.onClick}
      style={{ zIndex: 10, position: "absolute", right: 0, top: "50%", transform: "translateY(-50%)" }}
    >
      {/* Your custom next arrow icon or image */}
      <img src="/group-1000005188.svg" alt="Next" style={{ width: isDesktop ? "56px":'30px', height: isDesktop ? "56px":'30px', transform: "rotate(180deg)" }} />
    </div>
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />
  };

  
  return (
    <div
    className={styles.testimony}
      style={{
        borderRadius: "5px",
        margin:isDesktop &&'5% 0px',
        boxSizing: "border-box",
        overflow: "hidden",
        textAlign: "center",
        fontSize: "24px",
        color: "rgba(46, 46, 46, 0.9)",
        fontFamily: "Inter",
        height:isDesktop ?'400px':'500px'
      }}
    >
    
     <div
        style={{
          position: isDesktop &&"absolute",
          top: "16px",
          left: isDesktop ?"5%":'',
          right:isDesktop ?'5%':'',
          borderRadius: "8px",
          backgroundColor: "#edf9e6",
          width: isDesktop ? "" : "100%",
          height: isDesktop ?"466px":'500px',
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: isDesktop ?"134px 81px":'2rem',
          boxSizing: "border-box",
          gap: isDesktop &&"119px",
        }}
      >
         <Slider {...settings}>
           {testimonies.map((testimonial, index) => (
   <div key={index}>
   
   <div
     style={{
       display: "flex",
       flexDirection: "column",
       alignItems: "center",
       justifyContent: "flex-start",
       gap: "48px",
     }}
   >
     <div
       style={{
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
         justifyContent: "flex-start",
         gap: "32px",
         fontSize:!isDesktop &&'20px'
       }}
     >
      
      <div style={{ width: "70%", lineHeight: "36px" }}>"{testimonial.text}"</div>
              <div style={{ fontWeight: "600" }}>{testimonial.name}</div>
              <div >{testimonial.cate}</div>
     </div>
    
   </div>
 
   </div>
      ))}
         </Slider>
       
      </div>
    </div>
  );
};

export default TestimonyProf;
