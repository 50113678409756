import { useState, useCallback } from "react";
import { TextField } from "@mui/material";
import Dropdown4 from "../components/Dropdown4";
import PortalPopup from "../components/PortalPopup";
import Dropdown5 from "../components/Dropdown5";
import { useNavigate } from "react-router-dom";
import styles from "./Post1.module.css";
import PostJobPage from "../components/postJobmain.tsx";
const Post1 = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdown1Open, setDropdown1Open] = useState(false);
  const navigate = useNavigate();

  const onInputContainerClick = useCallback(() => {
    // Please sync "Messages" to the project
  }, []);

  const onPageSelectorContainerClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHowItWorksClick = useCallback(() => {
    navigate("/consumerhow-it-works");
  }, [navigate]);

  const openDropdown = useCallback(() => {
    setDropdownOpen(true);
  }, []);

  const closeDropdown = useCallback(() => {
    setDropdownOpen(false);
  }, []);

  const openDropdown1 = useCallback(() => {
    setDropdown1Open(true);
  }, []);

  const closeDropdown1 = useCallback(() => {
    setDropdown1Open(false);
  }, []);

  const onFrameButtonClick = useCallback(() => {
    navigate("/consumerhelpfaqdone");
  }, [navigate]);

  const onSmallButtonsClick = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  return (
    <>
     <PostJobPage/>
    </>
  );
};

export default Post1;
