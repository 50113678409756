import { useNavigate } from "react-router-dom";
import styles from "./MenuConsumer.module.css";

const MenuConsumer = () => {
  const navigate = useNavigate()
  return (
    <div className={styles.menuconsumer}>
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div className={styles.hiringGuidesParent}
          style={{cursor:'pointer'}}
          onClick={()=>navigate('/blog/for-consumers/hiring-guide')}>
            <b className={styles.hiringGuides}>Hiring Guides</b>
            <div className={styles.gainGuidanceIn}>
              Gain guidance in hiring and selecting professionals.
            </div>
          </div>
          <div className={styles.hiringGuidesParent}
          style={{cursor:'pointer'}}
          onClick={()=>navigate('/blog/for-consumers/pricing-guides')}>
            <b className={styles.hiringGuides}>Pricing Guides</b>
            <div className={styles.gainGuidanceIn}>
              Find the price range of most home service professionals
            </div>
          </div>
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.hiringGuidesParent}
          style={{cursor:'pointer'}}
          onClick={()=>navigate('/blog/for-consumers/tips')}>
            <b className={styles.tips}>Tips</b>
            <div className={styles.gainGuidanceIn}>
              Find valuable insights on how make the most out of Loggworks.
            </div>
          </div>
          <div className={styles.hiringGuidesParent}
          style={{cursor:'pointer'}}
          onClick={()=>navigate('/blog/for-consumers/how-to')}>
            <b className={styles.tips}>How To</b>
            <div className={styles.getAccessToContainer}>
              <span className={styles.getAccessTo}>{`Get access to `}</span>
              <span>instructional content, guides, and tutorials.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuConsumer;
