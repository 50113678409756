import { useState, useCallback, useRef, useEffect } from "react";
import Dropdown4 from "../components/Dropdown4";
import PortalPopup from "../components/PortalPopup";
import Dropdown5 from "../components/Dropdown5";
import MobileMenuConsumer1 from "../components/MobileMenuConsumer1";
import { useNavigate } from "react-router-dom";
import styles from "./Agreement.module.css";
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import MobileFooter from "../components/MobileFooter";
import LocationSearch from "../components/locationSearch.tsx";
import { FormControl, MenuItem, Select } from "@mui/material";

const TermandCond = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(null);
  const open = Boolean(isDropdownOpen);
  const DivRef1 = useRef(null);
  const DivRef2 = useRef(null);
  const DivRef3 = useRef(null);
  const DivRef4 = useRef(null);
  const DivRef5 = useRef(null);
  const DivRef6 = useRef(null);
  const DivRef7 = useRef(null);
  const DivRef8 = useRef(null);
  const DivRef9 = useRef(null);
  const DivRef10 = useRef(null);
  //mobile
  const DivRef1m = useRef(null);
  const DivRef2m = useRef(null);
  const DivRef3m = useRef(null);
  const DivRef4m = useRef(null);
  const DivRef5m = useRef(null);
  const DivRef6m = useRef(null);
  const DivRef7m = useRef(null);
  const DivRef8m = useRef(null);
  const DivRef9m = useRef(null);
  const DivRef10m = useRef(null);
  const [value,setValue]=useState('Definitions')
 /* useEffect(() => {
    const handleScroll = () => {
      const div1Top = DivRef1.current.offsetTop;
      const div2Top = DivRef2.current.offsetTop;
      const div3Top = DivRef3.current.offsetTop;
      const div4Top = DivRef4.current.offsetTop;
      const div5Top = DivRef5.current.offsetTop;
      const div6Top = DivRef6.current.offsetTop;
      const div7Top = DivRef7.current.offsetTop;
      const div8Top = DivRef8.current.offsetTop;
      const div9Top = DivRef9.current.offsetTop;
      const div10Top = DivRef10.current.offsetTop;

      const scrollPosition = window.scrollY;

      if (scrollPosition >= div1Top && scrollPosition < div2Top) {
       setValue('Introduction')
      } 
      else if (scrollPosition >= div2Top && scrollPosition < div3Top) {
        setValue('Collection of your information')
      } 
      else if (scrollPosition >= div3Top && scrollPosition < div4Top) {
        setValue('Personal data')
      } 
      else if (scrollPosition >= div4Top && scrollPosition < div5Top) {
        setValue('Financial data')
      } else if (scrollPosition >= div5Top && scrollPosition < div6Top) {
        setValue('Use of your information')
      } else if (scrollPosition >= div6Top && scrollPosition < div7Top) {
        setValue('Disclosure of your information')
      } else if (scrollPosition >= div7Top && scrollPosition < div8Top) {
        setValue('By law or to protect rights')
      } else if (scrollPosition >= div8Top && scrollPosition < div9Top) {
        setValue('Third party service providers')
      } else if (scrollPosition >= div9Top && scrollPosition < div10Top) {
        setValue('Marketing communications')
      } else if (scrollPosition >= div10Top) {
        setValue('Security of your information')
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);*/


  const openDropdown = (event) => {
    setDropdownOpen(event.currentTarget);
  };
  const closeDropdown = () => {
    setDropdownOpen(null);
  };
  const [isDropdown1Open, setDropdown1Open] = useState(null);
  const open1 = Boolean(isDropdown1Open);
   const openDropdown1 = (event) => {
    setDropdown1Open(event.currentTarget);
  };
  const closeDropdown1 = () => {
    setDropdown1Open(null);
  };
  const [isMobileMenuConsumerPopupOpen, setMobileMenuConsumerPopupOpen] =
    useState(false);
  const navigate = useNavigate();

  const onPostAJobClick = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const onHowItWorksClick = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQsClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementTextClick = useCallback(() => {
    navigate("/agreement");
  }, [navigate]);

  const onHowItWorks1Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onContactUsText1Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQs1Click = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementText1Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/becometeam");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/partners");
  }, [navigate]);

  const onInfluencerProgramTextClick = useCallback(() => {
    navigate("/affiliate-program");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/home-service-provider");
  }, [navigate]);

  const onLargeButtons1Click = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const onPageSelectorContainerClick = useCallback(() => {
    navigate("/professional");
  }, [navigate]);

  const onHomeTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHowItWorks2Click = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onFrameButtonClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onSmallButtonsClick = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const openMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(true);
  }, []);

  const closeMobileMenuConsumerPopup = useCallback(() => {
    setMobileMenuConsumerPopupOpen(false);
  }, []);

  return (
    <>
      <div className={styles.agreement}>
        <div className={styles.careersdone}>
          <div className={styles.prototypeFooter}>
            <div className={styles.footer}>
              <div className={styles.download}>
                <div className={styles.downloadOurApps}>Download our apps</div>
                <img
                  className={styles.playstoreButtonIcon}
                  alt=""
                  src="/playstore-button9.svg"
                />
                <img
                  className={styles.appstoreButtonIcon}
                  alt=""
                  src="/appstore-button7.svg"
                />
              </div>
              <div className={styles.footerChild} />
              <img className={styles.layer2Icon} 
               style={{cursor:'pointer'}}
               onClick={()=>{navigate('/')}}
               alt="" src="/layer-281.svg" />
              <div className={styles.frameParent}>
                <div className={styles.homeownersParent}>
                  <b className={styles.homeowners}>Homeowners</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.postAJob} onClick={onPostAJobClick}>
                      Post a Job
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHowItWorksClick}
                    >
                      How it works
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onContactUsTextClick}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHelpFAQsClick}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.postAJob}
                      onClick={onUserAgreementTextClick}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.professionalsParent}>
                  <b className={styles.homeowners}>Professionals</b>
                  <div className={styles.postAJobParent}>
                    <div className={styles.signUp}
                    style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                    <div
                      className={styles.postAJob}
                      onClick={onHowItWorks1Click}
                    >
                      Blog
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onContactUsText1Click}
                    >
                      Contact Us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onHelpFAQs1Click}
                    >{`Help & FAQ’s`}</div>
                    <div
                      className={styles.postAJob}
                      onClick={onUserAgreementText1Click}
                    >
                      User Agreement
                    </div>
                  </div>
                </div>
                <div className={styles.loggworksParent}>
                  <b className={styles.homeowners}>Loggworks</b>
                  <div className={styles.postAJobParent}>
                    <div
                      className={styles.postAJob}
                      onClick={onAboutUsTextClick}
                    >
                      About us
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onCareersTextClick}
                    >
                      Careers
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onPartnersTextClick}
                    >
                      Partners
                    </div>
                    <div
                      className={styles.postAJob}
                      onClick={onInfluencerProgramTextClick}
                    >
                      Affiliate program
                    </div>
                    <div className={styles.blog}>Blog</div>
                  </div>
                </div>
                <div className={styles.loggworksGroup}>
                  <div className={styles.loggworks1}>
                    <b className={styles.followUs}>Follow us</b>
                  </div>
                  <div className={styles.groupParent}>
                    <div className={styles.frameGroup}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://web.facebook.com/Loggworks?_rdc=1&_rdr', '_blank')}>
                      <img
                        className={styles.frameIcon}
                        alt=""
                        src="/frame20.svg"
                      />
                      <div className={styles.facebook}>Facebook</div>
                    </div>
                    <div className={styles.frameContainer}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://twitter.com/loggworks', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame211.svg"
                      />
                      <div className={styles.twitter}>Twitter</div>
                    </div>
                    <div className={styles.groupDiv}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.instagram.com/loggworks/?utm_medium=copy_link', '_blank')}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame22.svg"
                      />
                      <div className={styles.twitter}>Instagram</div>
                    </div>
                    <div className={styles.frameParent1}>
                      <img
                        className={styles.frameIcon1}
                        alt=""
                        src="/frame23.svg"
                      />
                      <div className={styles.twitter}>YouTube</div>
                    </div>
                    <div className={styles.mdilinkedinParent}
                      style={{cursor:'pointer'}}
                      onClick={()=>window.open(' https://www.linkedin.com/company/loggworks', '_blank')}>
                      <img
                        className={styles.mdilinkedinIcon}
                        alt=""
                        src="/mdilinkedin1.svg"
                      />
                      <div className={styles.linkedin}>LinkedIn</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.copyright2022LoggworkLtdAParent}>
                <div className={styles.copyright2022Loggwork}>
                  ©Copyright 2024 loggworks  Ltd.  All Rights Reserved
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                    style={{cursor:'pointer'}}
                    onClick={()=>{navigate('/terms')}}>
                      Terms of Use
                    </div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div
                      className={styles.copyright2022Loggwork}
                      style={{cursor:'pointer'}}
                      onClick={()=>{navigate('/privacy')}}
                    >{`Privacy Policy `}</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork}
                     style={{cursor:'pointer'}}
                     onClick={()=>{navigate('/cookie')}}>Cookies</div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.contactUsParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Contact Us
                    </div>
                    <div className={styles.frameChild} />
                  </div>
                  <div className={styles.termsOfUseParent}>
                    <div className={styles.copyright2022Loggwork} style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href='https://www.loggworks.co.uk/sitemap.xml'
                    }}>Sitemap</div>
                    <div className={styles.frameChild1} />
                  </div>
                </div>
              </div>
              <div className={styles.largeButtonsParent}>
                <button
                  className={styles.largeButtons}
                  style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder38.svg"
                  />
                  <b className={styles.label}>Become a Professional</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder38.svg"
                  />
                </button>
                <button
                  className={styles.largeButtons}
                  onClick={onLargeButtons1Click}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder39.svg"
                  />
                  <b className={styles.label}>Post a Job</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder39.svg"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className={styles.frameParent2}>
            <div className={styles.frameWrapper}>
              <div className={styles.frameWrapper1}>
                <div className={styles.serviceConsumerUserAgreemenParent}>
                  <b className={styles.serviceConsumerUser}>
                    Terms of Use
                  </b>
                  <div className={styles.thisAgreementBinds}>
                    This agreement binds Service providers and Loggworks.
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameWrapper2}>
              <div className={styles.frameParent3}>
             
                <div className={styles.userAgreementTabsParent}>
                  <div 
                  style={{cursor:'pointer'}}
                  onClick={()=>{
                    setValue('Definitions')
                    DivRef1.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                  className={value=='Definitions'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}>Definitions</div>
                  </div>
                  <div 
                  style={{cursor:'pointer'}}
                  onClick={()=>{
                    setValue('Content')
                    DivRef2.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                  className={value=='Content'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}>
                    Content
                    </div>
                  </div>
                  <div 
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                    setValue('Loggworks Account')
                    DivRef3.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                   className={value=='Loggworks Account'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}>Loggworks Account</div>
                  </div>
                  <div 
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                    setValue('Intellectual Property Rights')
                    DivRef4.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                   className={value=='Intellectual Property Rights'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}>Intellectual Property Rights</div>
                  </div>
                  <div
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                    setValue('Limitation of Liability')
                    DivRef5.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                   className={value=='Limitation of Liability'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}>
                    Limitation of Liability
                    </div>
                  </div>
                  <div 
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                    setValue('Disclaimer')
                    DivRef6.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                   className={value=='Disclaimer'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}>
                    Disclaimer
                    </div>
                  </div>
                  <div
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                    setValue('Our Rights')
                    DivRef7.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                   className={value=='Our Rights'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}>
                    Our Rights
                    </div>
                  </div>
                  <div 
                   style={{cursor:'pointer'}}
                   onClick={()=>{
                    setValue('General')
                    DivRef8.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                   className={value=='General'?styles.userAgreementTabs:styles.userAgreementTabs1} >
                    <div className={styles.introduction}>
                    General
                    </div>
                  </div>
                </div>
                <div className={styles.frameParent4}>
                  <div className={styles.introductionParent}>
                  <div className={styles.weMayCollect}>
                    <Content
                    DivRef1={DivRef1}
                    DivRef2={DivRef2}
                    DivRef3={DivRef3}
                    DivRef4={DivRef4}
                    DivRef5={DivRef5}
                    DivRef6={DivRef6}
                    DivRef7={DivRef7}
                    DivRef8={DivRef8}
                    />
                  </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <nav className={styles.navigation}>
            <div className={styles.navigation1}>
              <img
                className={styles.navigationChild}
                alt=""
                src="/frame-665223.svg"
              />
              <div className={styles.navigationItem} />
              <div className={styles.tabSwitch}>
                <div
                  className={styles.pageSelector}
                  onClick={onPageSelectorContainerClick}
                >
                  <div className={styles.descriptionParent}>
                    <div className={styles.copyright2022Loggwork}>
                      Professional
                    </div>
                    <div className={styles.selector} />
                  </div>
                </div>
                <div className={styles.pageSelector1}
                 style={{cursor:'pointer'}}
                 onClick={()=>{navigate('/')}}>
                  <div className={styles.descriptionParent}>
                    <b className={styles.copyright2022Loggwork}>Consumer</b>
                    <div className={styles.selector} />
                  </div>
                </div>
              </div>
              <LocationSearch/>
            </div>
            
          </nav>
        </div>
        <div className={styles.homeMobile}>
          <div className={styles.navbarMobileclosed}>
            <img className={styles.layer2Icon1} alt="" src="/layer-28.svg" 
            style={{cursor:'pointer'}} onClick={()=>navigate('/')} />
            <div className={styles.iconsaccountGroup}>
              <img
                className={styles.iconsaccount1}
                alt=""
                src="/iconsaccount6.svg"
              />
              <img
                className={styles.hamburgerIcon}
                alt=""
                src="/hamburger.svg"
                onClick={openMobileMenuConsumerPopup}
              />
            </div>
          </div>
          <div className={styles.homeMobileInner}>
            <div className={styles.frameWrapper3}>
              <div className={styles.serviceConsumerUserAgreemenGroup}>
                <b className={styles.serviceConsumerUser1}>
                Terms of Use
                </b>
                <div className={styles.thisAgreementBinds1}>
                  This agreement binds Service providers and Loggworks.
                </div>
              </div>
            </div>
          </div>
          <div style={{width:'90%'}}>
             <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
           onChange={(e)=>{
             setValue(e.target.value)
             if (e.target.value=='Definition') {
               DivRef1m.current.scrollIntoView({ behavior: 'smooth' });
             }
             else if (e.target.value=='Content') {
               DivRef2m.current.scrollIntoView({ behavior: 'smooth' });
             }
             else if (e.target.value=='Loggworks Account') {
               DivRef3m.current.scrollIntoView({ behavior: 'smooth' });
             }
             else if (e.target.value=='Intellectual Property Rights') {
              DivRef4m.current.scrollIntoView({ behavior: 'smooth' });
            }
             else if (e.target.value=='Limitation of Liability') {
               DivRef5m.current.scrollIntoView({ behavior: 'smooth' });
             }
             else if (e.target.value=='Disclaimer') {
               DivRef6m.current.scrollIntoView({ behavior: 'smooth' });
             }
             else if (e.target.value=='Our Rights') {
               DivRef7m.current.scrollIntoView({ behavior: 'smooth' });
             }
             else{
               DivRef8m.current.scrollIntoView({ behavior: 'smooth' });
             }
           }}
        >
          <MenuItem value={'Definitions'}>Definitions</MenuItem>
          <MenuItem value={'Content'}>Content</MenuItem>
          <MenuItem value={'Loggworks Account'}>Loggworks Account</MenuItem>
          <MenuItem value={'Intellectual Property Rights'}>Intellectual Property Rights</MenuItem>
          <MenuItem value={'Limitation of Liability'}>Limitation of Liability</MenuItem>
          <MenuItem value={'Disclaimer'}>Disclaimer</MenuItem>
          <MenuItem value={'Our Rights'}>Our Rights</MenuItem>
          <MenuItem value={'General'}>General</MenuItem>
          <MenuItem value={'Chat'}>Chat</MenuItem>
        </Select>
      </FormControl>
          </div>
         
          <div className={styles.frameParent7}>
            <div className={styles.introductionGroup}>
              <div className={styles.thisBusinessRespectsContainer1}>
                <div className={styles.thisBusinessRespects}>
                  <Content
                     DivRef1={DivRef1m}
                     DivRef2={DivRef2m}
                     DivRef3={DivRef3m}
                     DivRef4={DivRef4m}
                     DivRef5={DivRef5m}
                     DivRef6={DivRef6m}
                     DivRef7={DivRef7m}
                     DivRef8={DivRef8m}
                  />
                </div>
                </div>
            </div>
          </div>
          <MobileFooter/>
        </div>
      </div>
      {isMobileMenuConsumerPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuConsumerPopup}
        >
          <MobileMenuConsumer1 onClose={closeMobileMenuConsumerPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default TermandCond;


const Content=({DivRef1,DivRef2,DivRef3,DivRef4,DivRef5,DivRef6,DivRef7,DivRef8})=>{
return(
 <>
 <p>Please read these Terms and Conditions carefully before using the <a
href="https://loggworks.co.uk/"><u>https://loggworks.co.uk</u> </a> website and the mobile application operated by Loggworks.</p>
<p>Your access to and use of the platform is subject to your acceptance
of and compliance with these terms. These terms apply to all visitors,
users, service professionals, and others who access or use the platform.
You warrant that you are at least 18 years of age and that you are
legally capable of entering into binding contracts. If you are under 18
years old, you warrant that you have obtained consent from your parent
or guardian, and they agree to be bound by these terms on your
behalf.</p>
<p>The <strong>Loggworks Terms of Service</strong> are comprised of the
following agreements:</p>
<p><u>The Service Consumer User Agreements</u></p>
<p><u>The Service Professional User Agreements</u></p>
<p><u>Terms of Use</u></p>
<p>The platform is for use in the United Kingdom only. You must not
access the service from any other jurisdiction. By accessing or using
the platform, you agree to be bound by these terms.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef1}>Definition</h1>
<br></br>
<p>In these terms, except to the extent that the context otherwise
requires:</p>
<p>1.1 <strong>“Content”</strong> means information posted, linked,
stored, shared, or otherwise made available on the platform or through
Loggworks, including text, graphics, videos, or other material and
listings for services.</p>
<p>1.2 <strong>“Intellectual Property”</strong> means and includes all
copyright and neighbouring rights, registered and unregistered
trademarks, designs, all rights in relation to the platform and
inventions (including patents), processes, confidential information
(including trade secrets and know-how), and all other rights resulting
from intellectual activity.</p>
<p>1.3 <strong>“Platform”</strong> means the Loggworks platform,
including the website loggworks.com and the mobile application
‘Loggworks’ through which service professionals get bookings or promote
their profile on the platform, or tasks they are available to provide,
and consumers can commission or engage service professionals to complete
a job.</p>
<p>1.4 <strong>“Loggworks”</strong> means the land of Goshen Group
Limited, <mark>registered in England under No</mark>. 15387808.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef2}>Content</h1>
<br></br>
<p>2.1 <strong>Loggworks</strong> allows you to post Content on the
Platform You are responsible for the content that you post to
<strong>Loggworks</strong>, including its legality, reliability, and
appropriateness.</p>
<p>2.2 By posting content to <strong>Loggworks</strong>, you grant us a
non-exclusive, unrestricted, and royalty-free licence to use, modify,
publicly perform, publicly display, reproduce, and distribute such
content on and through <strong>Loggworks</strong> and as otherwise
required to provide the platform for the promotion of
<strong>Loggworks</strong> and as permitted by the Terms. You retain any
and all of your rights to any content you submit, post, or display on or
through <strong>Loggworks,</strong> and you are responsible for
protecting those rights. You agree that this licence includes the right
for us to make your content available to other users of
<strong>Loggworks</strong>, who may also use your content, subject to
the Terms.</p>
<p>2.3 You represent and warrant that: the content is yours (you own
it), or you have the right to use it and grant us the rights and
licenses as provided in these Terms. The posting of your content on or
through <strong>Loggworks</strong> does not violate the privacy rights,
publicity rights, copyrights, contract rights, or any other rights of
any person, and you will only post true and accurate content on the
<strong>Loggworks</strong> platform.</p>
<p>2.4 Further, you warrant that: the content will not cause you or
<strong>Loggworks</strong> to breach any law, regulation, rule, code, or
other legal obligation. The Content will not or could not be reasonably
considered to be obscene, inappropriate, defamatory, disparaging,
indecent, seditious, offensive, pornographic, threatening, abusive,
liable to incite racial hatred, discriminatory, harassing, fraudulent,
in breach of confidence or in breach of privacy;</p>
<p>2.4.3 The content will not be unsolicited, undisclosed, or
unauthorised advertising. The content does not contain software viruses
or any other computer code, files, or programs designed to interrupt,
destroy, or limit the functionality of any computer software, hardware,
or telecommunications equipment. The content does not bring
<strong>Loggworks</strong> into disrepute.</p>
<p>The content must not, in any way whatsoever, be potentially or
harmful to <strong>Loggworks</strong> or any other person, including but
not limited to economic loss; and the content must not be false,
misleading, or deceptive.</p>
<p>2.5 You agree to keep all records necessary to establish that your
content does not violate any of the requirements of this clause and make
such records available upon reasonable request by
<strong>Loggworks</strong>. We are under no obligation to regularly
monitor the accuracy or reliability of your content incorporated into
the platform. We reserve the right to modify or remove any Content at
any time, without notice</p>
<p>2.6 You acknowledge and agree that all content you provide
<strong>Loggworks</strong> will be publicly available information, and
you bear the risks involved with such public disclosures.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef3}>Loggworks Account</h1>
<br></br>
<p>3.1 When you create an account with Loggworks, you must provide
information that is accurate, complete, and current at all times.
Failure to do so constitutes a breach of the terms, which may result in
the immediate termination of your account on the platform.</p>
<p>3.2 You are responsible for safeguarding the password that you use to
access Loggworks and for any activities or actions under your password,
whether your password is with Loggworks or a third-party service. You
must always remain in control of your account.</p>
<p>3.3 You agree not to disclose your password to any third party. You
agree to be fully responsible for activities that relate to your account
or password. You must notify Loggworks immediately upon becoming aware
of any breach of security or unauthorised use of your account.</p>
<p>3.4 You may not use as a username the name of another person or
entity that is not lawfully available for use, a name or trademark that
is subject to any rights of another person or entity other than you
without appropriate authorization, or a name that is otherwise
offensive, vulgar, or obscene.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef4}>Intellectual Property Rights</h1>
<br></br>
<p>4.1 The platform and its original content (excluding content provided
by users), features, and functionality are and will remain the exclusive
property of Loggworks and its licensors. The platform is protected by
copyright, trademarks, and the laws of England, Wales, and other foreign
countries. Loggworks trademark, trade address, and intellectual property
may not be used in connection with any product or service without the
prior written consent of Loggworks. Nothing in these terms constitutes a
transfer of any intellectual property rights from us to you.</p>
<p>4.2 You are permitted to use the platform only as authorised by
Loggworks. As a service consumer or service professional, you are
granted a limited, non-exclusive, revocable, and non-transferable right
to use the platform to create, display, use, play, and download content,
subject to these terms.</p>
<p>4.3 Loggworks intellectual property must not be used in connection
with a product or service that is not affiliated with Loggworks or in
any way brings Loggworks into disrepute.</p>
<p>4.4 You must not modify the physical or digital copies of any content
or intellectual property you obtain in any way; you must not use any
illustrations, photographs, video or audio, or any graphics separately
from any accompanying text.</p>
<p>4.5 Any opinions, advice, statements, services, offers, or other
information or content expressed or made available by any other users
are those of the respective authors or distributors and not of
Loggworks.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef5}>Limitation of Liability</h1>
<br></br>
<p>5.1 Notwithstanding any other provision, nothing in these Terms of
Use shall exclude or limit either party’s liability for death or
personal injury caused by that party’s negligence, fraud or fraudulent
misrepresentation, or any other liability that cannot lawfully be
excluded or limited.</p>
<p>5.2 If you are dissatisfied with the <strong>platform</strong> or any
of these terms of use, your sole remedy under these terms of use shall
be to discontinue use of the platform.</p>
<p>5.3 Without limiting the foregoing, Loggworks shall have no liability
for any failure or delay resulting from any matter beyond our reasonable
control.</p>
<p>5.4 Other than as set out in this Limitation of Liability section and
notwithstanding any other provision of these Terms of Use,
<strong>Loggworks</strong> shall not be liable in contract, tort,
negligence, statutory duty, misrepresentation, or otherwise for any loss
or damage whatsoever arising from or in any way connected with these
Terms of Use.</p>
<p>5.5 Save as expressly set out herein, all conditions, warranties, and
obligations that may be implied or incorporated into these Terms of Use
by statute, common law, or otherwise, and any liabilities arising from
them, are hereby expressly excluded to the extent permitted by law.</p>
<p>5.6 <strong>Loggworks</strong> shall not be liable for any loss of
business, loss of profits, business interruption, loss of business
information, loss of data, or any other pecuniary loss (even where we
have been advised of the possibility of such loss or damage).</p>
<p>5.7 In the event that any limitation or exclusion of liability in
these Terms of Use proves ineffective, then Loggworks shall not be
liable to you for more than £100 in aggregate. If you register on the
website or any of the apps, then only the aggregate cap on liability
under the agreement that you enter upon registration shall apply. If you
register as both a “consumer” and a “service professional,” then only
the aggregate cap in the Service Professional User Agreement shall
apply.</p>
<p>5.8 Loggworks cannot guarantee the day or time that we will respond
to any email, telephone, or written inquiries or website form
submissions.</p>
<p>5.9 Each of the provisions of this clause shall be construed
separately and independently of the others.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef6}>Disclaimer</h1>
<br></br>
<p>6.1 Your use of the platform is at your sole risk. The platform is
provided on an "AS IS" and "AS AVAILABLE" basis. To the extent permitted
by law, the platform is provided without warranties of any kind, whether
express or implied, including but not limited to implied warranties of
merchantability, fitness for a particular purpose, noninfringement, or
course of performance.</p>
<p>6.2 <strong>Loggworks</strong>, its subsidiaries, affiliates, and its
licensors do not warrant that the platform will function uninterrupted,
secure, or available at any particular time or location. Any errors or
defects will be corrected. The platform is free of viruses or other
harmful components, and the results of using the platform will meet your
requirements.</p>
<p>6.3 This disclaimer of liability applies to any damages or injury
caused by any failure of performance, error, omission, interruption,
deletion, defect, delay in operation or transmission, computer virus,
communication line failure, theft, destruction, or unauthorised access,
or alteration of or use of records in connection with the use or
operation of the platform, whether for breach of contract, tortious
behaviour, negligence, or any other cause of action.</p>
<p>6.4 Loggworks makes no representations or warranties of any kind, to
the extent permitted by law, express or implied, about the completeness,
accuracy, reliability, suitability, or availability with respect to the
content contained on the platform for any purpose. Any reliance you
place on such information is, therefore, strictly at your own risk.
Loggworks disclaims any express or implied warranty, representation, or
guarantee as to the effectiveness or profitability of the platform or
that the operation of the platform will be uninterrupted or error-free.
Loggworks is not liable for the consequences of any interruptions or
errors on the platform.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef7}>Our Rights</h1>
<br></br>
<p>7.1 <strong>Loggworks</strong> reserves the right at all times to
edit, refuse to post, or remove from the service any information or
materials for any reason whatsoever, and to disclose any information we
deem appropriate to satisfy any applicable law, regulation, legal
process, police request, or governmental request.</p>
<p>7.2 <strong>Loggworks</strong> reserves the right to restrict your
access to the service at any time without notice for any reason
whatsoever.</p>
<p>7.3 Without prejudice to the generality of the above,
<strong>Loggworks</strong> reserves the right to restrict your access to
the service at any time without notice in the event that we suspect you
to be in material breach of any term of these Terms of Use.</p>
<p>7.4 <strong>Loggworks</strong> reserves the right to modify or
discontinue temporarily or permanently all or part of the service with
or without notice, without liability for any modification or
discontinuance.</p>
<p>7.5 <strong>Loggworks</strong> may vary these Terms of Use from time
to time and shall post such alterations on the Service.</p>
<br></br>
<h1 style={{fontSize:'1.5rem', fontWeight:'bold'}} ref={DivRef8}>General</h1>
<br></br>
<p>8.1 Clause headings are inserted for convenience only and shall not
affect the interpretation of these Terms of Use.</p>
<p>8.2 If any provisions hereof are held to be illegal or unenforceable,
such provisions shall be severed, and the remainder of these Terms of
Use shall remain in full force and effect unless the business purpose of
these Terms of Use is substantially frustrated, in which case they shall
terminate without giving rise to further liability.</p>
<p>8.3 You may not assign, transfer, or subcontract any of your rights
hereunder without our prior written consent. We may assign, transfer, or
sub-contract all or any of our rights at any time without consent.</p>
<p>8.4 No waiver shall be effective unless in writing, and no waiver
shall constitute a continuing waiver so as to prevent us from acting
upon any continuing or subsequent breach or default.</p>
<p>8.5 These Terms of Use constitute the entire agreement as to its
subject matter and supersede and extinguish all previous communications,
representations (other than fraudulent misrepresentations), and
arrangements, whether written or oral, with the exception of the Service
Consumer User Agreement and/or Service Professional User Agreement where
these have been entered into. To the extent that there is any conflict
between them, those agreements shall apply in the following order of
precedence:</p>
<p>8.5.1 the Service professionals User Agreement</p>
<p>8.5.2 <u>the Service Consumers User Agreement</u>; then</p>
<p>8.5.3 These <u>Terms of Use</u>.</p>
<p>8.6 You acknowledge that you have placed no reliance on any
representation made but not set out expressly in these Terms of Use.</p>
<p>8.7 Any notice to be given under these Terms of Use may be given via
e-mail, regular mail, facsimile, or by hand to the address provided on
the website or otherwise as notified by one party to the other.</p>
<p>8.8 Nothing herein shall create or be deemed to create any joint
venture, principal-agent, or partnership relationship between the
parties, and neither party shall hold itself out in its advertising or
otherwise in any manner that would indicate or imply any such
relationship with the other.</p>
<p>8.9 Notwithstanding any other provision in these Terms of Use, a
person who is not a party hereto has no right under the Contracts
(Rights of Third Parties) Act 1999 to rely upon or enforce these Terms
of Use</p>
<p>8.10 These Terms of Use shall be subject to the laws of England, and
the parties shall submit to the exclusive jurisdiction of the English
courts.</p>
<p>8.11 In the event of any comments or questions regarding these Terms
of Use (including the <u>Privacy Policy),</u> please <u>contact
us</u>.</p>
 </>
)
}