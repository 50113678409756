import React from 'react';
import style from './404Page.module.css'; // Import CSS for styling (optional)

const NotFoundPage = () => {
  return (
    <div className={style.not_found_container}>
      <h1>Oops! Page Not Found</h1>
      <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
     
      <p>Go back to <a className={style.a} href="/">homepage</a></p>
    </div>
  );
}

export default NotFoundPage;
