import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MenuMobile.module.css";

const MenuMobile = ({onClose}) => {
  const navigate = useNavigate();
  const [acc1, setAcc1]=useState(0)
  const [acc2, setAcc2]=useState(0)
  const [acc3, setAcc3]=useState(0)
  const onForConsumersTextClick = useCallback(() => {
    navigate("/blog/for-consumers");
  }, [navigate]);

  const onHiringGuidesTextClick = useCallback(() => {
    navigate("/blog/for-consumers/hiring-guide");
  }, [navigate]);

  const onPricingGuidesTextClick = useCallback(() => {
    navigate("/blog/for-consumers/pricing-guides");
  }, [navigate]);

  const onTipsTextClick = useCallback(() => {
    navigate("/blog/for-consumers/tips");
  }, [navigate]);

  const onHowToTextClick = useCallback(() => {
    navigate("/blog/for-consumers/how-to");
  }, [navigate]);

  const onForProfessionalsTextClick = useCallback(() => {
    navigate("/blog/for-professionals");
  }, [navigate]);

  const onBusinessAdviceTextClick = useCallback(() => {
    navigate("/blog/for-professionals/business-advice");
  }, [navigate]);

  const onManagingYourBusinessClick = useCallback(() => {
    navigate("/blog/for-professionals/managing-your-business");
  }, [navigate]);

  const onFinanceAdviceTextClick = useCallback(() => {
    navigate("/blog/for-professionals/finance-advice");
  }, [navigate]);

  const onSpotlightTextClick = useCallback(() => {
    navigate("/blog/for-professionals/spotlight");
  }, [navigate]);

  const onTipsText2Click = useCallback(() => {
    navigate("/blog/for-professionals/tips");
  }, [navigate]);

  const onHowToText2Click = useCallback(() => {
    navigate("/blog/for-professionals/how-to");
  }, [navigate]);

  const onNewsUpdatesClick = useCallback(() => {
    navigate("/blog/news");
  }, [navigate]);

  const onProductUpdatesTextClick = useCallback(() => {
    navigate("/blog/news/product-updates");
  }, [navigate]);

  const onPartnersTextClick = useCallback(() => {
    navigate("/blog/news/partners");
  }, [navigate]);

  const onIndustryNewsTextClick = useCallback(() => {
    navigate("/blog/news/industry-news");
  }, [navigate]);

  const onLoggworksInTheClick = useCallback(() => {
    navigate("/blog/news/loggworks-in-the-news");
  }, [navigate]);

  return (
    <div className={styles.menumobile}>
      <div className={styles.navbarMobileclosedParent}>
        <div className={styles.navbarMobileclosed}>
          <img className={styles.layer2Icon} alt="" src="/layer-2.svg" />
          <img
            className={styles.iconsaccount}
            alt=""
            src="/iconsaccount3.svg"
          />
          <img className={styles.xIcon} alt="" src="/x.svg" onClick={onClose}/>
          <img className={styles.closeIcon} alt="" src="/close.svg" 
          onClick={onClose}/>
        </div>
        <div className={styles.frameWrapper}>
          <div className={styles.frameParent}>
            <div className={styles.frameGroup}>
              <div className={styles.groupParent}>
                <div className={styles.frameContainer}>
                  <div className={styles.forConsumersParent}>
                    <b
                      className={styles.forConsumers}
                      onClick={onForConsumersTextClick}
                    >
                      For Consumers
                    </b>
                    <img
                      className={styles.caretupIcon}
                      alt=""
                      onClick={()=>{
                        if (acc1==0) {
                          setAcc1(1)
                        }else{
                          setAcc1(0)
                        }
                        }}
                      src={acc1==0?"/caretdown.svg":"/caretup.svg"}
                    />
                  </div>
                </div>
               {
                acc1==1&&<div className={styles.groupParent}>
                  <div
                    className={styles.hiringGuides}
                    onClick={onHiringGuidesTextClick}
                  >
                    Hiring Guides
                  </div>
                  <div
                    className={styles.hiringGuides}
                    onClick={onPricingGuidesTextClick}
                  >
                    Pricing Guides
                  </div>
                  <div
                    className={styles.hiringGuides}
                    onClick={onTipsTextClick}
                  >
                    Tips
                  </div>
                  <div
                    className={styles.hiringGuides}
                    onClick={onHowToTextClick}
                  >
                    How To
                  </div>
                </div>
                }
              </div>
              <div className={styles.groupParent}>
                <div className={styles.groupDiv}>
                  <div className={styles.forConsumersParent}>
                    <b
                      className={styles.forConsumers}
                      onClick={onForProfessionalsTextClick}
                    >
                      For Professionals
                    </b>
                    <img
                      className={styles.caretupIcon}
                      alt=""
                      onClick={()=>{
                        if (acc2==0) {
                          setAcc2(1)
                        }else{
                          setAcc2(0)
                        }
                        }}
                      src={acc2==0?"/caretdown.svg":"/caretup.svg"}
                    />
                  </div>
                </div>
                {
                acc2==1&&
<div className={styles.groupParent}>
                  <div
                    className={styles.businessAdvice}
                    onClick={onBusinessAdviceTextClick}
                  >
                    Business advice
                  </div>
                  <div
                    className={styles.businessAdvice}
                    onClick={onManagingYourBusinessClick}
                  >
                    managing your business
                  </div>
                  <div
                    className={styles.businessAdvice}
                    onClick={onFinanceAdviceTextClick}
                  >
                    finance advice
                  </div>
                  <div
                    className={styles.businessAdvice}
                    onClick={onSpotlightTextClick}
                  >
                    spotlight
                  </div>
                  <div
                    className={styles.hiringGuides}
                    onClick={onTipsText2Click}
                  >
                    Tips
                  </div>
                  <div
                    className={styles.hiringGuides}
                    onClick={onHowToText2Click}
                  >
                    How To
                  </div>
                </div>
                }
                
              </div>
              <div className={styles.groupParent}>
                <div className={styles.frameWrapper1}>
                  <div className={styles.forConsumersParent}>
                    <b
                      className={styles.forConsumers}
                      onClick={onNewsUpdatesClick}
                    >{`News & Updates`}</b>
                    <img
                      className={styles.caretupIcon}
                      alt=""
                      onClick={()=>{
                        if (acc3==0) {
                          setAcc3(1)
                        }else{
                          setAcc3(0)
                        }
                        }}
                      src={acc3==0?"/caretdown.svg":"/caretup.svg"}
                    />
                  </div>
                </div>
                {
                acc3==1&&
                <div className={styles.groupParent}>
                  <div
                    className={styles.businessAdvice}
                    onClick={onProductUpdatesTextClick}
                  >
                    Product Updates
                  </div>
                  <div
                    className={styles.businessAdvice}
                    onClick={onPartnersTextClick}
                  >
                    partners
                  </div>
                  <div
                    className={styles.businessAdvice}
                    onClick={onIndustryNewsTextClick}
                  >
                    industry News
                  </div>
                  <div
                    className={styles.businessAdvice}
                    onClick={onLoggworksInTheClick}
                  >
                    loggworks in the news
                  </div>
                </div>
                }
              </div>
            </div>
            <button className={styles.largeButtons}
            onClick={()=>navigate('/')}>
              <b className={styles.loggworkscouk}>loggworks.co.uk</b>
              <img
                className={styles.arrowrightIcon}
                alt=""
                src="/arrowright.svg"
              />
              <img className={styles.iconsadd} alt="" src="/iconsadd4.svg" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuMobile;
