import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./PrototypeFooter.module.css";

const PrototypeFooter = () => {
  const navigate = useNavigate();
 
    const onAboutUsTextClick = useCallback(() => {
     navigate("/about-us");
   }, [navigate]);
 
 
   const onCareersTextClick = useCallback(() => {
     navigate("/becometeam");
   }, [navigate]);
 
 
   const onPartnersTextClick = useCallback(() => {
     navigate("/partners");
   }, [navigate]);
 
 
   const onInfluencerProgramTextClick = useCallback(() => {
     navigate("/affiliate-program");
   }, [navigate]);
 
 
   const onLargeButtonsClick = useCallback(() => {
     window.open('https://www.professional.loggworks.co.uk/')
   }, [navigate]);
 
 
   const onLargeButtons1Click = useCallback(() => {
    navigate('/postjob')
   }, [navigate]);


  const onPostAJobClick = useCallback(() => {
    navigate("/postjob");
  }, [navigate]);

  const onHowItWorksClick = useCallback(() => {
    navigate("/how-we-work");
  }, [navigate]);

  const onContactUsTextClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQsClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const onUserAgreementTextClick = useCallback(() => {
    navigate("/agreement");
  }, [navigate]);

  const onHowItWorks1Click = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onContactUsText1Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onHelpFAQs1Click = useCallback(() => {
    navigate("/professional-faq");
  }, [navigate]);

  const onUserAgreementText1Click = useCallback(() => {
    navigate("/careers");
  }, [navigate]);


 



  return (
    <div className={styles.prototypeFooter}>
      <div className={styles.footer}>
        <div className={styles.frameParent}>
          <div className={styles.layer2Parent}>
            <img className={styles.layer2Icon} 
             style={{cursor:'pointer'}}
             onClick={()=>{navigate('/')}}
             alt="" src="/layer-22.svg" />
            <div className={styles.largeButtonsParent}>
              <button
                className={styles.largeButtons}
                onClick={onLargeButtonsClick}
              >
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder12.svg"
                />
                <b className={styles.label}>Become a Professional</b>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder12.svg"
                />
              </button>
              <button
                className={styles.largeButtons}
                onClick={onLargeButtons1Click}
              >
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder10.svg"
                />
                <b className={styles.label}>Post a Job</b>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder10.svg"
                />
              </button>
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.homeownersParent}>
              <b className={styles.homeowners}>Homeowners</b>
              <div className={styles.postAJobParent}>
                <div className={styles.postAJob} onClick={onPostAJobClick}>
                  Post a Job
                </div>
                <div className={styles.postAJob} onClick={onHowItWorksClick}>
                  How it works
                </div>
                <div className={styles.postAJob} onClick={onContactUsTextClick}>
                  Contact Us
                </div>
                <div
                  className={styles.postAJob}
                  onClick={onHelpFAQsClick}
                >{`Help & FAQ’s`}</div>
                <div
                  className={styles.postAJob}
                  onClick={onUserAgreementTextClick}
                >
                  User Agreement
                </div>
              </div>
            </div>
            <div className={styles.professionalsParent}>
              <b className={styles.homeowners}>Professionals</b>
              <div className={styles.postAJobParent}>
                <div className={styles.signUp}
                style={{cursor:'pointer'}} onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>Sign Up</div>
                <div className={styles.postAJob} onClick={onHowItWorks1Click}>
                  Blog
                </div>
                <div
                  className={styles.postAJob}
                  onClick={onContactUsText1Click}
                >
                  Contact Us
                </div>
                <div
                  className={styles.postAJob}
                  onClick={onHelpFAQs1Click}
                >{`Help & FAQ’s`}</div>
                <div
                  className={styles.postAJob}
                  onClick={onUserAgreementText1Click}
                >
                  User Agreement
                </div>
              </div>
            </div>
            <div className={styles.loggworksParent}>
              <b className={styles.homeowners}>Loggworks</b>
              <div className={styles.postAJobParent}>
                <div className={styles.postAJob} onClick={onAboutUsTextClick}>
                  About us
                </div>
                <div className={styles.postAJob} onClick={onCareersTextClick}>
                  Careers
                </div>
                <div className={styles.postAJob} onClick={onPartnersTextClick}>
                  Partners
                </div>
                <div
                  className={styles.postAJob}
                  onClick={onInfluencerProgramTextClick}
                >
                  Influencer program
                </div>
                <div className={styles.blog}>Blog</div>
              </div>
            </div>
            <div className={styles.loggworksGroup}>
              <div className={styles.loggworks1}>
                <b className={styles.followUs}>Follow us</b>
              </div>
              <div className={styles.groupParent}>
                <div className={styles.frameContainer} style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://web.facebook.com/Loggworks?_rdc=1&_rdr', '_blank')}>
                      <img className={styles.frameIcon} alt="" src="/frame33.svg" />
                  <div className={styles.facebook}>Facebook</div>
                </div>
                <div className={styles.groupDiv} style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://twitter.com/loggworks', '_blank')}>
                      <img className={styles.frameIcon1} alt="" src="/frame34.svg" />
                  <div className={styles.twitter}>Twitter</div>
                </div>
                <div className={styles.frameParent1}  style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.instagram.com/loggworks/?utm_medium=copy_link', '_blank')}>
                      <img className={styles.frameIcon1} alt="" src="/frame35.svg" />
                  <div className={styles.twitter}>Instagram</div>
                </div>
                <div className={styles.frameParent2}>
                  <img className={styles.frameIcon1} alt="" src="/frame36.svg" />
                  <div className={styles.twitter}>YouTube</div>
                </div>
                <div className={styles.mdilinkedinParent} style={{cursor:'pointer'}}
                      onClick={()=>window.open('https://www.linkedin.com/company/loggworks', '_blank')}>
                      <img
                    className={styles.mdilinkedinIcon}
                    alt=""
                    src="/mdilinkedin2.svg"
                  />
                  <div className={styles.linkedin}>LinkedIn</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footerChild} />
        <div className={styles.copyright2022LoggworkLtdAParent}>
          <div className={styles.copyright2022Loggwork}>
            ©Copyright 2024 loggworks Ltd. All Rights Reserved
          </div>
          <div className={styles.frameDiv}>
            <div className={styles.termsOfUseParent}>
              <div className={styles.copyright2022Loggwork}
              style={{cursor:'pointer'}}
              onClick={()=>{navigate('/terms')}}>Terms of Use</div>
              <div className={styles.frameChild} />
            </div>
            <div className={styles.termsOfUseParent}>
              <div
                className={styles.copyright2022Loggwork}
                style={{cursor:'pointer'}}
                onClick={()=>{navigate('/privacy')}}
              >{`Privacy Policy `}</div>
              <div className={styles.frameChild} />
            </div>
            <div className={styles.termsOfUseParent}>
              <div className={styles.copyright2022Loggwork}
              style={{cursor:'pointer'}}
              onClick={()=>{navigate('/terms')}}>Cookies</div>
              <div className={styles.frameChild} />
            </div>
            <div className={styles.contactUsParent}>
              <div className={styles.copyright2022Loggwork}>Contact Us</div>
              <div className={styles.frameChild} />
            </div>
            <div className={styles.termsOfUseParent}>
              <div className={styles.copyright2022Loggwork} style={{cursor:'pointer'}}
                    onClick={()=>{
                      window.location.href='https://www.loggworks.co.uk/sitemap.xml'
                    }}>Sitemap</div>
              <div className={styles.frameChild1} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrototypeFooter;
