import { useNavigate, useParams } from "react-router-dom";
import MobileFooter from "../components/MobileFooter";
import PrototypeFooter from "../components/PrototypeFooter";
import styles from "./ProfFinding.module.css";
import { useCallback, useEffect, useState } from "react";
import PortalPopup from "../components/PortalPopup";
import mobnav from "./ConsumerHome.module.css";
import MobileMenuProfProfessional1 from "../components/MobileMenuProfProfessional1";
import LocationSearch from "../components/locationSearch.tsx";
import { db } from "../firebase";
import { Helmet } from "react-helmet";

const ProfFinding = () => {
  const navigate=useNavigate()
  const [
    isMobileMenuProfProfessionalPopupOpen,
    setMobileMenuProfProfessionalPopupOpen,
  ] = useState(false);
  let {prof,loc}=useParams()
  prof = prof!='short-order cook'?prof.replace(/-/g, ' ').trim(): prof.trim();
  loc = loc.replace(/-/g, ' ').trim();
  const [img1,setImg1]=useState('')
  const [img2,setImg2]=useState('')
  
  const openMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(true);
  }, []);

  const closeMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(false);
  }, []);
  const categories = [
    {
      title: "Catering",
      options: 
      [
      "/find/prof_caterer.webp", 
      "/find/prof_caterer02.webp",
    ],
    },
    {
      title: "Childcare",
      options: [
      "/find/prof_childcare.webp", 
      "/find/prof_childcare02.webp", 
      ],
    },
    {
      title: "Cleaning",
      options: [
        "/find/prof_cleaner.webp", 
        "/find/prof_cleaner02.webp", 
      ],
    },
    {
      title: "Entertainment",
      options: [
        "/find/prof_event.webp", 
        "/find/prof_event02.webp",
      ],
    },
    {
      title: "Appliance Repair",
      options: [
        "/find/prof_appl.webp", 
        "/find/prof_appl02.webp", 
      ],
    },
    {
      title: "Mobile Mechanic",
      options: [
        "/find/prof_mobile.webp", 
        "/find/prof_mobile02.webp",
      ],
    },
    {
      title: "Personal Assistance",
      options: [
        "/find/prof_passist.webp", 
        "/find/prof_passist02.webp"
      ],
    },
    {
      title: "Pet Groomers",
      options: [
        "/find/prof_pet.webp", 
      "/find/prof_pet.webp", 
      ],
    },
    {
      title: "Recovery Services",
      options: [
        "/find/prof_recov.webp", 
        "/find/prof_recov02.webp",
      ],
    },
    {
      title: "Computer & Phone repair",
      options: [
        "/find/prof_comp.webp", 
        "/find/prof_comp02.webp",
      ],
    },
    {
      title: "Removal Services",
      options: [
        "/find/prof_remov.webp", 
      "/find/prof_remov02.webp", 
      ],
    },
    {
      title: "Tradesmen",
      options: [
        "/find/prof_trades.webp", 
      "/find/prof_trades02.webp",
      ],
    },
    {
      title: "Transport Services",
      options: [
        "/find/prof_trans.webp", 
      "/find/prof_trans02.webp", 
      ],
    },
    {
      title: "Dry Cleaning & Laundry",
      options: [
        "/find/prof_dryclean.webp", 
        "/find/prof_dryclean02.webp",
      ]
    },
    {
      title: "Health, Wellness & Beauty",
      options: [
        "/find/prof_health.webp", 
        "/find/prof_health02.webp",
      ],
    },
    {
      title: "Tuition & Classes",
      options: [
        "/find/prof_tuition.webp", 
      "/find/prof_tuition02.webp", 
      ],
    },
  ];
  useEffect(() => {
    const usersRef = db.collection('categories');
    const tagRef = db.collection('serviceTags');
  
    const querySnapshot = usersRef.get();
    const tagSnapshot = tagRef.get();
  
    querySnapshot.then(function (userSnap) {
      userSnap.forEach(function (doc) {
        const data = doc.data();
        const id = doc.id;
  
        if (id.toLowerCase() === prof.toLowerCase()) {
          categories.map((x)=>{
            if (x.title.toLowerCase()==id.toLowerCase()) {
              setImg1(x.options[0])
              setImg2(x.options[1])
            }
          })
        } else if(data.subcategories.includes(prof)) {
          categories.map((x)=>{
            if (x.title.toLowerCase()==id.toLowerCase()) {
              setImg1(x.options[0])
              setImg2(x.options[1])
            }
          })
        }
        else{
          tagSnapshot.then(function (userSnap) {
            userSnap.forEach(function (doc) {
              const data = doc.data();
              const id = doc.id;
        
              if (id.toLowerCase() === prof.toLowerCase()) {
                categories.map((x)=>{
                  if (x.title.toLowerCase()==id.toLowerCase()) {
                    setImg1(x.options[0])
                    setImg2(x.options[1])
                  }
                })
              } else if(data.subcategories.includes(prof)) {
                categories.map((x)=>{
                  if (x.title.toLowerCase()==id.toLowerCase()) {
                    setImg1(x.options[0])
                    setImg2(x.options[1])
                  }
                })
              }
            });
          });
        }
      });
    });
  }, [])
  return (
    <div className={styles.proffinding}>
    <Helmet>
                <meta charSet="utf-8" />
                <title>Find {prof} Jobs In {loc}</title>
                <meta name="description" 
                content={`Discover top ${prof} jobs in ${loc} with LoggWorks. you get unlimited customers, free business listings, instant quotes, and efficient business management. Enhance your business efficiency and revenue in ${loc}.`}/>
            </Helmet>
      <div className={styles.landingPage}>
        <div className={styles.frameParent}>
        <img className={styles.frameChild} alt="" 
        onClick={()=>navigate('/')}
        style={{cursor:'pointer'}}
        src="/frame-66522.svg" />
          <LocationSearch/>
          <div className={styles.frameGroup}>
            <div className={styles.professionalParent}
             style={{cursor:'pointer'}}
             onClick={()=>{
             }}>
              <b className={styles.professional}>Professional</b>
              <div className={styles.selector} />
            </div>
            <div className={styles.consumerParent}
             style={{cursor:'pointer'}}
             onClick={()=>{
               navigate('/consumers/'+prof+'/'+loc)
             }}>
              <div className={styles.consumer}>Consumer</div>
              <div className={styles.selector1} />
            </div>
          </div>
        </div>
        <div className={styles.frameContainer}>
          <div className={styles.frameDiv}>
            <div className={styles.lookingForCaterersJobsInLParent}>
              <b className={styles.lookingForCaterersContainer}>
                <p className={styles.lookingFor}>Looking for</p>
                <p className={styles.lookingFor}>
                  <span >{`${prof} Jobs in `}</span>
                  <span className={styles.lewisham}>{loc}?</span>
                </p>
              </b>
              <img className={styles.groupInner} alt="" src="/vector-4.svg" />
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.checksquareParent}>
                <img
                  className={styles.checksquareIcon}
                  alt=""
                  src="/checksquare.svg"
                />
                <div className={styles.onlyPayCommission}>
                  Only pay commission on completed jobs
                </div>
              </div>
              <div className={styles.blogBottomPostsParent}>
                <div className={styles.checksquareParent}>
                  <img
                    className={styles.checksquareIcon}
                    alt=""
                    src="/checksquare.svg"
                  />
                  <div className={styles.onlyPayCommission}>
                    100% free Quote
                  </div>
                </div>
                <div className={styles.checksquareParent}>
                  <img
                    className={styles.checksquareIcon}
                    alt=""
                    src="/checksquare.svg"
                  />
                  <div className={styles.onlyPayCommission}>
                    100% free payment Management
                  </div>
                </div>
              </div>
              <div className={styles.frameParent3}>
                <div className={styles.checksquareParent}>
                  <img
                    className={styles.checksquareIcon}
                    alt=""
                    src="/checksquare.svg"
                  />
                  <div
                    className={styles.onlyPayCommission}
                  >{`100% free Profile Promotion `}</div>
                </div>
                <div className={styles.checksquareParent}>
                  <img
                    className={styles.checksquareIcon}
                    alt=""
                    src="/checksquare.svg"
                  />
                  <div className={styles.onlyPayCommission}>
                    100% Free business listing page
                  </div>
                </div>
              </div>
              <div className={styles.checksquareParent}>
                <img
                  className={styles.checksquareIcon}
                  alt=""
                  src="/checksquare.svg"
                />
                <div
                  className={styles.onlyPayCommission}
                >{`100% free Automated Job Management feature `}</div>
              </div>
            </div>
          </div>
          <button className={styles.largeButtons}
          onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
            <img className={styles.mappinIcon} alt="" src="/iconsadd.svg" />
            <b className={styles.label}>Create new business profile for free</b>
            <img className={styles.iconsadd1} alt="" src="/iconsadd10.svg" />
          </button>
        </div>
        <div className={styles.whyChooseUs}>
          <div className={styles.topCatererJobsContainer}>Why Choose Us?</div>
          <div className={styles.frameParent4}>
            <div className={styles.frameParent5}>
              <div className={styles.coinsParent}>
                <img className={styles.coinsIcon} alt="" src="/coins.svg" />
                <div className={styles.startWithZero}>Start with zero cost</div>
              </div>
              <div className={styles.ourZeroCostStartupContainer}>
                <p className={styles.lookingFor}>
                  Our zero-cost startup approach is tailored to support
                  professionals in growing
                </p>
                <p className={styles.lookingFor}>
                  their businesses sustainably.
                </p>
              </div>
            </div>
            <div className={styles.frameParent5}>
              <div className={styles.coinsParent}>
                <img className={styles.coinsIcon} alt="" src="/receipt.svg" />
                <div className={styles.startWithZero}>
                  Reduce your business costs
                </div>
              </div>
              <div className={styles.ourZeroCostStartupContainer}>
                <p className={styles.lookingFor}>
                  Our platform is designed to provide maximum exposure and
                  opportunities
                </p>
                <p className={styles.lookingFor}>at minimal cost.</p>
              </div>
            </div>
            <div className={styles.frameParent5}>
              <div className={styles.coinsParent}>
                <img className={styles.coinsIcon} alt="" src="/list.svg" />
                <div className={styles.commissionAfterCompletion}>
                  Free Business Listing
                </div>
              </div>
              <div className={styles.ourZeroCostStartupContainer}>
                Gain more visibility and attract more clients with a free
                business listing on our platform.
              </div>
            </div>
            <div className={styles.frameParent5}>
              <div className={styles.coinsParent}>
                <img className={styles.coinsIcon} alt="" src="/handcoins.svg" />
                <div className={styles.commissionAfterCompletion}>
                  Commission After Completion
                </div>
              </div>
              <div className={styles.ourZeroCostStartupContainer}>
                Our unique model ensures you only pay us when you successfully
                complete a job.
              </div>
            </div>
          </div>
        </div>
        <div className={styles.landingPageInner}>
          <div className={styles.whyChooseUs}>
            <div className={styles.topCatererJobsContainer}>
              <span>{`Top `}</span>
              <span className={styles.lewisham}>{prof}</span>
              <span> Jobs in {loc}</span>
            </div>
            <div className={styles.groupDiv}>
            <img
             src={img1}
             style={{width:'100%',height:'100%'}}
             />
            </div>
          </div>
        </div>
        <div className={styles.howItWorksProf}>
          <div className={styles.frameParent9}>
            <div className={styles.frameParent10}>
              <div className={styles.vectorParent}>
                <img className={styles.vectorIcon} alt="" src="/vector.svg" />
                <div className={styles.howItWorks}>HOW IT WORKS</div>
              </div>
              <div className={styles.getStartedOnContainer}>
                <p className={styles.lookingFor}>Get started on</p>
                <p className={styles.lookingFor}>loggworks with</p>
                <p className={styles.lookingFor}>four easy steps</p>
              </div>
            </div>
            <div className={styles.frameParent11}>
              <div className={styles.topCatererJobsInLewishamGroup}>
                <img
                  className={styles.groupIcon}
                  alt=""
                  src="/profimage01.png"
                />
                <div className={styles.topCatererJobsInLewishamGroup}>
                  <div className={styles.signUpAsAServiceProviderParent}>
                    <div className={styles.signUpAs}>
                      Sign up as a service provider.
                    </div>
                    <div className={styles.youChooseThe}>
                      You choose the category and subcategory of the type of
                      service you offer.
                    </div>
                  </div>
                  <button className={styles.largeButtons1}
                  onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                    <img
                      className={styles.mappinIcon}
                      alt=""
                      src="/iconsadd3.svg"
                    />
                    <b className={styles.label}>
                      Create new business profile for free
                    </b>
                    <img
                      className={styles.iconsadd1}
                      alt=""
                      src="/iconsadd4.svg"
                    />
                  </button>
                </div>
              </div>
              <div className={styles.topCatererJobsInLewishamGroup}>
                <img
                  className={styles.groupIcon}
                  alt=""
                  src="/profimage02.png"
                />
                <div className={styles.topCatererJobsInLewishamGroup}>
                  <div className={styles.signUpAsAServiceProviderParent}>
                    <div className={styles.signUpAs}>
                      Receive requests from customers.
                    </div>
                    <div className={styles.youChooseThe}>
                      Get booking requests from service consumers, review job
                      details and book jobs through Loggworks website or mobile
                      app.
                    </div>
                  </div>
                  <button className={styles.largeButtons1}
                  onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                    <img
                      className={styles.mappinIcon}
                      alt=""
                      src="/iconsadd3.svg"
                    />
                    <b className={styles.label}>
                      Create new business profile for free
                    </b>
                    <img
                      className={styles.iconsadd1}
                      alt=""
                      src="/iconsadd4.svg"
                    />
                  </button>
                </div>
              </div>
              <div className={styles.topCatererJobsInLewishamGroup}>
                <img
                  className={styles.groupIcon}
                  alt=""
                  src="/profimage03.png"
                />
                <div className={styles.topCatererJobsInLewishamGroup}>
                  <div className={styles.signUpAsAServiceProviderParent}>
                    <div className={styles.signUpAs}>
                      Send quote and invoice.
                    </div>
                    <div className={styles.youChooseThe}>
                      <p className={styles.lookingFor}>
                        When you get the job details, you will send a quote
                        containing the terms and conditions of the job upon the
                        acceptance of the quote by the customer, then
                      </p>
                      <p className={styles.lookingFor}>
                        an invoice will be issued for payment.
                      </p>
                    </div>
                  </div>
                  <button className={styles.largeButtons1}
                  onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                    <img
                      className={styles.mappinIcon}
                      alt=""
                      src="/iconsadd3.svg"
                    />
                    <b className={styles.label}>
                      Create new business profile for free
                    </b>
                    <img
                      className={styles.iconsadd1}
                      alt=""
                      src="/iconsadd4.svg"
                    />
                  </button>
                </div>
              </div>
              <div className={styles.topCatererJobsInLewishamGroup}>
                <img
                  className={styles.groupIcon}
                  alt=""
                  src="/profimage04.png"
                />
                <div className={styles.topCatererJobsInLewishamGroup}>
                  <div className={styles.signUpAsAServiceProviderParent}>
                    <div className={styles.signUpAs}>
                      Complete the job and get paid.
                    </div>
                    <div className={styles.youChooseThe}>
                      Upon job completion to the satisfaction of the client your
                      funds are made available to you, get the job done and get
                      paid.
                    </div>
                  </div>
                  <button className={styles.largeButtons1}
                  onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                    <img
                      className={styles.mappinIcon}
                      alt=""
                      src="/iconsadd3.svg"
                    />
                    <b className={styles.label}>
                      Create new business profile for free
                    </b>
                    <img
                      className={styles.iconsadd1}
                      alt=""
                      src="/iconsadd4.svg"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.steps} />
          </div>
        </div>
        <div className={styles.whyYouShouldFindCaterersJParent}>
          <div className={styles.whyYouShouldContainer}>
            <p className={styles.lookingFor}>Why You Should Find</p>
            <p className={styles.lookingFor}>
              <span className={styles.lewisham}>{`${prof} `}</span>
              <span>Jobs On Loggworks</span>
            </p>
          </div>
          <div className={styles.whyLoggworks}>
            <div className={styles.groupParent9}>
              <div className={styles.groupParent10}>
                <div className={styles.rectangleParent16}>
                  <div className={styles.groupChild27} />
                  <div className={styles.groupChild28} />
                  <div className={styles.getRealTimeInsightsParent}>
                    <b className={styles.getRealTime}>Get Real Time Insights</b>
                    <div className={styles.elevateYourProfessional}>
                      Elevate your professional journey with instant,
                      data-driven customer feedback. Gain access to real-time
                      customer satisfaction metrics to stay ahead of the curve,
                      elevate your service, and build lasting customer
                      relationships.
                    </div>
                  </div>
                </div>
                <div className={styles.rectangleParent17}>
                  <div className={styles.customerSatisfactionMeasureChild} />
                  <div className={styles.customerSatisfactionMeasureParent}>
                    <div className={styles.customerSatisfactionMeasure}>
                      <div
                        className={styles.customerSatisfactionMeasureChild}
                      />
                      <b className={styles.customerSatisfactionMeasure1}>
                        Customer Satisfaction Measure
                      </b>
                      <div className={styles.overallSatisfaction60Container}>
                        <span className={styles.overallSatisfaction}>
                          Overall Satisfaction:
                        </span>
                        <span className={styles.span}>
                          <span className={styles.span1}>{` `}</span>
                          <span>60%</span>
                        </span>
                      </div>
                      <div className={styles.surveyDate}>Survey Date</div>
                      <div className={styles.div}>12/01/20 - 13/01/20</div>
                      <div className={styles.responses140}>Responses: 140</div>
                      <div className={styles.parent}>
                        <div className={styles.div1}>60%</div>
                        <img
                          className={styles.groupChild30}
                          alt=""
                          src="/group-66459@2x.png"
                        />
                      </div>
                    </div>
                    <div className={styles.customerRatings}>
                      <div className={styles.customerRatingsChild} />
                      <b className={styles.customerRatings1}>
                        Customer Ratings
                      </b>
                      <div className={styles.outOf50Container}>
                        <span className={styles.span2}>4.6</span>
                        <span className={styles.span3}>{` `}</span>
                        <span className={styles.outOf50}>out of 5.0</span>
                      </div>
                      <div className={styles.div2}>12/01/20 - 13/01/20</div>
                      <div className={styles.reviews}>56 Reviews</div>
                      <img
                        className={styles.customerRatingsItem}
                        alt=""
                        src="/group-66649.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.groupParent10}>
                <div className={styles.rectangleParent16}>
                  <div className={styles.groupChild27} />
                  <div className={styles.rectangleParent19}>
                    <div className={styles.groupChild32} />
                    <div className={styles.freeBusinessListingParent}>
                      <b className={styles.quoteAndInvoice}>
                        Free Business Listing
                      </b>
                      <div className={styles.elevateYourProfessional}>
                        Gain more visibility with a free business listing on our
                        platform. Highlight your unique services and attract
                        more clients by showcasing your expertise and
                        professionalism.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.frameWrapper}>
                  <div className={styles.profileWrapper}>
                    <div className={styles.profile}>
                      <div className={styles.profileSection}>
                        <div className={styles.imgContainer}>
                          <img
                            className={styles.imgContainerChild}
                            alt=""
                            src="/frame-67185.svg"
                          />
                          <img
                            className={styles.imgContainerItem}
                            alt=""
                            src="/frame-67186.svg"
                          />
                          <div className={styles.content}>
                            <div className={styles.contentChild} />
                            <div className={styles.contentItem} />
                            <div className={styles.contentItem} />
                            <div className={styles.contentItem} />
                            <div className={styles.contentItem} />
                          </div>
                          <div className={styles.smallButtons}>
                            <div className={styles.iconseditParent}>
                              <img
                                className={styles.iconsedit}
                                alt=""
                                src="/iconsedit.svg"
                              />
                              <div className={styles.button}>Edit</div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.profile1}>
                          <div className={styles.profileHeading}>
                            <div className={styles.avatarParent}>
                              <img
                                className={styles.avatarIcon}
                                alt=""
                                src="/avatar14.svg"
                              />
                              <div className={styles.frameParent16}>
                                <div className={styles.iconseditParent}>
                                  <div className={styles.dysonElectricals}>
                                    Dyson Electricals
                                  </div>
                                  <img
                                    className={styles.iconsedit}
                                    alt=""
                                    src="/guarantee-1.svg"
                                  />
                                </div>
                                <div className={styles.transactionPills}>
                                  <div className={styles.success}>
                                    unverified
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles.awards}>
                              <div className={styles.rookie} />
                              <div className={styles.rookie} />
                              <div className={styles.rookie} />
                              <div className={styles.rookie} />
                              <div className={styles.rookie} />
                              <div className={styles.rookie} />
                              <div className={styles.ambassador}>
                                <img
                                  className={styles.ambassadorChild}
                                  alt=""
                                  src="/group-66966.svg"
                                />
                                <img
                                  className={styles.ambassadorItem}
                                  alt=""
                                  src="/group-66967@2x.png"
                                />
                                <img
                                  className={styles.ambassadorInner}
                                  alt=""
                                  src="/group-66950.svg"
                                />
                                <div className={styles.ambassador1}>
                                  Ambassador
                                </div>
                                <img
                                  className={styles.ambassadorChild1}
                                  alt=""
                                  src="/vector-39.svg"
                                />
                              </div>
                            </div>
                          </div>
                          <div className={styles.buttons}>
                            <div className={styles.largeButtons5}>
                              <img
                                className={styles.icons20pxplaceholder}
                                alt=""
                                src="/icons20pxplaceholder.svg"
                              />
                              <b className={styles.howToEarn}>
                                Promote Profile
                              </b>
                              <img
                                className={styles.icons20pxplaceholder}
                                alt=""
                                src="/icons20pxplaceholder.svg"
                              />
                            </div>
                            <div className={styles.buttonsInner}>
                              <div className={styles.shareParent}>
                                <b className={styles.share}>Share</b>
                                <img
                                  className={styles.iconsshare}
                                  alt=""
                                  src="/iconsshare.svg"
                                />
                              </div>
                            </div>
                          </div>
                          <div className={styles.prototypeSettings}>
                            <div className={styles.twoTabSwitch}>
                              <div className={styles.tab1Wrapper}>
                                <div className={styles.tab1}>Account</div>
                              </div>
                              <div className={styles.tab2Wrapper}>
                                <div className={styles.list}>Settings</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.frameParent17}>
                        <div className={styles.frameParent18}>
                          <div className={styles.frameParent19}>
                            <div className={styles.frameParent20}>
                              <div className={styles.group}>
                                <div className={styles.dysonElectricals}>
                                  300
                                </div>
                                <div className={styles.totalPoints}>
                                  Total Points
                                </div>
                              </div>
                              <div className={styles.nextLevelParent}>
                                <div className={styles.nextLevel}>
                                  Next Level
                                </div>
                                <div className={styles.intermediate1}>
                                  Intermediate
                                </div>
                                <div className={styles.nextLevel}>
                                  2500 points
                                </div>
                              </div>
                            </div>
                            <div className={styles.progressBar}>
                              <div className={styles.progressBarInner}>
                                <div className={styles.frameChild4} />
                              </div>
                              <div className={styles.frameParent21}>
                                <div className={styles.rectangleWrapper}>
                                  <div className={styles.frameChild5} />
                                </div>
                                <div className={styles.frameChild6} />
                              </div>
                            </div>
                          </div>
                          <div className={styles.earn950Points}>
                            Earn +950 points more to advance to the next level
                          </div>
                          <div
                            className={styles.howToEarnPointsAndLevelUParent}
                          >
                            <div className={styles.howToEarn}>
                              How to earn points and level up
                            </div>
                            <img
                              className={styles.iconswarning}
                              alt=""
                              src="/iconswarning.svg"
                            />
                          </div>
                        </div>
                        <div className={styles.verificationProgressBar}>
                          <img
                            className={styles.verificationProgressBarChild}
                            alt=""
                            src="/vector-27.svg"
                          />
                          <div className={styles.exclamationMark1Parent}>
                            <img
                              className={styles.exclamationMark1Icon}
                              alt=""
                              src="/exclamationmark-1.svg"
                            />
                            <div
                              className={styles.yourVerificationIs68ComplParent}
                            >
                              <div className={styles.dysonElectricals}>
                                Your verification is 68% complete
                              </div>
                              <div className={styles.completeVerificationTo}>
                                Complete verification to earn points
                              </div>
                            </div>
                          </div>
                          <div className={styles.progressBar1}>
                            <div className={styles.progress} />
                          </div>
                        </div>
                        <div className={styles.frameWrapper1}>
                          <div
                            className={styles.yourVerificationIs68ComplParent}
                          >
                            <div className={styles.profileList}>
                              <div className={styles.content1}>
                                <div className={styles.left}>
                                  <div className={styles.main}>
                                    <img
                                      className={styles.exclamationMark1Icon}
                                      alt=""
                                      src="/verify.svg"
                                    />
                                    <div className={styles.list}>
                                      License and certification
                                    </div>
                                  </div>
                                  <div className={styles.subTextWrapper}>
                                    <div className={styles.subText}>
                                      Certification depending on service type
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={styles.iconschevronRightWrapper}
                                >
                                  <img
                                    className={styles.iconsshare}
                                    alt=""
                                    src="/iconschevronright.svg"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className={styles.profileList}>
                              <div className={styles.content1}>
                                <div className={styles.left}>
                                  <div className={styles.main}>
                                    <img
                                      className={styles.exclamationMark1Icon}
                                      alt=""
                                      src="/verify.svg"
                                    />
                                    <div className={styles.list}>Education</div>
                                  </div>
                                  <div className={styles.subTextWrapper}>
                                    <div className={styles.subText}>
                                      Complete your profile by adding education
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={styles.iconschevronRightWrapper}
                                >
                                  <img
                                    className={styles.iconsshare}
                                    alt=""
                                    src="/iconschevronright.svg"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className={styles.profileList}>
                              <div className={styles.content1}>
                                <div className={styles.left}>
                                  <div className={styles.main}>
                                    <img
                                      className={styles.exclamationMark1Icon}
                                      alt=""
                                      src="/verify.svg"
                                    />
                                    <div className={styles.list}>
                                      Proof of Identity
                                    </div>
                                  </div>
                                  <div className={styles.subTextWrapper}>
                                    <div className={styles.subText}>
                                      Add documents to verify your identity
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={styles.iconschevronRightWrapper}
                                >
                                  <img
                                    className={styles.iconsshare}
                                    alt=""
                                    src="/iconschevronright.svg"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className={styles.profileList}>
                              <div className={styles.content1}>
                                <div className={styles.left}>
                                  <div className={styles.main}>
                                    <img
                                      className={styles.exclamationMark1Icon}
                                      alt=""
                                      src="/verify.svg"
                                    />
                                    <div className={styles.list}>
                                      Work Experience
                                    </div>
                                  </div>
                                  <div className={styles.subTextWrapper}>
                                    <div className={styles.subText}>
                                      Add references to help you stand out
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={styles.iconschevronRightWrapper}
                                >
                                  <img
                                    className={styles.iconsshare}
                                    alt=""
                                    src="/iconschevronright.svg"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className={styles.profileList}>
                              <div className={styles.content1}>
                                <div className={styles.left}>
                                  <div className={styles.main}>
                                    <img
                                      className={styles.exclamationMark1Icon}
                                      alt=""
                                      src="/verify.svg"
                                    />
                                    <div className={styles.list}>
                                      My Network
                                    </div>
                                  </div>
                                  <div className={styles.subTextWrapper}>
                                    <div className={styles.subText}>
                                      Add people in the same field of service
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={styles.iconschevronRightWrapper}
                                >
                                  <img
                                    className={styles.iconsshare}
                                    alt=""
                                    src="/iconschevronright.svg"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className={styles.profileList5}>
                              <div className={styles.frameParent22}>
                                <div className={styles.titleParent}>
                                  <div className={styles.list}>References</div>
                                  <div className={styles.subtitle}>
                                    Add references to help you stand out
                                  </div>
                                </div>
                                <div className={styles.smallButtons1}>
                                  <div className={styles.buttonWrapper}>
                                    <div className={styles.button}>
                                      Add references
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.rectangleParent20}>
              <div className={styles.frameChild7} />
              <div className={styles.rectangleParent21}>
                <div className={styles.groupChild33} />
                <div className={styles.frameParent23}>
                  <div className={styles.jobsParent}>
                    <div className={styles.jobs}>
                      <div className={styles.jobs1}>
                        <div className={styles.profileParent}>
                          <div className={styles.profile2}>
                            <img
                              className={styles.avatarIcon1}
                              alt=""
                              src="/avatar12.svg"
                            />
                            <div
                              className={styles.yourVerificationIs68ComplParent}
                            >
                              <div className={styles.peoplename}>
                                Annette Black
                              </div>
                              <div className={styles.dateTime}>
                                <div className={styles.date}>26/06/2021</div>
                                <div className={styles.dateTimeChild} />
                                <div className={styles.date}>{`6:32AM `}</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className={styles.iconsmoreVertical}
                            alt=""
                            src="/iconsmore-vertical.svg"
                          />
                        </div>
                        <div className={styles.frameParent24}>
                          <div className={styles.frameParent25}>
                            <div className={styles.iconscalendarParent}>
                              <img
                                className={styles.iconsmoreVertical}
                                alt=""
                                src="/iconscalendar.svg"
                              />
                              <div className={styles.date}>{`09/06/2021 `}</div>
                            </div>
                            <div className={styles.iconscalendarParent}>
                              <img
                                className={styles.iconsmoreVertical}
                                alt=""
                                src="/iconsclock.svg"
                              />
                              <div className={styles.date}>9:00AM</div>
                            </div>
                            <div className={styles.iconscalendarParent}>
                              <img
                                className={styles.iconsmoreVertical}
                                alt=""
                                src="/iconsduration.svg"
                              />
                              <div className={styles.date}>5 days</div>
                            </div>
                          </div>
                          <div className={styles.iconslocationParent}>
                            <img
                              className={styles.iconsmoreVertical}
                              alt=""
                              src="/iconslocation1.svg"
                            />
                            <div className={styles.citiessummary19}>
                              8 High Street, Chase Terrace, WS7 1LP
                            </div>
                          </div>
                        </div>
                        <div className={styles.electricianRequiredTo}>
                          Electrician required to fix a spoilt vaccum cleaner.
                          Please treat as urgent.
                        </div>
                        <div className={styles.rating}>
                          <div className={styles.transactionPills1}>
                            <div className={styles.success}>COMPLETED</div>
                          </div>
                          <div className={styles.iconsstarParent}>
                            <img
                              className={styles.iconsstar}
                              alt=""
                              src="/iconsstar.svg"
                            />
                            <img
                              className={styles.iconsstar}
                              alt=""
                              src="/iconsstar1.svg"
                            />
                            <img
                              className={styles.iconsstar}
                              alt=""
                              src="/iconsstar2.svg"
                            />
                            <img
                              className={styles.iconsstar}
                              alt=""
                              src="/iconsstar3.svg"
                            />
                            <img
                              className={styles.iconsstar}
                              alt=""
                              src="/iconsstar4.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.jobs2}>
                      <div className={styles.jobs1}>
                        <div className={styles.profileParent}>
                          <div className={styles.profile2}>
                            <img
                              className={styles.avatarIcon1}
                              alt=""
                              src="/avatar13.svg"
                            />
                            <div
                              className={styles.yourVerificationIs68ComplParent}
                            >
                              <div className={styles.peoplename}>
                                Brooklyn Simmons
                              </div>
                              <div className={styles.dateTime}>
                                <div className={styles.date}>26/06/2021</div>
                                <div className={styles.dateTimeChild} />
                                <div className={styles.date}>{`6:32AM `}</div>
                              </div>
                            </div>
                          </div>
                          <img
                            className={styles.iconsmoreVertical}
                            alt=""
                            src="/iconsmore-vertical.svg"
                          />
                        </div>
                        <div className={styles.frameParent24}>
                          <div className={styles.frameParent25}>
                            <div className={styles.iconscalendarParent}>
                              <img
                                className={styles.iconsmoreVertical}
                                alt=""
                                src="/iconscalendar.svg"
                              />
                              <div className={styles.date}>{`09/06/2021 `}</div>
                            </div>
                            <div className={styles.iconscalendarParent}>
                              <img
                                className={styles.iconsmoreVertical}
                                alt=""
                                src="/iconsclock.svg"
                              />
                              <div className={styles.date}>9:00AM</div>
                            </div>
                            <div className={styles.iconscalendarParent}>
                              <img
                                className={styles.iconsmoreVertical}
                                alt=""
                                src="/iconsduration.svg"
                              />
                              <div className={styles.date}>5 days</div>
                            </div>
                          </div>
                          <div className={styles.iconslocationParent}>
                            <img
                              className={styles.iconsmoreVertical}
                              alt=""
                              src="/iconslocation1.svg"
                            />
                            <div className={styles.citiessummary19}>
                              8 High Street, Chase Terrace, WS7 1LP
                            </div>
                          </div>
                        </div>
                        <div className={styles.electricianRequiredTo}>
                          Electrician required to fix a spoilt vaccum cleaner.
                          Please treat as urgent.
                        </div>
                        <div className={styles.rating}>
                          <div className={styles.transactionPills1}>
                            <div className={styles.success}>COMPLETED</div>
                          </div>
                          <div className={styles.iconsstarParent}>
                            <img
                              className={styles.iconsstar}
                              alt=""
                              src="/iconsstar.svg"
                            />
                            <img
                              className={styles.iconsstar}
                              alt=""
                              src="/iconsstar1.svg"
                            />
                            <img
                              className={styles.iconsstar}
                              alt=""
                              src="/iconsstar2.svg"
                            />
                            <img
                              className={styles.iconsstar}
                              alt=""
                              src="/iconsstar3.svg"
                            />
                            <img
                              className={styles.iconsstar}
                              alt=""
                              src="/iconsstar4.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.jobPrototypes}>
                    <div className={styles.tabSwitch}>
                      <div className={styles.tabSwitchChild} />
                      <div className={styles.all}>
                        <div className={styles.booking}>Current</div>
                      </div>
                      <div className={styles.all1}>
                        <div className={styles.booking1}>Upcoming</div>
                      </div>
                      <div className={styles.all2}>
                        <div className={styles.booking1}>Past</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.efficientJobScheduleManagemParent}>
                <b className={styles.efficientJobSchedule}>
                  Efficient Job Schedule Management
                </b>
                <div className={styles.keepTrackOf}>
                  Keep track of your commitments with our intuitive job schedule
                  manager. Get timely reminders and manage your jobs effectively
                  to maximize productivity and client satisfaction.
                </div>
              </div>
            </div>
            <div className={styles.groupParent9}>
              <div className={styles.groupParent10}>
                <div className={styles.rectangleParent16}>
                  <div className={styles.groupChild27} />
                  <div className={styles.rectangleParent19}>
                    <div className={styles.groupChild32} />
                    <div className={styles.freeBusinessListingParent}>
                      <b className={styles.getRealTime}>
                        Promote your services effectively
                      </b>
                      <div className={styles.elevateYourProfessional}>
                        Take advantage of Loggworks' business promotion tools to
                        reach a wider audience. Showcase your skills and
                        services to potential clients actively seeking
                        professionals like you.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.rectangleParent24}>
                  <div className={styles.groupChild36} />
                  <div className={styles.promotionsListParent}>
                    <div className={styles.promotionsList}>
                      <div className={styles.rectangleParent25}>
                        <img
                          className={styles.frameChild8}
                          alt=""
                          src="/rectangle-12399@2x.png"
                        />
                        <div className={styles.frameParent28}>
                          <div className={styles.frameParent29}>
                            <div className={styles.citiessummaryParent2}>
                              <div className={styles.citiessummary24}>
                                Get 10% off on all bookings
                              </div>
                              <img
                                className={styles.iconsmoreVertical2}
                                alt=""
                                src="/iconsmore-vertical.svg"
                              />
                            </div>
                            <div className={styles.frameWrapper2}>
                              <div className={styles.frameParent30}>
                                <div className={styles.iconscalendarContainer}>
                                  <img
                                    className={styles.iconsmoreVertical2}
                                    alt=""
                                    src="/iconscalendar.svg"
                                  />
                                  <div className={styles.citiessummary25}>
                                    Ends:
                                  </div>
                                  <div
                                    className={styles.citiessummary26}
                                  >{`09/06/2021 `}</div>
                                </div>
                                <div className={styles.iconsclockContainer}>
                                  <img
                                    className={styles.iconsmoreVertical2}
                                    alt=""
                                    src="/iconsclock.svg"
                                  />
                                  <div className={styles.citiessummary27}>
                                    9:00AM
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles.price}>Price</div>
                          </div>
                          <div className={styles.citiessummary28}>
                            {" "}
                            2 mins ago
                          </div>
                          <div className={styles.rectangleParent26}>
                            <div className={styles.instanceChild2} />
                            <div className={styles.pendingApproval}>
                              Pending Approval
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.promotionsList1}>
                      <div className={styles.rectangleParent25}>
                        <img
                          className={styles.frameChild8}
                          alt=""
                          src="/rectangle-12399@2x.png"
                        />
                        <div className={styles.frameParent28}>
                          <div className={styles.frameParent29}>
                            <div className={styles.citiessummaryParent2}>
                              <div className={styles.citiessummary29}>
                                <span
                                  className={styles.overallSatisfaction}
                                >{`Get 20% when you spend above `}</span>
                                <b>£500</b>
                              </div>
                              <img
                                className={styles.iconsmoreVertical2}
                                alt=""
                                src="/iconsmore-vertical1.svg"
                              />
                            </div>
                            <div className={styles.frameWrapper2}>
                              <div className={styles.frameParent30}>
                                <div className={styles.iconscalendarContainer}>
                                  <img
                                    className={styles.iconsmoreVertical2}
                                    alt=""
                                    src="/iconscalendar.svg"
                                  />
                                  <div className={styles.citiessummary25}>
                                    Ends:
                                  </div>
                                  <div
                                    className={styles.citiessummary26}
                                  >{`09/06/2021 `}</div>
                                </div>
                                <div className={styles.iconsclockContainer}>
                                  <img
                                    className={styles.iconsmoreVertical2}
                                    alt=""
                                    src="/iconsclock.svg"
                                  />
                                  <div className={styles.citiessummary27}>
                                    9:00AM
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles.price}>Price</div>
                          </div>
                          <div className={styles.citiessummary28}>
                            {" "}
                            2 mins ago
                          </div>
                          <div className={styles.rectangleParent28}>
                            <div className={styles.instanceChild3} />
                            <div className={styles.approved}>Approved</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.groupParent10}>
                <div className={styles.rectangleParent16}>
                  <div className={styles.groupChild27} />
                  <div className={styles.groupChild28} />
                  <div className={styles.quoteAndInvoiceWithEaseParent}>
                    <b className={styles.quoteAndInvoice}>
                      Quote and Invoice with Ease
                    </b>
                    <div className={styles.elevateYourProfessional}>
                      Streamline your business transactions with our integrated
                      quoting and invoicing features. Provide estimates and
                      manage payments effortlessly, all within the same
                      platform.
                    </div>
                  </div>
                </div>
                <div className={styles.groupParent15}>
                  <div className={styles.chatLeftWrapper}>
                    <div className={styles.chatLeft}>
                      <div className={styles.chatLeftInner}>
                        <div className={styles.quoteWrapper}>
                          <div className={styles.quote}>
                            <div className={styles.quoteBtn}>
                              <img
                                className={styles.quoteBtnChild}
                                alt=""
                                src="/rectangle-12260@2x.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.quoteContainer}>
                    <div className={styles.quote1}>
                      <div className={styles.quoteParent}>
                        <div className={styles.quote2}>Quote</div>
                        <div className={styles.createJobQuote}>
                          Create job quote for client jobs
                        </div>
                      </div>
                      <div className={styles.largeButtons6}>
                        <img
                          className={styles.iconsadd10}
                          alt=""
                          src="/iconsadd.svg"
                        />
                        <b className={styles.label6}>Create Quote</b>
                        <img
                          className={styles.iconsadd11}
                          alt=""
                          src="/iconsadd.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.whyLoggworksInner}>
              <div className={styles.groupParent16}>
                <div className={styles.rectangleParent30}>
                  <div className={styles.groupChild39} />
                  <div className={styles.instanceContainer}>
                    <div className={styles.rectangleParent31}>
                      <div className={styles.instanceChild4} />
                      <div className={styles.rectangleParent32}>
                        <img
                          className={styles.groupChild40}
                          alt=""
                          src="/rectangle-12401@2x.png"
                        />
                        <img
                          className={styles.groupChild41}
                          alt=""
                          src="/rectangle-12407@2x.png"
                        />
                      </div>
                      <div className={styles.fluenttextDescriptionLtr20Parent2}>
                        <img
                          className={styles.fluenttextDescriptionLtr20Icon4}
                          alt=""
                          src="/fluenttextdescriptionltr20filled.svg"
                        />
                        <div className={styles.imInNeed4}>
                          I’m in need of a skilled {prof} at...
                        </div>
                      </div>
                      <div className={styles.akarIconslocationParent2}>
                        <img
                          className={styles.fluenttextDescriptionLtr20Icon4}
                          alt=""
                          src="/akariconslocation.svg"
                        />
                        <div className={styles.citiessummary34}>{loc}</div>
                      </div>
                      <div className={styles.rectangleParent33}>
                        <div className={styles.groupChild42} />
                        <div className={styles.citiessummary35}>
                          Message Annette
                        </div>
                      </div>
                      <div className={styles.groupParent17}>
                        <div className={styles.rectangleParent34}>
                          <img
                            className={styles.groupChild43}
                            alt=""
                            src="/rectangle-12403@2x.png"
                          />
                          <img
                            className={styles.groupChild44}
                            alt=""
                            src="/rectangle-12404@2x.png"
                          />
                          <img
                            className={styles.groupChild45}
                            alt=""
                            src="/rectangle-12405@2x.png"
                          />
                          <img
                            className={styles.groupChild46}
                            alt=""
                            src="/rectangle-12406@2x.png"
                          />
                        </div>
                        <div className={styles.citiessummary36}>+3</div>
                      </div>
                      <b className={styles.requestForElectricianContainer8}>
                        <span
                          className={styles.requestForElectricianContainer1}
                        >
                          <p className={styles.lookingFor}>{` `}</p>
                          <p className={styles.lookingFor}>
                            Request for {prof}
                          </p>
                        </span>
                      </b>
                      <div className={styles.citiessummaryParent4}>
                        <div className={styles.citiessummary37}>
                          £600 - £1000
                        </div>
                        <img
                          className={styles.uilmoneyBillIcon4}
                          alt=""
                          src="/uilmoneybill.svg"
                        />
                      </div>
                    </div>
                    <div className={styles.rectangleParent35}>
                      <div className={styles.instanceChild4} />
                      <div className={styles.rectangleParent32}>
                        <img
                          className={styles.groupChild40}
                          alt=""
                          src="/rectangle-12401@2x.png"
                        />
                        <img
                          className={styles.groupChild41}
                          alt=""
                          src="/rectangle-12407@2x.png"
                        />
                      </div>
                      <div className={styles.fluenttextDescriptionLtr20Parent2}>
                        <img
                          className={styles.fluenttextDescriptionLtr20Icon4}
                          alt=""
                          src="/fluenttextdescriptionltr20filled.svg"
                        />
                        <div className={styles.imInNeed4}>
                          I’m in need of a skilled {prof} at...
                        </div>
                      </div>
                      <div className={styles.akarIconslocationParent2}>
                        <img
                          className={styles.fluenttextDescriptionLtr20Icon4}
                          alt=""
                          src="/akariconslocation.svg"
                        />
                        <div className={styles.citiessummary34}>{loc}</div>
                      </div>
                      <div className={styles.rectangleParent33}>
                        <div className={styles.groupChild42} />
                        <div className={styles.citiessummary35}>
                          Message Joshua
                        </div>
                      </div>
                      <div className={styles.groupParent18}>
                        <div className={styles.rectangleParent38}>
                          <img
                            className={styles.groupChild43}
                            alt=""
                            src="/rectangle-12403@2x.png"
                          />
                          <img
                            className={styles.groupChild44}
                            alt=""
                            src="/rectangle-12404@2x.png"
                          />
                          <img
                            className={styles.groupChild52}
                            alt=""
                            src="/rectangle-12405@2x.png"
                          />
                          <img
                            className={styles.groupChild53}
                            alt=""
                            src="/rectangle-12406@2x.png"
                          />
                        </div>
                        <div className={styles.citiessummary40}>+3</div>
                      </div>
                      <b className={styles.requestForElectricianContainer8}>
                        <span
                          className={styles.requestForElectricianContainer1}
                        >
                          <p className={styles.lookingFor}>{` `}</p>
                          <p className={styles.lookingFor}>
                            Request for {prof}
                          </p>
                        </span>
                      </b>
                      <div className={styles.citiessummaryParent4}>
                        <div className={styles.citiessummary37}>
                          £600 - £1000
                        </div>
                        <img
                          className={styles.uilmoneyBillIcon4}
                          alt=""
                          src="/uilmoneybill.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.rectangleParent39}>
                  <div className={styles.groupChild54} />
                  <div className={styles.browseJobsAtNoCostParent}>
                    <b className={styles.efficientJobSchedule}>
                      Browse jobs at no cost
                    </b>
                    <div className={styles.keepTrackOf}>
                      Dive into a vast pool of service jobs without any charges.
                      We don't sell leads; we only take a commission after the
                      job is completed. This ensures that our interests are
                      aligned with your success.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rectangleParent40}>
          <div className={styles.groupChild55} />
          <div className={styles.frameWrapper4}>
            <div className={styles.joinOurGrowingCommunityOfParent}>
              <div className={styles.joinOurGrowing}>
                Join our growing community of professionals
              </div>
              <div className={styles.largeButtons7}>
                <img className={styles.iconsadd1} alt="" src="/iconsadd3.svg" />
                <b className={styles.category}>Become A Professional</b>
                <img className={styles.iconsadd1} alt="" src="/iconsadd4.svg" />
              </div>
            </div>
          </div>
          <img className={styles.maskGroupIcon} alt="" src="/mask-group.svg" />
        </div>
        <div className={styles.testimonialsProf}>
          <div className={styles.whyYouShouldContainer}>
            See What Our Satisfied Professionals are Saying
          </div>
          <div className={styles.frameParent4}>
            <div className={styles.frameWrapper5}>
              <div className={styles.joiningLoggworksHasBeenATParent}>
                <div className={styles.joiningLoggworksHas}>
                  Joining Loggworks has been a turning point for my carpentry
                  business. The platform connected me with clients who were
                  exactly looking for my expertise. Their user-friendly system
                  makes it easy to manage bookings and communicate with
                  customers. What impressed me was the increase in my client
                  base without investing in expensive advertising. Loggworks has
                  helped me grow my business and given me the tools to deliver
                  top-quality service.
                </div>
                <div className={styles.ellipseParent}>
                  <img
                    className={styles.ellipseIcon}
                    alt=""
                    src="/ellipse-276@2x.png"
                  />
                  <div className={styles.johnBarnesParent}>
                    <b className={styles.johnBarnes}>John Barnes</b>
                    <div className={styles.carpenter}>Carpenter</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameWrapper5}>
              <div className={styles.joiningLoggworksHasBeenATParent}>
                <div className={styles.joiningLoggworksHas}>
                  I started my cleaning service two years ago and struggled to
                  find consistent work. Since I registered with Loggworks, I’ve
                  seen a significant increase in bookings. The platform is
                  incredible at matching me with clients who need my services.
                  Their background verification process has also helped in
                  building trust with new clients. I’ve grown my business thanks
                  to the exposure and reliability that Loggworks provides. It’s
                  rewarding to be part of a community that values and supports
                  professional growth.
                </div>
                <div className={styles.ellipseParent}>
                  <img
                    className={styles.ellipseIcon}
                    alt=""
                    src="/ellipse-276@2x.png"
                  />
                  <div className={styles.johnBarnesParent}>
                    <b className={styles.johnBarnes}>Esther Forster</b>
                    <div className={styles.carpenter}>Professional Cleaner</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.whyYouShouldFindCaterersJParent}>
          {/*<div className={styles.whyYouShouldContainer}>Our Blog</div>
          <div className={styles.frameParent35}>
            <div className={styles.blogBottomPostsParent}>
              <div className={styles.blogBottomPosts}>
                <img
                  className={styles.unsplashsvfxlchg9hiIcon}
                  alt=""
                  src="/unsplashsvfxlchg9hi@2x.png"
                />
                <div className={styles.mergeWithTime}>
                  <div className={styles.mergeWithTime}>
                    <div className={styles.merge2}>
                      <div className={styles.articleCategory}>
                        <div className={styles.category}>How To’s</div>
                      </div>
                      <div className={styles.content6}>
                        <b className={styles.howToBuild}>
                          5 Tips for Finding the Right Home Service
                          Professionals.
                        </b>
                        <div
                          className={styles.loggworksHelpsYou}
                        >{`Loggworks helps you get special offers, connect you to top vetted and reliable service professionals with the `}</div>
                      </div>
                    </div>
                    <div className={styles.cta}>
                      <div className={styles.howItWorks}>Read More</div>
                      <img
                        className={styles.iconly}
                        alt=""
                        src="/iconly@2x.png"
                      />
                    </div>
                  </div>
                  <div className={styles.articleCategory}>
                    <div className={styles.category}>18th Jun, 2023</div>
                  </div>
                </div>
              </div>
              <div className={styles.blogBottomPosts}>
                <img
                  className={styles.unsplashsvfxlchg9hiIcon}
                  alt=""
                  src="/unsplashsvfxlchg9hi@2x.png"
                />
                <div className={styles.mergeWithTime}>
                  <div className={styles.mergeWithTime}>
                    <div className={styles.merge2}>
                      <div className={styles.articleCategory}>
                        <div className={styles.category}>How To’s</div>
                      </div>
                      <div className={styles.content6}>
                        <b className={styles.howToBuild}>
                          7 tips for running a successful cleaning service
                          company.
                        </b>
                        <div
                          className={styles.loggworksHelpsYou}
                        >{`Loggworks helps you get special offers, connect you to top vetted and reliable service professionals with the `}</div>
                      </div>
                    </div>
                    <div className={styles.cta}>
                      <div className={styles.howItWorks}>Read More</div>
                      <img
                        className={styles.iconly}
                        alt=""
                        src="/iconly@2x.png"
                      />
                    </div>
                  </div>
                  <div className={styles.articleCategory}>
                    <div className={styles.category}>18th Jun, 2023</div>
                  </div>
                </div>
              </div>
              <div className={styles.blogBottomPosts}>
                <img
                  className={styles.unsplashsvfxlchg9hiIcon}
                  alt=""
                  src="/unsplashsvfxlchg9hi@2x.png"
                />
                <div className={styles.mergeWithTime}>
                  <div className={styles.mergeWithTime}>
                    <div className={styles.merge2}>
                      <div className={styles.articleCategory}>
                        <div className={styles.category}>How To’s</div>
                      </div>
                      <div className={styles.content6}>
                        <b className={styles.howToBuild}>
                          How to know the best child care worker for your kids.
                        </b>
                        <div
                          className={styles.loggworksHelpsYou}
                        >{`Loggworks helps you get special offers, connect you to top vetted and reliable service professionals with the `}</div>
                      </div>
                    </div>
                    <div className={styles.cta}>
                      <div className={styles.howItWorks}>Read More</div>
                      <img
                        className={styles.iconly}
                        alt=""
                        src="/iconly@2x.png"
                      />
                    </div>
                  </div>
                  <div className={styles.articleCategory}>
                    <div className={styles.category}>18th Jun, 2023</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.largeButtons8}>
              <img
                className={styles.icons20pxplaceholder2}
                alt=""
                src="/icons20pxplaceholder131.svg"
              />
              <b className={styles.category}>Checkout Our Blog</b>
              <img
                className={styles.icons20pxplaceholder2}
                alt=""
                src="/icons20pxplaceholder131.svg"
              />
            </div>
          </div>*/}
        </div>
        <div className={styles.prototypeFooter}>
        <PrototypeFooter/>
        </div>
      </div>
      <div className={styles.homeMobile}>
      <div className={mobnav.navbarMobileclosed}>
          <img className={mobnav.layer2Icon} alt="" src="/layer-21.svg"
          style={{cursor:'pointer'}} onClick={()=>navigate('/')}  />
          <div className={mobnav.iconsaccountParent}>
            <img className={mobnav.iconsaccount} alt="" src="/iconsaccount21.svg" 
             onClick={()=>window.open('https://www.professional.loggworks.co.uk/', '_blank')}/>
            <img className={mobnav.hamburgerIcon} alt="" src="/hamburger.svg" onClick={openMobileMenuProfProfessionalPopup} />
          </div>
        </div>
        <div className={styles.homeMobileInner}>
          <div className={styles.topCatererJobsInLewishamGroup}>
            <div className={styles.groupParent20}>
              <div className={styles.lookingForCaterersJobsInLGroup}>
                <b className={styles.lookingForCaterersContainer1}>
                  <p className={styles.lookingFor}>Looking for</p>
                  <p className={styles.lookingFor}>
                    <span>{`${prof} Jobs in `}</span>
                    <span className={styles.lewisham}>{loc}?</span>
                  </p>
                </b>
                <img
                  className={styles.groupChild56}
                  alt=""
                  src="/vector-42.svg"
                />
              </div>
              <div className={styles.frameParent43}>
                <div className={styles.checksquareParent}>
                  <img
                    className={styles.mappinIcon}
                    alt=""
                    src="/checksquare.svg"
                  />
                  <div className={styles.freePaymentMgt}>
                    Only pay commission on completed jobs
                  </div>
                </div>
                <div className={styles.checksquareParent}>
                  <img
                    className={styles.mappinIcon}
                    alt=""
                    src="/checksquare.svg"
                  />
                  <div className={styles.freePaymentMgt}>100% free Quote</div>
                </div>
                <div className={styles.checksquareParent}>
                  <img
                    className={styles.mappinIcon}
                    alt=""
                    src="/checksquare.svg"
                  />
                  <div className={styles.freePaymentMgt}>
                    100% free payment Mgt
                  </div>
                </div>
                <div className={styles.checksquareParent}>
                  <img
                    className={styles.mappinIcon}
                    alt=""
                    src="/checksquare.svg"
                  />
                  <div
                    className={styles.freePaymentMgt}
                  >{`100% free Profile Promotion `}</div>
                </div>
                <div className={styles.checksquareParent}>
                  <img
                    className={styles.mappinIcon}
                    alt=""
                    src="/checksquare.svg"
                  />
                  <div className={styles.freePaymentMgt}>
                    100% Free business listing page
                  </div>
                </div>
                <div className={styles.checksquareParent}>
                  <img
                    className={styles.mappinIcon}
                    alt=""
                    src="/checksquare.svg"
                  />
                  <div
                    className={styles.freePaymentMgt}
                  >{`100% free Automated Job Mgt feature `}</div>
                </div>
              </div>
            </div>
            <button className={styles.largeButtons11}
            onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
              <img className={styles.mappinIcon} alt="" src="/iconsadd.svg" />
              <b className={styles.label}>
                Create new business profile for free
              </b>
              <img className={styles.iconsadd1} alt="" src="/iconsadd10.svg" />
            </button>
          </div>
        </div>
        <div className={styles.topCatererJobsInLewishamGroup}>
          <div className={styles.yourVerificationIs68ComplParent}>
            <b className={styles.whyChooseUs2}>Why Choose Us?</b>
          </div>
          <div className={styles.frameParent45}>
            <div className={styles.frameParent46}>
              <div className={styles.coinsGroup}>
                <img className={styles.coinsIcon} alt="" src="/coins.svg" />
                <div className={styles.startWithZero1}>
                  Start with zero cost
                </div>
              </div>
              <div className={styles.ourZeroCostStartupContainer1}>
                <p className={styles.lookingFor}>
                  Our zero-cost startup approach is tailored to support
                  professionals in growing
                </p>
                <p className={styles.lookingFor}>
                  their businesses sustainably.
                </p>
              </div>
            </div>
            <div className={styles.signUpAsAServiceProviderParent}>
              <div className={styles.receiptGroup}>
                <img className={styles.coinsIcon} alt="" src="/receipt.svg" />
                <div className={styles.reduceYourBusiness1}>
                  Reduce your business costs
                </div>
              </div>
              <div className={styles.ourPlatformIsContainer1}>
                <p className={styles.lookingFor}>
                  Our platform is designed to provide maximum exposure and
                  opportunities
                </p>
                <p className={styles.lookingFor}>at minimal cost.</p>
              </div>
            </div>
            <div className={styles.signUpAsAServiceProviderParent}>
              <div className={styles.receiptGroup}>
                <img className={styles.coinsIcon} alt="" src="/list.svg" />
                <div className={styles.freeBusinessListing4}>
                  Free Business Listing
                </div>
              </div>
              <div className={styles.gainMoreVisibility2}>
                Gain more visibility and attract more clients with a free
                business listing on our platform.
              </div>
            </div>
            <div className={styles.frameParent46}>
              <div className={styles.coinsGroup}>
                <img className={styles.coinsIcon} alt="" src="/handcoins.svg" />
                <div className={styles.commissionAfterCompletion1}>
                  Commission After Completion
                </div>
              </div>
              <div className={styles.ourZeroCostStartupContainer1}>
                Our unique model ensures you only pay us when you successfully
                complete a job.
              </div>
            </div>
          </div>
        </div>
        <div className={styles.topCatererJobsInLewishamGroup}>
          <b className={styles.whyChooseUs2}>
            <p className={styles.lookingFor}>
              <span className={styles.top}>{`Top `}</span>
              <span className={styles.lewisham}>{prof}</span>
              <span> Jobs</span>
            </p>
            <p className={styles.lookingFor}>in {loc}</p>
          </b>
          <div className={styles.instanceParent1}>
          <img
             src={img2}
             style={{width:'80%',height:'100%'}}
             />
          </div>
        </div>
        <div className={styles.howItWorksMobileProf}>
          <div className={styles.howItWorksMobileProfChild} />
          <div className={styles.frameParent50}>
            <div className={styles.frameParent51}>
              <div className={styles.vectorParent}>
                <img className={styles.mappinIcon} alt="" src="/steps.svg" />
                <div className={styles.howItWorks3}>HOW IT WORKS</div>
              </div>
              <b className={styles.getStartedOn1}>
                Get started on loggworks with four easy steps
              </b>
            </div>
            <div className={styles.frameParent45}>
              <div className={styles.mobileFooterParent}>
                <img
                  className={styles.frameChild15}
                  alt=""
                  src="/profimage01.png"
                />
                <div className={styles.frameParent53}>
                  <div className={styles.signUpAsAServiceProviderParent}>
                    <div className={styles.signUpAs1}>
                      Sign up as a service provider.
                    </div>
                    <div className={styles.youChooseThe1}>
                      You choose the category and subcategory of the type of
                      service you offer.
                    </div>
                  </div>
                  <button className={styles.largeButtons12}
                  onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                    <img
                      className={styles.mappinIcon}
                      alt=""
                      src="/iconsadd.svg"
                    />
                    <b className={styles.label}>
                      Create new business profile for free
                    </b>
                    <img
                      className={styles.iconsadd1}
                      alt=""
                      src="/iconsadd10.svg"
                    />
                  </button>
                </div>
              </div>
              <div className={styles.mobileFooterParent}>
                <img
                  className={styles.frameChild15}
                  alt=""
                  src="/profimage02.png"
                />
                <div className={styles.frameParent53}>
                  <div className={styles.signUpAsAServiceProviderParent}>
                    <div className={styles.signUpAs1}>
                      Receive requests from customers.
                    </div>
                    <div className={styles.youChooseThe1}>
                      Get booking requests from service consumers, review job
                      details and book jobs through Loggworks website or mobile
                      app.
                    </div>
                  </div>
                  <button className={styles.largeButtons12}
                  onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                    <img
                      className={styles.mappinIcon}
                      alt=""
                      src="/iconsadd.svg"
                    />
                    <b className={styles.label}>
                      Create new business profile for free
                    </b>
                    <img
                      className={styles.iconsadd1}
                      alt=""
                      src="/iconsadd10.svg"
                    />
                  </button>
                </div>
              </div>
              <div className={styles.mobileFooterParent}>
                <img
                  className={styles.frameChild15}
                  alt=""
                  src="/profimage03.png"
                />
                <div className={styles.frameParent53}>
                  <div className={styles.signUpAsAServiceProviderParent}>
                    <div className={styles.signUpAs1}>
                      Send quote and invoice.
                    </div>
                    <div className={styles.youChooseThe1}>
                      <p className={styles.lookingFor}>
                        When you get the job details, you will send
                      </p>
                      <p className={styles.lookingFor}>
                        a quote containing the terms and conditions
                      </p>
                      <p className={styles.lookingFor}>
                        of the job upon the acceptance of the quote
                      </p>
                      <p className={styles.lookingFor}>
                        by the customer, then an invoice will be issued for
                        payment.
                      </p>
                    </div>
                  </div>
                  <button className={styles.largeButtons12}
                  onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                    <img
                      className={styles.mappinIcon}
                      alt=""
                      src="/iconsadd.svg"
                    />
                    <b className={styles.label}>
                      Create new business profile for free
                    </b>
                    <img
                      className={styles.iconsadd1}
                      alt=""
                      src="/iconsadd10.svg"
                    />
                  </button>
                </div>
              </div>
              <div className={styles.mobileFooterParent}>
                <img
                  className={styles.frameChild15}
                  alt=""
                  src="/profimage04.png"
                />
                <div className={styles.frameParent53}>
                  <div className={styles.signUpAsAServiceProviderParent}>
                    <div className={styles.signUpAs1}>
                      Complete the job and get paid.
                    </div>
                    <div className={styles.youChooseThe1}>
                      Upon job completion to the satisfaction of the client your
                      funds are made available to you, get the job done and get
                      paid.
                    </div>
                  </div>
                  <button className={styles.largeButtons12}
                  onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')}>
                    <img
                      className={styles.mappinIcon}
                      alt=""
                      src="/iconsadd.svg"
                    />
                    <b className={styles.label}>
                      Create new business profile for free
                    </b>
                    <img
                      className={styles.iconsadd1}
                      alt=""
                      src="/iconsadd10.svg"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.topCatererJobsInLewishamGroup}>
          <div className={styles.yourVerificationIs68ComplParent}>
            <b className={styles.whyChooseUs2}>
              <p className={styles.lookingFor}>Why You Should Find</p>
              <p className={styles.lookingFor}>
                <span className={styles.lewisham}>{prof}</span>
                <span> Jobs On Loggworks</span>
              </p>
            </b>
          </div>
          <div className={styles.whyLoggworksMobile}>
            <div className={styles.groupParent29}>
              <div className={styles.rectangleParent57}>
                <div className={styles.groupChild85} />
                <div className={styles.groupChild86} />
                <div className={styles.frameWrapper7}>
                  <div className={styles.getRealTimeInsightsGroup}>
                    <div className={styles.getRealTime1}>
                      Get Real Time Insights
                    </div>
                    <div className={styles.elevateYourProfessional1}>
                      Elevate your professional journey with instant,
                      data-driven customer feedback. Gain access to real-time
                      customer satisfaction metrics to stay ahead of the curve,
                      elevate your service, and build lasting customer
                      relationships.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.rectangleParent58}>
                <div className={styles.groupChild87} />
                <div className={styles.customerSatisfactionMeasureGroup}>
                  <div className={styles.customerSatisfactionMeasure2}>
                    <div className={styles.customerSatisfactionMeasureItem} />
                    <b className={styles.customerSatisfactionMeasure3}>
                      Customer Satisfaction Measure
                    </b>
                    <div className={styles.overallSatisfaction60Container1}>
                      <span className={styles.overallSatisfaction}>
                        Overall Satisfaction:
                      </span>
                      <span className={styles.span}>
                        <span className={styles.span1}>{` `}</span>
                        <span>60%</span>
                      </span>
                    </div>
                    <div className={styles.surveyDate1}>Survey Date</div>
                    <div className={styles.div4}>12/01/20 - 13/01/20</div>
                    <div className={styles.responses1401}>Responses: 140</div>
                    <div className={styles.parent1}>
                      <div className={styles.div5}>60%</div>
                      <img
                        className={styles.groupChild88}
                        alt=""
                        src="/group-66459@2x.png"
                      />
                    </div>
                  </div>
                  <div className={styles.customerRatings2}>
                    <div className={styles.customerRatingsInner} />
                    <b className={styles.customerRatings3}>Customer Ratings</b>
                    <div className={styles.outOf50Container1}>
                      <span className={styles.span2}>4.6</span>
                      <span className={styles.span3}>{` `}</span>
                      <span className={styles.outOf50}>out of 5.0</span>
                    </div>
                    <div className={styles.div6}>12/01/20 - 13/01/20</div>
                    <div className={styles.reviews1}>56 Reviews</div>
                    <img
                      className={styles.customerRatingsChild1}
                      alt=""
                      src="/group-66649.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.groupParent30}>
              <div className={styles.rectangleParent59}>
                <div className={styles.groupChild85} />
                <div className={styles.groupChild90} />
                <div className={styles.frameWrapper8}>
                  <div className={styles.getRealTimeInsightsGroup}>
                    <div className={styles.getRealTime1}>
                      Free Business Listing
                    </div>
                    <div className={styles.elevateYourProfessional1}>
                      Gain more visibility with a free business listing on our
                      platform. Highlight your unique services and attract more
                      clients by showcasing your expertise and professionalism.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameWrapper9}>
              <img
             src={'/find/mobilefind.png'}
           
             />
              </div>
            </div>
            <div className={styles.groupParent31}>
              <div className={styles.rectangleParent57}>
                <div className={styles.groupChild85} />
                <div className={styles.groupChild92} />
                <div className={styles.frameWrapper8}>
                  <div className={styles.getRealTimeInsightsGroup}>
                    <div className={styles.getRealTime1}>
                      Efficient Job Schedule Management
                    </div>
                    <div className={styles.elevateYourProfessional1}>
                      Keep track of your commitments with our intuitive job
                      schedule manager. Get timely reminders and manage your
                      jobs effectively to maximize productivity and client
                      satisfaction.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.rectangleParent61}>
                <div className={styles.frameParent65}>
                <img
             src={'/find/mobilefind2.png'}
           
             />
                </div>
              </div>
            </div>
            <div className={styles.groupParent30}>
              <div className={styles.rectangleParent59}>
                <div className={styles.groupChild85} />
                <div className={styles.groupChild90} />
                <div className={styles.frameWrapper8}>
                  <div className={styles.getRealTimeInsightsGroup}>
                    <div className={styles.getRealTime1}>
                      Promote your services effectively
                    </div>
                    <div className={styles.elevateYourProfessional1}>
                      Take advantage of Loggworks' business promotion tools to
                      reach a wider audience. Showcase your skills and services
                      to potential clients actively seeking professionals like
                      you.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.rectangleParent63}>
                <div className={styles.groupChild96} />
                <div className={styles.promotionsListGroup}>
                  <div className={styles.promotionsList2}>
                    <div className={styles.rectangleParent64}>
                      <img
                        className={styles.frameChild22}
                        alt=""
                        src="/rectangle-12399@2x.png"
                      />
                      <div className={styles.frameParent70}>
                        <div className={styles.frameParent71}>
                          <div className={styles.citiessummaryParent10}>
                            <div className={styles.citiessummary66}>
                              Get 10% off on all bookings
                            </div>
                            <img
                              className={styles.iconsmoreVertical6}
                              alt=""
                              src="/iconsmore-vertical.svg"
                            />
                          </div>
                          <div className={styles.frameWrapper2}>
                            <div className={styles.frameParent72}>
                              <div className={styles.iconscalendarParent4}>
                                <img
                                  className={styles.iconsmoreVertical6}
                                  alt=""
                                  src="/iconscalendar.svg"
                                />
                                <div className={styles.citiessummary67}>
                                  Ends:
                                </div>
                                <div
                                  className={styles.citiessummary68}
                                >{`09/06/2021 `}</div>
                              </div>
                              <div className={styles.iconsclockParent4}>
                                <img
                                  className={styles.iconsmoreVertical6}
                                  alt=""
                                  src="/iconsclock.svg"
                                />
                                <div className={styles.citiessummary69}>
                                  9:00AM
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.price2}>Price</div>
                        </div>
                        <div className={styles.citiessummary70}>
                          {" "}
                          2 mins ago
                        </div>
                        <div className={styles.rectangleParent65}>
                          <div className={styles.instanceChild10} />
                          <div className={styles.pendingApproval1}>
                            Pending Approval
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.promotionsList3}>
                    <div className={styles.rectangleParent64}>
                      <img
                        className={styles.frameChild22}
                        alt=""
                        src="/rectangle-12399@2x.png"
                      />
                      <div className={styles.frameParent70}>
                        <div className={styles.frameParent71}>
                          <div className={styles.citiessummaryParent10}>
                            <div className={styles.citiessummary71}>
                              <span
                                className={styles.overallSatisfaction}
                              >{`Get 20% when you spend above `}</span>
                              <b>£500</b>
                            </div>
                            <img
                              className={styles.iconsmoreVertical6}
                              alt=""
                              src="/iconsmore-vertical.svg"
                            />
                          </div>
                          <div className={styles.frameWrapper2}>
                            <div className={styles.frameParent72}>
                              <div className={styles.iconscalendarParent4}>
                                <img
                                  className={styles.iconsmoreVertical6}
                                  alt=""
                                  src="/iconscalendar.svg"
                                />
                                <div className={styles.citiessummary67}>
                                  Ends:
                                </div>
                                <div
                                  className={styles.citiessummary68}
                                >{`09/06/2021 `}</div>
                              </div>
                              <div className={styles.iconsclockParent4}>
                                <img
                                  className={styles.iconsmoreVertical6}
                                  alt=""
                                  src="/iconsclock.svg"
                                />
                                <div className={styles.citiessummary69}>
                                  9:00AM
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.price2}>Price</div>
                        </div>
                        <div className={styles.citiessummary70}>
                          {" "}
                          2 mins ago
                        </div>
                        <div className={styles.rectangleParent67}>
                          <div className={styles.instanceChild11} />
                          <div className={styles.approved1}>Approved</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.groupParent30}>
              <div className={styles.rectangleParent59}>
                <div className={styles.groupChild97} />
                <div className={styles.groupChild86} />
                <div className={styles.frameWrapper16}>
                  <div className={styles.getRealTimeInsightsGroup}>
                    <div className={styles.getRealTime1}>
                      Quote and Invoice with Ease
                    </div>
                    <div className={styles.elevateYourProfessional1}>
                      Streamline your business transactions with our integrated
                      quoting and invoicing features. Provide estimates and
                      manage payments effortlessly, all within the same
                      platform.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.groupParent34}>
                <div className={styles.chatLeftContainer}>
                  <div className={styles.chatLeft1}>
                    <div className={styles.chatLeftChild}>
                      <div className={styles.quoteFrame}>
                        <div className={styles.quote3}>
                          <div className={styles.quoteBtn}>
                            <img
                              className={styles.quoteBtnItem}
                              alt=""
                              src="/rectangle-12260@2x.png"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.quoteWrapper1}>
                  <div className={styles.quote4}>
                    <div className={styles.quoteGroup}>
                      <div className={styles.quote5}>Quote</div>
                      <div className={styles.createJobQuote1}>
                        Create job quote for client jobs
                      </div>
                    </div>
                    <div className={styles.largeButtons17}>
                      <img
                        className={styles.iconsadd24}
                        alt=""
                        src="/iconsadd13.svg"
                      />
                      <b className={styles.category}>Create Quote</b>
                      <img
                        className={styles.iconsadd25}
                        alt=""
                        src="/iconsadd14.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.groupParent29}>
              <div className={styles.rectangleParent57}>
                <div className={styles.groupChild85} />
                <div className={styles.groupChild92} />
                <div className={styles.frameWrapper7}>
                  <div className={styles.getRealTimeInsightsGroup}>
                    <div className={styles.getRealTime1}>
                      Browse jobs at no cost
                    </div>
                    <div className={styles.elevateYourProfessional1}>
                      Dive into a vast pool of service jobs without any charges.
                      We don't sell leads; we only take a commission after the
                      job is completed. This ensures that our interests are
                      aligned with your success.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.rectangleParent70}>
                <div className={styles.instanceParent2}>
                <img
             src={'/find/mobilefind3.png'}
           
             />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rectangleParent79}>
          <div className={styles.groupChild116} />
          <div className={styles.frameWrapper18}>
            <div className={styles.joinOurGrowingCommunityOfGroup}>
              <div className={styles.joinOurGrowing1}>
                Join our growing community of professionals
              </div>
              <div className={styles.largeButtons18}>
                <img
                  className={styles.iconsadd26}
                  alt=""
                  src="/iconsadd15.svg"
                />
                <b className={styles.becomeAProfessional}>
                  Become A Professional
                </b>
                <img
                  className={styles.iconsadd26}
                  alt=""
                  src="/iconsadd16.svg"
                />
              </div>
            </div>
          </div>
          <img
            className={styles.maskGroupIcon1}
            alt=""
            src="/mask-group.svg"
          />
        </div>
        <div className={styles.topCatererJobsInLewishamGroup}>
          <div className={styles.yourVerificationIs68ComplParent}>
            <b className={styles.whyChooseUs2}>
              See What Our Satisfied Professionals are Saying
            </b>
          </div>
          <div className={styles.testimonialMobileProfInner}>
            <div className={styles.frameWrapper19}>
              <div className={styles.signUpAsAServiceProviderParent}>
                <div className={styles.joiningLoggworksHas1}>
                  Joining Loggworks has been a turning point for my carpentry
                  business. The platform connected me with clients who were
                  exactly looking for my expertise. Their user-friendly system
                  makes it easy to manage bookings and communicate with
                  customers. What impressed me was the increase in my client
                  base without investing in expensive advertising. Loggworks has
                  helped me grow my business and given me the tools to deliver
                  top-quality service.
                </div>
                <div className={styles.ellipseContainer}>
                  <img
                    className={styles.frameChild24}
                    alt=""
                    src="/ellipse-276@2x.png"
                  />
                  <div className={styles.yourVerificationIs68ComplParent}>
                    <b className={styles.johnBarnes1}>John Barnes</b>
                    <div className={styles.carpenter1}>Carpenter</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       { /*<div className={styles.topCatererJobsInLewishamGroup}>
          <div className={styles.topCatererJobsInLewishamGroup}>
            <div className={styles.yourVerificationIs68ComplParent}>
              <b className={styles.whyChooseUs2}>Our Blog</b>
            </div>
            <div className={styles.blogBottomPostsGroup}>
              <div className={styles.blogBottomPosts3}>
                <img
                  className={styles.unsplashsvfxlchg9hiIcon3}
                  alt=""
                  src="/unsplashsvfxlchg9hi@2x.png"
                />
                <div className={styles.mergeWithTime3}>
                  <div className={styles.mergeWithTime3}>
                    <div className={styles.merge7}>
                      <div className={styles.articleCategory3}>
                        <div className={styles.category}>How To’s</div>
                      </div>
                      <div className={styles.content15}>
                        <b className={styles.howToBuild}>
                          5 Tips for Finding the Right Home Service
                          Professionals.
                        </b>
                        <div
                          className={styles.loggworksHelpsYou3}
                        >{`Loggworks helps you get special offers, connect you to top vetted and reliable service professionals with the `}</div>
                      </div>
                    </div>
                    <div className={styles.cta3}>
                      <div className={styles.howItWorks}>Read More</div>
                      <img
                        className={styles.iconly3}
                        alt=""
                        src="/iconly@2x.png"
                      />
                    </div>
                  </div>
                  <div className={styles.articleCategory3}>
                    <div className={styles.category}>18th Jun, 2023</div>
                  </div>
                </div>
              </div>
              <div className={styles.blogBottomPosts3}>
                <img
                  className={styles.unsplashsvfxlchg9hiIcon3}
                  alt=""
                  src="/unsplashsvfxlchg9hi@2x.png"
                />
                <div className={styles.mergeWithTime3}>
                  <div className={styles.mergeWithTime3}>
                    <div className={styles.merge7}>
                      <div className={styles.articleCategory3}>
                        <div className={styles.category}>How To’s</div>
                      </div>
                      <div className={styles.content15}>
                        <b className={styles.howToBuild}>
                          7 tips for running a successful cleaning service
                          company.
                        </b>
                        <div
                          className={styles.loggworksHelpsYou3}
                        >{`Loggworks helps you get special offers, connect you to top vetted and reliable service professionals with the `}</div>
                      </div>
                    </div>
                    <div className={styles.cta3}>
                      <div className={styles.howItWorks}>Read More</div>
                      <img
                        className={styles.iconly3}
                        alt=""
                        src="/iconly@2x.png"
                      />
                    </div>
                  </div>
                  <div className={styles.articleCategory3}>
                    <div className={styles.category}>18th Jun, 2023</div>
                  </div>
                </div>
              </div>
              <div className={styles.blogBottomPosts3}>
                <img
                  className={styles.unsplashsvfxlchg9hiIcon3}
                  alt=""
                  src="/unsplashsvfxlchg9hi@2x.png"
                />
                <div className={styles.mergeWithTime3}>
                  <div className={styles.mergeWithTime3}>
                    <div className={styles.merge7}>
                      <div className={styles.articleCategory3}>
                        <div className={styles.category}>How To’s</div>
                      </div>
                      <div className={styles.content15}>
                        <b className={styles.howToBuild}>
                          How to know the best child care worker for your kids.
                        </b>
                        <div
                          className={styles.loggworksHelpsYou3}
                        >{`Loggworks helps you get special offers, connect you to top vetted and reliable service professionals with the `}</div>
                      </div>
                    </div>
                    <div className={styles.cta3}>
                      <div className={styles.howItWorks}>Read More</div>
                      <img
                        className={styles.iconly3}
                        alt=""
                        src="/iconly@2x.png"
                      />
                    </div>
                  </div>
                  <div className={styles.articleCategory3}>
                    <div className={styles.category}>18th Jun, 2023</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.largeButtons12}>
            <img className={styles.iconsadd1} alt="" src="/iconsadd3.svg" />
            <b className={styles.label}>Checkout Our Blog</b>
            <img className={styles.iconsadd1} alt="" src="/iconsadd4.svg" />
          </div>
        </div>*/}
        <MobileFooter/>
      </div>
      {isMobileMenuProfProfessionalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuProfProfessionalPopup}
        >
          <MobileMenuProfProfessional1
            onClose={closeMobileMenuProfProfessionalPopup}
          />
        </PortalPopup>
      )}
    </div>
  );
};

export default ProfFinding;
