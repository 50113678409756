import { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./MobileMenuProfProfessional1.module.css";
import LocationSearch2 from "./locationSearch2.tsx";
import MobileMenuConsumer1 from "./MobileMenuConsumer1";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';

const MobileMenuProfProfessional1 = ({ onClose }) => {
  const navigate = useNavigate();
  const [tab,setTab]=useState(0)
  const location = useLocation(); // Use useLocation hook
  const pathname = location.pathname;
  let {prof,loc}=useParams()

  const onHomeTextClick = useCallback(() => {
    navigate("/professional");
  }, [navigate]);

  const onCategoriesTextClick = useCallback(() => {
    navigate("/category");
  }, [navigate]);

  const onFrameContainerClick = useCallback(() => {
    navigate('/blog');
  }, [navigate]);

  const onAllContainerClick = useCallback(() => {
    setTab(1);
  }, [navigate]);

  return (
    <>
    {
      tab==0?
      <div className={styles.mobileMenuprofprofessional}>
        <div style={{display:'flex',justifyContent:'right',width:'90vw',padding:'1rem'}}>
      <div style={{cursor:'pointer'}}
      onClick={()=>{
        if(!pathname.includes('/consumers/')&&!pathname.includes('/professionals/')){
          navigate('/professional')
        }else{
          navigate('/professionals/'+prof+'/'+loc)
        }
        onClose()
        }}>
        <ClearIcon/>
      </div>
    </div>
          <div className={styles.tabsSubSwitch}>
        <div className={styles.allParent}>
          <div className={styles.all} onClick={onAllContainerClick}>
            <div className={styles.booking}>Consumer</div>
          </div>
          <div className={styles.booking1}>
            <div className={styles.booking2}>Professional</div>
          </div>
        </div>
      </div>
       <div className={styles.inputWrapper}>
        <div className={styles.input}>
          <div className={styles.labelContainer}>
            <div className={styles.label1}>Label</div>
          </div>
          <div className={styles.baseField}>
            <LocationSearch2/>
          </div>
          <div className={styles.helperTextContainer}>
            <div className={styles.thisIsA}>This is a helper text</div>
            <div className={styles.div}>1/100</div>
          </div>
        </div>
      </div>
      <div className={styles.frameParent}>
        <div className={styles.homeParent}>
          <div className={styles.home} style={{marginBottom:'1rem'}} onClick={onHomeTextClick}>
            Home
          </div>
          <div className={styles.home} style={{marginBottom:'0.5rem'}} onClick={onCategoriesTextClick}>
            Categories
          </div>
          <div className={styles.resourcesProfessional} style={{marginBottom:'0.5rem'}}>
            <div className={styles.services}>
              <Accordion  elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{maxHeight:'5px',width:'6.54rem',}}
        >
          <div className={styles.resources}>Resources</div>
        </AccordionSummary>
        <AccordionDetails>
        <div className={styles.list}>
              <div
                className={styles.blogParent}
                onClick={onFrameContainerClick}
              >
                <div className={styles.blog}>Blog</div>
                <div className={styles.whatIsA}>Latest news and articles</div>
              </div>
              <div className={styles.trainingAcademyParent}>
                <div className={styles.blog}>{`Training & Academy`}</div>
                <div className={styles.professionalPill}>
                  <div className={styles.icons16pxplaceholderParent}>
                    <img
                      className={styles.icons16pxplaceholder}
                      alt=""
                      src="/icons16pxplaceholder11.svg"
                    />
                    <div className={styles.childcare}>Coming Soon</div>
                  </div>
                </div>
              </div>
            </div>
        </AccordionDetails>
      </Accordion>
            </div>
          
          </div>
          <div className={styles.services1} style={{marginBottom:'1rem'}} onClick={()=>navigate('/priceplan')}>
            <div className={styles.resources}>Pricing</div>
          </div>
          <div className={styles.help} onClick={()=>navigate('/professional-faq')}>Help</div>
        </div>
        <div className={styles.largeButtons}
         style={{cursor:'pointer'}}
         onClick={()=>window.open('https://www.professional.loggworks.co.uk/sign-up', '_blank')} >
          <img className={styles.iconsadd} alt="" src="/iconsadd26.svg" />
          <b className={styles.label}>Become a Professional</b>
          <img className={styles.iconsadd} alt="" src="/iconsadd27.svg" />
        </div>
      </div>
    
      
    </div>
    :
    <MobileMenuConsumer1 onClose={onClose}/>
    }
    </>
   
  );
};

export default MobileMenuProfProfessional1;
