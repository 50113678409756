import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Homeblog.module.css";
import { Menu, MenuItem, TextField } from "@mui/material";
import MenuConsumer from "./components/MenuConsumer";
import MenuProfessional from "./components/MenuProfessional";
import NewsMenu from "./components/NewsMenu";
import ToolBar from "./components/ToolBar";
import PrototypeFooter from "../../components/PrototypeFooter";
import mobnav from "../ConsumerHome.module.css";
import MobileFooter from "../../components/MobileFooter";
import PortalPopup from "../../components/PortalPopup";
import MenuMobile from "./components/MenuMobile";
import axios from "axios";
import ProfBanner from "./components/ProfBanner";
import CustBanner from "./components/CustBanner";
import SubsBanner from "./components/SubsBanner";

const Homeblog = () => {
  const [forcons, setForcons]=useState([])
  const [forprof, setForprof]=useState([])
  const [news, setNews]=useState([])
  const [trend, setTrend]=useState([])
  const [feature_cons, setFeatureCons]=useState([])
  const [feature_prof, setFeatureProf]=useState([])
  const [
    isMobileMenuProfProfessionalPopupOpen,
    setMobileMenuProfProfessionalPopupOpen,
  ] = useState(false);
  const openMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(true);
  }, []);

  const closeMobileMenuProfProfessionalPopup = useCallback(() => {
    setMobileMenuProfProfessionalPopupOpen(false);
  }, []);
  const navigate = useNavigate();

  const formatDateToWords = (dateString) => {
    const date = new Date(dateString);
    
    // Array of month names
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    // Get the components of the date
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    
    // Construct the formatted date string
    const formattedDate = `${month} ${day}, ${year}`;
    
    return formattedDate;
  };
  const onTrendingArticlesTextClick = useCallback(() => {
    navigate("/blog/trending");
  }, [navigate]);

  const onButtonContainerClick = useCallback(() => {
    navigate("/blog/trending");
  }, [navigate]);

  const onForConsumersTextClick = useCallback(() => {
    navigate("/blog/for-consumers");
  }, [navigate]);

  const onForProfessionalsTextClick = useCallback(() => {
    navigate("/blog/for-professionals");
  }, [navigate]);

  const onNewsUpdatesClick = useCallback(() => {
    navigate("/blog/news");
  }, [navigate]);

  useEffect(() => {
    axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/blogs?populate=*')
    .then(response => {
      const blogsData = response.data.data;
      setForcons(blogsData.slice(0, 3)); // Limiting to the first 3 blogs
      const featuredBlogs = blogsData.filter(blog => blog.attributes.category === 'featured');
      setFeatureCons(featuredBlogs.slice(0, 1)); // Limiting to the first featured blog
    })
    .catch(error => {
      console.error('Error fetching blogs:', error);
    });
      axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/for-professionals?populate=*')
      .then(response => {
        setForprof(response.data.data.slice(0,3));
        const blogsData = response.data.data;
        const featuredBlogs = blogsData.filter(blog => blog.attributes.category === 'featured');
        setFeatureProf(featuredBlogs.slice(0, 1)); // Limiting to the first featured blog
      })
      .catch(error => {
        console.error('Error fetching blogs:', error);
      });
      axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/news?populate=*')
      .then(response => {
        setNews(response.data.data.slice(0,3));
        const blogsData = response.data.data;
      })
      .catch(error => {
        console.error('Error fetching blogs:', error);
      });
      Promise.all([
        axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/blogs?populate=*'),
        axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/for-professionals?populate=*'),
        axios.get('https://loggworks-cms-1213ad49d34a.herokuapp.com/api/news?populate=*')
      ])
        .then(([blogsResponse, professionalsResponse, newsResponse]) => {
          const blogsData = blogsResponse.data.data.filter(blog => blog.attributes.category === 'trending').map(blog => ({ ...blog, type: 'blogs' }));;
          const professionalsData = professionalsResponse.data.data.filter(blog => blog.attributes.category === 'trending').map(blog => ({ ...blog, type: 'professionals' }));;
          const newsData = newsResponse.data.data.filter(blog => blog.attributes.category === 'trending').map(blog => ({ ...blog, type: 'news' }));;
          
          const combined = [...blogsData, ...professionalsData, ...newsData];
          setTrend(combined.slice(0, 3));
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
  }, []);

  return (
    <div className={styles.homeblog}>
      <div className={styles.blogweb}>
        <div className={styles.blogwebInner}>
        <ToolBar/>
        </div>
        {feature_prof.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
                  <div className={styles.frameParent}>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.newArticleWrapper}>
                <div className={styles.newArticle}>New Article</div>
              </div>
              <div className={styles.minsRead}>{blog.attributes.duration} mins read</div>
            </div>
            <div className={styles.frameDiv}>
              <div className={styles.tipsForFindingTheRightHomParent}>
                <b className={styles.tipsForFinding}>
                {blog.attributes.BlogTitle}
                </b>
                <div className={styles.loggworksHelpsYou}>
                {blog.attributes.description}
                </div>
              </div>
              <div className={styles.christinaWilliamsParent}>
                <div className={styles.christinaWilliams}>
                  {blog.attributes.Author}
                </div>
                <div className={styles.frameInner} />
                <div className={styles.january18th2024}>{formatDateToWords(blog.attributes.createdAt)}</div>
              </div>
            </div>
          </div>
          <img
            className={styles.rectangleIcon}
            alt=""
            src={blog.attributes.BlogImage.data.attributes.url}
          />
        </div>
                </Link>
             ))}
     
        <div className={styles.frameParent1}>
          <div className={styles.trendingArticlesParent}>
            <div
              className={styles.trendingArticles}
              onClick={onTrendingArticlesTextClick}
            >
              Trending Articles
            </div>
            <div className={styles.groupContainer}>
                {trend.map(blog => (
                <Link key={blog.id} to={
                blog.type=='blogs'?'/blog/for-consumers/'+blog.attributes.slug:
                blog.type=='professionals'?'/blog/for-professionals/'+blog.attributes.slug:'/blog/news/'+blog.attributes.slug}>
               <div className={styles.frameWrapper}>
              <div className={styles.groupContainer}>
                <div className={styles.rectangleGroup}>
                  <div className={styles.groupItem} />
                  <img
                    className={styles.groupInner}
                    alt=""
                    src={blog.attributes.BlogImage.data.attributes.url}
                  />
                  <div className={styles.frameParent2}>
                    <div className={styles.frameParent3}>
                      <div className={styles.businessAdviceWrapper}>
                        <div className={styles.businessAdvice}>
                        {blog.attributes.category}
                        </div>
                      </div>
                      <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                    </div>
                    <div className={styles.howToPricingYourServicesAParent}>
                      <b className={styles.howToPricing}>
                      {blog.attributes.BlogTitle}
                      </b>
                      <div
                        className={styles.embarkingOnA}
                      >{blog.attributes.description}</div>
                    </div>
                    <div className={styles.christinaWilliamsGroup}>
                      <div className={styles.christinaWilliams1}>
                        {blog.attributes.Author}
                      </div>
                      <div className={styles.ellipseDiv} />
                      <div className={styles.january18th20241}>
                      {formatDateToWords(blog.attributes.createdAt)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
       
             ))}
            </div>
          </div>
          {
            trend.length>3&&
             <div className={styles.button} onClick={onButtonContainerClick}>
            <div className={styles.smallButtons1}>
              <div className={styles.viewMoreTrendingArticlesWrapper}>
                <div className={styles.viewMoreTrending}>
                  View More Trending Articles
                </div>
              </div>
              <img
                className={styles.caretrightIcon}
                alt=""
                src="/caretright.svg"
              />
            </div>
          </div>
          }
         
        </div>
        <div className={styles.trendingArticlesParent}>
          <div className={styles.forConsumersGroup}>
            <div
              className={styles.trendingArticles}
              onClick={onForConsumersTextClick}
            >
              For Consumers
            </div>
            <div className={styles.component7Parent}>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/for-consumers/hiring-guide/')}>
                <div className={styles.hiringGuides}>Hiring Guides</div>
              </div>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/for-consumers/pricing-guides')}>
                <div className={styles.hiringGuides}>Pricing Guides</div>
              </div>
              <div className={styles.component7}
               style={{cursor:'pointer'}}
               onClick={()=>navigate('/blog/for-consumers/tips')}>
                <div className={styles.hiringGuides}>Tips</div>
              </div>
              <div className={styles.component7}
               style={{cursor:'pointer'}}
               onClick={()=>navigate('/blog/for-consumers/how-to')}>
                <div className={styles.hiringGuides}>How To</div>
              </div>
            </div>
          </div>
          {feature_cons.map(blog => (
                <Link key={blog.id} to={'/blog/for-consumers/'+blog.attributes.slug}>
               <div className={styles.rectangleParent1}>
            <div className={styles.groupChild4} />
            <img
              className={styles.groupChild5}
              alt=""
              src={blog.attributes.BlogImage.data.attributes.url}
            />
            <div className={styles.frameParent9}>
              <div className={styles.tipsForFindingTheRightHomGroup}>
                <b className={styles.tipsForFinding1}>
                {blog.attributes.BlogTitle}
                </b>
                <div className={styles.loggworksHelpsYou2}>
                {blog.attributes.description}
                </div>
              </div>
              <div className={styles.christinaWilliamsParent2}>
                <div className={styles.christinaWilliams4}>
                  {blog.attributes.Author}
                </div>
                <div className={styles.frameInner} />
                <div className={styles.january18th2024}>{formatDateToWords(blog.attributes.createdAt)}</div>
              </div>
              <div className={styles.frameParent10}>
                <div className={styles.forConsumersWrapper}>
                  <div className={styles.newArticle}>{blog.attributes.category}</div>
                </div>
                <div className={styles.minsRead4}>{blog.attributes.duration} mins read</div>
              </div>
            </div>
          </div>
                </Link>
       
             ))}
          
          <div className={styles.frameWrapper}>
            <div className={styles.groupContainer}>
              
              {forcons.map(blog => (
                <Link key={blog.id} to={'/blog/for-consumers/'+blog.attributes.slug}>
                <div className={styles.rectangleParent2}>
                <div className={styles.groupChild6} />
                <img
                  className={styles.groupChild7}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent11}>
                  <div className={styles.frameParent3}>
                    <div className={styles.businessAdviceWrapper}>
                      <div className={styles.businessAdvice}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.howToPricingYourServicesAParent}>
                    <b className={styles.howToPricing}>
                     {blog.attributes.BlogTitle}
                    </b>
                    <div
                      className={styles.embarkingOnA}
                    >{blog.attributes.description}</div>
                  </div>
                  <div className={styles.christinaWilliamsGroup}>
                    <div className={styles.christinaWilliams1}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.ellipseDiv} />
                    <div className={styles.january18th20241}>
                      {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
                </Link>
       
             ))}
            </div>
          </div>
        </div>
        <CustBanner/>
        <div className={styles.trendingArticlesParent}>
          <div className={styles.forConsumersGroup}>
            <div
              className={styles.trendingArticles}
              onClick={onForProfessionalsTextClick}
            >
              For Professionals
            </div>
            <div className={styles.component7Parent}>
              <div className={styles.component7}
               style={{cursor:'pointer'}}
               onClick={()=>navigate('/blog/for-professionals/business-advice')}>
                <div className={styles.hiringGuides4}>Business advice</div>
              </div>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/for-professionals/managing-your-business')}>
                <div className={styles.hiringGuides4}>
                  managing your business
                </div>
              </div>
              <div className={styles.component7}
                style={{cursor:'pointer'}}
                onClick={()=>navigate('/blog/for-professionals/finance-advice')}>
                <div className={styles.hiringGuides4}>finance advice</div>
              </div>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/for-professionals/spotlight')}>
                <div className={styles.hiringGuides4}>spotlight</div>
              </div>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/for-professionals/tips')}>
                <div className={styles.hiringGuides4}>Tips</div>
              </div>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/for-professionals/how-to')}>
                <div className={styles.hiringGuides4}>How To</div>
              </div>
            </div>
          </div>
          {feature_prof.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
                  <div className={styles.rectangleParent6}>
            <img
              className={styles.frameChild7}
              alt=""
              src={blog.attributes.BlogImage.data.attributes.url}
            />
            <div className={styles.frameParent19}>
              <div className={styles.frameParent20}>
                <div className={styles.forProfessionalsWrapper}>
                  <div className={styles.newArticle}>For Professionals</div>
                </div>
                <div className={styles.minsRead4}>{blog.attributes.duration} mins read</div>
              </div>
              <div className={styles.tipsForFindingTheRightHomParent2}>
                <b className={styles.tipsForFinding1}>
                {blog.attributes.BlogTitle}
                </b>
                <div className={styles.loggworksHelpsYou2}>
                {blog.attributes.description}
                </div>
              </div>
              <div className={styles.christinaWilliamsParent6}>
                <div className={styles.christinaWilliams4}>
                  {blog.attributes.Author}
                </div>
                <div className={styles.frameInner} />
                <div className={styles.january18th2024}>{formatDateToWords(blog.attributes.createdAt)}</div>
              </div>
            </div>
          </div>
                </Link>
             ))}
         
          <div className={styles.frameWrapper}>
            <div className={styles.groupContainer}>
            {forprof.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
                <div className={styles.rectangleParent2}>
                <div className={styles.groupChild6} />
                <img
                  className={styles.groupChild7}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent11}>
                  <div className={styles.frameParent3}>
                    <div className={styles.businessAdviceWrapper}>
                      <div className={styles.businessAdvice}>
                      {blog.attributes.category}
                      </div>
                    </div>
                    <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.howToPricingYourServicesAParent}>
                    <b className={styles.howToPricing}>
                    {blog.attributes.BlogTitle}
                    </b>
                    <div
                      className={styles.embarkingOnA}
                    >{blog.attributes.description}</div>
                  </div>
                  <div className={styles.christinaWilliamsGroup}>
                    <div className={styles.christinaWilliams1}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.ellipseDiv} />
                    <div className={styles.january18th20241}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
                </Link>
       
             ))}
             
            </div>
          </div>
        </div>
        <ProfBanner/>
        <div className={styles.frameParent27}>
          <div className={styles.forConsumersGroup}>
            <div
              className={styles.trendingArticles}
              onClick={onNewsUpdatesClick}
            >{`News & Updates`}</div>
            <div className={styles.component7Parent}>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/news/product-updates')}>
                <div className={styles.hiringGuides4}>Product Updates</div>
              </div>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/news/partners')}>
                <div className={styles.hiringGuides4}>partners</div>
              </div>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/news/industry-news')}>
                <div className={styles.hiringGuides4}>industry News</div>
              </div>
              <div className={styles.component7}
              style={{cursor:'pointer'}}
              onClick={()=>navigate('/blog/news/loggworks-in-the-news')}>
                <div className={styles.hiringGuides4}>
                  loggworks in the news
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameWrapper}>
            <div className={styles.groupContainer}>
            {news.map(blog => (
                <Link key={blog.id} to={'/blog/news/'+blog.attributes.slug}>
                <div className={styles.rectangleParent2}>
                <div className={styles.groupChild6} />
                <img
                  className={styles.groupChild7}
                  alt=""
                  src={blog.attributes.BlogImage.data.attributes.url}
                />
                <div className={styles.frameParent11}>
                  <div className={styles.frameParent3}>
                    <div className={styles.businessAdviceWrapper}>
                      <div
                        className={styles.businessAdvice}
                      >{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead1}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.howToPricingYourServicesAParent}>
                    <b className={styles.howToPricing}>
                    {blog.attributes.BlogTitle}
                    </b>
                    <div
                      className={styles.embarkingOnA}
                    >{blog.attributes.description}</div>
                  </div>
                  <div className={styles.christinaWilliamsGroup}>
                    <div className={styles.christinaWilliams1}>
                      {blog.attributes.Author}
                    </div>
                    <div className={styles.ellipseDiv} />
                    <div className={styles.january18th20241}>
                    {formatDateToWords(blog.attributes.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
                </Link>
       
             ))}
             
            </div>
          </div>
        </div>
        <div className={styles.subscribeForNewsletterParent}>
        <SubsBanner/>
          <div className={styles.prototypeFooter}>
          <PrototypeFooter/>
          </div>
        </div>
      </div>
      <div className={styles.blogmob}>
          <div className={mobnav.navbarMobileclosed}>
          <img className={mobnav.layer2Icon} alt="" src="/layer-21.svg"
          style={{cursor:'pointer'}} onClick={()=>navigate('/')}  />
          <div className={mobnav.iconsaccountParent}>
            <div className={mobnav.iconsaccount} alt="" />
            <img className={mobnav.hamburgerIcon} alt="" src="/hamburger.svg" onClick={openMobileMenuProfProfessionalPopup} />
          </div>
        </div>
        {feature_prof.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
                <div className={styles.frameParent40}>
          <div className={styles.frameWrapper5}>
            <div className={styles.frameGroup}>
              <div className={styles.frameContainer}>
                <div className={styles.newArticleContainer}>
                  <div className={styles.newArticle1}>{blog.attributes.category}</div>
                </div>
                <div className={styles.minsRead14}>{blog.attributes.duration} mins read</div>
              </div>
              <div className={styles.frameParent43}>
                <div className={styles.getFreeInsightsAndLatestUGroup}>
                  <b className={styles.tipsForFinding8}>
                  {blog.attributes.BlogTitle}
                  </b>
                  <div className={styles.loggworksHelpsYou7}>
                  {blog.attributes.description}
                  </div>
                </div>
                <div className={styles.christinaWilliamsParent12}>
                  <div className={styles.christinaWilliams14}>
                    {blog.attributes.Author}
                  </div>
                  <div className={styles.frameInner} />
                  <div className={styles.january18th202414}>
                  {formatDateToWords(blog.attributes.createdAt)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            className={styles.frameChild20}
            alt=""
            src={blog.attributes.BlogImage.data.attributes.url}
          />
        </div>
                </Link>
             ))}
        
        <div className={styles.forConsumersGroup}>
          <div className={styles.trendingArticles1}
          onClick={onTrendingArticlesTextClick}>Trending Articles</div>
          <div className={styles.groupParent7}>
          {trend.map(blog => (
                <Link key={blog.id} to={
                blog.type=='blogs'?'/blog/for-consumers/'+blog.attributes.slug:
                blog.type=='professionals'?'/blog/for-professionals/'+blog.attributes.slug:'/blog/news/'+blog.attributes.slug}>
               <div className={styles.rectangleParent13}>
              <div className={styles.groupChild24} />
              <img
                className={styles.groupChild25}
                alt=""
                src={blog.attributes.BlogImage.data.attributes.url}
              />
              <div className={styles.frameParent44}>
                <div className={styles.frameParent45}>
                  <div className={styles.businessAdviceContainer}>
                    <div className={styles.businessAdvice1}>
                    {blog.attributes.category}
                    </div>
                  </div>
                  <div className={styles.minsRead15}>{blog.attributes.duration} mins read</div>
                </div>
                <div className={styles.howToPricingYourServicesAGroup}>
                  <b className={styles.howToPricing1}>
                  {blog.attributes.BlogTitle}
                  </b>
                  <div
                    className={styles.loggworksHelpsYou8}
                  >
                    {blog.attributes.description}
                  </div>
                </div>
                <div className={styles.christinaWilliamsParent13}>
                  <div className={styles.christinaWilliams15}>
                    {blog.attributes.Author}
                  </div>
                  <div className={styles.frameChild21} />
                  <div className={styles.january18th202415}>
                  {formatDateToWords(blog.attributes.createdAt)}
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
            
          </div>
          {
            trend.length>3&&
             <div className={styles.largeButtons2} onClick={onButtonContainerClick}>
            <b className={styles.viewMoreTrending1}>
              View More Trending Articles
            </b>
            <img className={styles.iconsadd1} alt="" src="/iconsadd4.svg" />
          </div>
          }
         
        </div>
        <div className={styles.frameParent50}>
          <div className={styles.tipsForFindingTheRightHomParent}>
            <div className={styles.trendingArticles1}
            onClick={onForConsumersTextClick}>For Consumers</div>
            <div className={styles.frameParent51}>
              <div className={styles.frameParent52}>
                <div className={styles.hiringGuidesWrapper}
                onClick={()=>navigate('/blog/for-consumers/hiring-guide')}>
                  <div className={styles.pricingGuides}>Hiring Guides</div>
                </div>
                <div className={styles.hiringGuidesWrapper}
                onClick={()=>navigate('/blog/for-consumers/pricing-guides')}>
                  <div className={styles.pricingGuides}>Pricing Guides</div>
                </div>
                <div className={styles.hiringGuidesWrapper}
                onClick={()=>navigate('/blog/for-consumers/tips')}>
                  <div className={styles.pricingGuides}>Tips</div>
                </div>
              </div>
              <div className={styles.frameParent52}>
                <div className={styles.hiringGuidesWrapper}
                onClick={()=>navigate('/blog/for-consumers/how-to')}>
                  <div className={styles.pricingGuides}>How To</div>
                </div>
                <div className={styles.pricingGuidesContainer}
                onClick={()=>navigate('/blog/for-consumers/pricing-guides')}>
                  <div className={styles.pricingGuides}>Pricing Guides</div>
                </div>
                <div className={styles.pricingGuidesContainer}
                onClick={()=>navigate('/blog/for-consumers/tips')}>
                  <div className={styles.pricingGuides}>Tips</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.groupParent7}>
          {feature_cons.map(blog => (
                <Link key={blog.id} to={'/blog/for-consumers'+blog.slug}>
                  <div className={styles.rectangleParent16}>
              <img
                className={styles.frameChild24}
                alt=""
                src={blog.attributes.BlogImage.data.attributes.url}
              />
              <div className={styles.frameWrapper6}>
                <div className={styles.frameGroup}>
                  <div className={styles.frameContainer}>
                    <div className={styles.forConsumersWrapper2}>
                      <div className={styles.newArticle1}>{blog.attributes.category}</div>
                    </div>
                    <div className={styles.minsRead14}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.frameParent43}>
                    <div className={styles.getFreeInsightsAndLatestUGroup}>
                      <b className={styles.tipsForFinding8}>
                      {blog.attributes.BlogTitle}
                      </b>
                      <div className={styles.loggworksHelpsYou7}>
                      {blog.attributes.description}
                      </div>
                    </div>
                    <div className={styles.christinaWilliamsParent12}>
                      <div className={styles.christinaWilliams14}>
                      {blog.attributes.Author}
                      </div>
                      <div className={styles.frameInner} />
                      <div className={styles.january18th202414}>
                      {formatDateToWords(blog.attributes.createdAt)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
           {forcons.map(blog => (
                <Link key={blog.id} to={'/blog/for-consumers/'+blog.attributes.slug}>
                  <div className={styles.rectangleParent13}>
              <div className={styles.groupChild24} />
              <img
                className={styles.groupChild27}
                alt=""
                src={blog.attributes.BlogImage.data.attributes.url}
              />
              <div className={styles.frameParent44}>
                <div className={styles.frameParent45}>
                  <div className={styles.businessAdviceContainer}>
                    <div className={styles.businessAdvice1}>{blog.attributes.category}</div>
                  </div>
                  <div className={styles.minsRead15}>{blog.attributes.duration} mins read</div>
                </div>
                <div className={styles.howToPricingYourServicesAGroup}>
                  <b className={styles.howToPricing1}>
                  {blog.attributes.BlogTitle}
                  </b>
                  <div
                    className={styles.loggworksHelpsYou8}
                  >{blog.attributes.description}</div>
                </div>
                <div className={styles.christinaWilliamsParent13}>
                  <div className={styles.christinaWilliams15}>
                  {blog.attributes.Author}
                  </div>
                  <div className={styles.frameChild21} />
                  <div className={styles.january18th202415}>
                  {formatDateToWords(blog.attributes.createdAt)}
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
           
            
          </div>
        </div>
        <CustBanner/>
        <div className={styles.forConsumersGroup}>
          <div className={styles.tipsForFindingTheRightHomParent}>
            <div className={styles.trendingArticles1}
            onClick={onForProfessionalsTextClick}>For Professionals</div>
            <div className={styles.frameWrapper7}>
              <div className={styles.tipsForFindingTheRightHomParent}>
                <div className={styles.frameParent52}>
                  <div className={styles.hiringGuidesWrapper} 
                  onClick={()=>navigate('/blog/for-professionals/business-advice')}>
                    <div className={styles.managingYourBusiness}>
                      Business advice
                    </div>
                  </div>
                  <div className={styles.hiringGuidesWrapper}
                  onClick={()=>navigate('/blog/for-professionals/managing-your-business')}>
                    <div className={styles.managingYourBusiness}>
                      managing your business
                    </div>
                  </div>
                </div>
                <div className={styles.frameParent52}>
                  <div className={styles.hiringGuidesWrapper}
                  onClick={()=>navigate('/blog/for-professionals/finance-advice')}>
                    <div className={styles.managingYourBusiness}>
                      finance advice
                    </div>
                  </div>
                  <div className={styles.hiringGuidesWrapper}
                  onClick={()=>navigate('/blog/for-professionals/spotlight')}>
                    <div className={styles.managingYourBusiness}>spotlight</div>
                  </div>
                  <div className={styles.hiringGuidesWrapper}
                  onClick={()=>navigate('/blog/for-professionals/tips')}>
                    <div className={styles.managingYourBusiness}>Tips</div>
                  </div>
                </div>
                <div className={styles.frameWrapper8}
                onClick={()=>navigate('/blog/for-professionals/how-to')}>
                  <div className={styles.hiringGuidesWrapper}>
                    <div className={styles.managingYourBusiness}>How To</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.groupParent7}>
          {feature_prof.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
                 <div className={styles.rectangleParent16}>
              <img
                className={styles.frameChild24}
                alt=""
                src={blog.attributes.BlogImage.data.attributes.url}
              />
              <div className={styles.frameWrapper9}>
                <div className={styles.frameGroup}>
                  <div className={styles.frameContainer}>
                    <div className={styles.forProfessionalsWrapper2}>
                      <div className={styles.newArticle1}>
                      {blog.attributes.category}
                      </div>
                    </div>
                    <div className={styles.minsRead14}>{blog.attributes.duration} mins read</div>
                  </div>
                  <div className={styles.frameParent43}>
                    <div className={styles.getFreeInsightsAndLatestUGroup}>
                      <b className={styles.tipsForFinding8}>
                      {blog.attributes.BlogTitle}
                      </b>
                      <div className={styles.loggworksHelpsYou7}>
                      {blog.attributes.description}
                      </div>
                    </div>
                    <div className={styles.christinaWilliamsParent12}>
                      <div className={styles.christinaWilliams14}>
                      {blog.attributes.Author}
                      </div>
                      <div className={styles.frameInner} />
                      <div className={styles.january18th202414}>
                      {formatDateToWords(blog.attributes.createdAt)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
            
            {forprof.map(blog => (
                <Link key={blog.id} to={'/blog/for-professionals/'+blog.attributes.slug}>
                  <div className={styles.rectangleParent13}>
              <div className={styles.groupChild24} />
              <img
                className={styles.groupChild27}
                alt=""
                src="/rectangle-12519@2x.png"
              />
              <div className={styles.frameParent44}>
                <div className={styles.frameParent45}>
                  <div className={styles.businessAdviceContainer}>
                    <div className={styles.businessAdvice1}>{blog.attributes.category}</div>
                  </div>
                  <div className={styles.minsRead15}>{blog.attributes.duration} mins read</div>
                </div>
                <div className={styles.howToPricingYourServicesAGroup}>
                  <b className={styles.howToPricing1}>
                  {blog.attributes.BlogTitle}
                  </b>
                  <div
                    className={styles.loggworksHelpsYou8}
                  >{blog.attributes.description}</div>
                </div>
                <div className={styles.christinaWilliamsParent13}>
                  <div className={styles.christinaWilliams15}>
                  {blog.attributes.Author}
                  </div>
                  <div className={styles.frameChild21} />
                  <div className={styles.january18th202415}>
                  {formatDateToWords(blog.attributes.createdAt)}
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
          </div>
        </div>
        <ProfBanner/>
        <div className={styles.forConsumersGroup}>
          <div className={styles.tipsForFindingTheRightHomParent}>
            <div className={styles.trendingArticles1}
            onClick={onNewsUpdatesClick}>{`News & Updates`}</div>
            <div className={styles.frameWrapper7}>
              <div className={styles.tipsForFindingTheRightHomParent}>
                <div className={styles.frameParent52}>
                  <div className={styles.hiringGuidesWrapper}
                  onClick={()=>navigate('/blog/news/product-updates')}>
                    <div className={styles.managingYourBusiness}>
                      Product Updates
                    </div>
                  </div>
                  <div className={styles.hiringGuidesWrapper}
                  onClick={()=>navigate('/blog/news/partners')}>
                    <div className={styles.managingYourBusiness}>partners</div>
                  </div>
                </div>
                <div className={styles.frameParent52}
                onClick={()=>navigate('/blog/news/industry-news')}>
                  <div className={styles.hiringGuidesWrapper}>
                    <div className={styles.managingYourBusiness}>
                      industry News
                    </div>
                  </div>
                  <div className={styles.hiringGuidesWrapper}
                  onClick={()=>navigate('/blog/news/loggworks-in-the-news')}>
                    <div className={styles.managingYourBusiness}>
                      loggworks in the news
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.groupParent7}>
          {news.map(blog => (
                <Link key={blog.id} to={'/blog/news/'+blog.attributes.slug}>
                  <div className={styles.rectangleParent13}>
              <div className={styles.groupChild24} />
              <img
                className={styles.groupChild27}
                alt=""
                src="/rectangle-12519@2x.png"
              />
              <div className={styles.frameParent44}>
                <div className={styles.frameParent45}>
                  <div className={styles.businessAdviceContainer}>
                    <div className={styles.businessAdvice1}>{blog.attributes.category}</div>
                  </div>
                  <div className={styles.minsRead15}>{blog.attributes.duration} mins read</div>
                </div>
                <div className={styles.howToPricingYourServicesAGroup}>
                  <b className={styles.howToPricing1}>
                  {blog.attributes.BlogTitle}
                  </b>
                  <div
                    className={styles.loggworksHelpsYou8}
                  >{blog.attributes.description}</div>
                </div>
                <div className={styles.christinaWilliamsParent13}>
                  <div className={styles.christinaWilliams15}>
                  {blog.attributes.Author}
                  </div>
                  <div className={styles.frameChild21} />
                  <div className={styles.january18th202415}>
                  {formatDateToWords(blog.attributes.createdAt)}
                  </div>
                </div>
              </div>
            </div>
                </Link>
             ))}
          </div>
        </div>
        <MobileFooter/>
      </div>
      {isMobileMenuProfProfessionalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMobileMenuProfProfessionalPopup}
        >
          <MenuMobile
            onClose={closeMobileMenuProfProfessionalPopup}
          />
        </PortalPopup>
      )}
    </div>
  );
};

export default Homeblog;
