import styles from "./FrameComponent1.module.css";
const FrameComponent1 = ({ onClose }) => {
  return (
    <div className={styles.frameParent}>
      <div className={styles.frameWrapper}>
        <div className={styles.frameGroup}>
          <div className={styles.titlesParent}>
            <div className={styles.titles}>
              <div className={styles.starRating}>Star rating</div>
              <img
                className={styles.iconschevronDown}
                alt=""
                src="/iconschevrondown5.svg"
              />
            </div>
            <div className={styles.ratingsFilterParent}>
              <div className={styles.ratingsFilter}>
                <div className={styles.checkboxParent}>
                  <div className={styles.checkbox} />
                  <div className={styles.stars}>5 Stars</div>
                </div>
                <div className={styles.stars1}>
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar7.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar7.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar7.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar7.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar7.svg"
                  />
                </div>
              </div>
              <div className={styles.ratingsFilter}>
                <div className={styles.checkboxParent}>
                  <div className={styles.checkbox} />
                  <div className={styles.stars}>4 Stars</div>
                </div>
                <div className={styles.stars1}>
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar7.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar7.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar7.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar7.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar8.svg"
                  />
                </div>
              </div>
              <div className={styles.ratingsFilter}>
                <div className={styles.checkboxParent}>
                  <div className={styles.checkbox} />
                  <div className={styles.stars}>3 Stars</div>
                </div>
                <div className={styles.stars1}>
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar7.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar7.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar7.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar8.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar8.svg"
                  />
                </div>
              </div>
              <div className={styles.ratingsFilter}>
                <div className={styles.checkboxParent}>
                  <div className={styles.checkbox} />
                  <div className={styles.stars}>2 Stars</div>
                </div>
                <div className={styles.stars1}>
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar7.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar7.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar8.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar8.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar8.svg"
                  />
                </div>
              </div>
              <div className={styles.ratingsFilter}>
                <div className={styles.checkboxParent}>
                  <div className={styles.checkbox} />
                  <div className={styles.stars}>1 Stars</div>
                </div>
                <div className={styles.stars1}>
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar7.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar8.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar8.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar8.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar8.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.titlesParent}>
            <div className={styles.titles}>
              <div className={styles.starRating}>Distance</div>
              <img
                className={styles.iconschevronDown}
                alt=""
                src="/iconschevrondown5.svg"
              />
            </div>
            <div className={styles.ratingsFilterParent}>
              <div className={styles.ratingsFilter}>
                <div className={styles.checkboxParent}>
                  <div className={styles.checkbox} />
                  <div className={styles.stars10}>10 - 20 miles</div>
                </div>
                <div className={styles.stars11}>
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar9.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                </div>
              </div>
              <div className={styles.ratingsFilter}>
                <div className={styles.checkboxParent}>
                  <div className={styles.checkbox} />
                  <div className={styles.stars10}>20 - 40 miles</div>
                </div>
                <div className={styles.stars11}>
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar9.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                </div>
              </div>
              <div className={styles.ratingsFilter}>
                <div className={styles.checkboxParent}>
                  <div className={styles.checkbox} />
                  <div className={styles.stars10}>40 - 60 miles</div>
                </div>
                <div className={styles.stars11}>
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar9.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                </div>
              </div>
              <div className={styles.ratingsFilter}>
                <div className={styles.checkboxParent}>
                  <div className={styles.checkbox} />
                  <div className={styles.stars10}>60 - 80 miles</div>
                </div>
                <div className={styles.stars11}>
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar9.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                </div>
              </div>
              <div className={styles.ratingsFilter}>
                <div className={styles.checkboxParent}>
                  <div className={styles.checkbox} />
                  <div className={styles.stars10}>80 - 100 miles</div>
                </div>
                <div className={styles.stars11}>
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar9.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.titlesParent}>
            <div className={styles.titles}>
              <div className={styles.starRating}>
                Categories/Specializations
              </div>
              <img
                className={styles.iconschevronDown}
                alt=""
                src="/iconschevrondown5.svg"
              />
            </div>
            <div className={styles.ratingsFilterParent}>
              <div className={styles.ratingsFilter}>
                <div className={styles.checkboxParent}>
                  <div className={styles.checkbox} />
                  <div className={styles.stars10}>Domestic/Residential</div>
                </div>
                <div className={styles.stars11}>
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar9.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                </div>
              </div>
              <div className={styles.ratingsFilter}>
                <div className={styles.checkboxParent}>
                  <div className={styles.checkbox} />
                  <div className={styles.stars10}>Commercial</div>
                </div>
                <div className={styles.stars11}>
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar9.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                </div>
              </div>
              <div className={styles.ratingsFilter}>
                <div className={styles.checkboxParent}>
                  <div className={styles.checkbox} />
                  <div className={styles.stars10}>Power contractors</div>
                </div>
                <div className={styles.stars11}>
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar9.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                </div>
              </div>
              <div className={styles.ratingsFilter}>
                <div className={styles.checkboxParent}>
                  <div className={styles.checkbox} />
                  <div className={styles.stars10}>Industrial electric</div>
                </div>
                <div className={styles.stars11}>
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar9.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                </div>
              </div>
              <div className={styles.ratingsFilter}>
                <div className={styles.checkboxParent}>
                  <div className={styles.checkbox} />
                  <div className={styles.stars10}>Solar installation</div>
                </div>
                <div className={styles.stars11}>
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar9.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                  <img
                    className={styles.iconsstar}
                    alt=""
                    src="/iconsstar10.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.frameContainer}>
        <div className={styles.filterParent}>
          <div className={styles.starRating}>Filter</div>
          <div className={styles.largeButtonsParent}>
            <div className={styles.largeButtons}>
              <img className={styles.iconsadd} alt="" src="/iconsadd17.svg" />
              <b className={styles.label}>Apply Filter</b>
              <img className={styles.iconsadd} alt="" src="/iconsadd18.svg" />
            </div>
            <img className={styles.iconsclose} alt="" src="/iconsclose.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent1;
