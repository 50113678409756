
import { useEffect, useRef, useState } from "react";
import NavHead from "./navHead.tsx";
import PostJob from "./postJob.tsx";

const PostJobPage = () => {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <div className="md:flex">
      <div className="flex-grow overflow-x-hidden">
        <div className="post-job bg-white h-screen relative">
          <NavHead
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            title="Post A Job"
            type="notab"
          />
          <PostJob activeStep={activeStep} setActiveStep={setActiveStep} postId={''}/>
        </div>
      </div>
    </div>
  );
};

export default PostJobPage;
