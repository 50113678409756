import React from 'react'
import { useState } from 'react';
import styles from "../Homeblog.module.css";
import { Menu, MenuItem, TextField } from "@mui/material";
import MenuConsumer from "./MenuConsumer";
import MenuProfessional from "./MenuProfessional";
import NewsMenu from "./NewsMenu";
import { useNavigate } from 'react-router-dom';

function ToolBar() {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorE2, setAnchorE2] = useState(null);
  const open2 = Boolean(anchorE2);
  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };
  const [anchorE3, setAnchorE3] = useState(null);
  const open3 = Boolean(anchorE3);
  const handleClick3 = (event) => {
    setAnchorE3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorE3(null);
  };
  return (
    <div className={styles.rectangleParent}>
    <div className={styles.groupChild} />
    <button className={styles.smallButtons}
    onClick={()=>{
      window.location.href='/'
    }}>
      <div className={styles.loggworkscoukParent}>
        <div className={styles.loggworkscouk}>loggworks.co.uk</div>
        <img
          className={styles.arrowrightIcon}
          alt=""
          src="/arrowright.svg"
        />
      </div>
    </button>
    <div className={styles.forConsumersParent}
     style={{cursor:'pointer'}}
     id="basic-button"
     aria-controls={open ? 'basic-menu' : undefined}
     aria-haspopup="true"
     aria-expanded={open ? 'true' : undefined}
     onClick={handleClick}>
      <div className={styles.forConsumers}
      onClick={()=>navigate('/blog/for-consumers')}>For Consumers</div>
      <img
        className={styles.caretdownIcon}
       
        alt=""
        src="/caretdown.svg"
      />
      
    </div>
    <Menu
id="basic-menu"
anchorEl={anchorEl}
open={open}
onClose={handleClose}
MenuListProps={{
  'aria-labelledby': 'basic-button',
}}
>
<MenuConsumer/>
</Menu>
    <div className={styles.forProfessionalsParent}
     style={{cursor:'pointer'}}
     id="basic-button"
     aria-controls={open2 ? 'basic-menu' : undefined}
     aria-haspopup="true"
     aria-expanded={open2 ? 'true' : undefined}
     onClick={handleClick2}>
      <div className={styles.forConsumers}
      onClick={()=>navigate('/blog/for-professionals')}>For Professionals</div>
      <img
        className={styles.caretdownIcon1}
        alt=""
        src="/caretdown.svg"
      />
    </div>
    <Menu
id="basic-menu"
anchorEl={anchorE2}
open={open2}
onClose={handleClose2}
MenuListProps={{
  'aria-labelledby': 'basic-button',
}}
>
<MenuProfessional/>
</Menu>
    <div className={styles.newsUpdatesParent}
     style={{cursor:'pointer'}}
     id="basic-button"
     aria-controls={open3 ? 'basic-menu' : undefined}
     aria-haspopup="true"
     aria-expanded={open3 ? 'true' : undefined}
     onClick={handleClick3}>
      <div className={styles.forConsumers}
      onClick={()=>navigate('/blog/news')}>{`News & Updates`}</div>
      <img
        className={styles.caretdownIcon2}
        alt=""
        src="/caretdown.svg"
      />
    </div>
    <Menu
id="basic-menu"
anchorEl={anchorE3}
open={open3}
onClose={handleClose3}
MenuListProps={{
  'aria-labelledby': 'basic-button',
}}
>
<NewsMenu/>
</Menu>
    <div className={styles.pricing}>Pricing</div>
    <div className={styles.blogParent}>
      <b className={styles.blog}>Blog</b>
      <div className={styles.groupParent}>
        <img
          className={styles.frameChild}
          alt=""
          src="/group-66524.svg"
        />
        <img
          className={styles.frameItem}
          alt=""
          src="/group-66525.svg"
        />
      </div>
    </div>
  </div>
  )
}

export default ToolBar