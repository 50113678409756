import styles from "./FrameComponent.module.css";
const FrameComponent = ({ onClose }) => {
  return (
    <div className={styles.frameParent}>
      <div className={styles.frameGroup}>
        <div className={styles.radioBtnParent}>
          <img className={styles.radioBtnIcon} alt="" src="/radio-btn.svg" />
          <div className={styles.all}>All</div>
        </div>
        <div className={styles.radioBtnParent}>
          <img className={styles.radioBtnIcon} alt="" src="/radio-btn1.svg" />
          <div className={styles.all}>Babysitters</div>
        </div>
        <div className={styles.radioBtnParent}>
          <img className={styles.radioBtnIcon} alt="" src="/radio-btn1.svg" />
          <div className={styles.all}>Childminders</div>
        </div>
        <div className={styles.radioBtnParent}>
          <img className={styles.radioBtnIcon} alt="" src="/radio-btn1.svg" />
          <div className={styles.all}>Nannies</div>
        </div>
        <div className={styles.radioBtnParent}>
          <img className={styles.radioBtnIcon} alt="" src="/radio-btn1.svg" />
          <div className={styles.all}>Virtual Nannies</div>
        </div>
        <div className={styles.radioBtnParent}>
          <img className={styles.radioBtnIcon} alt="" src="/radio-btn1.svg" />
          <div className={styles.all}>Nursery</div>
        </div>
      </div>
      <div className={styles.frameWrapper}>
        <div className={styles.sortParent}>
          <div className={styles.sort}>Sort</div>
          <div className={styles.largeButtonsParent}>
            <div className={styles.largeButtons}>
              <img className={styles.iconsadd} alt="" src="/iconsadd17.svg" />
              <b className={styles.all}>Apply</b>
              <img className={styles.iconsadd} alt="" src="/iconsadd18.svg" />
            </div>
            <img className={styles.iconsclose} alt="" src="/iconsclose.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent;
