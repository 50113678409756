import { useCallback } from "react";
import styles from "./Dropdown3.module.css";
const Dropdown3 = ({ onClose }) => {
  const onDropdownListContainer1Click = useCallback(() => {
    // Please sync "Home Mobile" to the project
  }, []);

  const onDropdownListContainer2Click = useCallback(() => {
    // Please sync "Home Mobile" to the project
  }, []);

  const onDropdownListContainer3Click = useCallback(() => {
    // Please sync "Home Mobile" to the project
  }, []);

  const onDropdownListContainer4Click = useCallback(() => {
    // Please sync "Home Mobile" to the project
  }, []);

  return (
    <div className={styles.dropdown}>
      <div className={styles.dropdownList}>
        <div className={styles.listParent}>
          <div className={styles.list}>All</div>
          <div className={styles.list1}>List</div>
        </div>
      </div>
      <div
        className={styles.dropdownList1}
        onClick={onDropdownListContainer1Click}
      >
        <div className={styles.listParent}>
          <div className={styles.list}>Trade Associations</div>
          <div className={styles.list1}>List</div>
        </div>
      </div>
      <div
        className={styles.dropdownList1}
        onClick={onDropdownListContainer2Click}
      >
        <div className={styles.listParent}>
          <div className={styles.list}>Retailers</div>
          <div className={styles.list1}>List</div>
        </div>
      </div>
      <div
        className={styles.dropdownList1}
        onClick={onDropdownListContainer3Click}
      >
        <div className={styles.listParent}>
          <div className={styles.list}>Ambassadors</div>
          <div className={styles.list1}>List</div>
        </div>
      </div>
      <div
        className={styles.dropdownList1}
        onClick={onDropdownListContainer4Click}
      >
        <div className={styles.listParent}>
          <div className={styles.list}>Training</div>
          <div className={styles.list1}>List</div>
        </div>
      </div>
      <div className={styles.dropdownList5}>
        <div className={styles.listParent}>
          <div className={styles.list10}>List</div>
          <div className={styles.list1}>List</div>
        </div>
      </div>
      <div className={styles.dropdownList5}>
        <div className={styles.listParent}>
          <div className={styles.list10}>List</div>
          <div className={styles.list1}>List</div>
        </div>
      </div>
      <div className={styles.dropdownList5}>
        <div className={styles.listParent}>
          <div className={styles.list10}>List</div>
          <div className={styles.list1}>List</div>
        </div>
      </div>
      <div className={styles.dropdownList5}>
        <div className={styles.listParent}>
          <div className={styles.list10}>List</div>
          <div className={styles.list1}>List</div>
        </div>
      </div>
      <div className={styles.dropdownList5}>
        <div className={styles.listParent}>
          <div className={styles.list10}>List</div>
          <div className={styles.list1}>List</div>
        </div>
      </div>
    </div>
  );
};

export default Dropdown3;
